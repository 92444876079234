import styled, { css } from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
	z-index: 1010 !important;

	.ant-drawer-title {
		color: #262626;
		font-size: 18px;
		line-height: 25px;
		font-weight: normal;
	}

	.ant-drawer-body {
		/* padding: 40px; */
		display: flex;
		flex-direction: column;
		padding: 0px;
		position: relative;
	}

	.ant-form-item-label, .ant-form-item-required {
		color: #595959;
		font-size: 14px;
		> label {
			color: #595959;
			font-size: 14px;
		}
	}

	.ant-form-item-required::before {
		position: absolute;
		right: -16px;
		color: rgb(255, 120, 117) !important;
	}

	.ant-checkbox-wrapper {
		color: #262626;
		font-weight: normal;
	}

	.ant-form {
		padding: 40px;
		.ant-form-item-explain {
			min-width: 140px;
		}
	}

	.footer-form {
		padding: 0px;
	}

	.ant-input, .ant-select-selector {
		border-radius: 4px !important;
	}

	// classes específicas para o drawer de empresa

	.empresa-user-role-warn {
		background: #D9EEFF;
		display: flex;
		align-items: center;
		margin: 24px 40px 0px;
		padding: 5px 13px;
		font-size: 12px;

		.link-roles {
			font-style: italic;
		}

		& > svg {
			margin-right: 10px;
			width: 23px;
			height: 23px;
		}
	}

	.radio-persona-type {
		.ant-radio-button-wrapper {
			font-size: 14px !important;
			color: #8C8C8C;
			font-weight: normal !important;
			border-radius: 8px 0px 0px 8px;
			&:last-child {
				border-radius: 0px 8px 8px 0px;
			}
		}

		.ant-radio-button-wrapper-checked {
			color: white;
			background-color: #0076D6 !important;
		}
	}

	.divider {
		margin-top: 0px;
		background: #D9D9D9;;
	}


	.line-uploader {
		height: 32px;
		display: flex;
		align-content: center;
		align-items: center;
		background: transparent;
		border: 1px dashed #0076D6;
		.ant-upload-drag-container {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 14px;
			color: #0076D6;
		}
	}

	.logo-uploader {
		width: 142px;
		text-align: center;
		.ant-upload-select {
			height: 142px;
			width: 142px;
		}

		.logo-uploader-under-text {
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			color: #8C8C8C;
		}
	}

	.anticon-loading {
		> svg {
			position: relative !important;
		}
	}

	@media(max-width: 768px) {
		.empresa-user-role-warn {
		& > svg {
			width: 43px !important;
		}
	}
		.ant-drawer-content-wrapper {
			max-width: 100%;
		}

		.logo-uploader {
			margin-bottom: 1rem;

			> .ant-form-item {
				margin-bottom: 2px;
			}
		}

		.ant-form {
			padding-bottom: 1rem !important;
			> .divider {
				display: none;
			}
		}

		.ant-row {
			display: block;

			> .ant-col-2,
			  .ant-col-6 ,
			  .ant-col-19 {
				max-width: 100%;
			}
		}
		
		.ant-drawer-footer {
			margin-bottom: 6rem;
    		padding: 10px 0px;
		}
	}
`;

export const IconButton = styled.div`
	cursor: pointer;
	${({ disabled }) => disabled && css`
	 	pointer-events: none;
		cursor: not-allowed;
	`};
`;

export const DrawerFooter = styled.div`
	/* margin-top: auto; */
	/* width: 100%; */
	/* background: #fff; */
	/* box-shadow: 0px -1px 0px #BFBFBF; */
`;

DrawerFooter.Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	padding: 6px 40px;

	& > .ant-form-item {
		margin-bottom: 0px;
	}

	.cancel-btn {
		border: 1px solid #595959;
		color: #595959;
	}

	.submit-btn {
		background-color: #0076D6;
	}
`;
