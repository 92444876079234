import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalAnexos = ({ updateModals, title, anexos }) => (
	<div className="modal-content">
		<div className="modal-header" styleName="modal-header">
			<button
				type="button"
				className="close"
				styleName="close-button"
				data-dismiss="modal"
				aria-label="Close"
				onClick={() => updateModals('anexos', false)}>
				<span aria-hidden="true">&times;</span>
			</button>
			<h4 className="modal-title">{title}</h4>
		</div>
		<div className="modal-body" styleName="modal-body">
			{/* eslint-disable react/no-array-index-key */}
			{anexos.map((anexo, a) => (
				<div key={a} styleName="anexo-row">
					<span>{a + 1}</span> <a href={anexo.url} target="_blank" rel="noopener noreferrer">{anexo.nome}</a>
				</div>
			))}
			{/* eslint-enable react/no-array-index-key */}
		</div>
	</div>
);

ModalAnexos.propTypes = {
	updateModals: PropTypes.func,
	title: PropTypes.string,
	anexos: PropTypes.array.isRequired,
};

ModalAnexos.defaultProps = {
	updateModals: () => {},
	title: 'Anexos',
};

export default CSSModules(ModalAnexos, styles, { allowMultiple: true });
