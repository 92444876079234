/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import RCInfo from './RCInfo';
import TagSimilar from './TagSimilar';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

class RCColumn extends Component {
	expandItensMobile = (e) => {
		const item_order = Number(e.currentTarget.getAttribute('data-order'));
		const item_id = Number(e.currentTarget.getAttribute('data-id'));
		const { updateModals, openItemAnaliseOnMobile } = this.props;
		openItemAnaliseOnMobile(item_order, item_id, updateModals);
	}

	render() {
		const {
			oc,
			itens_analise,
			rcs_abertos,
			openCloseRCs,
			window_height,
			edition_mode,
			selectItem,
			changeCondicaoPagamento,
			mobile,
			menor_preco,
		} = this.props;
		const { rcs_analise } = oc;
		/**
		 * Opção de compra colapsada
		 */
		if (oc.collapsed) {
			const rc_a = rcs_analise[0];

			return (
				<div key={rc_a.id} styleName="rc collapsed">
					{rc_a.itens_rc_analise.map(item => (
						<div
							key={item.id}
							styleName="item i-collapsed"
							data-item-right={item.item_analise_id} />
					))}
				</div>
			);
		}

		return (
			<>
				{rcs_analise.map((rc_a, r) => {
					const combinacao_end = rcs_analise.length - 1 === r;
					const rc_comb = combinacao_end ? 'rc' : 'rc comb';
					const rc_class = `${rc_comb}`;
					let tag_best_offer;
					const info_open = rcs_abertos
						.filter(x => x === rc_a.id).length > 0;

					/**
					 * Proposta sendo atualizada pelo fornecedor
					 */
					const rc_sendo_atualizado = (rc_a.rc.status === 35)
						|| (rc_a.rc.status === 36)
						|| (rc_a.rc.status === 37);
					/**
					 * Ordena itens
					 */
					const itens_rc_analise_sorted = [
						...rc_a.itens_rc_analise,
					].sort((a, b) => a.ordem - b.ordem);

					return (
						<div key={rc_a.id} styleName={rc_class}>
							{info_open && (
								<RCInfo
									window_height={window_height}
									oc_id={oc.id}
									rc_a={rc_a}
									openCloseRCs={openCloseRCs}
									changeCondicaoPagamento={
										changeCondicaoPagamento
									} />
							)}
							{itens_rc_analise_sorted.map((item, i) => {
								/**
								 * Preco total
								 */
								const precoTotal = menor_preco[item.item_analise_id];
								const {
									quantidade,
									preco_unitario,
									desconto = 0,
								} = item.item_de_resposta;

								const sub_total = preco_unitario * quantidade;

								const valor_desconto = (sub_total / 100) * desconto;
								// total
								const preco_total = (sub_total - valor_desconto) || 0;

								tag_best_offer = preco_total === precoTotal
									? (
										<span
											className="label label-default"
											styleName="style_best_offer">
											Melhor preço
										</span>
									)
									: ('');

								const row_class =
									i % 2 === 0 ? 'item' : 'item lighter';
								const ativo_type = !edition_mode
									? 'inactive'
									: 'inactive-edition';
								const ativo_class = !item.ativo
									? ` ${ativo_type}`
									: '';
								const comb_first =
									r === 0 && rcs_analise.length > 1
										? ' comb-first-col'
										: '';
								const comb_last =
									r === rcs_analise.length - 1 &&
									rcs_analise.length > 1
										? ' comb-last-col'
										: '';
								const comb_middle =
									comb_first === '' &&
									comb_last === '' &&
									rcs_analise.length > 1
										? ' comb-middle-col'
										: '';
								const col_class = `${comb_first}${comb_last}${comb_middle}`;
								const last_item_border =
									i === rc_a.itens_rc_analise.length - 1
										? ' last-item'
										: '';
								const item_class = `${row_class}${ativo_class}${col_class}${last_item_border}`;

								const btn_select_class = !item.ativo
									? 'btn-select-item'
									: 'btn-select-item ativo';

								/**
								 * Check se a quantidade é diferente do pedido
								 */
								const item_analise_qtd =
									itens_analise.by_id[item.item_analise_id]
										.item_de_pedido.quantidade;
								const item_analise_un =
									itens_analise.by_id[item.item_analise_id]
										.item_de_pedido.unidade;

								/**
								 * Pedido construtor
								 */

								const { caracteristicas_preenchidas: caracteristicas_preenchidas_analise }
									= itens_analise.by_id[item.item_analise_id].item_de_pedido.item_preenchido;
								/**
								 * Pega as características do fornecedor e as ordena
								 */
								const caracteristicas = (((
									item.item_de_resposta || {})
									.item_preenchido || {})
									.caracteristicas_preenchidas || [])
									.filter(x => x.caracteristica.visao_fornecedor !== 0)
									.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);


								const observacoes = (
									item.item_de_resposta || {})
									.informacoes_adicionais || '';

								const item_nome = (((
									item.item_de_resposta || {})
									.item_preenchido || {})
									.item || {})
									.nome || '';

								/**
								 * Proposta sendo atualizada pelo fornecedor
								 */
								if (rc_sendo_atualizado) {
									return (
										<div
											key={item.id}
											styleName={item_class}
											data-item-right={
												item.item_analise_id
											} />
									);
								}

								/**
								 * Não tenho este item
								 */
								if (item.item_de_resposta.status === -1) {
									return (
										<div
											key={item.id}
											styleName={item_class}
											data-item-right={
												item.item_analise_id
											}>
											<div styleName="nao-tenho-item-tag">
												<i
													className="fa fa-times-circle fa-fw"
													aria-hidden="true" />{' '}
												Não possui este item
											</div>
										</div>
									);
								}

								const { id: item_resposta_id } = item.item_de_resposta;
								return (
									<div
										key={item.id}
										styleName={item_class}
										data-item-right={item.item_analise_id}>
										<span
											key={item_resposta_id}
											className="label label-default hidden"
											styleName="alerta_preco_invalido"
											id={`div_alerta_preco_errado_${item_resposta_id}`}>
											Verifique o preço
										</span>
										{edition_mode && (
											<button
												type="button"
												styleName={btn_select_class}
												data-item-right={
													item.item_analise_id
												}
												onClick={() => selectItem(
													item.item_analise_id,
													item.id,
													item.rc_analise_id,
												)
												} />
										)}
										{mobile && (
											<button
												styleName="caracteristica button"
												data-order={i + 1}
												data-id={item.item_analise_id}
												onClick={this.expandItensMobile}>
												{item_nome}
											</button>
										)}
										<div styleName="preco-unitario">
											Preço unit:
											{item.item_de_resposta.status === 0 ? (
												<p>-</p>
											) : (
												<p>
													{formatCurrency(
														item.item_de_resposta
															.preco_unitario,
														2,
														'R$',
													)}
												</p>
											)}
										</div>
										<div styleName="preco-total">
											Preço total:
											{item.item_de_resposta.status === 0 ? (
												<p>Incluso</p>
											) : (
												<>
													<p>
														{formatCurrency(preco_total, 2, 'R$')}
													</p>
													{desconto > 0 && (
														<div style={{
															color: '#a1a9af',
															fontSize: '12px',
															marginTop: '-1px',
														}}>
															{desconto}% desconto incluso
														</div>
													)}
													{tag_best_offer}
												</>
											)}
										</div>
										{caracteristicas.map(carac => {
											const {
												nome,
											} = carac.caracteristica;
											const valor = carac.opcoes_preenchidas
												.map(op => (op.unidade !== '' &&
													op.unidade !== null &&
													op.unidade !== undefined
													? `${op.valor} ${op.unidade}`
													: op.valor))
												.join(', ');

											return valor !== '' ? (
												<div
													key={carac.caracteristica.id}
													styleName="caracteristica">
													<span>{nome}: </span>{valor}
												</div>
											) : null;
										})}
										{observacoes !== '' && (
											<div styleName="caracteristica">
												<span>Observações: </span>{observacoes}
											</div>
										)}
										{(
											item_analise_qtd !== item.item_de_resposta.quantidade
											|| item_analise_un !== item.item_de_resposta.unidade
										) && (
											<div styleName="caracteristica">
												<span>Quantidade: </span>{' '}
												{item.item_de_resposta.quantidade}{' '}
												{item.item_de_resposta.unidade}
												<div styleName="qtd-diferente">Diferente do pedido</div>
											</div>
										)}
										<TagSimilar
											carac_item_analise={caracteristicas_preenchidas_analise}
											carac_item_oc={caracteristicas} />
									</div>
								);
							})}
						</div>
					);
				})}
			</>
		);
	}
}

RCColumn.propTypes = {
	oc: PropTypes.object.isRequired,
	itens_analise: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	window_height: PropTypes.number.isRequired,
	edition_mode: PropTypes.bool.isRequired,
	selectItem: PropTypes.func.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	mobile: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	openItemAnaliseOnMobile: PropTypes.func.isRequired,
	menor_preco: PropTypes.object.isRequired,
};

export default CSSModules(RCColumn, styles, { allowMultiple: true });
