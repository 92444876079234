import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

/* eslint-disable */
const IIStatusView = ({ item_on_edition }) => {
	const item_integrado = item_on_edition.item_integrado || {};
	const item_integrado_id = item_integrado.id || null;
	if (item_integrado_id === null) {
		return (
			<div styleName="status">
				<span styleName="label">Nenhum item selecionado</span>
			</div>
		);
	}

		return null;

};

IIStatusView.propTypes = {
	item_on_edition: PropTypes.object,
};

IIStatusView.defaultProps = {
	item_on_edition: {},
};

export default CSSModules(IIStatusView, styles, { allowMultiple: true });
