import {
	UPDATE_COMPRAS,
	UPDATE_COMPRAS_RAW,
	COMPRAS_INITIAL_REQUESTED,
	COMPRAS_REQUESTED,
	COMPRA_REQUESTED,
} from './types';

export const updateCompras = (field, value) => ({ type: UPDATE_COMPRAS, field, value });
export const updateComprasRaw = fields_n_values => ({ type: UPDATE_COMPRAS_RAW, fields_n_values });
export const getInitialCompras = (obra_id, tab = null) => ({
	type: COMPRAS_INITIAL_REQUESTED,
	obra_id,
	tab,
});
export const getCompras = (obra_id, tab, page) => ({
	type: COMPRAS_REQUESTED,
	obra_id,
	tab,
	page,
});
export const getCompra = (url) => ({ type: COMPRA_REQUESTED, url });
