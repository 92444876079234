import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PDFObject from 'pdfobject';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Loading from '../../_loadings/Loading/Loading';

class ModalPdf extends Component {
	constructor() {
		super();
		this.state = {
			url: '',
			support: true,
		};
		this.openCloseModals = this.openCloseModals.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
	}

	UNSAFE_componentWillMount() {
		window.addEventListener('keyup', this.handleKeyUp);
		const { qc_anexo } = this.props;
		if (qc_anexo.url !== undefined && qc_anexo.url !== null) {
			const url = qc_anexo.url.substring(0, 8) !== 'https://' ? `https://${qc_anexo.url}` : qc_anexo.url;
			const support = PDFObject.supportsPDFs;
			this.setState({ url, support });
		}
	}

	componentDidMount() {
		PDFObject.embed(this.state.url, '#pdf');
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.handleKeyUp);
	}

	openCloseModals() {
		this.props.openCloseModals('pdf_qc', false);
	}

	handleKeyUp(e) {
		if (e.keyCode === 27) this.props.openCloseModals('pdf_qc', false);
	}

	render() {
		const { url, support } = this.state;

		if (support) {
			return (
				<div className="modal-content" styleName="modal-content">
					<button
						type="button"
						className="close"
						styleName="close-button-top"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.openCloseModals}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div id="pdf" styleName="pdf-wrapper" />
					<div styleName="loading">Carregando PDF... <Loading /></div>
				</div>
			);
		}

		return (
			<div className="modal-content" styleName="modal-content">
				<button
					type="button"
					className="close"
					styleName="close-button-top"
					data-dismiss="modal"
					aria-label="Close"
					onClick={this.openCloseModals}>
					<span aria-hidden="true">&times;</span>
				</button>
				<div id="pdf" styleName="pdf-wrapper no-support" />
				<div styleName="support-download-message">
					<p styleName="visible-p">Seu navegador não abre arquivos em PDF. Para fazer o download do Quadro de Cotações, clique no botão abaixo.</p>
					<p styleName="visible-p">
						<a href={url} download><button className="btn btn-success" type="button" style={{ marginTop: '20px', padding: '9px 12px 9px' }}><i className="fa fa-download" aria-hidden="true" /> Baixar PDF</button></a>
					</p>
				</div>
			</div>
		);
	}
}

ModalPdf.propTypes = {
	qc_anexo: PropTypes.object.isRequired,
	openCloseModals: PropTypes.func.isRequired,
};

export default CSSModules(ModalPdf, styles, { allowMultiple: true });
