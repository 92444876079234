import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CondicaoButton from './CondicaoButton';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { editMomentDateFormat } from '../../../../components/_functions/_editDateFormat';

const RCInfo = ({ window_height, oc_id, rc_a, openCloseRCs, changeCondicaoPagamento }) => {
	/**
	* Informações do RC analise
	*/
	const {
		preco_rc,
		condicao_selecionada_id,
	} = rc_a;
	/**
	* Informações do RC
	*/
	const {
		condicoes_de_pagamento,
		transporte,
		transporte_proprio,
		valor_transporte: vt,
		anexos_rc: anexos,
		observacoes,
		validade,
	} = rc_a.rc;

	const custos_adicionais = rc_a.rc.custos_adicionais || 0;
	const valor_transporte = (vt !== null && vt !== undefined) ? vt : 0;
	const transporte_total = transporte === 1 ? valor_transporte : 0;

	// transporte_proprio
	const opcoes_tp = {
		0: 'Próprio',
		1: 'Terceirizado',
		2: 'A Combinar',
	};
	const transporte_proprio_view = opcoes_tp[transporte_proprio] || 'A Combinar';

	return (
		<div styleName="info-open-rc" style={{ height: window_height - 40 }} data-rc-aberto data-rc-info>
			<div styleName="rc-content" style={{ height: window_height - 128 }} data-rc-info>
				{condicoes_de_pagamento && condicoes_de_pagamento.length > 0 && (
					<div styleName="caracteristica" data-rc-info>
						<span data-rc-info>Opções de pagamento:</span>
						{condicoes_de_pagamento.map((condicao, c) => {
							const com_desconto = preco_rc - (preco_rc * condicao.desconto) / 100;
							const preco_com_desconto = com_desconto + transporte_total + custos_adicionais;

							return (
								<CondicaoButton // eslint-disable-next-line react/no-array-index-key
									key={c}
									order={c}
									oc_id={oc_id}
									rc_a_id={rc_a.id}
									condicao={condicao}
									changeCondicaoPagamento={changeCondicaoPagamento}
									preco_com_desconto={preco_com_desconto}
									condicao_selecionada_id={condicao_selecionada_id} />
							);
						})}
					</div>
				)}
				<hr data-rc-info />
				{validade !== null && (
					<div styleName="caracteristica" data-rc-info>
						<span data-rc-info>Validade:</span> {editMomentDateFormat(validade)}
					</div>
				)}
				<div styleName="caracteristica" data-rc-info>
					<span data-rc-info>Transporte: </span>
					{transporte === 0 && ( // incluso no preço
						<>Frete incluso no preço (CIF)</>
					)}
					{transporte === 1 && ( // a pagar
						<>
							{transporte_proprio === 2 ? (
								<>{transporte_proprio_view}</>
							) : (
								<>
									{formatCurrency(transporte_total, 2, 'R$')} - {transporte_proprio_view}
									<p data-rc-info>(Já incluso)</p>
								</>
							)}
						</>
					)}
					{transporte === 2 && ( // não realiza entrega
						<>Frete por conta do cliente</>
					)}
				</div>
				<div styleName="caracteristica" data-rc-info>
					<span data-rc-info>Custos adicionais:</span> {formatCurrency(custos_adicionais, 2, 'R$')}
					{custos_adicionais > 0 && <p data-rc-info>(Já incluso)</p>}
				</div>

				{anexos && anexos.length > 0 && (
					<>
						<hr style={{ marginTop: '12px' }} data-rc-info />
						<div styleName="caracteristica" data-rc-info>
							<span data-rc-info>Anexos:</span>
							{anexos.map((anexo, a) => ( // eslint-disable-next-line react/no-array-index-key
								<div key={a} styleName="anexo-row" data-rc-info>
									<div styleName="number" data-rc-info>•</div>
									<a href={anexo.url} target="_blank" rel="noopener noreferrer" data-rc-info>{anexo.nome}</a>
								</div>
							))}
						</div>
					</>
				)}
				{observacoes !== '' && (
					<>
						<hr style={{ marginTop: '12px' }} data-rc-info />
						<div styleName="caracteristica" data-rc-info>
							<span data-rc-info>Observações:</span> {observacoes}
						</div>
					</>
				)}
			</div>
			<button
				type="button"
				data-rc-info
				className="btn btn-clear-conaz round-border"
				styleName="close-button"
				onClick={() => openCloseRCs(rc_a.id, true)}>
				Fechar
			</button>
		</div>
	);
};

RCInfo.propTypes = {
	window_height: PropTypes.number.isRequired,
	oc_id: PropTypes.number.isRequired,
	rc_a: PropTypes.object.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
};

export default CSSModules(RCInfo, styles, { allowMultiple: true });
