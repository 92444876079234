/* eslint-disable */
import React from 'react';
import { StatusIcon, StatusCell, ActionsArea, NumberBadge, FlexCell } from './styled';
import { ReactComponent as EditPen } from '../../icons/edit_pen.svg';
import { ReactComponent as BlockCircle } from '../../icons/block_circle.svg';
import { ReactComponent as VisualizeEye } from '../../icons/visualize_eye.svg';

// antdesign components
import { Popconfirm, Tooltip } from 'antd';

const emptyCell = '-';

const deparaDesativado = (desativado) => {
	return desativado ? 'Inativo' : 'Ativo';
};

const renderTelefones = (telefones) => {
	if(telefones && telefones.length) {
		return (
			<FlexCell>
				{telefones[0].numero}
				{telefones.length > 1 && (
					<NumberBadge>
						+ {telefones.length - 1}
					</NumberBadge>
				)}
			</FlexCell>
		);
	}
	return emptyCell;
};

const renderProjetos = (projetos) => {
	if(projetos && projetos.length) {
		return (
			<FlexCell>
				<span>
					{projetos[0]?.obra?.nome}
				</span>
				{projetos.length > 1 && (
					<NumberBadge>
						+ {projetos.length - 1}
					</NumberBadge>
				)}
			</FlexCell>
		)
	}
	return emptyCell;
}


export default (currentUserRole, openUserDrawer, blockUser, desativado, usuarioAdministrador) => [
	{
		title: 'Usuário',
		key: '1',
		dataIndex: 'nome',
		onFilter: (value, record) => record.name.includes(value),
		sorter: (a, b) => a.nome.length - b.nome.length,
		render: (value) => value || emptyCell,
		align: 'left',
		width: 160,
		ellipsis: true,
	},
	{
		title: 'E-mail',
		key: '2',
		dataIndex: 'email',
		render: (value) => value || emptyCell,
		onFilter: (value, record) => record.name.includes(value),
		sorter: (a, b) => a.email.length - b.email.length,
		width: 160,
		ellipsis: true,
	  },
	{
		title: 'Telefone',
		dataIndex: 'telefones',
		width: 140,
		key: '3',
		render: (value) => renderTelefones(value),
		// sorter: (a, b) => a.telefones[0]?.numero - b.telefones[0]?.numero,
		ellipsis: true,
	},
	{
		title: 'Cargo',
		dataIndex: 'cargo',
		width: 150,
		key: '3',
		render: (value) => value || emptyCell,
		sorter: (a, b) => a.cargo.length - b.cargo.length,
		ellipsis: true,
	},
	{
		title: 'Projetos',
		dataIndex: 'obras',
		width: 210,
		key: '4',
		// sorter: (a, b) => a.projects.length - b.projects.length,
		render: (value) => renderProjetos(value),
		ellipsis: true,
	},
	{
		title: 'Status',
		dataIndex: 'desativado',
		key: '5',
		width: 100,
		sorter: (a, b) => a.desativado - b.desativado,
		defaultSortOrder: 'ascend',
		render: (value) => (
			<StatusCell>
				<StatusIcon $status={value}/>
				{deparaDesativado(value)}
			</StatusCell>
		),
		ellipsis: true,
	},
	{
		title: 'Ações',
		dataIndex: 'actions',
		key: '6',
		width: 120,
		render: (_, record) => (
			<ActionsArea>
				{currentUserRole &&
					<>
						<Tooltip placement="top" title={`Editar o usuário ${record.nome || ''}`} color="#0076D6">
						<EditPen onClick={() => usuarioAdministrador === false ? null : openUserDrawer('edit', record)} />
						</Tooltip>

						<Tooltip placement="top" title={record.desativado === true ? `Ativar o usuário ${record.nome || ''}?` : `Desativar o usuário ${record.nome || ''}? `} color="#0076D6">
						<Popconfirm
						 title={record.desativado === true ? `Ativar o usuário ${record.nome || ''}?` : `Desativar o usuário ${record.nome || ''}? `} 
						 onCancel={() => {}} 
						 onConfirm={() => blockUser(record)}
						 disabled={usuarioAdministrador === false}
						 placement="top"
						 cancelText="Cancelar"
						 okText="Sim"
						 >
						<BlockCircle />
						</Popconfirm>
						</Tooltip>
					</>
				}
				<Tooltip placement="right" title={`Visualizar ${record.nome || ''}`} color="#0076D6">
				<VisualizeEye onClick={() => openUserDrawer('view', record)} />
				</Tooltip>
			</ActionsArea>
		)
	},
];
