import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputUnidadeItem from '../../_inputs/InputUnidadeItem/InputUnidadeItem';
import InputText from '../../_inputs/InputText/InputText';
import IETUpdateUnidade from './Triagem/IETUpdateUnidade';

class IEUnidade extends Component {
	static getDerivedStateFromProps(nextProps, prevState) {
		const { item } = nextProps;
		const item_id = item.id || 0;
		const prev_item_id = prevState.item_id || 0;
		// Check error
		const { errors } = item.front || {};
		const error = (errors || [])
			.filter(e => e.type === 'unidade')
			.length > 0;
		// Check outra unidade
		let outra_unidade = prevState.outra_unidade || false;
		if (
			item.item_preenchido !== undefined
			&& item.item_preenchido.item !== undefined
		) {
			const { item_preenchido, unidade } = item;
			const { unidades } = item_preenchido.item;
			if (item_id !== prev_item_id) {
				outra_unidade = false;
			}
			if (
				unidades.filter(x => x === unidade).length === 0
				&& unidade !== ''
			) {
				outra_unidade = true;
			}
		}
		return {
			item_id,
			outra_unidade,
			error,
		};
	}

	state = {
		outra_unidade: false,
		error: false,
	};

	updateUnidade = (item_id, field, value) => {
		const { error } = this.state;
		const { item, updateFullItemById } = this.props;
		const front = !error
			? { ...item.front }
			: {
				...item.front,
				errors: (item.front.errors || [])
					.filter(e => e.type !== 'unidade'),
			};
		const new_item = !error
			? { ...item, [field]: value }
			: { ...item, [field]: value, front };
		updateFullItemById(item_id, new_item);
		this.setState({ error: false });
	}

	handleOutraUnidade = () => {
		const outra_unidade = !this.state.outra_unidade;
		const { item, updateItemById, autoSave } = this.props;
		updateItemById(item.id, 'unidade', '');
		if (outra_unidade) {
			setTimeout(() => {
				const elem = document.getElementById(`unidade-${item.id}`);
				if (elem) elem.focus();
			}, 60);
		}
		this.setState({ outra_unidade });
		autoSave(item.id);
	}

	handleOutroAlert = () => {
		const { item } = this.props;
		const elem = document.getElementById(`tooltip-outraunidade-${item.id}`);
		if (elem) {
			const className = elem.className === 'tooltip top'
				? 'tooltip top in'
				: 'tooltip top';
			elem.className = className;
		}
	}

	triagemZeraOutraUnidade = () => {
		this.setState({ outra_unidade: false });
	}

	render() {
		const { outra_unidade, error } = this.state;
		const { item, updateFullItemById, autoSave } = this.props;
		const { id, cliente_associou, unidade, pre_unidade, item_preenchido } = item;
		const unidades = cliente_associou
			? (item_preenchido.item || {}).unidades
			: [];
		const form_group_class = error
			? 'form-group has-error'
			: 'form-group';

		return (
			<div styleName="form-group-unidades">
				<div className={form_group_class}>
					<label>&nbsp;</label>
					{(!outra_unidade && cliente_associou) ? (
						<InputUnidadeItem
							field_id={`unidade-${id}`}
							item_key={id}
							field="unidade"
							default_value={unidade}
							opcoes={unidades}
							updateFunction={this.updateUnidade}
							handleOutraUnidade={this.handleOutraUnidade}
							autoSave={autoSave}
							zerar_erros={false} />
					) : (
						<InputText
							type="item-unidade"
							field_id={`unidade-${id}`}
							item_key={id}
							field={!cliente_associou ? 'pre_unidade' : 'unidade'}
							default_value={!cliente_associou ? pre_unidade : unidade}
							updateFunction={this.updateUnidade}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSave={autoSave}
							maxLength={70}
							zerar_erros={false} />
					)}

					{outra_unidade && (
						<IETUpdateUnidade
							item={item}
							opcoes={unidades}
							unidade_to_save={!cliente_associou ? pre_unidade : unidade}
							updateFullItemById={updateFullItemById}
							autoSave={autoSave}
							triagemZeraOutraUnidade={this.triagemZeraOutraUnidade} />
					)}
				</div>
			</div>
		);
	}
}

IEUnidade.propTypes = {
	item: PropTypes.object.isRequired,
	updateItemById: PropTypes.func.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IEUnidade, styles, { allowMultiple: true });
