import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Components
import SelectOption from './SelectOption';

// Functions
import { clearItensAlerts } from '../../../_functions/_clearItensAlerts';
import { setDecimalsToString } from '../../../_functions/_numbers';

class InputSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.default_value,
		};
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { default_value } = nextProps;
		if (default_value !== this.state.value) this.setState({ value: default_value });
	}

	handleEdition(opcao_preenchida) {
		const {
			type,
			item_key,
			field,
			updateFunction,
			autoSave,
			zerar_erros,
		} = this.props;
		const _valor = typeof opcao_preenchida[0].valor === 'number'
			? setDecimalsToString(opcao_preenchida[0].valor) : opcao_preenchida[0].valor;
		this.setState({ value: `${_valor} ${opcao_preenchida[0].unidade}` });

		switch (type) {
		case 'item':
			updateFunction(item_key, field, `${opcao_preenchida[0].valor} ${opcao_preenchida[0].unidade}`);
			break;
		case 'caracteristica':
			updateFunction(item_key, field, opcao_preenchida);
			break;
		default:
		}

		autoSave(item_key);
		if (zerar_erros) clearItensAlerts();
	}

	render() {
		const { value } = this.state;
		const { type, item_key, field, opcoes, show_outro } = this.props;
		const field_id = type === 'caracteristica' ? `resposta-${item_key}-${field}` : '';

		return (
			<div className="dropdown">
				<button
					id={field_id}
					className="btn btn-default input-md dropdown-toggle"
					styleName="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="true"
					data-dropdown>
					{value} <span className="caret" styleName="dropdown-caret" data-dropdown-2 />
				</button>
				<div className="dropdown-menu" styleName="dropdown-menu-local" data-dropchild>
					{/* eslint-disable react/no-array-index-key */}
					{opcoes.map((opcao, o) => (
						<SelectOption
							key={o}
							handleEdition={this.handleEdition}
							option={opcao}
							option_view={value} />
					))}
					{show_outro && (
						<>
							<li role="separator" className="divider" styleName="divider" data-dropchild />
							<li data-dropchild><Link to="#" data-dropchild onClick={this.props.handleOutro}>Outro</Link></li>
						</>
					)}
				</div>
			</div>
		);
	}
}

InputSelect.propTypes = {
	type: PropTypes.string.isRequired,
	item_key: PropTypes.number.isRequired,
	field: PropTypes.string.isRequired,
	default_value: PropTypes.string,
	opcoes: PropTypes.array,
	updateFunction: PropTypes.func,
	handleOutro: PropTypes.func,
	autoSave: PropTypes.func,
	show_outro: PropTypes.bool,
	zerar_erros: PropTypes.bool,
};

InputSelect.defaultProps = {
	default_value: '',
	opcoes: [],
	updateFunction: () => {},
	autoSave: () => {},
	handleOutro: () => {},
	show_outro: true,
	zerar_erros: true,
};

export default CSSModules(InputSelect, styles, { allowMultiple: true });
