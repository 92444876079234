/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
// CSS
import CSSModules from 'react-css-modules';
import { Tooltip } from 'antd';
import styles from './style.module.scss';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';
import CompraRowSimplificada from '../CompraRowSimplificada/CompraRowSimplificada';
import CriarCompraButton from './CriarCompraButton';
import StarsAvaliacaoResumido from '../StarsAvaliacaoResumido/StarsAvaliacaoResumido';

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';
import { editMomentDateFormat } from '../../_functions/_editDateFormat';
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { setPrazoEntregaLabel, setPrazoEntregaView } from '../../_functions/_setPrazoEntrega';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../_content/_condicoes_pagamento';

class RCRowDetails extends Component {
	constructor(props) {
		super(props);
		this.openInfo = this.openInfo.bind(this);

		this.state = {
			toolTipRascunho: false,
			toolTipRecusada: false,
			all_compras: [],
			compras_em_rascunho: [],
			compras_recusadas: [],
			ver_compras: false,
			rascunho_destaque: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.compras !== this.props.compras) this.handleCompras();
	}

	handleCompras = () => {
		const { oc, compras } = this.props;

		const rca_id = (oc.rcs_analise[0] || {}).id || null;
		/**
		 * Verifica compras
		 */
		const all_compras = compras.filter(c => {
			if (c.rc_analise_id !== null && c.status !== -100) {
				return c.rc_analise_id === rca_id;
			}
			return (c.rc || {}).rc_id === rca_id && c.rc_id !== null;
		});
		const compras_em_rascunho = all_compras
			.filter(c => defineStatusCompra(c) === 'rascunho');

		const compras_recusadas = _.filter(all_compras, compra => defineStatusCompra(compra) === 'recusada_fornecedor');

		this.setState({ compras_em_rascunho, all_compras, compras_recusadas });
	};

	chooseWarningTooltip = (c_rascunho) => {
		const { toolTipRascunho, toolTipRecusada, compras_recusadas } = this.state;
		const { oc, cotacao, updateModals, history } = this.props;
		const tem_recusada = compras_recusadas.length > 0;
		const c_recusadas = compras_recusadas.length;
		const _id = (compras_recusadas[0] || {}).id;
		if (tem_recusada) {
			return (
				<div className={toolTipRecusada ? '' : 'hidden'} styleName="tooltip">
					<p>
						{c_recusadas === 1
							? 'Existe uma compra recusada pelo fornecedor'
							: 'Existem compras recusadas pelo fornecedor'
						}.{' '}
						Para prosseguir <b>edite</b> ou <b>cancele</b> {`a${c_recusadas === 1 ? '' : 's'}
						 compra${c_recusadas === 1 ? '' : 's'} recusada${c_recusadas === 1 ? '' : 's'}.`}
					</p>
					<div>
						<button
							onClick={() => this.setState({ toolTipRecusada: false })}
							styleName="cancelar"
							className="btn btn-default"
							style={{ paddding: '6px 30px 5px', height: '32px' }}
						>
							Cancelar
						</button>
						<button
							onClick={() => history.push(`/compra/${_id}`)}
							className="btn btn-default"
							style={{ paddding: '6px 30px 5px', height: '32px' }}

						>
							Ir para compra
						</button>
					</div>
				</div>
			);
		}
		return (
			<div className={toolTipRascunho ? '' : 'hidden'} styleName="tooltip">
				<p>
					{c_rascunho === 1
						? 'Existe uma compra em rascunho'
						: 'Existem compras em rascunho'
					}.{' '}
					Você deseja prosseguir e criar uma nova compra?
				</p>
				<div>
					<CriarCompraButton
						oc={oc}
						pedido={cotacao}
						style_class="button-comprar"
						html="Criar mesmo assim"
						updateModals={updateModals}
						style={{ height: '32px', padding: '6px 30px 5px' }}
					/>
					<button
						onClick={this.mostrarRascunhos}
						className="btn btn-default"
						style={{ height: '34px', padding: '6px 12px' }}
					>
						Mostrar rascunhos
					</button>
				</div>
			</div>
		);
	}

	openInfo() {
		const { oc, openCloseRCs } = this.props;
		const uuid = oc.id !== null
			? oc.rcs_analise[0].id
			: oc.rcs_analise[0].rc.uuid;
		openCloseRCs(uuid);
	}

	handleTooltip = () => {
		const { compras_recusadas } = this.state;
		const has_refused = compras_recusadas.length;
		if (has_refused) {
			this.setState({ toolTipRecusada: !this.state.toolTipRecusada });
			return;
		}
		this.setState({ toolTipRascunho: !this.state.toolTipRascunho });
	};

	handleOutrasCompras = () => this.setState({ ver_compras: !this.state.ver_compras });

	mostrarRascunhos = () => {
		this.setState({
			ver_compras: true,
			rascunho_destaque: true,
			toolTipRascunho: false,
		});
	}

	render() {
		const {
			all_compras,
			compras_em_rascunho,
			compras_recusadas,
			ver_compras,
			rascunho_destaque,
			toolTipRascunho,
		} = this.state;
		const {
			oc,
			cotacao,
			order,
			loading_compras,
			rcs_abertos,
			in_comb,
			qc_foi_visto,
			alertVerPdf,
			updateModals,
		} = this.props;
		const {
			anexos_rc,
			fornecedor,
			sub_total,
			condicoes_de_pagamento,
			observacoes,
			completo,
			usuario,
			status,
			uuid,
			transporte,
			transporte_proprio,
			valor_transporte: vt,
			validade,
		} = oc.rcs_analise[0].rc;
		const nome_fornecedor_view = fornecedor.nome !== '' ? fornecedor.nome : '-';
		const notas_fornecedor = fornecedor?.nota_detalhada;

		// check condicoes
		const condicoes_pagamento_check = (
			condicoes_de_pagamento !== null &&
			condicoes_de_pagamento !== undefined &&
			condicoes_de_pagamento.length > 0
		) ? condicoes_de_pagamento : [{ desconto: 0, forma: 0, outra_forma: '', outro_prazo: '', prazo: 0 }];

		// limpa, remove desconto null e ordena a lista
		const condicoes_pagamento_clean = condicoes_pagamento_check
			.filter(c => c.forma !== 0 && c.prazo !== 0)
			.map(c => ((c.desconto === null || c.desconto === undefined) ? { ...c, desconto: 0 } : c))
			.sort((a, b) => b.desconto - a.desconto);

		const custos_adicionais = oc.rcs_analise[0].rc.custos_adicionais || 0;
		const valor_transporte = (vt !== null && vt !== undefined) ? vt : 0;
		const transporte_total = transporte === 1 ? valor_transporte : 0;
		const desconto_geral = condicoes_pagamento_clean.length > 0
			? condicoes_pagamento_clean[0].desconto
			: 0;

		// pega o menor preço
		const total_com_desconto = sub_total - (sub_total * desconto_geral) / 100;
		const menor_preco_pre = total_com_desconto + transporte_total + custos_adicionais;
		const menor_preco = !in_comb
			? menor_preco_pre
			: oc.rcs_analise[0].preco_selecionado_rc;

		const condicoes_pagamento_list = [...condicoes_pagamento_clean];

		const condicoes_p_list = condicoes_pagamento_list.slice([1]);

		const preco_class = !completo ? 'preco p-incompleto' : 'preco';

		const info_open = oc.id !== null
			? rcs_abertos.filter(x => x === oc.rcs_analise[0].id).length > 0
			: rcs_abertos.filter(x => x === uuid).length > 0;

		// rc wrapper class
		const info_open_class = !info_open ? '' : ' info-open';
		const in_comb_class = !in_comb ? '' : ' in-comb';
		const rc_wrapper_class = `rc-wrapper${info_open_class}${in_comb_class}`;

		const col_class = !in_comb ? '' : 'col-in-comb';

		const c_rascunho = compras_em_rascunho.length;

		// bool compras canceladas
		const c_recusadas = compras_recusadas.length;

		const had_warning = c_rascunho > 0 || c_recusadas > 0;

		// transporte_proprio
		const opcoes_tp = {
			0: 'Próprio',
			1: 'Terceirizado',
			2: 'A Combinar',
		};
		const transporte_proprio_view = opcoes_tp[transporte_proprio] || 'A Combinar';

		const prazo_entrega_label = setPrazoEntregaLabel(oc.rcs_analise[0].rc);
		const prazo_entrega_view = setPrazoEntregaView(oc.rcs_analise[0].rc);

		// Texto do botão
		const has_active_compras = all_compras
			.filter(c => {
				const _status = defineStatusCompra(c);
				return (
					_status !== 'cancelada'
					&& _status !== 'rascunho'
				);
			})
			.length > 0;
		const text = has_active_compras
			? 'Comprar novamente'
			: 'Criar compra';

		// Estilizacao javascript dos botões de compra
		const buttonCriarCompraSemCombinacao = (window.innerWidth > 768)
			? { top: '1rem', borderRadius: 60, padding: '6px 30px 5px' } : { borderRadius: 60 };

		const buttonCriarCompraComCombinacao = (window.innerWidth > 768)
			? { top: '1.5rem' } : {};

		const buttonComprarNovamente = (window.innerWidth > 768 && !in_comb && text === 'Comprar novamente')
			? { top: '1rem', borderRadius: 60 } : !in_comb && text === 'Criar compra' ? buttonCriarCompraSemCombinacao :
				in_comb && text === 'Criar compra' ? buttonCriarCompraComCombinacao : { borderRadius: 60 };

		const valorCompra = (window.innerWidth > 768 && in_comb && text === 'Criar compra')
			? { top: '-10rem' } :
			(window.innerWidth > 768 && in_comb && text === 'Comprar novamente')
				? { top: '-10rem' } : {};

		// Mostra o QC opaco e bloqueado caso a proposta esteja sendo atualizada
		if (status === 35 || status === 36 || status === 37) {
			return (
				<div className="col-xs-12" styleName={col_class} style={{ opacity: '.45' }}>
					<div styleName={rc_wrapper_class}>
						{!in_comb && <div styleName="order">{order}</div>}
						<div styleName="fornecedor" style={window.innerWidth === 768 ? { width: '100%' } : {}}>
							<button
								styleName="nome"
								onClick={() => this.props.infoFornecedor(fornecedor.id, usuario)}>
								{nome_fornecedor_view} {notas_fornecedor != null ? '-' : ''} {window.innerWidth < 748 && <StarsAvaliacaoResumido media={notas_fornecedor?.media} tamanho_estrela={20} />}
							</button>
						</div>
						{status === 35 ? (
							<div styleName="atualizando-proposta">Proposta está sendo atualizada pelo fornecedor</div>
						) : (
							<div styleName="atualizando-proposta">Correção foi solicitada ao fornecedor</div>
						)}
					</div>
				</div>
			);
		}

		return (
			<div className="col-xs-12" styleName={col_class}>
				<div styleName={rc_wrapper_class}>
					{!in_comb && <div styleName="order">{order}</div>}
					<div styleName="fornecedor" style={window.innerWidth === 1024 ? { width: '27%' } : window.innerWidth === 768 ? { display: 'block', width: '100%' } : {}}>
						<button
							styleName="nome"
							onClick={() => this.props.infoFornecedor(fornecedor.id, usuario)}>
							{nome_fornecedor_view} {window.innerWidth < 768 && <button styleName="avaliacao-container" onClick={() => this.props.infoFornecedor(fornecedor.id, usuario)}>
								<StarsAvaliacaoResumido media={notas_fornecedor?.media} tamanho_estrela={20} />
							</button>}
						</button>
						{!completo && <div styleName="incompleto">Incompleto</div>}
					</div>


					{window.innerWidth !== 768 && window.innerWidth > 768 && <button styleName="avaliacao-container" onClick={() => this.props.infoFornecedor(fornecedor.id, usuario)}>
						<StarsAvaliacaoResumido media={notas_fornecedor?.media} tamanho_estrela={20} />
					</button>}
					{!notas_fornecedor && (
						<button styleName="avaliacao-container" style={{ marginRight: '4.4rem' }} />)
					}
					<div styleName="entrega-obs" style={window.innerWidth === 768 ? { marginLeft: '4.8rem' } : {}}>

						<div styleName="infos-rc">
							<Tooltip
								placement="topLeft"
								title={
									`${condicoes_pagamento_clean[0].forma === -1
										? condicoes_pagamento_clean[0].outra_forma
										: formas_pagamento[condicoes_pagamento_clean[0].forma].view} :
									${condicoes_pagamento_clean[0].prazo === -1
				? condicoes_pagamento_clean[0].outro_prazo
				: prazos_pagamento_full[condicoes_pagamento_clean[0].prazo].view}`
								}>
								<span>Forma de pagamento: </span>
								<strong style={{
									width: '140px',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap' }}>

									{condicoes_pagamento_clean[0].forma === -1
										? condicoes_pagamento_clean[0].outra_forma
										: formas_pagamento[condicoes_pagamento_clean[0].forma].view}:&nbsp;
									{condicoes_pagamento_clean[0].prazo === -1
										? condicoes_pagamento_clean[0].outro_prazo
										: prazos_pagamento_full[condicoes_pagamento_clean[0].prazo].view}
								</strong>
							</Tooltip>
						</div>

						<div styleName="infos-rc">
							<span>{prazo_entrega_label}: </span>
							<strong>{prazo_entrega_view}</strong>
						</div>

						<Tooltip placement="topLeft" title={observacoes !== '' && observacoes}>
							<div styleName={window.innerWidth > 768 ? 'infos-rc-obs' : 'infos-rc'}>
								<span>Observações: </span>
								<strong
									styleName="info-observacao"
									style={{
										width: '140px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap' }}>
									{observacoes || (!observacoes && 'Nenhuma observação')}
								</strong>
							</div>
						</Tooltip>

						<div styleName={window.innerWidth > 768 ? 'infos-rc-obs' : 'infos-rc'}>
							<span>Validade: </span>
							<strong
								styleName="info-observacao">
								{editMomentDateFormat(validade) || (!validade && '-')}
							</strong>
						</div>

						<div styleName="infos-rc">
							<span>Transporte: </span>
							{transporte === 0 && ( // incluso no preço
								<Tooltip placement="topLeft" title="Frete incluso no preço (CIF)">
									<strong style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Frete incluso no preço (CIF)</strong>
								</Tooltip>
							)}
							{transporte === 1 && ( // a pagar
								<>
									{transporte_proprio === 2 ? (
										<Tooltip placement="topLeft" title={transporte_proprio_view}>
											<strong style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{transporte_proprio_view}</strong>
										</Tooltip>
									) : (
										<Tooltip placement="topLeft" title={`${formatCurrency(transporte_total, 2, 'R$')} - ${transporte_proprio_view} (Já incluso)`}>
											<p style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
												{formatCurrency(transporte_total, 2, 'R$')} - {transporte_proprio_view} (Já incluso)
											</p>
										</Tooltip>
									)}
								</>
							)}
							{transporte === 2 && ( // não realiza entrega
								<strong style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>Frete por conta do cliente</strong>
							)}
						</div>

						<div styleName="infos-rc">
							<span>Adicionais: </span>
							<strong>{formatCurrency(custos_adicionais, 2, 'R$')}
								{custos_adicionais > 0 && <><p style={{ margin: '0' }}>(Já incluso)</p></>}</strong>
						</div>

						{condicoes_p_list.length > 1 && (
							<div styleName={window.innerWidth > 768 ? 'infos-rc-obs' : 'infos-rc'}>
								<button styleName="button-expandir-rc" onClick={this.openInfo} style={info_open && condicoes_p_list.length > 1 ? { marginLeft: '-25px' } : { }}>{info_open && condicoes_p_list.length > 1 ? 'Ocultar opções' : 'Ver outras opções'} </button>
								{(anexos_rc && anexos_rc.length > 0) && (
									<div styleName="anexos">
										<button type="button" onClick={() => this.props.openAnexosFornecedor(anexos_rc)}>
											<i className="fa fa-paperclip fa-rotate-90" /> {anexos_rc.length} anexo{anexos_rc.length !== 1 && 's'}
										</button>
									</div>
								)}
							</div>
						)}
					</div>

					<div styleName="valor-condicao" style={valorCompra}>
						<div styleName={preco_class} style={window.innerWidth > 768 && in_comb ? { top: '0px' } : { left: '0.2rem' }}><b>{formatCurrency(menor_preco, 2, 'R$')}</b></div>

						{(loading_compras === 2 || loading_compras === 3) ? (
							<>
								{had_warning ? (
									<>
										{this.chooseWarningTooltip(c_rascunho)}
										<button
											onClick={this.handleTooltip}
											disabled={toolTipRascunho}
											className={`btn ${toolTipRascunho
												? 'btn-default'
												: 'btn-secondary-conaz round-border'}`}
											styleName={`button-comprar ${toolTipRascunho ? 'round-border' : ''}`}
											style={buttonComprarNovamente}
										>
											{text}
										</button>
									</>
								) : (
									<>
										{!qc_foi_visto ? (
											<button
												type="button"
												className="btn btn-secondary-conaz round-border"
												styleName="button-comprar"
												onClick={alertVerPdf}
												style={window.innerWidth > 768 ? { top: '10rem' } : {}}
											>
												{text}
											</button>
										) : (
											<CriarCompraButton
												oc={oc}
												pedido={cotacao}
												in_comb={in_comb}
												style_class="button-comprar"
												html={text}
												updateModals={updateModals} />
										)}
									</>
								)}
							</>
						) : (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading"
								styleName="button-comprar">
								<LoadingButton />{text}
							</button>
						)}
					</div>

					{info_open && (
						<>
							{condicoes_p_list.map((condicao, c) => {
								const com_desconto = sub_total - (sub_total * condicao.desconto) / 100;
								const rca_sub_total = oc.rcs_analise[0].preco_rc || 0;
								const rca_com_desconto = rca_sub_total - (rca_sub_total * condicao.desconto) / 100;
								const preco_com_desconto = !in_comb
									? com_desconto + transporte_total + custos_adicionais
									: rca_com_desconto + transporte_total + custos_adicionais;
								return (
									<>
										<div styleName="expanded-info">
											<div styleName="info_expanded">
												<p style={{ color: '#BFBFBF', fontWeight: 500 }}>Opção {c + 1}</p>
											</div>
											<div styleName="outras-opcoes">
												{condicoes_pagamento_list.length > 0 && <div>Forma de pagamento:</div>}

												<div key={c} styleName="pagamentos" style={condicao.forma !== -1 && condicao.desconto ? { paddingTop: '12px' } : { paddingTop: '1rem' }}>
													<p style={{ width: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{condicao.forma === -1 ? condicao.outra_forma : formas_pagamento[condicao.forma].view}:
														{condicao.prazo === -1 ? condicao.outro_prazo : prazos_pagamento_full[condicao.prazo].view}</p>
													{condicao.desconto > 0 && <><p>({condicao.desconto}% de desconto já incluso)</p></>}
												</div>
											</div>

											<div styleName="outras-opcoes" style={{ left: '5rem' }}>
												{condicoes_pagamento_list.length > 0 && <div>Prazo de entrega:</div>}

												<div key={c} styleName="pagamentos">
													<p>{prazo_entrega_view}</p>
												</div>
											</div>

											<div styleName="outras-opcoes" style={{ left: '-4rem' }}>
												{condicoes_pagamento_list.length > 0 && <div>Transporte:</div>}

												<div key={c} styleName="pagamentos">
													{transporte === 0 && ( // incluso no preço
														<p>Frete incluso no preço (CIF)</p>
													)}
													{transporte === 1 && ( // a pagar
														<>
															{transporte_proprio === 2 ? (
																<>{transporte_proprio_view}</>
															) : (
																<>
																	<Tooltip placement="top" title={`${formatCurrency(transporte_total, 2, 'R$')} - ${transporte_proprio_view} (Já incluso)`}>
																		<p>{formatCurrency(transporte_total, 2, 'R$')} - {transporte_proprio_view} (Já incluso)</p>
																	</Tooltip>
																</>
															)}
														</>
													)}
												</div>
											</div>

											<div styleName="outras-opcoes" style={transporte === 0 ? { left: '-8rem' } : transporte === 2 ? { left: '-11rem' } : { left: '-8rem' }}>
												{condicoes_pagamento_list.length > 0 && <div>Adicionais:</div>}

												<div key={c} styleName="pagamentos">

													<p>{formatCurrency(custos_adicionais, 2, 'R$')}
														{custos_adicionais > 0 && <><p style={{ margin: '0' }}>(Já incluso)</p></>}
													</p>
												</div>

												<div key={c} styleName="pagamentos" style={{ left: '4.8rem', float: 'right', bottom: '0.5rem' }}>

													<p style={{ color: '#0076D6', fontSize: '24px' }}>{formatCurrency(preco_com_desconto, 2, 'R$')}</p>
												</div>
											</div>
										</div>
									</>
								);
							})}
						</>
					)}
					{/* SECTION Mais de uma compra */}
					{all_compras.length > 0 && (
						<>
							{ver_compras ? (
								<>
									{all_compras.map(c => (
										<CompraRowSimplificada
											key={c.id}
											destaque={rascunho_destaque}
											compra={c} />
									))}
									<button
										styleName="button-compras-dropdown"
										onClick={this.handleOutrasCompras}>
										<i className="fa fa-chevron-up" />
										{' '}ver menos compras{' '}
										<i className="fa fa-chevron-up" />
									</button>
								</>
							) : (
								<>
									<CompraRowSimplificada
										destaque={rascunho_destaque}
										compra={all_compras[0]} />
									{all_compras.length > 1 && (
										<button
											styleName="button-compras-dropdown"
											onClick={this.handleOutrasCompras}>
											<i className="fa fa-chevron-down" />
											{' '}ver mais compras{' '}
											<i className="fa fa-chevron-down" />
										</button>
									)}
								</>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

RCRowDetails.propTypes = {
	order: PropTypes.number.isRequired,
	oc: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	compras: PropTypes.array,
	updateModals: PropTypes.func.isRequired,
	qc_foi_visto: PropTypes.bool.isRequired,
	alertVerPdf: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	openAnexosFornecedor: PropTypes.func.isRequired,
	loading_compras: PropTypes.number.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	in_comb: PropTypes.bool,
};

RCRowDetails.defaultProps = {
	compras: [],
	in_comb: false,
};

const _RCRowDetails = CSSModules(RCRowDetails, styles, { allowMultiple: true });
export default withRouter(_RCRowDetails);
