import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CombinacaoPage1 from './CombinacaoPage1';
import CombinacaoPage2 from './CombinacaoPage2';

// Functions
import { _get } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

class CombinacaoSideBar extends Component {
	constructor() {
		super();
		this.state = {
			tab: 1,
			calculando: 0,
			opcoes_de_combinacao: [],
			menor_preco: 0,
			preco_minimo: 0,
			max_fornecedores: 3,
			ocs_selecionados: [],
		};
		this.updateState = this.updateState.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.selectOC = this.selectOC.bind(this);
		this.selectAllOCs = this.selectAllOCs.bind(this);
		this.geraOpcoesCombinacao = this.geraOpcoesCombinacao.bind(this);
	}

	componentDidMount() {
		this.selectAllRCsOnStart();
	}

	updateState(field, value) {
		this.setState({ [field]: value });
	}

	changeTab(e) {
		this.setState({ tab: Number(e.target.id) });
	}

	selectAllRCsOnStart() {
		const { all_ids, by_id } = this.props.opcoes_de_compra;
		const apenas_rcs = all_ids
			// .filter(oc_id => by_id[oc_id].rcs_analise.length === 1);
			.filter(oc_id => {
				const condicoes_de_pagamento = ((by_id[oc_id].rcs_analise[0] || {}).rc || {}).condicoes_de_pagamento || [];
				const aceita_parcial = condicoes_de_pagamento.filter(c => c.aceita_parcial !== 0).length > 0;
				return by_id[oc_id].rcs_analise.length === 1 && aceita_parcial;
			});
		this.setState(
			{ ocs_selecionados: apenas_rcs },
			this.geraOpcoesCombinacao,
		);
	}

	selectOC(id) {
		const is_selected = this.state.ocs_selecionados.filter(x => x === Number(id)).length > 0;
		const ocs_selecionados = !is_selected
			? [...this.state.ocs_selecionados, Number(id)].filter((el, i, a) => i === a.indexOf(el))
			: this.state.ocs_selecionados.filter(x => x !== Number(id));
		this.setState({ ocs_selecionados });
	}

	selectAllOCs(ocs_selecionados) {
		this.setState({ ocs_selecionados });
	}

	buscaMenorPreco() {
		const { all_ids, by_id } = this.props.opcoes_de_compra;
		const rc_menor_preco = [...all_ids]
			.filter(x => (
				by_id[x].rcs_analise.length === 1 &&
				by_id[x].rcs_analise[0].rc.completo
			))
			.sort((a, b) => by_id[a].preco_total - by_id[b].preco_total);
		const menor_preco = rc_menor_preco.length > 0 ? by_id[rc_menor_preco[0]].preco_total : 0.0;
		this.setState({ menor_preco });
		return menor_preco;
	}

	geraOpcoesCombinacao() {
		const {
			preco_minimo,
			max_fornecedores,
			ocs_selecionados,
		} = this.state;
		const {
			opcoes_de_compra,
			pedido_id,
		} = this.props;
		this.setState({ calculando: 1, opcoes_de_combinacao: [] });
		const menor_preco = this.buscaMenorPreco();
		const { all_ids, by_id } = opcoes_de_compra;
		const apenas_rcs = all_ids
			.filter(oc_id => by_id[oc_id].rcs_analise.length === 1);
		const rcs_ignorados = apenas_rcs
			.filter(oc_id => (
				ocs_selecionados.filter(x => x === oc_id).length === 0
				|| by_id[oc_id].rcs_analise[0].rc.status === 35
				|| by_id[oc_id].rcs_analise[0].rc.status === 36
				|| by_id[oc_id].rcs_analise[0].rc.status === 37
			))
			.map(oc_id => by_id[oc_id].rcs_analise[0].rc.uuid)
			.join(',');
		const params = {
			rcs_ignorados,
			limite_fornecedores: max_fornecedores,
			compra_minima: preco_minimo,
			// max_combinacoes: 4,
		};
		_get(`/conaz/v2/analises/gerar_combinacao/${pedido_id}`, params).then(response => {
			const sugestoes = response.data
				.filter(s => {
					/**
					 * Remove combinações já adicionadas
					 */
					const same_price = all_ids.filter(id => by_id[id].preco_total.toFixed(2) === s.total.toFixed(2));
					let ja_adicionada = false;
					if (same_price.length > 0) {
						const fornecedores_diferentes = s.fornecedores.filter((f, i) => {
							const nome = ((((by_id[same_price[0]] || {}).rcs_analise[i] || {}).rc || {}).fornecedor || {}).nome || '';
							const preco = ((by_id[same_price[0]] || {}).rcs_analise[i] || {}).preco_selecionado_rc || 0;
							return f.nome !== nome || f.total.toFixed(2) !== preco.toFixed(2);
						});
						if (fornecedores_diferentes.length === 0) {
							ja_adicionada = true;
						}
					}
					return !ja_adicionada;
				})
				.filter(s => {
					if (s.total < menor_preco || menor_preco === 0) {
						return true;
					}
					/**
					 * Remove mais caras
					 */
					const perc = Math.round((s.total - menor_preco) / (s.total / 100));
					return perc < 0;
				});
			this.setState({ calculando: 2, opcoes_de_combinacao: sugestoes });
		}).catch(error => {
			this.setState({ calculando: 3 });
			handleRequestErrors(error);
		});
		this.setState({ tab: 1 });
	}

	render() {
		/**
		 * Seta altura do modal
		 * Barra superior (busca, obras e menu):		60;
		 */
		const barra_superior = 60 + 1;
		const modal_height = window.innerHeight - barra_superior;

		const {
			tab,
			calculando,
			opcoes_de_combinacao,
			menor_preco,
			preco_minimo,
			max_fornecedores,
			ocs_selecionados,
		} = this.state;
		const {
			analise_id,
			pedido_id,
			history,
			openCombinacaoSideBar,
			closeCombinacaoSideBarAfterCreate,
			opcoes_de_compra,
			updateQc,
			only_1_item,
		} = this.props;

		if (only_1_item) {
			return (
				<div id="combinacao_side_bar" styleName="combinacao-side-bar" style={{ height: modal_height }}>
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={openCombinacaoSideBar}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="content" style={{ height: modal_height }}>
						<div styleName="no-result" style={{ paddingTop: '50px' }}>
							<i className="fa fa-lock" aria-hidden="true" />
							<p>Não é possível criar combinações para esta cotação, ela possui somente 1 item.</p>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div id="combinacao_side_bar" styleName="combinacao-side-bar" style={{ height: modal_height }}>
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={openCombinacaoSideBar}>
					<span aria-hidden="true">&times;</span>
				</button>
				<div styleName="content" style={{ height: modal_height }}>
					<div styleName="top-part">
						<h3>Combinações com múltiplos fornecedores</h3>
						<p style={{ margin: '0 0 23px', color: '#9aa0a9' }}>Analise compras com mais de um fornecedor no próprio QC. Você pode editar ou deletá-las quando quiser.</p>
						<div styleName="tabs-wrapper">
							<button
								type="button"
								id="1"
								styleName={tab === 1 ? 'tabs-item active' : 'tabs-item'}
								onClick={this.changeTab}>
								Sugestões mais baratas
							</button>
							<button
								type="button"
								id="2"
								styleName={tab === 2 ? 'tabs-item active' : 'tabs-item'}
								onClick={this.changeTab}>
								Combinação personalizada
							</button>
						</div>
					</div>

					{tab === 1 && (
						<CombinacaoPage1
							calculando={calculando}
							updateState={this.updateState}
							opcoes_de_combinacao={opcoes_de_combinacao}
							menor_preco={menor_preco}
							analise_id={analise_id}
							opcoes_de_compra={opcoes_de_compra}
							ocs_selecionados={ocs_selecionados}
							selectOC={this.selectOC}
							selectAllOCs={this.selectAllOCs}
							updateQc={updateQc}
							closeCombinacaoSideBarAfterCreate={closeCombinacaoSideBarAfterCreate}
							preco_minimo={preco_minimo}
							max_fornecedores={max_fornecedores}
							geraOpcoesCombinacao={this.geraOpcoesCombinacao} />
					)}

					{tab === 2 && (
						<CombinacaoPage2
							opcoes_de_compra={opcoes_de_compra}
							analise_id={analise_id}
							history={history}
							pedido_id={pedido_id} />
					)}
				</div>
			</div>
		);
	}
}

CombinacaoSideBar.propTypes = {
	openCombinacaoSideBar: PropTypes.func.isRequired,
	closeCombinacaoSideBarAfterCreate: PropTypes.func.isRequired,
	opcoes_de_compra: PropTypes.object.isRequired,
	pedido_id: PropTypes.string.isRequired,
	analise_id: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	updateQc: PropTypes.func.isRequired,
	only_1_item: PropTypes.bool.isRequired,
};

export default CSSModules(CombinacaoSideBar, styles, { allowMultiple: true });
