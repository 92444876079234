import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const FracaoAvaliacao = ({ media }) => {
	const mediaString = (Math.round(media * 10) / 10).toString();
	const primeirosDigitos = mediaString.slice(0, 3);
	const denominador = '5';
	if (primeirosDigitos[2] === '0' || !primeirosDigitos[2]) {
		return (<div><span styleName="primeiro-digito">{primeirosDigitos[0]}</span><span styleName="slash">/</span><span styleName="denominador">{denominador}</span></div>);
	}
	return (<div><span styleName="primeiros-digitos">{primeirosDigitos}</span><span styleName="slash">/</span><span styleName="denominador">{denominador}</span></div>);
};


FracaoAvaliacao.propTypes = {
	media: PropTypes.number.isRequired,
};


export default CSSModules(FracaoAvaliacao, styles, { allowMultiple: true });
