import {
	UPDATE_USER,
	UPDATE_USER_RAW,
	CHANGE_OBRA,
	SET_AUTH,
	UNSET_AUTH,
	USER_REQUESTED,
} from './types';

export const updateUser = (field, value) => ({ type: UPDATE_USER, field, value });
export const updateUserRaw = (fields_n_values) => ({ type: UPDATE_USER_RAW, fields_n_values });
export const changeObra = (obra_id, tem_express) => ({ type: CHANGE_OBRA, obra_id, tem_express });
export const setAuth = (token, user, tem_express) => ({ type: SET_AUTH, token, user, tem_express });
export const unsetAuth = () => ({ type: UNSET_AUTH });
export const getUser = () => ({ type: USER_REQUESTED });
export const reportErrors = () => {};
