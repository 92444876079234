import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import {
	Input,
	Form,
	Row,
	Col,
	Select,
	Checkbox,
	Button,
	Divider,
} from 'antd';
import InputMask from 'react-input-mask';

//
import { validations } from 'ambar-ui';
import { StyledDrawer, DrawerFooter, IconButton } from './styled';
import { ReactComponent as PlusCircle } from '../../icons/plus_circle.svg';
import { ReactComponent as MinusCircle } from '../../icons/minus_circle.svg';
import { ReactComponent as Exclamacao } from '../../icons/exclamacao_outlined.svg';

//
import cargoOptions from './cargoOptions';

const { validateEmail, validateRequired, validateTelephone } = validations;
const { Option } = Select;
const modes = {
	NEW: 'new',
	EDIT: 'edit',
	VIEW: 'view',
};

function DrawerUser({ onClose, visible, userData, mode, onSubmit, loading, width }) {
	const [form] = Form.useForm();
	const [isAdmin, setIsAdmin] = useState(false);
	const disableAll = mode === modes.VIEW || loading;
	const loadData = mode === modes.VIEW || mode === modes.EDIT;
	const maxTelefones = 2;
	const userRoleLink = 'https://produtosautodoc.zendesk.com/hc/pt-br/articles/4416798861971-Como-cadastrar-um-usu%C3%A1rio-';

	const onCheckBoxChange = (e) => {
		const { checked } = e.target;
		setIsAdmin(checked);
		form.setFields([{ name: 'isAdmin', value: checked }]);
	};

	const handleSubmit = (fields) => {
		const {
			telefones,
			...rest
		} = fields;
		const previousData = userData && mode === modes.EDIT ? userData : {};
		const telefonesAux = telefones.map((t) => ({ numero: t.replace(/[^\d]+/g, ''), tipo: 1 }));
		onSubmit({
			id: previousData.id,
			...rest,
			telefones: telefonesAux,
		});
	};

	const getDrawerTitle = (m) => {
		if (m === modes.EDIT) return 'Edição de usuário';
		if (m === modes.VIEW) return 'Visualização de usuário';
		return 'Cadastro de novo usuário';
	};

	const handleClose = () => {
		onClose();
		form.resetFields();
	};

	useEffect(() => {
		if (loadData && userData) {
			// fix para quando algum usuário tem o campo telefone mas n tem número.
			const { telefones } = userData || {};
			const phonesAux = telefones.length ? telefones.map((t) => t.numero) : [' '];
			const aux = { ...userData, telefones: phonesAux };
			form.setFieldsValue(aux);
		}
	}, [mode, userData]);

	useEffect(() => {
		if (!visible) form.resetFields();
	}, [visible]);

	return (
		<StyledDrawer
			title={getDrawerTitle(mode)}
			placement="right"
			width={width || '620'}
			destroyOnClose
			onClose={handleClose}
			visible={visible}
		>
			<div className="usuario-user-role-warn" style={{ margin: 0 }}>
				<Exclamacao />
				<div style={{ display: 'block', alignItems: 'center' }}>
					<a className="link-roles" href={userRoleLink}>
						Entenda como cadastrar um novo usuário em nossa central de ajuda.
					</a>
				</div>
			</div>
			<Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{ telefones: [' '] }}>
				<Form.Item label="Nome do usuário" name="nome" rules={[validateRequired()]}>
					<Input disabled={disableAll} />
				</Form.Item>
				<Form.Item label="E-mail do usuário" name="email" rules={[validateEmail(), validateRequired()]}>
					<Input disabled={disableAll} />
				</Form.Item>
				<Row gutter={20}>
					<Col span={14}>
						<Form.Item label="Cargo" name="cargo" rules={[validateRequired()]}>
							<Select placeholder="Selecione o cargo" disabled={disableAll}>
								{cargoOptions.map((cargo) => (
									<Option value={cargo.value} key={cargo.value}>
										{cargo.label}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Col>
					<Col span={9} flex="1">
						<Form.List name="telefones">
							{(fields, { add, remove }) => (
								<>
									{fields.map((f, index) => (
										<div className="phone-input-with-plus" key={f.key}>
											<Form.Item
												{...f}
												label={!index && 'Telefones'}
												rules={[validateRequired(), validateTelephone()]}
											>
												<InputMask mask="(99) 99999-9999" placeholder="(00) 00000-0000" disabled={disableAll}>
													{(props) => <Input {...props} disabled={disableAll} />}
												</InputMask>
											</Form.Item>
											{fields.length < maxTelefones &&
												<IconButton onClick={add} disabled={disableAll}>
													<PlusCircle fill="#0076D6" />
												</IconButton>
											}
											{fields.length >= maxTelefones &&
												<IconButton onClick={() => remove(f.name)} disabled={disableAll}>
													<MinusCircle />
												</IconButton>
											}
										</div>
									))}
								</>
							)}
						</Form.List>
					</Col>
				</Row>
				<Form.Item name="isAdmin">
					<Checkbox onChange={onCheckBoxChange} checked={isAdmin} disabled={disableAll}>
						É um usuário administrador
					</Checkbox>
				</Form.Item>
				{loadData && (
					<>
						<Divider />
						<Form.Item label="Obras associadas">
							<Select
								className="select-tags-obras"
								disabled
								mode="tags"
								value={userData && userData.obras?.map((item) => item.obra?.nome)}
							/>
						</Form.Item>
					</>
				)}
			</Form>

			<DrawerFooter>
				<Form form={form} onFinish={handleSubmit} className="footer-form">
					<DrawerFooter.Actions>
						{mode === modes.EDIT || mode === modes.NEW ? (
							<>
								<Form.Item>
									<Button className="cancel-btn" shape="round" onClick={handleClose} disabled={disableAll}>
										Cancelar
									</Button>
								</Form.Item>
								<Form.Item>
									<Button className="submit-btn" type="primary" shape="round" htmlType="submit" disabled={disableAll}>
										{mode === modes.EDIT ? 'Editar' : 'Cadastrar'}
									</Button>
								</Form.Item>
							</>
						) : (
							<Button className="submit-btn" type="primary" shape="round" onClick={handleClose}>
								Ok
							</Button>
						)
						}
					</DrawerFooter.Actions>
				</Form>
			</DrawerFooter>
		</StyledDrawer>
	);
}

DrawerUser.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	visible: PropTypes.bool,
	loading: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mode: PropTypes.oneOf(['view', 'edit', 'new']),
	userData: PropTypes.shape({
		obras: PropTypes.arrayOf(PropTypes.shape({ nome: PropTypes.string })),
		telefones: PropTypes.arrayOf(PropTypes.shape({})),
	}),
};

DrawerUser.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	loading: false,
	mode: 'new',
	visible: false,
	userData: null,
	width: null,
};

export default DrawerUser;
