import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ExportSteps from './ExportSteps';
import ExportErrorViews from './ExportErrorViews';

// functions
import { _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import { sendSlack } from '../../../../components/_functions/_sendSlack';

const CriaObjetoSienge = ({
	setStep,
	setSiengeData,
	sienge_data,
	compra,
	cliente_id,
	setExportingError,
	closeFunc,
	itens,
	setForce,
	force,
	force_cotacao,
}) => {
	const [error, setError] = useState(null);
	const [item_nome, setItemNome] = useState('');

	function setAllErrors(err) {
		setError(err);
		setExportingError(true);
	}

	function checkErrors(err) {
		const message = ((
			err.response || {})
			.data || {})
			.message || '';

		// DONE Usuário no swi não existe
		const error_3 = message
			.indexOf('Usuário no swi não existe') !== -1;
		if (error_3) {
			setAllErrors(3);
			return;
		}

		// DONE SWI não foi encontrado
		const error_4 = message
			.indexOf('SWI não foi encontrado') !== -1;
		if (error_4) {
			setAllErrors(4);
			return;
		}

		// DONE Obra associada não existe
		const error_5 = message
			.indexOf('Obra associada não existe') !== -1;
		if (error_5) {
			setAllErrors(5);
			return;
		}

		// DONE Fornecedor não existe
		const error_6 = (
			message.indexOf('Fornecedor associado não existe') !== -1
			|| message.indexOf('Fornecedor integrado não existe') !== -1
		);
		if (error_6) {
			setAllErrors(6);
			return;
		}

		// DONE Não tem item integrado
		const error_7 = message
			.indexOf('não tem item integrado') !== -1;
		if (error_7) {
			const _item_nome = message
				.replace(/ValueError: Item: /g, '')
				.replace(/ não tem item integrado/g, '');
			setItemNome(_item_nome);
			setAllErrors(7);
			return;
		}

		// TODO (esse é erro 13, não 11) Cotação já criada no swi
		const error_11 = message
			.indexOf('Cotação já criada no swi') !== -1;
		if (error_11) {
			setAllErrors(11);
			return;
		}

		// DONE Erro não identificado
		setAllErrors(0);
		handleRequestErrors(err);
	}

	useEffect(() => {
		function criaObjeto() {
			const compra_id = compra.id || 0;
			const url = `/conaz/v2/integracoes_de_compras/${compra_id}`;
			const params = { swi: 1, cliente_id, force, force_cotacao };
			_post(url, params)
				.then(response => {
					const objeto_sienge = response.data || {};
					setSiengeData(objeto_sienge);
					setStep(4);
				}).catch(err => {
					checkErrors(err);
				});
		}

		// TEMP
		function enviaAlertSlack() {
			const is_prod = (window.location.href.indexOf('portal.conazweb.com.br') !== -1);
			const swi_cotacao_id = (sienge_data || {}).id || null;
			const primeira_exportacao = swi_cotacao_id === null;
			if (primeira_exportacao && is_prod) {
				const compra_id = compra.id || 0;
				const compra_email = compra.usuario_construtor.email || '';
				const message = `Exportação da compra *${compra_id} [${compra_email}]* iniciada`;
				sendSlack('#exportações-sienge', 'Exportação Sienge', message, ':gustavito:');
			}
		}

		criaObjeto();
		enviaAlertSlack();
	}, []);

	// DONE Usuário no sienge não existe
	if (error === 3) {
		return <ExportErrorViews error_type={3} />;
	}

	// DONE SWI não foi encontrado
	if (error === 4) {
		return <ExportErrorViews error_type={4} />;
	}

	// DONE Obra associada não existe
	if (error === 5) {
		return <ExportErrorViews error_type={5} />;
	}

	// DONE Fornecedor não existe
	if (error === 6) {
		return <ExportErrorViews error_type={6} />;
	}

	// DONE Não tem item integrado
	if (error === 7) {
		return <ExportErrorViews error_type={7} item_nome={item_nome} />;
	}

	// TODO Cotação já criada no Sienge
	if (error === 11) {
		// const cotacao_id = sienge_data.cotacao_id || 0;
		return (
			<ExportErrorViews
				error_type={11}
				itens={itens}
				closeFunc={closeFunc}
				setForce={setForce}
				setStep={setStep}
			/>
		);
	}

	// DONE Erro não identificado
	if (error === 0) {
		return <ExportErrorViews />;
	}

	return 	<ExportSteps current={1} />;
};

CriaObjetoSienge.propTypes = {
	setStep: PropTypes.func.isRequired,
	setSiengeData: PropTypes.func.isRequired,
	sienge_data: PropTypes.object.isRequired,
	compra: PropTypes.object.isRequired,
	cliente_id: PropTypes.number.isRequired,
	setExportingError: PropTypes.func.isRequired,
	closeFunc: PropTypes.func.isRequired,
	itens: PropTypes.object.isRequired,
	setForce: PropTypes.func.isRequired,
	force: PropTypes.bool.isRequired,
	force_cotacao: PropTypes.bool.isRequired,
};

export default CSSModules(CriaObjetoSienge, styles, { allowMultiple: true });
