import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';

// Actions
import { updateComprasRaw } from '../../../../store/compras/actions';

// Function
import { _get } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

const UsarObsAnterior = ({ compra }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(0);
	const [error_type, setErrorType] = useState(0);

	function clearErrors() {
		setErrorType(0);
	}

	function handleError({ type, error }) {
		setErrorType(type);
		setLoading(2);
		if (type === 1) {
			handleRequestErrors(error);
		}
	}

	function buildObservacao(result) {
		const observacoes_ja_preenchidas = (compra || {}).observacoes || '';
		const observacao_nova = (result || {}).observacao || '';
		const space_between = observacoes_ja_preenchidas !== '' ? ' ' : '';
		return `${observacoes_ja_preenchidas}${space_between}${observacao_nova}`;
	}

	function appendLastObsToCompra(result) {
		const observacoes = buildObservacao(result);
		const nova_compra = {
			...compra,
			observacoes,
		};
		dispatch(updateComprasRaw({ nova_compra }));
		setLoading(2);
	}

	function checkIfObsExist(response) {
		const result = ((response || {}).data || {}).result || null;
		const message = ((response || {}).data || {}).message || '';
		if (message === 'Observação passada não encontrada') {
			handleError({ type: 2, error: message });
			return;
		}
		appendLastObsToCompra(result);
	}

	function fetchLastObs() {
		setLoading(1);
		const obra_id = (compra.obra || {}).id || 0;
		const url = `/conaz/v2/compras/observacoes_passadas?obra_id=${obra_id}`;
		_get(url).then(response => (
			checkIfObsExist(response)
		)).catch(error => (
			handleError({ type: 1, error })
		));
	}

	// ==================================== render

	const is_loading = loading === 1;
	const onClick = is_loading
		? null
		: fetchLastObs;
	const button_class = is_loading
		? 'usar-obs-button loading'
		: 'usar-obs-button';
	const button_text = is_loading
		? <>Buscando observação...</>
		: <><i className="fa fa-plus" aria-hidden="true" /> Usar observação anterior</>;

	// errors
	const has_error = error_type === 1 || error_type === 2;
	const error_message = error_type === 2
		? 'Você não possui observações recentes para compras dessa obra.'
		: 'Desculpe, não conseguimos buscar observações recentes. Tente novamente em alguns instantes.';

	return (
		<>
			{has_error && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={clearErrors}>
						<ModalConfirm
							title={error_message}
							message={<></>}
							button_type="clear-conaz"
							button_text="Entendi!"
							cancelar_button={false}
						/>
					</ModalWrapperPortal>
				</ModalPortal>
			)}
			<button type="button" styleName={button_class} onClick={onClick}>
				{button_text}
			</button>
		</>
	);
};

UsarObsAnterior.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(UsarObsAnterior, styles, { allowMultiple: true });
