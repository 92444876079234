import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import Loading from '../../../../components/_parts/_loadings/Loading/Loading';

const InitialLoading = () => (
	<div styleName="initial-loading-wrapper">
		<div><Loading /></div>
	</div>
);

export default CSSModules(InitialLoading, styles, { allowMultiple: true });
