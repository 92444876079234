import update from 'immutability-helper';
import cotacao from './store';
import {
	UPDATE_COTACAO,
	UPDATE_COTACAO_MULTI,
	REMOVE_ITEM,
	UNDO_REMOVE_ITEM,
	CLEAR_SEARCH,
	UPDATE_ITEM,
	UPDATE_ITEM_PROPRIEDADES,
	UPDATE_ITEM_VALOR_PREENCHIDO,
	SAVE_ITEMS,
	UPDATE_ITEM_CARACTERISTICA,
} from './types';

const reducer = (state = cotacao, action) => {
	let itens_new = [];
	let itens_new_pre = [];
	let item_removed = [];
	let i = 0;
	let deletado_relacionado_parent_child = null;

	switch (action.type) {
	case UPDATE_COTACAO:
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});
		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});
		default:
			return state;
		}

	case UPDATE_COTACAO_MULTI: {
		const all_fields = Object.keys(action.fields_n_values)
			.reduce((result, current) => ({
				...result,
				[current]: { $set: action.fields_n_values[current] },
			}), {});
		return update(state, all_fields);
	}

	case REMOVE_ITEM:
		i = 0;
		item_removed = [state.itens[action.item]];
		itens_new = state.itens.map(item => {
			const item_new = { ...item };
			if (item_new.is_relacionado.length > 0) {
				const is_relacionado_filter = item_new.is_relacionado.filter(key => key !== action.item);
				const is_relacionado_new = is_relacionado_filter.map(key => {
					if (key > action.item) return key - 1;
					return key;
				});
				if (is_relacionado_new.length === 0) item_new.item_relacionado = false;
				if (item_new.is_relacionado.length !== is_relacionado_new.length) {
					deletado_relacionado_parent_child = [action.item, item_new.key];
				}
				item_new.is_relacionado = [...is_relacionado_new];
			}
			if (item_new.key !== action.item) {
				item_new.key = i;
				i++;
			}
			return item_new;
		});
		return update(state, {
			itens: { $set: [
				...itens_new.slice(0, action.item),
				...itens_new.slice(action.item + 1),
			] },
			itens_deletados: { $set: item_removed },
			deletado_relacionado_parent_child: { $set: deletado_relacionado_parent_child },
		});

	case UNDO_REMOVE_ITEM:
		i = 0;
		itens_new_pre = [
			...state.itens.slice(0, action.item),
			state.itens_deletados[0],
			...state.itens.slice(action.item),
		];
		itens_new = itens_new_pre.map(item => {
			const item_new = { ...item };
			item_new.key = i;
			const is_relacionado_new = item_new.is_relacionado.map(key => {
				if (key > action.item) return key + 1;
				return key;
			});
			if (state.deletado_relacionado_parent_child !== null && i === state.deletado_relacionado_parent_child[1]) {
				is_relacionado_new.push(state.deletado_relacionado_parent_child[0]);
			}
			item_new.is_relacionado = [...is_relacionado_new];
			if (is_relacionado_new.length > 0) item_new.item_relacionado = true;
			i++;
			return item_new;
		});
		return update(state, {
			itens: { $set: itens_new },
			itens_deletados: { $set: [] },
			deletado_relacionado_parent_child: { $set: null },
		});

	case UPDATE_ITEM:
		itens_new = [...state.itens];
		itens_new[action.item_key][action.field] = action.value;
		return update(state, { itens: { $set: itens_new } });

	case UPDATE_ITEM_CARACTERISTICA: {
		const caracteristicas_preenchidas = {
			...state.itens[action.item_key].item_preenchido.caracteristicas_preenchidas,
			[action.ca_id]: {
				...state.itens[action.item_key].item_preenchido.caracteristicas_preenchidas[action.ca_id],
				opcoes_preenchidas: action.value,
			},
		};
		const item = {
			...state.itens[action.item_key],
			item_preenchido: {
				...state.itens[action.item_key].item_preenchido,
				caracteristicas_preenchidas,
			},
		};
		const itens = [
			...state.itens.slice(0, action.item_key),
			item,
			...state.itens.slice(action.item_key + 1),
		];
		return update(state, { itens: { $set: itens } });
	}

	case UPDATE_ITEM_PROPRIEDADES:
		itens_new = [...state.itens];
		itens_new[action.item_key].propriedades[action.resposta_key][action.tipo_campo] = action.value;
		return update(state, { itens: { $set: itens_new } });

	case UPDATE_ITEM_VALOR_PREENCHIDO:
		itens_new = state.itens.map(item => {
			const item_new = { ...item };
			if (item_new.key === action.item_key) {
				item_new.propriedades[action.resposta_key].valor_preenchido[action.tipo_campo] = action.value;
			}
			return item_new;
		});
		return update(state, { itens: { $set: itens_new } });

	case CLEAR_SEARCH:
		return update(state, { item_added: { $set: false } });

	case SAVE_ITEMS:
		return update(state, { salvando: { $set: action.status } });

	default:
		return state;
	}
};

export default reducer;
