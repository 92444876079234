/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// Components
import { Form, Row, Col, Select, Input, Divider, Upload, InputNumber, message } from 'antd';
import EnderecoForm from 'components/EnderecoForm';

import { validations } from 'ambar-ui';
import { getLocalstorageData } from 'components/_functions/_getLocalstorageData';

// Redux HOC
import { connect } from 'react-redux';

import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

import { ReactComponent as Clip } from '../../icons/clip.svg';

// opções dos selects
import tiposProjetos from './selectOptions/TiposProjetos';
import padraoAcabamento from './selectOptions/padraoAcabamento';
import metodoConstrutivo from './selectOptions/metodoConstrutivo';
import faseAtual from './selectOptions/faseAtual';

const { validateRequired } = validations;

function CreateObraForm({ mode, form, enableFields, disableAll, associaObra, handleClose, ...props }) {
	const { obrasDrawer } = props;
	const getValue = (e) => {
		if (Array.isArray(e)) return e;
		return e && (e.fileList);
	};

	const [capturaIdPhotoUpload, setCapturaIdPhotoUpload] = useState(null);

	const handleFile = async (e) => {
		const checkMode = mode === 'edit' ? associaObra.obra.id : obrasDrawer.dataToDrawer.id;
		const base_url = getLocalstorageData('environment', 'base_url');
		const token = getLocalstorageData('user', 'token');

		const { file } = e;
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (file && file.status !== 'removed') {
			if (!isJpgOrPng) {
				message.error('Somente arquivos .jpeg, .jpg ou .png');
			}

			const isLt2M = file.size / 1024 / 1024 < 3;
			if (!isLt2M) {
				message.error('Arquivo deve ser menor que 3mb!');
			}

			const url = `${base_url}/conaz/v2/obras/${checkMode}/anexos`;
			const data = new FormData();
			data.append('files', file, file.name);

			axios.defaults.headers.common.token = token;
			axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
			axios.post(url, data).then((res) => {
				if (res.data) {
					setCapturaIdPhotoUpload(res.data[0].id);
				}
			}).catch({});
		}
		return false;
	};

	const removePhoto = async (e) => {
		const base_url = getLocalstorageData('environment', 'base_url');
		const token = getLocalstorageData('user', 'token');
		const urlDeletePhoto = `${base_url}/conaz/v2/anexos_obra/${e.id ? e.id : capturaIdPhotoUpload}`;

		axios.defaults.headers.common.token = token;
		axios.delete(urlDeletePhoto).then(() => {
		}).catch(err => {
			if (err.response.data.message) {
				message.error(`Erro ao apagar foto. ${err.response.data.message}`);
				handleClose();
			} else {
				message.error('Erro ao apagar foto. Aguarde uns minutos e tente novamente!');
			}
		});
	};

	useEffect(() => {
	}, [mode, getValue]);

	return (
		<>
			<Form.Item noStyle name="obra_id"> <Input hidden /> </Form.Item>
			<Row gutter={24}>
				<Col span={8}>
					<Form.Item name="obra_nome" label="Nome do projeto" rules={[validateRequired()]}>
						<Input disabled={disableAll} />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name="tipo_projeto" label="Tipo de projeto" rules={[validateRequired()]}>
						<Select placeholder="Selecione o tipo" disabled={disableAll}>
							{tiposProjetos.map((tp) => <Select.Option key={tp} value={tp}>{tp}</Select.Option>)}
						</Select>
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name="fase_atual" label="Fase atual da Obra">
						<Select placeholder="Selecione a fase atual da obra" disabled={disableAll}>
							{faseAtual.map((mc) => <Select.Option key={mc} value={mc}>{mc}</Select.Option>)}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={24}>
				<Col span={6}>
					<Form.Item name="metragem" label="Área total construída (m²)" rules={[validateRequired()]}>
						<InputNumber type="number" min={0} className="input-number-width" disabled={disableAll} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="quantidade_uh" label="Quantidade UHs">
						<InputNumber type="number" className="input-number-width" disabled={disableAll} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="padrao_acabamento" label="Padrão de Acabamento">
						<Select placeholder="Selecione o padrão" disabled={disableAll}>
							{padraoAcabamento.map((pa) => <Select.Option key={pa} value={pa}>{pa}</Select.Option>)}
						</Select>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="metodo_construtivo" label="Método Construtivo">
						<Select placeholder="Selecione o metodo Construtivo" disabled={disableAll}>
							{metodoConstrutivo.map((mc) => <Select.Option key={mc} value={mc}>{mc}</Select.Option>)}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Divider className="divider" />
			<EnderecoForm form={form} disableAll={disableAll} enableFields={enableFields} {...props} />
			<Divider className="divider" />

			<Form.Item name="fotos_obra" label="Imagens do projeto" getValueFromEvent={getValue} valuePropName="fileList">
				<Upload.Dragger
					name="files"
					className="line-uploader"
					beforeUpload={() => false}
					onChange={(obrasDrawer.drawerMode === 'new') && mode !== 'edit' ? null : handleFile}
					disabled={disableAll}
					onRemove={(obrasDrawer.drawerMode === 'new') && mode !== 'edit' ? null : removePhoto}
				>
					<Clip /> &nbsp; Clique para anexar ou arraste seus arquivos e imagens aqui
				</Upload.Dragger>
			</Form.Item>
		</>
	);
}

CreateObraForm.propTypes = {
	mode: PropTypes.string,
	form: PropTypes.shape({
		getFieldValue: PropTypes.func,
	}),
	disableAll: PropTypes.bool,
	enableFields: PropTypes.bool,
	obrasDrawer: PropTypes.shape(),
	associaObra: PropTypes.shape(),
	handleClose: PropTypes.func,
};

CreateObraForm.defaultProps = {
	mode: null,
	form: {},
	disableAll: false,
	enableFields: false,
	obrasDrawer: {},
	associaObra: {},
	handleClose: {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(CreateObraForm));
