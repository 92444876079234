import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Function
import { splitName } from './utils';
import { withToggle } from '../../../_hocs/hocs';

// Components
import InputSelectGeral from '../../_inputs/InputSelectGeral/InputSelectGeral';
import FileError from './FileError';
import ButtonIcon from '../../../../pages/CompraV3/subcomponents/Anexos/elements/ButtonIcon';
import TooltipWrapper from '../../_tooltips/TooltipWrapper.js/TooltipWrapper';

class FileForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tipo_opcoes: [
				{ id: 1, valor: 'Nota Fiscal' },
				{ id: 2, valor: 'Boleto' },
			],
			outro_tipo: false,
			outro_tipo_value: '',
		};
	}

	UNSAFE_componentWillMount() {
		const { tipo_opcoes } = this.state;
		const { arquivo } = this.props;
		const outra_opcao_selecionada = (
			tipo_opcoes.filter(x => x.valor === arquivo.tipo).length === 0 &&
			arquivo.tipo !== ''
		);
		if (outra_opcao_selecionada) {
			this.setState({ outro_tipo: true, outro_tipo_value: arquivo.tipo });
		}
	}

	deleteFile = () => {
		const { arquivo, arquivos_para_enviar, updateFiles } = this.props;
		const _arquivos = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp)
			.filter(id_temp => Number(id_temp) !== arquivo.id_temp);
		const arquivos_by_id = {};
		_arquivos.forEach(current => {
			arquivos_by_id[current] = arquivos_para_enviar[current];
		});
		updateFiles('arquivos_para_enviar', arquivos_by_id);
	}

	handleTipo = (field, value) => {
		const { tipo_opcoes } = this.state;
		const { updateFiles } = this.props;
		/**
		 * Busca tipo string (Boleto) ao invés de id
		 * para enviar para o backend
		 */
		const filtra_tipo_selecionado = tipo_opcoes.filter(x => x.id === value) || [];
		const tipo = (filtra_tipo_selecionado[0] || {}).valor || '';
		/**
		 * Atualizar arquivos
		 */
		const arquivo = {
			...this.props.arquivo,
			tipo,
		};
		const arquivos_para_enviar = {
			...this.props.arquivos_para_enviar,
			[arquivo.id_temp]: arquivo,
		};
		updateFiles('arquivos_para_enviar', arquivos_para_enviar);
	}

	handleOutroTipo = (e) => {
		const outro_tipo_value = e.target.value.toString();
		this.setState({ outro_tipo_value });
		const { updateFiles } = this.props;
		/**
		 * Atualizar arquivos
		 */
		const arquivo = {
			...this.props.arquivo,
			tipo: outro_tipo_value,
		};
		const arquivos_para_enviar = {
			...this.props.arquivos_para_enviar,
			[arquivo.id_temp]: arquivo,
		};
		updateFiles('arquivos_para_enviar', arquivos_para_enviar);
	}

	switchOutroTipo = () => {
		const outro_tipo = !this.state.outro_tipo;
		this.setState({ outro_tipo });
		if (outro_tipo) {
			const { arquivo } = this.props;
			const input_id = `outro_tipo_value_${arquivo.id_temp}`;
			setTimeout(() => document.getElementById(input_id).focus(), 60);
		} else {
			this.handleOutroTipo({
				target: {
					value: '',
				},
			});
		}
	}

	checkOutroTipo = () => {
		const { outro_tipo, outro_tipo_value } = this.state;
		if (outro_tipo && outro_tipo_value === '') {
			this.switchOutroTipo();
		}
	}

	handleVisivelParaFornecedor = bool => {
		const { updateFiles, toggle } = this.props;
		const arquivo = {
			...this.props.arquivo,
			visivel_pro_fornecedor: bool,
		};
		const arquivos_para_enviar = {
			...this.props.arquivos_para_enviar,
			[arquivo.id_temp]: arquivo,
		};
		updateFiles('arquivos_para_enviar', arquivos_para_enviar);
		toggle();
	}

	render() {
		const { tipo_opcoes, outro_tipo, outro_tipo_value } = this.state;
		const { arquivo, error, isVisible, updateErrorSize } = this.props;
		const input_id = `outro_tipo_value_${arquivo.id_temp}`;
		/**
		 * Arquivo maior que o permitido
		 */
		if (arquivo.size_error) {
			return (
				<FileError
					updateErrorSize={updateErrorSize}
					arquivo={arquivo}
					deleteFile={this.deleteFile} />
			);
		}

		return (
			<div styleName="file-wrapper form">
				<div styleName="icon-name">
					<i className="fa fa-file-o" aria-hidden="true" style={{ color: '#000' }} />
					<div styleName="file-name">
						{splitName(arquivo.nome, 15)}
					</div>
				</div>
				<div styleName="file-type">
					<div styleName="select-wrapper">
						<label styleName="desktop">
							Tipo
							<TooltipWrapper
								otherCondition={error && arquivo.tipo === ''}
								tooltipMessage="O tipo dos arquivos é obrigatorio"
								positionTooltip="bottom"
								styleTooltip={{
									backgroundColor: '#e86243',
									borderRadius: '5px',
								}}
								styleArrow={{
									borderBottomColor: '#e86243',
								}}
								stylePosition={{
									arrow_left: '125px',
									margin_top: '0px',
									margin_left: '-98px',
									width: '200px',
								}}>
								<span style={{ color: '#ff0000' }}>*</span>
							</TooltipWrapper>
						</label>
						<label styleName="mobile">
							<span>Tipo</span>
							<TooltipWrapper
								otherCondition={error && arquivo.tipo === ''}
								tooltipMessage="O tipo dos arquivos é obrigatorio"
								positionTooltip="bottom"
								styleTooltip={{
									position: 'absolute',
									top: '-22px',
									backgroundColor: '#e86243',
									borderRadius: '5px',
								}}
								styleArrow={{
									transform: 'rotate(-180deg)',
									borderBottomColor: '#e86243',
								}}
								stylePosition={{
									arrow_left: '33px',
									margin_top: '-20px',
									// margin_left: '-178px',
									width: '200px',
								}}>
								<span style={{ color: '#ff0000' }}>*</span>
							</TooltipWrapper>
						</label>
						{
							!outro_tipo ?
								(
									<div styleName="button-wrapper">
										<InputSelectGeral
											field_id=""
											field="tipo"
											error={error}
											tipo={arquivo.tipo}
											default_value={arquivo.tipo}
											opcoes={tipo_opcoes}
											updateFunction={this.handleTipo}
											handleOutro={this.switchOutroTipo} />
									</div>
								)
								:
								(
									<div styleName="button-wrapper">
										<input
											id={input_id}
											styleName={(error || arquivo.tipo === '') && 'file-wrapper form-error'}
											type="text"
											maxLength={20}
											className="form-control input-md"
											value={outro_tipo_value}
											onChange={this.handleOutroTipo}
											onBlur={this.checkOutroTipo} />
									</div>
								)}
					</div>
				</div>
				<div styleName="icons-wrapper">
					<ButtonIcon
						icon="fa fa-eye-slash"
						styleToggleIcon={{ color: '#000' }}
						toggleIconName="fa fa-eye"
						tooltipMessage="Desabilitar visualização para o fornecedor"
						func={() => this.handleVisivelParaFornecedor(isVisible)}
						styleTooltip={{
							borderRadius: '5px',
							position: 'relative',
						}}
						positionTooltip="top"
						stylePosition={{
							arrow_left: '125px',
							margin_top: '-36px',
							margin_left: '-36px',
							width: '200px',

						}} />
					<ButtonIcon icon="fa fa-trash" func={() => this.deleteFile()} />
				</div>
			</div>
		);
	}
}

FileForm.propTypes = {
	updateErrorSize: PropTypes.shape({
		updateErrorSize: PropTypes.func.isRequired,
		modalErrorFeedback: PropTypes.bool.isRequired,
	}).isRequired,
	arquivo: PropTypes.shape({
		id: PropTypes.number.isRequired,
		id_temp: PropTypes.number,
		nome: PropTypes.string.isRequired,
		file: PropTypes.object,
		tipo: PropTypes.string.isRequired,
		tamanho: PropTypes.number,
		success: PropTypes.number,
		size_error: PropTypes.bool,
		visivel_pro_fornecedor: PropTypes.bool,
		error_message: PropTypes.string,
	}).isRequired,
	error: PropTypes.bool.isRequired,
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	arquivos_para_enviar: PropTypes.objectOf(PropTypes.shape({
		id: PropTypes.number,
		id_temp: PropTypes.number,
		nome: PropTypes.string,
		file: PropTypes.object,
		tipo: PropTypes.string,
		tamanho: PropTypes.number,
		success: PropTypes.number,
		size_error: PropTypes.bool,
		error_message: PropTypes.string,
	})),
	updateFiles: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	// idFile: PropTypes.number.isRequired,
};

FileForm.defaultProps = {
	arquivos_para_enviar: {},
};

export default withToggle(CSSModules(FileForm, styles, { allowMultiple: true }));
