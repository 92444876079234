import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { detailedmapDispatchToProps } from '../../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../../store/withStore';

// CSS
import styles from './style.module.scss';

// Components
import Loading from '../../../../components/_parts/_loadings/Loading/Loading';
import Alert2 from '../../../../components/_parts/Alerts/Alert2';
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { editMomentDateFormat } from '../../../../components/_functions/_editDateFormat';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import _getUserAlcadaCompra from '../../../../components/_functions/_getUserAlcadaCompra';

class AprovarActions extends Component {
	state = {
		aprovando: false,
		cancelando: false,
		editando: false,
		alcada: {},
		error_message: '',
	};

	UNSAFE_componentWillMount() {
		const { compra, user } = this.props;
		const alcada = _getUserAlcadaCompra(user, compra.obra.id);
		this.setState({ alcada });
	}

	editarCompra = () => {
		const { editando } = this.state;
		this.setState({ editando: !editando });
		const {
			compra,
			updateUi,
			redirectAfterConcluir,
		} = this.props;
		_post(`/conaz/v2/compras/${compra.id}/iniciar_edicao`, {}).then(() => {
			this.setState({ editando: !editando });
			updateUi(['changes', 'compras'], true);
			redirectAfterConcluir(compra.id);
		}).catch(error => {
			this.setState({ editando: 3 });
			handleRequestErrors(error);
		});
	}

	handleModal = (
		boolean_type,
		boolean_value,
		message,
		color,
		func,
	) => {
		const { updateComprasRaw } = this.props;

		return (
			boolean_type === boolean_value && (
				<Alert2
					{...{ color }}
					mensagem={message}
					updateComprasRaw={updateComprasRaw}
					externalFunction={func} />
			)
		);
	}

	handleError = (aprovando, editando) => (
		<>
			{
				this.handleModal(
					aprovando,
					2,
					'Compra aprovada com sucesso!',
					'green',
				)
			}
			{
				this.handleModal(
					aprovando,
					3,
					'Algo deu errado, por favor tente novamente mais tarde!',
					'red',
					() => this.setState({ aprovando: false }),
				)
			}
			{
				this.handleModal(
					aprovando,
					4,
					'Você não possui alçada suficiente para aprovar essa compra!',
					'red',
					() => this.setState({ aprovando: false }),
				)
			}
			{
				this.handleModal(
					editando,
					3,
					'Algo deu errado, por favor tente novamente mais tarde!',
					'red',
					() => this.setState({ editando: false }),
				)
			}
		</>
	);

	aprovarCompraForce = () => {
		this.aprovarCompra(true);
	}

	aprovarCompraNormal = () => {
		this.aprovarCompra();
	}

	aprovarCompra = (force = false) => {
		const {
			compra,
			redirectAfterConcluir,
			updateComprasRaw,
		} = this.props;
		const { alcada } = this.state;
		const { valor_alcada_compra, compra_ilimitada } = alcada;
		const nao_tem_alcada_suficiente = (
			(compra.valor_total > valor_alcada_compra && valor_alcada_compra !== -1)
			&& compra_ilimitada === false);
		this.setState({ aprovando: 1 });
		const url = `/conaz/v2/compras/${compra.id}/concluir`;
		if (nao_tem_alcada_suficiente) {
			this.setState({ aprovando: 4 });
			return;
		}
		const params = force ? { force, pular_aprovacao: true } : { pular_aprovacao: true };
		_post(url, params)
			.then(() => {
				const alert = {
					color: 'green',
					message: 'Compra aprovada com sucesso!',
				};
				updateComprasRaw({ alert });
				redirectAfterConcluir(compra.id);
			})
			.catch(error => {
				handleRequestErrors(error);
				const _message = (((error || {}).response || {}).data || {}).message;
				const precisa_analise =
					_message === 'Compra necessita análise';
				if (precisa_analise) {
					this.setState({ aprovando: 5, error_message: _message });
				} else {
					this.setState({ aprovando: 3 });
				}
			});
	}

	hasAlcada = () => {
		const { alcada } = this.state;
		if (
			alcada.compra_ilimitada === true ||
			alcada.valor_alcada_compra !== 0 ||
			(alcada.compra_ilimitada === false && alcada.valor_alcada_compra > 0)
		) return true;
		return false;
	}

	closeModaEdicao = () => {
		this.setState({ aprovando: false });
	}

	handleTemAlcada = (alcada, aprovarBool, cancelarBool, editarBoll) => {
		if (alcada) {
			return (
				<>
					{aprovarBool === 1 ? (
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button loading circle aprove">
							<Loading style={{ position: 'relative', left: '-8px', top: '-2px' }} />
						</button>
					) : (
						<button
							onClick={this.aprovarCompraNormal}
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button">
							<b>Aprovar</b>
						</button>
					)}
					{cancelarBool === 2 ? (
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							onClick={() => this.setState({ cancelando: 1 })}
							styleName="alert-button loading circle">
							<Loading style={{ position: 'relative', left: '-8px', top: '-2px' }} />
						</button>
					) : (
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button"
							onClick={this.cancelarAprovacao}>
							<b>Cancelar</b>
						</button>
					)}
					{editarBoll ? (
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							onClick={this.editarCompra}
							styleName="alert-button loading circle editing">
							<Loading style={{ position: 'relative', left: '-12px', top: '-6px' }} />
						</button>
					) : (
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button"
							onClick={this.editarCompra}>
							<b>Editar</b>
						</button>
					)}
				</>
			);
		}
		return null;
	}

	cancelarAprovacao = () => {
		const { cancelaCompra } = this.props;
		this.setState({ cancelando: 2 });
		cancelaCompra();
		this.setState({ cancelando: false });
	}

	render() {
		const {
			aprovando,
			cancelando,
			editando,
			alcada: {
				valor_alcada_compra,
				compra_ilimitada,
			},
			error_message,
		} = this.state;
		const { compra } = this.props;
		const { aprovacao_pedida_por, aprovacao_solicitada_em } = compra;
		const quem_pediu = aprovacao_pedida_por.nome || '';
		const data_solicitacao = aprovacao_solicitada_em || '';
		const descricao =
			data_solicitacao !== '' ? (
				<span>
					enviado em {editMomentDateFormat(data_solicitacao, 'default')}
				</span>
			) : (
				null
			);
		const nao_tem_alcada_suficiente = (
			compra.valor_total > valor_alcada_compra
			&& valor_alcada_compra !== -1
			&& compra_ilimitada === false
		);
		const tem_alcada = this.hasAlcada();
		const necessita_analise = aprovando === 5;
		const error_message_body = (
			<p>Esta compra precisará passar por uma análise da Conaz (<a href="https://conaz.zendesk.com/hc/pt-br/categories/115000062953-Construtor" target="_blank" rel="noopener noreferrer"><i className="fa fa-question-circle" /></a>) porque possui itens ou características não associadas (destacadas com o símbolo <i className="fa fa-exclamation-circle" />). Isso pode atrasar o envio aos fornecedores.<br /><br />  Deseja enviar mesmo assim?</p>
		);
		if (!tem_alcada || nao_tem_alcada_suficiente) return null;
		return (
			<>
				{this.handleError(aprovando, editando)}
				<div styleName="alerts-messages-wrapper flex">
					<div styleName="am-text flex">
						<p><b>Aprovar o pedido de {quem_pediu}</b></p>
						{descricao}
					</div>
					<div styleName="am-buttons row">
						{this.handleTemAlcada(tem_alcada, aprovando, cancelando, editando)}
					</div>
				</div>
				{necessita_analise && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.closeModaEdicao}>
							<ModalConfirm
								title={error_message}
								message={error_message_body}
								confirmFunc={this.aprovarCompraForce}
								button_type="secondary-conaz"
								button_text="Aprovar" />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
			</>
		);
	}
}

AprovarActions.propTypes = {
	compra: PropTypes.object.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	cancelaCompra: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => detailedmapDispatchToProps(dispatch, ['updateCompras', 'updateUi']);
export default compose(
	withStore,
	connect(null, mapDispatchToProps),
	withRouter,
)(CSSModules(AprovarActions, styles, { allowMultiple: true }));
