import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const CompraRowAvaliacaoButton = ({
	handleEvaluation,
	location,
}) => {
	const button_class = location === 'compra'
		? 'relative-button'
		: 'absolute-button';

	const [buttonAvaliarEntrega, setButtonAvaliarEntrega] = useState(window.innerWidth < 700);

	const _resize = () => {
		setButtonAvaliarEntrega(window.innerWidth < 700);
	};

	useEffect(() => {
		window.addEventListener('resize', _resize);
	}, [buttonAvaliarEntrega]);

	return (
		<button
			type="button"
			className="btn btn-primary-conaz round-border"
			styleName={button_class}
			style={buttonAvaliarEntrega ? { right: '20px', top: '50px' } : { right: '20px', padding: '6px 30px 10px' }}
			onClick={handleEvaluation}
		>
			Avaliar entrega
		</button>
	);
};

CompraRowAvaliacaoButton.propTypes = {
	handleEvaluation: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowAvaliacaoButton, styles, { allowMultiple: true });
