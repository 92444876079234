import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Components
import MultipleRCOption from './MultipleRCOption';

class InputMultipleRCs extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_by_id: {},
			opcoes_all_ids: [],
			opcoes_selecionadas: [],
		};
		this.handleEdition = this.handleEdition.bind(this);
		// this.selectAll = this.selectAll.bind(this);
		// this.saveToRedux = this.saveToRedux.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { opcoes_de_compra } = this.props;
		this.setInitialValues(opcoes_de_compra);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { opcoes_de_compra } = nextProps;
		this.setInitialValues(opcoes_de_compra);
	}

	setInitialValues(opcoes_de_compra) {
		const { all_ids, by_id } = opcoes_de_compra;
		/**
		 * Filtra apenas os RCs, remove as combinações
		 */
		const apenas_rcs = all_ids.filter(oc_id => by_id[oc_id].rcs_analise.length === 1);

		const opcoes_by_id = apenas_rcs.reduce((result, current) => ({
			...result,
			[current]: {
				...by_id[current],
			},
		}), {});

		this.setState({
			opcoes_by_id,
			opcoes_all_ids: apenas_rcs,
		});
	}

	handleEdition(id) {
		const is_selected = this.state.opcoes_selecionadas.filter(x => x === Number(id)).length > 0;
		const opcoes_selecionadas = !is_selected
			? [...this.state.opcoes_selecionadas, Number(id)].filter((el, i, a) => i === a.indexOf(el))
			: this.state.opcoes_selecionadas.filter(x => x !== Number(id));
		this.setState({ opcoes_selecionadas });
		this.props.selectOC(opcoes_selecionadas);
	}

	render() {
		const { opcoes_by_id, opcoes_all_ids, opcoes_selecionadas } = this.state;
		// const checkall_selected = opcoes_selecionadas.length !== 0 && opcoes_selecionadas.length === opcoes_all_ids.length;
		// const checkall_button_class = checkall_selected
		// 	? 'checkbox-button checked'
		// 	: 'checkbox-button';

		return (
			<div styleName="fornecedores-list" id="input-multiple-rcs">
				{/*
				<button type="button" onClick={this.selectAll} styleName={checkall_button_class}>
					<div styleName="checkbox-icon">
						{checkall_selected && <i className="fa fa-check" aria-hidden="true" />}
					</div>
					Todos
				</button>
				<hr style={{ margin: '11px 0 17px' }} />
				*/}
				{opcoes_all_ids.map(opcao_id => {
					const selected = opcoes_selecionadas.filter(id => id === opcao_id).length > 0;

					return (
						<MultipleRCOption
							key={opcao_id}
							handleEdition={this.handleEdition}
							option={opcoes_by_id[opcao_id]}
							selected={selected} />
					);
				})}
			</div>
		);
	}
}

InputMultipleRCs.propTypes = {
	opcoes_de_compra: PropTypes.object.isRequired,
	selectOC: PropTypes.func.isRequired,
};

export default CSSModules(InputMultipleRCs, styles, { allowMultiple: true });
