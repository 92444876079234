// import { connect } from 'react-redux';
// import * as actions from '../../actions/index';

const getUserAlcadaCompra = (user, compra_obra_id) => {
	const obra_in_user = user.obras_by_id[compra_obra_id] || {};
	const obra_exist = obra_in_user.id !== undefined;

	const compra_ilimitada = obra_exist
		? obra_in_user.compra_ilimitada || false
		: false;
	const valor_alcada_compra = obra_exist
		? obra_in_user.valor_alcada_compra || 0
		: 0;
	const success = obra_exist;

	return {
		compra_ilimitada,
		valor_alcada_compra,
		success,
	};
};


// const mapStateToProps = props => ({
// 	user: props.user,
// });

// const mapDispatchToProps = dispatch => ({
// 	updateItensRaw: (fields_n_values) => dispatch(actions.updateItensRaw(fields_n_values)),
// });

// export const getUserAlcadaCompra = connect(mapStateToProps, mapDispatchToProps)(_getUserAlcadaCompra);
export default getUserAlcadaCompra;
