import { _post } from './_requests';

export const sendSlack = (
	channel = '',
	username = '',
	text = '',
	icon_emoji = '',
) => {
	_post('/conaz/legacy/send_slack', {
		channel,
		username,
		text,
		icon_emoji,
	});
};
