import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { _put } from '../../../../_functions/_requests';

class IETUpdateUnidade extends Component {
	state = {
		atualizando: false,
	};

	atualiza = () => {
		this.setState({ atualizando: true });
		const {
			item,
			opcoes,
			unidade_to_save,
		} = this.props;
		const { id, nome } = (item.item_preenchido || {}).item || {};
		const unidades = [...opcoes, unidade_to_save].filter(
			(el, i, a) => i === a.indexOf(el),
		);
		const url = `/conaz/v2/itens/${id}`;
		_put(url, { nome, unidades }).then(() => {
			this.addItemToRedux(unidades);
		}).catch(error => {
			alert(error); // eslint-disable-line
			this.setState({ atualizando: false });
		});
	}

	addItemToRedux(unidades) {
		const {
			item,
			unidade_to_save,
			updateFullItemById,
			autoSave,
			triagemZeraOutraUnidade,
		} = this.props;
		const _item = {
			...item,
			item_preenchido: {
				...item.item_preenchido,
				item: {
					...item.item_preenchido.item,
					unidades,
				},
			},
			unidade: unidade_to_save,
		};
		updateFullItemById(item.id, _item);
		autoSave(item.id);
		triagemZeraOutraUnidade(true);
		this.setState({ atualizando: false });
	}

	render() {
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		if (!is_triagem) return null;

		const { atualizando } = this.state;

		if (!atualizando) {
			return (
				<>
					<button
						type="button"
						className="close"
						styleName="update-unidade-button"
						onClick={this.atualiza}>
						<i className="fa fa-check" aria-hidden="true" />
					</button>
				</>
			);
		}
		return (
			<button
				type="button"
				className="close"
				styleName="update-unidade-button"
				style={{ opacity: '.3' }}>
				<i className="fa fa-check" aria-hidden="true" />
			</button>
		);
	}
}

IETUpdateUnidade.propTypes = {
	item: PropTypes.object.isRequired,
	opcoes: PropTypes.array.isRequired,
	unidade_to_save: PropTypes.string.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	triagemZeraOutraUnidade: PropTypes.func.isRequired,
};

export default CSSModules(IETUpdateUnidade, styles, { allowMultiple: true });
