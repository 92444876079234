import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { setUserLocalstorageData } from '../../_functions/_getUserLocalstorageData';

class Obras extends Component {
	constructor() {
		super();
		this.state = {
			obras_menu: false,
		};
		this.handleMenu = this.handleMenu.bind(this);
		this.changeObra = this.changeObra.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.obras_menu !== this.props.obras_menu) this.setState({ obras_menu: nextProps.obras_menu });
	}

	handleMenu() {
		const obras_menu = !this.state.obras_menu;
		this.setState({ obras_menu });
		this.props.updateUi(['obras_menu'], obras_menu);
	}

	changeObra(e) {
		const obra_id = Number(e.target.id);
		const { tem_express } = this.props.obras_by_id[obra_id];
		const obra_tem_express = (tem_express !== undefined && tem_express !== null) ? tem_express : false;
		this.props.changeObra(obra_id, obra_tem_express);
		setUserLocalstorageData('obra', obra_id);
		this.handleMenu();

		if (
			window.location.pathname.indexOf('/cotacao/nova/') === -1 && window.location.pathname.indexOf('/info') === -1
			&& window.location.pathname !== '/cotacoes'
			&& window.location.pathname !== '/compras'
		) {
			this.props.history.push('/cotacoes');
		}

		if (window.location.pathname.indexOf('/cotacao/nova/') !== -1 && window.location.pathname.indexOf('/info') !== -1) {
			this.props.autoSavePage2();
			this.props.updateUi(['changes', 'cotacoes'], true);
		}
	}

	render() {
		const { obras_menu } = this.state;
		const { obras_by_id, obras_all_ids, obra_selected, on_form } = this.props;

		const obras_wrapper_class = !on_form ? 'obras-wrapper' : 'obras-wrapper on-form';
		const obras_button_class = !on_form ? 'obras-button' : 'obras-button on-form';
		const obras_list_class = !on_form ? 'obras-list' : 'obras-list on-form';
		const obra_nome = (obras_by_id[obra_selected] || {}).nome;

		return (
			<div styleName={obras_wrapper_class}>
				{(obra_selected !== 0 && obras_all_ids.length > 0) ? (
					<button
						type="button"
						styleName={obras_button_class}
						onClick={this.handleMenu}
						data-obras-menu
					>
						{!on_form && <i className="fa fa-map-marker" styleName="i-label" aria-hidden="true" data-obras-menu />} {obra_nome} <span className="caret" styleName="caret" data-obras-menu />
					</button>
				) : (
					<button
						type="button"
						styleName={obras_button_class}
					>
						<span styleName="label" data-obras-menu>Carregando obras...</span>
					</button>
				)}
				{!!obras_menu && (
					<div styleName={obras_list_class} data-obras-menu>
						{obras_all_ids.map(id => (
							<button
								key={id}
								id={id}
								type="button"
								styleName={id === obra_selected ? 'obra selected' : 'obra'}
								onClick={this.changeObra}
								data-obras-menu
							>
								{id === obra_selected ? (
									<i className="fa fa-map-marker" styleName="check" aria-hidden="true" id={id} data-obras-menu />
								) : (
									<i className="fa fa-map-marker" aria-hidden="true" id={id} data-obras-menu />
								)}
								{obras_by_id[id].nome}
							</button>
						))}
					</div>
				)}
			</div>
		);
	}
}

Obras.propTypes = {
	updateUi: PropTypes.func,
	obras_menu: PropTypes.bool,
	obras_by_id: PropTypes.object.isRequired,
	obras_all_ids: PropTypes.array.isRequired,
	obra_selected: PropTypes.number.isRequired,
	changeObra: PropTypes.func.isRequired,
	on_form: PropTypes.bool,
	autoSavePage2: PropTypes.func,
	history: PropTypes.object.isRequired,
};

Obras.defaultProps = {
	updateUi: () => {},
	obras_menu: false,
	on_form: false,
	autoSavePage2: () => {},
};

const _Obras = CSSModules(Obras, styles, { allowMultiple: true });
export default withRouter(_Obras);
