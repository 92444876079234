import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ModalExportaCompras = ({ closeModal, text }) => (
	<div className="modal-content alert" styleName="modal-content">
		<button
			type="button"
			className="close"
			styleName="close-button"
			data-dismiss="modal"
			aria-label="Close"
			onClick={closeModal}>
			<span aria-hidden="true">&times;</span>
		</button>
		<i
			className="fa fa-exclamation-triangle fa-fw"
			style={{ color: 'red' }}
			aria-hidden="true" />{' '}
		<span>{text}</span>
	</div>
);

ModalExportaCompras.propTypes = {
	closeModal: PropTypes.func.isRequired,
	text: PropTypes.string.isRequired,
};

export default CSSModules(ModalExportaCompras, styles, { allowMultiple: true });
