export const defineStatusPedido = pedido => {
	const status = (pedido || {}).status || 0;
	const cancelado = (pedido || {}).cancelado || false;
	const em_aprovacao = (pedido || {}).em_aprovacao || false;
	const em_refinamento = (pedido || {}).em_refinamento || false;

	if (cancelado) {
		return 'cancelado';
	}

	if (em_refinamento) {
		return 'refinamento';
	}

	if (em_aprovacao) {
		return 'envio_solicitado';
	}

	switch (status) {
	case -50:
	case -40:
		return 'rascunho';
	case -35:
		return 'refinamento';
	case 10:
		return 'em_edicao';
	case -5:
		return 'envio_solicitado';
	case 0:
	case 15:
	case 20:
	case 30:
		return 'revisao_conaz';
	case 40:
	case 45:
	default:
		return 'aguardando_cotacoes';
	case 50:
		return 'qc_disponivel';
	case 60:
		return 'compras_aguardando_confirmacao';
	case 70:
		return 'finalizado';
	}
};
