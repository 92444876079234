export default [
	{ value: 'Coordenador de Suprimentos', label: 'Coordenador de Suprimentos' },
	{ value: 'Comprador', label: 'Comprador' },
	{ value: 'Gerente de Suprimentos', label: 'Gerente de Suprimentos' },
	{ value: 'Analista de Compras', label: 'Analista de Compras' },
	{ value: 'Assistente de Compras', label: 'Assistente de Compras' },
	{ value: 'Auxiliar de Compras', label: 'Auxiliar de Compras' },
	{ value: 'Engenheiro(a)', label: 'Engenheiro(a)' },
	{ value: 'Auxiliar de Engenharia', label: 'Auxiliar de Engenharia' },
	{ value: 'Estagiário(a)', label: 'Estagiário(a)' },
	{ value: 'Almoxarife', label: 'Almoxarife' },
	{ value: 'Secretário(a)', label: 'Secretário(a)' },
	{ value: 'Financeiro', label: 'Financeiro' },
	{ value: 'Investidor(a)', label: 'Investidor(a)' },
	{ value: 'Diretor(a)', label: 'Diretor(a)' },
	{ value: 'Proprietário(a)', label: 'Proprietário(a)' },
	{ value: 'Outro', label: 'Outro' },
];
