import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Redux
import { buildMapStateToProps } from '../../../store/reduxDispatchs';

// Functions
import { getUserLocalstorageData } from '../../_functions/_getUserLocalstorageData';

class AccountMenu extends Component {
	state = {
		account_menu: false,
		user_nome: '',
	};

	UNSAFE_componentWillMount() {
		const user_nome = getUserLocalstorageData('nome');
		if (user_nome === '') {
			this.name_checks = window.setInterval(() => this.setName(), 10);
		} else {
			this.setState({ user_nome });
		}
	}

	// Arrumar esse trecho de código para evitar re-renderização desncessária
	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({ account_menu: nextProps.account_menu });
	}

	setName() {
		const user_nome = getUserLocalstorageData('nome');
		if (user_nome !== '') {
			window.clearTimeout(this.name_checks);
			this.setState({ user_nome });
		}
	}

	handleMenu = () => {
		const account_menu = !this.state.account_menu;
		this.setState({ account_menu });
		this.props.updateUi(['account_menu'], account_menu);
	}

	signOut = (e) => {
		e.preventDefault();
		this.props.unsetAuth();
		this.props.history.push('/entrar');
		localStorage.removeItem('usuario_admin');
	}

	render() {
		const { account_menu, user_nome } = this.state;
		const { user } = this.props;
		const { fetching } = user;
		const show_sienge_button = user.sienge_liberado || false;

		return (
			<div styleName="account-wrapper">
				{fetching !== 20 ? (
					<button id="account_nome" styleName="account-button" data-account-menu>
						<span styleName="name" style={{ color: '#bbb' }}>Carregando... </span><i className="fa fa-user-circle" aria-hidden="true" data-account-menu /> <span className="caret" styleName="caret" data-account-menu />
					</button>
				) : (
					<button id="account_nome" styleName="account-button" onClick={this.handleMenu} data-account-menu>
						<span styleName="name">Olá, {user_nome} </span><i className="fa fa-user-circle" aria-hidden="true" data-account-menu /> <span className="caret" styleName="caret" data-account-menu />
					</button>
				)}

				{!!account_menu && (
					<div styleName="account-list" data-account-menu>
						{show_sienge_button && (
							<Link to="/integracao/sienge" data-account-menu>
								<i className="fa fa-exchange" aria-hidden="true" data-account-menu /> Integração Sienge
							</Link>
						)}
						<Link to="/integracao/planilha" data-account-menu>
							<i className="fa fa-table" aria-hidden="true" data-account-menu /> Integração Planilha
						</Link>
						{/* <Link to="/avaliacoes" data-account-menu target="_blank" rel="noopener noreferrer">
							<i className="fa fa-star-o" aria-hidden="true" data-account-menu /> Avaliações
						</Link> */}
						<a href="https://produtosautodoc.zendesk.com/hc/pt-br/categories/21489087577875-Conaz" target="_blank" rel="noopener noreferrer" data-account-menu>
							<i className="fa fa-question-circle" aria-hidden="true" data-account-menu /> Central de ajuda
						</a>
						<Link to="/alterar-senha" data-account-menu>
							<i className="fa fa-key" aria-hidden="true" data-account-menu /> Alterar senha
						</Link>
						<Link to="#" onClick={this.signOut} data-account-menu>
							<i className="fa fa-sign-out" aria-hidden="true" data-account-menu /> Sair
						</Link>
					</div>
				)}
			</div>
		);
	}
}

AccountMenu.propTypes = {
	updateUi: PropTypes.func,
	account_menu: PropTypes.bool,
	unsetAuth: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.shape({
		sienge_liberado: PropTypes.bool,
		fetching: PropTypes.number,
	}).isRequired,
};

AccountMenu.defaultProps = {
	updateUi: () => {},
	account_menu: false,
};

const _AccountMenu = CSSModules(AccountMenu, styles, { allowMultiple: true });
const mapStateToProps = props => buildMapStateToProps(props, ['user']);
export default connect(mapStateToProps, null)(withRouter(_AccountMenu));
