/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import RequestsWrapperFuture from '../../components/RequestsWrapper/RequestsWrapperFuture';
import ViewCotacao from './ViewCotacao';

// Functions
import { _post } from '../../components/_functions/_requests';
import { defineStatusPedido } from '../../components/_functions/_defineStatusPedido';
import { _sendResponseToSlackToMap2 } from '../../components/_functions/_sendResponseToSlackToMap';
import { setUserLocalstorageData } from '../../components/_functions/_getUserLocalstorageData';

class ViewCotacaoContainerFuture extends Component {
	constructor(props) {
		super(props);
		this.state = {
			urls_to_fetch: [
				`/lite/qcResumido/${props.match.params.id}`,
			],
			rc_card_min_height: 10,
			qc_versao: 2,
			qc_anexo: {
				id: 0,
				nome: '',
				criado_em: '',
				atualizado_em: '',
				tamanho: 0,
				url: '',
				visto: true,
				already_set: false,
			},
			pdf_foi_aberto_alert: false,
			loading_fornecedores: false,
			loading_button: false,
			hide_cancel_duplicar: false,
			perfil_fornecedor: {
				vendedor_id: 0,
				vendedor_selecionado: {},
			},
			anexos_fornecedor: [],
			loading_compras: 0,
			rcs_abertos: [],
			qc_visto: true,
		};
		this.reloadPageChangeStatus = this.reloadPageChangeStatus.bind(this);
		this.handleResponses = this.handleResponses.bind(this);
		this.changeTab = this.changeTab.bind(this);
		this.openPdf = this.openPdf.bind(this);
		this.alertVerPdf = this.alertVerPdf.bind(this);
		this.openAnexosFornecedor = this.openAnexosFornecedor.bind(this);
		this.infoFornecedor = this.infoFornecedor.bind(this);
		this.openCloseRCs = this.openCloseRCs.bind(this);
	}

	changeObra(props) {
		const { cotacao, user, changeObra } = props;
		const cotacao_view = cotacao.cotacao_view || {};
		const cotacao_obra_id = (cotacao_view.obra || {}).id;
		const isCotacaoLoaded = cotacao_view.obra !== undefined;
		const isDifferentObra = user.obra_selected !== cotacao_obra_id;
		localStorage.setItem('obra', JSON.stringify(user.obras_by_id));
		if (isCotacaoLoaded && isDifferentObra) {
			changeObra(cotacao_obra_id, false);
			setUserLocalstorageData('obra', cotacao_obra_id);
		}
	}

	componentDidMount() {
		const { match, history, updateCompras } = this.props;
		const eh_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		// Redirect para triagem editar pedido
		if (eh_triagem) {
			history.replace(`/cotacao/nova/${match.params.id}`);
			return;
		}
		// Google Analytics
		window.ga('set', 'page', window.location.pathname + window.location.search);
		window.ga('send', 'pageview');
		// Set page title
		document.title = `#${match.params.id} - Cotação - Conaz`;
		window.scroll(0, 0);
		this._mounted = true;
		// Zera nova compra
		const criar_compra = {
			pedido: {},
			rc: {},
			rc_analise: {},
			type: '',
		};
		updateCompras(['criar_compra'], criar_compra);
		// Change obra
		this.changeObra(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		/**
		 * Verifica se mudou o código da cotação
		 * se mudou envia as novas url's para o wrapper carregá-las
		 */
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.setState({
				urls_to_fetch: [
					`/lite/qcResumido/${nextProps.match.params.id}`,
				],
			});
		}
		// Change obra
		this.changeObra(nextProps);
	}

	componentWillUnmount() {
		this._mounted = false;
		const { updateModals, updateUi, updateCotacao, ui } = this.props;
		updateModals('anexos', false);
		updateModals('pdf_qc', false);
		updateModals('fornecedor', false);
		updateModals('notifica_decisor', false);
		updateUi(['tabs', 'cotacao'], '');
		updateCotacao(['compras'], []);
		updateCotacao(['cotacao_view'], {});
		// alerts
		const alerts = { ...ui.alerts, success: { visible: false, message: '' } };
		updateUi(['alerts'], alerts);
	}

	setQCAnexo(cotacao_view) {
		const { ultimo_qc_anexo } = cotacao_view;
		if (
			ultimo_qc_anexo !== undefined &&
			ultimo_qc_anexo !== null &&
			ultimo_qc_anexo.url !== undefined &&
			ultimo_qc_anexo.url !== ''
		) {
			this.setState({ qc_anexo: { ...ultimo_qc_anexo, already_set: true } });
		} else {
			this.setState({ qc_anexo: { ...this.state.qc_anexo, already_set: true } });
		}
	}

	reloadPageChangeStatus(codigo) {
		this.setState({
			urls_to_fetch: [
				`/lite/qcResumido/${codigo}`,
			],
		});
	}

	checkVersaoQC(cotacao, analise) {
		/**
		 * Check se é QC novo ou antigo
		 */
		let qc_versao = 2;
		const pdf_url = (cotacao.ultimo_qc_anexo || {}).url || '';
		const has_qc_pdf = pdf_url !== '';
		const id_analise = (analise || {}).pedido_id || 0;

		if (!has_qc_pdf && id_analise === 0) qc_versao = 0;
		if (has_qc_pdf) qc_versao = 1;

		this.setState({ qc_versao });
		return qc_versao;
	}

	checkPrimeiraVisualizacaoQC(analise) {
		const analise_id = (analise || {}).id || null;
		const visualizado = (analise || {}).visualizado || false;
		if (!visualizado && analise_id !== null) {
			this.setState({ qc_visto: false });
		}
	}

	loadQCVersao2(responses, cotacao, updateWrapperState) {
		const { updateCotacao } = this.props;
		const opcoes_to_get = responses[0].data.analises[0] ? responses[0].data.analises[0].opcoes_de_compra
			.filter(x => !x.oculta && !x.rascunho) || [] : [];

		if (opcoes_to_get.length === 0) {
			updateCotacao(['ocs'], []);
			const statusRc = responses[0].data.status;
			const tipoSelecaoFornecedor = responses[0].data.rcs;
			const rcs = statusRc !== -30
					&& (statusRc >= 40 || tipoSelecaoFornecedor.filter(rc => rc.tipo_selecao_fornecedor < 4));
			updateCotacao(['rcs'], rcs);
			this.finalizaQCResumido(cotacao, updateWrapperState);
			return;
		}

		const opcoes_de_compra = opcoes_to_get
			.filter(x => x.rcs_analise.length > 0);
		const ocs = opcoes_de_compra.map(oc => {
			const is_combinacao = oc.rcs_analise.length > 1;
			/**
				 * Loop por todos os RC's analise
				 */
			const rcs_analise = oc.rcs_analise.map(rc_a => {
				const {
					condicoes_de_pagamento: cp,
					transporte,
					valor_transporte: vt,
					custos_adicionais: ca,
				} = rc_a.rc;
					/**
					 * Condições de pagamento
					 *  - Check se o campo está null ou vazio
					 *  - Filtra só os com valores corretos
					 */
				const cp_check = (
					cp !== null &&
						cp !== undefined &&
						cp.length > 0
				) ? [...cp] : [{ desconto: 0, forma: 0, outra_forma: '', outro_prazo: '', prazo: 0 }];
				const cp_clean = cp_check
					.filter(c => c.forma !== 0 && c.prazo !== 0)
					.map(c => ((c.desconto === null || c.desconto === undefined) ? { ...c, desconto: 0 } : c))
					.sort((a, b) => b.desconto - a.desconto);
					/**
					 * Calcula preço da soma dos itens
					 */
				const itens_total = !is_combinacao
					? rc_a.rc.sub_total || 0
					: rc_a.sub_total || 0;
					/**
					 * Custos adicionais
					 */
				const custos_adicionais = (ca !== null && ca !== undefined) ? ca : 0;
				/**
					 * Verifica se o transporte terá custos
					 */
				const valor_transporte = (vt !== null && vt !== undefined) ? vt : 0;
				const transporte_total = transporte === 1 ? valor_transporte : 0;
				/**
					 * Desconto
					 */
				const desconto_selecionado = (cp_clean[0] || {}).desconto || 0;
				/**
					 * Define menor preço
					 */
				const total_com_desconto = itens_total - (itens_total * desconto_selecionado) / 100;
				const preco_selecionado_rc = total_com_desconto + transporte_total + custos_adicionais;
				const preco_rc = itens_total;

				return {
					...rc_a,
					rc: {
						...rc_a.rc,
						condicoes_de_pagamento: cp_clean,
					},
					preco_rc,
					preco_selecionado_rc,
				};
			});

			/**
				 * Seta o valor total da opção de compra, também das combinações
				 */
			const preco_total = rcs_analise.reduce((total_rc, atual_rc) => (
				total_rc + atual_rc.preco_selecionado_rc
			), 0);

			return {
				...oc,
				rcs_analise,
				preco_total,
			};
		});

		/**
			 * Bloqueia caso seja cotação diferente
			 */
		const window_href = window.location.href;
		const cotacao_diferente = window_href.indexOf(`/cotacao/${cotacao.codigo}`) === -1;
		if (cotacao_diferente) return;

		/**
			 * QC pdf
			 * Opções de compra em branco, apenas RCs
			 */
		updateCotacao(['ocs'], ocs);
		const rcs = responses[0].data.status !== -30;

		updateCotacao(['rcs'], rcs);
		this.finalizaQCResumido(cotacao, updateWrapperState);
	}

	handleResponses(responses, updateWrapperState) {
		const { history, updateCotacao } = this.props;
		const cotacao = { ...responses[0].data, codigo: responses[0].data.pedido_id.toString() };
		/**
		 * Pedido não existe
		 */
		if (cotacao.type === 'ResourceError' && cotacao.message === 'Pedido inexistente') {
			updateWrapperState('request_state', 5);
			return;
		}
		/**
		 * Erros gerais
		 */
		if (cotacao.type === 'ResourceError') {
			updateWrapperState('request_state', 3);
			return;
		}

		/**
		 * Verifica status da cotação e redireciona caso seja rascunho
		 * Mostra mensagem que o pedido não existe caso status esteja errado
		 */
		const status = defineStatusPedido(cotacao);
		if (
			status === 'rascunho' ||
			status === 'refinamento' ||
			status === 'em_edicao' ||
			status === 'envio_solicitado'
		) {
			history.replace(`/cotacao/nova/${cotacao.codigo}`);
			return;
		}
		if (
			status !== 'revisao_conaz' &&
			status !== 'aguardando_cotacoes' &&
			status !== 'qc_disponivel' &&
			status !== 'compras_aguardando_confirmacao' &&
			status !== 'finalizado' &&
			status !== 'cancelado'
		) {
			updateWrapperState('request_state', 5);
			return;
		}

		/**
		 * Check se é QC novo ou antigo
		 */
		const qc_versao = this.checkVersaoQC(cotacao, responses[0].data);

		/**
		 * Trata os RCs
		 */
		if (qc_versao === 2) {
			this.checkPrimeiraVisualizacaoQC(responses[0].data);
			this.loadQCVersao2(responses, cotacao, updateWrapperState);
			/**
			 * QC online
			 * Filtra as opções ocultas
			 */
		} else {
			/**
			 * QC pdf
			 * Opções de compra em branco, apenas RCs
			 */
			_sendResponseToSlackToMap2(responses);
			const ocs_response = (responses || [])[1] || {};
			const ocs = (ocs_response.data || []).map(rc => ({
				id: null,
				rcs_analise: [{
					id: null,
					rc: {
						...rc,
						sub_total: rc.preco_total,
					},
				}],
			}));
			updateCotacao(['ocs'], ocs);
			const statusRc = responses[0].data.status;
			const tipoSelecaoFornecedor = responses[0].data.rcs;
			const rcs = statusRc !== -30
					&& (statusRc >= 40 || tipoSelecaoFornecedor.filter(rc => rc.tipo_selecao_fornecedor < 4));
			updateCotacao(['rcs'], rcs);

			this.finalizaQCResumido(cotacao, updateWrapperState);
		}
	}

	finalizaQCResumido(cotacao, updateWrapperState) {
		this.props.updateCotacao(['cotacao_view'], cotacao);

		/**
		 * Set QC Anexo
		 */
		this.setQCAnexo(cotacao);

		/**
		 * Carrega as compras e ordens de compra e se repete ao montar componente
		 */
		this.carregaCompras(this.props.cotacao);

		/**
		 * Zera as url's no RequestWrapper para prevenir novas requests
		 * Finaliza o loading e mostra o conteúdo
		 */
		this.setState({ urls_to_fetch: [] });
		updateWrapperState('request_state', 2);
		/**
		 * Handle cards heights
		 */
		if (cotacao.status === 40 || cotacao.status === 45) {
			this.handleRCsCardsHeight();
		}
	}

	carregaCompras(cotacao) {
		const { updateCotacao } = this.props;

		const { compras } = cotacao;
		const compras_e_ordens = [...compras];

		updateCotacao(['compras'], compras_e_ordens);
		this.setState({ loading_compras: 2 });
	}

	openPdf() {
		const { id, visto } = this.state.qc_anexo;
		const { updateModals, updateCotacao } = this.props;
		updateCotacao(['cotacao_view', 'qc_visto'], true);
		updateModals('pdf_qc', true);
		if (id !== 0 && !visto) {
			const qc_anexo = { ...this.state.qc_anexo, visto: true };
			this.setState({ qc_anexo, pdf_foi_aberto_alert: false });
			_post(`/conaz/v2/anexos_pedido/${id}/visto`, {});
		}
	}

	alertVerPdf() {
		window.scroll(0, 0);
		this.setState({ pdf_foi_aberto_alert: true });
	}

	openAnexosFornecedor(anexos_fornecedor) {
		this.setState({ anexos_fornecedor });
		this.props.updateModals('anexos', true);
	}

	infoFornecedor(vendedor_id, vendedor_selecionado) {
		const perfil_fornecedor = { vendedor_id, vendedor_selecionado };
		this.setState({ perfil_fornecedor });
		this.props.updateModals('fornecedor', true);
	}

	openCloseRCs(uuid) {
		const is_open = this.state.rcs_abertos.filter(x => x === uuid).length > 0;
		const rcs_abertos = !is_open
			? [...this.state.rcs_abertos, uuid].filter((el, i, a) => i === a.indexOf(el))
			: this.state.rcs_abertos.filter(x => x !== uuid);
		this.setState({ rcs_abertos });
	}

	handleRCsCardsHeight() {
		setTimeout(() => {
			const rcs_cards_height = [];
			for (let i = 1; i < 50; i++) {
				if (document.getElementById(`rc-o${i}`)) {
					const card_height = Math.ceil(document.getElementById(`rc-o${i}`).offsetHeight);
					rcs_cards_height.push(card_height);
				}
			}
			if (rcs_cards_height.length > 0) {
				const max_height = rcs_cards_height.reduce((acc, cur) => Math.max(acc, cur)) + 2;
				if (max_height !== this.state.rc_card_min_height) {
					for (let i = 1; i < 50; i++) {
						if (document.getElementById(`rc-o${i}`)) {
							document.getElementById(`rc-o${i}`).style.minHeight = `${max_height}px`;
						}
					}
					this.setState({ rc_card_min_height: max_height });
				}
			}
		}, 10);
	}

	changeTab(e) {
		this.props.updateUi(['tabs', 'cotacao'], e.target.id);
	}

	render() {
		return (
			<RequestsWrapperFuture
				urls_to_fetch={this.state.urls_to_fetch}
				handleResponses={this.handleResponses}
				{...this.props}>

				<ViewCotacao
					{...this.state}
					{...this.props}
					reloadPageChangeStatus={this.reloadPageChangeStatus}
					changeTab={this.changeTab}
					openPdf={this.openPdf}
					alertVerPdf={this.alertVerPdf}
					openAnexosFornecedor={this.openAnexosFornecedor}
					infoFornecedor={this.infoFornecedor}
					openCloseRCs={this.openCloseRCs} />

			</RequestsWrapperFuture>
		);
	}
}

ViewCotacaoContainerFuture.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	qc: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
	updateQc: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(ViewCotacaoContainerFuture));
