import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { _post } from '../../../components/_functions/_requests';

class DescancelarCotacao extends Component {
	constructor(props) {
		super(props);
		this.state = {
			confirm_descancelar: false,
			descancelando: 0,
		};
		this.confirmaDescancelar = this.confirmaDescancelar.bind(this);
		this.descancelarCotacao = this.descancelarCotacao.bind(this);
	}

	confirmaDescancelar() {
		const confirm_descancelar = !this.state.confirm_descancelar;
		this.setState({ confirm_descancelar });
		this.props.handleInsideConfirm();
	}

	descancelarCotacao() {
		this.setState({ descancelando: 1 });
		const { match, updateUi, updateModals, reloadPageChangeStatus } = this.props;
		_post(`/conaz/v2/pedidos/${match.params.id}/descancelar`, {}).then(response => {
			updateUi(['changes', 'cotacoes'], true);
			reloadPageChangeStatus(response.data.codigo);
		}).catch(error => {
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const { descancelando, confirm_descancelar } = this.state;
		const { cancelado, inside_confirm } = this.props;

		if (!inside_confirm && cancelado && !confirm_descancelar && (descancelando === 0 || descancelando === 2)) {
			return (
				<button
					type="button"
					data-acoes-pedido-menu
					onClick={this.confirmaDescancelar}>
					<i className="fa fa-reply fa-fw" aria-hidden="true" data-acoes-pedido-menu /> Restaurar cotação
				</button>
			);
		}

		if (inside_confirm && cancelado && descancelando >= 1) {
			return (
				<p style={{ margin: '10px 14px' }} data-acoes-pedido-menu>Descancelando cotação...</p>
			);
		}
		if (cancelado && confirm_descancelar && !descancelando) {
			return (
				<div styleName="confirm-wrapper" data-acoes-pedido-menu>
					<p data-acoes-pedido-menu>Tem certeza que deseja restaurar esta cotação?</p>
					<div style={{ display: 'flex', margin: '20px', marginBottom: '0px', width: 'auto', maxWidth: '100%' }}>
						<i className="fa fa-exclamation-circle" style={{ fontSize: 20, color: '#faad14' }} />
						<p data-acoes-pedido-menu style={{ marginLeft: '10px' }}>
							Ao restaurar uma cotação, as propostas enviadas pelos fornecedores não estarão mais disponíveis e não será possível vincular uma compra.
							<p style={{ marginTop: 10 }}>Para reativar esta solicitação, após a restauração, você deverá clicar em ações, editar cotação, selecionar os fornecedores novamente e reenviar a solicitação com novos prazos.</p>
						</p>
					</div>
					<button
						type="button"
						style={{ marginLeft: 38 }}
						className="btn btn-danger-conaz round-border"
						styleName="descartar-button"
						data-acoes-pedido-menu
						onClick={this.descancelarCotacao}>
						Sim
					</button>
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						styleName="cancelar-button"
						data-acoes-pedido-menu
						onClick={this.confirmaDescancelar}>
						Não
					</button>

				</div>
			);
		}
		return null;
	}
}

DescancelarCotacao.propTypes = {
	cancelado: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
	handleInsideConfirm: PropTypes.func.isRequired,
	reloadPageChangeStatus: PropTypes.func.isRequired,
};

export default CSSModules(DescancelarCotacao, styles, { allowMultiple: true });
