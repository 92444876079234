import {
	UPDATE_ITENS,
	UPDATE_ITENS_RAW,
	UPDATE_ITEM_BY_ID,
	UPDATE_FULLITEM_BY_ID,
	UPDATE_ITEM_CARAC_BY_ID,
	DELETE_ITEM,
} from './types';

export const updateItens = (field, value) => ({ type: UPDATE_ITENS, field, value });
export const updateItensRaw = fields_n_values => ({ type: UPDATE_ITENS_RAW, fields_n_values });
export const updateItemById = (item_id, field, value) => ({ type: UPDATE_ITEM_BY_ID, item_id, field, value });
export const updateFullItemById = (item_id, item) => ({ type: UPDATE_FULLITEM_BY_ID, item_id, item });
export const updateItemCaracById = (item_id, ca_id, value) => ({ type: UPDATE_ITEM_CARAC_BY_ID, item_id, ca_id, value });
export const deleteItem = (item_id) => ({ type: DELETE_ITEM, item_id });
