import styled from 'styled-components/macro';

export const CloseButton = styled.button`
	position: absolute;
	margin: 5px 0 0 93%;
	border: none;
	background: white;
	color: #C4C4C4;
	font-size: 18px;
`;

export const Div = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background: white;
	border-radius: 10px;
	width: 596.68px;
	min-height: 275.22px;
`;

export const Title = styled.div`
	font-family: Roboto;
	font-style: normal;
	margin-left: 29px;
	margin-top: 31px;
	font-size: 18px;
	line-height: 21px;
`;

export const ResponsavelBlock = styled.div`
	font-family: Roboto;
	font-style: normal;
	margin: 31px 0 31px 29px;

	span {
		font-size: 14px;
		&:first-of-type { color: #000000 }
		&:last-of-type { color: #828282 }
	}

`;

export const Message = styled.div`
	font-weight: 500;
	font-family: Roboto;
	font-style: normal;
	line-height: 16px;
	margin-left: 29px;
	margin-top: 20px;
	color: #000000;
`;

export const OkButton = styled.button`
	background: #13AECB;
	border-radius: 50px;
	padding: 10px 30px;
	margin: 25px 25px 25px auto;
	color: white;
	border: none;
`;
