import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const HeaderLogin = () => (
	<>
		{/* Header */}
		<div className="header">
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="center-position">
							<div styleName="card-login">
								<h3 className="h3-header" style={{ lineHeight: '1.4' }}>Configurações de acesso à<br /> API do SIENGE</h3>
								<div styleName="form-row-back-button">
									<Link to="/integracao/sienge">
										<button className="btn btn-clear-conaz round-border">
											<i className="fa fa-angle-left fa-fw" aria-hidden="true" /> Voltar para o início
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
);

export default CSSModules(HeaderLogin, styles, { allowMultiple: true });
