import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { message } from 'antd';

import { getObraList, associaEmpresa, criaObra, associaUsario, editaObra, statusVisualizacaoObra, getObra, statusAtivoObra } from 'services/obras';
import * as Types from './types';
import * as Actions from './actions';

import * as ObrasDrawerActions from '../obrasDrawer/actions';

function* getObras() {
	try {
		const result = yield call(getObraList);
		if (result.status === 200) yield put(Actions.getObrasSuccess(result.data));
		else throw new Error('Não foi possível recuperar as obras');
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível recuperar as obras');
		}
		yield put(Actions.getObrasError(error));
	}
}

function* visualizacaoObra({ payload: { data } }) {
	try {
		const { id } = data;
		const res = yield call(statusVisualizacaoObra, id, data);
		if (res.status !== 200) {
			throw new Error('Não foi possível alterar status da obra');
		}
		if (res.status === 200) {
			const response = yield call(getObra, id);
			yield put(Actions.editObrasSuccess(response.data));
		}
		message.success('Status da obra alterado com sucesso');
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível alterar status da obra');
		}
		yield put(Actions.actionError(error));
	}
}

function* ativoObra({ payload: { data } }) {
	try {
		const { id } = data;
		const res = yield call(statusAtivoObra, id, data);
		if (res.status !== 200) {
			throw new Error('Não foi possível alterar status da obra');
		}

		if (res.status === 200) {
			const response = yield call(getObra, id);
			yield put(Actions.editObrasSuccess(response.data));
		}
		message.success('Status da obra alterado com sucesso');
	} catch (error) {
		message.error('Não foi possível alterar status da obra');
		yield put(Actions.actionError(error));
	}
}

function* editObras({ payload: { data } }) {
	try {
		const { obra_id, ...editData } = data;
		const res = yield call(editaObra, obra_id, editData);

		if (res.status !== 200) {
			throw new Error('Não foi possível editar a obra');
		}

		if (localStorage.getItem('obra_id') !== null && localStorage.getItem('obra_id') !== undefined) {
			yield put(Actions.editObrasSuccess(res.data));
			yield put(ObrasDrawerActions.createdObra(res.data));
		} else {
			yield put(Actions.editObrasSuccess(res.data));
			yield put(ObrasDrawerActions.closeObraDrawer());
		}

		message.success('Obra editada com sucesso');
	} catch (error) {
		if (error.response.data && error.response.data.message) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível editar a obra');
		}
		yield put(Actions.actionError(error));
	}
}

function* createObras({ payload: { data } }) {
	try {
		const formData = new FormData();
		const { quantidade, metodo_construtivo, endereco, fotos_obra, ...rest } = data;

		if (quantidade === null || quantidade === undefined) {
			formData.delete('quantidade');
		} else {
			formData.append('quantidade', quantidade);
		}
		Object.keys(rest).forEach((k) => {
			formData.append(k, rest[k]);
		});
		formData.append('endereco', JSON.stringify(endereco));
		fotos_obra.forEach((f) => {
			formData.append(['files'], f.originFileObj, f.name);
		});
		const result = yield call(criaObra, formData);
		if (result.status !== 201) {
			throw new Error('Não foi possível criar a obra.');
		}
		yield put(Actions.createObraSuccess(result.data));
		yield put(ObrasDrawerActions.createdObra(result.data));
		if (result.data && result.data.id) {
			localStorage.setItem('obra_id', result.data.id);
		}
	} catch (error) {
		if (error.response.data && error.response.data.message) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível Criar a obra');
		}
		yield put(Actions.actionError(error));
	}
}

// Essa func vai ter q alterar o estado do redux do drawer de obras conforme resultado
function* handleAssociaEmpresa({ payload: { data } }) {
	try {
		const {
			obra_id,
			empresa_responsavel_fatura,
			empresa_responsavel_obra,
			empresa_resp_obra_faturamento: mesmo_responsavel,
		} = data;

		// para quando é a mesma empresa para ambas casos.
		if (mesmo_responsavel || empresa_responsavel_fatura === empresa_responsavel_obra) {
			const res = yield call(associaEmpresa, obra_id, empresa_responsavel_obra, {
				papeis: [1, 2],
			});
			if (res.status !== 201) {
				throw new Error('Não foi possível associar empresa a obra');
			}

			yield put(Actions.associaEmpresaSuccess([res.data], obra_id));
		} else {
			const { fatura, obra } = yield all({
				fatura: call(associaEmpresa, obra_id, empresa_responsavel_fatura, {
					papeis: [2],
				}),
				obra: call(associaEmpresa, obra_id, empresa_responsavel_obra, {
					papeis: [1],
				}),
			});
			if (fatura.status !== 201 && obra.status !== 201) {
				throw new Error('Não foi possível associar as empresas');
			}

			yield put(Actions.associaEmpresaSuccess([fatura.data, obra.data], obra_id));
		}
		// avançar no drawer.
		yield put(ObrasDrawerActions.associaEmpresaSuccess());
	}	catch (error) {
		message.error('Erro ao tentar associar empresa a obra.');
		yield put(Actions.actionError(error));
	}
}

function* handleAssociaUsuario({ payload: { data } }) {
	try {
		const {
			papeis,
			compra_ilimitada,
			valor_alcada_compra,
			user_id,
			obra_id,
		} = data;

		const aux = { papeis, valor_alcada_compra, compra_ilimitada: compra_ilimitada || false };
		const res = yield call(associaUsario, obra_id, user_id, aux);
		if (res.status !== 201) {
			throw new Error('Erro ao tentar Associar usuário a obra');
		}

		// setando na tabela.
		const form = yield select((state) => state.obrasDrawer.formInstance);
		const { tabela_usuarios } = form.getFieldsValue();
		const usuarioTabela = {
			...res.data,
			...res.data.usuario,
		};
		form.setFieldsValue({
			tabela_usuarios: [...tabela_usuarios, usuarioTabela],
			nome_usuario: undefined,
			papeis: undefined,
			compra_ilimitada: undefined,
			valor_alcada_compra: undefined,
			user_id: undefined,
		});

		yield put(Actions.associaUsuarioSuccess(obra_id, res.data));
		if (res.data && res.data.obra) {
			yield put(Actions.editObrasSuccess(res.data.obra));
		}
	} catch (error) {
		const errorMessage = error?.response?.data?.message || 'Erro ao tentar associar usuário a obra';
		message.error(errorMessage);
		yield put(Actions.actionError(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.OBRAS_GET_LIST_REQUEST, getObras),
		takeLatest(Types.OBRAS_EDIT_REQUEST, editObras),
		takeLatest(Types.OBRAS_CREATE_REQUEST, createObras),
		takeLatest(Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_REQUEST, handleAssociaEmpresa),
		takeLatest(Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_REQUEST, handleAssociaUsuario),
		takeLatest(Types.ASSOCIA_OBRAS_STATUS_VISUALIZACAO, visualizacaoObra),
		takeLatest(Types.ASSOCIA_OBRAS_STATUS_ATIVO, ativoObra),
	]);
}
