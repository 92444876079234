import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ButtonRound from '../../_buttons/ButtonRound/ButtonRound';

// Functions
import { _delete } from '../../../_functions/_requests';

class IEButtons extends Component {
	state = {
		confirm_trocar: false,
		confirm_delete: false,
	};

	openConfirmTrocar = () => {
		const { confirm_trocar } = this.state;
		this.setState({
			confirm_trocar: !confirm_trocar,
			confirm_delete: false,
		});
	}

	alteraItem = () => {
		const { item, updateFullItemById, autoSave } = this.props;
		const pre_nome = item.pre_nome === ''
			? item.item_preenchido.item.nome
			: item.pre_nome;
		const pre_detalhe = item.pre_detalhe === ''
			? item.observacoes
			: item.pre_detalhe;
		const new_item = {
			...item,
			cliente_associou: false,
			importado_planilha: 1,
			pre_nome,
			pre_detalhe,
			item_preenchido: {},
		};
		updateFullItemById(item.id, new_item);
		autoSave(item.id);
	}

	openConfirmDelete = () => {
		const { confirm_delete } = this.state;
		this.setState({
			confirm_delete: !confirm_delete,
			confirm_trocar: false,
		});
	}

	deleteItem = () => {
		const { item, deleteItem, openItemEdition } = this.props;
		const url = `/conaz/v2/itens_de_compra/${item.id}`;
		_delete(url);
		deleteItem(item.id);
		openItemEdition();
	}

	render() {
		const { confirm_trocar, confirm_delete } = this.state;

		return (
			<>
				<div styleName="buttons">
					<ButtonRound
						icon="fa fa-exchange"
						tooltip_text="Trocar item"
						left="-29%"
						first_of_type
						onClick={this.openConfirmTrocar} />
					<ButtonRound
						icon="fa fa-trash"
						tooltip_text="Apagar"
						left="-31%"
						onClick={this.openConfirmDelete} />
				</div>

				{confirm_trocar && (
					<div styleName="alert-wrapper">
						<div styleName="message">
							<div styleName="confirm-wrapper">
								<p>Tem certeza que deseja trocar o item? Suas informações preenchidas serão perdidas.</p>
								<button
									type="button"
									className="btn btn-secondary-conaz round-border"
									styleName="descartar-button"
									onClick={this.alteraItem}>
									Trocar
								</button>
								<button
									type="button"
									className="btn btn-clear-conaz round-border"
									styleName="cancelar-button"
									onClick={this.openConfirmTrocar}>
									Não
								</button>
							</div>
						</div>
					</div>
				)}

				{confirm_delete && (
					<div styleName="alert-wrapper">
						<div styleName="message">
							<div styleName="confirm-wrapper">
								<p>Deseja apagar este item?</p>
								<button
									type="button"
									className="btn btn-danger-conaz round-border"
									styleName="descartar-button"
									onClick={this.deleteItem}>
									Apagar
								</button>
								<button
									type="button"
									className="btn btn-clear-conaz round-border"
									styleName="cancelar-button"
									onClick={this.openConfirmDelete}>
									Manter
								</button>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

IEButtons.propTypes = {
	item: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	openItemEdition: PropTypes.func.isRequired,
};

export default CSSModules(IEButtons, styles, { allowMultiple: true });
