import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import { PlusOutlined } from '@ant-design/icons';
import { Form, Button, Row, Select, Col } from 'antd';
import DrawerEmpresas from 'components/DrawerEmpresas';
import { validations } from 'ambar-ui';

import { DrawerFooter, StyledDrawer } from '../styled';

// Redux HOC
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

// Icon
import { ReactComponent as Exclamacao } from '../../../../icons/exclamacao_outlined.svg';

import styles from './style.module.scss';

const { validateRequired } = validations;

function AssociaEmpresa({ ...props }) {
	const {
		empresas,
		getEmpresas,
		associaObra,
		associaEmpresaCloseDrawer,
		associaEmpresaObraSelecionada,
		openEmpresaDrawer,
		closeEmpresaDrawer,
		createEmpresa,
	} = props;

	const [form] = Form.useForm();
	const editMode = !!associaObra.empresaToEdit;
	const userRoleLink = 'https://produtosautodoc.zendesk.com/hc/pt-br/articles/4416784905107-Como-associar-uma-empresa-a-uma-obra-';

	// função que chama submit do Form. Serve para trigger as validações dos inputs.
	const requestSubmitEdit = () => {
		form.submit();
	};

	// função chamada dps de requestSubmitEdit (caso não tenha problema em algum campo).
	const handleSubmit = () => {
		const { empresa, empresa_papel } = form.getFieldsValue();
		const { id: obra_id } = associaObra.obra;
		// editMode só para poder alterar o texto de sucesso.
		associaEmpresaObraSelecionada(obra_id, empresa.value, empresa_papel, editMode);
	};

	const handleSubmitEmpresa = (data) => {
		createEmpresa(data);
	};

	const disableAll = () => {
		if (!editMode) return associaObra.actionFetching || empresas.isFetching;
		if (editMode && associaObra.actionFetching) return true;
		return false;
	};

	const Footer = () => (
		<DrawerFooter>
			<div className="footer-form">
				<DrawerFooter.Actions>
					<Button
						onClick={associaEmpresaCloseDrawer}
						disabled={disableAll()}
					>
						Cancelar
					</Button>
					<Button className="submit-btn" type="primary" onClick={requestSubmitEdit} disabled={disableAll()}>
						Salvar
					</Button>
				</DrawerFooter.Actions>
			</div>
		</DrawerFooter>
	);

	useEffect(() => {
		if (associaObra.showAssociaEmpresaDrawer && !empresas.empresas.length && !empresas.isFetching) {
			getEmpresas();
		}
	}, [associaObra, empresas, getEmpresas]);

	useEffect(() => {
		if (!associaObra.showAssociaEmpresaDrawer) {
			form.resetFields();
		}

		if (associaObra.showAssociaEmpresaDrawer && associaObra.empresaToEdit) {
			const { papeis, pessoa } = associaObra.empresaToEdit;
			form.setFieldsValue({
				empresa_papel: papeis,
				empresa: { value: pessoa.id, label: pessoa.nome },
			});
		}
	}, [associaObra.showAssociaEmpresaDrawer]);

	const empresaOptions = empresas.empresas.map((emp) => ({ value: emp.id, label: emp.nome }));

	return (
		<>
			<DrawerEmpresas
				onClose={closeEmpresaDrawer}
				mode="new"
				visible={empresas.showDrawer}
				onSubmit={handleSubmitEmpresa}
				loading={empresas.actionFetching}
			/>
			<StyledDrawer
				title="Associação de empresa"
				placement="right"
				width="620"
				destroyOnClose
				onClose={associaEmpresaCloseDrawer}
				visible={associaObra.showAssociaEmpresaDrawer}
				footer={<Footer />}
			>
				<div styleName="empresa-user-role-warn" style={{ margin: 0 }}>
					<Exclamacao />
					<div style={{ display: 'block', alignItems: 'center', marginTop: '5px' }}>
						<a styleName="link-roles" href={userRoleLink}>
							Entenda como associar suas empresas em nossa central de ajuda.
						</a>
					</div>
				</div>

				<Form className="ant-form-padding" layout="vertical" onFinish={handleSubmit} form={form}>
					<Row>
						<Col span={24}>
							<Form.Item name="empresa" label="Nome da empresa" rules={[validateRequired()]} className="ant-form-item-less-margin">
								<Select
									showSearch
									showArrow={false}
									labelInValue
									notFoundContent="Nenhuma empresa encontrada"
									filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									loading={associaObra.actionFetching || empresas.isFetching}
									options={empresaOptions || []}
									disabled={associaObra.actionFetching || empresas.isFetching || editMode}
								/>
							</Form.Item>
						</Col>
					</Row>
					{!editMode && (
						<div style={{ marginBottom: 16 }}>
							<Button className="drawer-obras-empresa-add" disabled={associaObra.actionFetching || empresas.isFetching} onClick={() => openEmpresaDrawer()}>
								<PlusOutlined />
								Cadastrar empresa
							</Button>
						</div>
					)}
					<Row>
						<Col span={24}>
							<Form.Item label="Responsável" name="empresa_papel" rules={[validateRequired()]}>
								<Select
									mode="tags"
									disabled={disableAll()}
									options={[
										{ label: 'Responsável pela obra', value: 1 },
										{ label: 'Responsável pela fatura', value: 2 },
									]}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</StyledDrawer>
		</>
	);
}

AssociaEmpresa.propTypes = {
	empresas: PropTypes.shape().isRequired,
	associaObra: PropTypes.shape().isRequired,
	getEmpresas: PropTypes.func.isRequired,
	createEmpresa: PropTypes.func.isRequired,
	associaEmpresaObraSelecionada: PropTypes.func.isRequired,
	associaEmpresaCloseDrawer: PropTypes.func.isRequired,
	openEmpresaDrawer: PropTypes.func.isRequired,
	closeEmpresaDrawer: PropTypes.func.isRequired,
};

const WithStyles = CSSModules(AssociaEmpresa, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));
