import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';
import { withToggle, withTooltip } from '../../../../../components/_hocs/hocs';
import ButtonToolTip from '../../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

const ButtonIcon = ({
	icon,
	func,
	toggleIconName,
	toggle,
	isVisible,
	tooltipIsVisible,
	closeTooltip,
	openTooltip,
	tooltipMessage,
	positionTooltip,
	stylePosition,
	styleTooltip,
	styleIcon,
	styleToggleIcon,
	entryValue,
	...otherProps
}) => {
	const handleFunction = () => {
		if (toggleIconName !== undefined) {
			func();
			toggle();
		} else {
			func();
		}
	};
	const switchEntryValueOrDefault = () => {
		if (entryValue !== undefined) {
			return (
				<i
					className={entryValue ? icon : toggleIconName}
					style={entryValue ? { ...styleIcon } : { ...styleToggleIcon }} />
			);
		}
		return <i
			className={isVisible ? icon : toggleIconName}
			style={isVisible ? { ...styleIcon } : { ...styleToggleIcon }} />;
	};

	if (tooltipMessage !== '') {
		return (
			<>
				{tooltipIsVisible &&
					<ButtonToolTip
						text={tooltipMessage}
						display={positionTooltip}
						{...{ ...stylePosition }}
						styleTooltip={{ ...styleTooltip }}
						{...otherProps} />}
				<button
					onMouseEnter={openTooltip}
					onMouseLeave={closeTooltip}
					styleName="icon-button"
					onClick={handleFunction}>
					{
						toggleIconName ?
							switchEntryValueOrDefault()
							:
							<i className={icon} style={{ ...styleIcon }} />
					}
				</button>
			</>
		);
	}

	return (
		<button
			onMouseEnter={openTooltip}
			onMouseLeave={closeTooltip}
			styleName="icon-button"
			onClick={handleFunction}>
			{
				toggleIconName ?
					switchEntryValueOrDefault()
					:
					<i className={icon} style={{ ...styleIcon }} />
			}
		</button>
	);
};

ButtonIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	func: PropTypes.func.isRequired,
	toggleIconName: PropTypes.string,
	toggle: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired,
	tooltipIsVisible: PropTypes.bool.isRequired,
	closeTooltip: PropTypes.func.isRequired,
	openTooltip: PropTypes.func.isRequired,
	tooltipMessage: PropTypes.string,
	positionTooltip: PropTypes.string,
	stylePosition: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	styleTooltip: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	styleArrow: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	entryValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
	styleIcon: PropTypes.objectOf(PropTypes.string),
	styleToggleIcon: PropTypes.objectOf(PropTypes.string),
};

ButtonIcon.defaultProps = {
	toggleIconName: undefined,
	styleIcon: {},
	styleToggleIcon: {},
	positionTooltip: undefined,
	entryValue: undefined,
	tooltipMessage: '',
	styleTooltip: {},
	styleArrow: {},
	stylePosition: {
		arrow_left: '',
		margin_top: '',
		margin_left: '',
		width: 'auto',
	},
};

export default compose(
	withToggle,
	withTooltip,
)(CSSModules(ButtonIcon, styles, { allowMultiple: true }));
