import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../../../components/_content/_condicoes_pagamento';

class CondicaoButton extends Component {
	constructor(props) {
		super(props);
		this.changeLocal = this.changeLocal.bind(this);
	}

	changeLocal() {
		const { oc_id, rc_a_id, condicao, changeCondicaoPagamento } = this.props;
		changeCondicaoPagamento(oc_id, rc_a_id, condicao.id);
	}

	render() {
		const {
			order,
			preco_com_desconto,
			condicao,
			condicao_selecionada_id,
		} = this.props;

		const pagamentos_class = (
			condicao_selecionada_id === condicao.id ||
			(
				order === 0 &&
				(condicao_selecionada_id === null || condicao_selecionada_id === -1)
			)
		)
			? 'pagamentos p-selected'
			: 'pagamentos';

		return (
			<button
				type="button"
				data-rc-info
				styleName={pagamentos_class}
				onClick={this.changeLocal}>
				<div styleName="dot" data-rc-info>•</div>
				<h4 data-rc-info><b data-rc-info>{formatCurrency(preco_com_desconto, 2, 'R$')}</b></h4>
				{condicao.forma === -1 ? condicao.outra_forma : formas_pagamento[condicao.forma].view}:&nbsp;
				{condicao.prazo === -1 ? condicao.outro_prazo : prazos_pagamento_full[condicao.prazo].view}
				{condicao.desconto > 0 && <p data-rc-info>({condicao.desconto}% de desconto já incluso)</p>}
				{condicao.aceita_parcial === 0 && (
					<p style={{ color: '#e91e63' }} data-rc-info>Não aceita compras parciais</p>
				)}
				{condicao.aceita_parcial === 1 && (
					<p data-rc-info>Aceita compras parciais</p>
				)}
				{(condicao.aceita_parcial === 2 && condicao.compra_minima > 0) && (
					<p data-rc-info>Aceita compras parciais acima de {formatCurrency(condicao.compra_minima, 2, 'R$')}</p>
				)}
			</button>
		);
	}
}

CondicaoButton.propTypes = {
	order: PropTypes.number.isRequired,
	oc_id: PropTypes.number.isRequired,
	rc_a_id: PropTypes.number.isRequired,
	condicao: PropTypes.object.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	preco_com_desconto: PropTypes.number.isRequired,
	condicao_selecionada_id: PropTypes.number,
};

CondicaoButton.defaultProps = {
	condicao_selecionada_id: -1,
};

export default CSSModules(CondicaoButton, styles, { allowMultiple: true });
