import * as Types from './types';

export const getAdressByCep = (cep) => ({
	type: Types.ADRESS_REQUEST,
	payload: { cep },
});

export const getAdressSuccess = (adress) => ({
	type: Types.ADRESS_SUCCESS,
	payload: { adress },
});

export const getAdressError = (erro_motivo) => ({
	type: Types.ADRESS_ERROR,
	payload: { erro_motivo },
});
