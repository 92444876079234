import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const InfoItem = styled.div``;

InfoItem.Label = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #8C8C8C;
`;

InfoItem.Text = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #595959;
`;

const InfoWithLabel = ({ label, text }) => (
	<InfoItem>
		<InfoItem.Label>
			{label}
		</InfoItem.Label>
		<InfoItem.Text>
			{text}
		</InfoItem.Text>
	</InfoItem>
);

InfoWithLabel.propTypes = {
	label: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default InfoWithLabel;
