import papeisOptions from '../papeisOptions';

const emptyCell = '-';

const papeisMap = papeisOptions.reduce((acc, cur) => {
	acc[cur.value] = cur.label;
	return acc;
}, {});

const numerosToPaPel = (nums) => nums.map((n) => papeisMap[n]).join(' ,');

export default () => [
	{
		title: 'Usuário',
		key: '1',
		dataIndex: 'nome',
		onFilter: (value, record) => record.name.includes(value),
		sorter: (a, b) => a.nome.length - b.nome.length,
		render: (value) => value || emptyCell,
		align: 'left',
		width: 180,
		ellipsis: true,
	},
	{
		title: 'Papéis',
		key: '2',
		dataIndex: 'papeis',
		render: (value) => numerosToPaPel(value) || emptyCell,
		onFilter: (value, record) => record.papeis.toLowerCase().includes(value.toLowerCase()),
		sorter: (a, b) => a.papeis.length - b.papeis.length,
		width: 200,
		ellipsis: true,
	},
	{
		title: 'Alçada',
		key: '3',
		dataIndex: 'valor_alcada_compra',
		render: (value, record) => {
			if (record.compra_ilimitada) return 'Ilimitado para compras';
			return value || emptyCell;
		},
		onFilter: (value, record) => record.valor_alcada_compra === value,
		sorter: (a, b) => a.valor_alcada_compra - b.valor_alcada_compra,
		width: 160,
		ellipsis: true,
	},
];
