import * as Types from './types';

export const getObras = (userId) => ({
	type: Types.OBRAS_GET_LIST_REQUEST,
	payload: { userId },
});

export const getObrasSuccess = (data) => ({
	type: Types.OBRAS_GET_LIST_SUCCESS,
	payload: { data },
});

export const getObrasError = (erro_motivo) => ({
	type: Types.OBRAS_GET_LIST_ERROR,
	payload: { erro_motivo },
});

export const createObra = (data) => ({
	type: Types.OBRAS_CREATE_REQUEST,
	payload: { data },
});

export const createObraSuccess = (data) => ({
	type: Types.OBRAS_CREATE_SUCCESS,
	payload: { data },
});

export const editObra = (data) => ({
	type: Types.OBRAS_EDIT_REQUEST,
	payload: { data },
});

// Edit status obra
export const alteraStatusObrasVisualizacao = (id, data) => ({
	type: Types.ASSOCIA_OBRAS_STATUS_VISUALIZACAO,
	payload: { id, data },
});

export const alteraStatusObrasAtivo = (id, data) => ({
	type: Types.ASSOCIA_OBRAS_STATUS_ATIVO,
	payload: { id, data },
});

export const editObrasSuccess = (data) => ({
	type: Types.OBRAS_EDIT_SUCCESS,
	payload: { data },
});

export const blockObras = (id, block) => ({
	type: Types.OBRAS_BLOCK_REQUEST,
	payload: { id, block },
});

export const blockObrasSuccess = (id, block) => ({
	type: Types.OBRAS_BLOCK_SUCCESS,
	payload: { id, block },
});

export const deleteObra = (id) => ({
	type: Types.OBRAS_DELETE_REQUEST,
	payload: { id },
});

export const deleteObraSuccess = (id) => ({
	type: Types.OBRAS_DELETE_SUCCESS,
	payload: { id },
});

// Associa Empresa
export const AssociaEmpresa = (data) => ({ // data é um array de empresas
	type: Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_REQUEST,
	payload: { data },
});

export const associaEmpresaSuccess = (data, obra_id) => ({
	type: Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_SUCCESS,
	payload: { data, obra_id },
});

export const associaEmpresaError = (erro_motivo) => ({
	type: Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_ERROR,
	payload: { erro_motivo },
});

// Associa usuário
export const associaUsuario = (data) => ({
	type: Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_REQUEST,
	payload: { data },
});

export const associaUsuarioSuccess = (obra_id, usuario) => ({
	type: Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_SUCCESS,
	payload: { obra_id, usuario },
});

export const associaUsuarioError = (erro_motivo) => ({
	type: Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_ERROR,
	payload: { erro_motivo },
});

export const actionError = (erro_motivo) => ({
	type: Types.OBRAS_ACTION_ERROR,
	payload: { erro_motivo },
});
