/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// Icons
import {
	CalendarOutlined,
	SendOutlined,
	CreditCardOutlined,
	UserOutlined,
	TeamOutlined,
	PaperClipOutlined,
	FileTextOutlined,
} from '@ant-design/icons';
import CarbonDelivery from 'icons/carbon_delivery.svg';
import GrommetLocation from 'icons/grommet-icons_location.svg';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { editMomentDateFormat, editEnviadoEmFormat } from '../../components/_functions/_editDateFormat';

// Content
import { formas_pagamento, prazos_pagamento_full } from '../../components/_content/_condicoes_pagamento';

const TabDetalhes = ({
	enviado_em,
	prazo_de_resposta,
	data_entrega_desejada,
	obra_nome,
	observacoes,
	observacoes_internas,
	observacoes_conaz,
	anexos,
	infoFornecedor,
	rcs,
	usuario,
	aprovado_por,
	aprovacao_pedida_por,
	condicao_de_preferencia,
}) => {
	const enviado_em_view = (enviado_em !== '' && enviado_em !== null)
		? editEnviadoEmFormat(enviado_em) : [<em className="em">Sem registro</em>, ''];
	const prazo_de_resposta_view = (prazo_de_resposta !== '' && prazo_de_resposta !== null)
		? editMomentDateFormat(prazo_de_resposta) : <em className="em">Sem registro</em>;
	const data_entrega_desejada_view = (data_entrega_desejada !== '' && data_entrega_desejada !== null)
		? editMomentDateFormat(data_entrega_desejada) : <em className="em">Sem registro</em>;

	const fornecedores_selecionados = rcs
		.filter(rc => rc.tipo_selecao_fornecedor === 1 || rc.tipo_selecao_fornecedor === 2);

	/**
	 * Preferência de pagamento
	 */
	const existe_preferencia = (
		condicao_de_preferencia && (condicao_de_preferencia.id !== undefined &&
		condicao_de_preferencia.id !== null &&
		(
			condicao_de_preferencia.forma !== 0 ||
			condicao_de_preferencia.prazo !== 0
		))
	);
	// forma
	const forma_preferencia = condicao_de_preferencia && ((existe_preferencia && condicao_de_preferencia.forma === -1)
		? condicao_de_preferencia.outra_forma
		: (formas_pagamento[condicao_de_preferencia.forma] || {}).view);
	// prazo
	const prazo_preferencia = condicao_de_preferencia && ((existe_preferencia && condicao_de_preferencia.prazo === -1)
		? condicao_de_preferencia.outro_prazo
		: (prazos_pagamento_full[condicao_de_preferencia.prazo] || {}).view);
	const preferencia = !existe_preferencia
		? <em style={{ color: '#262626' }}>-</em>
		: <span>{forma_preferencia}: {prazo_preferencia}</span>;

	return (
		<div styleName="content" id="tab-detalhes">
			<div className="row">
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block">
						<SendOutlined styleName="icons-send-detalhes" />
						<p>Enviado em</p>
						<p styleName="labels-info-detalhes">{enviado_em_view[0]} {' '} às {enviado_em_view[1]}</p>
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block">
						<CalendarOutlined styleName="icons-tab-detalhes" />
						{/* <i className="fa fa-clock-o" styleName="clock-o" aria-hidden="true" /> */}
						<p>Prazo de resposta</p>
						<p styleName="labels-info-detalhes">{prazo_de_resposta_view}</p>
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block">
						<img src={CarbonDelivery} alt="Entrega" styleName="icons-tab-detalhes" />
						<p>Entrega estimada</p>
						<p styleName="labels-info-detalhes">{data_entrega_desejada_view}</p>
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block last-obra">
						<img src={GrommetLocation} alt="Obra" styleName="icons-tab-detalhes" />
						<p>Obra</p>
						<p styleName="labels-info-detalhes">{obra_nome}</p>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block last">
						<CreditCardOutlined styleName="icons-tab-detalhes" />
						<p>Preferência de pagamento</p>
						<p styleName="labels-info-detalhes">{preferencia}</p>
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					{usuario.id !== undefined && (
						<div styleName="content-block last">
							<UserOutlined styleName="icons-tab-detalhes" />
							<p>Criado por:</p>
							<b styleName="labels-info-detalhes">{usuario.nome}</b>
						</div>
					)}
				</div>
				{(aprovacao_pedida_por.id !== undefined) && (
					<div className="col-xs-12 col-md-3">
						<div styleName="content-block last">
							<UserOutlined styleName="icons-tab-detalhes" />
							<p>Solicitou envio:</p>
							<b styleName="labels-info-detalhes">{aprovacao_pedida_por.nome}</b>
						</div>
					</div>
				)}
				{(aprovado_por.id !== undefined) && (
					<div className="col-xs-12 col-md-3">
						<div styleName="content-block last">
							<UserOutlined styleName="icons-tab-detalhes" />
							<p>Enviado por:</p>
							<b styleName="labels-info-detalhes">{aprovado_por.nome}</b>
						</div>
					</div>
				)}
			</div>
			<div className="row">
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block last">
						<TeamOutlined styleName="icons-tab-detalhes" />
						<p>Fornecedores selecionados</p>
						{fornecedores_selecionados.map((rc, i) => {
							const uuid = (rc || {}).uuid || '';
							const fornecedor_id = ((rc || {}).fornecedor || {}).id || 0;
							const fornecedor_nome = ((rc || {}).fornecedor || {}).nome || '';
							const fornecedor_usuario = (rc || {}).usuario || {};

							return (
								<div key={uuid} styleName="fornecedor-selecionado">
									<button type="button" onClick={() => infoFornecedor(fornecedor_id, fornecedor_usuario)}>
										<div styleName="number">{i + 1}</div>
										<div styleName="nome">{fornecedor_nome}</div>
									</button>
								</div>
							);
						})}
						{fornecedores_selecionados.length === 0 && <em style={{ color: '#c3c6c9' }}>Nenhum fornecedor selecionado</em>}
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block last info-anexos">
						<PaperClipOutlined styleName="icons-tab-detalhes" />
						<p>Anexos</p>
						{anexos.map((anexo, a) => ( // eslint-disable-next-line react/no-array-index-key
							<div key={a} styleName="anexo-row">
								<span>{a + 1}</span> <a href={anexo.url} target="_blank" rel="noopener noreferrer">{anexo.nome}</a>
							</div>
						))}
						{anexos.length === 0 && <strong styleName="anexos-detalhes" style={{ color: '#262626' }}>0 arquivos</strong>}
					</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<div styleName="content-block last">
						<FileTextOutlined styleName="icons-tab-detalhes" />
						<p>Observações</p>
						{observacoes !== '' && observacoes_conaz === '' ? <strong styleName="obs-conaz">{observacoes}</strong> : observacoes === '' && observacoes_conaz !== '' ? <strong styleName="obs-conaz">{observacoes_conaz}</strong> : <strong styleName="obs-conaz" style={{ color: '##262626' }}>Nenhuma observação.</strong>}
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12">
					<div styleName="content-block last obs-internas">
						<p>Observações internas</p>
						{observacoes_internas !== '' ? <strong style={{ color: '#595959' }}>&#34;{observacoes_internas}&#34;</strong> : <strong style={{ color: '#595959' }}>Nenhuma observação.</strong>}
					</div>
				</div>
			</div>
		</div>
	);
};

TabDetalhes.propTypes = {
	enviado_em: PropTypes.string,
	prazo_de_resposta: PropTypes.string,
	data_entrega_desejada: PropTypes.string,
	obra_nome: PropTypes.string,
	observacoes: PropTypes.string,
	observacoes_conaz: PropTypes.string,
	observacoes_internas: PropTypes.string,
	anexos: PropTypes.array,
	infoFornecedor: PropTypes.func.isRequired,
	rcs: PropTypes.array,
	usuario: PropTypes.object.isRequired,
	aprovado_por: PropTypes.object.isRequired,
	aprovacao_pedida_por: PropTypes.object.isRequired,
	condicao_de_preferencia: PropTypes.object.isRequired,
};

TabDetalhes.defaultProps = {
	enviado_em: '',
	prazo_de_resposta: '',
	data_entrega_desejada: '',
	obra_nome: '',
	observacoes: '',
	observacoes_conaz: '',
	observacoes_internas: '',
	anexos: [],
	rcs: [],
};

export default CSSModules(TabDetalhes, styles, { allowMultiple: true });
