import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Content
import {
	formas_pagamento,
	prazos_pagamento_full,
} from '../../_content/_condicoes_pagamento';

const CompraRowPagamento = ({ compra }) => {
	const { condicao_de_pagamento } = compra;
	const { forma, outra_forma, prazo, outro_prazo } = condicao_de_pagamento;

	const forma_pagamento_view = (forma === -1 || forma === -2 || forma === null)
		? outra_forma
		: (formas_pagamento[forma] || {}).view || '';
	const prazo_pagamento_view = (prazo === -1 || prazo === -2 || prazo === null)
		? outro_prazo
		: (prazos_pagamento_full[prazo] || {}).view || '';
	return (
		<>
			{(forma_pagamento_view !== '' && forma_pagamento_view)
				|| (prazo_pagamento_view !== '' && prazo_pagamento_view) ? (
					<Tooltip placement="topLeft" title={`${forma_pagamento_view} : ${prazo_pagamento_view}`} color="#0076D6">
						<div styleName="condicao">
							Pagamento
							<p>{forma_pagamento_view}: {prazo_pagamento_view}</p>
						</div>

					</Tooltip>
				) : (
					<div styleName="condicao">
						Pagamento
						<p><em styleName="em">Sem registro</em></p>
					</div>
				)}

		</>
	);
};

CompraRowPagamento.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowPagamento, styles, { allowMultiple: true });
