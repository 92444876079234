import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getLocalstorageData } from '../components/_functions/_getLocalstorageData';

/* ------------------------------------------------------ *\
	get
\* ------------------------------------------------------ */
const baseURL = getLocalstorageData('environment', 'base_url');
export const instance_get = axios.create({
	baseURL,
	maxRedirects: 0,
});

let retries = 0;
axiosRetry(instance_get, {
	retries: 3,
	retryCondition: (error) => {
		const { config } = error;
		if (!config) return false;
		retries++;
		if (retries >= 3) {
			retries = 0;
			return false;
		}
		return true;
	},
});
