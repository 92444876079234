const criterios = [
	{
		text: 'A entrega foi realizada dentro do prazo?',
		success: 'success',
		failed: 'failed',
	},
	{
		text: 'Os documentos (NFs, boletos, etc) foram entregues corretamente?',
		success: 'success',
		failed: 'failed',
	},
	{
		text: 'Os materiais entregues estavam de acordo com a solicitação?',
		success: 'success',
		failed: 'failed',
	},
	{
		text: 'Os materiais estavam em boas condições de uso?',
		success: 'success',
		failed: 'failed',
	},
];

export default criterios;
