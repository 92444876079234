import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IntegracaoSearchInput from './IntegracaoSearchInput';
import SearchInputAlt from './SearchInputAlt';
import IntegracaoSearchResult from './IntegracaoSearchResult';
import IntegracaoSugestoes from './IntegracaoSugestoes';
import Loading from '../../../../_loadings/Loading/Loading';

class IntegracaoSearch extends Component {
	state = {
		search_state: 0,
		switch_input: false,
		results: [],
		// search: '',
	};

	updateState = (fields_n_values) => {
		this.setState(fields_n_values);
	}

	switchInputs = () => {
		this.setState({
			switch_input: !this.state.switch_input,
			search_state: 0,
		});
	}

	render() {
		const {
			search_state,
			results,
			// search,
			switch_input,
		} = this.state;
		const {
			item_on_edition,
			updateFullItemById,
			obra_id,
			client_id,
			autoSave,
		} = this.props;

		return (
			<>
				<div styleName="title">
					Insumo Sienge
				</div>
				<div styleName="search">
					{!switch_input && (
						<IntegracaoSearchInput
							updateState={this.updateState}
						/>
					)}

					{switch_input && (
						<SearchInputAlt
							client_id={client_id}
							obra_id={obra_id}
							updateState={this.updateState}
						/>
					)}

					{search_state === 1 && (
						<div styleName="no-result-wrapper">
							<Loading />
						</div>
					)}

					{search_state === 2 && (
						results.map(item => {
							const detalhe_id = (item.detalhe || {}).id || '';
							const key = `${item.id}${detalhe_id}`;

							return (
								<IntegracaoSearchResult
									key={key}
									item_on_edition={item_on_edition}
									item={item}
									updateFullItemById={updateFullItemById}
									autoSave={autoSave}
								/>
							);
						})
					)}

					{(search_state === 2 && results.length === 0) && (
						<>
							<div styleName="no-result-wrapper">
								Nenhum resultado encontrado no nosso sistema.
							</div>
							<Link to="/integracao/sienge/itens" className="btn btn-link">
								Deseja atualizar seus insumos do Sienge?
							</Link>
							<button
								onClick={this.switchInputs}
								className="btn btn-link">
								Deseja fazer uma requisição no Sienge para encontrar este insumo?
							</button>
						</>
					)}

					{search_state === 3 && !switch_input && (
						<div styleName="no-result-wrapper">
							Desculpe, aconteceu algo errado. Tente novamente.
						</div>
					)}

					{search_state === 3 && switch_input && (
						<div styleName="no-result-wrapper">
							Não encontramos este Insumo no Sienge.
						</div>
					)}

					{(search_state === 0 && results.length === 0 && !switch_input) && (
						<IntegracaoSugestoes
							item_on_edition={item_on_edition}
							updateFullItemById={updateFullItemById}
							autoSave={autoSave}
							client_id={client_id}
						/>
					)}
				</div>
			</>
		);
	}
}

IntegracaoSearch.propTypes = {
	item_on_edition: PropTypes.object.isRequired,
	obra_id: PropTypes.number.isRequired,
	client_id: PropTypes.number.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IntegracaoSearch, styles, { allowMultiple: true });
