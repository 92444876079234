import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import DropArea from './DropArea';
import FilesToSend from './FilesToSend';
import FilesToUpdate from './FilesToUpdate';

class ModalUpload extends Component {
	constructor(props) {
		super(props);
		this.state = {
			files_temp_id: 0,
			arquivos_para_enviar: {},
			nao_quer_adicionar_mais: true,
			enviando: false,
		};
	}

	addFilesToState = (files, last_id) => {
		const { arquivos_para_enviar, currentSize } = this.state;
		this.setState({ currentSize: currentSize + 1 });
		const all = {
			...arquivos_para_enviar,
			...files,
		};
		this.setState({ arquivos_para_enviar: all, files_temp_id: last_id });
	}

	updateEnviandoStatus = () => this.setState({ enviando: false });

	updateEnviarMais = () => {
		const { nao_quer_adicionar_mais } = this.state;
		this.setState({ nao_quer_adicionar_mais: !nao_quer_adicionar_mais });
	}

	updateFiles = (field, files) => {
		this.setState({ [field]: files });
	}

	blockCloseButton = () => {
		const enviando = !this.state.enviando;
		this.setState({ enviando });
	}

	handleDropArea = () => {

	}

	render() {
		const {
			arquivos_para_enviar,
			enviando,
			files_temp_id,
			nao_quer_adicionar_mais,
		} = this.state;
		const {
			closeModal,
			config,
			arquivo_para_editar,
			updateCompras,
			anexos,
			updateUi,
		} = this.props;
		const tem_arquivos_selecionados = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp).length !== 0;
		const tem_arquivo_para_editar = arquivo_para_editar.id !== undefined;
		const _arquivo_para_editar = !tem_arquivo_para_editar
			? {}
			: { ...arquivo_para_editar, id_temp: arquivo_para_editar.id };

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					{!enviando && (
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={closeModal}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					<div styleName="content">
						<h3>Anexos</h3>
						{!tem_arquivo_para_editar ? (
							<>
								{tem_arquivos_selecionados && nao_quer_adicionar_mais
									? (
										<FilesToSend
											{...{
												config,
												arquivos_para_enviar,
												updateCompras,
												updateUi,
												anexos,
												closeModal,
											}}
											updateEnviarMais={this.updateEnviarMais}
											updateEnviandoStatus={this.updateEnviandoStatus}
											updateFiles={this.updateFiles}
											blockCloseButton={this.blockCloseButton} />
									) :
									(
										<DropArea
											updateEnviarMais={this.updateEnviarMais}
											nao_quer_adicionar_mais={nao_quer_adicionar_mais}
											files_temp_id={files_temp_id}
											addFilesToState={this.addFilesToState} />
									)}
							</>
						)
							:
							(
								<FilesToUpdate
									{...{
										config,
										updateCompras,
										anexos,
										closeModal,
									}}
									arquivo_para_editar={_arquivo_para_editar}
									blockCloseButton={this.blockCloseButton} />
							)}
					</div>
				</div>
			</div>
		);
	}
}

ModalUpload.propTypes = {
	closeModal: PropTypes.func,
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	arquivo_para_editar: PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	}),
	updateCompras: PropTypes.func.isRequired,
	anexos: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	})).isRequired,
	updateUi: PropTypes.func.isRequired,
};

ModalUpload.defaultProps = {
	arquivo_para_editar: {},
	closeModal: undefined,
};

export default CSSModules(ModalUpload, styles, { allowMultiple: true });
