import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import withAddItemFuncs from '../../_hocs/withAddItemFuncs';
import ItemRowWrapper from './ItemRowWrapper';
import Tooltip from '../../../_tooltips/ButtonToolTip/ButtonToolTip';

// Functions
import { formatCurrency } from '../../../../_functions/_formatCurrency';
import { _put, _post, _delete } from '../../../../_functions/_requests';
import { setClasses } from '../../../../_functions/_setClasses';
import { handleRequestErrors } from '../../../../_functions/_handleRequestErrors';

import AssociaFornecedor from '../../../../../icons/associacao_fornecedor.svg';

class ItemResumido extends Component {
	state = {
		confirm_delete: false,
		deleting: 0,
		copying: 0,
	};

	openItemEditionLocal = (e) => {
		e.preventDefault();
		const { deleting } = this.state;
		if (deleting === 1) return;
		const { item, openItemEdition } = this.props;
		openItemEdition(item.id);
	}

	handleDelete = () => {
		const { confirm_delete } = this.state;
		this.setState({ confirm_delete: !confirm_delete });
	}

	deleteItem = () => {
		const {
			item,
			updateItemById,
			deleteItem,
			closeItemEdition,
		} = this.props;
		closeItemEdition();
		this.setState({ deleting: 1, confirm_delete: false });
		const url = `/conaz/v2/itens_de_compra/${item.id}`;
		_delete(url).then(() => {
			const item_de_cotacao = (item.item_de_resposta || {}).id !== undefined;
			if (item_de_cotacao) {
				updateItemById(item.id, 'excluido', true);
				this.setState({ deleting: 0 });
				return;
			}
			deleteItem(item.id);
		}).catch(error => {
			this.checkError('deleting', error);
		});
	}

	restoreItem = () => {
		const { item, updateItemById } = this.props;
		this.setState({ deleting: 1, confirm_delete: false });
		const url = `/conaz/v2/itens_de_compra/${item.id}`;
		const params = { excluido: false };
		_put(url, params).then(() => {
			updateItemById(item.id, 'excluido', false);
			this.setState({ deleting: 0 });
		}).catch(error => {
			this.checkError('deleting', error);
		});
	}

	duplicateItem = () => {
		const { item, addItemToPedido } = this.props;
		this.setState({ copying: 1 });
		const url = `/conaz/v2/itens_de_compra/${item.id}/duplicar`;
		_post(url, {}).then(response => {
			addItemToPedido(response.data, null);
			this.setState({ copying: 0 });
		}).catch(error => {
			this.checkError('copying', error);
		});
	}

	checkError(field, error) {
		this.setState({ [field]: 3 });

		const { handleModalErroEdicao, updateModals } = this.props;
		const conaz_error = ((
			error.response || {})
			.data || {})
			.message || '';

		if (conaz_error === 'Apenas os responsáveis da compra podem editá-la.') {
			handleModalErroEdicao();
			return;
		}

		updateModals('error', true);
		handleRequestErrors(error);
	}

	render() {
		const {
			confirm_delete,
			deleting,
			copying,
		} = this.state;
		const {
			item,
			total,
			toggle,
			handleCollapse,
			selected,
			compra_has_rc,
			is_editable,
		} = this.props;
		const {
			preco_unitario,
			desconto,
			editado,
			item_de_resposta,
			front,
			importado_planilha,
		} = item;
		const item_preenchido = item.item_preenchido || {};
		const nome = !item.cliente_associou
			? item.pre_nome
			: (item_preenchido.item || {}).nome;
		const quantidade = !item.cliente_associou
			? item.pre_quantidade || 0
			: item.quantidade || 0;
		const unidade = !item.cliente_associou
			? item.pre_unidade || ''
			: item.unidade || '';

		const excluido = item.excluido || false;

		// item fora da CPIC
		const item_fora_da_cpic = (item_preenchido.item || {}).id === undefined;

		const has_errors = (front.errors || []).length > 0;
		const item_classes = {
			expanded: toggle,
			selected,
			has_errors,
			deleted: deleting === 1,
			excluido,
		};
		const item_class = setClasses('item', item_classes);

		// item novo
		const item_de_resposta_id = item_de_resposta.id || null;
		const item_novo = (
			compra_has_rc
			&& item_de_resposta_id === null
			&& (item.cliente_associou || !item.item_preenchido?.item)
			&& !editado
		);

		/**
		 * -1 - fornecedor não tem
		 *  0 - incluso
		 *  1 - normal
		 */
		const { status } = item_de_resposta;
		const removido_msg = status === -1
			? 'Fornecedor não possui este item'
			: 'Item removido da compra';

		// desconto no valor unitário para mobile
		const valor_desconto_unitario = (preco_unitario / 100) * desconto;
		const unitario_com_desconto = (preco_unitario - valor_desconto_unitario);

		return (
			<div id={`item-list-${item.id}`} styleName="item-wrapper">
				{!confirm_delete ? (
					<>
						{(deleting !== 1 && copying !== 1) && (
							<div styleName="buttons">
								{(is_editable && !excluido) && (
									<>
										<button
											type="button"
											styleName="button-inline"
											onClick={this.duplicateItem}>
											<i className="fa fa-clone" aria-hidden="true" />
										</button>
										<button
											type="button"
											styleName="button-inline"
											onClick={this.handleDelete}>
											<i className="fa fa-trash" />
										</button>
									</>
								)}
								{(is_editable && excluido && status !== -1) && (
									<button
										type="button"
										styleName="button-inline"
										onClick={this.restoreItem}>
										<i className="fa fa-repeat" />
									</button>
								)}
								<button
									type="button"
									styleName="button-inline"
									onClick={handleCollapse}>
									{!toggle ? (
										<i className="fa fa-expand" />
									) : (
										<i className="fa fa-compress" />
									)}
								</button>
							</div>
						)}
					</>
				) : (
					<div styleName="confirm-delete">
						Deseja apagar este item?
						<button
							type="button"
							className="btn btn-danger-conaz round-border"
							styleName="button-delete"
							onClick={this.deleteItem}>
							Apagar
						</button>
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="button-cancelar"
							onClick={this.handleDelete}>
							Manter
						</button>
					</div>
				)}
				<ItemRowWrapper
					is_editable={is_editable}
					excluido={excluido}
					openItemEditionLocal={this.openItemEditionLocal}>
					<div styleName={item_class}>
						{importado_planilha === 1 ? (
							<div styleName="nome nao-associado">
								<div styleName="number">{front.key}.</div>
								<i>{item.pre_nome} {item.pre_detalhe}</i> <i className="fa fa-chain-broken fa-fw" aria-hidden="true" />
							</div>
						) : (
							<>
								<div styleName="nome">
									{/* (has_outro && is_editable) && (
										<i className="fa fa-exclamation-circle" styleName="outro-valor-warning-icon" aria-hidden="true" />
									) */}
									<div styleName="number">{front.key}.</div>
									{nome}
									{editado && (
										<div>
											<div styleName="tag editado">Editado</div>
										</div>
									)}
									{item_novo && (
										<div>
											<div styleName="tag novo">Item novo</div>
										</div>
									)}
									{item_fora_da_cpic && (
										<div>
											<div styleName="tag fora-cpic" style={{ marginLeft: item_novo ? '2px' : '0' }}>
												Fora do Padrão
												<div styleName="action-tooltip">
													<Tooltip
														bottom="15px"
														margin_left="25px"
														width="150px"
														styleArrow={{ left: '10%' }}
														text="Insumo não cadastrado na base Conaz" />
												</div>
											</div>

										</div>
									)}
								</div>
								<div styleName="quantidade">
									{/* <div styleName="label-mobile">Quantidade</div> */}
									{quantidade} {unidade}
									<span styleName="mobile-values spaces"> x </span>
									<span styleName="mobile-values unitario">{formatCurrency(unitario_com_desconto, 2, 'R$')}</span>
									<span styleName="mobile-values total"><b>Total:</b> {formatCurrency(total, 2, 'R$')}</span>
									{item.id_no_swi !== '' && item.id_no_swi ? (
										<button styleName="mobile-values status_associado" onClick={this.openItemEditionLocal}><img src={AssociaFornecedor} alt="Item associado" /></button>
									) : ''}
								</div>
								{!excluido ? (
									<>
										<div styleName="valor-unitario">
											{/* <div styleName="label-mobile">Valor unitário</div> */}
											{formatCurrency(preco_unitario, 2, 'R$')}
										</div>
										<div styleName="desconto">
											<div styleName="label-mobile">Desconto</div>
											{desconto}%
										</div>
										<div styleName="valor-total">
											<div styleName="label-mobile">Total</div>
											{formatCurrency(total, 2, 'R$')}
											{item.id_no_swi !== '' && item.id_no_swi ? (
												<button styleName="mobile-values status_associado" onClick={this.openItemEditionLocal}><img src={AssociaFornecedor} alt="Item associado" /></button>
											) : ''}
										</div>
									</>
								) : (
									<div styleName="item-removido">
										{removido_msg}
									</div>
								)}
							</>
						)}
					</div>
				</ItemRowWrapper>
			</div>
		);
	}
}

ItemResumido.propTypes = {
	item: PropTypes.object.isRequired,
	total: PropTypes.number.isRequired,
	toggle: PropTypes.bool.isRequired,
	handleCollapse: PropTypes.func.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	closeItemEdition: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	compra_has_rc: PropTypes.bool.isRequired,
	deleteItem: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
	addItemToPedido: PropTypes.func.isRequired,
	is_editable: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	handleModalErroEdicao: PropTypes.func.isRequired,
	/* Props que vao para o HOC withAddItemFuncs */
	/* eslint-disable react/no-unused-prop-types */
	type: PropTypes.string.isRequired,
};

const _ItemResumido = CSSModules(ItemResumido, styles, { allowMultiple: true });
export default withAddItemFuncs(_ItemResumido);
