import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import LayoutWrapper from '../../components/LayoutWrapper';
import EscolheCompra from './EscolheCompra';

class EscolheCompraContainer extends Component {
	componentDidMount() {
		// google analytics
		window.ga('set', 'page', window.location.pathname + window.location.search);
		window.ga('send', 'pageview');
		// page title
		document.title = 'Nova compra - Conaz';
		// zera info para nova compra
		const criar_compra = {
			pedido: {},
			rc: {},
			rc_analise: {},
			type: '',
		};
		this.props.updateCompras(['criar_compra'], criar_compra);
	}

	selecionaCotacaoAlert = (e) => {
		e.preventDefault();
		const { ui, updateUi, history } = this.props;
		const alerts = {
			...ui.alerts,
			escolhe_cotacao: { visible: true, message: '' },
		};
		updateUi(['alerts'], alerts);
		history.push('/cotacoes');
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>
				<EscolheCompra
					{...this.props}
					selecionaCotacaoAlert={this.selecionaCotacaoAlert} />
			</LayoutWrapper>
		);
	}
}

EscolheCompraContainer.propTypes = {
	// =========== store
	cotacao: PropTypes.object.isRequired,
	cotacoes: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateCompras: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(EscolheCompraContainer));
