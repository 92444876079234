/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const Step = ({
	stage,
	current,
	label,
	descricao,
	last,
	status_front,
	beforeLast,
	lastState,
	done,
}) => {
	const isDone = status_front === 'confirmada' || lastState.label.includes('Exportada');
	const handle_css_step = () => {
		let cancelado = label === 'Cancelada em' || label === 'Compra recusada em';
		let registred =
			label === 'Registrada e aprovada '
			|| label === 'Registrada e aprovada em '
			|| label === 'Confirmada pelo fornecedor em'
			|| lastState.label === 'Confirmada por você em'
			|| isDone
			|| (!last);
		const active = stage <= current;
		if (beforeLast) {
			cancelado = lastState.label === 'Cancelada em' || lastState.label === 'Compra recusada em';
			registred =
				lastState.label === 'Registrada e aprovada '
				|| lastState.label === 'Confirmada pelo fornecedor em'
				|| label === 'Registrada e aprovada em '
				|| lastState.label === 'Confirmada por você em'
				|| isDone;
			if (cancelado) return 'cancelado';
			if (registred) return 'registred';
			if (active) return 'active';
			return '';
		}
		if (cancelado) return 'cancelado';
		if (registred) return 'registred';
		if (active) return 'active';
		return '';
	};
	const step = handle_css_step();
	if (last) {
		return (
			<div styleName="step-view">
				{stage === current && !done ? (
					<div styleName={`dot-container ${step}`}>
						<div styleName="dot" />
					</div>
				) : (
					<i className="fa fa-check-circle fa-fw" aria-hidden="true" />
				)}
				<div styleName="descricao">
					<span styleName="label">{label}</span>
					<span>{descricao}</span>
				</div>
			</div>
		);
	}
	return (
		<div styleName="step-view">
			{stage === current && !done ? (
				<div styleName="dot-container">
					<div styleName="dot" />
				</div>
			) : (
				<i className="fa fa-check-circle fa-fw" aria-hidden="true" />
			)}
			<div styleName="descricao">
				<span styleName="label">{label}</span>
				<span>{descricao}</span>
			</div>
			<div styleName={beforeLast && !isDone ? `step ${step} dashed` : `step ${step}`} />
		</div>
	);
};

Step.propTypes = {
	status_front: PropTypes.string.isRequired,
	last: PropTypes.bool.isRequired,
	beforeLast: PropTypes.bool.isRequired,
	stage: PropTypes.number,
	current: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
	label: PropTypes.string.isRequired,
	descricao: PropTypes.object,
	done: PropTypes.bool,
	lastState: PropTypes.object.isRequired,
};

Step.defaultProps = {
	done: false,
	stage: 1,
	descricao: null,
};

export default CSSModules(Step, styles, { allowMultiple: true });
