import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedoresSearchResult from './FornecedoresSearchResult';
import Loading from '../_loadings/Loading/Loading';

const FornecedoresSearchResultList = ({
	columns_height,
	search_fornecedor_state,
	search_state,
	request_state_populares,
	populares_fornecedores_all_ids,
	populares_fornecedores_by_id,
	fornecedores_all_ids,
	fornecedores_result,
	cliente_id_cadastro,
	adicionaFornecedor,
	adicionaFornecedorNaoCadastrado,
	search,
	search_type,
}) => (
	<div styleName="search-results-column">
		{search_fornecedor_state === 1 ? (
			<Scrollbars style={{ height: columns_height }}>
				<Loading />
			</Scrollbars>
		) : (
			<Scrollbars style={{ height: columns_height }}>
				{(search_state === 0 && request_state_populares === 1) && <Loading />}
				{(search_state === 0 && request_state_populares === 2 && populares_fornecedores_all_ids.length > 0) && (
					<div>
						<h3 styleName="sugestoes-title">Alguns fornecedores cadastrados que podem te atender</h3>
						{populares_fornecedores_all_ids.map(fornecedor => (
							<FornecedoresSearchResult
								key={fornecedor}
								fornecedor={populares_fornecedores_by_id[fornecedor]}
								adicionaFornecedor={adicionaFornecedor}
								already_selected={fornecedores_all_ids.filter(x => x === fornecedor).length > 0}
								cliente_id={cliente_id_cadastro}
							/>
						))}
					</div>
				)}
				{(
					search_state === 0
					&& (
						request_state_populares === 3
						|| (
							request_state_populares === 2
							&& populares_fornecedores_all_ids.length === 0
						)
					)
				) && (
					<div styleName="search-assist">
						<i className="fa fa-long-arrow-up" aria-hidden="true" />Digite acima o nome do fornecedor desejado.
					</div>
				)}

				{search_state === 1 && <Loading />}
				{search_state === 2 && fornecedores_result.map(fornecedor => (
					<FornecedoresSearchResult
						key={fornecedor.id}
						fornecedor={fornecedor}
						adicionaFornecedor={adicionaFornecedor}
						already_selected={fornecedores_all_ids.filter(x => x === fornecedor.id).length > 0}
						cliente_id={cliente_id_cadastro} />
				))}
				{search_state === 2 && (
					<div styleName="btn-add-mesmo-assim">
						<button type="button" onClick={() => adicionaFornecedorNaoCadastrado(search)}>
							{(search_type === 'email' || search_type === 'documento') ? 'Cadastrar novo fornecedor' : <span>Adicionar <b>{search}</b> mesmo assim.</span>}
						</button>
					</div>
				)}
			</Scrollbars>
		)}
	</div>
);

FornecedoresSearchResultList.propTypes = {
	columns_height: PropTypes.number.isRequired,
	search_fornecedor_state: PropTypes.number.isRequired,
	search_state: PropTypes.number.isRequired,
	request_state_populares: PropTypes.number.isRequired,
	populares_fornecedores_all_ids: PropTypes.array.isRequired,
	populares_fornecedores_by_id: PropTypes.object.isRequired,
	fornecedores_all_ids: PropTypes.array.isRequired,
	fornecedores_result: PropTypes.array.isRequired,
	cliente_id_cadastro: PropTypes.number.isRequired,
	adicionaFornecedor: PropTypes.func.isRequired,
	adicionaFornecedorNaoCadastrado: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	search_type: PropTypes.string.isRequired,
};

export default CSSModules(FornecedoresSearchResultList, styles, { allowMultiple: true });
