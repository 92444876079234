export const formatFornecedores = rcs => {
	const filtrados = rcs.filter(f => f.status !== -30); // remove RC's cancelados
	const fornecedores_by_id = filtrados.reduce((result, current) => {
		const vendedores = current.fornecedor.usuarios.map(u => ({
			id: u.usuario.id,
			nome: u.usuario.nome,
			email: u.usuario.email,
			telefones: u.usuario.telefones,
			status: u.usuario.status,
			cadastrado_por: u.usuario.cadastrado_por,
			locais_de_entrega: u.locais_de_entrega,
			grupos: u.grupos,
			status_fornecedor_vendedor: u.status,
		}));
		/**
		 * tipo_selecao_fornecedor
		 *   1 => contrutor escolheu só fornecedor
		 *   2 => contrutor escolheu só fornecedor e vendedor
		 *   3 => conaz
		 */
		return {
			...result,
			[current.fornecedor.id]: {
				...current.fornecedor,
				rc_id: current.uuid,
				rc: {
					estah_resetado: current.estah_resetado,
					status: current.status,
				},
				vendedores,
				vendedor_selecionado: current.usuario,
				sem_preferencia_vendedor: current.tipo_selecao_fornecedor === 1,
				preenchimento_fornecedor: current.preenchimento_fornecedor,
				tipo_selecao_fornecedor: current.tipo_selecao_fornecedor,
			},
		};
	}, {});
	const fornecedores_all_ids = Object.keys(fornecedores_by_id).map(id => Number(id));
	return {
		fornecedores_by_id,
		fornecedores_all_ids,
		request_state_fornecedores: 2,
	};
};
