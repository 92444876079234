import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalRating from '../ModalRating';

// Avaliacoes
import { avaliacao_vendedor } from '../ModalRating/avaliacoes';

const AvaliaVendedorButton = ({
	compra,
}) => {
	const [modal_evaluation, setModalEvaluation] = useState(false);

	function handleEvaluation() {
		setModalEvaluation(!modal_evaluation);
	}

	const nome_vendedor = (compra.usuario_fornecedor || {}).nome || '';
	const avaliacao = avaliacao_vendedor(nome_vendedor);

	const modalRating = modal_evaluation ? (
		<ModalPortal>
			<ModalWrapperPortal closeModal={handleEvaluation}>
				<ModalRating
					closeModal={handleEvaluation}
					avaliacao={avaliacao}
					compra={compra}
					location="vendedor"
				/>
			</ModalWrapperPortal>
		</ModalPortal>
	) : null;

	const nota_vendedor = ((
		compra.avaliacao_vendedor || {})
		.detalhe || {})
		.estrelas || 0;
	const ja_avaliado = nota_vendedor !== 0;

	if (ja_avaliado) {
		return (
			<div styleName="estrelas-avaliacao-vendedor">
				{[1, 2, 3, 4, 5].map(n => {
					const start_class = n <= nota_vendedor
						? 'active'
						: '';
					return (
						<i
							key={n}
							className="fa fa-star"
							styleName={start_class}
							aria-hidden="true"
						/>
					);
				})}
			</div>
		);
	}

	return (
		<>
			{modalRating}
			<button
				type="button"
				className="btn btn-link"
				styleName="avaliacao-vendedor-button"
				onClick={handleEvaluation}
			>
				Avaliar este vendedor
			</button>
		</>
	);
};

AvaliaVendedorButton.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(AvaliaVendedorButton, styles, { allowMultiple: true });
