import React from 'react';
import Proptypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';

// Components
import AvaliacoesHeader from './AvaliacoesHeader';
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';
import Rating from '../../components/Rating';

// data
import criterios from './criterios';

// Style
import {
	EvaluationBlock,
	HeaderCompra,
	Criterios,
	AvaliacaoVendedor,
	Title,
	Description,
	LinkButton,
	Score,
	ErrorWrapper,
	SectionPagination,
} from './Avaliacoes.style';

const Avaliacoes = ({
	avaliacoes,
	search,
	handleSearch,
	inputRef,
	handleMobileSearchClick,
	isSearching,
	commentAnalysis,
	handlePage,
}) => {
	const { fetching, page, result } = avaliacoes;

	if (fetching === 10) {
		return (
			<>
				<AvaliacoesHeader
					avaliacoes={avaliacoes}
					inputRef={inputRef}
					search={search}
					isSearching={isSearching}
					handleSearch={handleSearch}
					handleMobileSearchClick={handleMobileSearchClick}
				/>
				<LoadingPage />
			</>
		);
	}

	return (
		<>
			<AvaliacoesHeader
				avaliacoes={avaliacoes}
				inputRef={inputRef}
				search={search}
				isSearching={isSearching}
				handleSearch={handleSearch}
				handleMobileSearchClick={handleMobileSearchClick}
			/>
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						{/* <OrderBlock>
							<b>Ordenar por:</b>
							<button name="maior" onClick={handleOrder}>maior</button>|
							<button name="menor" onClick={handleOrder}>menor</button>|
							<button name="bloqueados" onClick={handleOrder}>bloqueados</button>
						</OrderBlock> */}
						{result.map(({ avaliacao_fornecedor, avaliacao_vendedor, compra }) => {
							const {
								id,
								titulo,
								fornecedor,
								obra,
							} = compra[0];
							const {
								estrelas: f_estrelas,
								respostas,
								comentario: f_comentario,
								status_comentario: fs_comentario,
							} = avaliacao_fornecedor.detalhe;
							const {
								estrelas: v_estrelas,
								comentario: v_comentario,
								status_comentario: vs_comentario,
							} = avaliacao_vendedor.detalhe;

							const _criterios = respostas.map(({ tipo_questao, resposta }) => {
								if (resposta) return { ...criterios[tipo_questao - 1], success: 'success selected' };
								return { ...criterios[tipo_questao - 1], failed: 'failed selected' };
							});

							const f_unrated = f_estrelas === 0 || !f_estrelas;
							const v_unrated = v_estrelas === 0 || !v_estrelas;

							const f_dt_avaliacao = moment().diff(moment(avaliacao_fornecedor.criado_em), 'days');
							const v_dt_avaliacao = moment().diff(moment(avaliacao_vendedor.criado_em), 'days');

							return (
								<EvaluationBlock key={id}>
									<HeaderCompra>
										<Link to={`/compra/${id}`} target="_blank">
											<span className="compra-id">#{id}</span>
											<span className="compra-title">{titulo}</span>
										</Link>
										<Score className="score">
											<Rating color="orange" lock rate={f_estrelas} />
											<span
												className={`score-number ${f_unrated ? 'unrated' : ''}`}>
												{f_estrelas}.0
											</span>{' '}
											{!f_unrated
												? <small>enviado há {f_dt_avaliacao} dias</small>
												: <LinkButton>Aguardando confirmação de entrega</LinkButton>
											}
										</Score>
										<div>
											<span className="fornecedor-label">Fornecedor</span>
											<span className="fornecedor">{fornecedor.nome}</span>
										</div>
										<div>
											<span className="fornecedor-label">Obra</span>
											<span className="fornecedor">{obra.nome}</span>
										</div>
										{/* <LinkButton>Editar avaliação</LinkButton> */}
									</HeaderCompra>
									<hr />

									<Criterios>
										<Title>Critérios de avaliação</Title>
										{_criterios.map(({ text, success, failed }) => (
											<div key={text} className="criterio-block">
												<span>{text}</span>
												<div>
													<span className={success}><i className="fa fa-check" /></span>
													<span className={failed}><i className="fa fa-times" /></span>
												</div>
											</div>
										))}
									</Criterios>
									<hr />

									{f_comentario !== '' && f_comentario !== null && (
										<>
											<div className="avaliacao-empresa">
												<Title>Comentário sobre a entrega</Title>
												{commentAnalysis(fs_comentario, Description, f_comentario)}
											</div>
											<hr />
										</>
									)}

									<AvaliacaoVendedor>
										<Title>Comentário sobre o atendimento</Title>
										{!v_unrated
											? (
												<Score>
													<Rating color="orange" lock rate={v_estrelas || 0} />
													<span
														className={`score-number ${v_unrated ? 'unrated' : ''}`}>
														{v_estrelas}.0{' '}
													</span>
													<small>enviado há {v_dt_avaliacao} dias</small>
												</Score>
											)
											: (
												<span>Não avaliado</span>
											)
										}
										{(!v_unrated && (v_comentario !== '' && v_comentario !== null)) && (
											<div className="v_rated">
												{commentAnalysis(vs_comentario, Description, v_comentario)}
												{/* <Link>Editar avaliação</Link> */}
											</div>
										)}
									</AvaliacaoVendedor>
								</EvaluationBlock>
							);
						})}
						{result.length === 0 && (
							<div className="container">
								<div className="row">
									<div className="col-xs-12">
										<ErrorWrapper>
											<i className="fa fa-star-o" aria-hidden="true" />
											<p>Nenhuma avaliação realizada até o momento</p>
										</ErrorWrapper>
									</div>
								</div>
							</div>
						)}
						{result.length > 0
						&& page.total > 20
						&& (fetching === 20 || fetching === 30) && <SectionPagination>
							<Pagination
								pageCount={Math.ceil(page.total / page.per_page)}
								pageRangeDisplayed={3}
								marginPagesDisplayed={1}
								onPageChange={handlePage}
								nextLabel=">"
								previousLabel="<"
								breakLabel="..."
								forcePage={page.number - 1}
								containerClassName="pagination"
								activeClassName="active"
							/>
						</SectionPagination>}
					</div>
				</div>
			</div>
		</>
	);
};

Avaliacoes.propTypes = {
	avaliacoes: Proptypes.object.isRequired,
	inputRef: Proptypes.object.isRequired,
	search: Proptypes.string.isRequired,
	isSearching: Proptypes.bool.isRequired,
	handleSearch: Proptypes.func.isRequired,
	handleMobileSearchClick: Proptypes.func.isRequired,
	commentAnalysis: Proptypes.func.isRequired,
	handlePage: Proptypes.func.isRequired,
	// modalFornecedor: Proptypes.bool.isRequired,
	// modalVendedor: Proptypes.bool.isRequired,
	// handleModalFornecedor: Proptypes.func.isRequired,
	// handleModalVendedor: Proptypes.func.isRequired,
};

export default Avaliacoes;
