import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import AnaliseActions from './AnaliseActions';
import AprovarActions from './AprovarActions';
import CancelarActions from './CancelarActions';
import RefinamentoActions from './RefinamentoActions';
import ExpiredActions from './ExpiredActions';
import AprovacaoSiengeActions from './AprovacaoSiengeActions';

const ResumoActions = ({
	compra,
	user,
	redirectAfterConcluir,
	updateComprasRaw,
	cancelaCompra,
}) => {
	const { status_front, rc } = compra;

	switch (status_front) {
	case 'cancelada':
		return (
			<CancelarActions
				compra={compra}
				redirectAfterConcluir={redirectAfterConcluir}
			/>
		);

	case 'em_analise':
		return (
			<AnaliseActions compra={compra} />
		);

	case 'em_aprovacao':
		return (
			<AprovarActions
				{...{
					compra,
					user,
					redirectAfterConcluir,
					updateComprasRaw,
					cancelaCompra,
				}}
			/>
		);

	case 'em_refinamento':
		return (
			<RefinamentoActions compra={compra} />
		);

	case 'em_edicao':
	case 'rascunho':
		return (
			<ExpiredActions rc={rc} />
		);

	case 'aguardando_aprovacao_sienge':
		return (
			<AprovacaoSiengeActions />
		);

	default:
		return null;
	}
};

ResumoActions.propTypes = {
	compra: PropTypes.object.isRequired,
	cancelaCompra: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

export default CSSModules(ResumoActions, styles, { allowMultiple: true });
