import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import shallowequal from 'shallowequal';

// Icons
import { SendOutlined } from '@ant-design/icons';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

/**
 * @render react
 * @name CompraRowSimplificada
 * @description Row da compra que aparece na listagem e no pedido
 * @example
 * 	<CompraRowSimplificada
 *    compra={compra} />
 */

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';
import { editEnviadoEmFormat } from '../../_functions/_editDateFormat';
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { setClasses } from '../../_functions/_setClasses';

class CompraRowSimplificada extends Component {
	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	render() {
		const {
			compra,
			border,
			destaque,
			reference,
			show_fornecedor,
		} = this.props;
		const {
			valor_total,
			id,
			registro,
			fornecedor,
		} = compra;

		const link = `/compra/${id}`;

		const icons_status = {
			deletada: <i className="fa fa-trash" style={{ color: 'red' }} aria-hidden="true" />,
			cancelada: <i className="fa fa-ban" style={{ color: 'red' }} aria-hidden="true" />,
			em_aprovacao: <SendOutlined styleName="icons-send-detalhes" style={{ color: '#01b0cd' }} aria-hidden="true" />,
			aguardando_aprovacao_sienge: <SendOutlined styleName="icons-send-detalhes" style={{ color: '#01b0cd' }} aria-hidden="true" />,
			em_edicao: <i className="fa fa-pencil" style={{ color: '#666c6d' }} aria-hidden="true" />,
			rascunho: <i className="fa fa-barcode" aria-hidden="true" />,
			em_analise: <i className="fa fa-barcode" aria-hidden="true" />,
			em_refinamento: <i className="fa fa-pencil" aria-hidden="true" />,
			aguardando_confirmacao: <i className="fa fa-barcode" aria-hidden="true" />,
			confirmada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
			recusada_fornecedor: <i className="fa fa-exclamation-triangle" style={{ color: 'red' }} aria-hidden="true" />,
			entrega_confirmada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
			finalizada: <i className="fa fa-check" style={{ color: '#5bda40' }} aria-hidden="true" />,
		};
		const views_status = {
			deletada: 'Deletada',
			cancelada: 'Cancelada',
			em_aprovacao: 'Em aprovação',
			aguardando_aprovacao_sienge: 'Aguardando aprovação no Sienge',
			em_edicao: 'Em edição',
			rascunho: 'Rascunho',
			em_analise: 'Aguardando análise Conaz',
			em_refinamento: 'Em refinamento',
			aguardando_confirmacao: 'Aguardando confirmação do fornecedor',
			confirmada: 'Aguardando entrega',
			recusada_fornecedor: 'Recusada pelo fornecedor',
			entrega_confirmada: 'Entrega confirmada',
			finalizada: 'Finalizada',
		};
		const status = defineStatusCompra(compra);
		const view_status = (registro && status === 'finalizada')
			? 'Registrada'
			: views_status[status] || 'Finalizada';
		const compra_id = compra.id || 0;
		const titulo = compra.titulo || '';
		const compra_wrapper_classes = {
			deletada: status === 'cancelada',
			cancelada: status === 'cancelada',
			em_aprovacao: status === 'em_aprovacao',
			aguardando_aprovacao_sienge: status === 'aguardando_aprovacao_sienge',
			em_edicao: status === 'em_edicao',
			rascunho: status === 'rascunho',
			em_analise: status === 'em_analise',
			em_refinamento: status === 'em_refinamento',
			aguardando_confirmacao: status === 'aguardando_confirmacao',
			confirmada: status === 'confirmada',
			recusada_fornecedor: status === 'recusada_fornecedor',
			entrega_confirmada: status === 'entrega_confirmada',
			finalizada: status === 'finalizada',
			border,
			destaque,
		};

		const compra_wrapper_class = setClasses('compra-wrapper', compra_wrapper_classes);
		const criado_em = compra.criado_em || '';
		const realizada_em_view = criado_em !== ''
			? editEnviadoEmFormat(criado_em)[0]
			: '';
		const realizada_view = (status === 'aguardando_confirmacao' || status === 'confirmada')
			? <>Realizada em <span styleName="data">{realizada_em_view}</span></>
			: '';
		const status_class = `status ${status}`;
		const preco_class = `preco-fechado ${status}`;

		const fornecedor_nome = (fornecedor && fornecedor.nome) || '';

		return (
			<div styleName="padding-wrapper">
				<Link ref={reference} to={link} styleName="compra-a">
					<div styleName={compra_wrapper_class}>
						{icons_status[status]}
						<div styleName="titulo">
							<div styleName="nome">
								<span style={{ color: '#aeb1b7' }}>#{compra_id}</span> - {titulo}
							</div>
							<div styleName="data-realizada">{realizada_view}</div>
							<div styleName={status_class}><b>{view_status}</b></div>
							{show_fornecedor && (
								<div styleName="fornecedor">
									<span>Fornecedor:</span> {fornecedor_nome}
								</div>
							)}
						</div>
						<div styleName={preco_class}>
							{formatCurrency(valor_total, 2, 'R$')}
						</div>
					</div>
				</Link>
			</div>
		);
	}
}

CompraRowSimplificada.propTypes = {
	compra: PropTypes.object.isRequired,
	border: PropTypes.bool,
	destaque: PropTypes.bool,
	reference: PropTypes.any,
	show_fornecedor: PropTypes.bool,
};

CompraRowSimplificada.defaultProps = {
	border: false,
	destaque: false,
	reference: null,
	show_fornecedor: false,
};

export default CSSModules(CompraRowSimplificada, styles, { allowMultiple: true });
