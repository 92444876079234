const checkJSON = json => {
	try {
		return JSON.parse(json);
	} catch (e) {
		return '';
	}
};

export const getUserLocalstorageData = field => {
	const user_localstorage_values = checkJSON(localStorage.getItem('user')) || {};
	return user_localstorage_values[field] !== undefined
		? user_localstorage_values[field]
		: '';
	// if (
	// 	localstorage_exists !== undefined &&
	// 	localstorage_exists !== null
	// 	// localstorage_exists instanceof String
	// ) {
	// 	const user_localstorage_values = JSON.parse(localstorage_exists) || {};
	// 	return user_localstorage_values[field] !== undefined ? user_localstorage_values[field] : '';
	// }
	// return '';
};

export const setUserLocalstorageData = (field, value) => {
	const token = getUserLocalstorageData('token');
	const email = getUserLocalstorageData('email');
	const id = getUserLocalstorageData('id');
	const nome = getUserLocalstorageData('nome');
	const obra = getUserLocalstorageData('obra');
	const tem_express = getUserLocalstorageData('tem_express');

	const user_localstorage = {
		token,
		email,
		id,
		nome,
		obra,
		tem_express,
	};
	user_localstorage[field] = value;
	localStorage.setItem('user', JSON.stringify(user_localstorage));
	return true;
};
