import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import SelectObra from '../SelectObra';

// Functions
import { formatNumbers } from '../../../../components/_functions/_formatNumbers';

class DadosObra extends Component {
	docType = doc => {
		if (doc === undefined || doc === null || doc === '') return null;
		if (doc.length === 11) return 'cpf';
		if (doc.length === 14) return 'cnpj';
		return null;
	};


	render() {
		const {
			compra,
			obras_by_id,
			obras_all_ids,
			autoSaveStart,
			updateComprasRaw,
		} = this.props;
		const { is_editable, obra } = compra;
		const obra_id = ((compra || {}).obra || {}).id || 0;
		const pedido_id = compra.pedido_id || null;
		// eslint-disable-next-line no-unused-vars
		const tem_cotacao = pedido_id !== null;
		const obra_nome = (obras_by_id[obra_id] || {}).nome || '';
		const { endereco, pessoas } = obra;
		const endereco_obra =
			Object.entries(endereco).length > 0
				? `${endereco.logradouro}
				, ${endereco.numero}
				. ${endereco.bairro}
				, ${endereco.cidade}${' - '}
				${endereco.estado}${' '}
				${formatNumbers(endereco.cep, 'cep')}`
				: false;
		const show_info = (pessoas?.length || 0) !== 0;
		let financeiro_nome = null;
		let financeiro_documento = null;
		let financeiro_endereco = null;
		if (show_info) {
			const financeiro =
				(pessoas || []).filter(
					p1 => p1.papeis.filter(p2 => p2 === 2).length > 0,
				)[0] || {};
			financeiro_nome = (financeiro.pessoa || {}).nome || '';
			const pessoa = ((financeiro || {}).pessoa || {});
			let financeiro_doc_type = '';
			if ((pessoa.documento || '') !== '' || ((pessoa.documento || '') !== undefined)) {
				financeiro_doc_type = this.docType((pessoa.documento || ''));
			}
			financeiro_documento =
				financeiro.pessoa.documento !== '' &&
					financeiro.pessoa.documento !== undefined &&
					financeiro.pessoa.documento !== null &&
					financeiro_doc_type !== '' ? (
						<>
							<span>{financeiro_doc_type.toUpperCase()}:</span>
							<span>
								{' '}
								{formatNumbers(
									financeiro.pessoa.documento,
									financeiro_doc_type,
								)}
							</span>
						</>
					) : (
						<em>Sem registro</em>
					);
			financeiro_endereco = (financeiro.pessoa || {}).endereco;
			financeiro_endereco =
				Object.entries(financeiro_endereco).length > 0
					? `${financeiro_endereco.logradouro}
					, ${financeiro_endereco.numero}
					. ${financeiro_endereco.bairro}
					, ${financeiro_endereco.cidade}${' - '}
					${financeiro_endereco.estado}${' '}
					${formatNumbers(financeiro_endereco.cep, 'cep')}`
					: false;
		}
		if (!show_info) {
			return (
				<div styleName="info-row">
					<div styleName="obra">
						<span styleName="label">
							<p>Dados da obra:</p>
							{is_editable
								? (
									<SelectObra
										obra_nome={obra_nome}
										compra={compra}
										obra_id={obra_id}
										obras_by_id={obras_by_id}
										func={this.handleObra}
										obras_all_ids={obras_all_ids}
										autoSaveStart={autoSaveStart}
										updateComprasRaw={updateComprasRaw} />
								) :
								(
									<span>{obra_nome}</span>
								)
							}
						</span>
					</div>
				</div>
			);
		}
		return (
			<div styleName="info-row">
				<div styleName="obra">
					<span styleName="label">
						<p>Dados da obra:</p>
						{is_editable && !tem_cotacao
							? (
								<SelectObra
									obra_nome={obra_nome}
									compra={compra}
									obra_id={obra_id}
									obras_by_id={obras_by_id}
									func={this.handleObra}
									obras_all_ids={obras_all_ids}
									autoSaveStart={autoSaveStart}
									updateComprasRaw={updateComprasRaw} />
							) :
							(
								<span>{obra_nome}</span>
							)
						}
					</span>
				</div>
				{
					show_info
					&& (
						<>
							{endereco_obra && <span>{endereco_obra}</span>}
							<div styleName="financeiro">
								<span styleName="label">
									<p id="dados"><br />Dados de faturamento:</p>
									<span>{financeiro_nome}</span>
								</span>
								<p>{financeiro_documento}</p>
								{financeiro_endereco && <span>{financeiro_endereco}</span>}
							</div>
						</>
					)
				}
			</div>
		);
	}
}

DadosObra.propTypes = {
	compra: PropTypes.object.isRequired,
	obras_by_id: PropTypes.object.isRequired,
	obras_all_ids: PropTypes.array.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(DadosObra, styles, { allowMultiple: true });
