import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import { dataPlanilhaFormat } from 'components/_functions/_editDateFormat';
import { _get } from 'components/_functions/_requests';
import { message } from 'antd';
import styles from './style.module.scss';

const HeaderSearchItensPlanilha = ({
	request_state,
	search,
	handleSearch,
	showTabelasToUpdate,
	atualizacao_planilha,
}) => {
	const [data_atualizacao_planilha, setDataAtualizacaoPlanilha] = useState(undefined);

	useEffect(() => {
		const url = '/conaz/v2/integracoes/requisicoes/job_status?job_tipo=IMPORT_RESOURCE_SPREADSHEET';
		_get(url, { data_atualizacao_planilha }).then((response) => {
			localStorage.setItem('ultima_atualizacao_planilha', response.data.requisitado_em);
			if (localStorage.getItem('ultima_atualizacao_planilha') !== 'undefined') {
				setDataAtualizacaoPlanilha(localStorage.getItem('ultima_atualizacao_planilha'));
			}
			if (response && response.data.erro_motivo === 'INVALID_SPREADSHEET') {
				message.error('Não foi possível importar a planilha. Verifique se o seu arquivo está no padrão selecionado. Dúvidas? Entre em contato com o suporte.', 10);
			}
			if (localStorage.getItem('ultima_atualizacao_planilha') === undefined) {
				setDataAtualizacaoPlanilha('');
			}
		}).catch({});
	}, [localStorage.getItem('ultima_atualizacao_planilha'), data_atualizacao_planilha]);

	return (
		<div className="header">
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<h3 className="h3-header" styleName="title-top">
							Configuração de insumos
							{request_state !== 10 && (
								<div styleName="button-atualiza-itens">
									<button
										type="button"
										className="btn round-border"
										styleName="buttonCustom"
										onClick={() => showTabelasToUpdate()}>
										<i className="fa fa-upload" aria-hidden="true" styleName="linkcustom" />
										<span styleName={data_atualizacao_planilha ? 'tooltip-refresh-disabled' : 'tooltip-refresh'}> Buscar novos itens da Planilha</span>
									</button>
									{data_atualizacao_planilha || atualizacao_planilha ? <span styleName="data_atualizada_insumos">Importado a última vez em: {dataPlanilhaFormat(data_atualizacao_planilha || atualizacao_planilha)}</span> : ''}
								</div>
							)}

							{request_state === 10 && (
								<>
									<button
										type="button"
										className="btn round-border"
										styleName="buttonCustomDisabled"
										disabled>
										<i className="fa fa-upload" aria-hidden="true" styleName="linkcustom" />
									</button>
									<span styleName="status-atualizando-lista">Atualizando...</span>
								</>
							)}
						</h3>
						<div styleName="form-row-back-button">
							<Link to="/integracao/planilha">
								<button className="btn btn-clear-conaz round-border">
									<i className="fa fa-angle-left fa-fw" aria-hidden="true" /> Voltar para o início
								</button>
							</Link>
						</div>
					</div>
				</div>
				<div className="row" styleName="mt-2">
					<div className="col-sm-6">
						{request_state !== 10 && (
							<div className="form-group">
								<div className="form-control input-md" styleName="form-control-custom">
									<i className="fa fa-search" aria-hidden="true" />
									<input
										type="text"
										className="form-control"
										styleName="form-search-custom"
										placeholder="Filtrar itens"
										value={search}
										onChange={handleSearch} />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

HeaderSearchItensPlanilha.propTypes = {
	request_state: PropTypes.number.isRequired,
	handleSearch: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	showTabelasToUpdate: PropTypes.func.isRequired,
	atualizacao_planilha: PropTypes.string.isRequired,
};

export default CSSModules(HeaderSearchItensPlanilha, styles, { allowMultiple: true });
