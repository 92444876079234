import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ tabs, compras, changeTab }) => {
	// tabs classes and total numbers
	const ativas_class = (tabs.compras === '' || tabs.compras === 'ativas')
		? 'header-tab-item active'
		: 'header-tab-item';
	const ativas_total = compras.ativas.page.total;
	const aguardando_class = tabs.compras === 'aguardando_entrega_avaliacao'
		? 'header-tab-item active'
		: 'header-tab-item';
	const aguardando_total = compras.aguardando_entrega_avaliacao.page.total;
	const concluidas_class = tabs.compras === 'finalizadas'
		? 'header-tab-item active'
		: 'header-tab-item';
	const concluidas_total = compras.finalizadas.page.total;
	const canceladas_class = tabs.compras === 'canceladas'
		? 'header-tab-item active'
		: 'header-tab-item';
	const canceladas_total = compras.canceladas.page.total;
	const rascunhos_class = tabs.compras === 'em_rascunho'
		? 'header-tab-item active'
		: 'header-tab-item';
	const rascunhos_total = compras.em_rascunho.page.total;

	return (
		<div className="row">
			<div className="col-xs-12 header-tab-wrapper">
				<button type="button" className={ativas_class} data-tab="ativas" onClick={changeTab}>
					Ativas <span data-tab="ativas">{ativas_total}</span>
				</button>
				<button type="button" className={aguardando_class} data-tab="aguardando_entrega_avaliacao" onClick={changeTab}>
					Aguardando entrega e avaliação <span data-tab="aguardando_entrega_avaliacao">{aguardando_total}</span>
				</button>
				<button type="button" className={concluidas_class} data-tab="finalizadas" onClick={changeTab}>
					Finalizadas <span data-tab="finalizadas">{concluidas_total}</span>
				</button>
				<button type="button" className={canceladas_class} data-tab="canceladas" onClick={changeTab}>
					Canceladas <span data-tab="canceladas">{canceladas_total}</span>
				</button>
				<div className="header-tab-item-division" />
				<button type="button" className={rascunhos_class} data-tab="em_rascunho" onClick={changeTab}>
					Rascunhos <span data-tab="em_rascunho">{rascunhos_total}</span>
				</button>
			</div>
		</div>
	);
};

Tabs.propTypes = {
	tabs: PropTypes.object.isRequired,
	compras: PropTypes.object.isRequired,
	changeTab: PropTypes.func.isRequired,
};

export default Tabs;
