import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import MultipleRCOption from './MultipleRCOption';

class InputMultipleRCs extends Component {
	constructor() {
		super();
		this.state = {
			opcoes_by_id: {},
			opcoes_all_ids: [],
			opcoes_selecionadas: [],
			opcoes_selecionadas_view: '',
		};
		this.handleEdition = this.handleEdition.bind(this);
		this.selectAll = this.selectAll.bind(this);
		this.saveToRedux = this.saveToRedux.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { opcoes_de_compra, ocs_selecionados } = this.props;
		this.setInitialValues(opcoes_de_compra, ocs_selecionados);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { opcoes_de_compra, ocs_selecionados } = nextProps;
		this.setInitialValues(opcoes_de_compra, ocs_selecionados);
	}

	setInitialValues(opcoes_de_compra, ocs_selecionados) {
		const { all_ids, by_id } = opcoes_de_compra;
		/**
		 * Filtra apenas os RCs, remove as combinações
		 */
		const apenas_rcs = all_ids.filter(oc_id => by_id[oc_id].rcs_analise.length === 1);

		const opcoes_by_id = apenas_rcs.reduce((result, current) => ({
			...result,
			[current]: {
				...by_id[current],
			},
		}), {});

		const opcoes_selecionadas_view = ocs_selecionados
			.map(id => `${by_id[id].rcs_analise[0].rc.fornecedor.nome}`)
			.join(', ');

		this.setState({
			opcoes_by_id,
			opcoes_all_ids: apenas_rcs,
			opcoes_selecionadas: ocs_selecionados,
			opcoes_selecionadas_view,
		});
	}

	handleEdition(opcao_id) {
		const { selectOC } = this.props;
		selectOC(opcao_id);
	}

	selectAll() {
		const { selectAllOCs } = this.props;
		const { opcoes_all_ids, opcoes_selecionadas } = this.state;

		if (opcoes_selecionadas.length === opcoes_all_ids.length) {
			this.setState({ opcoes_selecionadas: [] });
			selectAllOCs([]);
		} else {
			this.setState({ opcoes_selecionadas: opcoes_all_ids });
			selectAllOCs(opcoes_all_ids);
		}
	}

	saveToRedux() {
		const { opcoes_by_id, opcoes_selecionadas } = this.state;
		const { updateFunction } = this.props;
		const opcoes_preenchidas = opcoes_selecionadas.map(id => opcoes_by_id[id]);
		updateFunction(opcoes_preenchidas);
	}

	render() {
		const { opcoes_by_id, opcoes_all_ids, opcoes_selecionadas, opcoes_selecionadas_view } = this.state;
		const checkall_selected = opcoes_selecionadas.length !== 0 && opcoes_selecionadas.length === opcoes_all_ids.length;
		const checkall_button_class = checkall_selected
			? 'checkbox-button checked'
			: 'checkbox-button';
		const selecteds_view = checkall_selected ? 'Todos' : opcoes_selecionadas_view;

		return (
			<div className="dropdown" id="input-multiple">
				<button
					className="btn btn-default input-md dropdown-toggle"
					styleName="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="true"
					data-dropdown>
					{selecteds_view} <span className="caret" styleName="dropdown-caret" data-dropdown-2 />
				</button>
				<div className="dropdown-menu" styleName="dropdown-menu-local" data-dropchild>
					<li data-dropchild>
						<button type="button" data-dropchild onClick={this.selectAll} styleName={checkall_button_class}>
							<div styleName="checkbox-icon" data-dropchild>
								{checkall_selected && <i className="fa fa-check" aria-hidden="true" data-dropchild />}
							</div>
							Todos
						</button>
					</li>
					<li role="separator" className="divider" styleName="divider" data-dropchild />
					{opcoes_all_ids.map(opcao_id => {
						const selected = opcoes_selecionadas.filter(id => id === opcao_id).length > 0;

						return (
							<MultipleRCOption
								key={opcao_id}
								handleEdition={this.handleEdition}
								option={opcoes_by_id[opcao_id]}
								selected={selected} />
						);
					})}
				</div>
			</div>
		);
	}
}

InputMultipleRCs.propTypes = {
	opcoes_de_compra: PropTypes.object.isRequired,
	ocs_selecionados: PropTypes.array.isRequired,
	selectOC: PropTypes.func.isRequired,
	selectAllOCs: PropTypes.func.isRequired,
	updateFunction: PropTypes.func,
};

InputMultipleRCs.defaultProps = {
	updateFunction: () => {},
};

export default CSSModules(InputMultipleRCs, styles, { allowMultiple: true });
