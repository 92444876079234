export const formatCurrency = (value, decimals, currency) => {
	const pre_value = (value !== null && value !== undefined) ? value : 0;
	const number = typeof pre_value === 'number'
		? pre_value.toFixed(decimals).toString().replace(/\./g, ',')
		: parseFloat(pre_value).toFixed(decimals).toString().replace(/\./g, ',');
	return `${currency} ${number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
};

export const formatCurrencyDecimalsFree = (value, cur) => {
	const currency = (
		cur === '' ||
		cur === undefined ||
		cur === null ||
		typeof cur !== 'string'
	) ? 'R$' : cur;
	const pre_value = (
		value !== null &&
		value !== undefined &&
		value !== ''
	) ? value : 0;
	const number = typeof pre_value === 'number'
		? pre_value.toString().replace(/\./g, ',')
		: parseFloat(pre_value).toString().replace(/\./g, ',');
	const inteiros = number.split(',')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	const pre_decimals = number.split(',')[1] || '00';
	const decimals = pre_decimals.length === 1 ? `${pre_decimals}0` : pre_decimals;
	return `${currency} ${inteiros},${decimals}`;
};
