import styled from 'styled-components';

export const CollapseContainer = styled.div`
	.ant-collapse-arrow {
   		left: 0px !important;
	}

	.ant-collapse-header {
		padding: 0px !important;
		padding-left: 18px !important;
		background-color: transparent !important;
	}
`;

CollapseContainer.Actions = styled.span`
	display: flex;
	align-items: center;
	// para n ter cliques fantasmas e abrir o drawer.
	width: 100%;
	cursor: default;
	height: 48px;
	//
	.colapse-title {
		margin: 0px 17px 0px 10px;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: #8C8C8C;
	}

	.colapse-add-btn {
		display: flex;
		align-items:center;
		padding: 4px 16px;
		color: white;
		border-color: #0076D6;
		background-color: #0076D6;
		&:hover {
			border-color: #3299ed;
			background-color: #3299ed;
		}
	}
`;
