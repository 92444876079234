/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import StepMobile from './StepMobile';
import Steps from './Steps';


class StepsV2 extends Component {
	state = {
		showHistory: false,
	};

	handleShowHistory = () => {
		const { showHistory } = this.state;
		this.setState({ showHistory: !showHistory });
	}

	render() {
		const { showHistory } = this.state;
		const { steps, status_front, children } = this.props;

		const childrenNode = React.cloneElement(children, {
			toggleHistory: this.handleShowHistory,
			showHistory,
			...this.props,
		});

		let current_stage = steps.length;
		const render_steps = steps.map((step, i) => {
			const previous_ativo = (steps[i - 1] || {}).ativo;
			if (!step.ativo && previous_ativo) {
				current_stage = i;
			}
			return (
				// novo step
				<StepMobile
					status_front={status_front}
					last={i === steps.length - 1}
					beforeLast={i === steps.length - 2}
					key={i}
					lastState={{
						label: steps[steps.length - 1].label,
					}}
					label={step.label}
					descricao={step.descricao}
					stage={i}
					current={i === steps.length - 1}
					size={steps.length}
					done={step.done && step.done} />
			);
		});

		return (
			<div styleName="stmobile">
				{childrenNode}
				{
					showHistory
					&& (
						<div styleName="expansive">
							<div styleName="history">
								<Steps current={current_stage}>
									{render_steps}
								</Steps>
							</div>
						</div>
					)
				}
			</div>
		);
	}
}

StepsV2.propTypes = {
	children: PropTypes.node.isRequired,
	steps: PropTypes.arrayOf(PropTypes.object),
	status_front: PropTypes.string.isRequired,
};

StepsV2.defaultProps = {
	steps: [{}],
};

export default CSSModules(StepsV2, styles, { allowMultiple: true });
