import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { _put } from '../../../../../_functions/_requests';
import { setClasses } from '../../../../../_functions/_setClasses';
import { handleRequestErrors } from '../../../../../_functions/_handleRequestErrors';

/* eslint-disable react/no-danger, react/no-array-index-key */
class IntegracaoSearchResult extends Component {
	state = {
		adding_item: false,
		error_adding: false,
	};

	addItemIntegrado = () => {
		const { adding_item } = this.state;
		const { item_on_edition, item } = this.props;

		if (adding_item) return;

		this.setState({ adding_item: true, error_adding: false });

		const id_no_swi = (item || {}).id_no_swi || '';
		const id_no_swi_detalhe = ((item || {}).detalhe || {}).id_no_swi || '';
		const item_id = item_on_edition.id || 0;
		const url = `/conaz/v2/itens_de_compra/${item_id}`;
		const params = {
			id_no_swi,
			id_no_swi_detalhe,
			force: true,
		};
		_put(url, params).then(() => {
			this.updateItem(item_on_edition, item, params);
		}).catch(error => {
			this.setState({ adding_item: false, error_adding: true });
			handleRequestErrors(error);
		});
	}

	updateItem(item_on_edition, item, params) {
		const { updateFullItemById, autoSave } = this.props;
		const nome = item.nome
			.replace(/<\/span>/g, '')
			.replace(/<span>/g, '');
		const new_item = {
			...item_on_edition,
			id_no_swi: params.id_no_swi,
			id_no_swi_detalhe: params.id_no_swi_detalhe,
			item_integrado: {
				...item,
				nome,
			},
		};
		updateFullItemById(item_on_edition.id, new_item);
		autoSave(item_on_edition.id);
		this.setState({ adding_item: false });
	}

	render() {
		const { adding_item, error_adding } = this.state;
		const { item } = this.props;

		const item_classes = {
			adding: adding_item,
			error: error_adding,
		};
		const item_class = setClasses('item-result', item_classes);

		// id planilha
		const item_integrado_id = (item || {}).id_no_swi || null;
		// detalhe
		const detalhe_item_integrado = item.detalhe || {};
		const detalhe_id = (detalhe_item_integrado || {}).id_no_swi || null;
		const detalhe_valor = (detalhe_item_integrado || {}).valor || '';

		return (
			<button
				type="button"
				styleName={item_class}
				onClick={this.addItemIntegrado}>
				<i className="fa fa-angle-right" aria-hidden="true" />
				<div
					className="list-group-item-heading"
					styleName="results-list-h4"
					dangerouslySetInnerHTML={{ __html: item.nome }}
				/>
				<div styleName="results-list-p">Código ERP: <b>{item_integrado_id}</b></div>
				{detalhe_id !== null && (
					<div styleName="results-list-p">
						Detalhe: <b>{detalhe_valor}</b>{' / '}
						ID Detalhe: <b>{detalhe_id}</b>
					</div>
				)}
				{error_adding && (
					<div styleName="error-alert">Erro ao adicionar item. Tente novamente.</div>
				)}
			</button>
		);
	}
}

IntegracaoSearchResult.propTypes = {
	item_on_edition: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IntegracaoSearchResult, styles, { allowMultiple: true });
