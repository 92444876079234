import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';
// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Function
import { isWeekday } from '../../../../components/_functions/_isWeekday';

class DataRegistro extends Component {
	constructor(props) {
		super(props);
		const data_de_decisao =
			props.compra.data_de_decisao !== null
				? moment(props.compra.data_de_decisao).format('YYYY-MM-DD')
				: moment().format('YYYY-MM-DD');
		this.state = {
			data_de_decisao,
		};
	}

	handleDataDecisao = _date => {
		const {
			compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;

		const data_de_decisao = _date.format('YYYY-MM-DD');
		const nova_compra = { ...compra, data_de_decisao };
		handleFixError('data_decisao');
		this.setState({ data_de_decisao });
		updateComprasRaw({ nova_compra });
		autoSaveStart();
	};

	render() {
		const {
			data_de_decisao,
		} = this.state;
		const {
			compra: { is_editable, tipo },
		} = this.props;

		const blank_data_entrega =
			data_de_decisao === moment('2016-01-01').format('YYYY-MM-DD');
		const date_selected = !blank_data_entrega
			? moment(data_de_decisao)
			: null;


		if (tipo === 5 && is_editable) {
			return (
				<div styleName="info-row">
					<div styleName="label">Data de efetuação da compra</div>
					<div styleName="form-control-date-wrapper">
						<i className="fa fa-calendar" aria-hidden="true" />
						<DatePicker
							id="data_registro"
							styleName="datepicker"
							className="form-control-date"
							selected={date_selected}
							onChange={this.handleDataDecisao}
							isWeekday={isWeekday}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							locale="pt-br" />
					</div>
				</div>
			);
		}
		return null;
	}
}
DataRegistro.propTypes = {
	compra: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(DataRegistro, styles, { allowMultiple: true });
