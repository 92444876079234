import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import RatingStep from './RatingStep';
import RatingLastStep from './RatingLastStep';
import ModalRatingThumbnails from './ModalRatingThumbnails';

const ModalRating = ({
	avaliacao,
	compra,
	closeModal,
	location,
}) => {
	const [criterios, setCriterios] = useState({});
	const [step, setStep] = useState(1);

	function checkStepCompleto() {
		const criterios_arr = Object.keys(criterios);
		const step_incompleto_key = criterios_arr
			.reduce((res, cur) => (
				res === '' && criterios[cur].value === null
					? cur
					: res
			), '');
		const step_incompleto = criterios_arr
			.findIndex(e => e === step_incompleto_key) + 1;
		return step_incompleto;
	}

	function navigateTo(to) {
		const last_step = Object.keys(criterios).length;
		const end_step = last_step + 1;
		// não passa do valor mínimo e máximo
		let _step = to <= 1 ? 1 : to;
		_step = to >= last_step ? last_step : _step;
		// busca maior step ainda não preenchido
		const max_step_incompleto = checkStepCompleto();
		// vai para último step de envio caso esteja tudo ok
		if (
			_step === last_step
			&& max_step_incompleto === 0
		) {
			setStep(end_step);
			return;
		}
		// não prossegue se etapa não estiver preenchida
		if (_step > max_step_incompleto) {
			return;
		}
		// navigate
		setStep(_step);
	}

	function navigateToNext() {
		const timeout = step === 1
			? 460
			: 160;
		setTimeout(() => {
			navigateTo(step + 1);
		}, timeout);
	}

	function handleCriterios(criterio, value) {
		const _criterios = {
			...criterios,
			[criterio]: {
				...criterios[criterio],
				value,
			},
		};
		setCriterios(_criterios);
	}

	useEffect(() => {
		setCriterios(avaliacao.criterios);
	}, []);

	useEffect(() => {
		if (Object.keys(criterios).length > 0) {
			navigateToNext();
		}
	}, [criterios]);

	const criterios_size = Object.keys(criterios).length;
	const last_step = criterios_size > 0
		? criterios_size + 1
		: -1;

	const criterios_wrapper_class = step === last_step
		? 'criterios-wrapper higher'
		: 'criterios-wrapper';

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				{step !== last_step && (
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={closeModal}
					>
						<span aria-hidden="true">&times;</span>
					</button>
				)}
				<div styleName={criterios_wrapper_class} id="criterios-wrapper-for-animation">
					{Object.keys(criterios).map((criterio_id, n) => (
						<RatingStep
							key={criterio_id}
							step={step}
							step_number={n + 1}
							criterio_id={criterio_id}
							criterio={criterios[criterio_id]}
							handleCriterios={handleCriterios}
						/>
					))}
					<RatingLastStep
						step={step}
						step_number={last_step}
						compra={compra}
						criterios={criterios}
						avaliacao_url={avaliacao.avaliacao_url}
						comentario_url={avaliacao.comentario_url}
						closeModal={closeModal}
						location={location}
					/>
				</div>
				{step !== last_step && (
					<ModalRatingThumbnails
						criterios={criterios}
						step={step}
						navigateTo={navigateTo}
						checkStepCompleto={checkStepCompleto}
					/>
				)}
				<div styleName="comment-bottom-2">
					Sua avaliação ficará disponível para esse fornecedor e irá compor a nota do mesmo
				</div>
			</div>
		</div>
	);
};

ModalRating.propTypes = {
	avaliacao: PropTypes.object.isRequired,
	compra: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(ModalRating, styles, { allowMultiple: true });
