import { withState, withHandlers, compose } from 'recompose';

export const withToggle = compose(
	withState('isVisible', 'handleToggle', false),
	withHandlers({
		toggle: ({ handleToggle, isVisible }) => () => handleToggle(!isVisible),
	}),
);

export const withTooltip = compose(
	withState('tooltipIsVisible', 'handleTooltip', false),
	withHandlers({
		closeTooltip: ({ handleTooltip }) => () => handleTooltip(false),
		openTooltip: ({ handleTooltip }) => () => handleTooltip(true),
	}),
);
