/* eslint-disable react/react-in-jsx-scope */
import { all, takeLatest, call, put, delay } from 'redux-saga/effects';

import { _get } from '../../components/_functions/_requests';

import * as Types from './types';
import * as Actions from './actions';

function* getImportStatus({ payload }) {
	const { job_tipo } = payload.data;

	try {
		const { data, data: { job_status } } = yield call(_get, `/conaz/v2/integracoes/requisicoes/job_status?job_tipo=${job_tipo}`);

		yield put(Actions.getImportStatusSuccess(data, job_status));

		if (data && data.message === 'Nenhuma requisição encontrada' && !job_status) {
			return false;
		}

		if (job_status !== 'FINISHED' && job_status !== 'ERROR') {
			while (true && job_status !== null) {
				yield delay(1200);
				yield put(Actions.getImportStatus(payload.data, job_status));
			}
		}

		if (data && data.message === 'Nenhuma requisição encontrada') {
			yield put(Actions.getImportStatus(payload.data, job_status));
			return false;
		}

		if (job_status === 'FINISHED') {
			yield put(Actions.getImportStatusSuccess(data, job_status));
		}
	} catch (err) {
		yield put(Actions.getImportStatusError(err));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.IMPORT_STATUS_REQUEST, getImportStatus),
	]);
}
