import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

function Sair({ unsetAuth }) {
	useEffect(() => {
		unsetAuth();
		window.location.replace(`${window.location.origin}/entrar`);
	}, []);

	return <div />;
}

Sair.propTypes = {
	unsetAuth: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(Sair));
