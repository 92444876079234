const buildItemPreenchido = (item, sem_recomendacao_marca = false) => {
	if (item.item_preenchido.item !== undefined) {
		// const carac_preenchidas_by_id = item.item_preenchido.item.caracteristicas.reduce((r, c) => {
		// 	const _c = {
		// 		caracteristica: { ...c },
		// 		opcoes_preenchidas: [],
		// 	};
		// 	return { ...r, [c.id]: _c };
		// }, {});
		const item_conaz = item.item_preenchido.item || {};
		const caracteristicas =	sem_recomendacao_marca
			? item_conaz.caracteristicas.filter(c => c.nome !== 'Recomendação de Marca')
			: [...item_conaz.caracteristicas];
		const carac_preenchidas_by_id_1 = caracteristicas.reduce((r, c) => ({
			...r,
			[c.id]: {
				caracteristica: { ...c },
				opcoes_preenchidas: c.opcoes_preenchidas !== undefined ? c.opcoes_preenchidas : [],
			},
		}), {});
		const caracs_preenchidas = sem_recomendacao_marca
			? item.item_preenchido.caracteristicas_preenchidas.filter(c => c.caracteristica.nome !== 'Recomendação de Marca')
			: [...item.item_preenchido.caracteristicas_preenchidas];
		const carac_preenchidas_by_id_2 = caracs_preenchidas.reduce((r, c) => ({
			...r,
			[c.caracteristica.id]: { ...c },
		}), {});
		const carac_preenchidas_by_id = { ...carac_preenchidas_by_id_1, ...carac_preenchidas_by_id_2 };
		const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(c_id => c_id);
		return {
			caracteristicas_preenchidas: carac_preenchidas_by_id,
			caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
			item: { ...item.item_preenchido.item },
		};
	}
	return {};
};

export const buildNewItem = (item_key, item, item_relacionado_id = null) => {
	const item_preenchido = buildItemPreenchido(item, false);
	const new_item = {
		...item,
		item_preenchido,
		relacionado_obrigatorio_id: item_relacionado_id,
		front: {
			key: item_key,
			has_error: false,
			validado: false,
			origem: '',
			item_de_planilha: false,
			planilha_pre_values: [],
		},
	};
	return new_item;
};

export const buildNewItemV2 = (item_key, item, item_relacionado_id = null) => {
	const item_preenchido = buildItemPreenchido(item, true);
	const new_item = {
		...item,
		item_preenchido,
		relacionado_obrigatorio_id: item_relacionado_id,
		front: {
			...item.front,
			key: item_key,
		},
	};
	return new_item;
};
