import styled from 'styled-components/macro';

export const Div = styled.div`
	background: white;
	border-radius: 10px;
	width: 720px;
	height: 760px;
`;

export const CloseButton = styled.button`
	margin: 5px 15px 0 0;
`;

export const EditButton = styled.button`
	margin: 5px 0 0 15px;
`;

export const Title = styled.div`
	width: 90%;
	font-size: 22px;
	line-height: 26px;
	margin-left: 20px;
	margin-top: 10px;
`;

export const LabelTitle = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	margin: 30px 20px 0 20px;
`;

export const RowInfo = styled.div`
	width: 90%;
	height: 60px;
	margin: 20px;
	> span:first-of-type { float: left; margin-top: 10px; }
	> span:nth-of-type(2), > span:last-of-type {
		float: right;
		border: 0.8px solid;
		box-sizing: border-box;
		padding: 7px 25px 7px 25px;
		border-radius: 30px;
		&.disabled {
			> i { color: grey !important }
			border-color: grey !important;
		}
	}

	> span:nth-of-type(2) {
		> i { color: red; }
		border-color: red;
		margin-left: 10px;
	}

	> span:last-of-type {
		> i { color: green }
		border-color: green;
	}
`;

export const RowRating = styled.div`
	width: 100%;
	margin-left: 20px;
	margin-top: 10px;
	color: #fadb14;
	font-size: 19px;
	> span {
		margin-top: 5px;
		margin-left: 20px;
	}
`;

export const CommentBlock = styled.div`
	margin: 20px;
`;
