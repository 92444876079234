import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { debounce } from '../../../../../_functions/_debounce';
import { handleRequestErrors } from '../../../../../_functions/_handleRequestErrors';
import { getLocalstorageData } from '../../../../../_functions/_getLocalstorageData';
import { refatoraListaSiengeItens } from '../../../../../../pages/Integracao/SiengeItens/subfunctions/_refatoraListaSiengeItens';
import { replaceSearchTextInte } from '../../../_subfunctions/replaceSearchTextInte';

class SearchInputAlt extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		const { updateState } = this.props;
		if (search.length > 0) {
			this.searchItems(search).then(response => (
				this.handleResponse(search, response)
			)).catch(error => {
				if (error.message !== 'canceled_request') {
					updateState({ search_state: 3 });
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	state = {
		search: '',
	};

	handleSearch = (e) => {
		if (this.cancel !== undefined) {
			this.cancel('canceled_request');
		}
		const { updateState } = this.props;
		const search = e.target.value.toString();
		if (search.trim().length > 0) {
			updateState({ search_state: 1 });
			this.setState({ search }, this.handleDebounce);
		} else {
			updateState({
				results: [],
				search_state: 0,
			});
			this.setState({ search });
		}
	}

	async searchItems(typed) {
		const { obra_id, client_id } = this.props;
		try {
			const base_url = getLocalstorageData('environment', 'base_url');
			const token = getLocalstorageData('user', 'token');
			const typed_encode = encodeURIComponent(typed).replace(/%0A/g, '');
			axios.defaults.headers.common.token = token;
			const { CancelToken } = axios;
			const url = `${base_url}/conaz/v2/integracoes/itens_integrados/busca_direta/${obra_id}/${typed_encode}
			?swi_number=1
			&cliente_id=${client_id}`;
			// const url = `${base_url}/conaz/v2/integracoes/itens_integrados/busca_direta/27/${typed_encode}
			// ?swi_number=1
			// &client_id=71`;
			return await axios({
				method: 'get',
				url,
				cancelToken: new CancelToken(c => {
					this.cancel = c;
					return c;
				}),
			});
		} catch (err) {
			throw err;
		}
	}

	handleResponse(search, response) {
		const _itens = response.data || [];
		const itens = refatoraListaSiengeItens(_itens, true);
		const itens_results = replaceSearchTextInte(search, itens);
		this.props.updateState({
			results: itens_results,
			search_state: 2,
			search,
		});
	}

	render() {
		const {
			search,
		} = this.state;
		const {
			placeholder,
		} = this.props;

		return (
			<input
				type="text"
				id="itens_search_input_2"
				styleName="search-input"
				autoComplete="itens-search-input"
				placeholder={placeholder}
				data-hj-whitelist=""
				value={search}
				onChange={this.handleSearch} />
		);
	}
}

SearchInputAlt.propTypes = {
	updateState: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	obra_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	client_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
};

SearchInputAlt.defaultProps = {
	placeholder: 'Digite o ID do item do Sienge a ser pesquisado',
};

export default CSSModules(SearchInputAlt, styles, { allowMultiple: true });
