/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Componentes
import InputNumber from '../../../../components/_parts/_inputs/InputNumber/InputNumber';

// Functions
import { _put } from '../../../../components/_functions/_requests';

class Desconto extends Component {
	state = {
		timer: 0,
	};

	handleDesconto = (item_key, field, desconto) => {
		const {
			nova_compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;
		const {
			condicao_de_pagamento: cdp,
			outra_condicao_de_pagamento: ocdp,
		} = nova_compra;
		const { timer } = this.state;

		handleFixError('condicao_pagamento_desconto');

		const condicao_de_pagamento =
			Object.entries(cdp).length === 0
				? { ...ocdp, desconto }
				: { ...cdp, desconto };
		const _nova_compra = {
			...nova_compra,
			condicao_de_pagamento,
		};
		updateComprasRaw({ nova_compra: _nova_compra });
		if (timer) clearTimeout(this.state.timer);
		this.setState({
			timer: setTimeout(() => {
				_put(`/conaz/v2/condicoes_de_pagamento/${condicao_de_pagamento.id}`, {
					...condicao_de_pagamento,
				}).then(() => autoSaveStart());
			}, 2000),
		});
	};

	render() {
		const { nova_compra } = this.props;
		const { id, condicao_de_pagamento } = nova_compra;
		const desconto = condicao_de_pagamento.desconto || 0;

		return (
			<div styleName="custos-input-wrapper">
				<InputNumber
					type="item"
					field_id={`desconto-${id}`}
					item_key={0}
					field="desconto"
					default_value={desconto}
					updateFunction={this.handleDesconto}
					icon="%"
					icon_width={36}
					zerar_erros={false} />
			</div>
		);
	}
}

Desconto.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(Desconto, styles, { allowMultiple: true });
