import React from 'react';
import PropTypes from 'prop-types';
import Paginate from 'react-paginate';

const Pagination = ({ requestState, activePage, totalItens, handleChangePage }) => (
	<div
		style={{ textAlign: 'center' }}
		className={`row ${requestState !== 2 ? 'hide' : ''}`}
	>
		<Paginate
			pageCount={Math.ceil(totalItens / 20)}
			pageRangeDisplayed={3}
			marginPagesDisplayed={1}
			onPageChange={handleChangePage}
			initialPage={0}
			forcePage={activePage - 1}
			breakClassName="break-item"
			breakLabel="..."
			previousLabel="<"
			nextLabel=">"
			containerClassName="pagination"
			activeClassName="active"
			disableInitialCallback={false}
		/>
	</div>
);

Pagination.propTypes = {
	totalItens: PropTypes.number.isRequired,
	requestState: PropTypes.number.isRequired,
	activePage: PropTypes.number.isRequired,
	handleChangePage: PropTypes.func.isRequired,
};

export default Pagination;
