import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const FIStatusView = () => (
	<div styleName="status">
		<span styleName="label">Nenhum fornecedor selecionado</span>
	</div>
);

export default CSSModules(FIStatusView, styles, { allowMultiple: true });
