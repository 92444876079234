import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { _get } from '../../../../_functions/_requests';

class IETUpdateItem extends Component {
	state = {
		atualizando: false,
	};

	atualizaItem = () => {
		this.setState({ atualizando: true });
		const { item } = this.props;
		const item_id = ((item.item_preenchido || {}).item || {}).id;
		_get(`/conaz/v2/itens/${item_id}`).then(response => {
			this.addItemToRedux(response.data);
		}).catch(error => {
			alert(error); // eslint-disable-line
			this.setState({ atualizando: false });
		});
	}

	addItemToRedux(item_novo) {
		const { item, updateFullItemById, autoSave } = this.props;
		const c_preenchidas = { ...item.item_preenchido.caracteristicas_preenchidas };
		const caracteristicas_preenchidas = item_novo.caracteristicas.reduce((r, c) => ({
			...r,
			[c.id]: {
				caracteristica: { ...c },
				opcoes_preenchidas: c_preenchidas[c.id]
					? [...c_preenchidas[c.id].opcoes_preenchidas]
					: [],
			},
		}), {});
		const caracteristicas_preenchidas_all_ids = Object
			.keys(caracteristicas_preenchidas)
			.map(c_id => c_id);
		const _item = {
			...item,
			item_preenchido: {
				...item.item_preenchido,
				item_novo,
				caracteristicas_preenchidas,
				caracteristicas_preenchidas_all_ids,
			},
		};
		updateFullItemById(item.id, _item);
		autoSave(item.id);
		this.setState({ atualizando: false });
	}

	render() {
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		if (!is_triagem) return null;

		const { atualizando } = this.state;

		if (!atualizando) {
			return (
				<>
					<button
						type="button"
						className="close"
						styleName="update-button"
						onClick={this.atualizaItem}>
						<i className="fa fa-refresh" aria-hidden="true" />
					</button>
					<div id="tooltip-atualizar" className="tooltip left" role="tooltip" style={{ top: '34px', right: '168px' }}>
						<div className="tooltip-arrow" />
						<div className="tooltip-inner">Atualizar item</div>
					</div>
				</>
			);
		}
		return (
			<button
				type="button"
				className="close"
				styleName="update-button"
				style={{ opacity: '.3' }}>
				<i className="fa fa-refresh" aria-hidden="true" />
			</button>
		);
	}
}

IETUpdateItem.propTypes = {
	item: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IETUpdateItem, styles, { allowMultiple: true });
