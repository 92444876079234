import { associarItem } from './_associarItem';

export const montarItem = (index, select_item, detalhe) => {
	const { itens_associados } = select_item;
	let item_integrado = null;
	const detalhe_id = (detalhe || {}).id || null;
	const item_associado = (itens_associados || [])
		.filter(item => (
			item.detalhe_item_integrado.id === detalhe_id
			|| (item.detalhe_item_integrado.id === undefined && detalhe_id === null)
		))[0] || {};

	if (item_associado.id !== undefined) {
		const { aprovado, item_preenchido, unidade } = item_associado;
		item_integrado = {
			id_no_swi_item: select_item.id_no_swi,
			id_no_swi_detalhe: detalhe ? detalhe.id_no_swi : null,
			aprovado,
			item_preenchido,
			unidade,
		};
	}

	const associado_aprovado = item_associado.id !== undefined
		? item_associado.aprovado
		: false;

	const _itens_associados = item_associado.id !== undefined
		? [item_associado]
		: [];

	const novoItem = {
		key: index,
		associado_aprovado,
		detalhe,
		...select_item,
		item_integrado,
		itens_associados: _itens_associados,
	};

	if (item_integrado) {
		return associarItem(index, novoItem, item_associado.item);
	}

	return novoItem;
};

// export const montarItem = (key, select_item, detalhe) => {
// 	const {
// 		itens_associados,
// 		ativo,
// 		criado_em,
// 		id,
// 		id_no_swi,
// 		nome,
// 		swi,
// 		unidade,
// 	} = select_item;
// 	const detalhe_id = (detalhe || {}).id || null;
// 	const item_associado = (itens_associados || [])
// 		.filter(item => (
// 			item.detalhe_item_integrado.id === detalhe_id
// 			|| (item.detalhe_item_integrado.id === undefined && detalhe_id === null)
// 		))[0] || {};

// 	const item_preenchido = item_associado.id !== undefined
// 		? item_associado.item_preenchido || {}
// 		: {};

// 	const novoItem = {
// 		key,
// 		ativo,
// 		criado_em,
// 		detalhe,
// 		id,
// 		id_no_swi,
// 		item_associado,
// 		nome,
// 		swi,
// 		unidade,
// 		front: {},
// 		item_associado_caracteristicas: {},
// 		item_preenchido,
// 	};

// 	if (item_associado.id !== undefined) {
// 		return associarItem(key, novoItem, item_associado.item);
// 	}

// 	return novoItem;
// };
