import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { _post } from 'components/_functions/_requests';

// CSS
import CSSModules from 'react-css-modules';
import { Space, Spin } from 'antd';
import styles from './style.module.scss';


import { codigos_recusa } from '../../_content/_codigos_recusa';
import StarsAvaliacaoResumido from '../StarsAvaliacaoResumido/StarsAvaliacaoResumido';

import CheckDoubleDisabled from '../../../icons/ri-check-double-line-disabled.svg';
import CheckDoubleEnabled from '../../../icons/ri-check-double-line.svg';
import CarbonCircleDash from '../../../icons/carbon_circle-dash.svg';
import CarbonCircleDashPreenchido from '../../../icons/carbon_circle-dash_preenchido.svg';
import CarbonCircleCircle from '../../../icons/carbon-circle-sync.svg';
import CarbonRecusada from '../../../icons/close.svg';

const RCCardStatus = ({ rc, infoFornecedor }) => {
	const { uuid, fornecedor, status, usuario, motivos_recusa, estah_resetado, preco_total, estah_no_qc } = rc;
	const nome_fornecedor_view = fornecedor.nome !== '' ? fornecedor.nome : '-';
	const nota_fornecedor_view = fornecedor.nota_detalhada && fornecedor.nota_detalhada !== '' ? fornecedor.nota_detalhada : '-';

	const [loading_spin, setLoadSpin] = useState(false);
	const [success_send, setSuccessSend] = useState(false);
	const [error_send, setErrorSend] = useState(false);

	function sendNotification() {
		if (loading_spin) return;
		if (uuid !== undefined && uuid !== null) {
			setLoadSpin(true);
			_post(`conaz/v2/rcs/${uuid}/enviar`).then((res) => {
				setSuccessSend(true);
				if (res) {
					setLoadSpin(false);
				}
			}).catch(() => {
				setErrorSend(true);
				setLoadSpin(false);
			});
		}
	}

	if (estah_resetado) {
		return (
			<div className="col-sm-12"> {/* styleName={col_class} */}
				<div styleName="rc-status-wrapper"> {/* id={col_id} */}
					<button styleName="nome-fornecedor" onClick={() => infoFornecedor(fornecedor.id, usuario)}>{nome_fornecedor_view}</button>
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CarbonCircleCircle} alt="Em atualização" /></strong>
							<p style={{ color: '#595959' }}>Em atualização</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="col-sm-12"> {/* styleName={col_class} */}
			<div styleName="rc-status-wrapper"> {/* id={col_id} */}
				<button styleName="nome-fornecedor" onClick={() => infoFornecedor(fornecedor.id, usuario)}>{nome_fornecedor_view}</button>

				{status === -20 && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CarbonRecusada} alt="Recusada" /></strong>
							<p style={{ color: '#595959' }}>&quot;{motivos_recusa && motivos_recusa.join('; ')}&quot;</p>
						</div>
					</div>
				)}
				{status === -30 && (
					<p style={{ color: '#999' }}>Cotação cancelada.</p>
				)}

				{status === 0 && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CheckDoubleDisabled} alt="Não visualizada" /></strong>
							<p style={{ color: '#595959' }}>Não visualizou</p>
						</div>
					</div>
				)}

				{(status === 10) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CheckDoubleEnabled} alt="Visualizado" /></strong>
							<p style={{ color: '#595959' }}>Visualizou</p>
						</div>
					</div>
				)}

				{(status === 20) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CarbonCircleDash} alt="Iniciado" /></strong>
							<p style={{ color: '#595959' }}>Iniciou preenchimento</p>
						</div>
					</div>
				)}

				{(status === 30) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CarbonCircleDashPreenchido} alt="Preenchido" /></strong>
							<p style={{ color: '#595959' }}>Cotação preenchida, mas não enviada</p>
						</div>
					</div>
				)}

				{(status === 35) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CarbonCircleCircle} alt="Em atualização" /></strong>
							<p style={{ color: '#595959' }}>Atualizando proposta</p>
						</div>
					</div>
				)}

				{(status === 36) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CheckDoubleDisabled} alt="Refinamento solicitado" /></strong>
							<p style={{ color: '#595959' }}>Alteração solicitada</p>
						</div>
					</div>
				)}

				{(status === 37) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<strong><img src={CheckDoubleEnabled} alt="Em refinamento" /></strong>
							<p style={{ color: '#595959' }}>Alterando proposta</p>
						</div>
					</div>
				)}

				{((status === 40 || status === 50 || status === 60) && preco_total === 0) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<p style={{ color: '#595959' }}>{codigos_recusa[0].value}</p>
						</div>
					</div>
				)}

				{(status === 40 && preco_total > 0 && !estah_no_qc) && (
					<div styleName="status">
						<StarsAvaliacaoResumido media={nota_fornecedor_view?.media} tamanho_estrela={20} />
						{(nota_fornecedor_view?.media === '' || nota_fornecedor_view?.media === null || !nota_fornecedor_view.media) && (
							<div style={{ marginRight: '4.3rem' }} />)}
						<div styleName="status-labels">
							<p style={{ color: '#595959' }}>Fornecedor respondeu e está sendo atualizado no Quadro de Cotações.</p>
						</div>
					</div>
				)}
				<div styleName="button-notificacao-fornecedor" style={(success_send || error_send) ? { marginTop: '1rem' } : {}}>
					{success_send && (
						<strong styleName="message-notification-success"><i className="fa fa-check fa-fw" aria-hidden="true" /> &nbsp; Notificação enviada com sucesso!</strong>
					)}
					{error_send && (
						<strong styleName="message-notification-error"><i className="fa fa-times fa-fw" aria-hidden="true" /> &nbsp; Erro ao enviar notificação!</strong>
					)}
					{(!success_send && !error_send && status >= 0 && status <= 37) && (
						<button onClick={sendNotification}>
							{loading_spin ? (
								<Space size="middle">
									<Spin size="small" />
								</Space>
							) : (
								<span>Enviar uma notificação</span>
							)}

						</button>
					)}
				</div>
			</div>
		</div>
	);
};

RCCardStatus.propTypes = {
	// order: PropTypes.number.isRequired,
	rc: PropTypes.object.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(RCCardStatus, styles, { allowMultiple: true });
