import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import shallowequal from 'shallowequal';

// Components
import ItemResumido from './ItemResumido';
import ItemExpandido from './ItemExpandido';

// Functions
import { calcValorItem } from '../_subfunctions/calcValorItem';

class Item extends Component {
	// shouldComponentUpdate(nextProps) {
	// 	if (!shallowequal(this.props, nextProps)) return true;
	// 	return false;
	// }

	handleCollapse = () => {
		const { item, toggle, handleCollapseItem } = this.props;
		handleCollapseItem(!toggle, item.id);
	}

	render() {
		const {
			item,
			toggle,
			openItemEdition,
			closeItemEdition,
			selected,
			compra_has_rc,
			deleteItem,
			is_editable,
			updateModals,
			handleModalErroEdicao,
			user,
		} = this.props;
		const valores = calcValorItem(item);
		const { total } = valores;

		return (
			<>
				<ItemResumido
					item={item}
					user={user}
					total={total}
					toggle={toggle}
					handleCollapse={this.handleCollapse}
					openItemEdition={openItemEdition}
					closeItemEdition={closeItemEdition}
					selected={selected}
					compra_has_rc={compra_has_rc}
					deleteItem={deleteItem}
					type="itens_compra"
					is_editable={is_editable}
					updateModals={updateModals}
					handleModalErroEdicao={handleModalErroEdicao} />

				{toggle && (
					<ItemExpandido
						item={item}
						total={total} />
				)}
			</>
		);
	}
}

Item.propTypes = {
	item: PropTypes.object.isRequired,
	toggle: PropTypes.bool.isRequired,
	handleCollapseItem: PropTypes.func.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	closeItemEdition: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
	compra_has_rc: PropTypes.bool.isRequired,
	deleteItem: PropTypes.func.isRequired,
	is_editable: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	handleModalErroEdicao: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
};

export default Item;
