const ui = {
	base_url: '',
	environment: '',
	request_state: 0,
	modals: {
		exporta_compras: false,
		anexos: false,
		pdf_qc: false,
		error: false,
		fornecedor: false,
		notifica_decisor: false,
		divide_pedido: false,
		check_itens: false,
		perfil_cliente: false,
		onboarding_pedido_anterior: false,
		exporta_excel: false,
		itens_comb_qc: false,
		item_analise_mobile: false,
		refinamento: false,
		confirm: false,
		motivo_edicao: false,
		confirmar_refinamento: false,
		associacao: false,
	},
	tabs: {
		compras: 'ativas',
		cotacoes: 'em_andamento',
		cotacao: '',
	},
	pagination: {
		cotacoes: 1,
	},
	tawkto: false,
	changes: {
		cotacoes: false,
		compras: false,
	},
	dropdown_open: false,
	account_menu: false,
	obras_menu: false,
	actions_menu: false,
	compra_actions_menu: false,
	descartar_pedido_menu: false,
	search_state: 0,
	search: '',
	search_results: {
		pedidos: [],
		compras: [],
	},
	alerts: {
		success: {
			visible: false,
			message: '',
		},
		escolhe_cotacao: {
			visible: false,
			message: '',
		},
	},
	search_itens_open: false,
	search_fornecedores_open: false,
};

export default ui;
