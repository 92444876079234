import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import ToolTip from '../../../../components/_parts/_errors/ToolTip/ToolTip';

import { emailValidation } from '../../../../components/_functions/_emailValidation';
import { titleCase } from '../../../../components/_functions/_titleCase';
import { maskPhone } from '../../../../components/_functions/_maskPhone';
import { validaTelefone } from '../../../../components/_functions/_validaTelefone';

class CadastrarVendedor extends React.Component {
	constructor() {
		super();
		this.state = {
			nome: '',
			email: '',
			telefones: [{ numero: '', tipo: 1 }],
			errors: {
				nome: false,
				email: false,
				telefones: [],
			},
		};
	}

	updateState = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
		this.clearErrors();
	};

	handleEditionTelefones = (idx, value) => {
		const { telefones } = this.state;
		telefones[idx].numero = maskPhone(value);
		this.setState({ telefones });
		this.clearErrors();
	};

	removerTelefone = idx => {
		const { telefones } = this.state;
		telefones.splice(idx, 1);
		this.setState({ telefones });
		this.clearErrors();
	};

	adicionarTelefone = () => {
		const { telefones } = this.state;
		telefones.push({ numero: '', tipo: 1 });
		this.setState({ telefones });
		this.clearErrors();
	};

	clearErrors = () => {
		this.setState({
			errors: {
				nome: false,
				email: false,
				telefones: [],
			},
		});
	};

	validarVendedor = () => {
		const { nome, email, telefones } = this.state;
		if (nome === '') {
			this.setState({ errors: { ...this.state.errors, nome: true } });
			document.getElementById('nome_vendedor').focus();
			return false;
		}
		const email_validation = emailValidation(email);
		if (email === '' || !email_validation) {
			this.setState({ errors: { ...this.state.errors, email: true } });
			document.getElementById('email_vendedor').focus();
			return false;
		}

		let telefones_validos = true;
		const telefones_errors = this.state.errors.telefones;
		telefones.forEach((telefone, idx) => {
			if (
				telefone.numero.length > 0 &&
				!validaTelefone(telefone.numero)
			) {
				telefones_errors[idx] = true;
				telefones_validos = false;
				document
					.getElementsByClassName('telefone_vendedor')
					// eslint-disable-next-line no-unexpected-multiline
					[idx].focus();
			}
		});
		this.setState({
			errors: { ...this.state.errors, telefones: telefones_errors },
		});

		if (!telefones_validos) {
			return false;
		}

		return true;
	};

	preparaCadastroVendedor = () => {
		if (this.validarVendedor()) {
			const { nome, email, telefones } = this.state;
			const _telefones = telefones.filter(tel => tel.numero !== '');
			const vendedor = {
				nome: titleCase(nome),
				email,
				telefones: _telefones.map(tel => ({
					...tel,
					numero: tel.numero.replace(/\D/g, ''),
				})),
			};
			this.props.cadastrarVendedorFunc(vendedor);
		}
	};

	render() {
		const { salvando_novo_fornecedor } = this.props;
		const { nome, email, telefones, errors } = this.state;
		return (
			<>
				{errors.nome && (
					<ToolTip
						message="O nome do vendedor é obrigatório"
						display="top"
						margin_top="-8px" />
				)}
				<label>
					Nome <span styleName="obrigatorio">*</span>
				</label>
				<input
					type="text"
					name="nome"
					className="form-control input-md"
					id="nome_vendedor"
					value={nome}
					maxLength="60"
					onChange={this.updateState} />
				{errors.email && (
					<ToolTip
						message="O e-mail do vendedor é obrigatório"
						display="top"
						margin_top="-33px" />
				)}
				<label>
					Email <span styleName="obrigatorio">*</span>
				</label>
				<input
					type="text"
					name="email"
					className="form-control input-md"
					id="email_vendedor"
					maxLength="80"
					value={email}
					onChange={this.updateState} />
				{/* eslint-disable react/no-array-index-key */
					telefones.map((tel, idx) => (
						<React.Fragment key={idx}>
							{errors.telefones[idx] && (
								<ToolTip
									message="Telefone inválido"
									display="top"
									margin_top="-34px"
									margin_left="40px" />
							)}
							<label>Telefone</label>
							<input
								type="text"
								name="telefone"
								className="form-control input-md telefone_vendedor"
								styleName="telefone"
								value={tel.numero}
								onChange={e => this.handleEditionTelefones(idx, e.target.value)
								} />
							<button
								type="button"
								className="btn btn-link"
								style={{ fontSize: '16px' }}
								onClick={() => this.removerTelefone(idx)}>
								<i className="fa fa-trash" aria-hidden="true" />
							</button>
						</React.Fragment>
					))}
				<div>
					<button
						type="button"
						className="btn btn-link"
						styleName="novo-telefone-button"
						onClick={this.adicionarTelefone}>
						<i className="fa fa-plus fa-fw" aria-hidden="true" />{' '}
						adicionar novo telefone
					</button>
				</div>

				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					styleName={
						salvando_novo_fornecedor
							? 'btn-adicionar-salvando'
							: 'btn-adicionar'
					}
					disabled={salvando_novo_fornecedor}
					onClick={this.preparaCadastroVendedor}>
					{salvando_novo_fornecedor
						? 'Salvando vendedor...'
						: 'Selecionar'}
				</button>
			</>
		);
	}
}

CadastrarVendedor.propTypes = {
	salvando_novo_fornecedor: PropTypes.bool.isRequired,
	cadastrarVendedorFunc: PropTypes.func.isRequired,
};

export default CSSModules(CadastrarVendedor, styles, { allowMultiple: true });
