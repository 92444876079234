import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Function
import { splitName } from './utils';

// Components
import ButtonIcon from '../../../../pages/CompraV3/subcomponents/Anexos/elements/ButtonIcon';

class FileError extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	deleteFile = () => {
		const { deleteFile, updateErrorSize: {
			updateErrorSize,
			modalErrorFeedback,
		} } = this.props;
		if (modalErrorFeedback) {
			deleteFile();
			updateErrorSize();
		}
		deleteFile();
	}

	render() {
		const { arquivo } = this.props;

		return (
			<div styleName="file-wrapper form-with-error">
				<div styleName="icon-name">
					<i className="fa fa-file" aria-hidden="true" style={{ color: '#e54662' }} />
					<div styleName="file-name">
						{splitName(arquivo.nome, 15)}
					</div>
					<div styleName="file-name-mobile">
						{splitName(arquivo.nome, 10)}
					</div>
				</div>
				{/* <button
					type="button"
					styleName="delete-button with-error"
					onClick={deleteFile}>
					<i className="fa fa-times" aria-hidden="true" />
				</button> */}
				{/* <div styleName="file-all">
					<div styleName="nome">{splitName(arquivo.nome, 18)}</div>
					{arquivo.tipo}
				</div> */}
				<div styleName="file-progress">
					<div styleName="error-message">
						Erro. Arquivo maior do que o permitido (32mb).
					</div>
				</div>
				<span>
					<ButtonIcon icon="fa fa-trash" func={this.deleteFile} />
				</span>
			</div>
		);
	}
}

FileError.propTypes = {
	updateErrorSize: PropTypes.shape({
		updateErrorSize: PropTypes.func.isRequired,
		modalErrorFeedback: PropTypes.bool.isRequired,
	}).isRequired,
	arquivo: PropTypes.shape({
		id: PropTypes.number.isRequired,
		nome: PropTypes.string.isRequired,
		file: PropTypes.object.isRequired,
		tipo: PropTypes.string.isRequired,
		tamanho: PropTypes.number,
		success: PropTypes.number.isRequired,
		size_error: PropTypes.bool.isRequired,
		error_message: PropTypes.string.isRequired,
	}).isRequired,
	// arquivos_para_enviar: PropTypes.objectOf(PropTypes.shape({
	// 	id: PropTypes.number.isRequired,
	// 	nome: PropTypes.string.isRequired,
	// 	file: PropTypes.object.isRequired,
	// 	tipo: PropTypes.string.isRequired,
	// 	tamanho: PropTypes.number,
	// 	progress: PropTypes.number.isRequired,
	// 	success: PropTypes.number.isRequired,
	// 	error: PropTypes.bool.isRequired,
	// 	error_message: PropTypes.string.isRequired,
	// })).isRequired,
	deleteFile: PropTypes.func.isRequired,
};

export default CSSModules(FileError, styles, { allowMultiple: true });
