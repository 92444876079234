import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalNotificaDecisor from './ModalNotificaDecisor';

// Functions
import { getLocalstorageData } from '../../../_functions/_getLocalstorageData';

const ModalNotificaSobreCompra = ({
	compra_id,
	obra_id,
	obra_nome,
	updateModals,
	compra_valor,
	titulo_modal,
	subtitulo_modal,
	pre_mensagem_text,
	tipo,
	redirectAfterAskAproval,
}) => {
	const user_nome = getLocalstorageData('user', 'nome');
	const assunto = `Notificação sobre a compra ${compra_id}`;
	const pre_mensagem = `${user_nome} ${pre_mensagem_text} ${compra_id} (obra: ${obra_nome}).`;

	const valor_check = compra_valor || 0;
	const valor_total = valor_check.toString().indexOf(',') !== -1
		? Number(valor_check.toString().replace(/[,]/g, '.'))
		: Number(valor_check.toString());

	return (
		<ModalNotificaDecisor
			sobre="compra"
			codigo={`${compra_id}`}
			assunto={assunto}
			pre_mensagem={pre_mensagem}
			texto_fim_mensagem="Você pode acessá-la através do link abaixo:"
			obra_selected={obra_id}
			updateModals={updateModals}
			compra_valor={valor_total}
			titulo_modal={titulo_modal}
			subtitulo_modal={subtitulo_modal}
			tipo={tipo}
			redirectAfterAskAproval={redirectAfterAskAproval} />
	);
};

ModalNotificaSobreCompra.propTypes = {
	obra_nome: PropTypes.string.isRequired,
	obra_id: PropTypes.number.isRequired,
	compra_id: PropTypes.number.isRequired,
	updateModals: PropTypes.func.isRequired,
	compra_valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	titulo_modal: PropTypes.string,
	subtitulo_modal: PropTypes.string,
	pre_mensagem_text: PropTypes.string,
	tipo: PropTypes.string,
	redirectAfterAskAproval: PropTypes.func,
};

ModalNotificaSobreCompra.defaultProps = {
	compra_valor: 0,
	titulo_modal: 'Notificar',
	subtitulo_modal: 'Selecione quem você deseja notificar',
	pre_mensagem_text: 'está te notificando sobre a compra',
	tipo: 'notificacao',
	redirectAfterAskAproval: () => {},
};

export default ModalNotificaSobreCompra;
