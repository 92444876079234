/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/pt-br';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Componentes
import InputNumber from '../../../../components/_parts/_inputs/InputNumber/InputNumber';
import OpcaoTransporte from './OpcaoTransporte';
// import OpcaoFretePagar from './OpcaoFretePagar';
import ValorDoFornecedor from './ValorDoFornecedor';
import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

// Function
import { isWeekday } from '../../../../components/_functions/_isWeekday';
import { getDaysBetweenDates } from '../../../../components/_functions/_getDaysBerweenDates';
import { editMomentDateFormat } from '../../../../components/_functions/_editDateFormat';
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

class Transporte extends Component {
	constructor(props) {
		super(props);
		const data_de_entrega =
			props.compra.data_de_entrega !== null
				? moment(props.compra.data_de_entrega).format('YYYY-MM-DD')
				: moment('2016-01-01').format('YYYY-MM-DD');

		this.state = {
			data_de_entrega,
			error: {},
			alert_data_minima: false,
		};
	}

	UNSAFE_componentWillMount() {
		const { data_de_entrega } = this.state;
		const blank_data_entrega =
				data_de_entrega === moment('2016-01-01').format('YYYY-MM-DD');
		const date_selected = !blank_data_entrega
			? moment(data_de_entrega)
			: null;
		this.validaPrazo(date_selected);
	}

	handleTransporte = transporte => {
		const {
			compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;

		const nova_compra = {
			...compra,
			transporte,
		};

		if (transporte !== 1) {
			nova_compra.valor_transporte = 0;
		}

		handleFixError('transporte');
		updateComprasRaw({ nova_compra });
		autoSaveStart();
	};

	handleTransporteProprio = (transporte_proprio) => {
		const {
			compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;

		const nova_compra = {
			...compra,
			transporte_proprio,
		};

		// if (transporte !== 1) {
		// 	nova_compra.valor_transporte = 0;
		// }

		handleFixError('transporte_proprio');
		updateComprasRaw({ nova_compra });
		autoSaveStart();
	};

	validaPrazo = data_de_entrega => {
		const { compra: nova_compra } = this.props;
		const rc_analise_id = nova_compra.rc_analise_id || null;
		const rc_uuid = (nova_compra.rc || {}).uuid || null;
		const tem_rc = rc_analise_id !== null || rc_uuid !== null;

		if (tem_rc) {
			const rc = nova_compra.rc || {};
			const today = moment();
			let dias_prazo_entrega =
			rc.prazo_de_entrega !== null ? rc.prazo_de_entrega : 0;
			const formated_date_with_sundays = moment().add(dias_prazo_entrega, 'days');
			const sundays_between_date =
				getDaysBetweenDates(new Date(moment().format('YYYY,MM,DD')),
					new Date(formated_date_with_sundays.format('YYYY,MM,DD')), 'Sun').length;
			if (today.hour() >= 16 && today.hour() <= 23) {
				dias_prazo_entrega++;
			}
			if (sundays_between_date > 0) {
				dias_prazo_entrega += sundays_between_date;
			}
			const dias_prazo_entrega_fds_added =
				dias_prazo_entrega <= 10
					? dias_prazo_entrega + 10
					: Math.ceil(
						(dias_prazo_entrega / 5) * 3 + dias_prazo_entrega,
					);
			let data_entrega_min = moment();
			let days = 0;
			for (let i = 1; i <= dias_prazo_entrega_fds_added; i++) {
				days++;
				if (days === dias_prazo_entrega) {
					data_entrega_min = moment().add(i, 'd');
					break;
				}
			}
			const data_entrega_menor_minimo = moment(data_de_entrega).isBefore(
				data_entrega_min,
				'day',
			);

			if (
				data_entrega_menor_minimo &&
				rc.transporte !== 2 &&
				nova_compra.tipo !== 5
			) {
				this.setState({ alert_data_minima: true });
				return;
			}
		}

		this.setState({ alert_data_minima: false });
	};

	handleValorTransporte = (item_key, field, valor_transporte) => {
		const {
			compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;
		const nova_compra = { ...compra, valor_transporte };
		handleFixError('valor_transporte');
		updateComprasRaw({ nova_compra });
		autoSaveStart();
	};

	handleDataEntrega = _date => {
		const {
			compra,
			updateComprasRaw,
			handleFixError,
			autoSaveStart,
		} = this.props;
		handleFixError('data_de_entrega');
		this.setState({ error: {} }, () => {
			const data_de_entrega = _date ? _date.format('YYYY-MM-DD') : _date;
			if (this.validaPrazo(data_de_entrega)) {
				this.setState({
					error: {
						prazo_invalido:
							'Data de entrega não pode ser inferior ao prazo',
					},
				});
				return;
			}
			const nova_compra = {
				...compra,
				data_de_entrega,
			};
			this.setState({ data_de_entrega });
			updateComprasRaw({ nova_compra });
			autoSaveStart();
		});
	};

	render() {
		const {
			data_de_entrega,
			error,
			alert_data_minima,
		} = this.state;
		const {
			compra,
			errors,
			handleFixError,
			is_mobile,
		} = this.props;
		const {
			is_editable,
			tipo,
			transporte,
		} = compra;

		const valor_transporte = compra.valor_transporte || 0;

		const min_date = tipo === 4 ? moment() : null;
		const blank_data_entrega =
			data_de_entrega === moment('2016-01-01').format('YYYY-MM-DD');
		const date_selected = !blank_data_entrega
			? moment(data_de_entrega)
			: null;

		const rc = compra.rc || {};
		const rc_uuid = rc.uuid || null;
		const tem_rc = rc_uuid !== null;

		const valor_transporte_class = errors.valor_transporte
			? 'valor-transporte-wrapper has-error'
			: 'valor-transporte-wrapper';

		if (is_editable) {
			return (
				<div styleName="info-row">
					<div>
						<div styleName={`label ${errors.transporte ? 'error' : ''}`}>
							<span>Transporte </span> <span styleName="obrigatorio">*</span>
							{(
								(rc.transporte !== undefined
								&& rc.transporte !== transporte)
								|| (rc.transporte === 1 && transporte === 1
									&& rc.valor_transporte !== valor_transporte)
							) && (
								<>
									<div styleName="tag editado">
										Editado
									</div>
									<div styleName="tooltip-hover">
										<Tooltip
											margin_top="-10.5%"
											margin_left="5%"
											text="Diferente da cotação do fornecedor" />
									</div>
								</>
							)}
						</div>
						<ValorDoFornecedor
							compra={compra} />
						<OpcaoTransporte
							value={0}
							label="Incluso no preço"
							transporte={transporte}
							handleTransporte={this.handleTransporte} />
						<OpcaoTransporte
							value={1}
							label="A pagar"
							transporte={transporte}
							handleTransporte={this.handleTransporte} />
						{transporte === 1 && (
							<div styleName="valor-frete-wrapper">
								<div styleName={valor_transporte_class}>
									<InputNumber
										type="item"
										field_id={`valor_transporte-${compra.id}`}
										item_key={0}
										field="valor_transporte"
										default_value={valor_transporte}
										updateFunction={this.handleValorTransporte}
										icon="R$"
										icon_width={40}
										zerar_erros={false} />
								</div>
							</div>
						)}
						<OpcaoTransporte
							value={2}
							label="Não incluso"
							transporte={transporte}
							handleTransporte={this.handleTransporte} />
					</div>
					<div style={{ paddingTop: '20px' }}>
						<label styleName={errors.data_de_entrega ? 'error' : ''}>
							Data de entrega do material em obra
							<span styleName="obrigatorio"> *</span>
						</label>
						{tem_rc && (
							<p style={{ color: '#111' }}>
								<span style={{ color: '#999' }}>Prazo mínimo do fornecedor: </span>
								{rc.prazo_de_entrega} {rc.prazo_de_entrega === 1 ? 'dia útil' : 'dias úteis'}, considerando ordens de compra até às 16h
							</p>
						)}
						{(errors.data_de_entrega ||
							error.prazo_invalido) && (
							<Tooltip
								margin_top={is_mobile ? '-15%' : '-5%'}
								text={
									errors.data_de_entrega ||
									error.prazo_invalido
								} />
						)}
						<div className="form-control-date-wrapper">
							<i className="fa fa-calendar" aria-hidden="true" />
							<DatePicker
								id="data_entrega"
								styleName={`datepicker ${
									errors.data_de_entrega ||
									error.prazo_invalido
										? 'error'
										: ''
								}`}
								className="form-control-date"
								selected={date_selected}
								minDate={min_date}
								onChange={this.handleDataEntrega}
								onFocus={() => handleFixError('data_de_entrega')}
								isWeekday={isWeekday}
								peekNextMonth
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
								locale="pt-br" />
						</div>
						{alert_data_minima && (
							<div className="alert alert-warning" role="alert" style={{ display: 'inline-block', marginTop: '15px', marginBottom: '8px' }}>
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> A data selecionada é menor que o prazo mínimo do fornecedor.
							</div>
						)}
					</div>
				</div>
			);
		}

		const data_entrega_view =
			data_de_entrega !== '' && data_de_entrega !== null ? (
				editMomentDateFormat(data_de_entrega)
			) : (
				<em styleName="em">Sem registro</em>
			);

		// transporte_proprio
		// const opcoes_tp = {
		// 	0: 'Próprio',
		// 	1: 'Terceirizado',
		// 	2: 'A Combinar',
		// };
		// const transporte_proprio_view = opcoes_tp[transporte_proprio] || 'A Combinar';
		let transporte_view;

		switch (transporte) {
		case 0:
			transporte_view = (
				<p styleName="transporte_view">Incluso no preço</p>
			);
			break;
		case 1:
			transporte_view = (
				<p styleName="transporte_view">
					A pagar: {formatCurrency(valor_transporte, 2, 'R$')}<br />
					<span style={{ color: '#a5a7aa' }}>(Já incluso)</span>
				</p>
			);
			break;
		case 2:
			transporte_view = (
				<p styleName="transporte_view">Não incluso</p>
			);
			break;
		default:
			break;
		}

		return (
			<div styleName="info-row">
				<div>
					<div styleName="label">Transporte</div>
					{transporte_view}
				</div>
				<div>
					<label style={{ paddingTop: '15px', marginBottom: '3px' }}>Data de entrega do material em obra:</label><br />
					<span styleName="date_view">
						{data_entrega_view}.
					</span>
				</div>
			</div>
		);
	}
}

Transporte.propTypes = {
	compra: PropTypes.object.isRequired,
	is_mobile: PropTypes.bool.isRequired,
	errors: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(Transporte, styles, { allowMultiple: true });
