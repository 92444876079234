import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
import StarsAvaliacao from './StarsAvaliacao';

const ComentarioAvaliacao = ({
	avaliacao,
}) => {
	const formatarData = (aaaammdd) => {
		const data = aaaammdd.split('T')[0].split('-');
		const aaaa = data[0];
		const mm = data[1];
		const dd = data[2];
		return `${dd}/${mm}/${aaaa}`;
	};
	if (avaliacao.nome_vendedor) {
		return <div key={avaliacao.comentario_fornecedor} styleName="comentario-avaliacao">
			<div styleName="avaliacao-header"><div styleName="cidade-comentario"><b>{avaliacao.cidade} - {avaliacao.estado}</b></div><div styleName="comentario-criado-em">&nbsp;&nbsp;-&nbsp; {formatarData(avaliacao.criado_em)}</div></div>
			<StarsAvaliacao media={avaliacao.estrelas_fornecedor} />
			<p>&quot;{avaliacao.comentario_fornecedor}&quot;</p>
			<div styleName="linha-horizontal" />
			<div styleName="nome-vendedor-container">
				<span styleName="vendedor">Vendedor -&nbsp;</span>
				<span styleName="nome-vendedor">{avaliacao.nome_vendedor}</span>
				<StarsAvaliacao media={avaliacao.estrelas_vendedor} />
			</div>
			<p>&quot;{avaliacao.comentario_vendedor}&quot;</p>
		</div>;
	}
	return (
		<div key={avaliacao.comentario} styleName="comentario-avaliacao">
			<div styleName="avaliacao-header"><div styleName="cidade-comentario"><b>{avaliacao.cidade} - {avaliacao.estado}</b></div><div styleName="comentario-criado-em">&nbsp;&nbsp;-&nbsp; {formatarData(avaliacao.criado_em)}</div></div>
			<StarsAvaliacao media={avaliacao.estrelas} />
			<p>&quot;{avaliacao.comentario}&quot;</p>
		</div>
	);
};

ComentarioAvaliacao.propTypes = {
	avaliacao: PropTypes.object.isRequired,
};

export default CSSModules(ComentarioAvaliacao, styles, { allowMultiple: true });
