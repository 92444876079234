import React, { Component } from 'react';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import IntegracaoInicio from './IntegracaoInicio';

class IntegracaoInicioContainer extends Component {
	componentDidMount() {
		document.title = 'Integração - Conaz';
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>
				<IntegracaoInicio {...this.props} />
			</LayoutWrapper>
		);
	}
}

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(IntegracaoInicioContainer));
