import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

class OpcaoTransporte extends Component {
	handleClick = () => {
		const { value, handleTransporte } = this.props;
		handleTransporte(value);
	};

	render() {
		const { value, transporte, label } = this.props;
		const btn_class =
			transporte === value
				? 'radio-button-block selected'
				: 'radio-button-block';

		return (
			<button
				type="button"
				onClick={this.handleClick}
				styleName={btn_class}>
				<div styleName="radio" />
				<div styleName="transporte">{label}</div>
			</button>
		);
	}
}

OpcaoTransporte.propTypes = {
	value: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
	transporte: PropTypes.number,
	handleTransporte: PropTypes.func.isRequired,
};

OpcaoTransporte.defaultProps = {
	transporte: null,
};

export default CSSModules(OpcaoTransporte, styles, { allowMultiple: true });
