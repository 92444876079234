import React from 'react';
import inboxOutlined from '../../../icons/inbox_group.svg';

export default {
	triggerDesc: 'Clique para ordenar descendentemente',
	triggerAsc: 'Clique para ordenar ascendentemente',
	cancelSort: 'Clique para retirar a ordenção',
	emptyText: (
		<>
			<span>
				<img src={inboxOutlined} alt="Sem registros" style={{ width: '100px', paddingTop: '3rem', paddingBottom: '1.5rem' }} />
				<p style={{ fontSize: '15px' }}>Sem dados</p>
			</span>
		</>
	),
};

// https://github.com/ant-design/ant-design/blob/6dae4a7e18ad1ba193aedd5ab6867e1d823e2aa4/components/locale/default.tsx#L19-L37
