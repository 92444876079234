import * as Types from './types';

export const getUsersByClient = id => ({
	type: Types.USERS_BY_CLIENT_REQUEST,
	payload: { id },
});

export const getUsersByClientSuccess = users => ({
	type: Types.USERS_BY_CLIENT_SUCCESS,
	payload: { users },
});

export const getUsersByClientError = error => ({
	type: Types.USERS_BY_CLIENT_ERROR,
	payload: { error },
});


export const editUser = (data) => ({
	type: Types.USERS_EDIT_REQUEST,
	payload: {
		action: 'edit',
		data,
	},
});

export const createUser = (data) => ({
	type: Types.USERS_CREATE_REQUEST,
	payload: {
		action: 'new',
		data,
	},
});

export const ativaUser = (data) => ({
	type: Types.USER_ACTIVATE_REQUEST,
	payload: {
		action: 'edit',
		data,
	},
});

export const disableUser = (data) => ({
	type: Types.USERS_DISABLE_REQUEST,
	payload: {
		action: 'edit',
		data,
	},
});

export const alteraStatusUserSuccess = (users) => ({
	type: Types.USERS_ALTERA_STATUS_SUCCESS,
	payload: { users },
});

export const createUserSuccess = (data) => ({
	type: Types.USERS_CREATE_SUCCESS,
	payload: {
		data,
	},
});

export const editUserSuccess = (data) => ({
	type: Types.USERS_EDIT_SUCCESS,
	payload: {
		data,
	},
});

export const userActionError = (erro_motivo, action) => ({
	type: Types.USERS_ACTION_ERROR,
	payload: { erro_motivo, action },
});


export const openUserDrawer = (mode, user) => ({
	type: Types.USERS_OPEN_DRAWER,
	payload: {
		userToDrawer: user,
		drawerMode: mode,
	},
});

export const closeUserDrawer = () => ({
	type: Types.USERS_CLOSE_DRAWER,
});
