import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import SearchResult from './SearchResult';
import Loading from '../../_loadings/Loading/Loading';

// Functions
import { _get } from '../../../_functions/_requests';
import { replaceSearchText } from '../../../_functions/_replaceSearchText';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class Sugestoes extends Component {
	state = {
		loading: 0,
		sugestoes: [],
	};

	componentDidMount() {
		this.getSugestoes();
	}

	getSugestoes() {
		this.setState({ loading: 1 });
		const { item_on_edition } = this.props;
		const pre_nome = item_on_edition.pre_nome || '';
		const url = `/conaz/v2/itens/search?q=${pre_nome}`;
		_get(url).then(response => {
			const sugestoes = replaceSearchText(pre_nome, response.data);
			this.setState({ sugestoes, loading: 2 });
		}).catch(error => {
			handleRequestErrors(error);
			this.setState({ sugestoes: [], loading: 0 });
		});
	}

	render() {
		const {
			loading,
			sugestoes,
		} = this.state;
		const {
			type,
			add_or_update,
			item_on_edition,
			openItemEdition,
			autoSave,
		} = this.props;

		const item_on_edition_id = item_on_edition.id || 0;

		if (loading === 0 || loading === 1) {
			return (
				<>
					<h4 styleName="populares-title">Sugestões</h4>
					<div style={{ position: 'relative', float: 'left' }}><Loading /></div>
				</>
			);
		}

		return (
			<>
				<h4 styleName="populares-title">Sugestões</h4>
				<div styleName="search-results">
					{sugestoes.map(item => (
						<SearchResult
							key={item.id}
							type={type}
							add_or_update={add_or_update}
							item_on_edition_id={item_on_edition_id}
							item={item}
							openItemEdition={openItemEdition}
							autoSave={autoSave} />
					))}
				</div>
			</>
		);
	}
}

Sugestoes.propTypes = {
	type: PropTypes.string.isRequired,
	add_or_update: PropTypes.string.isRequired,
	item_on_edition: PropTypes.object.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(Sugestoes, styles, { allowMultiple: true });
