export const formatCaracteristica = (value) => {
	let caracteristica = null;
	if (value[0].id) {
		caracteristica = {
			id: value[0].caracteristica_id,
			opcoes_ids: [value[0].id],
		};
	} else {
		caracteristica = {
			id: value[0].caracteristica_id,
			outra_opcao: `${value[0].valor || ''}`,
			unidade: value[0].unidade || '',
		};
	}
	return caracteristica;
};
