export const defineStatusItemSienge = item => {
	const { item_associado_caracteristicas } = (item || {});

	if (!item_associado_caracteristicas) {
		return 'no_associado';
	}

	if (item_associado_caracteristicas) {
		return 'associado';
	}
	return 'no_associado';
	// switch (status) {
	// 	case 0:
	// 		return 'associado';
	// 	case 1:
	// 		return 'no_associado';
	// 	case 2:
	// 		return 'detalhado';
	// 	default:
	// 		return 'no_associado';
	// }
};
