import INITAL_STATE from './store';
import * as Types from './types';

const adicionarEmpresa = (obra, empresa) => {
	let existe = false;
	const novaLista = obra.pessoas.map((p) => {
		if (p.id === empresa.id) {
			existe = true;
			return empresa;
		}
		return p;
	});

	if (!existe) {
		novaLista.push(empresa);
	}

	return novaLista;
};

const adicionaUsuario = (obra, usuario) => {
	let existe = false;
	const novaLista = obra.usuarios.map((u) => {
		if (u.id === usuario.id) {
			existe = true;
			return usuario;
		}
		return u;
	});

	if (!existe) novaLista.push(usuario);

	return novaLista;
};

const reducer = (state = INITAL_STATE, action) => {
	switch (action.type) {
	// Pega obra selecionada
	case Types.ASSOCIA_OBRA_GET_OBRA_REQUEST:
		return {
			...state,
			isFetching: true,
		};
	case Types.ASSOCIA_OBRA_GET_OBRA_SUCCESS:
		return {
			...state,
			isFetching: false,
			obra: action.payload.data,
			erro_motivo: null,
		};
	case Types.ASSOCIA_OBRA_GET_OBRA_ERROR:
		return {
			...state,
			isFetching: false,
			erro_motivo: action.payload.erro_motivo,
		};
	// edita obra
	case Types.ASSOCIA_OBRA_EDIT_OBRA_REQUEST:
	case Types.ASSOCIA_OBRA_STATUS_VISUALIZACAO:
	case Types.ASSOCIA_OBRA_STATUS_ATIVO:
		return {
			...state,
			actionFetching: true,
		};
	case Types.ASSOCIA_OBRA_EDIT_OBRA_SUCCESS:
		return {
			...state,
			actionFetching: false,
			erro_motivo: null,
			obra: action.payload.data,
			showEditObraDrawer: false,
		};
	case Types.ASSOCIA_OBRA_EDIT_OBRA_ERROR:
		return {
			...state,
			erro_motivo: action.payload.erro_motivo,
			actionFetching: false,
		};
	case Types.ASSOCIA_OBRA_OPEN_EDIT_OBRA_DRAWER:
		return {
			...state,
			showEditObraDrawer: true,
		};
	case Types.ASSOCIA_OBRA_CLOSE_EDIT_OBRA_DRAWER:
		return {
			...state,
			showEditObraDrawer: false,
		};

	// Associa empresa a obra
	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_REQUEST:
		return {
			...state,
			actionFetching: true,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_SUCCESS:
		return {
			...state,
			actionFetching: false,
			showAssociaEmpresaDrawer: false,
			erro_motivo: null,
			obra: {
				...state.obra,
				pessoas: adicionarEmpresa(state.obra, action.payload.data),
			},
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_ERROR:
		return {
			...state,
			actionFetching: false,
			erro_motivo: action.payload.erro_motivo,
		};

	case Types.ASSOCIA_OBRA_REMOVE_EMPRESA_REQUEST:
		return {
			...state,
			actionFetching: true,
		};
	case Types.ASSOCIA_OBRA_REMOVE_EMPRESA_SUCCESS:
		return {
			...state,
			actionFetching: false,
			erro_motivo: null,
			obra: {
				...state.obra,
				pessoas: state.obra.pessoas.filter((p) => p.pessoa.id !== action.payload.empresa_id),
			},
		};
	case Types.ASSOCIA_OBRA_REMOVE_EMPRESA_ERROR:
		return {
			...state,
			actionFetching: false,
			erro_motivo: action.payload.erro_motivo,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_DRAWER:
		return {
			...state,
			showAssociaEmpresaDrawer: true,
			empresaToEdit: null,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_EDIT_DRAWER:
		return {
			...state,
			showAssociaEmpresaDrawer: true,
			empresaToEdit: action.payload.data,
		};

	case Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_CLOSE_DRAWER:
		return {
			...state,
			showAssociaEmpresaDrawer: false,
			empresaToEdit: null,
		};

	// Associa usuario
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_REQUEST:
		return {
			...state,
			actionFetching: true,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_SUCCESS:
		return {
			...state,
			erro_motivo: null,
			actionFetching: false,
			showAssociaUsuarioDrawer: false,
			usuarioToEdit: null,
			obra: {
				...state.obra,
				usuarios: adicionaUsuario(state.obra, action.payload.data),
			},
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_ERROR:
		return {
			...state,
			actionFetching: false,
			erro: action.payload.erro_motivo,
		};
	case Types.ASSOCIA_OBRA_REMOVE_USUARIO_REQUEST:
		return {
			...state,
			actionFetching: true,
		};
	case Types.ASSOCIA_OBRA_REMOVE_USUARIO_SUCCESS:
		return {
			...state,
			actionFetching: false,
			obra: {
				...state.obra,
				usuarios: state.obra.usuarios.filter((p) => p.usuario.id !== action.payload.user_id),
			},
		};
	case Types.ASSOCIA_OBRA_REMOVE_USUARIO_ERROR:
		return {
			...state,
			actionFetching: false,
			erro_motivo: action.payload.erro_motivo,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_DRAWER:
		return {
			...state,
			showAssociaUsuarioDrawer: true,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_EDIT_DRAWER:
		return {
			...state,
			showAssociaUsuarioDrawer: true,
			usuarioToEdit: action.payload.data,
		};
	case Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_CLOSE_DRAWER:
		return {
			...state,
			showAssociaUsuarioDrawer: false,
		};

	case Types.ASSOCIA_OBRA_GET_LIMPA_ESTADO:
		return INITAL_STATE;
	default:
		return state;
	}
};

export default reducer;
