import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputSelectGeral from '../../../../components/_parts/_inputs/InputSelectGeral/InputSelectGeral';

// Functions
import { focusOnElement } from '../../../../components/_functions/_focusOnElement';

// Content
import {
	formas_pagamento,
	prazos_pagamento,
} from '../../../../components/_content/_condicoes_pagamento';

class CV3CondicaoPagamento extends Component {
	constructor() {
		super();
		this.state = {
			outra_forma: false,
			outra_forma_value: '',
			outro_prazo: false,
			outro_prazo_value: '',
		};
		this.handleForma = this.handleForma.bind(this);
		this.handleOutraForma = this.handleOutraForma.bind(this);
		this.switchOutraForma = this.switchOutraForma.bind(this);
		this.checkOutraForma = this.checkOutraForma.bind(this);
		this.handlePrazo = this.handlePrazo.bind(this);
		this.handleOutroPrazo = this.handleOutroPrazo.bind(this);
		this.switchOutroPrazo = this.switchOutroPrazo.bind(this);
		this.checkOutroPrazo = this.checkOutroPrazo.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { condicao_pagamento } = this.props;
		const outra_forma = (condicao_pagamento || {}).outra_forma || '';
		const outro_prazo = (condicao_pagamento || {}).outro_prazo || '';
		if (outra_forma !== '') {
			this.setState({
				outra_forma: true,
				outra_forma_value: outra_forma,
			});
		}
		if (outro_prazo !== '') {
			this.setState({
				outro_prazo: true,
				outro_prazo_value: outro_prazo,
			});
		}
	}

	handleForma(field, value) {
		const { handleUpdate } = this.props;
		const condicao_pagamento = {
			...this.props.condicao_pagamento,
			forma: value,
			outra_forma: '',
		};
		handleUpdate(condicao_pagamento);
	}

	handleOutraForma(e) {
		const outra_forma_value = e.target.value.toString();
		this.setState({ outra_forma_value });
		const { handleUpdate } = this.props;
		const condicao_pagamento = {
			...this.props.condicao_pagamento,
			forma: -1,
			outra_forma: outra_forma_value,
		};
		handleUpdate(condicao_pagamento);
	}

	switchOutraForma() {
		const outra_forma = !this.state.outra_forma;
		this.setState({ outra_forma });
		if (outra_forma) {
			setTimeout(() => focusOnElement('outra_forma_value'), 60);
		} else {
			const { handleUpdate } = this.props;
			const condicao_pagamento = {
				...this.props.condicao_pagamento,
				forma: 0,
				outra_forma: '',
			};
			handleUpdate(condicao_pagamento);
		}
	}

	checkOutraForma() {
		const { outra_forma, outra_forma_value } = this.state;
		if (outra_forma && outra_forma_value === '') this.switchOutraForma();
	}

	handlePrazo(field, value) {
		const { handleUpdate } = this.props;
		const condicao_pagamento = {
			...this.props.condicao_pagamento,
			prazo: value,
			outro_prazo: '',
		};
		handleUpdate(condicao_pagamento);
	}

	handleOutroPrazo(e) {
		const outro_prazo_value = e.target.value.toString();
		this.setState({ outro_prazo_value });
		const { handleUpdate } = this.props;
		const condicao_pagamento = {
			...this.props.condicao_pagamento,
			prazo: -1,
			outro_prazo: outro_prazo_value,
		};
		handleUpdate(condicao_pagamento);
	}

	switchOutroPrazo() {
		const outro_prazo = !this.state.outro_prazo;
		this.setState({ outro_prazo });
		if (outro_prazo) {
			setTimeout(() => focusOnElement('outro_prazo_value'), 60);
		} else {
			const { handleUpdate } = this.props;
			const condicao_pagamento = {
				...this.props.condicao_pagamento,
				prazo: 0,
				outro_prazo: '',
			};
			handleUpdate(condicao_pagamento);
		}
	}

	checkOutroPrazo() {
		const { outro_prazo, outro_prazo_value } = this.state;
		if (outro_prazo && outro_prazo_value === '') this.switchOutroPrazo();
	}

	render() {
		const {
			outra_forma,
			outra_forma_value,
			outro_prazo,
			outro_prazo_value,
		} = this.state;
		const { type, condicao_pagamento, error, handleFixError } = this.props;

		const formas_pagamento_list = Object.keys(formas_pagamento)
			.map(f => ({
				id: formas_pagamento[f].value,
				valor: formas_pagamento[f].view,
			}))
			.filter(x => x.id !== 0 && x.id !== -1);
		const prazos_pagamento_list = Object.keys(prazos_pagamento)
			.map(p => ({
				id: prazos_pagamento[p].value,
				valor: prazos_pagamento[p].view,
				order: prazos_pagamento[p].order,
			}))
			.filter(x => x.id !== -1 && x.id !== 0)
			.sort((a, b) => a.order - b.order);

		if (type === 'forma') {
			const default_forma =
				(formas_pagamento[(condicao_pagamento || {}).forma] || {})
					.view || '';
			return (
				<div styleName="condicao-pagamento-wrapper">
					{!outra_forma ? (
						<InputSelectGeral
							field_id="forma_pagamento_externa"
							field="forma"
							error={error}
							handleFixError={handleFixError}
							default_value={default_forma}
							opcoes={formas_pagamento_list}
							updateFunction={this.handleForma}
							handleOutro={this.switchOutraForma} />
					) : (
						<input
							type="text"
							className="form-control input-md"
							id="outra_forma_value"
							maxLength="175"
							value={outra_forma_value}
							onChange={this.handleOutraForma}
							onBlur={this.checkOutraForma} />
					)}
				</div>
			);
		}

		const default_prazo =
			(prazos_pagamento[(condicao_pagamento || {}).prazo] || {}).view ||
			'';

		return (
			<div styleName="condicao-pagamento-wrapper">
				{!outro_prazo ? (
					<InputSelectGeral
						field_id="prazo_pagamento_externa"
						field="prazo"
						error={error}
						handleFixError={handleFixError}
						default_value={default_prazo}
						opcoes={prazos_pagamento_list}
						updateFunction={this.handlePrazo}
						handleOutro={this.switchOutroPrazo} />
				) : (
					<input
						type="text"
						className="form-control input-md"
						id="outro_prazo_value"
						maxLength="175"
						value={outro_prazo_value}
						onChange={this.handleOutroPrazo}
						onBlur={this.checkOutroPrazo} />
				)}
			</div>
		);
	}
}

CV3CondicaoPagamento.propTypes = {
	type: PropTypes.string.isRequired,
	handleUpdate: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	condicao_pagamento: PropTypes.object.isRequired,
	error: PropTypes.string,
};

CV3CondicaoPagamento.defaultProps = {
	error: '',
};

export default CSSModules(CV3CondicaoPagamento, styles, {
	allowMultiple: true,
});
