import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import NovaCotacaoHoc from '../NovaCotacaoHoc';
import RequestsWrapperFuture from '../../../components/RequestsWrapper/RequestsWrapperFuture';
import NovaCotacaoFornecedores from './NovaCotacaoFornecedores';

// Functions
import { _get, _put, _post } from '../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { handleUpdateState } from '../../../components/_functions/_handleUpdateState';
import * as constants from './constants';

class NovaCotacaoFornecedoresContainer extends Component {
	static definirTipoSelecao(sem_preferencia_vendedor) {
		let tipo_selecao_fornecedor = sem_preferencia_vendedor ? 1 : 2;
		if (constants.SELECAO_FORNECEDOR_CONAZ) tipo_selecao_fornecedor = 3;
		return tipo_selecao_fornecedor;
	}

	static definirOrigemCadastro() {
		return constants.ORIGEM_CADASTRO_FORNECEDOR_CONAZ ? 2 : 3;
	}

	static postVendedor(vendedor, cliente_id) {
		const params_usuario = {
			nome: vendedor.nome !== '' ? vendedor.nome : '',
			email: vendedor.email,
			telefones: vendedor.telefones,
			cadastrado_por: cliente_id,
			status: -10,
			origem_cadastro: this.definirOrigemCadastro(),
		};
		return _post('/conaz/v2/usuarios_fornecedor/', params_usuario);
	}

	constructor(props) {
		super(props);
		this.state = {
			/**
			 * Seta as url's para carregar através do componente RequestsWrapper
			 */
			urls_to_fetch: props.cotacao.codigo !== props.match.params.id
				? [`/conaz/v2/pedidos/${props.match.params.id}`]
				: [],
			fornecedor_pre_selecionado: {},
			form_novo_fornecedor: false,
			fornecedor_ja_existe: false,
			fornecedor_existente: {},
			vendedor_existente: {},
			no_fornecedor_tooltip: 0,
			salvando_selecao_fornecedor: false,
			errors: {
				salvando_fornecedor: false,
				fornecedor_com_rc: false,
				vendedor_com_rc: false,
				rc_ja_enviado: false,
			},
			max_5_alert: -1,
			alert_delete_fornecedor_respondido: false,
			removendo_fornecedor: false,
			pre_nome_fornecedor: '',
		};
		this.openSearch = this.openSearch.bind(this);
		this.validaEnvio = this.validaEnvio.bind(this);
		this.updateState = this.updateState.bind(this);
		this.removeFornecedor = this.removeFornecedor.bind(this);
		this.revelaFornecedor = this.revelaFornecedor.bind(this);
		this.cancelaFornecedorExistente = this.cancelaFornecedorExistente.bind(this);
		this.adicionaFornecedorExistente = this.adicionaFornecedorExistente.bind(this);
		this.removeMax5Alert = this.removeMax5Alert.bind(this);
		this.handleAlertFornecedor = this.handleAlertFornecedor.bind(this);
		this.exibePerfilCliente = this.exibePerfilCliente.bind(this);
		this.handleProspeccaoExtraFornecedores = this.handleProspeccaoExtraFornecedores.bind(this);
		this.handleResponses = this.handleResponses.bind(this);
		this.criarEditarRC = this.criarEditarRC.bind(this);
		this.cadastrarFornecedorVendedor = this.cadastrarFornecedorVendedor.bind(this);
		this.cadastrarVendedor = this.cadastrarVendedor.bind(this);
		this.obterClienteIdCadastro = this.obterClienteIdCadastro.bind(this);
		this.obterClienteIdReal = this.obterClienteIdReal.bind(this);
		this.resetaErros = this.resetaErros.bind(this);
	}

	componentDidMount() {
		window.scroll(0, 0);
		this.buscaMelhoresFornecedores();
		/**
		 * Set page title
		 */
		document.title = `#${this.props.match.params.id} - Cotação - Conaz`;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// this.buscaMelhoresFornecedores();
		/**
		 * Verifica se mudou o código da cotação
		 * se mudou envia as novas url's para o wrapper carregá-las
		 */
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.setState({
				urls_to_fetch: [`/conaz/v2/pedidos/${nextProps.match.params.id}`],
			});
			document.title = `#${nextProps.match.params.id} - Cotação - Conaz`;
		}
	}

	componentWillUnmount() {
		const { updateCotacaoRaw, updateUi, updateModals } = this.props;
		updateCotacaoRaw({
			request_state_populares: 0,
			populares_fornecedores_by_id: {},
			populares_fornecedores_all_ids: [],
		});

		updateUi(['search_fornecedores_open'], false);
		updateModals('fornecedor', false);
		updateModals('perfil_cliente', false);
	}

	obterClienteIdCadastro() {
		const { obra_selected, obras_by_id } = this.props.user;
		return constants.ORIGEM_CADASTRO_FORNECEDOR_CONAZ ? constants.ID_CLIENTE_CONAZ : obras_by_id[obra_selected].cliente.id;
	}

	obterClienteIdReal() {
		const { obra_selected, obras_by_id } = this.props.user;
		const { cotacao } = this.props;
		return process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno' ? cotacao.cliente_rc_revisao.id_cliente : obras_by_id[obra_selected].cliente.id;
	}

	buscaMelhoresFornecedores() {
		const is_construtor = process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno';
		if (is_construtor) {
			this.props.updateCotacao(['request_state_populares'], 2);
			this.props.updateCotacao(['fornecedores_loaded'], true);
		}
	}

	openSearch() {
		const { email } = this.props.user;
		const { fornecedores_all_ids, fornecedores_by_id, prospeccao_extra_fornecedores } = this.props.cotacao;
		if (constants.ALERTAR_PEDIDO_SEM_PROSPECCAO_EXTRA_FORNECEDORES && !prospeccao_extra_fornecedores) {
			window.alert('O construtor restringiu os fornecedores da cotação aos escolhidos por ele. Marque o checkbox no fim da página para adicionar mais fornecedores'); // eslint-disable-line
			return;
		}

		const is_construtor = process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno';

		if (this.props.cotacao.request_state_populares === 0 && !is_construtor) {
			this.props.updateCotacao(['request_state_populares'], 1);
			const cotacao_codigo = this.props.match.params.id;
			_get(`/conaz/v2/fornecedores/pedido/${cotacao_codigo}`).then(response => {
				const fornecedores = response.data.reduce((result, current) => ({
					...result, [current.id]: current,
				}), {});
				const fornecedores_to_sort = Object.keys(fornecedores).map(id => ({ id: fornecedores[id].id, nome: fornecedores[id].nome }));
				const all_ids = fornecedores_to_sort.sort((a, b) => {
					if (a.nome < b.nome) return -1;
					if (a.nome > b.nome) return 1;
					return 0;
				}).map(f => f.id).filter((el, i, a) => i === a.indexOf(el));
				this.props.updateCotacao(['populares_fornecedores_by_id'], fornecedores);
				this.props.updateCotacao(['populares_fornecedores_all_ids'], all_ids);
				this.props.updateCotacao(['request_state_populares'], 2);
				this.props.updateCotacao(['fornecedores_loaded'], true);
			}).catch(() => {
				this.props.updateCotacao(['request_state_populares'], 3);
			});
		}

		const count_fornecedores_construtor = fornecedores_all_ids.filter(f_id => fornecedores_by_id[f_id].tipo_selecao_fornecedor === 1 || fornecedores_by_id[f_id].tipo_selecao_fornecedor === 2).length;
		if (
			count_fornecedores_construtor > 9 &&
			email.indexOf('@grupolumis.com.br') === -1 &&
			email.indexOf('@construtorasc.com.br') === -1 &&
			email.indexOf('@ayoshii.com.br') === -1 &&
			email.indexOf('@yticon.com.br') === -1 &&
			email.indexOf('@jcdarquitetos.com') === -1 &&
			email.indexOf('julio@juliodedavid.com') === -1 &&
			email.indexOf('samubc1989@gmail.com') === -1 &&
			email.indexOf('@ambar.tech') === -1 &&
			constants.LIMITAR_NUMERO_FORNECEDORES
		) {
			this.setState({ max_5_alert: 0 });
		} else if (
			count_fornecedores_construtor > 14 &&
			constants.LIMITAR_NUMERO_FORNECEDORES
		) {
			this.setState({ max_5_alert: 0 });
		} else {
			this.props.updateUi(['search_fornecedores_open'], true);
			document.getElementById('fornecedores_search_input').focus();
		}
	}

	updateState(e, field, value) {
		const _value = handleUpdateState(e, value);
		this.setState({ [field]: _value });
	}

	removeMax5Alert() {
		this.setState({ max_5_alert: -1 });
	}

	handleAlertFornecedor() {
		const alert_delete_fornecedor_respondido = !this.state.alert_delete_fornecedor_respondido;
		this.setState({ alert_delete_fornecedor_respondido });
	}

	cancelaFornecedorExistente() {
		this.setState({ fornecedor_ja_existe: false });
	}

	resetaErros() {
		this.setState({
			errors: {
				salvando_fornecedor: false,
				fornecedor_com_rc: false,
				vendedor_com_rc: false,
			},
		});
	}

	adicionaFornecedorExistente(preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor) {
		const { fornecedor_existente, vendedor_existente } = this.state;
		this.criarEditarRC(fornecedor_existente, vendedor_existente, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor);
		this.setState({ fornecedor_ja_existe: false });
	}

	criarEditarRC(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor) {
		this.resetaErros();
		this.setState({ salvando_selecao_fornecedor: true });
		if (constants.VERIFICA_RESTRICOES) {
			const { id_cliente } = this.props.cotacao.cliente_rc_revisao;

			_get(`/conaz/v2/restricoes/?cliente.id=${id_cliente}&fields=id,fornecedor,usuario&restringido_por=1&restringido_por=2`)
				.then(response => {
					const restricoes = response.data.filter(restricao => restricao.usuario.id === vendedor.id || restricao.fornecedor.id === fornecedor.id);

					if (restricoes.length > 0) {
						window.alert('Este construtor já informou anteriormente que não deseja cotar mais com este fornecedor e/ou vendedor.'); // eslint-disable-line
						this.props.updateUi(['search_fornecedores_open'], false);
						this.props.updateModals('fornecedor', false);
						this.setState({ salvando_selecao_fornecedor: false });
					} else {
						this._criarEditarRC(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor);
					}
				});
		} else {
			this._criarEditarRC(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor);
		}
	}

	postFornecedor(fornecedor, cliente_id) {
		const novo_fornecedor = {
			nome: fornecedor.nome,
			site: fornecedor.site,
			cadastrado_por: cliente_id,
			status: -10,
		};

		return _post('/conaz/v2/fornecedores/', novo_fornecedor)
			.catch(error => {
				if (
					error.response &&
					error.response.data &&
					error.response.data.message &&
					error.response.data.message.indexOf('Fornecedor com nome') !== -1
				) {
					this.setState({
						fornecedor_ja_existe: true,
						fornecedor_existente: {},
						vendedor_existente: {},
					});
				}
				throw error;
			});
	}

	postFornecedorUsuario(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor) {
		return _post(`/conaz/v2/fornecedores/${fornecedor.id}/usuarios/${vendedor.id}`, { tipo_associacao: 4 })
			.then(() => this.criarEditarRC(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor));
	}

	cadastrarFornecedorVendedor(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor) {
		this.resetaErros();
		this.setState({ salvando_selecao_fornecedor: true });
		const cliente_id = this.obterClienteIdCadastro();

		this.checarSeExisteVendedorEmail(vendedor.email, cliente_id)
			.then(response_check => {
				if (!response_check) {
					return this.postFornecedor(fornecedor, cliente_id)
						.then(response_fornecedor => this.constructor.postVendedor(vendedor, cliente_id)
							.then(response_vendedor => this.postFornecedorUsuario(response_fornecedor.data, response_vendedor.data, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor)));
				}
				return null;
			}).catch(error => {
				this.setState({ errors: { ...this.state.errors, salvando_fornecedor: true }, salvando_selecao_fornecedor: false });
				handleRequestErrors(error, this.props.match.path, this.props.reportErrors);
			});
	}

	checarSeExisteVendedorEmail(email, cliente_id) {
		return _get(`/conaz/v2/usuarios_fornecedor/?email=${email}&cadastrado_por=${cliente_id}`)
			.then(response => {
				if (response.data.length > 0) {
					const fornecedor_existente = response.data[0].fornecedores[0] !== undefined
						? response.data[0].fornecedores[0].fornecedor : {};
					this.setState({
						fornecedor_ja_existe: true,
						fornecedor_existente,
						vendedor_existente: response.data[0],
					});
					return true;
				}
				return false;
			});
	}

	cadastrarVendedor(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor) {
		this.resetaErros();
		this.setState({ salvando_selecao_fornecedor: true });
		const cliente_id = this.obterClienteIdCadastro();

		this.checarSeExisteVendedorEmail(vendedor.email, cliente_id)
			.then(response_check => {
				if (!response_check) {
					return this.constructor.postVendedor(vendedor, cliente_id)
						.then(response_vendedor => this.postFornecedorUsuario(fornecedor, response_vendedor.data, preenchimento_fornecedor, sem_preferencia_vendedor, tipo_selecao_fornecedor));
				}
				return null;
			}).catch(error => {
				this.setState({ errors: { ...this.state.errors, salvando_fornecedor: true }, salvando_selecao_fornecedor: false });
				handleRequestErrors(error, this.props.match.path, this.props.reportErrors);
			});
	}

	_criarEditarRC(fornecedor, vendedor, preenchimento_fornecedor, sem_preferencia_vendedor, _tipo_selecao_fornecedor) {
		if (!fornecedor.rc_id) {
			const pedido_id = this.props.match.params.id;
			const has_usuario = vendedor.id !== undefined;
			const usuario_id = has_usuario ? vendedor.id : fornecedor.vendedores[0].id;

			const tipo_selecao_fornecedor = _tipo_selecao_fornecedor || this.constructor.definirTipoSelecao(sem_preferencia_vendedor);
			const novo_rc = {
				fornecedor_id: fornecedor.id,
				pedido_id,
				tipo_selecao_fornecedor,
				usuario_id,
				preenchimento_fornecedor,
			};
			_post('/conaz/v2/rcs/', novo_rc).then(response => {
				this.processResponseRC(response.data);
			}).catch(error => {
				this.trataErrosCriacaoEdicaoRC(fornecedor, vendedor, error);
			});
		} else {
			const { rc_id } = fornecedor;
			const has_usuario = vendedor.id !== undefined;
			const usuario_id = has_usuario ? vendedor.id : fornecedor.vendedores[0].id;
			const novo_rc = { usuario_id, preenchimento_fornecedor };

			if (_tipo_selecao_fornecedor) {
				novo_rc.tipo_selecao_fornecedor = _tipo_selecao_fornecedor;
			}

			_put(`/conaz/v2/rcs/${rc_id}`, novo_rc).then(response => {
				this.processResponseRC(response.data);
			}).catch(error => {
				this.trataErrosCriacaoEdicaoRC(fornecedor, vendedor, error);
			});
		}
	}

	trataErrosCriacaoEdicaoRC(fornecedor, vendedor, error) {
		const pedido_id = this.props.match.params.id;
		const message_conaz = ((error.response || {}).data || {}).message || '';
		if (message_conaz === 'Pedido esta sendo editado') {
			this.handleAlertFornecedor();
		} else if (message_conaz === 'Ja existe RC para o fornecedor informado') {
			if (constants.SUBSTITUIR_TIPO_SELECAO_RC_CRIADO_FORNECEDOR) {
				_get(`/conaz/v2/rcs/?pedido.id=${pedido_id}&fornecedor.id=${fornecedor.id}&tipo_selecao_fornecedor=4&status_lt=40&status_ne=-30`)
					.then(response => {
						if (response.data.length > 0) {
							const rc = response.data[0];
							const { usuario, uuid } = rc;
							if (usuario.id !== vendedor.id) {
								_post(`/conaz/v2/rcs/${uuid}/cancelar`)
									.then(() => this._criarEditarRC(fornecedor, vendedor, true, false));
							} else {
								_put(`/conaz/v2/rcs/${uuid}`, { tipo_selecao_fornecedor: 2 })
									.then(res => this.processResponseRC(res.data));
							}
						} else {
							this.setState({ errors: { ...this.state.errors, fornecedor_com_rc: true }, salvando_selecao_fornecedor: false });
						}
					});
			} else {
				this.setState({ errors: { ...this.state.errors, fornecedor_com_rc: true }, salvando_selecao_fornecedor: false });
			}
		} else if (message_conaz === 'Ja existe RC para o vendedor informado') {
			if (constants.SUBSTITUIR_TIPO_SELECAO_RC_CRIADO_FORNECEDOR) {
				_get(`/conaz/v2/rcs/?pedido.id=${pedido_id}&usuario.id=${vendedor.id}&tipo_selecao_fornecedor=4&status_lt=40&status_ne=-30`)
					.then(response => {
						if (response.data.length > 0) {
							const fornecedor_rc = response.data[0].fornecedor;
							const { uuid } = response.data[0];
							if (fornecedor_rc.id !== fornecedor.id) {
								_post(`/conaz/v2/rcs/${uuid}/cancelar`)
									.then(() => this._criarEditarRC(fornecedor, vendedor, true, false));
							} else {
								_put(`/conaz/v2/rcs/${uuid}`, { tipo_selecao_fornecedor: 2 })
									.then(res => this.processResponseRC(res.data));
							}
						} else {
							this.setState({ errors: { ...this.state.errors, fornecedor_com_rc: true }, salvando_selecao_fornecedor: false });
						}
					});
			} else {
				this.setState({ errors: { ...this.state.errors, fornecedor_com_rc: true }, salvando_selecao_fornecedor: false });
			}
		} else if (message_conaz === 'RC ja foi enviado') {
			this.setState({ errors: { ...this.state.errors, rc_ja_enviado: true }, salvando_selecao_fornecedor: false });
			handleRequestErrors(error);
		} else {
			this.setState({ errors: { ...this.state.errors, salvando_fornecedor: true }, salvando_selecao_fornecedor: false });
			handleRequestErrors(error);
		}
	}

	processResponseRC(rc) {
		const { fornecedores_by_id, fornecedores_all_ids } = this.props.cotacao;
		const vendedores = rc.fornecedor.usuarios.map(u => ({
			id: u.usuario.id,
			nome: u.usuario.nome,
			email: u.usuario.email,
			telefones: u.usuario.telefones,
			status: u.usuario.status,
			cadastrado_por: u.usuario.cadastrado_por,
			locais_de_entrega: u.locais_de_entrega,
			grupos: u.grupos,
			status_fornecedor_vendedor: u.status,
		}));

		const _new_fornecedor = {
			...rc.fornecedor,
			rc_id: rc.uuid,
			rc: {
				estah_resetado: rc.estah_resetado,
				status: rc.status,
			},
			vendedores,
			vendedor_selecionado: rc.usuario,
			preenchimento_fornecedor: rc.preenchimento_fornecedor,
			sem_preferencia_vendedor: rc.tipo_selecao_fornecedor === 1,
			tipo_selecao_fornecedor: rc.tipo_selecao_fornecedor,
		};
		const _fornecedores_by_id = {
			...fornecedores_by_id,
			[_new_fornecedor.id]: _new_fornecedor,
		};

		const _fornecedores_all_ids = [...fornecedores_all_ids, _new_fornecedor.id].filter((el, i, a) => i === a.indexOf(el));

		this.props.updateCotacao((['fornecedores_by_id']), _fornecedores_by_id);
		this.props.updateCotacao((['fornecedores_all_ids']), _fornecedores_all_ids);
		this.props.updateModals('fornecedor', false);
		this.props.updateUi(['search_fornecedores_open'], true);
		this.setState({ salvando_selecao_fornecedor: false });
		if (constants.ADICIONAR_RC_AO_CHECKITEM) {
			_post(`/conaz/v2/rcs/${rc.uuid}/adicionar_no_checklist `, {});
		}
	}

	revelaFornecedor(fornecedor) {
		this.setState({ form_novo_fornecedor: false, fornecedor_pre_selecionado: fornecedor });
		this.props.updateModals('fornecedor', true);
	}

	removeFornecedor(fornecedor_id) {
		this.setState({ removendo_fornecedor: true });
		const { cotacao, updateCotacao, updateModals } = this.props;

		/**
		 * Bloqueia se RC já foi respondido
		 */
		const rc_status = ((cotacao.fornecedores_by_id[fornecedor_id] || {}).rc || {}).status;
		const rc_resetado = ((cotacao.fornecedores_by_id[fornecedor_id] || {}).rc || {}).estah_resetado || false;
		if (rc_status >= 40 || rc_resetado) {
			this.setState({ removendo_fornecedor: false });
			this.handleAlertFornecedor();
			return;
		}

		/**
		 * Apaga RC
		 */
		const { rc_id } = cotacao.fornecedores_by_id[fornecedor_id];
		_post(`/conaz/v2/rcs/${rc_id}/cancelar`).then(() => {
			this.setState({ max_5_alert: -1 });
			const _fornecedores_all_ids = cotacao.fornecedores_all_ids.filter(f => f !== fornecedor_id);
			updateCotacao((['fornecedores_all_ids']), _fornecedores_all_ids);
			this.setState({ removendo_fornecedor: false });
		}).catch(error => {
			this.setState({ removendo_fornecedor: false });
			const message_conaz = ((error.response || {}).data || {}).message || '';
			if (message_conaz === 'Não é possível cancelar RC respondido') {
				this.handleAlertFornecedor();
				return;
			}
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	validaEnvio() {
		const { match: { params }, cotacao, history } = this.props;
		const cotacao_codigo = params.id;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const { no_fornecedor_tooltip } = this.state;
		if (
			cotacao.fornecedores_all_ids.length > 0
			|| no_fornecedor_tooltip === 2
			|| is_triagem
		) {
			history.push(`/cotacao/nova/${cotacao_codigo}/info`);
		} else {
			this.setState({ no_fornecedor_tooltip: 1 });
		}
	}

	validaEnvioTooltip = ({ target }) => {
		if (target.name === 'no_fornecedor') {
			this.setState({ no_fornecedor_tooltip: 2 }, () => this.validaEnvio());
		} else this.setState({ no_fornecedor_tooltip: 0 }, () => this.openSearch());
	}

	exibePerfilCliente(e) {
		e.preventDefault();
		this.props.updateModals('perfil_cliente', true);
	}

	handleProspeccaoExtraFornecedores(e) {
		e.preventDefault();
		const { codigo, obra, prospeccao_extra_fornecedores } = this.props.cotacao;
		const _prospeccao_extra_fornecedores = !prospeccao_extra_fornecedores;
		this.props.updateCotacao(['prospeccao_extra_fornecedores'], _prospeccao_extra_fornecedores);

		_put(`/conaz/v2/pedidos/${codigo}`, { obra_id: obra, prospeccao_extra_fornecedores: _prospeccao_extra_fornecedores }).then(() => {
			this.props.updateUi(['changes', 'cotacoes'], true);
			this.setState({ salva: true });
		}).catch(error => {
			this.props.updateModals('error', true);
			handleRequestErrors(error, this.props.match.path, this.props.reportErrors);
		});
	}

	handleResponses(responses, updateWrapperState) {
		const { handleResponsesHoc, history, updateCotacaoRaw } = this.props;
		handleResponsesHoc(
			responses,
			updateWrapperState,
			history,
			updateCotacaoRaw,
		);
		/**
		 * Zera as url's no RequestWrapper para prevenir novas requests
		 * Finaliza o loading e mostra o conteúdo
		 */
		this.setState({ urls_to_fetch: [] });
	}

	render() {
		return (
			<RequestsWrapperFuture
				urls_to_fetch={this.state.urls_to_fetch}
				handleResponses={this.handleResponses}
				topbar={false}
				{...this.props}>

				<NovaCotacaoFornecedores
					{...this.state}
					{...this.props}
					openSearch={this.openSearch}
					validaEnvio={this.validaEnvio}
					validaEnvioTooltip={this.validaEnvioTooltip}
					updateState={this.updateState}
					removeFornecedor={this.removeFornecedor}
					revelaFornecedor={this.revelaFornecedor}
					cancelaFornecedorExistente={this.cancelaFornecedorExistente}
					adicionaFornecedorExistente={this.adicionaFornecedorExistente}
					removeMax5Alert={this.removeMax5Alert}
					handleAlertFornecedor={this.handleAlertFornecedor}
					exibePerfilCliente={this.exibePerfilCliente}
					handleProspeccaoExtraFornecedores={this.handleProspeccaoExtraFornecedores}
					criarEditarRC={this.criarEditarRC}
					cadastrarFornecedorVendedor={this.cadastrarFornecedorVendedor}
					cadastrarVendedor={this.cadastrarVendedor}
					obterClienteIdCadastro={this.obterClienteIdCadastro}
					obterClienteIdReal={this.obterClienteIdReal}
					resetaErros={this.resetaErros} />

			</RequestsWrapperFuture>
		);
	}
}

NovaCotacaoFornecedoresContainer.propTypes = {
	// =========== hoc
	handleResponsesHoc: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateCotacaoRaw: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
const _NovaCotacaoFornecedoresContainer = withStore(connect(mapStateToProps, mapDispatchToProps)(NovaCotacaoFornecedoresContainer));
export default NovaCotacaoHoc(_NovaCotacaoFornecedoresContainer);
