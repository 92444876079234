import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
import StarsAvaliacao from './StarsAvaliacao';

// Components
import DescricaoAvaliacao from './DescricaoAvaliacao';
import ComentarioAvaliacao from './ComentarioAvaliacao';

const TabAvaliacoes = ({
	vendedores,
	nota_detalhada,
	avaliacoes,
}) => (
	<>
		<DescricaoAvaliacao nota_detalhada={nota_detalhada} />
		<div styleName="linha-horizontal" />
		<div styleName="avaliacao-title">Média geral dos vendedores</div>
		{vendedores
			.filter(v => v.nome !== null && v.nome !== '')
			.map(vendedor => (
				vendedor.media_avaliacao ? <div styleName="avaliacao-vendedor-avaliado" key={vendedor.id}>
					<div>{vendedor.nome || vendedor.usuario.nome} &ensp;</div>
					<StarsAvaliacao media={vendedor.media_avaliacao} starSize={16} />
					<div>&ensp;({vendedor.media_avaliacao})</div>
				</div> : <div styleName="avaliacao-vendedor" key={vendedor.id}>
					{vendedor.nome || vendedor.usuario.nome}
					<i className="fa fa-star" aria-hidden="true" />
					<span>Sem avaliações suficientes para compor nota</span>
				</div>
			))
		}
		<div>
			<div styleName="linha-horizontal" />
			<div styleName="avaliacao-title">Comentários</div>
			{!avaliacoes?.comentarios?.length ?
				<div styleName="comentarios-indisponiveis">Sem comentários disponíveis</div> : <></>}
			{avaliacoes?.comentarios?.map((avaliacao) => (
				<ComentarioAvaliacao avaliacao={avaliacao} />
			))}
		</div>
	</>
);

TabAvaliacoes.propTypes = {
	vendedores: PropTypes.array.isRequired,
	nota_detalhada: PropTypes.object,
	avaliacoes: PropTypes.object,
};

TabAvaliacoes.defaultProps = {
	nota_detalhada: null,
	avaliacoes: {},
};

export default CSSModules(TabAvaliacoes, styles, { allowMultiple: true });
