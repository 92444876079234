import INITIAL_STATE from './store';
import * as Types from './types';


export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.USERS_BY_CLIENT_REQUEST:
		return {
			...state,
			isFetching: true,
		};
	case Types.USERS_BY_CLIENT_SUCCESS:
		return {
			...state,
			isFetching: false,
			users: action.payload.users,
			error: null,
		};
	case Types.USERS_BY_CLIENT_ERROR:
		return {
			...state,
			isFetching: false,
			error: action.payload.erro_motivo,
		};

	case Types.USERS_OPEN_DRAWER:
		return {
			...state,
			showDrawer: true,
			drawerMode: action.payload.drawerMode,
			userToDrawer: action.payload.userToDrawer,
		};

	case Types.USERS_CLOSE_DRAWER:
		return {
			...state,
			showDrawer: false,
			userToDrawer: null,
		};

	case Types.USER_ACTIVATE_REQUEST:
	case Types.USERS_DISABLE_REQUEST:
	case Types.USERS_CREATE_REQUEST:
	case Types.USERS_EDIT_REQUEST:
		return {
			...state,
			action: action.payload.type, // edit, new, block
			actionFetching: true,
		};
	case Types.USERS_ALTERA_STATUS_SUCCESS:
		return {
			...state,
			actionFetching: false,
			users: action.payload.users,
			error: null,
		};
	case Types.USERS_CREATE_SUCCESS:
		return {
			...state,
			users: [...state.users, action.payload.data],
			actionFetching: false,
			error: false,
			showDrawer: false,
			userToDrawer: null,
		};
	case Types.USERS_EDIT_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			users: state.users.map((u) => {
				if (u.id === action.payload.data.id) return action.payload.data;
				return u;
			}),
			showDrawer: false,
			userToDrawer: null,
		};
	case Types.USERS_ACTIVATE_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			users: state.users.map((u) => {
				if (u.id === action.payload.data.id) return action.payload.data;
				return u;
			}),
		};
	case Types.USERS_DELETE_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			users: state.users.map((u) => {
				if (u.id === action.payload.data.id) return action.payload.data;
				return u;
			}),
			// users: state.users.filter((u) => u.id !== action.payload.data.id),
		};
	case Types.USERS_ACTION_ERROR:
		return {
			...state,
			actionFetching: false,
			error: action.payload.erro_motivo,
		};
	default:
		return state;
	}
}
