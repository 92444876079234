const defaults = {
	metros: ['m', 'metros', 'metro', 'mt', 'mts', 'metro linear', 'metros lineares', 'ml'],
	m2: ['m2', 'm²', 'metro quadrado', 'metros quadrados', 'mts quadrados'],
	m3: ['m3', 'm³', 'metro cubico', 'metro cúbico', 'metros cúbicos', 'metros cubicos', 'mts cubicos'],
	kg: ['kg', 'quilos', 'quilo', 'kilo', 'kilos', 'kilograma', 'quilograma', 'kilogramas', 'quilogramas'],
	ton: ['ton', 'toneladas', 't', 'to'],
	litros: ['litros', 'lt', 'litro', 'lts'],
	cm: ['cm', 'cent', 'centimetro', 'centimetros', 'centímetro', 'centímetros'],
	gramas: ['g', 'grama', 'gramas'],
	mg: ['mg', 'miligramas', 'miligrama'],
	un: ['un', 'unidade', 'unitário', 'unitario', 'unidades', 'und', 'uni', 'ud', 'peças', 'pç', 'pçs'],
	vb: ['vb', 'verba'],
};
const defaults_keys = Object.keys(defaults);

export const compareUnidades = (un_1 = '', un_2 = '') => {
	const un_conaz = (un_1 || '')
		.toString()
		.toLowerCase();
	const un_integrado = (un_2 || '')
		.toString()
		.toLowerCase();

	// vazios
	if (un_conaz === '' || un_integrado === '') {
		return false;
	}

	// exatamente iguais
	if (un_conaz === un_integrado) {
		return true;
	}

	// find unidade conaz
	const array_key_search = defaults_keys
		.filter(key => {
			const arr = defaults[key];
			const exists = arr
				.filter(x => x === un_conaz)
				.length > 0;
			return exists;
		});

	const is_equal = array_key_search
		.filter(x => x === un_integrado)
		.length > 0;

	return is_equal;
};

// const defaults = {
// 	metros: [
// 		{ value: 'm', qtd: 1 },
// 		{ value: 'metros', qtd: 1 },
// 		{ value: 'metro', qtd: 1 },
// 		{ value: 'barras de 6 m', qtd: 6 },
// 	],
// 	m3: [
// 		{ value: 'm3', qtd: 1 },
// 		{ value: 'm³', qtd: 1 },
// 		{ value: 'metros cúbicos', qtd: 1 },
// 		{ value: 'metros cubicos', qtd: 1 },
// 	],
// };
// const defaults_keys = Object.keys(defaults);

// export const compareUnidades = (un_1 = '', un_2 = '') => {
// 	const un_conaz = (un_1 || '')
// 		.toString()
// 		.toLowerCase();
// 	const un_integrado = (un_2 || '')
// 		.toString()
// 		.toLowerCase();

// 	// find unidade conaz
// 	let multiplier = 1;
// 	const array_key_search = defaults_keys
// 		.filter(key => {
// 			const arr = defaults[key];
// 			const conaz_values = arr
// 				.filter(x => x.value === un_conaz);
// 			const exists = conaz_values.length > 0;
// 			if (exists) {
// 				multiplier = (conaz_values[0] || {}).qtd || 1;
// 			}
// 			return exists;
// 		})[0] || '';

// 	// false if not found
// 	if (array_key_search === '') {
// 		return false;
// 	}

// 	// check equal
// 	const is_equal = defaults[array_key_search]
// 		.filter(x => x.value === un_integrado)
// 		.length > 0;

// 	return [is_equal, multiplier];
// };

// const a = compareUnidades('barras de 6 m', 'm');
// 		console.log(a);
// // ====================================================================

// const b = compareUnidades('m³', 'm3');
// 		console.log(b);
// // ====================================================================
