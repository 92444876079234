const cotacoes = {
	fetching: 0,
	RS_em_andamento: 1,
	RS_finalizados: 0,
	RS_cancelados: 0,
	RS_em_rascunho: 0,
	obra: null,
	current_tab: 'em_andamento',
	em_andamento: [],
	finalizados: [],
	cancelados: [],
	em_rascunho: [],
};

export default cotacoes;
