import React from 'react';
import PropTypes from 'prop-types';

import { StyledTable } from './styled';
import columns from './columns';
import tableLocale from './locale';

function TabelaUsuariosAdicionados({ value }) {
	const maxPerPage = 10;

	return (
		<>
			<div className="block-title">
				Usuários adicionados
			</div>
			<StyledTable
				columns={columns()}
				rowKey="id"
				dataSource={value}
				pagination={{ pageSize: maxPerPage, hideOnSinglePage: true }}
				locale={tableLocale}
				size="small"
			/>
		</>
	);
}

TabelaUsuariosAdicionados.propTypes = {
	value: PropTypes.arrayOf(PropTypes.shape()),
};

TabelaUsuariosAdicionados.defaultProps = {
	value: [],
};

export default TabelaUsuariosAdicionados;
