import update from 'immutability-helper';
import user from './store';
import {
	UPDATE_USER,
	UPDATE_USER_RAW,
	CHANGE_OBRA,
	SET_AUTH,
	UNSET_AUTH,
	USER_REQUESTED,
	USER_RECEIVED,
	USER_REQUEST_FAILED,
} from './types';

// Functions
import { buildObras } from './utils';
import { setUserLocalstorageData } from '../../components/_functions/_getUserLocalstorageData';
import { attachWootric } from '../../components/_functions/_attachWootric';
import { isGeneralError, handleGeneralErrors } from '../utility';

const reducer = (state = user, action) => {
	switch (action.type) {
	case UPDATE_USER: {
		return update(state, {
			[action.field]: { $set: action.value },
		});
	}

	case UPDATE_USER_RAW: {
		const all_fields = Object.keys(action.fields_n_values)
			.reduce((result, current) => ({
				...result,
				[current]: { $set: action.fields_n_values[current] },
			}), {});
		return update(state, all_fields);
	}

	case CHANGE_OBRA: {
		return update(state, {
			obra_selected: { $set: action.obra_id },
			tem_express: { $set: action.tem_express },
		});
	}

	case UNSET_AUTH: {
		localStorage.setItem('user', JSON.stringify({ email: '' }));
		return update(state, {
			logged: { $set: false },
			token: { $set: '' },
			email: { $set: '' },
			id: { $set: 0 },
			nome: { $set: '' },
			tem_express: { $set: false },
			obras_by_id: { $set: {} },
			obras_all_ids: { $set: [] },
			obra_selected: { $set: 0 },
		});
	}

	case SET_AUTH: {
		return update(state, {
			logged: { $set: true },
			token: { $set: action.token },
			email: { $set: action.user.email },
			id: { $set: Number(action.user.id) },
			nome: { $set: action.user.apelido },
			tem_express: { $set: action.tem_express },
		});
	}

	case USER_REQUESTED: {
		return {
			...state,
			fetching: 10,
		};
	}

	case USER_RECEIVED: {
		const user_data = action.payload.data || {};
		const obras_filtered = user_data.obras
			.filter(o => !o.obra.desativada)
			.sort((a, b) => {
				if (a.obra.nome < b.obra.nome) return -1;
				if (a.obra.nome > b.obra.nome) return 1;
				return 0;
			});
		const obras_byid_and_allids = buildObras(obras_filtered);
		// Cliente
		const cliente = ((obras_filtered[0] || {}).obra || {}).cliente || {};
		const cliente_id = cliente.id || 0;
		const sienge_liberado = cliente.ativacao_swi || false;
		const aprovacao_sienge = cliente.aprovacao_swi || false;
		setUserLocalstorageData('obra', obras_byid_and_allids[2]);

		// HiChat
		const is_prod = window.location.href.indexOf('portal.conazweb.com.br') !== -1;
		if (is_prod) {
			attachWootric(user_data);
		}

		return {
			...state,
			fetching: 20,
			obras_by_id: { ...obras_byid_and_allids[0] },
			obras_all_ids: [...obras_byid_and_allids[1]],
			obra_selected: obras_byid_and_allids[2],
			cliente_id,
			usuario_no_swi: user_data.usuario_no_swi || '',
			clientes_administrados: user_data.clientes_administrados || [],
			sienge_liberado,
			aprovacao_sienge,
			confirmado_em: user_data.confirmado_em || '',
		};
	}

	case USER_REQUEST_FAILED: {
		const error = action.error || {};
		const general_error = isGeneralError(error);
		if (general_error) {
			handleGeneralErrors(error);
			return { ...state };
		}

		return {
			...state,
			fetching: 30,
		};
	}

	default:
		return state;
	}
};

export default reducer;
