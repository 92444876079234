import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rate } from 'antd';
import { Div } from './Rating.style';

const Rating = props => {
	const [rate_view, setRateView] = useState(0);
	const { rate, handleRate, lock } = props;
	const desc = ['Péssimo', 'Ruim', 'Regular', 'Boa', 'Excelente'];

	function onHoverChange(value) {
		const view = value || 0;
		if (view !== rate_view) {
			setRateView(view);
		}
	}

	return !lock ? (
		<Div>
			<Rate
				onChange={handleRate}
				onHoverChange={onHoverChange}
				allowClear={false}
				value={rate}
			/>
			<div>
				{rate_view !== 0 && (
					<div className="hover-text">{desc[rate_view - 1]}</div>
				)}
				<span>{desc[rate - 1]}</span>
			</div>
		</Div>
	) : (
		<Rate disabled defaultValue={rate} />
	);
};

Rating.propTypes = {
	handleRate: PropTypes.func,
	rate: PropTypes.number,
	lock: PropTypes.bool,
};

Rating.defaultProps = {
	lock: false,
	rate: null,
	handleRate: () => {},
};

export default Rating;
