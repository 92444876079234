import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import axios from 'axios';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { debounce } from '../../_functions/_debounce';
import { getLocalstorageData } from '../../_functions/_getLocalstorageData';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';

const compra_search_fields = `
	id,
	status,
	condicao_de_pagamento,
	sub_total,
	valor_transporte,
	custos_adicionais,
	valor_total,
	fornecedor.nome,
	titulo,
	pedido_id,
	em_analise,
	em_refinamento,
	cancelada_em,
	em_aprovacao,
	em_edicao,
	itens_de_compra.quantidade,
	itens_de_compra.preco_unitario,
	itens_de_compra.desconto,
	itens_de_compra.excluido
`;
const compra_search_status = '&status=-50&status=-40&status=-20&status=-10&status=0&status=5&status=10&status=20&status=30&status=40&status=50&status=60';

class SearchBar extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		if (search.length > 0) {
			const typed_encode = encodeURIComponent(search).replace(/%0A/g, '');
			const id_encode = typed_encode.charAt(0) === '0' ? typed_encode.substring(1, typed_encode.length) : typed_encode;
			const all_searchs = [
				this.search(`/conaz/v2/pedidos/?fields=id,codigo,compras,obra.desativada,titulo,status,deletado,cancelado,aprovacao_pedida_por.id,aprovado_por.id,em_aprovacao,em_refinamento,itens_de_pedido.pedido_no_swi&titulo=*${typed_encode}*`),
				this.search(`/conaz/v2/pedidos/?fields=id,codigo,compras,obra.desativada,titulo,status,deletado,cancelado,aprovacao_pedida_por.id,aprovado_por.id,em_aprovacao,em_refinamento,itens_de_pedido.pedido_no_swi&id=*${id_encode}*`),
				this.search(`/conaz/v2/pedidos/?fields=id,codigo,compras,obra.desativada,titulo,status,deletado,cancelado,aprovacao_pedida_por.id,aprovado_por.id,em_aprovacao,em_refinamento,itens_de_pedido.pedido_no_swi&itens_de_pedido.excluido=0&itens_de_pedido.pedido_no_swi=*${id_encode}*`),
				this.search(`/conaz/v2/compras/?fields=${compra_search_fields}&id=*${id_encode}*${compra_search_status}`),
				this.search(`/conaz/v2/compras/?fields=${compra_search_fields}&pedido_id=*${id_encode}*${compra_search_status}`),
				this.search(`/conaz/v2/compras/?fields=${compra_search_fields}&fornecedor.nome=*${id_encode}*${compra_search_status}`),
			];
			Promise.all(all_searchs).then(responses => {
				this.props.updateUi(['search_state'], 2);
				const pedidos_by_id = [...responses[0].data, ...responses[1].data, ...responses[2].data]
					.filter(c => !c.deletado)
					.reduce((r, c) => ({ ...r, [c.id]: c }), {});
				const pedidos = Object.keys(pedidos_by_id).map(p => pedidos_by_id[p]);
				const compras = [...responses[3].data, ...responses[4].data, ...responses[5].data];
				this.props.updateUi(['search_results', 'pedidos'], pedidos);
				this.props.updateUi(['search_results', 'compras'], compras);
			}).catch(error => {
				if (error.message !== 'canceled_request') {
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	constructor(props) {
		super(props);
		this.state = {
			search: props.search,
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.handleDebounce = this.handleDebounce.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.search !== this.state.search) this.setState({ search: nextProps.search });
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	async search(url) {
		try {
			const token = getLocalstorageData('user', 'token');
			const base_url = getLocalstorageData('environment', 'base_url');
			const { CancelToken } = axios;
			axios.defaults.headers.common.token = token;
			const full_url = `${base_url}${url}`;
			return await axios({
				method: 'get',
				url: full_url,
				cancelToken: new CancelToken(c => {
					this.cancel = c;
					return c;
				}),
			});
		} catch (err) {
			throw err;
		}
	}

	handleSearch(e) {
		const search = e.target.value.toString();
		if (this.cancel !== undefined) this.cancel('canceled_request');
		if (search.trim().length > 0) {
			this.props.updateUi(['search'], search);
			this.props.updateUi(['search_state'], 1);
			this.setState({ search }, this.handleDebounce);
		} else {
			this.setState({ search });
			this.props.updateUi(['search'], search);
			this.props.updateUi(['search_state'], 0);
		}
	}

	render() {
		const { search } = this.state;
		return (
			<div styleName="search-bar">
				<i className="fa fa-search" aria-hidden="true" />
				<input
					type="text"
					placeholder="Encontre cotações e compras por código, título e nome de fornecedor"
					value={search}
					onChange={this.handleSearch} />
			</div>
		);
	}
}

SearchBar.propTypes = {
	updateUi: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
};

export default CSSModules(SearchBar, styles, { allowMultiple: true });
