/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import { formatNumbers } from '../../../../components/_functions/_formatNumbers';
import { titleCase } from '../../../../components/_functions/_titleCase';

const FornecedorReview = ({ fornecedor, cliente_id }) => {
	const { endereco, documento, site } = fornecedor;

	const _rua =
		endereco !== null && endereco.logradouro !== null
			? endereco.logradouro
			: '';
	const _numero =
		endereco !== null &&
		endereco.numero !== null &&
		endereco.numero !== undefined
			? endereco.numero
			: '';
	const _bairro =
		endereco !== null &&
		endereco.bairro !== null &&
		endereco.bairro !== undefined
			? endereco.bairro
			: '';
	const _cidade =
		endereco !== null &&
		endereco.cidade !== null &&
		endereco.cidade !== undefined
			? titleCase(endereco.cidade)
			: '';
	const _estado =
		endereco !== null &&
		endereco.estado !== null &&
		endereco.estado !== undefined
			? endereco.estado
			: '';

	const _documento =
		documento !== '' && documento !== null ? (
			formatNumbers(documento, 'cnpj')
		) : (
			<em>Sem registro</em>
		);
	const _site = site !== '' && site !== null ? site : '';
	const _site_link =
		_site.indexOf('https://') === -1 && _site.indexOf('http://') === -1
			? `http://${_site}`
			: _site;
	const h3_class =
		cliente_id === fornecedor.cadastrado_por ? { marginBottom: '5px' } : {};

	return (
		<>
			<h3 style={h3_class}>{fornecedor.nome}</h3>
			{cliente_id === fornecedor.cadastrado_por && (
				<span className="label label-primary">Cadastrado por você</span>
			)}
			{fornecedor.razao_social && (
				<div>
					<span>Razão social:</span> {fornecedor.razao_social}
				</div>
			)}

			<div>
				<span>CNPJ:</span> {_documento}
			</div>
			{_cidade !== '' ? (
				<div>
					<span>Endereço:</span>{' '}
					{_rua !== '' && <span>{_rua}, </span>}
					{_numero !== '' && <span>nº {_numero}, </span>}
					{_bairro !== '' && <span>{_bairro} - </span>}
					{_cidade !== '' && <span>{_cidade}</span>}
					{_estado !== '' && <span>/{_estado}</span>}
				</div>
			) : (
				<div>
					<span>Endereço:</span> <em>Sem registro</em>
				</div>
			)}
			{_site !== '' && (
				<div>
					<span>Site:</span>{' '}
					<a
						href={_site_link}
						target="_blank"
						rel="noopener noreferrer">
						{_site}
					</a>
				</div>
			)}
		</>
	);
};

FornecedorReview.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	cliente_id: PropTypes.number,
};

FornecedorReview.defaultProps = {
	cliente_id: -1,
};

export default CSSModules(FornecedorReview, styles, { allowMultiple: true });
