import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IIStatusView from './IIStatusView';

const InfoCompacted = ({ empty_fornecedor, fornecedor_integrado }) => (
	<>
		<div styleName="title">Fornecedor Sienge</div>
		{!empty_fornecedor && (
			<div styleName="name compact">
				<div>{fornecedor_integrado.nome}</div>
			</div>
		)}
		{empty_fornecedor && (<IIStatusView
			fornecedor_integrado={fornecedor_integrado}
			empty_fornecedor={empty_fornecedor} />)}
	</>
);

InfoCompacted.propTypes = {
	empty_fornecedor: PropTypes.bool.isRequired,
	fornecedor_integrado: PropTypes.object,
};

InfoCompacted.defaultProps = {
	fornecedor_integrado: {},
};

export default CSSModules(InfoCompacted, styles, { allowMultiple: true });
