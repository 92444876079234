import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IntegracaoSearchResult from './IntegracaoSearchResult';
import Loading from '../../../../_loadings/Loading/Loading';

// Functions
import { _get } from '../../../../../_functions/_requests';
import { replaceSearchTextInte } from '../../../_subfunctions/replaceSearchTextInte';
import { handleRequestErrors } from '../../../../../_functions/_handleRequestErrors';
import { refatoraListaSiengeItens } from '../../../../../../pages/Integracao/SiengeItens/subfunctions/_refatoraListaSiengeItens';

class IntegracaoSugestoes extends Component {
	state = {
		loading: 0,
		sugestoes: [],
	};

	componentDidMount() {
		this.getSugestoes();
	}

	getSugestoes() {
		this.setState({ loading: 1 });
		const { item_on_edition, client_id } = this.props;
		const nome = (((
			item_on_edition || {})
			.item_preenchido || {})
			.item || {})
			.nome || '';
		const url = `/conaz/v2/integracoes/itens_integrados/search
			?q=${nome || item_on_edition.pre_nome}
			&id_no_swi=${nome || item_on_edition.pre_nome}
			&swi=1
			&cliente_id=${client_id}`;

		_get(url).then(response => {
			const integrados = refatoraListaSiengeItens(response.data);
			const sugestoes = replaceSearchTextInte(nome, integrados);
			this.setState({ sugestoes, loading: 2 });
		}).catch(error => {
			handleRequestErrors(error);
			this.setState({ sugestoes: [], loading: 0 });
		});
	}

	render() {
		const {
			loading,
			sugestoes,
		} = this.state;
		const {
			item_on_edition,
			updateFullItemById,
			autoSave,
		} = this.props;

		if (loading === 0 || loading === 1) {
			return (
				<>
					<h4 styleName="sugestoes-title">Sugestões</h4>
					<div styleName="no-result-wrapper"><Loading /></div>
				</>
			);
		}

		return (
			<>
				<h4 styleName="sugestoes-title">Sugestões</h4>
				{sugestoes.map(item => {
					const detalhe_id = (item.detalhe || {}).id || '';
					const key = `${item.id}${detalhe_id}`;

					return (
						<IntegracaoSearchResult
							key={key}
							item_on_edition={item_on_edition}
							item={item}
							updateFullItemById={updateFullItemById}
							autoSave={autoSave}
						/>
					);
				})}
			</>
		);
	}
}

IntegracaoSugestoes.propTypes = {
	item_on_edition: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	client_id: PropTypes.number.isRequired,
};

export default CSSModules(IntegracaoSugestoes, styles, { allowMultiple: true });
