/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import 'react-step-progress-bar/styles.css';
import { ProgressBar, Step } from 'react-step-progress-bar';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';
import IconeStatus from '../../../../icons/bolinha_status_exportacao.svg';

import { setClasses } from '../../../../components/_functions/_setClasses';

const StatusProgressBar = ({
	passo_atual_tipo,
	passo_atual_status,
}) => (
	<ProgressBar
		percent={
			passo_atual_tipo === 'GET_SIENGE_CREDENTIALS' || passo_atual_tipo === 'GET_CONAZ_PURCHASE_DETAILS' || passo_atual_tipo === 'GET_CONAZ_ALL_PURCHASE_ITEMS_DETAILS' || passo_atual_tipo === 'GET_CONAZ_PURCHASE_ITEM_DETAILS'

				? 0 : passo_atual_tipo === 'GET_SIENGE_ALL_RESOURCE_ITEMS_DETAILS' || passo_atual_tipo === 'GET_SIENGE_RESOURCE_ITEM_DETAILS' || passo_atual_tipo === 'GET_SIENGE_PURCHASE_REQUEST_INFO' || passo_atual_tipo === 'GET_SIENGE_PURCHASE_REQUEST_ITEMS'
					|| passo_atual_tipo === 'GET_SIENGE_ALL_ITEMS_DELIVERY_REQUIREMENTS' || passo_atual_tipo === 'GET_SIENGE_DELIVERY_REQUIREMENTS' || passo_atual_tipo === 'VALIDATE_ALL_PURCHASE_ITEMS_WITH_REQUEST' || passo_atual_tipo === 'VALIDATE_PURCHASE_ITEM_WITH_REQUEST'

					? 25	 : passo_atual_tipo === 'CREATE_SIENGE_QUOTATION' || passo_atual_tipo === 'INSERT_ALL_ITEMS_IN_SIENGE_QUOTATION' || passo_atual_tipo === 'INSERT_ITEM_IN_SIENGE_QUOTATION' || passo_atual_tipo === 'INSERT_SUPPLIER_IN_ALL_SIENGE_QUOTATION_ITEMS' || passo_atual_tipo === 'INSERT_SUPPLIER_IN_SIENGE_QUOTATION_ITEM'
						? 50 : passo_atual_tipo === 'CREATE_SIENGE_NEGOTIATION' || passo_atual_tipo === 'ADD_DATA_TO_SIENGE_NEGOTIATION' || passo_atual_tipo === 'ADD_VALUES_TO_ALL_SIENGE_ITEM_NEGOTIATIONS' || passo_atual_tipo === 'ADD_VALUES_TO_SIENGE_ITEM_NEGOTIATION'
							? 75 : passo_atual_tipo === 'POST_SIENGE_QUOTATION_ID_TO_CONAZ' ? 100 : 0}
		filledBackground="linear-gradient(to right, #FFA800, #f0bb31)"
		hasStepZero
		index={0}
	>
		<Step transition="scale">
			{({ accomplished }) => (
				<img
					style={{ filter: `grayscale(${accomplished === 0})` }}
					width="18"
					height="16"
					src={IconeStatus}
				/>
			)}
		</Step>
		<Step transition="scale">
			{({ accomplished }) => (
				<img
					style={{ filter: `grayscale(${accomplished === 25})` }}
					width="18"
					height="16"
					src={IconeStatus}
				/>
			)}
		</Step>
		<Step transition="scale">
			{({ accomplished }) => (
				<img
					style={{ filter: `grayscale(${accomplished === 50})` }}
					width="18"
					height="16"
					src={IconeStatus}
				/>
			)}
		</Step>
		<Step transition="scale">
			{({ accomplished }) => (
				<img
					style={{ filter: `grayscale(${accomplished === 75})` }}
					width="18"
					height="16"
					src={IconeStatus}
				/>
			)}
		</Step>
		<Step transition="scale">
			{({ accomplished }) => (
				<img
					style={{ filter: `grayscale(${accomplished === 100})` }}
					width="18"
					height="16"
					src={IconeStatus}
				/>
			)}
		</Step>
	</ProgressBar>
);

StatusProgressBar.propTypes = {
	passo_atual_status: PropTypes.string,
	passo_atual_tipo: PropTypes.string,
};

StatusProgressBar.defaultProps = {
	passo_atual_tipo: 'GET_SIENGE_CREDENTIALS',
	passo_atual_status: 'RUNNING',
};

export default CSSModules(StatusProgressBar, styles, { allowMultiple: true });
