import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { defineStatusItemSienge } from '../../_functions/_defineStatusItemSienge';

class PlanilhaItensRow extends Component {
	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	caracteristicas() {
		const { item: _item } = this.props;
		const {
			caracteristicas,
			caracteristicas_preenchidas_all_ids,
			caracteristicas_preenchidas,
			item,
		} = _item.item_associado_caracteristicas.item_preenchido;

		const item_integrado = _item.item_integrado || {};
		const unidade_preenchida = item_integrado.unidade || '-';

		return (
			<div styleName="caracteristicas">
				<label>{item.nome}</label>
				<p>
					Unidade: <span>{unidade_preenchida}</span>
				</p>
				{caracteristicas_preenchidas_all_ids &&
					caracteristicas_preenchidas_all_ids.map(
						(caracteristica, i) => {
							const key = `${caracteristica}${i}`;
							const crt = caracteristicas_preenchidas[caracteristica].caracteristica;
							const c_item_associado = caracteristicas.find(c => c.id === Number(caracteristica)) || {};
							const c_item_associado_outra_opcao = c_item_associado.outra_opcao || '';

							const has_opcao_with_id = (
								c_item_associado.id !== undefined
								&& c_item_associado_outra_opcao === ''
								&& (c_item_associado.opcoes_ids || []).length > 0
							);

							const opcoes_with_id = has_opcao_with_id
								? c_item_associado.opcoes_ids
									.map(o_id => {
										const opcao = crt.opcoes.filter(o => o.id === o_id)[0] || {};
										return opcao;
									})
								: [];

							const has_outra_opcao = (
								c_item_associado.id !== undefined
								&& c_item_associado_outra_opcao !== ''
								&& (c_item_associado.opcoes_ids || []).length === 0
							);

							// Não obrigatória e sem preenchimento
							if (!has_opcao_with_id && !has_outra_opcao && crt.visao_construtor !== 2) {
								return null;
							}

							let option = '-';
							if (has_opcao_with_id && opcoes_with_id.length > 1) {
								option = opcoes_with_id
									.map(o => `${o.valor || ''} ${o.unidade || ''}`)
									.join(', ');
							}
							if (has_opcao_with_id && opcoes_with_id.length === 1) {
								option = `${(opcoes_with_id[0] || {}).valor} ${(opcoes_with_id[0] || {}).unidade}`;
							}
							if (has_outra_opcao) {
								option = `${c_item_associado.outra_opcao} ${c_item_associado.unidade}`;
							}

							return (
								<p key={key}>
									{crt.nome}:
									<span>{option}</span>
								</p>
							);
						},
					)}
			</div>
		);
	}

	render() {
		const { item, handleSelectedItem } = this.props;
		const { id_no_swi, detalhe, nome } = item;
		const caracteristicas = item.item_associado_caracteristicas ? (
			this.caracteristicas()
		) : (
			<span>Não configurado</span>
		);
		const icons_status = {
			associado: (<i className="fa fa-check" style={{ color: '#53c33f' }} aria-hidden="true" />),
			no_associado: null,
			aguardando_confirmacao: (<i className="fa fa-check" style={{ color: '#666c6d' }} aria-hidden="true" />),
		};
		const status = defineStatusItemSienge(item);
		const status_class = `${status}`;

		return (
			<button
				type="button"
				onClick={() => handleSelectedItem(item)}
				styleName="iten-button">
				<div styleName="itens-wrapper">
					{icons_status[status]}
					<div styleName="titulo">
						<div styleName="justify-flex-start">
							<span styleName={status_class}>{nome}</span>
						</div>
						{detalhe && (
							<>
								<div styleName="justify-flex-start">
									<p>
										<span>
											Detalhe: &nbsp;
											<span styleName={status_class}>
												{detalhe.valor}
											</span>
										</span>
									</p>
								</div>
							</>
						)}
						<div styleName="justify-flex-start">
							<p>
								<span>Código ERP: {id_no_swi}</span>
							</p>
						</div>
					</div>
					<div styleName="iten-associado">{caracteristicas}</div>
				</div>
			</button>
		);
	}
}

PlanilhaItensRow.propTypes = {
	item: PropTypes.object.isRequired,
	handleSelectedItem: PropTypes.func.isRequired,
};

export default CSSModules(PlanilhaItensRow, styles, { allowMultiple: true });
