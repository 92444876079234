import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, detailedmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import SiengeItens from './SiengeItens';
import HeaderSearchItens from './HeaderSearchItens';
import LeftModalWrapper from '../../../components/_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalAssociacao from '../../../components/_parts/_modals/LeftModalAssociacao/LeftModalAssociacao';

// Functions
import { _get, _post, _delete } from '../../../components/_functions/_requests';
import { formatCaracteristica } from './subfunctions/_formatCaracteristica';
import { refatoraListaSiengeItens } from './subfunctions/_refatoraListaSiengeItens';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { debounce } from '../../../components/_functions/_debounce';

class SiengeItensContainer extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		if (search.length > 0) {
			this.search(search).then(response => {
				const _itens = response.data.result || [];
				const itens = refatoraListaSiengeItens(_itens);
				this.setState({ request_state: 2, itens }, () => {
					const full_search = this.props.location.search
						.replace('?id=', '')
						.replace(/%20/g, ' ');
					const id_to_search = full_search
						.split('&detalhe=')[0]
						.replace(/\D/g, '');

					if (id_to_search === search) {
						this.openItemModalOnSearch(itens, search);
					}
				});
			}).catch(error => {
				this.setState({ request_state: 2, itens: [] });
				if (error.message !== 'canceled_request') {
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	constructor(props) {
		super(props);
		this.state = {
			itemsPage: {
				activePage: 1,
				pages: 0,
				totalItens: 0,
			},
			search: '',
			search_detalhe: '', // detalhe vem da url
			flagAssociated: true,
			flagNotAssociated: true,
			itemSiengeSelected: {},
			itemAssociated: {},
			request_state: 0,
			saving: 0,
			page: props.match.params.id,
			key_item_selecionado: -1,
			itens: [],
			show_tabelas_update: false,
			error: false,
			error_message: '',
			tabela: '',
			timer: null,
		};
		this.handleDebounce = this.handleDebounce.bind(this);
		this.handleflagNotAssociated = this.handleflagNotAssociated.bind(this);
		this.handleflagAssociated = this.handleflagAssociated.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSelectedItem = this.handleSelectedItem.bind(this);
		this.saveItem = this.saveItem.bind(this);
		this.desassociarItem = this.desassociarItem.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.showTabelasToUpdate = this.showTabelasToUpdate.bind(this);
		this.handleAtualizarItens = this.handleAtualizarItens.bind(this);
		this.updateItemCaracteristica = this.updateItemCaracteristica.bind(this);
		this.updateItem = this.updateItem.bind(this);
		this.autoSave = this.autoSave.bind(this);
		this.updateAprovacao = this.updateAprovacao.bind(this);
		this.removeLimitError = this.removeLimitError.bind(this);
	}

	componentDidMount() {
		document.title = 'Integração - Conaz';
		this.checkLoad(this.props.user);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.checkLoad(nextProps.user);
	}

	checkLoad(user) {
		const { cliente_id, sienge_liberado } = user;
		const { request_state, itemsPage } = this.state;
		const { history } = this.props;

		if (cliente_id !== 0 && !sienge_liberado) {
			history.replace('/cotacoes');
			return;
		}

		if (cliente_id !== 0 && request_state === 0) {
			this.carregarItens(itemsPage.activePage, user);
		}
	}

	openItemModalOnSearch(itens, search) {
		const { search_detalhe } = this.state;
		const item = itens.find(i => {
			if (search_detalhe !== '') {
				return i.id_no_swi === search && (i.detalhe || {}).id_no_swi === search_detalhe;
			}
			return i.id_no_swi === search && (i.detalhe || {}).id === undefined;
		});
		if (item !== undefined) this.handleSelectedItem(item);
	}

	updateAprovacao(aprovado) {
		const { itemAssociated } = this.state;
		const new_item = {
			...itemAssociated,
			associado_aprovado: aprovado,
			item_integrado: {
				...itemAssociated.item_integrado,
				aprovado,
			},
		};
		this.atualizarItemNaLista(new_item.key, new_item);
		this.autoSave(new_item);
	}

	checkSearch(opt = 0) {
		const { search } = this.props.location;
		const id_search = search.indexOf('?id=') !== -1;
		if (id_search) {
			const full_search = search
				.replace('?id=', '')
				.replace(/%20/g, ' ');
			const id_to_search = full_search
				.split('&detalhe=')[0]
				.replace(/\D/g, '');
			const detalhe_to_search = full_search
				.split('&detalhe=')[1] || '';
			this.setState({ search: id_to_search, search_detalhe: detalhe_to_search });
			const e = {
				target: {
					value: id_to_search,
				},
			};
			if (opt === 0) return this.handleSearch(e);
			return id_to_search;
		}
		return false;
	}

	carregarItens(pageNumber, user, request_state = 1, check_url_params = true) {
		this.setState({ request_state });
		const { cliente_id } = user;
		const url = `/conaz/v2/integracoes/itens_integrados/associados?swi=1&cliente_id=${cliente_id}&page=${pageNumber}&per_page=50&sort=nome`;
		_get(url, {}, []).then(result => {
			/**
			 * Verifica se os itens estão vazios
			 * seria o primeiro carregamento
			 */
			const itens = result.data.result || [];
			if (itens.length === 0 && request_state === 1) {
				this.showTabelasToUpdate();
				return;
			}

			/**
			 * Verifica se existe busca a ser feita
			 */
			const { search } = this.props.location;
			const id_search = search.indexOf('?id=') !== -1;
			if (id_search && check_url_params) {
				this.checkSearch();
				return;
			}

			/**
			 * Itens ok
			 */
			const itens_refatorados = refatoraListaSiengeItens(itens);
			const show_tabelas_update = itens.length === 0;
			this.setState({
				request_state: 2,
				show_tabelas_update,
				itens: itens_refatorados,
				itemsPage: {
					activePage: pageNumber,
					pages: result.data.pages,
					totalItens: result.data.total,
				},
			});
		}).catch(() => {
			this.setState({ request_state: 2, itens: [] });
		});
	}

	showTabelasToUpdate() {
		const { show_tabelas_update } = this.state;
		this.setState({
			request_state: 0,
			show_tabelas_update: !show_tabelas_update,
		});
	}

	handleAtualizarItens(tabela) {
		this.setState({ error: false, request_state: 10 });
		const { user } = this.props;
		// const { cliente_id } = user;
		const url = '/conaz/v2/integracoes/itens_integrados/atualizar_async';
		const params = {
			database_id: tabela,
		};
		_post(url, params, 0).then(((response) => {
			const ultima_atualizacao_itens = response.data.requisitado_em;
			this.carregarItens(1, user, 10);
			localStorage.setItem('ultima_atualizacao_itens', ultima_atualizacao_itens);
			const data = localStorage.getItem('ultima_atualizacao_itens');
			return data;
		})).catch(error => {
			const error_status = ((error || {}).response || {}).status;
			const message_conaz = (((error || {}).response || {}).data || {}).message || '';
			const error_message = message_conaz !== ''
				? message_conaz
				: '';

			// Erro de timeout
			const is_timeout_error = error_status === 408;
			if (is_timeout_error) {
				this.setState({
					error: true,
					error_message: 'timeout',
					show_tabelas_update: true,
					request_state: 0,
					itens: [],
				});
				return;
			}

			// Erro de limite de requests
			const requests_error = error_message
				.indexOf('LimitReachedException: Limite de requests') !== -1;
			if (requests_error) {
				this.setState({
					tabela,
					error_message,
					request_state: 20,
					itens: [],
				});
				return;
			}

			this.setState({
				error: true,
				error_message,
				show_tabelas_update: true,
				request_state: 0,
				itens: [],
			});
		});
	}

	removeLimitError() {
		this.setState({
			tabela: '',
			error_message: '',
			request_state: 0,
		});
	}

	handlePageChange({ selected }) {
		const { user } = this.props;
		this.carregarItens(selected + 1, user, 1, false);
	}

	async search(typed) {
		try {
			const { cliente_id } = this.props.user;

			const typed_encode = encodeURIComponent(typed).replace(/%0A/g, '');
			// const url = `${base_url}/conaz/v2/integracoes/itens_integrados/search?q=${typed_encode}&id_no_swi=${typed_encode}&swi=1`;
			const url = !parseInt(typed_encode, 10)
				? `/conaz/v2/integracoes/itens_integrados/associados?swi=1&nome=*${typed_encode}*&cliente_id=${cliente_id}`
				: `/conaz/v2/integracoes/itens_integrados/associados?swi=1&id_no_swi=${typed_encode}&cliente_id=${cliente_id}`;

			return _get(url);
		} catch (err) {
			throw err;
		}
	}

	handleSearch(e) {
		if (this.cancel !== undefined) {
			this.cancel('canceled_request');
		}

		clearTimeout(this.state.timer);

		const { itemsPage } = this.state;
		const { user } = this.props;
		const search = e.target.value.toString();
		if (search.trim().length > 0) {
			this.setState({ search, request_state: 1 }, this.handleDebounce);
		} else {
			this.setState({ search: '', itens: [], request_state: 1 });
			this.setState({ timer: setTimeout(() => this.carregarItens(itemsPage.activePage, user, 1, false), 800) });
		}
	}

	handleSelectedItem(item) {
		this.setState({ itemSiengeSelected: item, itemAssociated: item });
		this.props.updateModals('associacao', true);
	}

	handleflagNotAssociated() {
		const { flagNotAssociated } = this.state;
		this.setState({ flagNotAssociated: !flagNotAssociated });
	}

	handleflagAssociated() {
		const { flagAssociated } = this.state;
		this.setState({ flagAssociated: !flagAssociated });
	}

	updateItem(item_id, field, value) {
		const { itemAssociated } = this.state;
		const item_integrado = {
			...itemAssociated.item_integrado,
			[field]: value,
		};
		const new_item = {
			...itemAssociated,
			item_integrado,
		};
		this.setState({ itemAssociated: new_item });
		this.atualizarItemNaLista(item_id, new_item);
		this.autoSave(new_item);
	}

	saveItem(key, new_item) {
		this.setState({ saving: 1 });
		const { cliente_id } = this.props.user;
		this.atualizarItemNaLista(key, new_item);
		const param = {
			cliente_id,
			swi: 1,
			...new_item.item_integrado,
		};
		_post('/conaz/v2/integracoes/itens_associados', param).then(res => {
			this.setState({ saving: 2 });
			setTimeout(() => this.setState({ saving: 0 }), 5000);
			const { itemSiengeSelected } = this.state;
			if (itemSiengeSelected.id === res.data.item_integrado.id) {
				const data = {
					...new_item,
					idAssociacao: res.data.id,
					itens_associados: [
						{ id: res.data.id },
					],
				};
				this.setState({ key_item_selecionado: key, itemAssociated: data });
				this.atualizarItemNaLista(key, data);
			}
		}).catch(() => {
			this.setState({ saving: 0 });
		});
	}

	atualizarItemNaLista(item_id, item) {
		const item_associado_caracteristicas = {
			item_preenchido: {
				...item.item_preenchido,
				caracteristicas: item.item_integrado.item_preenchido.caracteristicas || [],
			},
		};

		const new_item_lista = {
			...item,
			item_associado_caracteristicas,
		};

		const _itens = this.state.itens;
		_itens[item_id] = new_item_lista;
		this.setState({ itens: _itens });

		const { itemSiengeSelected } = this.state;
		if (itemSiengeSelected.id === item.id) {
			this.setState({ itemAssociated: item });
		}
	}

	desassociarItem() {
		window.clearTimeout(this.save_timeout);
		const { itens, itemAssociated, itemSiengeSelected } = this.state;
		const item_key = itemSiengeSelected.key;
		const item_na_lista = itens[item_key] || {};
		const url = `/conaz/v2/integracoes/itens_associados/${itemAssociated.itens_associados[0].id}`;
		_delete(url).then(() => {
			/**
			 * Remove item associado dos objetos
			 */
			const _itemAssociated = {
				...itemAssociated,
				itens_associados: [],
				item_integrado: null,
				item_preenchido: {},
				cliente_associou: false,
			};
			delete _itemAssociated.idAssociacao;
			delete _itemAssociated.front;
			const _itemSiengeSelected = {
				...itemSiengeSelected,
				itens_associados: [],
				item_integrado: null,
				cliente_associou: false,
			};
			const _item_na_lista = {
				...item_na_lista,
				itens_associados: [],
				item_integrado: null,
				item_preenchido: {},
				cliente_associou: false,
			};
			delete _item_na_lista.item_associado_caracteristicas;
			delete _item_na_lista.front;

			/**
			 * Atualiza listagem dos itens
			 */
			const _itens = [
				...itens.slice(0, item_key),
				_item_na_lista,
				...itens.slice(item_key + 1),
			];

			this.setState({
				itens: _itens,
				key_item_selecionado: -1,
				itemAssociated: _itemAssociated,
				itemSiengeSelected: _itemSiengeSelected,
			});
			// this.props.updateModals('associacao', false);
		}).catch(err => {
			console.error(err); // eslint-disable-line
		});
	}

	updateItemCaracteristica(item_id, ca_id, value) {
		const { itemAssociated } = this.state;

		let caracteristica = { id: Number(ca_id) };
		if (value.length > 1) {
			const opcoes_ids = [];
			value.forEach(ctr => { opcoes_ids.push(ctr.id); });
			caracteristica = {
				id: value[0].caracteristica_id,
				opcoes_ids,
			};
		}

		if (value.length === 1) {
			caracteristica = formatCaracteristica(value);
		}

		let caracteristicas = [];
		if (itemAssociated.item_integrado.item_preenchido.caracteristicas) {
			caracteristicas = [
				...itemAssociated.item_integrado.item_preenchido.caracteristicas,
			];
		}

		const objIndex = caracteristicas.findIndex((crt => crt.id === (caracteristica || {}).id));
		if (caracteristicas[objIndex]) {
			caracteristicas[objIndex] = caracteristica;
		} else {
			caracteristicas.push(caracteristica);
		}

		// Elimina repetições
		const by_id = caracteristicas
			.filter(c => c !== null)
			.reduce((result, current) => (
				{ ...result, [current.id]: { ...current } }
			), {});
		const _caracteristicas = Object.keys(by_id).map(c_id => by_id[c_id]);

		const caracteristicas_preenchidas = {
			...itemAssociated.item_preenchido.caracteristicas_preenchidas,
			[ca_id]: {
				...itemAssociated.item_preenchido.caracteristicas_preenchidas[ca_id],
				opcoes_preenchidas: value,
			},
		};

		const new_item_associado = {
			...itemAssociated,
			item_integrado: {
				...itemAssociated.item_integrado,
				item_preenchido: {
					...itemAssociated.item_integrado.item_preenchido,
					caracteristicas: [..._caracteristicas],
				},
			},
			item_preenchido: {
				...itemAssociated.item_preenchido,
				caracteristicas_preenchidas,
			},
		};
		// const new_item_associado = itemAssociated;
		// new_item_associado.item_integrado.item_preenchido.caracteristicas = [..._caracteristicas];
		// new_item_associado.item_preenchido.caracteristicas_preenchidas = caracteristicas_preenchidas;
		delete new_item_associado.item_integrado.aprovado;

		this.atualizarItemNaLista(item_id, new_item_associado);
		this.autoSave(new_item_associado);
	}

	autoSave(item) {
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => this.saveItem(this.state.key_item_selecionado, item), 3000);
	}

	render() {
		const { ui, updateModals } = this.props;
		return (
			<LayoutWrapper {...this.props}>
				<>
					<LeftModalWrapper
						visible={ui.modals.associacao}
						updateModals={updateModals}
						modal_to_close="associacao">
						<LeftModalAssociacao
							updateItemCaracteristica={this.updateItemCaracteristica}
							updateItem={this.updateItem}
							saveItem={this.saveItem}
							desassociarItem={this.desassociarItem}
							itemSiengeSelected={this.state.itemSiengeSelected}
							itemAssociated={this.state.itemAssociated}
							updateAprovacao={this.updateAprovacao}
							itens={this.state.itens}
							autoSave={this.autoSave}
							{...this.state}
							{...this.props} />
					</LeftModalWrapper>
					<HeaderSearchItens
						handleflagAssociated={this.handleflagAssociated}
						handleflagNotAssociated={this.handleflagNotAssociated}
						handleSearch={this.handleSearch}
						handleAtualizarItens={this.handleAtualizarItens}
						showTabelasToUpdate={this.showTabelasToUpdate}
						{...this.state}
						{...this.props} />
					<SiengeItens
						handleSelectedItem={this.handleSelectedItem}
						handlePageChange={this.handlePageChange}
						handleAtualizarItens={this.handleAtualizarItens}
						showTabelasToUpdate={this.showTabelasToUpdate}
						removeLimitError={this.removeLimitError}
						{...this.state}
						{...this.props} />
				</>
			</LayoutWrapper>
		);
	}
}

SiengeItensContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(
	props, ['compras', 'cotacao', 'cotacoes', 'qc', 'ui', 'user'],
);
const mapDispatchToProps = (dispatch) => detailedmapDispatchToProps(
	dispatch, ['updateUi', 'updateUser', 'updateModals', 'updateCotacao'],
);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(SiengeItensContainer));
