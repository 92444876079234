/* eslint-disable react/react-in-jsx-scope */
import { all, takeLatest, put, call } from 'redux-saga/effects';
import { message } from 'antd';

import { ativaUsuario, criaUsuario, desativaUsuario, editaUsuario } from 'services/obras';
import { _get } from '../../components/_functions/_requests';

import * as Types from './types';
import * as Actions from './actions';

function* getUsers() {
	try {
		const res = yield call(_get, 'conaz/v2/usuarios_construtor');
		yield put(Actions.getUsersByClientSuccess(res.data));
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível recuperar os usuários');
		}
		yield put(Actions.getUsersByClientError(error));
	}
}

function* newUser({ payload: { action, data } }) {
	try {
		const res = yield call(criaUsuario, data);
		if (res.status === 400 || res.status === 404) {
			message.error('Não foi possível cadastrar o usuário');
		}

		yield put(Actions.createUserSuccess(res.data));
		yield put(Actions.closeUserDrawer());
		message.success('Usuário criado com sucesso');
	} catch (error) {
		message.error(`Não foi possível criar o usuário ${data.nome}`);
		yield put(Actions.userActionError(error, action));
	}
}

function* editUser({ payload: { data, action } }) {
	try {
		const { id } = data;
		const res = yield call(editaUsuario, id, data);
		if (res.status === 400 || res.status === 404) {
			throw new Error('Não foi possível editar o usuário');
		}
		yield put(Actions.editUserSuccess(data));
		message.success('Usuário editado com sucesso');
	} catch (error) {
		yield put(Actions.closeUserDrawer());
		message.error(`Não foi possível editar o usuário ${data.nome}`);
		yield put(Actions.userActionError(error, action));
	}
}

function* activateUser({ payload: { data, action } }) {
	try {
		const { id } = data;
		const res = yield call(ativaUsuario, id, data);
		if (res.status === 400 || res.status === 404) {
			throw new Error('Não foi possível ativar o usuário');
		}
		if (res.status === 200) {
			const response = yield call(_get, 'conaz/v2/usuarios_construtor');
			yield put(Actions.alteraStatusUserSuccess(response.data));
		}
		yield message.success('Usuário ativado com sucesso');
	} catch (error) {
		message.error(`Não foi possível ativar o usuário ${data.nome}`);
		yield put(Actions.userActionError(error, action));
	}
}

function* deactivateUser({ payload: { data, action } }) {
	try {
		const { id } = data;
		const res = yield call(desativaUsuario, id, data);
		if (res.status === 400 || res.status === 404) {
			throw new Error('Não foi possível desativar o usuário');
		}
		if (res.status === 200) {
			const response = yield call(_get, 'conaz/v2/usuarios_construtor');
			yield put(Actions.alteraStatusUserSuccess(response.data));
		}
		yield message.success('Usuário desativado com sucesso');
	} catch (error) {
		message.error(`Não foi possível desativar o usuário ${data.nome}`);
		yield put(Actions.userActionError(error, action));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.USERS_BY_CLIENT_REQUEST, getUsers),
		takeLatest(Types.USERS_CREATE_REQUEST, newUser),
		takeLatest(Types.USERS_EDIT_REQUEST, editUser),
		takeLatest(Types.USERS_DISABLE_REQUEST, deactivateUser),
		takeLatest(Types.USER_ACTIVATE_REQUEST, activateUser),
	]);
}
