import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import AssociaFornecedor from '../../../../icons/associacao_fornecedor.svg';

// Components
import ButtonRound from '../../../../components/_parts/_buttons/ButtonRound/ButtonRound';

const Fornecedor = ({
	/* state */
	loading,
	fornecedores,
	fornecedor: forn,
	timer,
	toggleList,
	searchInput,
	fornecedorInput,
	/* local funcs */
	handleNovoFornecedor,
	handleNomeFornecedor,
	handleDisplayFornecedor,
	handleUndoFornecedor,
	handleChangeFornecedor,
	/* props */
	nova_compra,
	nova_compra: { is_editable },
	errors,
	/* props funcs */
	handleFixError,
}) => {
	const lista_fornecedores =
		fornecedores.length > 0 ? (
			<>
				{fornecedores.map(fornecedor => (
					<React.Fragment key={fornecedor.id}>
						<button
							styleName="fornecedor-item"
							reference="fornecedor-item"
							onClick={() => handleChangeFornecedor(fornecedor)
							}>
							<p>{fornecedor.nome}</p>
							<p>
								Localizado em: {fornecedor.endereco.cidade}
							</p>
						</button>
					</React.Fragment>
				))}
				{searchInput !== '' && (
					<>
						<hr />
						<button
							styleName="fornecedor-item"
							reference="fornecedor-item"
							onClick={handleNovoFornecedor}>
							Adicionar &quot;<b>{searchInput}</b>
							&quot; mesmo assim
						</button>
					</>
				)}
			</>
		) : (
			<>
				<p styleName="no-fornecedor">
					Nenhum fornecedor encontrado
				</p>
				{searchInput !== '' && (
					<button
						styleName="fornecedor-item"
						reference="fornecedor-item"
						onClick={handleNovoFornecedor}>
						Adicionar &quot;<b>{searchInput}</b>
						&quot;
					</button>
				)}
			</>
		);

	const rc_analise_id = nova_compra.rc_analise_id || null;
	const rc_uuid = (nova_compra.rc || {}).uuid || null;
	const { usuario_fornecedor } = nova_compra;
	const tem_fornecedor_de_cotacao =
		rc_analise_id !== null || rc_uuid !== null || !is_editable;
	const { nome: nome_fornecedor = '', endereco = '' } = forn;
	const endereco_fornecedor =
		endereco && endereco.cidade && endereco.estado
			? `${endereco.cidade}/${endereco.estado}`
			: '';
	const vendedor_dados = `Vendedor(a): ${usuario_fornecedor.nome}`;

	if (!tem_fornecedor_de_cotacao) {
		return !nome_fornecedor ? (
			<div styleName="info-row">
				<div
					styleName={`label ${errors.fornecedor ? 'error' : ''}`}>
					Fornecedor<span styleName="obrigatorio">*</span>
				</div>
				<div styleName="inputs">
					{!loading ? (
						<i
							styleName="search-icon"
							className="fa fa-search"
							aria-hidden="true" />
					) : (
						<i
							styleName="search-icon"
							className="fa fa-spin fa-spinner"
							aria-hidden="true" />
					)}
					<input
						ref={fornecedorInput}
						className="form-control input-lg"
						styleName={`input ${
							errors.fornecedor ? 'error' : ''
						}`}
						type="text"
						value={searchInput}
						onChange={handleNomeFornecedor}
						placeholder="Buscar fornecedor"
						onFocus={() => handleFixError('fornecedor')} />
				</div>
				{toggleList && timer && !loading && (
					<div styleName="fornecedor-lista" reference="fornecedores-lista">
						{lista_fornecedores}
					</div>
				)}
			</div>
		) : (
			<div styleName="info-row">
				<div styleName="label">
					Fornecedor<span styleName="obrigatorio">*</span>
				</div>
				<button
					styleName="fornecedor-select"
					onClick={() => handleChangeFornecedor(forn)}>
					{nome_fornecedor}
					<p>{endereco_fornecedor}</p>
					<p>{vendedor_dados}</p>
				</button>
				<div style={{ float: 'right' }}>
					<ButtonRound
						size="small"
						icon="fa fa-exchange"
						tooltip_text="Remover fornecedor"
						left="-70%"
						onClick={handleUndoFornecedor}
						first_of_type />
				</div>
			</div>
		);
	}

	return (
		<>
			{nova_compra.fornecedor_integrado.ativo === true ? (
				<>
					<div styleName="info-row">
						<div styleName="label">Fornecedor
							<button
								onClick={handleDisplayFornecedor}><img src={AssociaFornecedor} alt="Associa fornecedor" /></button>
						</div>
						<button
							styleName="fornecedor"
							onClick={handleDisplayFornecedor}>
							<span>{nome_fornecedor}</span>
							<p styleName="fornecedor_endereco">
								{endereco_fornecedor}
							</p>
							<p styleName="vendor_dados">{vendedor_dados}</p>
						</button>
					</div>
				</>
			) : 	<div styleName="info-row">
				<div styleName="label">Fornecedor
					<button
						onClick={handleDisplayFornecedor} />
				</div>
				<button
					styleName="fornecedor"
					onClick={handleDisplayFornecedor}>
					<span>{nome_fornecedor}</span>
					<p styleName="fornecedor_endereco">
						{endereco_fornecedor}
					</p>
					<p styleName="vendor_dados">{vendedor_dados}</p>
				</button>
			</div>}
		</>
	);
};

Fornecedor.propTypes = {
	/* state */
	loading: PropTypes.bool.isRequired,
	fornecedores: PropTypes.array.isRequired,
	fornecedor: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string,
	]).isRequired,
	timer: PropTypes.bool.isRequired,
	toggleList: PropTypes.bool.isRequired,
	searchInput: PropTypes.string.isRequired,
	fornecedorInput: PropTypes.object.isRequired,
	/* local funcs */
	handleNovoFornecedor: PropTypes.func.isRequired,
	handleNomeFornecedor: PropTypes.func.isRequired,
	handleDisplayFornecedor: PropTypes.func.isRequired,
	handleUndoFornecedor: PropTypes.func.isRequired,
	handleChangeFornecedor: PropTypes.func.isRequired,
	/* props */
	nova_compra: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	/* props funcs */
	handleFixError: PropTypes.func.isRequired,
};

export default CSSModules(Fornecedor, styles, { allowMultiple: true });
