import styled from 'styled-components';

export const ObraPhotos = styled.div``;

ObraPhotos.Label = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #8C8C8C;
`;

ObraPhotos.Imgs = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);	align-items: center;
	gap: 6px;

	.infos-photo {
		margin-top: 3px;
		cursor: pointer;
		& > img {
			border-radius: 8px !important;
		}
	}
`;
