import { fork, all } from 'redux-saga/effects';

import comprasSaga from './compras/sagas';
import avaliacoesSaga from './avaliacoes/sagas';
import cotacoesSaga from './cotacoes/sagas';
import userSaga from './user/sagas';
import exportStatusSaga from './exportStatus/saga';
import importacaoPlanilha from './importacaoPlanilha/saga';
import usuariosSagas from './usuarios/sagas';
import empresasSagas from './empresas/sagas';
import enderecoForm from './enderecoForm/sagas';
import obrasSagas from './obras/sagas';
import obrasDrawerSagas from './obrasDrawer/s';
import associaObra from './associaObra/sagas';

export default function* rootSaga() {
	yield all([
		fork(comprasSaga),
		fork(avaliacoesSaga),
		fork(cotacoesSaga),
		fork(userSaga),
		fork(exportStatusSaga),
		fork(importacaoPlanilha),
		fork(usuariosSagas),
		fork(empresasSagas),
		fork(enderecoForm),
		fork(obrasSagas),
		fork(obrasDrawerSagas),
		fork(associaObra),
	]);
}
