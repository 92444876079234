// Recupera dados da obra selecionada
export const ASSOCIA_OBRA_GET_OBRA_REQUEST = 'ASSOCIA_OBRA_GET_OBRA_REQUEST';
export const ASSOCIA_OBRA_GET_OBRA_SUCCESS = 'ASSOCIA_OBRA_GET_OBRA_SUCCESS';
export const ASSOCIA_OBRA_GET_OBRA_ERROR = 'ASSOCIA_OBRA_GET_OBRA_ERROR';

// edita obra selecionada
export const ASSOCIA_OBRA_EDIT_OBRA_REQUEST = 'ASSOCIA_OBRA_EDIT_OBRA_REQUEST';
export const ASSOCIA_OBRA_EDIT_OBRA_SUCCESS = 'ASSOCIA_OBRA_EDIT_OBRA_SUCCESS';
export const ASSOCIA_OBRA_EDIT_OBRA_ERROR = 'ASSOCIA_OBRA_EDIT_OBRA_ERROR';
export const ASSOCIA_OBRA_OPEN_EDIT_OBRA_DRAWER = 'ASSOCIA_OBRA_OPEN_EDIT_OBRA_DRAWER';
export const ASSOCIA_OBRA_CLOSE_EDIT_OBRA_DRAWER = 'ASSOCIA_OBRA_CLOSE_EDIT_OBRA_DRAWER';

// associa empresa a obra selecionada
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_REQUEST = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_REQUEST';
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_SUCCESS = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_SUCCESS';
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_ERROR = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_ERROR';
export const ASSOCIA_OBRA_REMOVE_EMPRESA_REQUEST = 'ASSOCIA_OBRA_REMOVE_EMPRESA_REQUEST';
export const ASSOCIA_OBRA_REMOVE_EMPRESA_SUCCESS = 'ASSOCIA_OBRA_REMOVE_EMPRESA_SUCCESS';
export const ASSOCIA_OBRA_REMOVE_EMPRESA_ERROR = 'ASSOCIA_OBRA_REMOVE_EMPRESA_ERROR';
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_DRAWER';
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_EDIT_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_EDIT_DRAWER';
export const ASSOCIA_OBRA_ASSOCIA_EMPRESA_CLOSE_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_EMPRESA_CLOSE_DRAWER';

// Associa usuario a obra
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_REQUEST = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_REQUEST';
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_SUCCESS = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_SUCCESS';
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_ERROR = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_ERROR';
export const ASSOCIA_OBRA_REMOVE_USUARIO_REQUEST = 'ASSOCIA_OBRA_REMOVE_USUARIO_REQUEST';
export const ASSOCIA_OBRA_REMOVE_USUARIO_SUCCESS = 'ASSOCIA_OBRA_REMOVE_USUARIO_SUCCESS';
export const ASSOCIA_OBRA_REMOVE_USUARIO_ERROR = 'ASSOCIA_OBRA_REMOVE_USUARIO_ERROR';
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_EDIT_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_EDIT_DRAWER';
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_DRAWER';
export const ASSOCIA_OBRA_ASSOCIA_USUARIO_CLOSE_DRAWER = 'ASSOCIA_OBRA_ASSOCIA_USUARIO_CLOSE_DRAWER';

export const ASSOCIA_OBRA_GET_LIMPA_ESTADO = 'ASSOCIA_OBRA_GET_LIMPA_ESTADO';

export const ASSOCIA_OBRA_STATUS_VISUALIZACAO = 'ASSOCIA_OBRA_STATUS_VISUALIZACAO';
export const ASSOCIA_OBRA_STATUS_ATIVO = 'ASSOCIA_OBRA_STATUS_ATIVO';
