import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
// import { editEnviadoEmFormat } from '../../../_functions/_editDateFormat';

class RefinamentoActions extends Component {
	state = {};

	render() {
		const { compra } = this.props;
		const { motivo_refinamento } = compra;

		return (
			<div styleName="alerts-messages-wrapper">
				<div styleName="am-text full">
					<b>Motivo do refinamento</b>
					<br />
					<p>{motivo_refinamento}</p>
					É necessário reenviar seu pedido após realizar as alterações solicitadas!
				</div>
			</div>
		);
	}
}

RefinamentoActions.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(RefinamentoActions, styles, { allowMultiple: true });
