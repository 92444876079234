/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import LoadingPage from 'components/_parts/_loadings/LoadingPage/LoadingPage';
import styles from './style.module.scss';


class SelecionaArquivo extends Component {
	constructor() {
		super();
		this.state = {
			dragover: false,
			request_state: 0,
		};
		this.splitFilesInput = this.splitFilesInput.bind(this);
		this.splitFilesDrop = this.splitFilesDrop.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
	}

	splitFilesInput(e) {
		e.preventDefault();
		const files_to_add = e.target.files[0];
		this.props.handleFileSelect(files_to_add);
	}

	splitFilesDrop(e) {
		e.stopPropagation();
		e.preventDefault();

		this.setState({ dragover: false });
		const files_to_add = e.dataTransfer.files[0];
		this.props.handleFileSelect(files_to_add);
	}

	handleDragEnter(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ dragover: true });
	}

	handleDragLeave(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ dragover: false });
	}

	/* eslint class-methods-use-this: 0 */
	handleDragOver(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	render() {
		const { dragover, request_state } = this.state;
		const droparea_class = !dragover
			? 'droparea'
			: 'droparea dragover';

		if (request_state === 10) {
			return (
				<div className="container">
					<div className="row">
						<div className="col-xs-12" styleName="mt-2">
							<LoadingPage small_pad_top />
							<h4 style={{ textAlign: 'center', margin: '20px 0' }}>Sincronizando seus insumos da planilha</h4>
							<p style={{ textAlign: 'center', fontSize: '16px' }}>Aguarde enquanto salvamos todos os seus insumos da Planilha em nosso sistema.<br /> Dependendo do número de itens, <b>este processo pode demorar alguns minutos</b>.</p>
						</div>
					</div>
				</div>
			);
		}


		return (
			<>
				<div styleName="importacao-via-planilha">
					<h3 styleName="carregar-title">Importar insumos do seu ERP</h3>
					<p styleName="descricao-importacao-planilha">Carregue a planilha de insumos exportada do seu ERP seguindo o modelo padrão. Nosso sistema irá buscar o conteúdo de sua planilha se a mesma tiver colunas na ordem <span styleName="destaque-negrito-planilha">"Código do insumo", "Nome do insumo", "Observação do insumo</span> (opcional)<span styleName="destaque-negrito-planilha">" e "Unidade"</span>, em uma lista contínua, ou seja, sem linhas vazias. A coluna "Código do insumo" só aceita números inteiros, se conter caracteres alfabéticos ou especiais ocorrerá um erro. O formato do arquivo dever ser .xls, .xlsx ou .csv. Dependendo do tamanho do arquivo, o carregamento pode levar alguns minutos para concluir.</p>

					<div styleName="botoes-acao-planilha">
						<div id="droparea_box" styleName="droparea-wrapper">
							<div
								styleName={droparea_class}
								onDragEnter={this.handleDragEnter}
								onDragLeave={this.handleDragLeave}
								onDragOver={this.handleDragOver}
								onDrop={this.splitFilesDrop}>
								{!dragover ?
									(
										<>
											<i className="fa fa-upload" style={{ color: '#828282' }} />
											<p styleName="droparea-title">Arraste aqui seu arquivo</p>
											<p styleName="droparea-or">ou</p>
											<div styleName="select-button">
												<input
													type="file"
													id="file-1"
													name="file-1[]"
													styleName="inputfile"
													onChange={this.splitFilesInput}
												/>
												<label htmlFor="file-1">
													<p styleName="computer">selecione-o no seu computador</p>
													<p styleName="phone">selecione-o no seu celular</p>
													<p styleName="tablet">selecione-o no seu tablet</p>
												</label>
											</div>
										</>
									) :
									(
										<p styleName="droparea-title-planilha">Solte para enviá-los</p>
									)}
							</div>
						</div>

						<a
							href="https://s3-sa-east-1.amazonaws.com/conaz/22 - 02_FEVEREIRO/102467/Anexos para fornecedores/Modelo_padrao_importacao_tabela_insumos.xlsx"
							target="_blank"
							rel="noopener noreferrer"
							type="button"
							styleName="baixar-planilha">
							Baixar planilha modelo
						</a>
					</div>
				</div>
			</>
		);
	}
}

SelecionaArquivo.propTypes = {
	handleFileSelect: PropTypes.func.isRequired,
};

export default CSSModules(SelecionaArquivo, styles, { allowMultiple: true });
