import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

class ModalCopiaInformacoes extends Component {
	constructor(props) {
		super(props);
		let tem_observacoes_itens = false;
		let tem_observacoes_itens_interna = false;
		props.dadosCotacao.itens_de_pedido.forEach(
			({ observacoes, observacoes_internas }) => {
				if (observacoes) {
					tem_observacoes_itens = true;
				}
				if (observacoes_internas) tem_observacoes_itens_interna = true;
			},
		);
		this.state = {
			confirmando: 0,
			observacoes: false,
			observacoes_internas: false,
			observacoes_itens: false,
			observacoes_internas_itens: false,
			tem_observacoes_itens,
			tem_observacoes_itens_interna,
		};
	}

	handleInfo = () => {
		const { compra, autoSaveInfo, closeModal, dadosCotacao, itens, updateItensRaw } = this.props;
		const {
			observacoes,
			observacoes_internas,
			observacoes_itens,
			observacoes_internas_itens,
		} = this.state;
		this.setState({ confirmando: 1 }, async () => {
			if (observacoes || observacoes_internas) {
				const nova_compra = { ...compra };
				if (observacoes) nova_compra.observacoes = dadosCotacao.observacoes;
				if (observacoes_internas) {
					nova_compra.observacoes_iternas =
						dadosCotacao.observacoes_internas;
				}

				await autoSaveInfo();
			}

			if (observacoes_itens || !observacoes_internas_itens) {
				const itens_compra = { ...itens };
				dadosCotacao.itens_de_pedido.forEach(({ id, observacoes: observacao_item, observacoes_internas_item }) => {
					itens_compra.by_id[id].observacoes = observacao_item;
					itens_compra.by_id[id].observacoes_internas = observacoes_internas_item;
				});

				updateItensRaw({ itens_compra });
			}
			this.setState({ confirmando: 0 }, () => closeModal());
		});
	};

	handleCheck = ({ target, target: { name, tagName } }) => {
		if (tagName === 'DIV' || tagName === 'SPAN') {
			this.setState({
				[target.closest('button').name]: !this.state[
					target.closest('button').name
				],
			});
		} else this.setState({ [name]: !this.state[name] });
	};

	render() {
		const {
			confirmando,
			observacoes,
			observacoes_internas,
			observacoes_itens,
			observacoes_internas_itens,
			tem_observacoes_itens,
			tem_observacoes_itens_interna,
		} = this.state;
		const {
			closeModal,
			modal_width,
			title,
			icon,
			button_type,
			button_text,
			cancel_button_text,
			error_message,
			dadosCotacao,
			is_mobile,
		} = this.props;

		const modal_width_class =
			modal_width !== ''
				? `modal-content ${modal_width}`
				: 'modal-content';

		return (
			<div className="modal-content" styleName={modal_width_class}>
				<div className="modal-body" styleName="modal-body">
					{confirmando !== 1 && (
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={closeModal}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					{icon !== '' && (
						<div styleName="icon">
							<i className={`fa fa-${icon}`} aria-hidden="true" />
						</div>
					)}
					<div styleName="content">
						<h3>{title}</h3>
						<button
							type="button"
							name="observacoes"
							styleName={`check-button-block ${
								observacoes ? 'selected' : ''
							}`}
							onClick={this.handleCheck}>
							{dadosCotacao.observacoes && (
								<div styleName="checkbox">
									<span styleName="checkmark" />
								</div>
							)}
							<div
								styleName={`label ${
									dadosCotacao.observacoes
										? ''
										: 'line-through'
								}`}>
								Mensagem para Fornecedores
							</div>
						</button>
						<button
							type="button"
							name="observacoes_internas"
							styleName={`check-button-block ${
								observacoes_internas ? 'selected' : ''
							}`}
							onClick={this.handleCheck}>
							{dadosCotacao.observacoes_internas && (
								<div styleName="checkbox">
									<span styleName="checkmark" />
								</div>
							)}
							<div
								styleName={`label ${
									dadosCotacao.observacoes_internas
										? ''
										: 'line-through'
								}`}>
								Observações Internas
							</div>
						</button>
						<button
							type="button"
							name="observacoes_itens"
							styleName={`check-button-block ${
								observacoes_itens ? 'selected' : ''
							}`}
							onClick={this.handleCheck}>
							{tem_observacoes_itens && (
								<div styleName="checkbox">
									<span styleName="checkmark" />
								</div>
							)}
							<div
								styleName={`label ${
									tem_observacoes_itens ? '' : 'line-through'
								}`}>
								Observações dos itens
							</div>
						</button>
						<button
							type="button"
							name="observacoes_internas_itens"
							styleName={`check-button-block ${
								observacoes_internas_itens ? 'selected' : ''
							}`}
							onClick={this.handleCheck}>
							{tem_observacoes_itens_interna && (
								<div styleName="checkbox">
									<span styleName="checkmark" />
								</div>
							)}
							<div
								styleName={`label ${
									tem_observacoes_itens_interna
										? ''
										: 'line-through'
								}`}>
								Observações internas dos itens
							</div>
						</button>
						{is_mobile && <br />}
						{confirmando === 3 && (
							<div className="alert alert-danger" role="alert">
								<i
									className="fa fa-exclamation-triangle fa-fw"
									aria-hidden="true" />{' '}
								{error_message}
							</div>
						)}
						{confirmando === 1 ? (
							<>
								<button
									type="button"
									style={{
										float: 'right',
										fontWeight: 'bold',
									}}
									className={`btn btn-${button_type} round-border loading`}>
									<LoadingButton />
									{button_text}
								</button>
								<button
									type="button"
									className="btn btn-clear-conaz round-border"
									style={{
										opacity: '0.3',
										float: 'right',
										marginRight: '10px',
									}}>
									Cancelar
								</button>
							</>
						) : (
							<>
								<button
									type="button"
									style={{
										float: 'right',
										fontWeight: 'bold',
									}}
									className={`btn btn-${button_type} round-border`}
									onClick={this.handleInfo}>
									{button_text}
								</button>
								<button
									type="button"
									className="btn btn-clear-conaz round-border"
									style={{
										float: 'right',
										marginRight: '10px',
									}}
									onClick={closeModal}>
									{cancel_button_text}
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalCopiaInformacoes.propTypes = {
	compra: PropTypes.object.isRequired,
	dadosCotacao: PropTypes.object.isRequired,
	autoSaveInfo: PropTypes.func.isRequired,
	closeModal: PropTypes.func,
	modal_width: PropTypes.string,
	title: PropTypes.string.isRequired,
	itens: PropTypes.object.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	icon: PropTypes.string,
	button_type: PropTypes.string,
	button_text: PropTypes.string,
	cancel_button_text: PropTypes.string,
	error_message: PropTypes.string,
	is_mobile: PropTypes.bool,
};

ModalCopiaInformacoes.defaultProps = {
	closeModal: () => {},
	modal_width: '',
	icon: '',
	button_type: 'primary', // secondary
	button_text: 'Inserir',
	cancel_button_text: 'Cancelar',
	is_mobile: false,
	error_message: 'Desculpe, aconteceu algo errado. Tente novamente.',
};

export default CSSModules(ModalCopiaInformacoes, styles, {
	allowMultiple: true,
});
