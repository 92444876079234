import React from 'react';
import PropTypes from 'prop-types';
import mojs from '@mojs/core';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import Rating from '../Rating';

// Functions
import { setClasses } from '../_functions/_setClasses';

const RatingStep = ({
	step,
	step_number,
	criterio_id,
	criterio,
	handleCriterios,
}) => {
	const _question = criterio.question || '';
	const question = _question
		.split('{br}')
		.map((p, i) => <React.Fragment key={i}>{p}<br /></React.Fragment>); // eslint-disable-line react/no-array-index-key
	const type = criterio.type || 'rating';
	const { value } = criterio;

	function handleRateEdition(rate) {
		// remove last animation html element
		const last_animation = document.querySelector('[data-name="mojs-shape"]');
		if (last_animation) {
			last_animation.parentElement.removeChild(last_animation);
		}
		// find star element
		const ant_rate_all = document.getElementsByClassName('ant-rate') || [];
		const ant_rate = ant_rate_all[0] || {};
		const ant_rate_childs = ant_rate.childNodes || [];
		const parent = ant_rate_childs[rate - 1];
		// create animation
		const burst = new mojs.Burst({
			radius: { 0: 55 },
			count: 10,
			angle: { 0: 50 },
			opacity: { 1: 0 },
			parent,
			top: 14,
			left: 10,
			children: {
				fill: ['#fadb14'],
				duration: 700,
			},
		});
		burst.replay();
		// save edition
		handleCriterios(criterio_id, rate);
	}

	function handleBoolEdition(bool) {
		handleCriterios(criterio_id, bool);
	}

	const step_classes = {
		actual: step === step_number,
		previous: step > step_number,
		next: step < step_number,
	};
	const step_class = setClasses('criterio', step_classes);
	const wrapper_id = `avaliacao-buttons-wrapper-${step_number}`;
	const yes_button_class = value === true
		? 'yes-button active'
		: 'yes-button';
	const no_button_class = value === false
		? 'no-button active'
		: 'no-button';

	return (
		<div styleName={step_class}>
			<div styleName="title">{question}</div>
			{type === 'rating' && (
				<Rating
					rate={value}
					handleRate={handleRateEdition}
				/>
			)}

			{type === 'bool' && (
				<div styleName="buttons-wrapper" id={wrapper_id}>
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						styleName={no_button_class}
						onClick={() => handleBoolEdition(false)}
					>
						<i className="fa fa-times" aria-hidden="true" />
					</button>
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						styleName={yes_button_class}
						onClick={() => handleBoolEdition(true)}
					>
						<i className="fa fa-check" aria-hidden="true" />
					</button>
				</div>
			)}
		</div>
	);
};

RatingStep.propTypes = {
	step: PropTypes.number.isRequired,
	step_number: PropTypes.number.isRequired,
	criterio_id: PropTypes.string.isRequired,
	criterio: PropTypes.object.isRequired,
	handleCriterios: PropTypes.func.isRequired,
};

export default CSSModules(RatingStep, styles, { allowMultiple: true });
