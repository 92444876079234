import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ButtonExpand = ({ compacted, handleExpand }) => {
	const icon = compacted
		? <i className="fa fa-angle-down" aria-hidden="true" />
		: <i className="fa fa-angle-up" aria-hidden="true" />;

	return (
		<button
			type="button"
			styleName="button-expand"
			onClick={handleExpand}>
			{icon}
		</button>
	);
};

ButtonExpand.propTypes = {
	compacted: PropTypes.bool.isRequired,
	handleExpand: PropTypes.func.isRequired,
};

export default CSSModules(ButtonExpand, styles, { allowMultiple: true });
