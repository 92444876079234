/* eslint-disable */
import React from 'react';
import { ActionsArea } from './styled';
import { EditOutlined, StopOutlined } from '@ant-design/icons';

import papeisOptions from './papeis';
import { Popconfirm } from 'antd';

const emptyCell = '-';

const papeisMap = papeisOptions.reduce((acc, cur) => {
	acc[cur.value] = cur.label;
	return acc;
}, {});

const numerosToPaPel = (nums) => {
	if (!nums || !nums.length) return emptyCell;

	return nums.map((n) => papeisMap[n]).join(' ,');
};

const numerosToAlcada = (nums) => {
	if (nums === -1) return 'Ilimitado para alçada de compra';
	return nums;
};

export default (currentUserRole, openDrawer) => [
	{
		title: 'Usuário',
		key: '1',
		dataIndex: 'usuario',
		onFilter: (value, record) => record.nome.includes(value),
		sorter: (a, b) => a.usuario.nome.length - b.usuario.nome.length,
		render: (value) => value.nome || emptyCell,
		align: 'left',
		width: 180,
		ellipsis: true,
	},
	{
		title: 'E-mail',
		key: '2',
		dataIndex: 'usuario',
		render: (value) => value.email || emptyCell,
		onFilter: (value, record) => record.usuario.email.includes(value),
		sorter: (a, b) => a.usuario.email.length - b.usuario.email.length,
		width: 185,
		ellipsis: true,
	},
	{
		title: 'Papeis',
		dataIndex: 'papeis',
		width: 400,
		key: '3',
		render: (value) => numerosToPaPel(value) || emptyCell,
		sorter: (a, b) => a.papeis.length - b.papeis.length,
		ellipsis: true,
	},
	{
		title: 'Alçada',
		dataIndex: 'valor_alcada_compra',
		width: 200,
		key: '3',
		render: (value) => numerosToAlcada(value),
		sorter: (a, b) => a.valor_alcada_compra.length - b.valor_alcada_compra.length,
		ellipsis: true,
	},
	{
		title: 'Ações',
		dataIndex: 'actions',
		key: '6',
		width: 110,
		render: (_, record) => (
			<ActionsArea>
				{currentUserRole &&
					<>
						<EditOutlined onClick={() => openDrawer('edit', record)} />
						<Popconfirm
						title={`Tem certeza que deseja remover ${record.usuario.nome} dessa associação?`} 
						onCancel={() => {}} 
						onConfirm={() => openDrawer('remove', record)}
						cancelText="Cancelar"
						okText="Sim">
						<StopOutlined />
						</Popconfirm>
					</>
				}
			</ActionsArea>
		)
	},
];
