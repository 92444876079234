import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

import { formatNumbers } from '../../../_functions/_formatNumbers';

const VendedorButton = ({ vendedor, estah_selecionado, onClickFunction }) => {
	const button_class = `radio-button-block${
		estah_selecionado ? ' selected' : ''
	}`;

	let _telefones = vendedor.telefones.map(tel => formatNumbers(tel.numero, 'telefone'));
	_telefones = _telefones.length > 0 ? _telefones.join(' / ') : '';

	return (
		<button
			type="button"
			styleName={button_class}
			onClick={() => onClickFunction(vendedor)}>
			<div styleName="radio" />
			<div styleName="nome">
				{vendedor.nome !== '' ? vendedor.nome : '-'}{' '}
			</div>
			<div styleName="email">{vendedor.email}</div>
			{_telefones !== '' && <div styleName="email">{_telefones}</div>}
		</button>
	);
};

VendedorButton.propTypes = {
	vendedor: PropTypes.object.isRequired,
	onClickFunction: PropTypes.func.isRequired,
	estah_selecionado: PropTypes.bool.isRequired,
};

export default CSSModules(VendedorButton, styles, { allowMultiple: true });
