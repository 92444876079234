import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';

const PlanilhaInicio = ({
	loading,
	itens_configurados,
}) => {
	if (loading === 1) {
		return <LoadingPage />;
	}
	const color_on = '#6dc641';

	return (
		<>
			{/* Header */}
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h3 className="h3-header">Conectar ao seu ERP via planilha</h3>
							<p styleName="sub-title">Agora você pode conectar ao seu ERP através da importação via planilha</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container" style={{ heigth: '210px' }}>
				<div className="row ">
					<div className="col-md-4 h-content" styleName="blocks-wrapper" style={{ opacity: '1' }}>
						<div styleName="number">1</div>
						{itens_configurados ? (
							<h4 className="h3-header" style={{ color: color_on, marginBottom: '5px' }}> <strong style={{ fontWeight: 'bolder' }}>Configure seus insumos <i className="fa fa-check" aria-hidden="true" /> </strong></h4>
						) : (
							<h4 className="h3-header">Configure seus insumos </h4>
						)}
						<p styleName="sub-title-content">Associe seus insumos para facilitar<br /> a importação das futuras solicitações.</p>
						<div>
							<Link
								to="/integracao/planilha/itens"
								className="btn btn-clear-conaz round-border"
								styleName="add-files-button-itens-planilha">
								<span>Configurar itens</span>
								<i className="fa fa-long-arrow-right" aria-hidden="true" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

PlanilhaInicio.propTypes = {
	// =========== state
	loading: PropTypes.number.isRequired,
	itens_configurados: PropTypes.number.isRequired,
};
export default CSSModules(PlanilhaInicio, styles, { allowMultiple: true });
