import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const Loading = ({ left, style }) => (
	<div styleName="loader" style={{ left, ...style }}>
		<svg styleName="circular" viewBox="25 25 50 50">
			<circle styleName="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
		</svg>
	</div>
);

Loading.propTypes = {
	left: PropTypes.string,
	style: PropTypes.objectOf(PropTypes.string),
};

Loading.defaultProps = {
	left: '0',
	style: {},
};

export default CSSModules(Loading, styles, { allowMultiple: true });
