import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

// Functions
import { _post } from '../../../_functions/_requests';
import { buildNewItemV2 } from '../../../_functions/_buildNewItem';
import { createItemBeforePost } from '../../../_functions/_createItemBeforePost';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

function withAddItemFuncs(Comp) {
	class PP extends Component {
		static saveSearch(search = '', item) {
			const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
			const item_id = (item || {}).id || null;
			const url = `/conaz/v2/itens/${item.id}/salvar_frase_de_busca`;
			const params = { frase: search };
			if (search !== '' && !is_triagem && item_id !== null) {
				_post(url, params)
					.then()
					.catch(handleRequestErrors);
			}
		}

		state = {
			adding_item: false,
			error_adding: false,
		};

		associaItemSienge = (item_id, item_to_add) => {
			this.setState({
				adding_item: true,
				error_adding: false,
			});

			// get itens to update
			const itens_store = this.defineItens();
			const { by_id } = itens_store;
			const item = by_id[item_id] || {};

			// TODO cliente_id precisa vir da obra
			const { user } = this.props;
			const { cliente_id } = user;
			const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
			const id_item_construtor = item.id_item_construtor || null;
			const id_no_swi_detalhe = item.id_detalhe_item_construtor || null;
			const item_conaz_id = item_to_add.id || null;
			const unidade = item.unidade || '';

			if (id_item_construtor !== null && !is_triagem) {
				const params = {
					swi: 1,
					cliente_id,
					id_no_swi_item: id_item_construtor,
					unidade,
					item_preenchido: { id: item_conaz_id },
				};
				if (id_no_swi_detalhe !== null) {
					params.id_no_swi_detalhe = id_no_swi_detalhe;
				}
				const url = `/conaz/v2/integracoes/associar_item_de_pedido/${item.id}`;
				_post(url, params).then(response => {
					const item_associado = response.data || {};
					this.associaItemPlanilha(item, item_to_add, item_associado);
				}).catch(() => {
					this.setState({
						adding_item: false,
						error_adding: true,
					});
				});
				return;
			}

			this.associaItemPlanilha(item, item_to_add, {});
		}

		associaItemPlanilha = (item, item_to_add, item_associado = {}) => {
			const item_associado_id = item_associado.id || null;
			const { type, updateItensRaw, autoSave } = this.props;

			// get itens to update
			const itens_store = this.defineItens();
			const { by_id, all_ids } = itens_store;

			// limpa nome
			const nome = item_to_add.nome
				.replace(/<\/span>/g, '')
				.replace(/<span>/g, '');
			const caracteristicas = item_to_add.caracteristicas
				.filter(c => c.nome !== 'Recomendação de Marca');
			const carac_preenchidas_by_id = caracteristicas
				.sort((a, b) => a.ordem - b.ordem)
				.reduce((r, c) => ({
					...r,
					[c.id]: {
						caracteristica: { ...c },
						opcoes_preenchidas: c.opcoes_preenchidas !== undefined
							? c.opcoes_preenchidas
							: [],
					},
				}), {});
			const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id)
				.map(id => id);
			// remove erros
			const front = {
				...item.front,
				errors: [],
			};

			const pre_new_item = {
				...item,
				item_de_resposta: {},
				cliente_associou: true,
				importado_planilha: 2,
				item_preenchido: {
					...item.item_preenchido,
					caracteristicas_preenchidas: carac_preenchidas_by_id,
					caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
					item: {
						...item_to_add,
						nome,
						caracteristicas,
					},
				},
				front,
			};

			if (item_associado_id !== null) {
				pre_new_item.item_integrado = {
					...pre_new_item.item_integrado,
					itens_associados: [{ ...item_associado }],
				};
				pre_new_item.id_item_associado = item_associado_id;
				pre_new_item.entendido = true;
			}

			const new_item = {
				...pre_new_item,
				relacionado_obrigatorio_id: null,
				front: {
					...pre_new_item.front,
					origem: '',
					item_de_planilha: false,
					planilha_pre_values: [],
				},
			};

			const _by_id = {
				...by_id,
				[item.id]: new_item,
			};
			const itens_updated = {
				by_id: _by_id,
				all_ids,
			};
			updateItensRaw({
				[type]: itens_updated,
				adding_item: false,
			});
			autoSave(item.id);

			if (item_to_add.itens_relacionados_obrigatorios.length === 0) {
				this.setState({ adding_item: false });
			} else {
				let { ordem } = item;
				const url = this.defineUrl();
				let relacionados_posts = [];
				if (item_to_add.itens_relacionados_obrigatorios.length > 0) {
					const relacionados_nao_adicionados = item_to_add.itens_relacionados_obrigatorios
						.filter(ir => {
							const check_id = all_ids.filter(x => x === ir.id).length;
							if (check_id > 0) return false;
							return true;
						});
					relacionados_posts = relacionados_nao_adicionados
						.map(ir => {
							ordem++;
							const item_relacionado = createItemBeforePost(ir, ordem);
							return _post(url, item_relacionado);
						});
				}
				Promise.all(relacionados_posts).then(responses => {
					for (let i = 0; i < responses.length; i++) {
						this.addItemToPedido(responses[i].data, item.id);
					}
				}).catch(() => {
					this.setState({
						adding_item: false,
						error_adding: true,
					});
				});
			}
		}

		getItemToAdd = (item_to_add, caracteristicas_preenchidas = []) => {
			const { search } = this.props;
			this.constructor.saveSearch(search, item_to_add);

			this.setState({
				adding_item: true,
				error_adding: false,
			});

			// get itens to update
			const itens_store = this.defineItens();
			const { by_id, all_ids } = itens_store;

			// limpa nome
			const nome = item_to_add.nome
				.replace(/<\/span>/g, '')
				.replace(/<span>/g, '');
			const caracteristicas = item_to_add.caracteristicas
				.filter(c => c.nome !== 'Recomendação de Marca');
			const item = {
				...item_to_add,
				nome,
				caracteristicas,
			};
			// limpa sinonimos
			delete item.sinonimos_front;
			// ordem
			const last_item_id = all_ids[all_ids.length - 1];
			let ordem = all_ids.length > 0
				? (by_id[last_item_id].ordem || 0) + 5
				: 0;
			// item
			// const parent_item = createItemBeforePost(item, ordem);
			const parent_item = {
				item_preenchido: {
					item: { ...item },
					caracteristicas_preenchidas: [...caracteristicas_preenchidas],
				},
				ordem,
				cliente_associou: true,
				incluso_em: 1,
				pre_quantidade: 0,
				quantidade: 0,
				outra_unidade: false,
			};
			const url = this.defineUrl();
			const parent_post = [_post(url, parent_item)];

			// Itens relacionados
			let relacionados_posts = [];
			if (item.itens_relacionados_obrigatorios.length > 0) {
				const relacionados_nao_adicionados = item.itens_relacionados_obrigatorios
					.filter(ir => {
						const check_id = all_ids.filter(x => x === ir.id).length;
						if (check_id > 0) return false;
						return true;
					});
				relacionados_posts = relacionados_nao_adicionados
					.map(ir => {
						ordem++;
						const item_relacionado = createItemBeforePost(ir, ordem);
						return _post(url, item_relacionado);
					});
			}
			const all_posts = [...parent_post, ...relacionados_posts];

			Promise.all(all_posts).then(responses => {
				this.addItemToPedido(responses[0].data, null);
				for (let i = 1; i < responses.length; i++) {
					this.addItemToPedido(responses[i].data, responses[0].data.id);
				}
			}).catch(error => {
				this.setState({
					adding_item: false,
					error_adding: true,
				});
				// updateModals('error', true);
				handleRequestErrors(error);
			});
		}

		addItemNaoCadastrado = (search) => {
			this.setState({
				adding_item: true,
				error_adding: false,
			});
			// get itens to update
			const itens_store = this.defineItens();
			const { by_id, all_ids } = itens_store;
			// nome
			const pre_nome = search
				.charAt(0)
				.toUpperCase()
				+ search.slice(1);
			// ordem
			const last_item_id = all_ids[all_ids.length - 1];
			const ordem = all_ids.length > 0
				? (by_id[last_item_id].ordem || 0) + 5
				: 0;
			// constroi item
			const _item = {
				cliente_associou: false,
				incluso_em: 1,
				observacoes: '',
				ordem,
				pre_detalhe: '',
				pre_nome,
				pre_quantidade: 0,
				pre_unidade: '',
				quantidade: 0,
				unidade: '',
				outra_unidade: false,
			};
			const url = this.defineUrl();
			_post(url, _item).then(response => {
				this.addItemToPedido(response.data, null);
			}).catch(() => {
				this.setState({
					adding_item: false,
					error_adding: true,
				});
			});
		}

		associaItemNaoCadastrado = (item_id, search) => {
			this.setState({
				adding_item: true,
				error_adding: false,
			});
			const { type, updateItensRaw, autoSave } = this.props;
			// get itens to update
			const itens_store = this.defineItens();
			const { by_id, all_ids } = itens_store;
			const item = by_id[item_id] || {};
			// nome
			const pre_nome = search
				.charAt(0)
				.toUpperCase()
				+ search.slice(1);
			// remove erros
			const front = {
				...item.front,
				errors: [],
			};
			// constroi item
			const new_item = {
				...item,
				cliente_associou: false,
				incluso_em: 1,
				observacoes: '',
				pre_nome,
				pre_quantidade: 0,
				pre_unidade: '',
				quantidade: 0,
				unidade: '',
				outra_unidade: false,
				importado_planilha: 2,
				front,
			};
			const _by_id = {
				...by_id,
				[item.id]: new_item,
			};
			const itens_updated = {
				by_id: _by_id,
				all_ids,
			};
			updateItensRaw({
				[type]: itens_updated,
				adding_item: false,
			});
			autoSave(item.id);
		}

		addItemToPedido = (item, item_relacionado_id) => {
			const { type, updateItensRaw, openItemEdition } = this.props;
			// get itens to update
			const itens_store = this.defineItens();
			const { by_id, all_ids } = itens_store;
			// number
			const last_id = all_ids[all_ids.length - 1];
			const item_number = (((by_id[last_id] || {}).front || {}).key || 0) + 1;
			// build item
			const _new_item = buildNewItemV2(item_number, item, item_relacionado_id);
			const new_item = {
				..._new_item,
				adicionado: true,
			};
			const _by_id = {
				...by_id,
				[item.id]: new_item,
			};
			const _all_ids = [...all_ids, item.id];
			const itens_updated = {
				by_id: _by_id,
				all_ids: _all_ids,
			};
			updateItensRaw({
				[type]: itens_updated,
				adding_item: false,
			});
			openItemEdition(item.id);
			// scroll
			const item_elem = document.getElementById(`item-list-${item.id}`);
			if (item_elem) {
				const itens_wrapper_elem = item_elem.parentElement.parentElement;
				itens_wrapper_elem.scrollTop = itens_wrapper_elem.scrollHeight;
			}
		}

		defineItens() {
			const { type, itens } = this.props;
			return itens[type] || {};
		}

		defineUrl() {
			const { type, cotacao, compras } = this.props;
			const all_urls = {
				itens_pedido: `/conaz/v2/pedidos/${cotacao.id}/itens_de_pedido`,
				itens_compra: `/conaz/v2/compras/${compras.nova_compra.id}/itens_de_compra`,
			};
			return all_urls[type] || '';
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					getItemToAdd={this.getItemToAdd}
					addItemNaoCadastrado={this.addItemNaoCadastrado}
					associaItemSienge={this.associaItemSienge}
					associaItemNaoCadastrado={this.associaItemNaoCadastrado}
					addItemToPedido={this.addItemToPedido} />
			);
		}
	}

	PP.propTypes = {
		type: PropTypes.string.isRequired,
		user: PropTypes.object.isRequired,
		updateItensRaw: PropTypes.func.isRequired,
		autoSave: PropTypes.func,
		search: PropTypes.string,
		compras: PropTypes.object.isRequired,
		itens: PropTypes.object.isRequired,
		cotacao: PropTypes.object.isRequired,
		openItemEdition: PropTypes.func.isRequired,
	};

	PP.defaultProps = {
		autoSave: () => {},
		search: '',
	};

	const mapStateToProps = props => buildMapStateToProps(props);
	const mapDispatchToProps = dispatch => buildmapDispatchToProps(dispatch);
	return connect(mapStateToProps, mapDispatchToProps)(PP);
}

export default withAddItemFuncs;
