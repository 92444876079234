export default {
	empresas: [],
	error: null,
	isFetching: false,
	// drawer controllers
	showDrawer: false,
	dataToDrawer: null,
	drawerMode: 'new',
	// variáveis que controlam loading e modo do Drawer.
	action: '',
	actionFetching: false,
};
