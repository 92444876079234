import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// CSS
import styles from './style.module.sass';

// Components
import LoadingButton from '../../components/_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { _post } from '../../components/_functions/_requests';
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';

function Unsubscribe({ history, location }) {
	const [enviando, setEnviando] = useState(0);

	function redirectEngano() {
		history.push('/cotacoes');
	}

	function cancelaRecebimento() {
		setEnviando(1);
		const search = (location.search || '').substr(1);
		const email_location = search
			.split('&')
			.filter(x => x.indexOf('email=') !== -1)[0] || '';
		const email = email_location
			.replace('email=', '');
		const uuid_location = search
			.split('&')
			.filter(x => x.indexOf('uuid=') !== -1)[0] || '';
		const uuid = uuid_location
			.replace('uuid=', '');
		const url = '/conaz/v2/usuarios_construtor/desinscrever';
		const params = { email, uuid };
		_post(url, params)
			.then(() => {
				history.push('/cotacoes');
			})
			.catch(err => {
				history.push('/cotacoes');
				handleRequestErrors(err);
			});
	}

	return (
		<div className="container" styleName="container">
			<i className="fa fa-envelope" aria-hidden="true" />
			<div className="row">
				<div className="col-xs-12">
					<h3 styleName="title">Cancelar recebimento de e-mails da Conaz</h3>
				</div>
			</div>
			<div className="row">
				<div className="col-xs-12">
					Cancelando sua inscrição no Sistema Conaz você não será mais notificado por e-mail sobre alterações nos seus pedidos e compras.
				</div>
			</div>
			<div className="row" styleName="buttons">
				{enviando === 1 ? (
					<div className="col-xs-12">
						<button
							type="button"
							className="btn btn-secondary-conaz round-border loading"
						>
							<LoadingButton />Cancelar recebimento
						</button>
					</div>
				) : (
					<div className="col-xs-12">
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={cancelaRecebimento}
						>
							Cancelar recebimento
						</button>
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							onClick={redirectEngano}
						>
							Cliquei por engano
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

Unsubscribe.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const _Unsubscribe = CSSModules(Unsubscribe, styles, { allowMultiple: true });
const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(_Unsubscribe));
