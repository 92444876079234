import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class TipoCompra extends Component {
	static getDerivedStateFromProps(nextProps) {
		const { compra } = nextProps;
		const { tipo } = compra;
		const tipo_compra = tipo || 0;
		return { tipo_compra };
	}

	state = {
		tipo_compra: 0,
		tipo_error: false,
	};

	handleOrdemCompra = () => {
		this.handleChange(4);
	}

	handleRegistroCompra = () => {
		this.handleChange(5);
	}

	handleChange = (tipo_compra) => {
		this.setState({ tipo_compra, tipo_error: false });
		const { compra, updateComprasRaw, autoSaveStart } = this.props;
		let _data_de_decisao;
		_data_de_decisao = moment(compra.data_de_decisao).format('YYYY-MM-DD');
		if (compra.data_de_decisao === null) {
			_data_de_decisao = moment().format('YYYY-MM-DD');
		}
		const nova_compra = {
			...compra,
			tipo: tipo_compra,
			data_de_decisao: _data_de_decisao,
		};
		updateComprasRaw({ nova_compra });
		autoSaveStart();
	}

	render() {
		const { tipo_compra, tipo_error } = this.state;
		const { compra, errors } = this.props;
		const { is_editable } = compra;

		const ordem_button_class = tipo_compra === 4
			? 'radio-button-block selected'
			: 'radio-button-block';
		const registro_button_class = tipo_compra === 5
			? 'radio-button-block selected'
			: 'radio-button-block';
		const label_class = errors.tipo
			? 'error'
			: '';

		if (is_editable) {
			return (
				<div styleName="info-row">
					{tipo_error && (
						<div
							className="tooltip top in"
							role="tooltip"
							style={{ marginTop: '-34px' }}>
							<div
								className="tooltip-arrow"
								style={{
									borderTopColor: '#ee2622',
									left: '10%',
								}} />
							<div
								className="tooltip-inner"
								style={{ backgroundColor: '#ee2622' }}>
								Selecione o tipo da compra
							</div>
						</div>
					)}
					<label styleName={label_class}>
						Tipo da compra
						<span styleName="obrigatorio"> *</span>
					</label>
					<button
						type="button"
						styleName={ordem_button_class}
						onClick={this.handleOrdemCompra}>
						<div styleName="radio" />
						<div styleName="content">
							Ordem de compra
							<br />
							<span>
								(Será enviada para o fornecedor para confirmação)
							</span>
						</div>
					</button>
					<button
						type="button"
						styleName={registro_button_class}
						onClick={this.handleRegistroCompra}>
						<div styleName="radio" />
						<div styleName="content">
							Registro de compra
							<br />
							<span>
								(Não será enviada ao fornecedor, apenas registrada em seu portal)
							</span>
						</div>
					</button>
				</div>
			);
		}

		return (
			<div styleName="info-row">
				<label styleName={label_class}>Tipo da compra</label>
				{compra.tipo === 4 ? (
					<p>Ordem de compra</p>
				) : (
					<p>Registro de compra</p>
				)}
			</div>
		);
	}
}

TipoCompra.propTypes = {
	compra: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(TipoCompra, styles, { allowMultiple: true });
