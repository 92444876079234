import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const TabFornecedor = ({
	tab_number,
	title,
	description,
	selected_tab,
	changeTab,
}) => {
	const is_active = tab_number === selected_tab;
	const tab_class = is_active
		? 'tab active'
		: 'tab';

	return (
		<button
			type="button"
			styleName={tab_class}
			onClick={() => changeTab(tab_number)}
		>
			<div styleName="title">{title}</div>
			<div styleName="description">{description}</div>
		</button>
	);
};

TabFornecedor.propTypes = {
	tab_number: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	selected_tab: PropTypes.number.isRequired,
	changeTab: PropTypes.func.isRequired,
};

export default CSSModules(TabFornecedor, styles, { allowMultiple: true });
