import styled from 'styled-components';

export const Div = styled.div`
	> div {
		display: flex;
		justify-content: center;
		width: 100%;

		&:last-child {
			margin-top: 10px;
			color: #484848;
		}

		> .hover-text {
			position: absolute;
			left: 0;
			width: 100%;
			background: #fff;
			color: grey;
		}
	}
`;
