import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FileForm from './FileForm';

// Functions
import { _put } from '../../../_functions/_requests';

class FilesToUpdate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			salvando: 0,
			error: false,
			erro_conaz: '',
			arquivo_para_editar: { ...props.arquivo_para_editar },
		};
	}

	updateFile = (field, files) => {
		const arquivo_para_editar = files[this.state.arquivo_para_editar.id] || {};
		this.setState({ arquivo_para_editar });
	}

	validaInformacoes = () => {
		this.setState({ error: false });
		const { arquivo_para_editar } = this.state;
		const tipo = (arquivo_para_editar || {}).tipo || '';

		if (tipo === '') {
			this.setState({ error: true });
			return;
		}

		this.sendFile();
	}

	sendFile = () => {
		const {
			id,
			tipo,
		} = this.state.arquivo_para_editar;
		const {
			config,
			blockCloseButton,
			closeModal,
			anexos,
			updateCompras,
		} = this.props;

		blockCloseButton();
		this.setState({ salvando: 1 });
		const url = config.put_url.replace('$anexo_id', id);
		const params = { tipo };
		_put(url, params).then(() => {
			const arquivo_filtered = anexos
				.map((a, i) => ({ id: a.id, key: i }))
				.filter(x => x.id === id);
			const arquivo_key = (arquivo_filtered[0] || {}).key;
			const _anexos = [
				...anexos.slice(0, arquivo_key),
				this.state.arquivo_para_editar,
				...anexos.slice(arquivo_key + 1),
			];
			updateCompras(['compra_view', 'anexos'], _anexos);
			closeModal();
		}).catch(error => {
			blockCloseButton();
			const erro_conaz = (((error || {}).response || {}).data || {}).message || 'Aconteceu algo errado, tente novamente.';
			this.setState({ salvando: 3, erro_conaz });
		});
	}

	render() {
		const {
			salvando,
			error,
			erro_conaz,
			arquivo_para_editar,
		} = this.state;

		const arquivos_para_enviar = {
			[arquivo_para_editar.id]: { ...arquivo_para_editar },
		};
		return (
			<div styleName="all-files-wrapper">
				<FileForm
					arquivo={arquivo_para_editar}
					arquivos_para_enviar={arquivos_para_enviar}
					updateFiles={this.updateFile} />

				{error && (
					<div styleName="alert-wrapper">
						<div styleName="alert" role="alert">
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> O <b>tipo</b> dos arquivos é obrigatório.
						</div>
					</div>
				)}

				{salvando === 3 && (
					<div styleName="alert-wrapper">
						<div styleName="alert" role="alert">
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Erro! {erro_conaz}
						</div>
					</div>
				)}

				{salvando !== 1 ? (
					<button
						type="button"
						styleName="send-button"
						className="btn btn-secondary-conaz round-border"
						onClick={this.validaInformacoes}>
						Salvar
					</button>
				) : (
					<button
						type="button"
						styleName="send-button"
						className="btn btn-secondary-conaz round-border"
						style={{ opacity: '0.4' }}>
						Salvar...
					</button>
				)}
			</div>
		);
	}
}

FilesToUpdate.propTypes = {
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	arquivo_para_editar: PropTypes.shape({
		id: PropTypes.number.isRequired,
		nome: PropTypes.string.isRequired,
		tipo: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	}).isRequired,
	// updateFiles: PropTypes.func.isRequired,
	blockCloseButton: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
	anexos: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	})).isRequired,
};

export default CSSModules(FilesToUpdate, styles, { allowMultiple: true });
