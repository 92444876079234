import React from 'react';
import Proptypes from 'prop-types';

// Style
import { SearchHeader } from './Avaliacoes.style';

const AvaliacoesHeader = ({
	avaliacoes,
	search,
	handleSearch,
	inputRef,
	handleMobileSearchClick,
	isSearching,
}) => (
	<div className="header avaliacoes fixed">
		<div className="container">
			<div className="row">
				<div className="col-xs-11 col-sm-8">
					<h3 className="h3-header">Suas avaliações</h3>
					<p style={{ marginTop: '3px', fontSize: '13px', color: '#858a90' }}>Suas avaliações ficarão disponíveis para os fornecedores</p>
				</div>
				<div className="col-xs-1 col-sm-4">
					<SearchHeader>
						{avaliacoes.fetching === 10
							? <i className={`fa fa-spinner fa-spin ${isSearching && 'showm'}`} />
							: <i className={`fa fa-search ${isSearching && 'showm'}`} />}
						<button
							className={`${!isSearching && 'showm'}`}
							onClick={handleMobileSearchClick}>
							<i className="fa fa-search" />
						</button>
						<input
							ref={inputRef}
							className="search-input"
							value={search}
							onChange={handleSearch}
							onBlur={handleMobileSearchClick}
							placeholder="Buscar fornecedores"
						/>
					</SearchHeader>
				</div>
			</div>
		</div>
	</div>
);

AvaliacoesHeader.propTypes = {
	avaliacoes: Proptypes.object.isRequired,
	inputRef: Proptypes.object.isRequired,
	search: Proptypes.string.isRequired,
	isSearching: Proptypes.bool.isRequired,
	handleSearch: Proptypes.func.isRequired,
	handleMobileSearchClick: Proptypes.func.isRequired,
};

export default AvaliacoesHeader;
