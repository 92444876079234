import * as Types from './types';
import INITIAL_STATE from './store';

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.EXPORT_REQUEST:
		return {
			...state,
			isFetching: true,
			data: null,
			error: null,
			status: '',
		};
	case Types.EXPORT_SUCCESS:
		return {
			...state,
			isFetching: false,
			data: action.payload.data,
			error: null,
			status: '',
		};
	case Types.EXPORT_ERROR:
		return {
			...state,
			isFetching: false,
			data: null,
			error: action.payload.error,
			status: '',
		};
	case Types.EXPORT_STATUS_REQUEST:
		return {
			...state,
			isFetching: true,
			data: action.payload.data,
			error: null,
			status: action.payload.status,
		};
	case Types.EXPORT_STATUS_SUCCESS:
		return {
			...state,
			isFetching: false,
			data: action.payload.data,
			error: null,
			status: action.payload.data.job_status,
			passo_atual_status: action.payload.data.passo_atual_status,
			passo_atual_tipo: action.payload.data.passo_atual_tipo,
		};
	case Types.EXPORT_STATUS_ERROR:
		return {
			...state,
			isFetching: false,
			data: null,
			error: action.payload.erro_motivo,
			status: '',
		};
	default:
		return state;
	}
}
