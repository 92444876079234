import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const AprovacaoSiengeActions = () => (
	<div styleName="alerts-messages-wrapper">
		<div styleName="am-text full">
			<p>A compra será aprovada automaticamente na Conaz assim que o processo de aprovação for realizado no Sienge.</p>
		</div>
	</div>
);

export default CSSModules(AprovacaoSiengeActions, styles, { allowMultiple: true });
