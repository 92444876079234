import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ButtonRound from '../../../../../../components/_parts/_buttons/ButtonRound/ButtonRound';
import IIStatusView from './IIStatusView';
import CNPJFornecedorSienge from '../../../../../../components/_parts/CNPJFornecedorSienge';

const InfoExpanded = ({
	fornecedor_integrado,
	empty_fornecedor,
	handleDesassociacao,
}) => {
	const {
		id_no_swi,
		nome,
		nome_fantasia,
		documento,
	} = fornecedor_integrado;

	return (
		<>
			<div styleName="title">
				Fornecedor Sienge{' '}
				<ButtonRound
					size="small"
					icon="fa fa-exchange"
					tooltip_text="Remover fornecedor associado"
					left="-70%"
					onClick={handleDesassociacao} />
			</div>
			<div styleName="code">
				<span>Código Sienge:</span>
				<p>{id_no_swi}</p>
			</div>
			<div styleName="code">
				<span>Nome:</span>
				<div styleName="name">{nome}</div>
			</div>
			<div styleName="cnpj">
				<span>CNPJ:</span>
				<div styleName="name">
					<CNPJFornecedorSienge documento={documento} />
				</div>
			</div>
			<div styleName="code">
				<span>Razão Social:</span>
				<div styleName="name">{nome_fantasia}</div>
			</div>
			{empty_fornecedor && (<IIStatusView
				fornecedor_integrado={fornecedor_integrado}
				empty_fornecedor={empty_fornecedor} />)}
		</>
	);
};

InfoExpanded.propTypes = {
	fornecedor_integrado: PropTypes.object.isRequired,
	handleDesassociacao: PropTypes.func.isRequired,
	empty_fornecedor: PropTypes.bool.isRequired,
};

export default CSSModules(InfoExpanded, styles, { allowMultiple: true });
