import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputMultipleRCs from './OpenSelect/InputMultipleRCs';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { _get, _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

class CombinacaoPage2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			criando: 0,
			ocs_selecionados: [],
		};
		this.selectOC = this.selectOC.bind(this);
		this.geraOpcaoCombinacao = this.geraOpcaoCombinacao.bind(this);
	}

	selectOC(ocs_selecionados) {
		this.setState({ ocs_selecionados });
	}

	geraOpcaoCombinacao() {
		this.setState({ criando: 1 });
		const { ocs_selecionados } = this.state;
		const { opcoes_de_compra, pedido_id } = this.props;
		/**
		 * Pega RC's selecionados
		 */
		const { all_ids, by_id } = opcoes_de_compra;
		const apenas_rcs = all_ids
			.filter(oc_id => by_id[oc_id].rcs_analise.length === 1);
		const rcs_selecionados = apenas_rcs
			.filter(oc_id => ocs_selecionados.filter(x => x === oc_id).length !== 0)
			.map(oc_id => by_id[oc_id].rcs_analise[0].rc.uuid);
		const rcs_ignorados = apenas_rcs
			.filter(oc_id => ocs_selecionados.filter(x => x === oc_id).length === 0)
			.map(oc_id => by_id[oc_id].rcs_analise[0].rc.uuid)
			.join(',');
		const params = {
			rcs_ignorados,
			personalizada: 1,
		};
		_get(`/conaz/v2/analises/gerar_combinacao/${pedido_id}`, params).then(response => {
			const combinacao = response.data.length > 0
				? response.data[0]
				: {};
			this.criaNovaCombinacao(rcs_selecionados, combinacao);
		}).catch(error => {
			this.setState({ criando: 3 });
			handleRequestErrors(error);
		});
	}

	criaNovaCombinacao(rcs, combinacao) {
		const { analise_id, history, pedido_id } = this.props;
		const itens_de_resposta_ids = combinacao.itens_de_resposta_ids || [];
		const params = {
			rcs_ids: rcs,
			itens_de_resposta_ids,
			rascunho: true,
		};
		_post(`/conaz/v2/analises/${analise_id}/opcoes_compra`, params).then(response => {
			const opcoes_de_compra_id = response.data.id;
			history.push(`/cotacao/${pedido_id}/qc/${opcoes_de_compra_id}`);
		}).catch(error => {
			this.setState({ criando: 3 });
			handleRequestErrors(error);
		});
	}

	render() {
		const {
			criando,
			ocs_selecionados,
		} = this.state;
		const {
			opcoes_de_compra,
		} = this.props;

		return (
			<div styleName="bottom-part" id="combinacao2">
				<p>Visualize e crie combinações manipulando os fornecedores e itens que você desejar.</p>
				<label style={{ margin: '12px 0px 16px' }}>Selecione Fornecedores para a sua combinação:</label>

				<InputMultipleRCs
					opcoes_de_compra={opcoes_de_compra}
					selectOC={this.selectOC} />

				<hr style={{ marginBottom: '17px' }} />

				{criando === 3 && (
					<div className="alert alert-danger" role="alert">
						<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Ops, aconteceu algo errado.
					</div>
				)}

				{(criando !== 1 && ocs_selecionados.length >= 2) && (
					<button
						type="button"
						className="btn btn-secondary-conaz round-border"
						style={{ marginTop: '6px' }}
						onClick={this.geraOpcaoCombinacao}>
						Combinar Itens
					</button>
				)}
				{(criando !== 1 && ocs_selecionados.length < 2) && (
					<button
						type="button"
						className="btn btn-secondary-conaz round-border"
						style={{ marginTop: '6px', opacity: '0.3' }}>
						Combinar Itens
					</button>
				)}
				{criando === 1 && (
					<button
						type="button"
						className="btn btn-secondary-conaz round-border loading"
						style={{ marginTop: '6px' }}>
						<LoadingButton />Combinar Itens
					</button>
				)}
			</div>
		);
	}
}

CombinacaoPage2.propTypes = {
	opcoes_de_compra: PropTypes.object.isRequired,
	analise_id: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	pedido_id: PropTypes.string.isRequired,
};

export default CSSModules(CombinacaoPage2, styles, { allowMultiple: true });
