import styled from 'styled-components';

import { Table } from 'antd';

export const StyledTable = styled(Table)`
	th {
		background: transparent !important;
		color: #595959 !important;
		font-size: 12px;
		line-height: 16px;
	}

	td {
		color: #595959;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 1px 0px #D9D9D9;
	}
`;
