import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Lib
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
import Alert2 from '../../Alerts/Alert2';

// Components
import FileForm from './FileForm';
import FileSending from './FileSending';

class FilesToSend extends Component {
	constructor() {
		super();
		this.state = {
			enviar: false,
			error: false,
			envios_finalizados: false,
			modalErrorFeedback: false,
		};
	}

	validaInformacoes = () => {
		this.setState({ error: false });
		const { arquivos_para_enviar } = this.props;
		const ids = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp)
			.filter(id_temp => !arquivos_para_enviar[id_temp].size_error);

		const size_error = _.some(arquivos_para_enviar, { size_error: true });

		if (size_error) {
			this.setState({ modalErrorFeedback: true });
			return;
		}
		this.setState({ modalErrorFeedback: false });


		this.setState({ modalErrorFeedback: false });

		for (let i = 0; i < ids.length; i++) {
			const nome = (arquivos_para_enviar[ids[i]] || {}).nome || '';
			const tipo = (arquivos_para_enviar[ids[i]] || {}).tipo || '';

			if (nome === '' || tipo === '') {
				this.setState({ error: true });
				return;
			}
		}

		this.sendFiles();
	}

	setEnviar = () => {
		this.setState({ enviar: false, envios_finalizados: false });
	}

	continuousUploadFiles = () => {
		const { updateFiles, updateEnviandoStatus } = this.props;
		const { envios_finalizados } = this.state;
		if (!envios_finalizados) return;
		updateEnviandoStatus();
		const arquivos_para_enviar = {};
		updateFiles('arquivos_para_enviar', arquivos_para_enviar);
		this.setEnviar();
	}

	continuousUploadFilesBefore = () => {
		// eslint-disable-next-line no-unused-vars
		const { updateFiles, arquivos_para_enviar, updateEnviarMais } = this.props;
		// updateFiles('arquivos_para_enviar', arquivos_para_enviar);
		updateEnviarMais();
	}

	sendFiles = () => {
		const { blockCloseButton, updateUi } = this.props;
		blockCloseButton();
		updateUi(['changes', 'compras'], true);
		this.setState({ enviar: true });
		this.interval_success_check = window.setInterval(() => {
			this.checkSuccess();
		}, 800);
	}

	checkSuccess = () => {
		let all_success = true;
		let errors = false;

		const { arquivos_para_enviar, anexos, updateCompras } = this.props;

		const ids = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp)
			.filter(id_temp => !arquivos_para_enviar[id_temp].size_error);

		for (let i = 0; i < ids.length; i++) {
			const { success } = (arquivos_para_enviar[ids[i]] || {});
			if (success === 0) {
				all_success = false;
			}
			if (success === 2) {
				errors = true;
			}
		}

		if (all_success && errors) {
			window.clearTimeout(this.interval_success_check);
			this.setState({ envios_finalizados: true });
			this.props.blockCloseButton();
			const novos_arquivos = Object.keys(arquivos_para_enviar)
				.map(id_temp => {
					const arquivo = { ...arquivos_para_enviar[id_temp] };
					delete arquivo.file;
					return arquivo;
				})
				.filter(file => file.success === 1);
			const _anexos = [
				...anexos,
				...novos_arquivos,
			];
			updateCompras(['nova_compra', 'anexos'], _anexos);
			return;
		}

		if (all_success) {
			window.clearTimeout(this.interval_success_check);
			const novos_arquivos = Object.keys(arquivos_para_enviar)
				.map(id_temp => {
					const arquivo = { ...arquivos_para_enviar[id_temp] };
					delete arquivo.file;
					return arquivo;
				})
				.filter(file => file.success === 1);
			const _anexos = [
				...anexos,
				...novos_arquivos,
			];
			updateCompras(['nova_compra', 'anexos'], _anexos);
			this.setState({ envios_finalizados: true });
		}
	}

	updateErrorSize = () => this.setState({ modalErrorFeedback: false });

	render() {
		const {
			enviar,
			error,
			envios_finalizados,
			modalErrorFeedback,
		} = this.state;
		const {
			config,
			arquivos_para_enviar,
			updateFiles,
			closeModal,
			updateCompras,
			updateUi,
			anexos,
		} = this.props;
		const ids_list = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp);
		const ids_to_send = Object.keys(arquivos_para_enviar)
			.map(id_temp => id_temp)
			.filter(id_temp => !arquivos_para_enviar[id_temp].size_error);
		const list_to_map = !enviar
			? [...ids_list]
			: [...ids_to_send];

		return (
			<>
				{modalErrorFeedback && (
					<Alert2
						color="red"
						mensagem="Você adicionou arquivos maiores do que o permitido (32mb), remova para prosseguir" />
				)}
				<div styleName="all-files-wrapper">
					{list_to_map.map(id => {
						if (!enviar) {
							return (
								<FileForm
									{...{
										config,
										updateFiles,
										arquivos_para_enviar,
										error,
									}}
									updateErrorSize={{
										modalErrorFeedback,
										updateErrorSize: this.updateErrorSize,
									}}
									idFile={id}
									key={id}
									arquivos_para_enviar={arquivos_para_enviar}
									arquivo={arquivos_para_enviar[id]} />
							);
						}

						return (
							<FileSending
								{...{
									config,
									arquivos_para_enviar,
									updateFiles,
									updateCompras,
									anexos,
									closeModal,
									updateUi,
									envios_finalizados,
									list_to_map,
								}}
								tryAgainFunction={this.setEnviar}
								key={id}
								arquivo={arquivos_para_enviar[id]} />
						);
					})}

					{(!enviar && !envios_finalizados) && (
						<>
							<button
								type="button"
								styleName="send-button add"
								className="btn btn-secondary-conaz round-border"
								onClick={this.validaInformacoes}>
								Adicionar
							</button>
							<button
								type="button"
								className="btn btn-conaz round-border"
								styleName="continue-adding before"
								onClick={this.continuousUploadFilesBefore}>
								Continuar adicionando
							</button>
						</>
					)}
					{(enviar && !envios_finalizados) && (
						<button
							type="button"
							styleName="send-button adding"
							className="btn btn-secondary-conaz round-border"
							style={{ opacity: '0.4' }}>
							Adicionando...
						</button>
					)}
					{envios_finalizados && (
						<>
							<button
								type="button"
								styleName="send-button"
								className="btn btn-secondary-conaz round-border"
								onClick={closeModal}>
								Fechar
							</button>
							<button
								type="button"
								className="btn btn-conaz round-border"
								styleName="continue-adding"
								onClick={this.continuousUploadFiles}>
								Continuar adicionando
							</button>
						</>
					)}
				</div>
			</>
		);
	}
}

FilesToSend.propTypes = {
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	updateEnviarMais: PropTypes.func.isRequired,
	arquivos_para_enviar: PropTypes.objectOf(PropTypes.shape({
		id: PropTypes.number,
		id_temp: PropTypes.number,
		nome: PropTypes.string,
		file: PropTypes.object,
		tipo: PropTypes.string,
		tamanho: PropTypes.number,
		success: PropTypes.number,
		size_error: PropTypes.bool,
		error_message: PropTypes.string,
	})).isRequired,
	updateEnviandoStatus: PropTypes.func.isRequired,
	updateFiles: PropTypes.func.isRequired,
	blockCloseButton: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
	anexos: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	})).isRequired,
	updateUi: PropTypes.func.isRequired,
};

export default CSSModules(FilesToSend, styles, { allowMultiple: true });
