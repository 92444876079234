/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import lodash from 'lodash';

// Components
import { Button } from 'antd';
import DrawerObras from 'components/DrawerObras';
import DrawerEmpresa from 'components/DrawerEmpresas';
import DrawerUsers from 'components/DrawerUser';

// Redux HOC
import { connect } from 'react-redux';
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';
// Layout
import LayoutWrapper from '../../components/LayoutWrapper';
// Estilos
import styles from './style.module.scss';
import { StyledTable } from './styled';

// Parte da tabela
import columns from './columns';
import tableLocale from './locale';

function TabelaObras({ ...props }) {
	const history = useHistory();
	const [loadingSearch] = useState(false);
	const [filterTxt, setFilterTxt] = useState('');

	const [usuarioAdministrador, setUsuarioAdministrador] = useState(localStorage.getItem('usuario_admin') || null);
	const [planContratado, setPlanContratado] = useState(window.innerWidth < 600);
	const [planoObra, setPlanoObra] = useState(null);
	const [qtdObras, setQtdObras] = useState(0);

	const userPermission = 'admin';
	const maxUserPerPage = 10;

	const {
		getObras,
		getUser,
		openObraEditDrawer,
		openNewObraDrawer,
		closeObraDrawer,
		obrasDrawer,
		obras,
		empresas,
		usuarios,
		createEmpresa,
		createUser,
		closeEmpresaDrawer,
		closeUserDrawer,
		alteraStatusObrasAtivo,
		alteraStatusObrasVisualizacao,
	} = props;

	const onSearch = (e) => {
		const { value } = e.target;
		setTimeout(() => {
			setFilterTxt(value);
		}, 400);
	};

	const filterObras = (txt) => {
		if (!txt) return obras.obras;
		const lowerTxt = txt.toLowerCase();
		return obras.obras.filter((ob) => {
			if (ob.nome.toLowerCase().includes(lowerTxt)) return true;
			if (ob.cliente?.apelido_interno.toLowerCase().includes(lowerTxt)
				|| ob.cliente.nome?.toLowerCase().includes(lowerTxt)) return true;
			if (ob.unidades_contrutivas?.fase_atual.toLowerCase().includes(lowerTxt)) return true;
			if (ob.pessoas?.[0]?.pessoa?.nome?.toLowerCase().includes(lowerTxt)) return true;
			return false;
		});
	};

	const filteredObras = filterObras(filterTxt);

	const handleCreateEmpresa = (data) => {
		createEmpresa(data);
	};

	const handleCreateUser = (data) => {
		createUser(data);
	};

	const alteraStatusObra = (obra) => {
		if (obra && obra.status === 30) alteraStatusObrasAtivo(obra.id, obra);

		if (obra && obra.status === 20) alteraStatusObrasVisualizacao(obra.id, obra);
	};

	const columnsActions = (mode, record) => {
		if (mode === 'edit') openObraEditDrawer(record);

		if (mode === 'view') history.push(`/cadastros/obras/${record.id}`);
	};

	useEffect(() => {
		const obrasInfo = obras.obras
			.filter(item => item.id !== '')
			.reduce((result, current) => ({
				...result,
				[current.cliente.id]: [
					...new Set(result[current.cliente.quantidade_obras] || []),
					current.cliente,
				],
			}), {});

	const ids_to_get = Object.values(obrasInfo)
		.map(item => item[0]);
	const obtemQuantidadeObras = ids_to_get.map(item => item.limite_de_obras);
	setQtdObras(lodash.sum(obtemQuantidadeObras));

	const obtemPlanoObra = ids_to_get.map(item => item.plano);
	setPlanoObra(obtemPlanoObra.shift(item => item[0]));
	}, [qtdObras, obras.obras]);

	const _resize = () => {
		setPlanContratado(window.innerWidth < 600);
	};

	useEffect(() => {
		window.addEventListener('resize', _resize);
	}, [planContratado]);

	useEffect(() => {
		document.title = 'Cadastro - Obras';
		getObras();
		getUser();
	}, [getObras, getUser]);

	useEffect(() => {
		if ((!localStorage.getItem('usuario_admin') || localStorage.getItem('usuario_admin') === false) && localStorage.getItem('usuario_admin') !== true) {
			setUsuarioAdministrador(false);
		}
	}, [usuarioAdministrador]);

	return (
		<LayoutWrapper {...props}>
			<>
				<DrawerObras
					mode={obrasDrawer.drawerMode}
					onClose={closeObraDrawer}
					visible={obrasDrawer.showDrawer}
				/>
				<DrawerUsers
					mode="new"
					width={1060}
					onClose={closeUserDrawer}
					visible={usuarios.showDrawer}
					onSubmit={handleCreateUser}
					loading={usuarios.actionFetching}
				/>
				<DrawerEmpresa
					mode="new"
					onClose={closeEmpresaDrawer}
					visible={empresas.showDrawer}
					onSubmit={handleCreateEmpresa}
					loading={empresas.actionFetching}
				/>
				<div styleName="header">
					<div styleName="header-title">
						Obras
					</div>

					<div styleName="header-right-side" style={planContratado ? { marginLeft: '2rem' } : {}}>
						{(planoObra && planoObra !== null) && (
						<div styleName="header-plans">
								{planoObra !== null && 'Plano'} {planoObra === 0 ? 'LIGHT /' :
										planoObra === 1 ? 'BASIC /' :
										planoObra === 2 ? 'PRO /' :
										planoObra === 3 ? 'ENTERPRISE /' :
										planoObra === 4 ? 'KEY_ACCOUNT /' :
										'' }
								{' '} {qtdObras || ''} Obras contratadas
						</div>
						)}

						<Button
							type="primary"
							shape="round"
							size="large"
							styleName="add-obras-btn"
							style={{ height: '32px', backgroundColor: `${usuarioAdministrador === false ? '#eee' : ''}`, display: (usuarioAdministrador === false ? 'none' : '') }}
							onClick={openNewObraDrawer}
						>
							Nova Obra
						</Button>
					</div>
				</div>

				<div styleName="search-area">
					<div styleName="search-input-wrapper">
						<input
							onChange={onSearch}
							styleName="search-input"
							placeholder="Buscar por nome ou palavra chave"
						/>
						<i
							styleName="search-input-icon"
							className={!loadingSearch ? 'fa fa-search' : 'fa fa-spin fa-spinner'}
							aria-hidden="true"
						/>
					</div>
				</div>

				<div styleName="table-space">
					<StyledTable
						rowKey="id"
						columns={columns(userPermission, columnsActions, alteraStatusObra, usuarioAdministrador)}
						dataSource={filteredObras}
						pagination={{ pageSize: maxUserPerPage, hideOnSinglePage: true }}
						locale={tableLocale}
						size="small"
						loading={obras.isFetching || obras.actionFetching}
					/>
				</div>
			</>
		</LayoutWrapper>
	);
}

TabelaObras.propTypes = {
	props: PropTypes.shape({}),
	obrasDrawer: PropTypes.shape({
		drawerMode: PropTypes.string,
		showDrawer: PropTypes.bool,
	}),
	obras: PropTypes.shape({
		obras: PropTypes.arrayOf(PropTypes.shape({ })),
		cliente: PropTypes.arrayOf(PropTypes.shape({ })),
		isFetching: PropTypes.bool,
		actionFetching: PropTypes.bool,
	}),
	usuarios: PropTypes.shape({
		showDrawer: PropTypes.bool,
		actionFetching: PropTypes.bool,
	}),
	empresas: PropTypes.shape({
		actionFetching: PropTypes.bool,
		showDrawer: PropTypes.bool,
	}),
	createEmpresa: PropTypes.func,
	createUser: PropTypes.func,
	getObras: PropTypes.func,
	getUser: PropTypes.func,
	openNewObraDrawer: PropTypes.func,
	openObraEditDrawer: PropTypes.func,
	closeObraDrawer: PropTypes.func,
	closeUserDrawer: PropTypes.func,
	closeEmpresaDrawer: PropTypes.func,
	alteraStatusObrasAtivo: PropTypes.func,
	alteraStatusObrasVisualizacao: PropTypes.func,
};

TabelaObras.defaultProps = {
	props: {},
	obras: {},
	obrasDrawer: {},
	empresas: {},
	usuarios: {},
	closeUserDrawer: () => {},
	closeObraDrawer: () => {},
	createEmpresa: () => {},
	createUser: () => {},
	getObras: () => {},
	getUser: () => {},
	openNewObraDrawer: () => {},
	openObraEditDrawer: () => {},
	closeEmpresaDrawer: () => {},
	alteraStatusObrasAtivo: () => {},
	alteraStatusObrasVisualizacao: () => {},
};

const WithStyles = CSSModules(TabelaObras, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));

