// import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

// const Tab = () => {
// 	const loading = useState(false);
// 	const current_tab = useState('');


// 	return (<div />);
// };

const Tab = ({ tab, handleChangeTab, cotacoes }) => {
	// tabs classes and total numbers
	const em_andamento_class = (tab === '' || tab === 'em_andamento')
		? 'header-tab-item active' : 'header-tab-item';
	const em_andamento_total = cotacoes.em_andamento.page.total;
	const finalizadas_class = tab === 'finalizados' ? 'header-tab-item active' : 'header-tab-item';
	const finalizadas_total = cotacoes.finalizados.page.total;
	const canceladas_class = tab === 'cancelados' ? 'header-tab-item active' : 'header-tab-item';
	const canceladas_total = cotacoes.cancelados.page.total;
	const rascunhos_class = tab === 'em_rascunho' ? 'header-tab-item active' : 'header-tab-item';
	const rascunhos_total = cotacoes.em_rascunho.page.total;

	return (
		<div className="row">
			<div className="col-xs-12 header-tab-wrapper">
				<button
					type="button"
					className={em_andamento_class}
					data-tab="em_andamento"
					onClick={handleChangeTab}>
					Em andamento{' '}
					<span data-tab="em_andamento">{em_andamento_total}</span>
				</button>
				<button
					type="button"
					className={finalizadas_class}
					data-tab="finalizados"
					onClick={handleChangeTab}>
					Finalizadas{' '}
					<span data-tab="finalizados">{finalizadas_total}</span>
				</button>
				<button
					type="button"
					className={canceladas_class}
					data-tab="cancelados"
					onClick={handleChangeTab}>
					Canceladas{' '}
					<span data-tab="cancelados">{canceladas_total}</span>
				</button>
				<div className="header-tab-item-division" />
				<button
					type="button"
					className={rascunhos_class}
					data-tab="em_rascunho"
					onClick={handleChangeTab}>
					Rascunhos{' '}
					<span data-tab="em_rascunho">{rascunhos_total}</span>
				</button>
			</div>
		</div>
	);
};

Tab.propTypes = {
	tab: PropTypes.string.isRequired,
	cotacoes: PropTypes.object.isRequired,
	handleChangeTab: PropTypes.func.isRequired,
};

export default Tab;
