export const isGeneralError = (error) => {
	// canceled request
	const canceled_request = error.message === 'canceled_request';
	if (canceled_request) return true;

	// 403 deslogado
	const status = (error.response || {}).status || null;
	if (status === 403) return true;

	return false;
};

export const handleGeneralErrors = (error) => {
	// canceled request
	const canceled_request = error.message === 'canceled_request';
	if (canceled_request) return;

	// 403 deslogado
	const status = (error.response || {}).status || null;
	if (status === 403) {
		window.location.replace(`${window.location.origin}/sair`);
	}
};
