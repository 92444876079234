import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { _post_exports } from '../../../../components/_functions/_requests';

// Components
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

class ModalExportaCompras extends Component {
	constructor(props) {
		super(props);

		this.state = {
			exportando: 1,
			link: '',
			error: '',
		};

		_post_exports(`/v2/compras/${props.compra_id}/gerar_oc_construtor`, {})
			.then(({ data }) => this.setState({
				exportando: 2,
				link: data.message,
			}))
			.catch(err => {
				const {
					status,
					data: { message },
				} = err.response;

				if (status === 404 && props.link !== '') {
					this.setState({ exportando: 2, link: props.link });
				} else {
					this.setState({
						exportando: 3,
						error: status === 500 ? '' : message,
					});
				}
			});
	}

	render() {
		const { exportando, error, link } = this.state;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.props.closeModal}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="icon">
						<img src="/images/excel-icon.png" width="84" alt="" />
					</div>
					<div styleName="content">
						<h3>Compra em Excel</h3>
						{exportando === 3 && (
							<>
								<br />
								<div
									className="alert alert-danger"
									role="alert">
									<i
										className="fa fa-exclamation-triangle fa-fw"
										aria-hidden="true" />{' '}
									{error ||
										`Desculpe, aconteceu algo errado. Tente
									novamente.`}
								</div>
							</>
						)}
						{exportando === 0 && (
							<a
								className="btn btn-secondary-conaz round-border"
								rel="noopener noreferrer"
								target="_blank"
								href={link}>
								Baixar planilha
							</a>
						)}
						{exportando === 1 && (
							<>
								<p>Sua planilha esta sendo preparada!</p>
								<a
									href={link}
									rel="noopener noreferrer"
									target="_blank"
									className="btn btn-secondary-conaz round-border loading">
									<LoadingButton />
									Baixar planilha
								</a>
							</>
						)}
						{exportando === 2 && (
							<a
								type="button"
								rel="noopener noreferrer"
								target="_blank"
								href={link}
								className="btn btn-secondary-conaz round-border">
								Baixar planilha
							</a>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalExportaCompras.propTypes = {
	closeModal: PropTypes.func.isRequired,
	compra_id: PropTypes.number.isRequired,
	link: PropTypes.string,
};

ModalExportaCompras.defaultProps = {
	link: '',
};

export default CSSModules(ModalExportaCompras, styles, { allowMultiple: true });
