import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
	Row,
	Col,
	Form,
	Input,
	Select,
	Upload,
	message,
} from 'antd';
import InputMask from 'react-input-mask';

import {
	LoadingOutlined,
	PlusOutlined,
} from '@ant-design/icons';

import { validations } from 'ambar-ui';
import validateCNPJ from './validCNPJ';

const { validateRequired } = validations;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

const UploadButton = ({ loading }) => (
	<div>
		{loading ? <LoadingOutlined /> : <PlusOutlined />}
		<div stlye={{ marginTop: 10 }}>
			Anexar
		</div>
	</div>
);

function UpperPart({ disableAll, form }) {
	const [previewImg, setPreviewImg] = useState(null);
	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('Somente arquivos .jpeg, .jpg ou .png');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Arquivo deve ser menor que 2mb!');
		}
		// retornando false para skipar o upload interno do <Upload />
		return false;
	}

	const getValue = (e) => {
		if (Array.isArray(e)) return e;
		return e && e.file;
	};

	const handleFile = async (e) => {
		const { file } = e;
		const f = await getBase64(file);
		setPreviewImg(f);
	};

	useEffect(() => {
		(async () => {
			const logo_url = form.getFieldValue('logo_url');
			if (!logo_url) return;
			if (typeof logo_url === 'string') setPreviewImg(logo_url);
			else setPreviewImg(await getBase64(logo_url));
		})();
	}, [form]);

	return (
		<Row gutter={12}>
			<Col span={5}>
				<div className="logo-uploader">
					<Form.Item name="logo_url" label="Logo" getValueFromEvent={getValue} valuePropName="fieldList">
						<Upload
							listType="picture-card"
							name="perfil"
							accept="image/png,image/jpg,image/jpeg"
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleFile}
							disabled={disableAll}
						>
							{previewImg ? <img src={previewImg} alt="avatar" style={{ width: '100%' }} /> : <UploadButton loading={false} />}
						</Upload>
					</Form.Item>
					<span className="logo-uploader-under-text">
						Apenas imagens em .png, .jpeg ou .jpg
					</span>
				</div>
			</Col>
			<Col span={19}>
				<Form.Item name="nome" label="Nome fantasia da empresa" rules={[validateRequired()]}>
					<Input disabled={disableAll} />
				</Form.Item>
				<Row gutter={24}>
					<Col span={18}>
						<Form.Item name="razao_social" label="Razão Social" rules={[validateRequired()]}>
							<Input disabled={disableAll} />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item name="documento" label="CNPJ" rules={[validateRequired(), validateCNPJ()]}>
							<InputMask mask="99.999.999/9999-99" placeholder="00.000.000/0000-00" disabled={disableAll}>
								{(props) => <Input disabled={disableAll} {...props} />}
							</InputMask>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={24}>
					<Col span={6}>
						<Form.Item name="contribuinte_icms" label="Contribuinte do ICMS" rules={[validateRequired()]}>
							<Select placeholder="Selecione" disabled={disableAll}>
								<Select.Option value="sim"> Sim </Select.Option>
								<Select.Option value="nao"> Não </Select.Option>
								<Select.Option value="isento"> Isento </Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={18}>
						<Form.Item name="inscricao_estadual" label="Inscrição Estadual">
							<Input disabled={disableAll} />
						</Form.Item>
					</Col>

				</Row>
			</Col>
		</Row>
	);
}

UploadButton.propTypes = {
	loading: PropTypes.bool,
};

UploadButton.defaultProps = {
	loading: false,
};

UpperPart.propTypes = {
	disableAll: PropTypes.bool,
	form: PropTypes.shape({
		getFieldValue: PropTypes.func,
	}),
};

UpperPart.defaultProps = {
	disableAll: false,
	form: {},
};

export default UpperPart;
