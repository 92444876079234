import INITIAL_STATE from './store';
import * as Types from './types';

import { mergeEmpresasAssociadas } from './utils';

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.OBRAS_GET_LIST_REQUEST:
		return {
			...state,
			isFetching: true,
		};
	case Types.OBRAS_GET_LIST_SUCCESS:
		return {
			...state,
			obras: action.payload.data,
			isFetching: false,
			error: false,
		};
	case Types.OBRAS_GET_LIST_ERROR:
		return {
			...state,
			isFetching: false,
			error: false,
		};
	case Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_REQUEST:
	case Types.OBRAS_CREATE_REQUEST:
	case Types.OBRAS_DELETE_REQUEST:
	case Types.OBRAS_EDIT_REQUEST:
	case Types.OBRAS_BLOCK_REQUEST:
	case Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_REQUEST:
	case Types.ASSOCIA_OBRAS_STATUS_VISUALIZACAO:
	case Types.ASSOCIA_OBRAS_STATUS_ATIVO:
		return {
			...state,
			actionFetching: true,
		};
	case Types.OBRAS_CREATE_SUCCESS:
		return {
			...state,
			obras: [...state.obras, action.payload.data],
			actionFetching: false,
			actionError: false,
		};
	case Types.OBRAS_BLOCK_SUCCESS:
		return {
			...state,
			actionFetching: false,
			actionError: false,
			obras: state.obras.map((ob) => {
				if (ob.id === action.payload.id) {
					return {
						...ob,
						desativada: action.payload.block,
					};
				}
				return ob;
			}),
		};
	case Types.OBRAS_DELETE_SUCCESS:
		return {
			...state,
			actionFetching: false,
			actionError: false,
			obras: state.obras.filter((ob) => ob.id !== action.payload.id),
		};
	case Types.OBRAS_EDIT_SUCCESS:
		return {
			...state,
			actionFetching: false,
			actionError: false,
			obras: state.obras.map((ob) => {
				if (ob.id === action.payload.data.id) {
					return action.payload.data;
				}
				return ob;
			}),
		};
	case Types.OBRAS_SUBMIT_ASSOCIA_EMPRESA_SUCCESS:
		return {
			...state,
			actionFetching: false,
			obras: mergeEmpresasAssociadas(state.obras, action.payload.obra_id, action.payload.data),
		};
	case Types.OBRAS_SUBMIT_ASSOCIA_USUARIO_SUCCESS:
		return {
			...state,
			actionFetching: false,
			obras: state.obras.map((ob) => {
				if (ob.id === action.payload.obra_id) {
					return {
						...ob,
						usuarios: [...ob.usuarios, action.payload.usuario],
					};
				}
				return ob;
			}),
		};
	case Types.OBRAS_ACTION_ERROR:
		return {
			...state,
			actionFetching: false,
			actionError: action.payload.error_motivo,
		};

	default:
		return state;
	}
}
