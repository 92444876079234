function myIsNaN(o) {
	return o !== o; // eslint-disable-line
}

const isNumeric = value => !myIsNaN(value - parseFloat(value));
// const isNumeric = value => !Number.isNaN(value - parseFloat(value));

export const searchTypeFornecedores = typed => {
	const _typed = typed.replace(/\.|,|-|\//g, '');
	if (isNumeric(_typed)) return ['documento', _typed];
	if (typed.indexOf('@') !== -1) return ['email', typed];
	return ['nome', typed];
};
