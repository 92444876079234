import {
	UPDATE_COTACOES,
	UPDATE_COTACOES_RAW,
	UPDATE_COTACOES_LIST,
	COTACOES_REQUESTED,
	COTACOES_INITIAL_REQUESTED,
} from './types';

export const updateCotacoes = (field, value) => ({ type: UPDATE_COTACOES, field, value });
export const updateCotacoesRaw = (fields_n_values) => ({ type: UPDATE_COTACOES_RAW, fields_n_values });
export const updateCotacoesList = (by_id, all_ids, ativas, finalizadas, canceladas, rascunhos) => ({
	type: UPDATE_COTACOES_LIST,
	by_id,
	all_ids,
	ativas,
	finalizadas,
	canceladas,
	rascunhos,
});
export const getInitialCotacoes = (obra_id, tab = null) => ({
	type: COTACOES_INITIAL_REQUESTED,
	tab,
	obra_id,
});

export const getCotacoes = (obra_id, tab, page) => ({
	type: COTACOES_REQUESTED,
	obra_id,
	tab,
	page,
});
