import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IIStatusView from './IIStatusView';

const InfoCompacted = ({ empty_item, item_on_edition }) => {
	const item_integrado = item_on_edition || {};

	return (
		<>
			<div styleName="title">Insumo ERP</div>
			{!empty_item && (
				<div styleName="name compact">
					<div>{item_integrado.pre_nome}</div>
				</div>
			)}
			<IIStatusView
				item_on_edition={item_on_edition} />
		</>
	);
};

InfoCompacted.propTypes = {
	empty_item: PropTypes.bool.isRequired,
	item_on_edition: PropTypes.object,
};

InfoCompacted.defaultProps = {
	item_on_edition: {},
};

export default CSSModules(InfoCompacted, styles, { allowMultiple: true });
