import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

class MultipleRCOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { option, handleEdition } = this.props;
		handleEdition(option.id);
	}

	render() {
		const { selected } = this.props;
		const { rcs_analise } = this.props.option;
		const checkbox_button_class = !selected ? 'checkbox-button' : 'checkbox-button checked';
		const { nome } = rcs_analise[0].rc.fornecedor;

		/**
		 * RC sendo editado
		 */
		if (rcs_analise[0].rc.status === 35 || rcs_analise[0].rc.status === 36 || rcs_analise[0].rc.status === 37) {
			return (
				<button type="button" styleName="checkbox-button resetado">
					<div styleName="checkbox-icon" />
					{nome}
				</button>
			);
		}

		/**
		 * Check compra parcial
		 */
		const condicoes_de_pagamento = ((rcs_analise[0] || {}).rc || {}).condicoes_de_pagamento || [];
		const aceita_parcial = condicoes_de_pagamento.filter(c => c.aceita_parcial !== 0).length > 0;

		return (
			<button type="button" styleName={checkbox_button_class} onClick={this.handleOption} id="multiple-rc-option">
				<div styleName="checkbox-icon">
					{selected && <i className="fa fa-check" aria-hidden="true" />}
				</div>
				{nome}
				{!aceita_parcial && <span styleName="nao-aceita-parcial"><br />(Não aceita compra parcial)</span>}
			</button>
		);
	}
}

MultipleRCOption.propTypes = {
	option: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	selected: PropTypes.bool.isRequired,
};

export default CSSModules(MultipleRCOption, styles, { allowMultiple: true });
