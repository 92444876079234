import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../_parts/_loadings/LoadingPage/LoadingPage';
import TopBar from '../_parts/TopBar/TopBar';
import LeftMenu from '../_parts/LeftMenu/LeftMenu';
import THBackspace from '../_parts/THBackspace/THBackspace';
import ModalWrapper from '../_parts/_modals/ModalWrapper/ModalWrapper';
import ModalError from '../_parts/_modals/ModalError/ModalError';

// Functions
import { getLocalstorageData } from '../_functions/_getLocalstorageData';

const LayoutWrapper = ({
	fetching,
	topbar,
	thbackspace_fixed,
	ui,
	user,
	cotacao,
	updateModals,
	updateUi,
	changeObra,
	unsetAuth,
	updateCotacao,
	match,
	history,
	children,
}) => {
	useEffect(() => {
		function checkAuth() {
			const token = getLocalstorageData('user', 'token') || '';
			const email = getLocalstorageData('user', 'email') || '';
			if (token === '' || email === '') {
				history.push('/sair');
			}
		}

		checkAuth();
	}, []);

	const { fetching: fetching_user, obras_by_id, obras_all_ids, obra_selected } = user;
	const { obras_menu, account_menu, modals, search_state, search, search_results } = ui;
	const { criando_cotacao } = cotacao;

	const render = (
		fetching_user === 20
		&& fetching === 20
	);

	const error = (
		fetching_user === 30
		|| fetching === 30
	);

	/* ========================================================================== *\
			Render
	\* ========================================================================== */
	if (render) {
		return (
			<div styleName="page-wrapper">
				<ModalWrapper visible={modals.error} updateModals={updateModals}>
					<ModalError />
				</ModalWrapper>
				<LeftMenu
					route_path={match.path}
					obra_selected={obra_selected}
					criando_cotacao={criando_cotacao}
					updateCotacao={updateCotacao}
					updateModals={updateModals}
					updateUi={updateUi} />
				{topbar && (
					<>
						<TopBar
							updateUi={updateUi}
							obras_menu={obras_menu}
							account_menu={account_menu}
							obras_by_id={obras_by_id}
							obras_all_ids={obras_all_ids}
							obra_selected={obra_selected}
							changeObra={changeObra}
							search_state={search_state}
							search={search}
							search_results={search_results}
							unsetAuth={unsetAuth} />
						<THBackspace fixed={thbackspace_fixed} />
					</>
				)}
				{React.cloneElement(children)}
			</div>
		);
	}

	/* ========================================================================== *\
			Error
	\* ========================================================================== */
	if (error) {
		return (
			<div styleName="page-wrapper">
				<ModalWrapper visible={modals.error} updateModals={updateModals}>
					<ModalError />
				</ModalWrapper>
				<LeftMenu
					route_path={match.path}
					obra_selected={obra_selected}
					criando_cotacao={criando_cotacao}
					updateCotacao={updateCotacao}
					updateModals={updateModals}
					updateUi={updateUi} />
				{topbar && (
					<>
						<TopBar
							updateUi={updateUi}
							obras_menu={obras_menu}
							account_menu={account_menu}
							obras_by_id={obras_by_id}
							obras_all_ids={obras_all_ids}
							obra_selected={obra_selected}
							changeObra={changeObra}
							search_state={search_state}
							search={search}
							search_results={search_results}
							unsetAuth={unsetAuth} />
						<THBackspace />
					</>
				)}
				<div className="container" style={{ marginTop: '36px' }}>
					<div className="row">
						<div className="col-sm-12" styleName="error-wrapper">
							<i className="fa fa-exclamation-circle" aria-hidden="true" />
							<h3>Desculpe, tivemos um problema!</h3>
							<p>Atualize a página como descrito abaixo ou entre em contato com nossa equipe de suporte pelo chat.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12" style={{ textAlign: 'center' }}>
							<div styleName="keyboards-wrapper">
								<div styleName="keyboards">
									<div styleName="type">Windows:</div>
									<div styleName="keys">
										<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
										<div styleName="key-plus">+</div>
										<div styleName="key c89"><span>F5</span></div>
									</div>
								</div>
								<div styleName="keyboards">
									<div styleName="type">Mac OS:</div>
									<div styleName="keys">
										<div styleName="key c89" style={{ width: '70px' }}><span>&#8984;</span></div>
										<div styleName="key-plus">+</div>
										<div styleName="key c89"><span>R</span></div>
									</div>
								</div>
								<div styleName="keyboards">
									<div styleName="type">Linux:</div>
									<div styleName="keys">
										<div styleName="key c89" style={{ width: '70px' }}><span>Ctrl</span></div>
										<div styleName="key-plus">+</div>
										<div styleName="key c89"><span>F5</span></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div styleName="page-wrapper" style={{ textAlign: 'center' }}>
			<ModalWrapper visible={modals.error} updateModals={updateModals}>
				<ModalError />
			</ModalWrapper>
			<LeftMenu
				route_path={match.path}
				obra_selected={obra_selected}
				criando_cotacao={criando_cotacao}
				updateCotacao={updateCotacao}
				updateModals={updateModals}
				updateUi={updateUi} />
			{topbar && (
				<>
					<TopBar
						updateUi={updateUi}
						obras_menu={obras_menu}
						account_menu={account_menu}
						obras_by_id={obras_by_id}
						obras_all_ids={obras_all_ids}
						obra_selected={obra_selected}
						changeObra={changeObra}
						search_state={search_state}
						search={search}
						search_results={search_results}
						unsetAuth={unsetAuth} />
					<THBackspace />
				</>
			)}
			<LoadingPage />
		</div>
	);
};

LayoutWrapper.propTypes = {
	// =========== components visibility
	fetching: PropTypes.number,
	topbar: PropTypes.bool,
	thbackspace_fixed: PropTypes.bool,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateCotacao: PropTypes.func,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	changeObra: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	children: PropTypes.object.isRequired,
};

LayoutWrapper.defaultProps = {
	// =========== components visibility
	fetching: 20,
	topbar: true,
	thbackspace_fixed: false,
	updateCotacao: () => {},
};

export default CSSModules(LayoutWrapper, styles, { allowMultiple: true });
