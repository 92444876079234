export const replaceSearchText = (typed, itens_results) => {
	const typed_words = typed.replace(/[~/\\#,+()$~.'"*?<>{}]/g, '').trim().split(' ');
	const _itens_results = itens_results.map(item => ({
		...item,
		nome: item.nome.replace(/~/g, ''),
		sinonimos_front: [...item.sinonimos],
	}));

	for (let i = 0; i < _itens_results.length; i++) {
		for (let i2 = 0; i2 < typed_words.length; i2++) {
			const regexp = new RegExp(typed_words[i2], 'i');
			if (_itens_results[i].nome.match(regexp)) {
				const typed_match = _itens_results[i].nome.match(regexp)[0];
				const nome = _itens_results[i].nome.replace(regexp, `~${typed_match}~~`);
				_itens_results[i].nome = nome;
			}

			// sinonimos
			const sinonimos_front = _itens_results[i].sinonimos_front.map(sinonimo => {
				if (sinonimo.nome.match(regexp)) {
					const typed_match2 = sinonimo.nome.match(regexp)[0];
					return { ...sinonimo, nome: sinonimo.nome.replace(regexp, `~${typed_match2}~~`) };
				}
				return sinonimo;
			});
			_itens_results[i].sinonimos_front = [...sinonimos_front];
			// const apelidos_new = _itens_results[i].sinonimos.map(sinonimo => {
			// 	if (sinonimo.nome.match(regexp)) {
			// 		console.log(sinonimo.nome);
			// 		const typed_match2 = sinonimo.nome.match(regexp)[0];
			// 		return { ...sinonimo, nome: sinonimo.nome.replace(regexp, `~${typed_match2}~~`) };
			// 	}
			// 	return sinonimo;
			// });
			// _itens_results[i].sinonimos_front = [...apelidos_new];
		}
		const add_span = _itens_results[i].nome.replace(/~~/g, '</span>').replace(/~/g, '<span>');
		_itens_results[i].nome = add_span;

		// sinonimos
		const apelidos_add_span = _itens_results[i].sinonimos_front.map((sinonimo, s) => (
			s > 0
				? { ...sinonimo, nome: ` / ${sinonimo.nome.replace(/~~/g, '</span>').replace(/~/g, '<span>')}` }
				: { ...sinonimo, nome: sinonimo.nome.replace(/~~/g, '</span>').replace(/~/g, '<span>') }
		));
		_itens_results[i].sinonimos_front = [...apelidos_add_span];
	}
	return _itens_results;
};
