import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import RCColumn from './RCColumn';

const RCs = ({
	window_height,
	opcoes_de_compra, // resposta fornecedor
	itens_analise, // itens construtor
	rcs_abertos,
	openCloseRCs,
	edition_mode,
	selectItem,
	changeCondicaoPagamento,
	mobile,
	updateModals,
	openItemAnaliseOnMobile,
	enviaQCTriagem,
	pedido_status,
	enviando_qc_triagem,
	menor_preco,
}) => {
	const { all_ids, by_id } = opcoes_de_compra;
	const has_combinacao =
		((by_id[all_ids[0]] || {}).rcs_analise || []).length > 1;
	let start_completos = false;
	let start_incompletos = false;

	/**
	 * Triagem
	 * Botão de envio de QC
	 */
	const show_enviar_qc_button = pedido_status >= 40;
	let enviar_qc_label = 'Enviar QC';
	if (pedido_status > 45) {
		enviar_qc_label = 'Reenviar QC';
	}

	const loading_column =
		all_ids.length === 0 && itens_analise.all_ids.length > 0 ? (
			<div styleName="rc">
				{itens_analise.all_ids.map(id => (
					<div
						key={itens_analise.by_id[id].id}
						styleName="item"
						data-item-right={itens_analise.by_id[id].id}>
						<div styleName="timeline-item">
							<div styleName="animated-background">
								<div styleName="background-masker m-precos" />
								<div styleName="background-masker m-space-1" />
								<div styleName="background-masker m-carac" />
								<div styleName="background-masker m-space-2" />
								<div styleName="background-masker m-carac-2" />
								<div styleName="background-masker m-space-3" />
							</div>
						</div>
					</div>
				))}
			</div>
		) : null;

	return (
		<div
			id="qc_scroll"
			styleName="qc-list"
			style={{ height: window_height }}>
			{all_ids.map(oc_id => {
				const oc = by_id[oc_id];
				const is_combinacao = oc.rcs_analise.length > 1;
				const _RCColumn = (
					<RCColumn
						key={oc_id}
						oc={oc}
						itens_analise={itens_analise}
						rcs_abertos={rcs_abertos}
						openCloseRCs={openCloseRCs}
						window_height={window_height}
						edition_mode={edition_mode}
						selectItem={selectItem}
						changeCondicaoPagamento={changeCondicaoPagamento}
						mobile={mobile}
						menor_preco={menor_preco}
						updateModals={updateModals}
						openItemAnaliseOnMobile={openItemAnaliseOnMobile} />
				);

				if (is_combinacao) {
					return (
						<React.Fragment key={oc_id}>
							{_RCColumn}
							{/* edition_mode && (
								<div styleName="message-onboarding-like">
									<i className="fa fa-long-arrow-left" aria-hidden="true" />
									<p>Selecione os itens e compare os preços</p>
								</div>
							) */}
						</React.Fragment>
					);
				}

				/**
				 * Espaçamento entre combinações e rcs
				 */
				if (has_combinacao && !start_completos) {
					start_completos = true;

					return (
						<React.Fragment key={oc_id}>
							<div styleName="separacao-tipos-rcs" />
							{_RCColumn}
						</React.Fragment>
					);
				}

				/**
				 * Espaçamento entre completos e incompletos
				 */
				if (!oc.rcs_analise[0].rc.completo && !start_incompletos) {
					start_incompletos = true;

					return (
						<React.Fragment key={oc_id}>
							<div styleName="separacao-tipos-rcs" />
							{_RCColumn}
						</React.Fragment>
					);
				}

				return _RCColumn;
			})}

			{all_ids.length === 0 && (
				<>
					{loading_column}
					{loading_column}
					{loading_column}
					{loading_column}
				</>
			)}

			{show_enviar_qc_button &&
				process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno' &&
				(enviando_qc_triagem === 1 ? (
					<button
						className="btn btn-secondary-conaz round-border"
						styleName="enviar-qc-triagem-btn">
						enviando...
					</button>
				) : (
					<button
						className="btn btn-secondary-conaz round-border"
						styleName="enviar-qc-triagem-btn"
						onClick={enviaQCTriagem}>
						{enviar_qc_label}
					</button>
				))}
			<div styleName="bottom-blank-space" />
		</div>
	);
};

RCs.propTypes = {
	window_height: PropTypes.number.isRequired,
	opcoes_de_compra: PropTypes.object.isRequired,
	itens_analise: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	edition_mode: PropTypes.bool,
	selectItem: PropTypes.func,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	mobile: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	openItemAnaliseOnMobile: PropTypes.func.isRequired,
	enviaQCTriagem: PropTypes.func,
	pedido_status: PropTypes.number.isRequired,
	enviando_qc_triagem: PropTypes.number,
	menor_preco: PropTypes.object.isRequired,
};

RCs.defaultProps = {
	edition_mode: false,
	selectItem: () => {},
	enviaQCTriagem: () => {},
	enviando_qc_triagem: 0,
};

export default CSSModules(RCs, styles, { allowMultiple: true });
