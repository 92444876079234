import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';

// Functions
import { _get } from '../../components/_functions/_requests';
import { setAuthLocalstorage } from '../../components/_functions/_setAuthLocalstorage';

class LogarComoUsuario extends React.Component {
	state = {
		request_state: 1,
	};

	componentDidMount() {
		const { match, setAuth, getUser, history } = this.props;
		const { token } = match.params;
		const user_localstorage = { token };
		localStorage.setItem('user', JSON.stringify(user_localstorage));
		_get('/conaz/v2/auth/me').then(response => {
			const usuario = response.data;
			setAuthLocalstorage(token, usuario, true);
			setAuth(token, usuario, true);
			getUser();
			history.push('/cotacoes');
		}).catch(error => {
			console.log(error); // eslint-disable-line no-console
			this.setState({ request_state: 3 });
			window.alert('Não foi possível completar sua solicitação'); // eslint-disable-line no-alert
		});
	}

	render() {
		return this.state.request_state === 1 ? <LoadingPage /> : null;
	}
}

LogarComoUsuario.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	setAuth: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(LogarComoUsuario));
