import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import axios from 'axios';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Loading from '../_loadings/Loading/Loading';

// Functions
import { debounce } from '../../_functions/_debounce';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { getLocalstorageData } from '../../_functions/_getLocalstorageData';
import { buildNewItem } from '../../_functions/_buildNewItem';

class AssociacaoSiengeSearchBar extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		if (search.length > 0) {
			this.searchItems(search).then(response => {
				// const itens_results_new = replaceSearchText(search, response.data);
				this.setState({ itens_results: response.data, search_state: 2 });
			}).catch(error => {
				this.setState({ itens_results: [], search_state: 0 });
				if (error.message !== 'canceled_request') {
					// updateModals('error', true);
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	constructor() {
		super();
		this.state = {
			sugestions_state: 0,
			search_state: 0,
			search: '',
			results_visible: false,
			itens_results: [],
			itens_sugestions: [],
			item_loading: 0,
			confirma_add_nao_cadastrado: false,
		};
		this.handleSearch = this.handleSearch.bind(this);
		this.clearSearch = this.clearSearch.bind(this);
		this.focusSearch = this.focusSearch.bind(this);
		this.blurSearch = this.blurSearch.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.buscarAssociacoesPossiveis(this.props.item_para_edicao);
	}

	UNSAFE_componentWillReceiveProps() {
		this.setState({ confirma_add_nao_cadastrado: false });
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	clearSearch() {
		this.setState({ search: '', results_visible: false });
	}

	focusSearch() {
		const results_visible = this.state.search !== '';
		this.setState({ results_visible });
	}

	blurSearch() {
		this.setState({ results_visible: false });
		document.getElementById('itens_search_input').blur();
	}

	async searchItems(typed) {
		try {
			const base_url = getLocalstorageData('environment', 'base_url');
			const token = getLocalstorageData('user', 'token');
			const typed_encode = encodeURIComponent(typed).replace(/%0A/g, '');
			axios.defaults.headers.common.token = token;
			const { CancelToken } = axios;
			const url = `${base_url}/conaz/v2/itens/search?q=${typed_encode}`;
			return await axios({
				method: 'get',
				url,
				cancelToken: new CancelToken(c => {
					this.cancel = c;
					return c;
				}),
			});
		} catch (err) {
			throw err;
		}
	}

	handleSearch(e) {
		const search = e.target.value.toString();
		const results_visible = search !== '';
		if (this.cancel !== undefined) this.cancel('canceled_request');
		if (search.trim().length > 0) {
			this.setState({ search, results_visible, search_state: 1, itens_results: [] }, this.handleDebounce);
		} else {
			this.setState({ search, results_visible, search_state: 0 });
		}
	}

	associarItem(item_to_add) {
		const { item_para_edicao, itens, saveItem } = this.props;
		const { id } = item_to_add;

		const key = itens.findIndex(x => x.key === item_para_edicao.key);
		const pre_new_item = {
			...item_para_edicao,
			associado_aprovado: true,
			cliente_associou: true,
			item_preenchido: {
				caracteristicas_preenchidas: [],
				item: { ...item_to_add },
				caracteristicas: [],
			},
			item_integrado: {
				aprovado: true,
				unidade: '',
				id_no_swi_item: item_para_edicao.id_no_swi,
				id_no_swi_detalhe: item_para_edicao.detalhe ? item_para_edicao.detalhe.id_no_swi : null,
				item_preenchido: {
					id,
				},
			},
		};

		const new_item = buildNewItem(key, pre_new_item, null);
		saveItem(key, new_item);
	}

	buscarAssociacoesPossiveis(item_para_edicao) {
		this.setState({ sugestions_state: 1 });
		this.searchItems(item_para_edicao.nome).then(response => {
			// const itens_results_new = replaceSearchText(pre_nome, response.data);
			this.setState({ itens_sugestions: response.data, sugestions_state: 2 });
		}).catch(() => {
			this.setState({ itens_sugestions: [], sugestions_state: 0 });
		});
	}

	render() {
		const {
			search,
			results_visible,
			sugestions_state,
			search_state,
			itens_results,
			itens_sugestions,
			item_loading,
			confirma_add_nao_cadastrado,
		} = this.state;
		const {
			item_para_edicao,
		} = this.props;

		return (
			<div styleName="associacao-search">
				<div styleName="input-wrapper">
					{!!results_visible && (
						<button
							type="button"
							className="close"
							styleName="clear-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.clearSearch}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					<input
						id="itens_search_input"
						type="text"
						className="form-control input-md"
						placeholder="Buscar item"
						value={search}
						onChange={this.handleSearch}
						onFocus={this.focusSearch} />
				</div>

				{(sugestions_state === 1 && sugestions_state !== 2 && !results_visible) && (
					<div styleName="first-results-wrapper">
						<p>Sugestões</p>
						<Loading />
					</div>
				)}
				{(sugestions_state === 2 && sugestions_state !== 1 && !results_visible) && (
					<div styleName="first-results-wrapper">
						<p>Sugestões</p>
						{itens_sugestions.map(item_possivel => {
							const h4_class = item_loading === item_possivel.id ? 'results-list-h4 loading' : 'results-list-h4';
							/* eslint-disable react/no-array-index-key */
							return (
								<button key={item_possivel.id} type="button" onClick={() => this.associarItem(item_possivel)}>
									<i className="fa fa-angle-right" aria-hidden="true" />
									{item_loading === item_possivel.id && <Loading />}
									<h4 className="list-group-item-heading" styleName={h4_class}>{item_possivel.nome}</h4>
									{item_possivel.sinonimos.length > 0 && (
										<p className="list-group-item-text" styleName="results-list-p">
											{item_possivel.sinonimos.map((sinonimo, s) => {
												if (s > 0) return <span key={s}> / {sinonimo.nome}</span>;
												return <span key={s}>{sinonimo.nome}</span>;
											})}
										</p>
									)}
								</button>
							);
						})}
						{(itens_sugestions.length === 0 && !confirma_add_nao_cadastrado) && (
							<div styleName="add-new-wrapper">
								<p>Não encontramos nenhum resultado no nosso banco de dados para <b>{item_para_edicao.nome}</b>.</p>
							</div>
						)}
					</div>
				)}

				{!!results_visible && (
					<div styleName="first-results-wrapper">
						{search_state === 1 ? <Loading /> : itens_results.map((item, i) => {
							const h4_class = item_loading === item.id ? 'results-list-h4 loading' : 'results-list-h4';
							return (
								<button key={item.id} type="button" onClick={() => this.associarItem(item)}>
									{item_loading === item.id && <Loading />}
									<i className="fa fa-angle-right" aria-hidden="true" />
									<h4 className="list-group-item-heading" styleName={h4_class}>{item.nome}</h4>
									{item.sinonimos.length > 0 && (
										<p className="list-group-item-text" styleName="results-list-p">
											{item.sinonimos.map((sinonimo, s) => {
												if (s > 0) return <span key={s}> / {sinonimo.nome}</span>;
												return <span key={s}>{sinonimo.nome}</span>;
											})}
										</p>
									)}
								</button>
							);
						})}
					</div>
				)}

				{(!!results_visible && search_state === 2 && itens_results.length === 0 && !confirma_add_nao_cadastrado) && (
					<div styleName="add-new-wrapper">
						<p>Não encontramos nenhum resultado no nosso banco de dados para <b>{item_para_edicao.nome}</b>.</p>
					</div>
				)}
			</div>
		);
	}
}

AssociacaoSiengeSearchBar.propTypes = {
	item_para_edicao: PropTypes.object.isRequired,
	saveItem: PropTypes.func.isRequired,
	itens: PropTypes.array.isRequired,
};

export default CSSModules(AssociacaoSiengeSearchBar, styles, { allowMultiple: true });
