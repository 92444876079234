import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import { Tooltip } from 'antd';
import styles from './style.module.scss';

const CompraRowFornecedor = ({ compra }) => {
	const { fornecedor } = compra;
	const fornecedor_nome = ((fornecedor.nome !== '' && fornecedor.nome !== null) && fornecedor !== undefined)
		? fornecedor.nome
		: <em style={{ color: '#bdbdbd' }}>Sem registro</em>;

	return (
		<>
			{fornecedor.nome ? (
				<Tooltip placement="topLeft" title={fornecedor_nome} color="#0076D6">
					<div styleName="fornecedor">
						Fornecedor
						<p>{fornecedor_nome}</p>
					</div>
				</Tooltip>
			) : (
				<div styleName="fornecedor">
					Fornecedor
					<p>{fornecedor_nome}</p>
				</div>
			)}
		</>

	);
};

CompraRowFornecedor.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowFornecedor, styles, { allowMultiple: true });
