import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class ModalLimitSienge extends Component {
	confirmFunc = () => {
		const { requestToContinue, var_for_request } = this.props;
		const force = true;
		if (var_for_request === '') {
			requestToContinue(force);
			return;
		}
		requestToContinue(var_for_request, force);
	}

	render() {
		const { error_message, removeLimitError } = this.props;
		const requests_values = error_message.split(' / ');
		const total_do_plano = (requests_values[1] || '')
			.replace(/total_do_plano: /g, '');
		const requests_necessarias = (requests_values[2] || '')
			.replace(/requests_necessarias: /g, '');
		const total_atingido = (requests_values[3] || '')
			.replace(/total_atingido: /g, '');
		const total_atingido_futuro = (
			Number(total_atingido) + Number(requests_necessarias)
		);

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<div styleName="icon">
						<i className="fa fa-exclamation-circle" aria-hidden="true" />
					</div>
					<div styleName="content">
						<p styleName="rl-title">Você irá ultrapassar o seu plano de requisições do Sienge com esta ação, o que pode ocasionar em cobrança adicional</p>
						<p>Deseja continuar mesmo assim?</p>
						<div styleName="rl-buttons">
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								style={{ marginRight: '15px' }}
								onClick={removeLimitError}>
								Cancelar
							</button>
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								onClick={this.confirmFunc}>
								Continuar mesmo assim
							</button>
						</div>
						<p styleName="rl-more-info">Você possui um plano que permite {total_do_plano} requisições diárias. Com esta ação você irá realizar {requests_necessarias} requisições, atingindo {total_atingido_futuro} requisições diárias. Consulte seu gerente de conta do Sienge para mais informações.</p>
					</div>
				</div>
			</div>
		);
	}
}

ModalLimitSienge.propTypes = {
	error_message: PropTypes.string,
	requestToContinue: PropTypes.func.isRequired,
	var_for_request: PropTypes.string,
	removeLimitError: PropTypes.func.isRequired,
};

ModalLimitSienge.defaultProps = {
	error_message: '',
	var_for_request: '',
};

export default CSSModules(ModalLimitSienge, styles, { allowMultiple: true });
