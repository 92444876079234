import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { CheckOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import EPLoading from 'icons/ep_loading.svg';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

const CompraRowIcon = ({ compra }) => {
	const status = defineStatusCompra(compra);
	const icons_status = {
		deletada: <i className="fa fa-trash" style={{ color: 'red' }} aria-hidden="true" />,
		cancelada: <i className="fa fa-ban" style={{ color: 'red' }} aria-hidden="true" />,
		em_aprovacao: <SendOutlined styleName="icons-send-detalhes" style={{ color: '#2F54EB' }} aria-hidden="true" />,
		aguardando_aprovacao_sienge: <SendOutlined styleName="icons-send-detalhes" style={{ color: '#2F54EB' }} aria-hidden="true" />,
		em_edicao: <EditOutlined styleName="custom-icons" style={{ color: '#8C8C8C' }} aria-hidden="true" />,
		rascunho: <i className="fa fa-barcode" aria-hidden="true" />,
		em_analise: <i className="fa fa-barcode" aria-hidden="true" />,
		em_refinamento: <i className="fa fa-pencil" aria-hidden="true" />,
		aguardando_confirmacao: <img src={EPLoading} styleName="custom-icons" alt="Aguardando confirmação" aria-hidden="true" />,
		confirmada: <CheckOutlined styleName="custom-icons" style={{ color: '#52C41A' }} aria-hidden="true" />,
		recusada_fornecedor: <i className="fa fa-exclamation-triangle" style={{ color: 'red' }} aria-hidden="true" />,
		entrega_confirmada: <CheckOutlined styleName="custom-icons" style={{ color: '#52C41A' }} aria-hidden="true" />,
		finalizada: <CheckOutlined styleName="custom-icons" style={{ color: '#52C41A' }} aria-hidden="true" />,
	};

	return (
		<>{icons_status[status]}</>
	);
};

CompraRowIcon.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowIcon, styles, { allowMultiple: true });
