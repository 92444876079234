import { associarItem } from './_associarItem';

export const montarItem = (index, select_item, detalhe) => {
	const { itens_associados } = select_item;
	let item_integrado = null;
	const item_associado = (itens_associados || [])[0] || {};

	if (item_associado.id !== undefined) {
		const { aprovado, item_preenchido, unidade } = item_associado;
		item_integrado = {
			id_no_swi_item: select_item.id_no_swi,
			id_no_swi_detalhe: detalhe ? detalhe.id_no_swi : null,
			aprovado,
			item_preenchido,
			unidade,
		};
	}

	const associado_aprovado = item_associado.id !== undefined
		? item_associado.aprovado
		: false;

	const _itens_associados = item_associado.id !== undefined
		? [item_associado]
		: [];

	const novoItem = {
		key: index,
		associado_aprovado,
		detalhe,
		...select_item,
		item_integrado,
		itens_associados: _itens_associados,
	};

	if (item_integrado) {
		return associarItem(index, novoItem, item_associado.item);
	}

	return novoItem;
};
