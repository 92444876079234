import { takeEvery, put } from 'redux-saga/effects';
import {
	COTACOES_REQUESTED,
	COTACOES_RECEIVED,
	COTACOES_REQUEST_FAILED,
	COTACOES_INITIAL_REQUESTED,
	COTACOES_INITIAL_RECEIVED,
} from './types';

// Services
import { get } from '../../services/get';

// Functions
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';
import { _sendResponseToSlackToMap } from '../../components/_functions/_sendResponseToSlackToMap';

export function* fetchInitialCotacoes({ tab, obra_id }) {
	const cotacoes_lista = ['em_andamento', 'finalizados', 'cancelados', 'em_rascunho'];

	try {
		const { data } = yield get(`/conaz/v2/pedidos/${tab}?obra_id=${obra_id}`);
		_sendResponseToSlackToMap(data);
		const _cotacoes_lista = cotacoes_lista.filter(c => c !== tab);
		const requests = _cotacoes_lista.map(t => get(`/conaz/v2/pedidos/${t}?obra_id=${obra_id}&per_page=0`));
		let payload;

		yield Promise.all(requests).then(request => {
			const results = {
				[tab]: data,
				[_cotacoes_lista[0]]: request[0].data,
				[_cotacoes_lista[1]]: request[1].data,
				[_cotacoes_lista[2]]: request[2].data,
			};

			payload = { data: results, tab };
		});

		yield put({ type: COTACOES_INITIAL_RECEIVED, payload });
	} catch (error) {
		handleRequestErrors(error);
		yield put({ type: COTACOES_REQUEST_FAILED, error });
	}
}

export function* fetchCotacoes({ obra_id, tab, page }) {
	try {
		const { data } = yield get(`/conaz/v2/pedidos/${tab}?page=${page}&obra_id=${obra_id}`);
		_sendResponseToSlackToMap(data);
		const payload = { data, tab };

		yield put({ type: COTACOES_RECEIVED, payload });
	} catch (error) {
		handleRequestErrors(error);
		yield put({ type: COTACOES_REQUEST_FAILED, error, tab });
	}
}

// saga
export default function* cotacoesSaga() {
	yield takeEvery([COTACOES_INITIAL_REQUESTED], fetchInitialCotacoes);
	yield takeEvery([COTACOES_REQUESTED], fetchCotacoes);
}
