import React from 'react';
import PropTypes from 'prop-types';
import Paginate from 'react-paginate';

const Pagination = ({ changePage, currentPage, totalPage, requestState, per_page }) => (
	<div className={`row ${requestState !== 2 ? 'hide' : ''}`} style={{ textAlign: 'center' }}>
		<Paginate
			pageCount={Math.ceil(totalPage / per_page)}
			pageRangeDisplayed={3}
			marginPagesDisplayed={1}
			forcePage={currentPage - 1}
			onPageChange={changePage}
			breakLabel="..."
			activeClassName="active"
			containerClassName="pagination"
			nextLabel=">"
			previousLabel="<"
		/>
	</div>
);

Pagination.propTypes = {
	changePage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	requestState: PropTypes.number.isRequired,
	per_page: PropTypes.number.isRequired,
};

export default Pagination;
