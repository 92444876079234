import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const IntegracaoInicio = () => (
	<>
		<div className="container">
			<div className="row">
				<div className="col-xs-12">
					<div styleName="justify-flex-center">
						<h3 className="h3-header">Integrações</h3>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6">
					<div styleName="card-position-a justify-flex-end">
						<Link styleName="btn-custom" className="btn" to="/integracao/sienge">
							<div><img src="/images/sienge.jpg" width="84" alt="" /></div>
							<div styleName="small-margin">Sienge</div>
						</Link>
					</div>
				</div>
				<div className="col-sm-6">
					<div styleName="card-position-a justify-flex-start" style={{ opacity: '0.4' }}>
						<Link styleName="btn-custom" className="btn" disabled="true" to="/integracao/inicio">
							<div><img src="/images/excel-icon.gif" width="84" alt="" /></div>
							<div styleName="small-margin">Planilha</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	</>
);

export default CSSModules(IntegracaoInicio, styles, { allowMultiple: true });
