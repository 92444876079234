import React from 'react';
import PropTypes from 'prop-types';
import { withStateHandlers } from 'recompose';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_modals/ModalPortal/ModalWrapperPortal';
import ModalUpload from './ModalUpload/ModalUpload';
import FileList from './FileList';

const withState = withStateHandlers(() => ({
	arquivo_para_editar: {},
}), {
	selectFileToEdit: ({ modal }) => {
		const modalIsVisible = !modal;
		return arquivo => {
			const arquivo_para_editar = modalIsVisible
				? { ...arquivo }
				: {};
			return { arquivo_para_editar };
		};
	},
});

const UploadFiles = ({
	config,
	anexos,
	updateCompras,
	updateUi,
	compra,
	modal,
	arquivo_para_editar,
	selectFileToEdit,
	handleModal,
}) => (
	<div styleName="upload-wrapper">
		{modal && (
			<ModalPortal>
				<ModalWrapperPortal closeModal={handleModal}>
					<ModalUpload
						{...{
							config,
							arquivo_para_editar,
							updateCompras,
							anexos,
							updateUi,
						}} />
				</ModalWrapperPortal>
			</ModalPortal>
		)}
		<FileList
			{...{
				config,
				selectFileToEdit,
				updateCompras,
				anexos,
				compra,
				handleModal,
				arquivo_para_editar,
				updateUi,
			}} />
	</div>
);

UploadFiles.propTypes = {
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	compra: PropTypes.object.isRequired,
	anexos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			nome: PropTypes.string,
			tipo: PropTypes.string,
			url: PropTypes.string,
			criado_em: PropTypes.string,
			tamanho: PropTypes.number,
			visto: PropTypes.bool,
		}),
	).isRequired,
	updateCompras: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	modal: PropTypes.bool.isRequired,
	arquivo_para_editar: PropTypes.object,
	selectFileToEdit: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
};

UploadFiles.defaultProps = {
	arquivo_para_editar: {},
};

export default withState(
	CSSModules(UploadFiles, styles, { allowMultiple: true }),
);
