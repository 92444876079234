export const mergeEmpresasAssociadas = (obras = [], obras_id, empresas = []) => obras.map((ob) => {
	if (obras_id === ob.id) {
		const aux = ob;
		const novasEmpresas = empresas.map((emp) => ({
			papeis: emp.papeis,
			pessoa: emp.pessoa,
			id: emp.id,
			criado_em: emp.criado_em,
		}));
		aux.pessoas = [...aux.pessoas, ...novasEmpresas];
		return aux;
	}
	return ob;
});
