// export const codigos_recusa = {
// 	0: { value: 'Não tenho a maioria dos itens' }, // Não tem a maioria dos itens
// 	1: { value: 'Não trabalho com este perfil de cliente' }, // Não trabalha com este perfil de cliente
// 	2: { value: 'Faturamento menor do que o mínimo' }, // Compra abaixo do faturamento mínimo
// 	3: { value: 'Outro' }
// };
export const codigos_recusa = {
	0: { value: 'Não possuo a maioria dos itens ou não trabalho com este material' },
	1: { value: 'Não trabalho com pessoa física' },
	2: { value: 'Valor da compra abaixo do faturamento mínimo' },
	3: { value: 'Outro' },
	4: { value: 'Não entrego nesse local' },
	5: { value: 'Não atendo a este volume de compra' },
	6: { value: 'Preciso de um tempo maior para responder' },
	7: { value: 'Não consigo atender o prazo de entrega em obra solicitado' },
};
