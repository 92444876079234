/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components

import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

class TagSimilar extends Component {
	static getDerivedStateFromProps(next, prev) {
		const has_data =
			(next.carac_item_analise !== prev.carac_item_analise)
			&& (next.carac_item_oc !== prev.carac_item_oc);
		return ({ has_data });
	}

	state = {
		has_data: false,
		similarHover: false,
	}

	handleHoverTag = val => {
		this.setState({ similarHover: val });
	}

	verifySimilarItem = () => {
		/* Função que pega os itens que o fornecedor preencheu e
			 verifica se tem pelo menos uma característica diferente do item de analise
		*/
		const {
			carac_item_analise,
			carac_item_oc,
		} = this.props;
		const { similarHover } = this.state;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const validated_options_analise =
			_.filter(carac_item_analise, ({ caracteristica: { visao_construtor, visao_fornecedor }, opcoes_preenchidas }) => {
				const is_valid =
					(visao_construtor === 1 || visao_construtor === 2)
					&& (visao_fornecedor === 1 || visao_fornecedor === 2)
					&& ((opcoes_preenchidas[0] || {}).valor || '') !== '';
				return is_valid;
			});
		const validated_options_oc =
			_.filter(carac_item_oc, ({ caracteristica: { visao_construtor, visao_fornecedor } }) => {
				const is_valid = (visao_construtor === 1 || visao_construtor === 2)
					&& (visao_fornecedor === 1 || visao_fornecedor === 2);
				return is_valid;
			});
		const has_information =
			validated_options_analise.length > 0
			&& validated_options_oc.length > 0;
		if (has_information) {
			const is_not_equal_group = _.filter(validated_options_analise,
				// pra cada da compra construtor
				({
					caracteristica: { id: id_analise },
					opcoes_preenchidas: opcoes_preenchidas_analise,
				}) => {
					// carac em ordem de comrpa que é igual a carac construtor
					const carac_oc =
						_.find(validated_options_oc, ({ caracteristica: { id: id_oc } }) => id_oc === id_analise);
					// se valor ou unidade diferentes tag similar
					let is_not_equal = false;
					if (carac_oc !== undefined) {
						is_not_equal =
							((carac_oc.opcoes_preenchidas[0] || {}).valor !== (opcoes_preenchidas_analise[0] || {}).valor)
							|| ((carac_oc.opcoes_preenchidas[0] || {}).unidade !== (opcoes_preenchidas_analise[0] || {}).unidade);
					}
					return is_not_equal;
				});
			if (is_not_equal_group.length > 0 && is_triagem) {
				return (
					<div styleName="caracteristica">
						{ similarHover &&
							<div styleName="arrow-box">
								<p>O fornecedor preencheu campo(s) diferente(s) do seu pedido.</p>
							</div>
						}
						<span
							styleName="produto-similar"
							onMouseEnter={() => this.handleHoverTag(true)}
							onMouseLeave={() => this.handleHoverTag(false)}>
							Produto Similar
						</span>
					</div>
				);
			}
		}
		return null;
	}


	render() {
		const { has_data } = this.state;
		return (
			<>
				{has_data && this.verifySimilarItem()}
			</>
		);
	}
}

TagSimilar.propTypes = {
	carac_item_analise: PropTypes.array,
	carac_item_oc: PropTypes.array,
};

TagSimilar.defaultProps = {
	carac_item_analise: [],
	carac_item_oc: [],
};

export default CSSModules(TagSimilar, styles, { allowMultiple: true });
