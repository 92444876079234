import INITIAL_STATE from './store';
import * as Types from './types';

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.DRAWER_OBRA_SET_FORM_INSTANCE:
		return {
			...state,
			formInstance: action.payload.form,
		};
	case Types.DRAWER_OBRA_HANDLE_CHANGE_TO_CPY_EMPRESA:
		return {
			...state,
			copyEmpresaState: !state.copyEmpresaState,
		};
	case Types.DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_SUCCESS:
		return {
			...state,
			associaEmpresaHaveChanged: false,
		};
	case Types.DRAWER_OBRA_ASSOCIA_EMPRESA_HAVE_CHANGED:
		return {
			...state,
			associaEmpresaHaveChanged: action.payload.changed,
		};
	case Types.DRAWER_OBRA_COPIA_EMPRESA_HAVE_CHANGED:
		return {
			...state,
			associaCopiaEmpresaHaveChanged: action.payload.changed,
		};
	case Types.DRAWER_OBRA_CHANGE_TO_CPY_USUARIO:
		return {
			...state,
			copyUsuariosState: true,
		};
	case Types.DRAWER_OBRA_CLOSE_CPY_USUARIO:
		return {
			...state,
			copyUsuariosState: false,
			associaUsuariosHaveChanged: false,
		};
	case Types.DRAWER_OBRA_ASSOCIA_USUARIO_HAVE_CHANGED:
		return {
			...state,
			associaUsuariosHaveChanged: action.payload.changed,
		};
	case Types.DRAWER_OBRA_NEXT_STEP:
		return {
			...state,
			currentStep: state.currentStep + 1,
			stepStatus: action.payload.stepStatus,

		};
	case Types.DRAWER_OBRA_PREV_STEP:
		return {
			...state,
			currentStep: state.currentStep - 1,
			stepStatus: action.payload.stepStatus,
		};

	case Types.DRAWER_OBRA_OPEN:
		return {
			...state,
			drawerMode: 'new',
			showDrawer: true,
			maxStep: 3,
		};
	case Types.DRAWER_OBRA_OPEN_EDIT:
		return {
			...state,
			drawerMode: 'edit',
			showDrawer: true,
			dataToDrawer: action.payload.dataToDrawer,
			maxStep: 1,
		};
	case Types.DRAWER_OBRA_CLOSE:
		return {
			...state,
			showDrawer: false,
			dataToDrawer: null,
			drawerMode: 'new',
			obraCadastrada: false,
			currentStep: 0,
		};

	default:
		return state;
	}
}
