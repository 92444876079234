import React from 'react';
import PropTypes from 'prop-types';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// Redux HOC
import { connect } from 'react-redux';
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

import modes from './modesTypes';
import { DrawerFooter } from './styled';

function DrawerObrasFooter({
	stepSubmit,
	nextStep,
	prevStep,
	mode,
	handleClose,
	...props
}) {
	const {
		obrasDrawer,
		obras,
		handleDrawerCopyEmpresa,
		closeDrawerCopyUsuario,
	} = props;

	const loading = obras.isFetching || obras.actionFetching;

	const handleCpy = () => {
		if (obrasDrawer.copyEmpresaState) {
			handleDrawerCopyEmpresa(true);
			return;
		}
		closeDrawerCopyUsuario(true);
	};

	const handleCancelCpy = () => {
		if (obrasDrawer.copyEmpresaState) {
			handleDrawerCopyEmpresa(false);
			return;
		}
		closeDrawerCopyUsuario(false);
	};

	const getNextButton = (m) => {
		if (obrasDrawer.copyEmpresaState || obrasDrawer.copyUsuariosState) {
			const semAlteracao = obrasDrawer.copyEmpresaState ? !obrasDrawer.associaCopiaEmpresaHaveChanged : !obrasDrawer.associaUsuariosHaveChanged;
			return (
				<Button
					onClick={handleCpy}
					className={!semAlteracao && 'submit-btn'}
					type={!semAlteracao && 'primary'}
					disabled={semAlteracao}>
					Copiar
				</Button>
			);
		}

		if (m === modes.VIEW) {
			return (
				<Button className="submit-btn" type="primary" shape="round" onClick={handleClose}>
					Ok
				</Button>
			);
		}

		if (obrasDrawer.currentStep + 1 === obrasDrawer.maxStep) {
			return (
				<Button
					className="submit-btn"
					type="primary"
					shape="round"
					form="obras_form"
					onClick={stepSubmit}
					disabled={loading}
				>
					{m === modes.EDIT ? 'Salvar' : 'Concluir cadastro'}
				</Button>
			);
		}

		// verificar se houver mudanças na parte de associar empresas. Se houve, mudar botão para  salvar e continuar
		const semAlteracao = !obrasDrawer.associaEmpresaHaveChanged && obrasDrawer.currentStep !== 0;
		const pular = semAlteracao || (obras.obraCadastrada && obrasDrawer.currentStep === 0);
		return (
			<Button
				className="submit-btn"
				type="primary"
				shape="round"
				form="obras_form"
				disabled={loading}
				onClick={pular ? nextStep : stepSubmit}
			>
				{pular ? 'pular' : 'Salvar e Continuar'}
				<ArrowRightOutlined />
			</Button>
		);
	};

	const getPrevButton = (m) => {
		if (m === modes.VIEW) return null;

		if (obrasDrawer.copyEmpresaState || obrasDrawer.copyUsuariosState) {
			return (
				<Button onClick={handleCancelCpy} disabled={loading}>
					Cancelar
				</Button>
			);
		}

		if (obrasDrawer.currentStep === 0) {
			return (
				<Button className="cancel-btn" shape="round" onClick={handleClose} disabled={loading}>
					Cancelar
				</Button>
			);
		}

		return (
			<Button className="cancel-btn" shape="round" onClick={prevStep} disabled={loading}>
				Voltar
			</Button>
		);
	};

	return (
		<DrawerFooter>
			<div className="footer-form">
				<DrawerFooter.Actions>
					<>
						{getPrevButton(mode)}
						{getNextButton(mode)}
					</>
				</DrawerFooter.Actions>
			</div>
		</DrawerFooter>
	);
}

DrawerObrasFooter.propTypes = {
	obras: PropTypes.shape(),
	obrasDrawer: PropTypes.shape(),
	handleDrawerCopyEmpresa: PropTypes.func,
	closeDrawerCopyUsuario: PropTypes.func,
	stepSubmit: PropTypes.func,
	nextStep: PropTypes.func,
	prevStep: PropTypes.func,
	mode: PropTypes.string,
	handleClose: PropTypes.func,
};

DrawerObrasFooter.defaultProps = {
	obras: {},
	mode: 'NEW',
	obrasDrawer: {},
	closeDrawerCopyUsuario: () => {},
	handleDrawerCopyEmpresa: () => {},
	handleClose: () => {},
	stepSubmit: () => {},
	nextStep: () => {},
	prevStep: () => {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(DrawerObrasFooter));



