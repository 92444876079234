import { takeLatest, put, all, call } from 'redux-saga/effects';
import { message } from 'antd';

import { getObra, editaObra, associaEmpresa, associaUsario, removeEmpresaAssociada, removeUsuarioAssociado, statusVisualizacaoObra, statusAtivoObra } from '../../services/obras';

import * as Types from './types';
import * as Actions from './actions';

function* handleGetObra({ payload: { id } }) {
	try {
		const res = yield call(getObra, id);
		yield put(Actions.getObraSuccess(res.data));
	} catch (error) {
		message.error('Erro ao tentar recuperar obra');
		yield put(Actions.getObraError(error));
	}
}

function* visualizacaoObra({ payload: { data } }) {
	try {
		const { id } = data;
		const res = yield call(statusVisualizacaoObra, id, data);
		if (res.status !== 200) {
			throw new Error('Não foi possível alterar status da obra');
		}
		if (res.status === 200) {
			const response = yield call(getObra, id);
			yield put(Actions.editObraSelecionadoSuccess(response.data));
		}
		message.success('Status da obra alterado com sucesso');
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível alterar status da obra');
		}
		yield put(Actions.editObraSelecionadoError(error));
	}
}

function* ativoObra({ payload: { data } }) {
	try {
		const { id } = data;
		const res = yield call(statusAtivoObra, id, data);
		if (res.status !== 200) {
			throw new Error('Não foi possível alterar status da obra');
		}

		if (res.status === 200) {
			const response = yield call(getObra, id);
			yield put(Actions.editObraSelecionadoSuccess(response.data));
		}
		message.success('Status da obra alterado com sucesso');
	} catch (error) {
		message.error('Não foi possível alterar status da obra');
		yield put(Actions.getObraError(error));
	}
}

function* handleEditObraSelecionada({ payload: { data } }) {
	try {
		const res = yield call(editaObra, data.obra_id, data);
		yield put(Actions.editObraSelecionadoSuccess(res.data));
		message.success('Obra editada com sucesso');
	} catch (error) {
		message.error('Erro ao tentar editar obra');
		yield put(Actions.editObraSelecionadoError(error));
	}
}


function* handleAssociaEmpresa({ payload: { obra_id, empresa_id, papeis, editMode } }) {
	try {
		const res = yield call(associaEmpresa, obra_id, empresa_id, {
			papeis,
		});

		yield put(Actions.associaEmpresaObraSelecionadaSuccess(res.data));
		const messageSuccess = editMode ? 'Associação da empresa modificada com sucesso.' : 'Empresa associada com sucesso!';
		message.success(messageSuccess);
	} catch (error) {
		const messageError = editMode ? 'Erro ao tentar alterar a associação da empresa' : 'Erro ao tentar associar empresa a obra';
		message.error(messageError);
		yield put(Actions.associaEmpresaObraSelecionadaError(error));
	}
}

function* handleRemoveEmpresaAssociada({ payload: { obra_id, empresa_id } }) {
	try {
		yield call(removeEmpresaAssociada, obra_id, empresa_id);
		yield put(Actions.removeEmpresaAssociadaSuccess(empresa_id));
		message.success('Associação removida com sucesso');
	} catch (error) {
		message.error('Erro ao tentar remover a associação da empresa');
		yield put(Actions.removeEmpresaAssociadaError(error));
	}
}

function* handleAssociaUsuario({ payload: { data, editMode } }) {
	try {
		const {
			papeis,
			compra_ilimitada,
			valor_alcada_compra,
			user_id,
			obra_id,
		} = data;
		const aux = { papeis, valor_alcada_compra, compra_ilimitada: compra_ilimitada || false };
		const res = yield call(associaUsario, obra_id, user_id, aux);
		yield put(Actions.associaUsuarioObraSuccess(res.data));
		const messageSucess = editMode ? 'Associação de usuário alterado com sucesso.' : 'Associado usuário com sucesso.';
		message.success(messageSucess);
	} catch (error) {
		const messageError = editMode ? 'Erro ao tentar alterar a associação do usuário' : 'Erro ao tentar associar usuário a obra';
		message.error(messageError);
		yield put(Actions.associaUsuarioObraError(error));
	}
}

function* handleRemoveUsuarioAssociado({ payload: { obra_id, user_id } }) {
	try {
		yield call(removeUsuarioAssociado, obra_id, user_id);
		yield put(Actions.removeUsuarioAssociadoSuccess(user_id));
		message.success('Usuário removido com sucesso');
	} catch (error) {
		message.error(error);
		yield put(Actions.removeUsuarioAssociadoError(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.ASSOCIA_OBRA_GET_OBRA_REQUEST, handleGetObra),
		takeLatest(Types.ASSOCIA_OBRA_EDIT_OBRA_REQUEST, handleEditObraSelecionada),
		takeLatest(Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_REQUEST, handleAssociaEmpresa),
		takeLatest(Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_REQUEST, handleAssociaUsuario),
		takeLatest(Types.ASSOCIA_OBRA_REMOVE_EMPRESA_REQUEST, handleRemoveEmpresaAssociada),
		takeLatest(Types.ASSOCIA_OBRA_REMOVE_USUARIO_REQUEST, handleRemoveUsuarioAssociado),
		takeLatest(Types.ASSOCIA_OBRA_STATUS_VISUALIZACAO, visualizacaoObra),
		takeLatest(Types.ASSOCIA_OBRA_STATUS_ATIVO, ativoObra),
	]);
}
