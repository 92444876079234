function myIsNaN(o) {
	return o !== o; // eslint-disable-line
}

export const toNumber = value => {
	const only_numbers = value.toString().replace(/\D/g, '');
	if (only_numbers === '') return 0;
	return Number(only_numbers);
};

export const setDecimalsToNumber = value => {
	let valor = value.toString();
	let v_integer = '';
	let v_decimals = '';
	if (valor === '.' || valor === ',') valor = '';
	valor = valor.replace(/[^0-9,]/g, '');
	valor = valor.replace(/^(?!,|$)/, '');
	const has_comma = valor.lastIndexOf(',');
	if (has_comma !== -1) {
		v_integer = valor.substr(0, has_comma);
		v_integer = v_integer.replace(/,/g, '');
		v_decimals = valor.substr(has_comma + 1, valor.length);
		valor = `${v_integer}.${v_decimals}`;
	}
	if (myIsNaN(parseFloat(valor))) valor = '0';
	// if (Number.isNaN(parseFloat(valor))) valor = '0';
	if (value === '') return [0, ''];
	return [parseFloat(valor), valor];
};

const dotSeparateNumber = _value => {
	let value = _value;
	while (/(\d+)(\d{3})/.test(value.toString())) {
		value = value.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2'); // eslint-disable-line no-useless-concat
	}
	return value;
};

export const setDecimalsToString = (value, with_dots = false) => {
	let valor = value.toString();
	let v_integer = '';
	let v_decimals = '';
	if (valor === '.' || valor === ',') valor = '';
	valor = valor.replace(/[^0-9.]/g, '');
	const has_comma = valor.lastIndexOf('.');
	if (has_comma !== -1) {
		v_integer = valor.substr(0, has_comma);
		v_integer = v_integer.replace(/\./g, '');
		v_decimals = valor.substr(has_comma + 1, valor.length);
		valor = `${v_integer},${v_decimals}`;
	}
	if (with_dots) return dotSeparateNumber(valor);
	// if (valor === '0') return '';
	return valor;
};
