/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import CSSModules from 'react-css-modules';
import { _get } from 'components/_functions/_requests';
import styles from './style.module.sass';
import * as Actions from '../../../../store/exportStatus/actions';

import StatusProgressBar from './StatusProgressBar';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';
import { errorStatus } from './ErrorStatus';
import { obtendoDadosStatus } from './ObtemStatus';

import SucessoExportacao from '../../../../icons/sucesso_exportacao.svg';

function ExportStatus({ compra }) {
	const dispatch = useDispatch();
	const { status, passo_atual_status, passo_atual_tipo, data } = useSelector(state => state.exportStatus);

	const [cotacaoId, setCotacaoId] = useState('');
	const [statusCompra, setStatusCompra] = useState([]);
	const [erroMotivo, setErroMotivo] = useState('');
	const swi_cotacao = compra.swi_cotacao || {};

	useEffect(() => {
		setStatusCompra(defineStatusCompra(compra));
	}, []);

	useEffect(() => {
		if (data) {
			setErroMotivo(data.erro_motivo);
		}
		if (!data) {
			setErroMotivo('');
		}
	}, [data, erroMotivo]);

	useEffect(() => {
		if ((statusCompra !== 'rascunho' && statusCompra !== 'em_edicao') && (status !== 'FINISHED' && !swi_cotacao.cotacao_id && compra.status_atual !== 'RASCUNHO')) {
			const payload = {
				job_tipo: 'EXPORT_PURCHASE',
				entidade_id: compra.id,
			};

			dispatch(Actions.getExportStatus(payload, ''));
		}
	}, [statusCompra]);

	const negociacao_id = compra.negociacao_id || '';

	const { exportado_em } = swi_cotacao;

	const iniciandoExport = (
		<div styleName="exportando-compra-container">
			<strong styleName="exportando-compra">Exportando Compra <span> - Em andamento</span></strong>
			<div styleName="status-progress">
				<StatusProgressBar
					passo_atual_tipo={passo_atual_tipo}
					passo_atual_status={passo_atual_status}
					status={status} />
			</div>

		</div>
	);

	useEffect(() => {
		errorStatus(data);
	}, [erroMotivo]);

	const getCotacaoId = async () => {
		const cotacaoInfo = await _get(`/conaz/v2/compras/${compra.id}`);
		if (cotacaoInfo) {
			setCotacaoId(cotacaoInfo.data.swi_cotacao.cotacao_id);
		}
	};

	useEffect(() => {
		if (status === 'FINISHED') {
			getCotacaoId();
		}
	}, [status]);


	useEffect(() => {
	}, [cotacaoId]);


	const successStatus = () => (
		<div styleName="sucesso-exportacao">
			<div styleName="mensagem-sucesso-fixa">
				<img src={SucessoExportacao} alt="Compra exportada com sucesso" />
				<strong>
					Compra exportada com sucesso para o Sienge</strong>
			</div>
			<div styleName="container-mensagens-sucesso">
				<span styleName="mensagem-sucesso">Cotação {cotacaoId || swi_cotacao.cotacao_id} criada no Sienge</span>

				<div styleName="icone-warning">
					<span styleName="mensagem-sienge">
						<i className="fa fa-warning" aria-hidden="true" />
						O Sienge não permite que a condição de pagamento seja exportada.
						Você poderá adicionar essa informação diretamente na negociação {negociacao_id}.
					</span>
				</div>
			</div>
		</div>
	);

	return (
		<div>
			<span>{status && status === 'RUNNING' && statusCompra !== 'rascunho' && !swi_cotacao.cotacao_id ? iniciandoExport : ''}</span>
			<span>{(cotacaoId || swi_cotacao.cotacao_id) && statusCompra !== 'rascunho' ? successStatus() : ''}</span>
			<span>{status && status === 'RUNNING' && passo_atual_status !== 'ERROR' && statusCompra !== 'rascunho' && (!swi_cotacao.cotacao_id || swi_cotacao.cotacao_id === null) ? obtendoDadosStatus(passo_atual_tipo) : ''}</span>
			{erroMotivo && (!swi_cotacao.cotacao_id || swi_cotacao.cotacao_id === null) && statusCompra !== 'rascunho' && errorStatus(data)}
		</div>
	);
}

ExportStatus.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(ExportStatus, styles);
