/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Select } from 'antd';

import { CheckOutlined } from '@ant-design/icons';
import { ActionsArea, StatusIcon, StatusCell } from './styled';
import { ReactComponent as EditPen } from '../../icons/edit_pen.svg';
import { ReactComponent as VisualizeEye } from '../../icons/visualize_eye.svg';

const { Option } = Select;

const sortTexto = (a, b) => {
	if (a > b) return 1;
	if (a < b) return -1;
	return 0;
};

const listaStatusSelect = (value) => {
	if (value === 20) return 'Visualização';
	if (value === 30) return 'Ativa';
	return null;
};

const enumStatusObra = (value) => {
	if (value === 10) return <div style={{ display: 'flex' }}> <StatusIcon $status={10} style={{ marginTop: 10, marginRight: 8 }} /> Inativa </div>;
	if (value === 20) {
		return <div style={{ display: 'flex' }}> <StatusIcon $status={20} style={{ marginTop: 10, marginRight: 8 }} /> Ativa </div>;
	}
	if (value === 30) return <div style={{ display: 'flex' }}> <StatusIcon $status={30} style={{ marginTop: 10, marginRight: 8 }} /> Visualização </div>;
};

const checkStatus = (value) => {
	if (value === 20 || value === 30) return <div style={{ display: 'flex', color: '#0076D6' }}> <CheckOutlined style={{ color: '#0076D6', marginTop: 4, marginRight: 8 }} /> {value === 10 ? 'Inativa' : value === 20 ? 'Ativa' : value === 30 ? 'Visualização' : {} } </div>;
};

const emptyCell = '-';

export default (currentUserRole, columnsActions, alteraStatusObra, usuarioAdministrador) => [
	{
		title: 'Obra',
		key: '1',
		dataIndex: 'nome',
		sorter: (a, b) => sortTexto(a.nome, b.nome),
		render: (value, record) => {
			if (value) {
				return (
					<Link to={`/cadastros/obras/${record.id}`} style={{ color: '#0076D6' }}>{value}</Link>
				);
			}
			return emptyCell;
		},
		align: 'left',
		width: 200,
		ellipsis: true,
	},
	{
		title: 'Etapa',
		key: '2',
		dataIndex: 'unidades_construtivas',
		render: (value) => value?.[0]?.fase_atual || emptyCell,
		sorter: (a, b) => a?.[0]?.fase_atual - b?.[0]?.fase_atual,
		width: 120,
		ellipsis: true,
	},
	{
		title: 'Cliente',
		dataIndex: 'cliente',
		width: 120,
		key: '3',
		render: (value) => value?.apelido_interno || emptyCell,
		sorter: (a, b) => sortTexto(a.cliente?.apelido_interno, b.cliente?.apelido_interno),
		ellipsis: true,
	},
	{
		title: 'Empresas',
		dataIndex: 'pessoas',
		width: 120,
		key: '4',
		render: (value) => value?.[0]?.pessoa?.nome || emptyCell,
		sorter: (a, b) => sortTexto(a.pessoas?.[0]?.pessoa?.nome, b.pessoas?.[0]?.pessoa?.nome),
		ellipsis: true,
	},
	{
		title: 'Usuários',
		dataIndex: 'usuarios',
		width: 100,
		key: '5',
		render: (value) => value?.length,
		sorter: (a, b) => a.usuarios.length - b.usuarios.length,
		ellipsis: true,
	},
	{
		title: 'Status',
		dataIndex: 'status',
		width: 120,
		key: '6',
		render: (_, record) => {
			if (record) {
				return (
					<StatusCell>
						<Select
							style={{ width: '100%' }}
							bordered={false}
							showArrow={false}
							value={enumStatusObra(record.status)}
							onChange={() => alteraStatusObra(record)}
							disabled={record.status === 10}
						>
							<Option disabled style={{ backgroundColor: '#D9EEFF' }}>{checkStatus(record.status)}</Option>
							{record.status !== 10 && (
								<Option style={{ alignContent: 'center', textAlign: 'center' }}>{listaStatusSelect(record.status)}</Option>
							)}
						</Select>
					</StatusCell>
				);
			}

			return emptyCell;
		},
		sorter: (a, b) => a.status - b.status,
		defaultSortOrder: 'descend',
		ellipsis: true,
	},
	{
		title: 'Ações',
		dataIndex: 'actions',
		key: '7',
		width: 110,
		render: (_, record) => (
			<ActionsArea>
				{currentUserRole === 'admin' &&
					<>
						<Tooltip placement="top" title={`Editar a obra ${record.nome || ''}`} color="#0076D6">
							<EditPen onClick={() => (usuarioAdministrador === false ? null : columnsActions('edit', record))} />
						</Tooltip>
					</>
				}
				<Tooltip placement="top" title={`Visualizar a obra ${record.nome || ''}`} color="#0076D6">
					<VisualizeEye onClick={() => (usuarioAdministrador === false ? null : columnsActions('view', record))} />
				</Tooltip>
			</ActionsArea>
		),
	},
];
