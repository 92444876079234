import { takeLatest, put } from 'redux-saga/effects';
import {
	AVALIACOES_REQUEST,
	AVALIACOES_SUCCESS,
	AVALIACOES_FAILED,
} from './types';

// Services
import { get } from '../../services/get';

export function* fetchAvaliacoes({ page, fornecedor }) {
	try {
		const url = fornecedor
			? `/avaliacao/v2/listar/usuario_construtor?nome=${fornecedor}&page=${page}`
			: `/avaliacao/v2/listar/usuario_construtor?page=${page}`;
		const { data } = yield get(url);
		yield put({ type: AVALIACOES_SUCCESS, payload: data });
	} catch (error) {
		yield put({ type: AVALIACOES_FAILED, error });
	}
}

// saga
export default function* avaliacoesSaga() {
	yield takeLatest([AVALIACOES_REQUEST], fetchAvaliacoes);
}
