import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Functions
import { setDecimalsToString } from '../../../_functions/_numbers';

class SelectOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { option, handleEdition } = this.props;
		handleEdition([option]);
	}

	render() {
		const { unidade, valor } = this.props.option;
		const _valor = typeof valor === 'number' ? setDecimalsToString(valor) : valor;
		const option_view = `${_valor} ${unidade}`;

		return (
			<li data-dropchild>
				<Link to="#" onClick={this.handleOption} styleName={option_view === this.props.option_view && 'selected'}>{option_view}</Link>
			</li>
		);
	}
}

SelectOption.propTypes = {
	option: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	option_view: PropTypes.string,
};

SelectOption.defaultProps = {
	option_view: '',
};

export default CSSModules(SelectOption, styles, { allowMultiple: true });
