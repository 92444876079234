import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

/* eslint-disable */
const IIStatusView = ({ item_on_edition }) => {
	const item_integrado = item_on_edition.item_integrado || {};
	const item_integrado_id = item_integrado.id || null;
	if (item_integrado_id === null) {
		return (
			<div styleName="status">
				<span styleName="label">Nenhum item selecionado</span>
			</div>
		);
	}

		return null;

	// detalhe
	const detalhe_item_integrado = (item_integrado || {}).detalhe || {};
	const detalhe_id = (detalhe_item_integrado || {}).id_no_swi || null;

	const itens_associados = (item_integrado || {}).itens_associados || [];
	const item_associado = itens_associados
		.filter(i => (
			i.detalhe_item_integrado.id_no_swi === detalhe_id
			|| (i.detalhe_item_integrado.id_no_swi === undefined && detalhe_id === null)
		))[0] || {};
	const item_associado_id = item_associado.id || null;
	const aprovado = item_associado.aprovado || false;
	const cliente_associou = item_on_edition.cliente_associou || true;
	const status_list = {
		nao_configurado: item_associado_id === null || !cliente_associou,
		pre_configurado: item_associado_id !== null && !aprovado && cliente_associou,
		configurado: item_associado_id !== null && aprovado && cliente_associou,
	};
	const status = Object.keys(status_list)
		.map(c => c)
		.filter(c => status_list[c])
		.join(' ')
		|| 'nao_configurado';
	const status_view = {
		nao_configurado: 'Não Configurado',
		pre_configurado: 'Pré Configurado',
		configurado: 'Configurado',
	};

	return (
		<div styleName="status">
			<span styleName="label">Status: </span>
			<span styleName={status}>{status_view[status]}</span>
		</div>
	);
};

IIStatusView.propTypes = {
	item_on_edition: PropTypes.object,
};

IIStatusView.defaultProps = {
	item_on_edition: {},
};

export default CSSModules(IIStatusView, styles, { allowMultiple: true });
