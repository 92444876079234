import _ from 'lodash';

export const splitName = (fileName, size) => {
	if (fileName.length > size) {
		const shortName = _.slice(fileName, 0, size).join('');
		const type = _.last(_.split(fileName, '.'));
		return `${shortName}... .${type}`;
	}
	return fileName;
};

export const isImage = fileName => {
	const type = _.toLower(_.last(_.split(fileName, '.')));
	if (
		type === 'jpg'
		|| type === 'png'
		|| type === 'jpeg'
		|| type === 'gif'
		|| type === 'jpg2'
		|| type === 'jp2'
		|| type === 'tiff'
	) return true;
	return false;
};
