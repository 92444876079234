import * as Types from './types';

export const setDrawerObraFormInstance = (form) => ({
	type: Types.DRAWER_OBRA_SET_FORM_INSTANCE,
	payload: { form },
});

// flag copy para copiar ou não os dados.
export const handleDrawerCopyEmpresa = (copy = true) => ({
	type: Types.DRAWER_OBRA_HANDLE_CHANGE_TO_CPY_EMPRESA,
	payload: { copy },
});

export const openDrawerCopyUsuario = () => ({
	type: Types.DRAWER_OBRA_CHANGE_TO_CPY_USUARIO,
});

export const handleHaveChangedAssociaUsuario = (changed = false) => ({
	type: Types.DRAWER_OBRA_ASSOCIA_USUARIO_HAVE_CHANGED,
	payload: { changed },
});

export const closeDrawerCopyUsuario = (copy) => ({
	type: Types.DRAWER_OBRA_CLOSE_CPY_USUARIO,
	payload: { copy },
});

export const handleHaveChangedAssociaEmpresa = (changed = false) => ({
	type: Types.DRAWER_OBRA_ASSOCIA_EMPRESA_HAVE_CHANGED,
	payload: { changed },
});

export const handleChangedCpyEmpresa = (changed = false) => ({
	type: Types.DRAWER_OBRA_COPIA_EMPRESA_HAVE_CHANGED,
	payload: { changed },
});

// Sagas de obras vai chamar esse para avançar com o drawer
export const associaEmpresaSuccess = () => ({
	type: Types.DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_SUCCESS,
});

export const openNewObraDrawer = () => ({
	type: Types.DRAWER_OBRA_OPEN,
});

export const openObraEditDrawer = (dataToDrawer) => ({
	type: Types.DRAWER_OBRA_OPEN_EDIT,
	payload: { dataToDrawer },
});

export const closeObraDrawer = () => ({
	type: Types.DRAWER_OBRA_CLOSE,
});

export const obraDrawerNextStep = () => ({
	type: Types.DRAWER_OBRA_NEXT_STEP_REQUEST,
});

export const obraDrawerNextStepSuccess = (stepStatus) => ({
	type: Types.DRAWER_OBRA_NEXT_STEP,
	payload: { stepStatus },
});

export const obraDrawerPrevStep = () => ({
	type: Types.DRAWER_OBRA_PREV_STEP_REQUEST,
});

export const obraDrawerPrevStepSuccess = (stepStatus) => ({
	type: Types.DRAWER_OBRA_PREV_STEP,
	payload: { stepStatus },
});

export const createdObra = (data) => ({
	type: Types.DRAWER_OBRA_SUBMIT_OBRA_SUCCESS,
	payload: { data },
});
