import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import SiengeInicio from './SiengeInicio';

// Functions
import { _get } from '../../../components/_functions/_requests';

class SiengeInicioContainer extends Component {
	state = {
		loading: 0,
	};

	componentDidMount() {
		document.title = 'Integração - Conaz';
		this.checkLoad(this.props.user);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.checkLoad(nextProps.user);
	}

	checkLoad(user) {
		const { loading } = this.state;
		const { cliente_id, sienge, sienge_liberado } = user;
		const { history } = this.props;

		if (cliente_id !== 0 && !sienge_liberado) {
			history.replace('/cotacoes');
			return;
		}

		if (
			cliente_id !== 0
			&& sienge.credenciais.id !== undefined
			&& loading === 0
		) {
			this.setState({ loading: 2 });
			return;
		}

		if (
			cliente_id !== 0
			&& sienge.credenciais.id === undefined
			&& loading !== 1
			&& !sienge.loaded
		) {
			this.buscaAccountObras(user);
		}
	}

	buscaAccountObras(user) {
		this.setState({ loading: 1 });
		const { cliente_id } = user;
		const url_credenciais = `/conaz/v2/integracoes/credenciais/?swi=1&cliente_id=${cliente_id}`;
		const url_obras = `/conaz/v2/integracoes/obras_associadas?swi=1&cliente_id=${cliente_id}`;
		const url_itens = `/conaz/v2/integracoes/itens_integrados?swi=1&cliente_id=${cliente_id}&per_page=1&fields=id`;
		const url_fornecedores = `/conaz/v2/integracoes/fornecedores_integrados/associados?swi=1&cliente_id=${cliente_id}&per_page=1&fields=id`;
		const all_urls = [
			_get(url_credenciais, {}, []),
			_get(url_obras, {}, []),
			_get(url_itens, {}, []),
			_get(url_fornecedores, {}, []),
		];
		Promise.all(all_urls).then(responses => {
			this.verifyAccount(responses);
		}).catch(() => {
			this.setState({ loading: 2 });
		});
	}

	verifyAccount(responses) {
		const credenciais = (((responses || [])[0] || {}).data || [])[0] || {};
		const login_configurado = credenciais.id !== undefined;
		const sienge = {
			login_configurado,
			obras_configuradas: false,
			itens_configurados: false,
			loaded: false,
			credenciais,
		};
		this.verifyObras(responses, sienge);
	}

	verifyObras(responses, sienge) {
		const obras = ((responses || [])[1] || {}).data || [];
		let obras_configuradas = obras
			.filter(o => o.id_no_swi !== '').length > 0;

		if (!sienge.login_configurado) {
			obras_configuradas = false;
		}

		const _sienge = {
			...sienge,
			obras_configuradas,
		};
		this.verifyItens(responses, _sienge);
	}

	verifyItens(responses, sienge) {
		const itens = (((responses || [])[2] || {}).data || {}).result || [];
		let itens_configurados = itens
			.filter(o => o.id_no_swi !== '').length > 0;

		if (!sienge.login_configurado) {
			itens_configurados = false;
		}

		const _sienge = {
			...sienge,
			itens_configurados,
			loaded: true,
		};
		this.verifyFornecedores(responses, _sienge);
	}

	verifyFornecedores(responses, sienge) {
		const fornecedores = (((responses || [])[3] || {}).data || {}).result || [];
		let fornecedores_configurados = fornecedores
			.filter(f => f.id_no_swi !== '').length > 0;

		if (!sienge.login_configurado) fornecedores_configurados = false;

		const _sienge = {
			...sienge,
			fornecedores_configurados,
			loaded: true,
		};
		const { updateUser } = this.props;
		updateUser(['sienge'], _sienge);
		this.setState({ loading: 2 });
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>

				<SiengeInicio
					{...this.state}
					{...this.props} />

			</LayoutWrapper>
		);
	}
}

SiengeInicioContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(SiengeInicioContainer));
