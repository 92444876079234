import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import CNPJFornecedorSienge from '../../../../../../components/_parts/CNPJFornecedorSienge';

// Functions
import { setClasses } from '../../../../../../components/_functions/_setClasses';

class IntegracaoSearchResult extends Component {
	state = {
		adding_fornecedor: false,
		error_adding: false,
	};

	addFornecedorIntegrado = () => {
		const { adding_fornecedor } = this.state;
		if (adding_fornecedor) return;

		this.setState({ adding_fornecedor: true, error_adding: false }, () => {
			const { fornecedor, saveFornecedorSienge, handleFornecedorAssociado } = this.props;
			saveFornecedorSienge(fornecedor);
			this.setState({ adding_fornecedor: false }, () => handleFornecedorAssociado(fornecedor));
		});
	}

	render() {
		const { adding_fornecedor, error_adding } = this.state;
		const { fornecedor } = this.props;

		const fornecedor_classes = {
			adding: adding_fornecedor,
			error: error_adding,
		};
		const fornecedor_class = setClasses('item-result', fornecedor_classes);

		// id sienge
		const fornecedor_integrado_id = (fornecedor || {}).id_no_swi || null;

		return (
			<button
				type="button"
				styleName={fornecedor_class}
				onClick={this.addFornecedorIntegrado}>
				<i className="fa fa-angle-right" aria-hidden="true" />
				<div className="list-group-item-heading" styleName="results-list-h4">
					{fornecedor.nome}
				</div>
				<div styleName="results-list-p">Código Sienge: <b>{fornecedor_integrado_id}</b></div>
				<div styleName="results-list-p">
					CNPJ: <CNPJFornecedorSienge documento={fornecedor.documento} />
				</div>
				{error_adding && (
					<div styleName="error-alert">Erro ao associar o fornecedor. Tente novamente.</div>
				)}
			</button>
		);
	}
}

IntegracaoSearchResult.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	handleFornecedorAssociado: PropTypes.func.isRequired,
};

export default CSSModules(IntegracaoSearchResult, styles, { allowMultiple: true });
