import { getLocalstorageData } from './_getLocalstorageData';
import { _post } from './_requests';

function reportResponse({ is_test, response }) {
	const full_url = window.location.href;
	const email = getLocalstorageData('user', 'email');
	const error_text = `*Url:* ${full_url}
*E-mail do cliente:* ${email}
*Print da response:* ${response}
----`;
	const channel = is_test ? '#erros-dev' : '#erros';

	_post('/conaz/legacy/send_slack', {
		channel,
		username: 'Clientes____',
		text: error_text,
		icon_emoji: ':triangular_flag_on_post:',
	});
}

export const _sendResponseToSlackToMap = (data) => {
	const is_test = (window.location.href.indexOf('portal.conazweb.com.br') === -1);
	const response = JSON.stringify(data);

	function sendResponse() {
		reportResponse({ is_test, response });
	}

	function checkResponse() {
		const { result } = data || {};
		const wrong_result = (
			result === undefined
			|| result === null
			|| result === ''
			|| result === 0
		);
		if (wrong_result) {
			return sendResponse();
		}
	}

	return checkResponse();
};

export const _sendResponseToSlackToMap2 = (responses) => {
	const is_test = (window.location.href.indexOf('portal.conazweb.com.br') === -1);
	const response = JSON.stringify(responses);

	function sendResponse() {
		reportResponse({ is_test, response });
	}

	function checkResponse() {
		const wrong_result = (
			responses === undefined
			|| responses === null
			|| responses === ''
			|| responses === 0
		);
		if (wrong_result) {
			return sendResponse();
		}
	}

	return checkResponse();
};
