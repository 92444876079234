import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import createCompraHoc from '../../../../hocs/createCompraHoc/createCompraHoc';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

class CriarCompraQCButton extends Component {
	criaCompra = () => {
		const { oc, pedido, updateModals, createCompra } = this.props;
		createCompra(oc, pedido, updateModals);
	}

	render() {
		const { criando, has_active_compras, status } = this.props;
		const button_class = criando === 1
			? 'btn btn-secondary-conaz round-border loading'
			: 'btn btn-secondary-conaz round-border';
		const on_click_func = criando === 1
			? null
			: this.criaCompra;
		const text = has_active_compras
			? 'Comprar novamente'
			: 'Comprar';
		const button_html = criando === 1
			? <><LoadingButton />{text}</>
			: text;

		return (
			<button
				type="button"
				className={button_class}
				styleName="button-criar-compra"
				onClick={on_click_func}
				id="criar-compra-qc-button"
				disabled={status === -30}
			>
				{button_html}
			</button>
		);
	}
}

CriarCompraQCButton.propTypes = {
	oc: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	createCompra: PropTypes.func.isRequired,
	criando: PropTypes.number.isRequired,
	pedido: PropTypes.object.isRequired,
	has_active_compras: PropTypes.bool,
	status: PropTypes.number,
};

CriarCompraQCButton.defaultProps = {
	has_active_compras: false,
	status: null,
};

const _CriarCompraQCButton = CSSModules(CriarCompraQCButton, styles, { allowMultiple: true });
export default createCompraHoc(_CriarCompraQCButton);
