export const UPDATE_COMPRAS = 'UPDATE_COMPRAS';
export const UPDATE_COMPRAS_RAW = 'UPDATE_COMPRAS_RAW';
export const COMPRAS_REQUESTED = 'COMPRAS_REQUESTED';
export const COMPRAS_RECEIVED = 'COMPRAS_RECEIVED';
export const COMPRAS_INITIAL_REQUESTED = 'COMPRAS_INITIAL_REQUESTED';
export const COMPRAS_INITIAL_RECEIVED = 'COMPRAS_INITIAL_RECEIVED';
export const COMPRAS_REQUEST_FAILED = 'COMPRAS_REQUEST_FAILED';
export const COMPRA_REQUESTED = 'COMPRA_REQUESTED';
export const COMPRA_RECEIVED = 'COMPRA_RECEIVED';
export const COMPRA_REQUEST_FAILED = 'COMPRA_REQUEST_FAILED';
