import PropTypes from 'prop-types';

const item_preenchido = {
	caracteristicas_preenchidas: PropTypes.objectOf(
		PropTypes.shape({
			caracteristica: PropTypes.shape({
				id: PropTypes.number.isRequired,
				item_id: PropTypes.number.isRequired,
				nome: PropTypes.string.isRequired,
				opcoes: PropTypes.array.isRequired,
				ordem: PropTypes.number.isRequired,
				selecao: PropTypes.number.isRequired,
				tipo_de_valor: PropTypes.string.isRequired,
				visao_construtor: PropTypes.number.isRequired,
				visao_fornecedor: PropTypes.number.isRequired,
			}),
			opcoes_preenchidas: PropTypes.arrayOf(
				PropTypes.shape({
					caracteristica_id: PropTypes.number,
					id: PropTypes.number,
					unidade: PropTypes.string,
					validada: PropTypes.bool,
					valor: PropTypes.oneOfType([
						PropTypes.string,
						PropTypes.number,
					]),
				}),
			),
		}),
	),
	item: PropTypes.shape({
		caracteristicas: PropTypes.array,
		descricao: PropTypes.string,
		eh_servico: PropTypes.bool,
		grupo: PropTypes.object,
		id: PropTypes.number,
		itens_relacionados_obrigatorios: PropTypes.array,
		itens_relacionados_opcionais: PropTypes.array,
		marcas: PropTypes.array,
		modelo: PropTypes.number,
		nome: PropTypes.string,
		sinonimos: PropTypes.array,
		unidades: PropTypes.array,
	}),
};

const item_de_resposta = {
	criado_em: PropTypes.string,
	editado: PropTypes.bool,
	id: PropTypes.number,
	informacoes_adicionais: PropTypes.string,
	item_de_pedido: PropTypes.object,
	item_preenchido: PropTypes.object,
	item_resposta_origin_id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
	preco_unitario: PropTypes.number,
	preenchido_pelo_construtor: PropTypes.bool,
	quantidade: PropTypes.number,
	rc_id: PropTypes.number,
	status: PropTypes.number,
	unidade: PropTypes.string,
};

const itens_shape = PropTypes.shape({
	by_id: PropTypes.objectOf(
		PropTypes.shape({
			compra_id: PropTypes.number.isRequired,
			editado: PropTypes.bool.isRequired,
			id: PropTypes.number.isRequired,
			preco_unitario: PropTypes.number.isRequired,
			quantidade: PropTypes.number.isRequired,
			status: PropTypes.number.isRequired,
			item_preenchido: PropTypes.shape(item_preenchido).isRequired,
			item_de_resposta: PropTypes.shape(item_de_resposta).isRequired,
			unidade: PropTypes.string,
			informacoes_adicionais: PropTypes.string,
		}),
	),
	all_ids: PropTypes.array.isRequired,
});

export default itens_shape;
