import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../_loadings/LoadingPage/LoadingPage';

// Functions
import { _get } from '../../../_functions/_requests';
import { formatNumbers } from '../../../_functions/_formatNumbers';
import { documentType } from '../../../_functions/_documentType';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class LeftModalClientePerfil extends Component {
	constructor() {
		super();
		this.state = {
			request_state: 0,
			cliente: {},
		};
	}

	UNSAFE_componentWillMount() {
		this.requestCliente();
	}

	requestCliente() {
		const { cliente_id } = this.props;
		this.setState({ request_state: 1 });
		_get(`/conaz/v2/clientes/${cliente_id}`).then(response => {
			const cliente = { ...response.data };
			const request_state = 2;
			this.setState({ cliente, request_state });
		}).catch(error => {
			this.setState({ request_state: 3 });
			handleRequestErrors(error);
		});
	}

	render() {
		const { request_state, cliente } = this.state;
		const { obra_endereco, obra_pessoas } = this.props;

		const { bairro, cidade, estado } = obra_endereco;
		const statusList = [
			{ value: 10, label: 'Trial' },
			{ value: 20, label: 'Em contrato' },
			{ value: 30, label: 'Desativado' },
		];

		const c1 = (obra_pessoas.filter(p1 => p1.papeis.filter(p2 => p2 === 1).length > 0)[0] || {});
		const c1_nome = (c1.pessoa || {}).nome || '';
		const c1_razao_social = (c1.pessoa || {}).razao_social || '';
		const c1_razao_social_view = c1_razao_social !== ''
			? <>{c1_razao_social}<br /></>
			: '';
		const c1_doc_type = documentType(c1.pessoa.documento);
		const c1_documento = (
			c1.pessoa.documento !== '' &&
			c1.pessoa.documento !== undefined &&
			c1.pessoa.documento !== null
		) ? <><span styleName="label">{c1_doc_type.toUpperCase()}:</span> {formatNumbers(c1.pessoa.documento, c1_doc_type)}</> : <em>Sem registro</em>;

		/**
		 * Cliente 2 = responsavel pagamento
		 *
		 *  - filtra usuários por papel = 2
		 *  - pega nome
		 *  - tamanho do documento para verificação
		 */
		const c2 = (obra_pessoas.filter(p1 => p1.papeis.filter(p2 => p2 === 2).length > 0)[0] || {});
		const c2_nome = (c2.pessoa || {}).nome || '';
		const c2_razao_social = (c2.pessoa || {}).razao_social || '';
		const c2_razao_social_view = c2_razao_social !== ''
			? <>{c2_razao_social}<br /></>
			: '';
		const c2_doc_type = documentType(c2.pessoa.documento);
		const c2_documento = (
			c2.pessoa.documento !== '' &&
			c2.pessoa.documento !== undefined &&
			c2.pessoa.documento !== null
		) ? <><span styleName="label">{c2_doc_type.toUpperCase()}:</span> {formatNumbers(c2.pessoa.documento, c2_doc_type)}</> : <em>Sem registro</em>;

		const getStatusLabel = value => {
			const filtered = statusList.filter(st => st.value === value);
			return filtered.length > 0 ? filtered[0].label : 'Indefinido';
		};

		switch (request_state) {
		/* ========================================================================== *\
				Loading
		\* ========================================================================== */
		default:
		case 0:
		case 1:
			return <LoadingPage />;

		/* ========================================================================== *\
				Error
		\* ========================================================================== */
		case 3:
			return (
				<div styleName="error-wrapper">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => this.props.updateModals('perfil_cliente', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<i className="fa fa-exclamation-circle" aria-hidden="true" />
					<h3>Desculpe, tivemos um problema!</h3>
					<p>Atualize a página apertando F5 para tentar novamente e/ou entre em contato com nossa equipe de suporte.</p>
				</div>
			);

		/* ========================================================================== *\
				Ok
		\* ========================================================================== */
		case 2: {
			return (
				<div styleName="modal-content">
					<div styleName="content">
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => this.props.updateModals('perfil_cliente', false)}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<h3>{cliente.apelido_interno}</h3>
						<div styleName="cnpj">
							<span styleName="label">Status:</span> {getStatusLabel(cliente.status)}
						</div>
						<div styleName="cnpj">
							<span styleName="label">Localização da obra:</span> {`${bairro}, ${cidade} - ${estado}`}
						</div>
						<div styleName="cnpj">
							<span styleName="label">Responsável pela obra:</span>
							<h4>{c1_nome}</h4>
							{c1_razao_social_view}
							{c1_documento}
						</div>
						<hr />
						<div styleName="cnpj">
							<span styleName="label">Responsável pelo pagamento:</span>
							<h4>{c2_nome}</h4>
							{c2_razao_social_view}
							{c2_documento}
						</div>
						<hr />
						{cliente.observacoes_criticas && (
							<b style={{ color: '#ff4d4d' }}>Perfil crítico</b>
						)}
						{cliente.perfil && (
							<div styleName="cnpj">
								<span styleName="label">Perfil do cliente: </span>
								<p>{cliente.perfil}</p>
							</div>
						)}
					</div>
				</div>
			);
		}
		}
	}
}

LeftModalClientePerfil.propTypes = {
	updateModals: PropTypes.func,
	cliente_id: PropTypes.number.isRequired,
	obra_endereco: PropTypes.object,
	obra_pessoas: PropTypes.array,
};

LeftModalClientePerfil.defaultProps = {
	updateModals: () => {},
	obra_endereco: {
		bairro: '',
		cidade: '',
		estado: '',
	},
	obra_pessoas: [],
};

export default CSSModules(LeftModalClientePerfil, styles, { allowMultiple: true });
