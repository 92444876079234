import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CNPJFornecedorSienge from '../CNPJFornecedorSienge';

class SiengeFornecedoresRow extends Component {
	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	render() {
		const { fornecedor, handleSelectedFornecedor } = this.props;
		const { id_no_swi, nome, fornecedores_associados, documento } = fornecedor;
		let associado;
		if (fornecedores_associados[0]) {
			const vendedor = ((
				fornecedores_associados || [])[0] || {})
				.usuario_fornecedor;

			const fornecedor_conaz = (((
				vendedor.fornecedores || [])[0] || {})
				.fornecedor || {})
				.nome;
			associado = (
				<>
					<div>{fornecedor_conaz}</div>
					<span>Vendedor: {vendedor.nome}</span>
				</>
			);
		} else associado = (<span>Não Configurado</span>);

		const icons_status = {
			associado: (<i className="fa fa-check" style={{ color: '#53c33f' }} aria-hidden="true" />),
			no_associado: null,
			aguardando_confirmacao: (<i className="fa fa-check" style={{ color: '#666c6d' }} aria-hidden="true" />),
		};

		const status_class = fornecedores_associados.length === 0
			? 'no_associado'
			: 'associado';

		return (
			<button
				type="button"
				onClick={() => handleSelectedFornecedor(fornecedor)}
				styleName="iten-button">
				<div styleName="itens-wrapper">
					{icons_status[status_class]}
					<div styleName="titulo">
						<div styleName="justify-flex-start">
							<span styleName={status_class}>{nome}</span>
						</div>
						<div styleName="justify-flex-start">
							<p>
								<span>Código Sienge: {id_no_swi}</span>
							</p>
						</div>
						<div styleName="justify-flex-start">
							<p>
								<span>CNPJ: <CNPJFornecedorSienge documento={documento} /></span>
							</p>
						</div>
					</div>
					<div styleName="iten-associado">{associado}</div>
				</div>
			</button>
		);
	}
}

SiengeFornecedoresRow.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	handleSelectedFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(SiengeFornecedoresRow, styles, { allowMultiple: true });
