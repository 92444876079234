export const formatNumbers = (value, mask) => {
	const only_numbers = value.replace(/\D/g, '');

	switch (mask) {
	case 'cnpj': {
		if (only_numbers.length === 14) {
			const x1 = only_numbers.match(
				/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
			);
			return `${x1[1]}.${x1[2]}.${x1[3]}/${x1[4]}-${x1[5]}`;
		}
		return only_numbers;
	}

	case 'cpf': {
		let aux;
		if (only_numbers.length === 11) {
			aux = only_numbers.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
			return `${aux[1]}.${aux[2]}.${aux[3]}-${aux[4]}`;
		}
		if (only_numbers.length < 11 && only_numbers > 0) {
			aux = `${'0'.repeat(
				11 - only_numbers.length,
			)}${only_numbers}`.match(/(\d{3})(\d{3})(\d{3})(\d{2})/);
			return `${aux[1]}.${aux[2]}.${aux[3]}-${aux[4]}`;
		}
		return only_numbers;
	}

	case 'cep': {
		let aux;
		if (only_numbers.length === 8) {
			aux = only_numbers.match(/(\d{5})(\d{3})/);
			return `${aux[1]}-${aux[2]}`;
		}
		if (only_numbers.length < 8 && only_numbers > 0) {
			aux = `${'0'.repeat(
				8 - only_numbers.length,
			)}${only_numbers}`.match(/(\d{5})(\d{3})/);
			return `${aux[1]}-${aux[2]}`;
		}
		return only_numbers;
	}

	case 'telefone': {
		switch (only_numbers.length) {
		case 8: {
			const x2 = only_numbers.match(/(\d{4})(\d{4})/);
			return `${x2[1]}-${x2[2]}`;
		}
		case 9: {
			const x3 = only_numbers.match(/(\d{5})(\d{4})/);
			return `${x3[1]}-${x3[2]}`;
		}
		case 10: {
			const x4 = only_numbers.match(/(\d{2})(\d{4})(\d{4})/);
			return `(${x4[1]}) ${x4[2]}-${x4[3]}`;
		}
		case 11: {
			if (
				['0300', '0500', '0800', '0900'].indexOf(
					only_numbers.substring(0, 4),
				) !== -1
			) {
				const x5 = only_numbers.match(
					/(\d{0,4})(\d{0,3})(\d{0,4})/,
				);
				return `${x5[1]} ${x5[2]} ${x5[3]}`;
			}
			const x5 = only_numbers.match(/(\d{2})(\d{5})(\d{4})/);
			return `(${x5[1]}) ${x5[2]}-${x5[3]}`;
		}
		default:
			return only_numbers;
		}
	}

	default:
		return value;
	}
};
