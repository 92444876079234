/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { setClasses } from '../../../_functions/_setClasses';


const StepV2 = ({
	stage,
	current,
	label,
	descricao,
	toggleHistory,
	done,
	showHistory,
	size,
}) => {
	const is_active = stage <= current;
	const step_classes = {
		is_active,
		current,
		cancelado: label === 'Cancelada em' || label === 'Compra recusada em',
		registred: label === 'Registrada e aprovada em ' || label === 'Confirmada pelo fornecedor em' || label === 'Confirmada por você em' || done,
	};
	const step_wrapper_class = setClasses('step-wrapper size-1', step_classes);
	const isDone =
		label === 'Registrada e aprovada em '
		|| label === 'Confirmada pelo fornecedor em '
		|| label === 'Confirmada por você em '
		|| done;
	const one = size === 1;
	return (
		<>
			{
				!one
				&& (
					<div styleName="first">
						<p>{size - 1}+</p>
					</div>
				)
			}
			<div styleName={one ? `${step_wrapper_class} one` : step_wrapper_class}>
				<div styleName={one ? 'content one' : 'content'}>
					{!isDone ? (
						<div styleName="dot-current" />
					) : (
						<i className="fa fa-check-circle fa-fw" aria-hidden="true" />
					)}
					<div styleName="descricao">
						{label}
						{descricao}
					</div>
				</div>
				<button
					onClick={toggleHistory}
					styleName={`button-toggle${one ? ' one' : ''}`}>
					<i className="fa fa-chevron-down" aria-hidden="true" styleName={showHistory ? 'up' : 'down'} />
				</button>
			</div>
		</>
	);
};

StepV2.propTypes = {
	stage: PropTypes.number,
	done: PropTypes.bool,
	current: PropTypes.number,
	showHistory: PropTypes.bool,
	toggleHistory: PropTypes.func,
	label: PropTypes.string,
	descricao: PropTypes.object,
	size: PropTypes.number,
};

StepV2.defaultProps = {
	done: false,
	stage: 1,
	current: 1,
	descricao: null,
	label: '',
	size: 1,
	showHistory: false,
	toggleHistory: () => null,
};

export default CSSModules(StepV2, styles, { allowMultiple: true });
