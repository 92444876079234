import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ButtonRound from '../../../../_buttons/ButtonRound/ButtonRound';
import IIStatusView from './IIStatusView';

// Functions
import { _put } from '../../../../../_functions/_requests';

class InfoExpanded extends Component {
	desassociaItem = () => {
		const { item_on_edition, updateFullItemById } = this.props;
		const new_item = {
			...item_on_edition,
			item_integrado: {},
		};
		updateFullItemById(item_on_edition.id, new_item);
		// put no item
		const item_id = item_on_edition.id || 0;
		const url = `/conaz/v2/itens_de_compra/${item_id}`;
		const params = {
			id_no_swi: '',
			id_no_swi_detalhe: '',
			force: true,
		};
		_put(url, params);
	}

	render() {
		const { item_on_edition } = this.props;
		const item_integrado = item_on_edition.item_integrado || {};
		const {
			id_no_swi,
			unidade,
			nome,
		} = item_integrado;

		// detalhe
		const detalhe = (item_integrado || {}).detalhe || {};
		const detalhe_id = (detalhe || {}).id_no_swi || null;
		const detalhe_valor = (detalhe || {}).valor || '';

		// const detalhe_url = detalhe_id !== null
		// 	? `&detalhe=${detalhe_id}`
		// 	: '';
		// const edit_item_url = `
		// 	${window.location.origin}/integracao/sienge/itens
		// 	?id=${id_no_swi}${detalhe_url}
		// `;

		const pre_quantidade = item_on_edition.pre_quantidade || 0;
		const pre_unidade = item_on_edition.pre_unidade || '';
		const show_solicitacao_data = pre_quantidade !== 0 || pre_unidade !== '';

		return (
			<>
				<div styleName="title">
					Insumo Sienge
					{/*
					<a href={edit_item_url} target="_blank" rel="noopener noreferrer">
						<ButtonRound
							size="small"
							icon="fa fa-external-link-square"
							tooltip_text="Configurar associação"
							left="-70%"
							first_of_type />
					</a>
					*/}
					<ButtonRound
						size="small"
						icon="fa fa-exchange"
						tooltip_text="Remover item associado"
						left="-70%"
						first_of_type
						onClick={this.desassociaItem} />
				</div>
				<div styleName="code">
					<span>Código Sienge:</span>
					<p>{id_no_swi}</p>
				</div>
				<div styleName="name">
					<div>{nome}</div>
					<span>Unidade: </span>
					<b>{unidade}</b>
				</div>
				{detalhe_id !== null && (
					<>
						<div styleName="code">
							<span>ID Detalhe:</span>
							<p>{detalhe_id}</p>
						</div>
						<div styleName="name">
							<div>{detalhe_valor}</div>
						</div>
					</>
				)}
				<IIStatusView
					item_on_edition={item_on_edition} />
				{show_solicitacao_data && (
					<div styleName="solicitacao-data">
						Importado da solicitação: <b style={{ color: '#333333' }}>{pre_quantidade} {pre_unidade}</b>
					</div>
				)}
			</>
		);
	}
}

InfoExpanded.propTypes = {
	item_on_edition: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
};

export default CSSModules(InfoExpanded, styles, { allowMultiple: true });
