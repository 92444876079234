import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { connect } from 'react-redux';
import { Form, Steps } from 'antd';
import { StyledDrawer } from './styled';

// Redux HOC
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// steps
import CreateObraForm from '../CreateObraForm/CreateObraForm';
import AssociaEmpresaForm from './steps/AssociaEmpresa';
import AssociaUsuarioForm from './steps/AssociaUsuario/AssociaUsuario';

import Footer from './DrawerObrasFooter';
import { ReactComponent as Exclamacao } from '../../icons/exclamacao_outlined.svg';

import modes from './modesTypes';

const stepsComponents = {
	0: CreateObraForm,
	1: AssociaEmpresaForm,
	2: AssociaUsuarioForm,
};

const stepsNames = {
	CADASTRO_OBRAS: 0,
	ASSOCIA_EMPRESAS: 1,
	ASSOCIA_USUARIOS: 2,
};

function DrawerObras({ mode, visible, onClose, ...props }) {
	const [form] = Form.useForm();
	const userRoleLink = 'https://produtosautodoc.zendesk.com/hc/pt-br/articles/4416782346643-O-que-s%C3%A3o-os-pap%C3%A9is-dos-usu%C3%A1rios-';

	const {
		setDrawerObraFormInstance,
		handleHaveChangedAssociaEmpresa,
		AssociaEmpresa,
		obrasDrawer,
		obras,
		user,
		obraDrawerNextStep,
		obraDrawerPrevStep,
		createObra,
		editObra,
	} = props;

	const disableAll = obras.actionFetching || obrasDrawer.obraCadastrada;

	const [usuarioAdministrador, setUsuarioAdministrador] = useState(false);
	const [removeIdObra, setRemoveIdObra] = useState(localStorage.getItem('obra_id') || null);

	const getDrawerTitle = (m) => {
		if (m === modes.VIEW) return 'Visualização de obra';
		if (m === modes.EDIT) return 'Edição de obra';
		return 'Cadastro de nova obra';
	};

	const nextStep = () => {
		if (obrasDrawer.currentStep + 1 < obrasDrawer.maxStep) {
			obraDrawerNextStep();
		}
	};

	const prevStep = () => {
		if (obrasDrawer.currentStep - 1 >= obrasDrawer.minStep) {
			obraDrawerPrevStep();
		}
	};

	const handleClose = () => {
		onClose();
	};

	const verifyEmpresaAssociaHaveChanges = (values) => {
		const {
			empresa_responsavel_obra,
			empresa_responsavel_fatura,
		} = values;

		if (empresa_responsavel_fatura || empresa_responsavel_obra) return true;
		return false;
	};

	const verifyFieldsTouched = (changedValues) => {
		if (verifyEmpresaAssociaHaveChanges(changedValues)) {
			handleHaveChangedAssociaEmpresa(true);
		} else {
			handleHaveChangedAssociaEmpresa(false);
		}
	};

	// middleware para trigger as verificações do form (requireds).
	const stepSubmit = () => {
		if (obrasDrawer.currentStep === stepsNames.CADASTRO_OBRAS) {
			form.submit();
		}

		if (obrasDrawer.currentStep === stepsNames.ASSOCIA_EMPRESAS) {
			form.submit();
		}

		if (obrasDrawer.currentStep === stepsNames.ASSOCIA_USUARIOS) {
			handleClose();
			localStorage.removeItem('obra_id');
		}
	};

	const getObraFormInfo = () => {
		const {
			obra_nome,
			obra_id,
			tipo_projeto,
			metragem,
			quantidade_uh,
			padrao_acabamento,
			metodo_construtivo,
			fase_atual,
			cep,
			cidade,
			estado,
			logradouro,
			numero,
			bairro,
			complemento,
			ibge,
			fotos_obra,
		} = form.getFieldsValue();

		return {
			obra_id, // serve para edição.
			endereco: {
				cep: cep.replace('-', ''),
				cidade,
				estado,
				numero,
				bairro,
				complemento,
				logradouro,
			},
			codigo_ibdge: ibge,
			nome: obra_nome,
			quantidade: quantidade_uh,
			padrao: padrao_acabamento || '',
			metodo_construtivo,
			fase_atual: fase_atual || '',
			tipo: tipo_projeto,
			metragem,
			cliente_id: user.cliente_id,
			fotos_obra: fotos_obra || [],
		};
	};

	const getAssociaEmpresasInfos = () => {
		const {
			obra_id,
			empresa_responsavel_fatura,
			empresa_responsavel_obra,
			empresa_resp_obra_faturamento,
		} = form.getFieldsValue();
		return {
			obra_id,
			empresa_responsavel_fatura,
			empresa_responsavel_obra,
			empresa_resp_obra_faturamento,
		};
	};

	// função que vai rodar dps do stepSubmit
	const handleFinish = () => {
		if (obrasDrawer.currentStep === stepsNames.CADASTRO_OBRAS) {
			if (mode === modes.EDIT || (mode === modes.NEW && localStorage.getItem('obra_id') !== null && localStorage.getItem('obra_id') !== undefined)) {
				editObra(getObraFormInfo());
				return;
			}
			createObra(getObraFormInfo());
		}
		if (obrasDrawer.currentStep === stepsNames.ASSOCIA_EMPRESAS) {
			AssociaEmpresa(getAssociaEmpresasInfos());
		}
	};

	const renderWarnNote = () => {
		if (!obrasDrawer.currentStep) return null;

		return (
			<div className="warn-note">
				<Exclamacao />
				{obrasDrawer.currentStep === stepsNames.ASSOCIA_EMPRESAS ? 'É necessário ter ao menos uma empresa responsável pela obra e pelo faturamento'
					: (
						<>
							Para cada usuário associado é necessário selecionar papéis. &nbsp;
							<a className="link-roles" href={userRoleLink}>
								Entenda os papéis dos usuários em nossa central de ajuda.
							</a>
						</>
					)
				}
			</div>
		);
	};

	const CurrentStepComponent = stepsComponents[obrasDrawer.currentStep];

	useEffect(() => {
		setDrawerObraFormInstance(form);
	}, [form]);

	useEffect(() => {
		if ((mode === modes.NEW && visible) && mode !== modes.EDIT) form.resetFields();
	}, [mode, visible]);

	useEffect(() => {
		if (mode === modes.EDIT && visible) {
			const { id: obra_id, codigo_ibdge, anexos, nome, endereco, metragem, unidades_construtivas } = obrasDrawer.dataToDrawer || {};
			const { tipo, padrao, metodo_construtivo, quantidade, fase_atual } = unidades_construtivas[0] || {};
			const { bairro, cep, cidade, complemento, estado, logradouro, numero } = endereco || {};
			form.setFieldsValue({
				obra_id,
				obra_nome: nome,
				tipo_projeto: tipo,
				quantidade_uh: quantidade,
				fase_atual,
				metragem,
				metodo_construtivo,
				padrao_acabamento: padrao,
				// endereco
				ibge: codigo_ibdge,
				cep,
				cidade,
				estado,
				bairro,
				logradouro,
				numero,
				complemento,
				fotos_obra: (anexos || []).map((a) => ({ ...a, uid: a.id, name: a.nome, status: 'done' })),
			});
		}
	}, [mode, visible]);

	useEffect(() => {
		const usuario_administrador = user.clientes_administrados[0] && user.clientes_administrados[0].apelido_interno;
		if (usuario_administrador) {
			setUsuarioAdministrador(localStorage.setItem('usuario_admin', true));
		} else {
			localStorage.removeItem('usuario_admin');
		}
	}, [user, usuarioAdministrador]);

	useEffect(() => {
		if (removeIdObra !== null) {
			localStorage.removeItem('obra_id');
		}
		setRemoveIdObra(localStorage.getItem('obra_id'));
	}, [removeIdObra]);

	return (
		<StyledDrawer
			title={getDrawerTitle(mode)}
			placement="right"
			width="1060"
			destroyOnClose
			onClose={handleClose}
			visible={visible}
			footer={
				<Footer
					stepSubmit={stepSubmit}
					form={form}
					nextStep={nextStep}
					prevStep={prevStep}
					mode={mode}
					handleClose={handleClose}
				/>
			}
		>
			{mode === modes.NEW && (
				<Steps
					className="drawer-obras-steps"
					type="navigation"
					current={obrasDrawer.currentStep}
					onChange={() => {}}
				>
					<Steps.Step
						disabled
						title={getDrawerTitle(mode)}
						status={obrasDrawer.stepStatus[0]}
					/>
					<Steps.Step
						disabled
						title="Associação de empresas"
						status={obrasDrawer.stepStatus[1]}
					/>
					<Steps.Step
						disabled
						title="Associação de usuários"
						status={obrasDrawer.stepStatus[2]}
					/>
				</Steps>
			)}

			{renderWarnNote()}

			<Form form={form} layout="vertical" id="obras_form" name="obras_form" onFinish={handleFinish} onValuesChange={verifyFieldsTouched} className="ant-form-padding">
				<CurrentStepComponent form={form} enableFields={mode === modes.EDIT} disableAll={disableAll} handleClose={handleClose} />
			</Form>
		</StyledDrawer>
	);
}

DrawerObras.propTypes = {
	user: PropTypes.shape(),
	obras: PropTypes.shape(),
	obrasDrawer: PropTypes.shape(),
	setDrawerObraFormInstance: PropTypes.func,
	AssociaEmpresa: PropTypes.func,
	obraDrawerNextStep: PropTypes.func,
	obraDrawerPrevStep: PropTypes.func,
	createObra: PropTypes.func,
	editObra: PropTypes.func,
	handleHaveChangedAssociaEmpresa: PropTypes.func,
	mode: PropTypes.string,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
};

DrawerObras.defaultProps = {
	user: {},
	obras: {},
	obrasDrawer: {},
	mode: 'new',
	visible: false,
	AssociaEmpresa: () => {},
	obraDrawerNextStep: () => {},
	obraDrawerPrevStep: () => {},
	createObra: () => {},
	editObra: () => {},
	handleHaveChangedAssociaEmpresa: () => {},
	onClose: () => {},
	setDrawerObraFormInstance: () => {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(DrawerObras));
