import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { lockBody } from '../../../_functions/_lockBody';

class ModalWrapperPortal extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
		this.escClose = this.escClose.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			window.addEventListener('keyup', this.escClose);
			lockBody(true);
			this.setState({ show: true });
		}, 80);
	}

	componentWillUnmount() {
		lockBody(false);
		window.removeEventListener('keyup', this.escClose);
	}

	escClose(e) {
		if (e.keyCode === 27) {
			this.props.closeModal();
		}
	}

	render() {
		const { show } = this.state;
		const modal_class = show ? 'modal fade in' : 'modal fade';

		return (
			<div
				className={modal_class}
				styleName={modal_class}
				tabIndex="-1"
				role="dialog">
				<div
					className="modal-dialog"
					role="document"
					styleName="modal-dialog">
					{React.cloneElement(this.props.children, {
						closeModal: this.props.closeModal,
					})}
				</div>
			</div>
		);
	}
}

ModalWrapperPortal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

export default CSSModules(ModalWrapperPortal, styles, { allowMultiple: true });
