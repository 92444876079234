import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import CSSModules from 'react-css-modules';
import _ from 'lodash';
import ButtonIcon from '../../../pages/CompraV3/subcomponents/Anexos/elements/ButtonIcon';
import styles from './style.module.scss';
import { splitName, isImage } from './ModalUpload/utils';
import ButtonToolTip from '../_tooltips/ButtonToolTip/ButtonToolTip';
import { _put } from '../../_functions/_requests';
import { withToggle, withTooltip } from '../../_hocs/hocs';

// Components
import DeleteAnexo from './DeleteAnexo';

class Anexo extends Component {
	constructor() {
		super();
		this.state = {
			visivelFornecedor: false,
			isEyeIcon: false,
			isDownloadIcon: false,
		};
		this.purchaseSentVisibleMessage = 'A compra já foi enviada para o fornecedor, não é possível realizar essa ação.';
		this.defaultMessage = 'Desabilitar visualização para o fornecedor.';
		this.downloadMessage = 'Fazer download do anexo.';
	}

	handleVisivel = () => {
		const { visivelFornecedor } = this.state;
		this.setState({ visivelFornecedor: !visivelFornecedor });
	}

	handleVisivelParaFornecedor = (bool, toggle) => {
		const {
			config,
			arquivo,
			updateCompras,
			updateUi,
			anexos,
		} = this.props;
		const { id } = arquivo;
		const url = config.put_url.replace('$anexo_id', id);
		const params = { visivel_pro_fornecedor: bool };

		_put(url, params).then(() => {
			let newArr = _.filter(anexos, x => x.id !== id);
			const anexo = _.filter(anexos, x => x.id === id);
			anexo[0].visivel_pro_fornecedor = bool;
			newArr.push(anexo[0]);
			newArr = _.sortBy(newArr, a => a.id);
			updateCompras(['nova_compra', 'anexos'], newArr);
			updateUi(['changes', 'compras'], true);
		});
		if (toggle) {
			this.handleVisivel();
		}
	};

	handleDownloadIcon = type => {
		const { closeTooltip, openTooltip } = this.props;
		if (type === 'open') {
			this.setState({ isDownloadIcon: true });
			openTooltip();
			return;
		}
		this.setState({ isDownloadIcon: false });
		closeTooltip();
	}

	handleTooltipEye = type => {
		const { closeTooltip, openTooltip } = this.props;
		if (type === 'open') {
			this.setState({ isEyeIcon: true });
			openTooltip();
			return;
		}
		this.setState({ isEyeIcon: false });
		closeTooltip();
	};

	handleDownloadTooltip = last => {
		const { anexos } = this.props;
		const third = anexos.length > 2;
		if (last && third) {
			return (
				<ButtonToolTip
					text={this.downloadMessage}
					display="top"
					arrow_left="225px"
					margin_top="-55px"
					margin_left="-130px"
					width="200px"
					positionAbsolute={{ position: 'relative' }}
					styleArrow={{
						transform: 'rotate(-184deg)!important',
						position: 'absolute',
						top: '42px',
					}}
					styleTooltip={{
						position: 'absolute',
						borderRadius: '5px',
						top: '19px',
						left: '50px',
						width: '100%',
					}} />
			);
		}
		return (
			<ButtonToolTip
				text={this.downloadMessage}
				display="top"
				arrow_left="202px"
				margin_top="-10px"
				margin_left="-157px"
				width="200px"
				styleArrow={{
					position: 'relative',
					top: '30px',
					left: '230px',
					transform: 'rotate(-92deg)',
					// borderTopColor: color,
					marginBottom: '16px',
				}}
				styleTooltip={{ position: 'relative', borderRadius: '5px', top: '0px', left: '28px' }} />
		);
	};

	chooseTooltip = (first, last, value) => {
		// lida com a cor e posição do tooltip no icone de olho para visivel ou invisivel para o fornecedor
		const { anexos } = this.props;
		const third = anexos.length > 2;
		const color = value === 'confirmada' ? 'red' : null;
		const firstArrowBottom = value === 'confirmada' ? '19px' : '14px';
		const lastTopTooltip = value === 'confirmada' ? '-15px' : '3px';
		if (first) {
			return (
				<ButtonToolTip
					text={this.handleAnexoMessage(value, this.purchaseSentVisibleMessage, this.defaultMessage)}
					display="top"
					arrow_left="202px"
					margin_top="-10px"
					margin_left="-157px"
					width="200px"
					styleArrow={{
						transform: 'rotate(-92deg)',
						borderTopColor: color,
						marginBottom: firstArrowBottom,
					}}
					styleTooltip={{ position: 'relative', borderRadius: '5px', top: '10px', left: '0px', backgroundColor: color }} />
			);
		}
		if (last && third) {
			return (
				<ButtonToolTip
					text={this.handleAnexoMessage(value, this.purchaseSentVisibleMessage, this.defaultMessage)}
					display="top"
					arrow_left="225px"
					margin_top="-55px"
					margin_left="-157px"
					width="200px"
					positionAbsolute={{ position: 'relative' }}
					styleArrow={{
						transform: 'rotate(-184deg)!important',
						position: 'absolute',
						borderTopColor: color,
						top: '42px',
					}}
					styleTooltip={{
						position: 'absolute',
						borderRadius: '5px',
						top: lastTopTooltip,
						left: '70px',
						backgroundColor: color,
						width: '100%',
					}} />
			);
		}
		return (
			<ButtonToolTip
				text={this.handleAnexoMessage(value, this.purchaseSentVisibleMessage, this.defaultMessage)}
				display="top"
				arrow_left="202px"
				margin_top="-10px"
				margin_left="-157px"
				width="200px"
				styleArrow={{
					transform: 'rotate(-92deg)',
					borderTopColor: color,
					marginBottom: '16px',
				}}
				styleTooltip={{ position: 'relative', borderRadius: '5px', backgroundColor: color }} />
		);
	};

	handleStyleIconEye = (value, uploadedAfter, isTrashIcon) => {
		const { arquivo } = this.props;
		if (value === 'confirmada' && !uploadedAfter) return { color: '#ccc' };
		return arquivo.visivel_pro_fornecedor && !isTrashIcon ? { color: '#000' } : null;
	}

	toggleMobile = () => {
		const { toggle, isVisible } = this.props;
		if (isVisible) {
			toggle();
			this.handleVisivel();
		}
		this.handleVisivel();
	}

	handleAnexoMessage = (value, message, defaultMessage) => {
		if (value === 'confirmada') return message;
		return defaultMessage;
	}

	render() {
		const {
			name,
			arquivo,
			tooltipIsVisible,
			first,
			last,
			config,
			compra,
			anexos,
			updateCompras,
		} = this.props;
		const { visivelFornecedor, isEyeIcon, isDownloadIcon } = this.state;
		const { status_front, data_envio } = compra;

		return (
			<>
				<li styleName="anexo-item">
					<div styleName="file-name">
						<i
							className="fa fa-file-o"
							aria-hidden="true"
							style={{ color: '#6f7a8c' }} />
						<p styleName="file-desktop">{splitName(name, 14)}</p>
						<p styleName="file-mobile">{splitName(name, 10)}</p>
					</div>
					<div styleName="icons">
						<div styleName="icons btn">
							<>
								{(tooltipIsVisible && isEyeIcon) && this.chooseTooltip(first, last, status_front)}
								<button
									onMouseEnter={() => this.handleTooltipEye('open')}
									onMouseLeave={() => this.handleTooltipEye('close')}
									styleName="icon-button toggle-desktop"
									onClick={status_front === 'confirmada'
										?
										null
										:
										() => this.handleVisivelParaFornecedor(!arquivo.visivel_pro_fornecedor)
									}>
									<i
										className={arquivo.visivel_pro_fornecedor ? 'fa fa-eye' : 'fa fa-eye-slash'}
										style={this.handleStyleIconEye(status_front)} />
								</button>
							</>
							<span styleName="toggle-mobile">
								<ButtonIcon
									icon="fa fa-eye"
									func={
										status_front === 'confirmada'
											? () => null
											: this.toggleMobile
									}
									toggleIconName="fa fa-eye-slash"
									entryValue={arquivo.visivel_pro_fornecedor}
									positionTooltip="top"
									styleIcon={this.handleStyleIconEye(status_front)}
									styleToggleIcon={this.handleStyleIconEye(status_front)}
									styleArrow={{
										transform: 'rotate(-92deg)',
										marginBottom: '12px',
									}}
									styleTooltip={{
										borderRadius: '5px',
									}}
									stylePosition={{
										zIndex: 9999,
										arrow_left: '202px',
										margin_top: '-10px',
										margin_left: '-157px',
										width: '200px',
										position: 'absolute',
									}} />
							</span>
							{(tooltipIsVisible && isDownloadIcon) && this.handleDownloadTooltip(last)}
							<a
								onMouseEnter={() => this.handleDownloadIcon('open')}
								onMouseLeave={() => this.handleDownloadIcon('close')}
								href={arquivo.url || ''}
								download={isImage(arquivo.url) && arquivo.url}
								target={isImage(arquivo.url) ? '_blank' : null}
								rel="noopener noreferrer">
								<i className="fa fa-download" />
							</a>
							<DeleteAnexo
								arquivo={arquivo}
								anexos={anexos}
								status_front={status_front}
								data_envio={data_envio}
								delete_url={config.delete_url}
								updateCompras={updateCompras}
							/>
							{
								// mobile
								visivelFornecedor
								&& (
									<div styleName="tooltip-delete visible">
										<div styleName="delete-container visible">
											<p><b>Deixar visível para o fornecedor?</b></p>
											<div>
												<button
													onClick={() => this.handleVisivelParaFornecedor(true, true)}
													className="btn btn-danger-conaz round-border"
													styleName="yes-delete visible">
													<b>Sim</b>
												</button>
												<button
													onClick={() => this.handleVisivelParaFornecedor(false, true)}
													className="btn btn-clear-conaz round-border"
													styleName="no-delete visible">
													<b>Não</b>
												</button>
											</div>
										</div>
									</div>
								)
							}
						</div>
					</div>
				</li>
			</>
		);
	}
}

Anexo.propTypes = {
	tooltipIsVisible: PropTypes.bool.isRequired,
	first: PropTypes.bool,
	last: PropTypes.bool,
	openTooltip: PropTypes.func.isRequired,
	compra: PropTypes.shape({
		status_front: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.oneOf([null]),
		]),
		data_envio: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.oneOf([null]),
		]),
	}).isRequired,
	closeTooltip: PropTypes.func.isRequired,
	name: PropTypes.string,
	isVisible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	arquivo: PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
		visivel_pro_fornecedor: PropTypes.bool,
	}).isRequired,
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	anexos: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
		nome: PropTypes.string,
		tipo: PropTypes.string,
		url: PropTypes.string,
		criado_em: PropTypes.string,
		tamanho: PropTypes.number,
		visto: PropTypes.bool,
	})),
	updateUi: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
};

Anexo.defaultProps = {
	name: '',
	anexos: [],
	first: false,
	last: false,
};

export default compose(
	withToggle,
	withTooltip,
)(CSSModules(Anexo, styles, { allowMultiple: true }));
