
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _post } from '../../components/_functions/_requests';
import { getLocalstorageData } from '../../components/_functions/_getLocalstorageData';

class RecuperarSenha extends Component {
	constructor() {
		super();
		this.state = {
			senha: '',
			senha_novamente: '',
			success: true,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillMount() {
		const token = getLocalstorageData('user', 'token');
		if (token !== '' && token !== null) this.props.history.push('/cotacoes');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Conaz';
	}

	handleEdition(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { senha, senha_novamente } = this.state;
		const { token } = this.props.match.params;
		this.setState({ infoMsg: '' });

		if (!token) {
			this.props.history.push('/login');
		}

		if (senha === senha_novamente && senha.length > 0) {
			_post('/conaz/v2/auth/construtor/recuperar_senha', { token, senha })
				.then(() => {
					this.setState({ success: true, infoMsg: 'Senha alterada com sucesso!' });
				})
				.catch(error => {
					if (!error.response || error.response.status === 500) {
						this.setState({ success: false, infoMsg: 'Ops... aconteceu algo errado. \nClique em Criar e tente novamente.' });
					} else {
						this.setState({ success: false, infoMsg: error.response.data.message });
					}
				});
		} else {
			this.setState({ success: false, infoMsg: 'As senhas não correspondem!' });
		}
	}

	render() {
		const { infoMsg, success } = this.state;
		const error_class = !success ? 'form-group has-error' : 'form-group';

		return (
			<div styleName="login-page-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-md-12" style={{ textAlign: 'center' }}>
							<div styleName="logo-wrapper">
								<img
									src="/images/logotipo_original.png"
									width="230"
									alt="" />
							</div>

							<div styleName="form-wrapper">
								<h3 styleName="h3-margin">Criar senha</h3>
								<form onSubmit={this.handleSubmit}>
									<div className={error_class}>
										<label htmlFor="senha">Nova senha</label>
										<input
											type="password"
											className="form-control"
											id="senha"
											name="senha"
											required
											onChange={this.handleEdition} />
									</div>
									<div className={error_class}>
										<label
											styleName="label"
											htmlFor="senha">
											Confirmar nova senha
										</label>
										<input
											type="password"
											className="form-control"
											id="senha_novamente"
											name="senha_novamente"
											required
											onChange={this.handleEdition} />
									</div>
									{ infoMsg && (
										<div className={`alert alert-${success ? 'success' : 'danger'}`} styleName="mensagem" role="alert">
											<i className={`fa fa-${success ? 'check' : 'exclamation-triangle fa-fw'}`} aria-hidden="true" /> {infoMsg}
										</div>
									)}

									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										styleName="button-margin">Criar
									</button><br />
								</form>
							</div>
							<div styleName="cadastre-wrapper">
								<p><Link to="/login">Voltar para login</Link></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

RecuperarSenha.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

export default CSSModules(RecuperarSenha, styles, { allowMultiple: true });
