export const clearItensAlerts = () => {
	const caracteristica_wrapper_unidade = document.getElementsByClassName('form-group fg-resposta has-unidade has-error');
	for (let i = 0; i < caracteristica_wrapper_unidade.length; i++) caracteristica_wrapper_unidade[i].className = 'form-group fg-resposta has-unidade';
	const caracteristica_wrapper = document.getElementsByClassName('form-group fg-resposta has-error');
	for (let i = 0; i < caracteristica_wrapper.length; i++) caracteristica_wrapper[i].className = 'form-group fg-resposta';
	const form_groups = document.getElementsByClassName('form-group has-error');
	for (let i = 0; i < form_groups.length; i++) form_groups[i].className = 'form-group';
	const topbars_right = document.getElementsByClassName('item-pedido-topbar-right has-error');
	for (let i = 0; i < topbars_right.length; i++) topbars_right[i].className = 'item-pedido-topbar-right';
	const tooltips_top = document.getElementsByClassName('tooltip top in');
	for (let i = 0; i < tooltips_top.length; i++) tooltips_top[i].className = 'tooltip top';
	// switch (type) {
	// case 'item':
	// 	document.getElementById(`unidade-${item_key}-wrapper`).className = 'form-group';
	// 	document.getElementById(`tooltip-unidade-${item_key}`).className = 'tooltip top';
	// 	break;
	// case 'caracteristica':
	// 	document.getElementById(`resposta-${item_key}-${resposta_key}-wrapper`).className = 'form-group';
	// 	document.getElementById(`tooltip-resposta-${item_key}-${resposta_key}`).className = 'tooltip top';
	// 	break;
	// case 'unidade-caracteristica':
	// 	document.getElementById(`unidade-resposta-${item_key}-${resposta_key}-wrapper`).className = 'form-group';
	// 	document.getElementById(`tooltip-unidade-resposta-${item_key}-${resposta_key}`).className = 'tooltip top';
	// 	break;
	// default:
	// }
};
