import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import moment from 'moment';
import { Popconfirm, Tooltip, message } from 'antd';
import styles from './style.module.scss';
import { _delete } from '../../_functions/_requests';

const DeleteAnexo = ({
	arquivo,
	anexos,
	status_front,
	data_envio,
	delete_url,
	updateCompras,
}) => {
	const deleteFile = () => {
		const url = delete_url.replace('$anexo_id', arquivo.id);
		_delete(url).then(() => {
			const _anexo = anexos.filter(a => a.id !== arquivo.id);
			updateCompras(['nova_compra', 'anexos'], _anexo);
		}).catch(() => {
			message.error({
				content: 'This is an error message',
				style: { marginTop: '40px' },
			});
		});
	};

	const isDeleteBlocked = () => {
		const anexoAdicionadoAposEnvioDaCompra = data_envio !== null
			? moment(arquivo.criado_em).isAfter(data_envio)
			: false;
		if (anexoAdicionadoAposEnvioDaCompra) {
			return false;
		}

		const statusBloqueados = ['confirmada', 'entrega_confirmada', 'finalizada'];
		const deleteBloqueadoPeloStatus = statusBloqueados.some(status => status === status_front);
		return deleteBloqueadoPeloStatus;
	};

	if (isDeleteBlocked()) {
		const content = (
			<span styleName="popover-alert">
				Este arquivo já foi enviada para o fornecedor, não é possível apagá-lo.
			</span>
		);

		return (
			<Tooltip placement="left" title={content}>
				<button styleName="icon-button blocked">
					<i className="fa fa-trash" style={{ color: '#ccc' }} />
				</button>
			</Tooltip>
		);
	}

	const icon = (
		<i
			className="fa fa-exclamation-circle"
			aria-hidden="true"
			styleName="popover-icon"
		/>
	);

	return (
		<Popconfirm
			placement="left"
			title="Deseja apagar este arquivo?"
			onConfirm={deleteFile}
			okText="Apagar"
			cancelText="Manter"
			icon={icon}
		>
			<button styleName="icon-button">
				<i className="fa fa-trash" />
			</button>
		</Popconfirm>
	);
};

DeleteAnexo.propTypes = {
	arquivo: PropTypes.shape({
		id: PropTypes.number,
		criado_em: PropTypes.string,
	}).isRequired,
	anexos: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.number,
	})).isRequired,
	status_front: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.oneOf([null]),
	]).isRequired,
	data_envio: PropTypes.string,
	delete_url: PropTypes.string.isRequired,
	updateCompras: PropTypes.func.isRequired,
};

DeleteAnexo.defaultProps = {
	data_envio: null,
};

export default CSSModules(DeleteAnexo, styles, { allowMultiple: true });
