import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import SiengeLogin from './SiengeLogin';
import InfoLogin from './InfoLogin';
import HeaderLogin from './HeaderLogin';

// Functions
import { _get, _put, _post } from '../../../components/_functions/_requests';

class SiengeLoginContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: 1,
			ja_configurado: false,
			credencial_id: 0,
			username: '',
			senha: '',
			dominio: '',
			user_sienge: '',
			infoMsg: '',
			logging_state: 0,
			isEdition: true,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
		this.handleEnableEdition = this.handleEnableEdition.bind(this);
		this.saveUsuarioSienge = this.saveUsuarioSienge.bind(this);
	}

	componentDidMount() {
		document.title = 'Integração - Conaz';
		this.checkLoad(this.props.user);
		if (this.state.user_sienge === '' && this.props.user.usuario_no_swi !== '') {
			this.setState({ user_sienge: this.props.user.usuario_no_swi });
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.checkLoad(nextProps.user);
		if (this.state.user_sienge === '' && nextProps.user.usuario_no_swi !== '') {
			this.setState({ user_sienge: nextProps.user.usuario_no_swi });
		}
	}

	checkLoad(user) {
		const { cliente_id, sienge_liberado } = user;
		const { history } = this.props;

		if (cliente_id !== 0 && !sienge_liberado) {
			history.replace('/cotacoes');
			return;
		}

		if (cliente_id !== 0) {
			this.buscaCredenciais(user);
		}
	}

	buscaCredenciais(user) {
		const { cliente_id } = user;
		const url = `/conaz/v2/integracoes/credenciais/?swi=1&cliente_id=${cliente_id}`;
		_get(url, {}, []).then(response => {
			const credenciais = (response.data || [])[0] || {};
			const login_configurado = credenciais.id !== undefined;

			if (login_configurado) {
				this.setState({
					isEdition: false,
					ja_configurado: true,
					credencial_id: credenciais.id,
					username: credenciais.username,
					dominio: credenciais.dominio,
					loading: 2,
				});
			}

			this.setState({ loading: 2 });
		}).catch(() => {
			this.setState({ loading: 2 });
		});
	}

	handleEdition(e) {
		const typed = e.target.value.toString();
		this.setState({ [e.target.id]: typed });
	}

	handleSubmit(e) {
		e.preventDefault();
		const { username, dominio, senha, ja_configurado, credencial_id } = this.state;

		if (username === '' || dominio === '' || senha === '') {
			this.setState({
				logging_state: 3,
				infoMsg: 'Há campos vazios, preencha o formulário corretamente.',
			});
			return;
		}

		this.setState({ logging_state: 1 });
		const url_post = '/conaz/v2/integracoes/credenciais';
		const url_put = `/conaz/v2/integracoes/credenciais/${credencial_id}`;
		const params = {
			swi: 1,
			cliente_id: this.props.user.cliente_id,
			username,
			senha,
			dominio,
		};

		const func_to_call = (ja_configurado && credencial_id !== 0)
			? _put(url_put, params)
			: _post(url_post, params);

		func_to_call.then(response => {
			const credenciais = (response.data || [])[0] || {};
			const login_configurado = credenciais.id !== undefined;
			const sienge = {
				login_configurado,
				obras_configuradas: false,
				credenciais,
			};
			const { updateUser } = this.props;
			updateUser(['sienge'], sienge);
			this.saveUsuarioSienge();
		}).catch(error => {
			const conaz_message = ((error.response || {}).data || {}).message || '';
			this.setState({
				logging_state: 3,
				infoMsg: conaz_message !== '' ? conaz_message : 'Erro ao conectar com SIENGE',
			});
		});
	}

	saveUsuarioSienge() {
		this.setState({ logging_state: 1 });
		const { user_sienge } = this.state;
		const { user, updateUser, history } = this.props;

		if (user_sienge !== '') {
			const url = `/conaz/v2/usuarios_construtor/${user.id}/usuario_no_swi`;
			const params = {
				usuario_no_swi: user_sienge,
			};
			_put(url, params).then(() => {
				updateUser(['usuario_no_swi'], user_sienge);
				history.push('/integracao/sienge');
			}).catch(error => {
				const conaz_message = ((error.response || {}).data || {}).message || '';
				this.setState({
					logging_state: 3,
					infoMsg: conaz_message !== '' ? conaz_message : 'Erro ao conectar com SIENGE',
				});
			});
			return;
		}

		history.push('/integracao/sienge');
	}

	handleEnableEdition() {
		this.setState({ isEdition: true });
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>
				<>
					<HeaderLogin ui={this.props.ui} />
					{(this.state.isEdition === true) ? (
						<SiengeLogin
							handleSubmit={this.handleSubmit}
							handleEdition={this.handleEdition}
							{...this.state}
							{...this.props} />
					) : (
						<InfoLogin
							handleEnableEdition={this.handleEnableEdition}
							handleEdition={this.handleEdition}
							saveUsuarioSienge={this.saveUsuarioSienge}
							{...this.state}
							{...this.props} />
					)}


				</>
			</LayoutWrapper>
		);
	}
}

SiengeLoginContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(SiengeLoginContainer));
