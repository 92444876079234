import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post, _post_exports } from '../../../_functions/_requests';

class ModalExportaExcel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exportando: 1,
			link: '',
			error: '',
		};

		_post_exports(`/v2/analises/${props.analise_id}/exportar`, {})
			.then(({ data }) => this.setState({
				exportando: 2,
				link: data.message,
			}))
			.catch(err => {
				const {
					status,
					data: { message },
				} = err.response;

				if (status === 404 && props.link !== '') {
					this.setState({ exportando: 2, link: props.link });
				} else {
					this.setState({
						exportando: 3,
						error: status === 500 ? '' : message,
					});
				}
			});

		this.enviarQCPorEmail = this.enviarQCPorEmail.bind(this);
	}

	enviarQCPorEmail() {
		_post(`/conaz/v2/analises/${this.props.analise_id}/enviar_email`)
			.then(() => this.setState({
				exportando: 4,
			}))
			.catch(err => {
				const {
					status,
					data: { message },
				} = err.response;
				this.setState({
					exportando: 3,
					error: status === 500 ? '' : message,
				});
			});
	}

	render() {
		const { exportando, error, link } = this.state;
		const { updateModals } = this.props;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('exporta_excel', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="icon">
						<img src="/images/excel-icon.png" width="84" alt="" />
					</div>
					<div styleName="content">
						<h3>QC em Excel</h3>
						<p>Saiba como imprimir seu arquivo em Excel clicando <a href="https://conaz.zendesk.com/hc/pt-br/articles/360010228454-Exportei-meu-QC-em-Excel-e-quero-imprimi-lo-Como-proceder-" target="_blank" rel="noopener noreferrer">nesse link</a>.<br /><br /></p>
						{exportando === 3 && (
							<>
								<br />
								<div
									className="alert alert-danger"
									role="alert">
									<i
										className="fa fa-exclamation-triangle fa-fw"
										aria-hidden="true" />{' '}
									{error ||
										`Desculpe, aconteceu algo errado. Tente
									novamente.`}
								</div>
							</>
						)}
						{exportando === 1 && (
							<>
								<p>Sua planilha esta sendo preparada!</p>
								<a
									href={link}
									rel="noopener noreferrer"
									target="_blank"
									className="btn btn-secondary-conaz round-border loading">
									<LoadingButton />
									Baixar planilha
								</a>
							</>
						)}
						{exportando === 2 && (
							<>
								<a
									type="button"
									rel="noopener noreferrer"
									target="_blank"
									href={link}
									className="btn btn-secondary-conaz round-border">
									Baixar planilha
								</a>
								<button
									type="button"
									rel="noopener noreferrer"
									onClick={this.enviarQCPorEmail}
									styleName="email-button"
									className="btn btn-secondary-conaz round-border"
								>
									Enviar por Email
								</button>
							</>
						)}
						{exportando === 4 && (
							<p style={{ fontSize: '16px', color: '#00b147', margin: '30px 0 10px 0' }}>
								<i className="fa fa-check fa-fw" aria-hidden="true" /> Pronto!<br /> Em alguns minutos o arquivo estará no seu e-mail.
							</p>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalExportaExcel.propTypes = {
	updateModals: PropTypes.func,
	analise_id: PropTypes.number.isRequired,
	link: PropTypes.string,
};

ModalExportaExcel.defaultProps = {
	updateModals: () => {},
	link: '',
};

export default CSSModules(ModalExportaExcel, styles, { allowMultiple: true });
