import { bindActionCreators } from 'redux';
import * as comprasActions from './compras/actions';
import * as avaliacoesActions from './avaliacoes/actions';
import * as cotacaoActions from './cotacao/actions';
import * as cotacoesActions from './cotacoes/actions';
import * as itensActions from './itens/actions';
import * as qcActions from './qc/actions';
import * as uiActions from './ui/actions';
import * as userActions from './user/actions';
import * as exportStatusActions from './exportStatus/actions';
import * as importacaoPlanilhaActions from './importacaoPlanilha/actions';
import * as usuariosActions from './usuarios/actions';
import * as empresasActions from './empresas/actions';
import * as enderecoFormActions from './enderecoForm/actions';
import * as obrasActions from './obras/actions';
import * as obrasDrawerActions from './obrasDrawer/actions';
import * as associaObraActions from './associaObra/actions';

const actions = {
	...comprasActions,
	...avaliacoesActions,
	...cotacaoActions,
	...cotacoesActions,
	...itensActions,
	...qcActions,
	...uiActions,
	...userActions,
	...exportStatusActions,
	...importacaoPlanilhaActions,
	...usuariosActions,
	...empresasActions,
	...enderecoFormActions,
	...obrasActions,
	...obrasDrawerActions,
	...associaObraActions,
};

export const buildMapStateToProps = (props, fields = null) => {
	if (fields === null) {
		return ({
			compras: props.compras,
			avaliacoes: props.avaliacoes,
			cotacao: props.cotacao,
			cotacoes: props.cotacoes,
			itens: props.itens,
			qc: props.qc,
			ui: props.ui,
			user: props.user,
			exportStatus: props.exportStatus,
			importacaoPlanilha: props.importacaoPlanilha,
			usuarios: props.usuarios,
			empresas: props.empresas,
			enderecoForm: props.enderecoForm,
			obras: props.obras,
			obrasDrawer: props.obrasDrawer,
			associaObra: props.associaObra,
		});
	}

	return fields.reduce((result, current) => ({
		...result,
		[current]: { ...props[current] },
	}), {});
};

export const buildmapDispatchToProps = dispatch => (
	bindActionCreators(actions, dispatch)
);

export const detailedmapDispatchToProps = (dispatch, funcs) => {
	const only_funcs = Object.keys(actions)
		.filter(a => funcs.filter(f => f === a).length > 0)
		.reduce((result, current) => ({
			...result,
			[current]: actions[current],
		}), {});

	return {
		dispatch,
		...bindActionCreators(only_funcs, dispatch),
	};
};
