import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const LoadingButton = ({ top }) => (
	<div styleName="loader" style={{ top }}>
		<svg styleName="circular" viewBox="25 25 50 50">
			<circle styleName="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
		</svg>
	</div>
);

LoadingButton.propTypes = {
	top: PropTypes.string,
};

LoadingButton.defaultProps = {
	top: '5px',
};

export default CSSModules(LoadingButton, styles, { allowMultiple: true });
