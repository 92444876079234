const compras = {
	fetching: 0,
	current_tab: 'ativas',
	RS_ativas: 0,
	RS_aguardando_entrega_avaliacao: 0,
	RS_finalizadas: 0,
	RS_canceladas: 0,
	RS_em_rascunho: 0,
	ativas: {},
	aguardando_entrega_avaliacao: {},
	canceladas: {},
	em_rascunho: {},
	finalizadas: {},
	compra_view: {},
	nova_compra: {
		rc_id: 0,
		cotacao_codigo: '',
		titulo: '',
	},
	criar_compra: {
		pedido: {},
		rc: {},
		rc_analise: {},
		type: 'rc',
	},
	alert: {
		color: '',
		message: '',
	},
};

export default compras;
