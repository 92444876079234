import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { _post_exports } from '../../../_functions/_requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class ModalExportaCompras extends Component {
	constructor() {
		super();
		this.state = {
			exportando: 0,
		};
		this.exportaExcel = this.exportaExcel.bind(this);
	}

	exportaExcel() {
		this.setState({ exportando: 1 });
		_post_exports('/v2/compras/exportar', {}).then(() => {
			this.setState({ exportando: 2 });
			setTimeout(() => this.props.updateModals('exporta_compras', false), 6000);
		}).catch(error => {
			this.setState({ exportando: 3 });
			handleRequestErrors(error);
		});
	}

	render() {
		const { exportando } = this.state;
		const { updateModals } = this.props;

		return (
			<div className="modal-content" styleName="modal-content">
				<div className="modal-body" styleName="modal-body">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={() => updateModals('exporta_compras', false)}>
						<span aria-hidden="true">&times;</span>
					</button>
					<div styleName="icon">
						<img src="/images/excel-icon.png" width="84" alt="" />
					</div>
					<div styleName="content">
						<h3>Compras em Excel</h3>
						<p>Deseja exportar uma planilha com todas suas compras e informações? O processo de exportação demora alguns minutos e você receberá no seu e-mail o arquivo para download.</p>
						{exportando === 3 && (
							<div className="alert alert-danger" role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Desculpe, aconteceu algo errado. Tente novamente.
							</div>
						)}
						{(exportando === 0 || exportando === 3) && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								onClick={this.exportaExcel}>
								Enviar arquivo para e-mail
							</button>
						)}
						{exportando === 1 && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading"
								onClick={this.exportaExcel}>
								<LoadingButton />Enviar arquivo para e-mail
							</button>
						)}
						{exportando === 2 && (
							<p style={{ fontSize: '16px', color: '#00b147', margin: '30px 0 10px 0' }}>
								<i className="fa fa-check fa-fw" aria-hidden="true" /> Pronto!<br /> Em alguns minutos o arquivo estará no seu e-mail.
							</p>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ModalExportaCompras.propTypes = {
	updateModals: PropTypes.func,
};

ModalExportaCompras.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalExportaCompras, styles, { allowMultiple: true });
