import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ExportSteps from './ExportSteps';
import ExportErrorViews from './ExportErrorViews';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

const AdicionaItensNegociacao = ({
	sienge_data,
	setSiengeData,
	setStep,
	compra,
	cliente_id,
	setExportingError,
	itens,
	closeFunc,
	setForce,
	force,
	force_cotacao,
}) => {
	const [itens_exportados, setItens] = useState({});
	const [error, setError] = useState({ number: null, item_nome: '' });

	function setAllErrors(err) {
		setError(err);
		setExportingError(true);
	}

	function updateNegociacaoIdObjetoSienge(data) {
		const negociacao_id = (data || {}).negociacao_id || '';
		const sienge_data_updated = {
			...sienge_data,
			negociacao_id,
		};
		setSiengeData(sienge_data_updated);
	}

	function updateObjetoSienge(item_id) {
		const itens_atualizados = {
			...itens_exportados,
			[item_id]: {
				...itens_exportados[item_id],
				adicionado_na_negociacao: true,
			},
		};
		setItens(itens_atualizados);
	}

	function checkErrors(err, item_id) {
		const message = ((
			err.response || {})
			.data || {})
			.message || '';
		const item_nome = (itens_exportados[item_id] || {}).nome_item || '';

		// DONE Swi Item de Cotação já exportado
		const item_ja_exportado = message
			.indexOf('Swi Item de Cotação já exportado') !== -1;
		if (item_ja_exportado) {
			updateObjetoSienge(item_id);
			return;
		}

		// DONE Unidade de medida está diferente ou incorreta
		const error_8 = (
			message.indexOf('Unidade de medida está diferente ou incorreta') !== -1
		);
		if (error_8) {
			setAllErrors({ number: 8, item_nome });
			return;
		}

		// DONE Fornecedor swi não existe para esse item
		const error_10 = message
			.indexOf('Fornecedor swi não existe para esse item ou o item já está associado a uma solicitação') !== -1;
		if (error_10) {
			setAllErrors({ number: 10 });
			return;
		}

		// DONE Negociação ou Cotação já existente para essa solicitação
		const error_11 = message
			.indexOf('Negociação ou Cotação já existente para essa solicitação') !== -1;
		if (error_11) {
			setAllErrors({ number: 11 });
			return;
		}

		// DONE SWI não foi encontrado
		const error_4 = (
			message.indexOf('SWI não foi encontrado') !== -1
			|| message.indexOf('Swi cotação não encontrado') !== -1
			|| message.indexOf('Swi Item de Cotação não foi encontrado') !== -1
			|| message.indexOf('Swi Item de Cotação não adicionado na cotação') !== -1
		);
		if (error_4) {
			setAllErrors({ number: 4 });
			handleRequestErrors(err);
			return;
		}

		// DONE Quantidade cotada maior que solicitada
		const error_14 = message
			.indexOf('Quantidade cotada maior que solicitada') !== -1;
		if (error_14) {
			const error_values = message.split(' | ');
			const nome_insumo = error_values[3] || '';
			setAllErrors({ number: 14, item_nome: nome_insumo });
			return;
		}

		// DONE Erro não identificado
		setAllErrors({ number: 0 });
		handleRequestErrors(err);
	}

	function postItem(item_id) {
		const compra_id = compra.id || 0;
		const url = `/conaz/v2/integracoes_de_compras/${compra_id}/cotacao/${item_id}/negociacao`;
		const params = { cliente_id, swi: 1, force, force_cotacao };
		_post(url, params)
			.then(response => {
				updateNegociacaoIdObjetoSienge(response.data);
				updateObjetoSienge(item_id);
			})
			.catch(err => checkErrors(err, item_id));
	}

	function callNextItem() {
		const all_exported = Object.keys(itens_exportados)
			.map(item_id => itens_exportados[item_id])
			.filter(item => !item.adicionado_na_negociacao);
		const first_item = all_exported[0] || {};

		if (first_item.id !== undefined) {
			postItem(first_item.id);
		}
	}

	function endPosts() {
		const itens_array = Object.keys(itens_exportados);

		if (itens_array.length === 0) return;

		const all_exported = itens_array
			.map(item_id => itens_exportados[item_id])
			.filter(item => !item.adicionado_na_negociacao)
			.length === 0;

		if (all_exported) {
			setTimeout(() => setStep(7), 400);
			return;
		}

		callNextItem();
	}

	function setItensToState() {
		const _itens_exportados = sienge_data.itens_de_cotacao
			.reduce((result, current) => ({
				...result,
				[current.id]: { ...current },
			}), {});
		setItens(_itens_exportados);
	}

	useEffect(() => {
		setItensToState();
	}, []);

	useEffect(() => {
		endPosts();
	}, [itens_exportados]);

	// DONE Unidade de medida está diferente ou incorreta
	if (error.number === 8) {
		const cotacao_id = sienge_data.cotacao_id || '';
		return (
			<ExportErrorViews
				error_type={8}
				cotacao_id={cotacao_id}
				item_nome={error.item_nome}
			/>
		);
	}

	// DONE Fornecedor Sienge não existe para esse item
	if (error.number === 10) {
		return <ExportErrorViews error_type={10} />;
	}

	// DONE Negociação ou Cotação já existente para essa solicitação
	if (error.number === 11) {
		return (
			<ExportErrorViews
				error_type={11}
				itens={itens}
				closeFunc={closeFunc}
				setForce={setForce}
				setStep={setStep}
			/>
		);
	}

	// DONE SWI não foi encontrado
	if (error.number === 4) {
		return <ExportErrorViews error_type={4} />;
	}

	// DONE Quantidade cotada maior que solicitada
	if (error.number === 14) {
		return (
			<ExportErrorViews
				error_type={14}
				item_nome={error.item_nome}
				closeFunc={closeFunc}
				setForce={setForce}
				setStep={setStep}
			/>
		);
	}

	// DONE Erro não identificado
	if (error.number === 0) {
		return <ExportErrorViews />;
	}

	const itens_list = Object.keys(itens_exportados)
		.map(item_id => itens_exportados[item_id]);
	const itens_total = itens_list.length;
	const itens_done = itens_list
		.filter(item => item.adicionado_na_negociacao)
		.length;
	const descricao = `${itens_done}/${itens_total}`;

	return (
		<ExportSteps
			current={4}
			itens_negociacao={descricao}
		/>
	);
};

AdicionaItensNegociacao.propTypes = {
	sienge_data: PropTypes.object.isRequired,
	setSiengeData: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
	cliente_id: PropTypes.number.isRequired,
	setExportingError: PropTypes.func.isRequired,
	itens: PropTypes.object.isRequired,
	closeFunc: PropTypes.func.isRequired,
	setForce: PropTypes.func.isRequired,
	force: PropTypes.bool.isRequired,
	force_cotacao: PropTypes.bool.isRequired,
};

export default CSSModules(AdicionaItensNegociacao, styles, { allowMultiple: true });
