import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import SearchInput from './SearchInput';
import SearchResult from './SearchResult';
import Populares from '../Populares/Populares';
import NoResult from './NoResult';
import Sugestoes from './Sugestoes';
import Loading from '../../_loadings/Loading/Loading';

class SearchItens extends Component {
	state = {
		search_state: 0,
		results: [],
		search: '',
	};

	updateState = (fields_n_values) => {
		this.setState(fields_n_values);
	}

	render() {
		const {
			search_state,
			results,
			search,
		} = this.state;
		const {
			type,
			add_or_update,
			item_on_edition,
			openItemEdition,
			populares,
			updateItensRaw,
			autoSave,
		} = this.props;

		const item_on_edition_id = item_on_edition.id || 0;

		return (
			<div>
				<SearchInput
					updateState={this.updateState} />

				{search_state === 1 && (
					<div styleName="no-result-wrapper"><Loading /></div>
				)}

				{search_state === 2 && (
					results.map(item => (
						<SearchResult
							key={item.id}
							type={type}
							add_or_update={add_or_update}
							item_on_edition_id={item_on_edition_id}
							item={item}
							openItemEdition={openItemEdition}
							autoSave={autoSave}
							search={search} />
					))
				)}

				{search_state === 2 && (
					<NoResult
						type={type}
						add_or_update={add_or_update}
						item_on_edition_id={item_on_edition_id}
						search={search}
						results={results}
						openItemEdition={openItemEdition}
						autoSave={autoSave} />
				)}

				{(
					search_state === 0
					&& results.length === 0
					&& add_or_update === 'add'
				) && (
					<Populares
						type={type}
						populares={populares}
						updateItensRaw={updateItensRaw}
						openItemEdition={openItemEdition} />
				)}

				{(
					search_state === 0
					&& results.length === 0
					&& add_or_update === 'update'
				) && (
					<Sugestoes
						type={type}
						add_or_update={add_or_update}
						item_on_edition={item_on_edition}
						updateItensRaw={updateItensRaw}
						openItemEdition={openItemEdition}
						autoSave={autoSave} />
				)}
			</div>
		);
	}
}

SearchItens.propTypes = {
	type: PropTypes.string.isRequired,
	add_or_update: PropTypes.string,
	item_on_edition: PropTypes.object,
	openItemEdition: PropTypes.func,
	populares: PropTypes.any,
	updateItensRaw: PropTypes.func,
	autoSave: PropTypes.func,
};

SearchItens.defaultProps = {
	add_or_update: 'add',
	item_on_edition: {},
	openItemEdition: () => {},
	populares: [],
	updateItensRaw: () => {},
	autoSave: () => {},
};

export default CSSModules(SearchItens, styles, { allowMultiple: true });
