export const USERS_BY_CLIENT_REQUEST = 'USERS_BY_CLIENT_REQUEST';
export const USERS_BY_CLIENT_SUCCESS = 'USERS_BY_CLIENT_SUCCESS';
export const USERS_BY_CLIENT_ERROR = 'USERS_BY_CLIENT_ERROR';

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST';
export const USERS_EDIT_REQUEST = 'USERS_EDIT_REQUEST ';
export const USER_ACTIVATE_REQUEST = 'USER_ACTIVATE_REQUEST';
export const USERS_DISABLE_REQUEST = 'USERS_DISABLE_REQUEST';

export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS';
export const USERS_EDIT_SUCCESS = 'USERS_EDIT_SUCCESS';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_ACTIVATE_SUCCESS = 'USERS_ACTIVATE_SUCCESS';

export const USERS_ACTION_ERROR = 'USERS_ACTION_ERROR';
// drawer
export const USERS_OPEN_DRAWER = 'USERS_OPEN_DRAWER';
export const USERS_CLOSE_DRAWER = 'USERS_CLOSE_DRAWER';
export const USERS_ALTERA_STATUS_SUCCESS = 'USERS_ALTERA_STATUS_SUCCESS';
