import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalMotivoEdicao from '../../../../components/_parts/_modals/ModalMotivoEdicao/ModalMotivoEdicao';
import AvaliaVendedorButton from '../../../../components/AvaliaVendedorButton';

// Function
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { caracteristicaType } from '../../../../components/_functions/_caracteristicaType';
import { _put, _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import { calcValorItem } from '../../../../components/_parts/Itens/_subfunctions/calcValorItem';
import {
	handleObrigatorio,
	handleZerado,
	handleDataInferior,
} from '../../subfunctions/_validationUtils';

class EnvioCompra extends Component {
	constructor(props) {
		super(props);
		this.state = {
			no_items_error: false,
			other_errors: false,
			api_error: false,
			changes_confirmation: false,
			error_message: <p />,
			enviando: 0,
			modal_edicao: false,
			modal_edicao_already_shown: false,
			concluir_sem_exportar: false,
		};
	}

	UNSAFE_componentWillMount() {
		this.validaAlteracoes();
	}

	concluirSemExportar = () => {
		this.setState(
			{ concluir_sem_exportar: true },
			() => this.validacao(),
		);
	}

	validacao = () => {
		this.clearErrors();
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		if (is_triagem) {
			alert('Apenas o construtor pode concluir a compra usando este botão.'); // eslint-disable-line
			return;
		}
		this.validacaoItens();
	};

	validacaoItens = () => {
		const { itens, updateItensRaw } = this.props;
		const { all_ids, by_id } = itens;
		const itens_na_compra = all_ids
			.filter(id => !by_id[id].excluido);

		if (itens_na_compra.length === 0) {
			this.setState({ no_items_error: true });
			return;
		}

		const _by_id = { ...by_id };
		let itens_has_error = false;

		for (let i = 0; i < itens_na_compra.length; i++) {
			const item = by_id[itens_na_compra[i]];
			const errors = [];

			const { cliente_associou, item_de_resposta } = item;
			const status = item_de_resposta.id !== undefined
				? item_de_resposta.status
				: 1;

			/**
			 * Item de planilha não associado
			 */
			if (item.importado_planilha === 1) {
				errors.push({
					type: 'importado_planilha',
					carac_id: null,
				});
			}

			/**
			 * Quantidade
			 */
			const quantidade = item.quantidade || 0;
			const pre_quantidade = item.pre_quantidade || 0;
			if (
				(cliente_associou && quantidade === 0) ||
				(!cliente_associou && pre_quantidade === 0)
			) {
				errors.push({
					type: 'quantidade',
					carac_id: null,
				});
			}

			/**
			 * Unidade
			 */
			const { unidade, pre_unidade } = item;
			if (
				(cliente_associou && unidade === '') ||
				(!cliente_associou && pre_unidade === '')
			) {
				errors.push({
					type: 'unidade',
					carac_id: null,
				});
			}

			/**
			 * Valor unitario
			 */
			const preco_unitario = item.preco_unitario || 0;
			if (
				cliente_associou
				&& preco_unitario === 0
				&& status === 1
			) {
				errors.push({
					type: 'preco_unitario',
					carac_id: null,
				});
			}

			/**
			 * Caracteristicas
			 */
			const c_all_ids =
				item.item_preenchido.caracteristicas_preenchidas_all_ids || [];
			if (c_all_ids.length > 0) {
				for (let p = 0; p < c_all_ids.length; p++) {
					const carac =
						item.item_preenchido.caracteristicas_preenchidas[
							c_all_ids[p]
						];
					const carac_id = Number(c_all_ids[p]);
					const {
						visao_construtor,
						visao_fornecedor,
					} = carac.caracteristica;
					if (visao_construtor === 2 || visao_fornecedor === 2) {
						const {
							selecao,
							tipo_de_valor,
							opcoes,
						} = carac.caracteristica;
						const valor =
							carac.opcoes_preenchidas.length > 0
								? carac.opcoes_preenchidas[0].valor
								: '';
						const c_unidade =
							carac.opcoes_preenchidas.length > 0
								? carac.opcoes_preenchidas[0].unidade
								: '';
						if (
							carac.opcoes_preenchidas.length === 0 ||
							valor === '' ||
							(tipo_de_valor === 'numero' && valor === 0)
						) {
							errors.push({
								type: 'caracteristica',
								carac_id,
							});
						}
						// unidade
						const caracteristica_type = caracteristicaType(
							opcoes,
							selecao,
							tipo_de_valor,
						);
						const validada =
							carac.opcoes_preenchidas.length > 0
								? carac.opcoes_preenchidas[0].validada
								: null;
						const has_unidade =
							(opcoes.length > 0 &&
								caracteristica_type !== 'selecao' &&
								caracteristica_type !== 'multipla_escolha') ||
							(caracteristica_type === 'selecao' &&
								!validada &&
								valor !== '' &&
								opcoes[0].unidade !== '');
						if (has_unidade && c_unidade === '') {
							errors.push({
								type: 'caracteristica_unidade',
								carac_id,
							});
						}
					}
				}
			}

			_by_id[item.id].front.errors = errors;

			if (errors.length > 0) {
				itens_has_error = true;
			}
		}

		const itens_compra = {
			all_ids,
			by_id: _by_id,
		};
		updateItensRaw({ itens_compra });
		this.handleValidacao(itens_has_error);
	};

	handleValidacao = itens_has_error => {
		const { compra, updateState } = this.props;
		const {
			titulo,
			transporte,
			valor_transporte,
			data_de_entrega,
			fornecedor,
			tipo,
			condicao_de_pagamento,
			outra_condicao_de_pagamento: { id: condicao_outro_id },
		} = compra;
		const { id: condicao_id } = condicao_de_pagamento;
		const forma = condicao_de_pagamento.forma || 0;
		const outra_forma = condicao_de_pagamento.outra_forma || '';
		const prazo = condicao_de_pagamento.prazo || 0;
		const outro_prazo = condicao_de_pagamento.outro_prazo || '';
		let errors = {};

		// Validação tipo da compra
		errors = handleObrigatorio(tipo, 'tipo', errors);

		// Validação titulo
		errors = handleObrigatorio(titulo, 'titulo', errors);

		// Validação Fornecedor
		errors = handleObrigatorio(fornecedor.nome, 'fornecedor', errors);

		// Validação Pagamento
		if (condicao_id === condicao_outro_id) {
			if (forma === 0 && outra_forma === '') {
				errors = {
					...errors,
					forma_de_pagamento:
						'Deve ser selecionado uma forma de pagamento',
				};
			}

			if (prazo === 0 && outro_prazo === '') {
				errors = {
					...errors,
					prazo_de_pagamento:
						'Deve ser selecionado um prazo de pagamento',
				};
			}

			errors = handleZerado(
				prazo,
				'prazo_de_pagamento',
				errors,
				null,
				'Deve ser selecionado um prazo de pagamento!',
			);
		}

		// Validação condição de Pagamento não selecionada
		errors = handleObrigatorio(
			condicao_id,
			'opcao_pagamento',
			errors,
			'condição de pagamento',
		);

		// Validação Transporte
		errors = handleObrigatorio(
			transporte,
			'transporte',
			errors,
		);
		if (transporte === 1) {
			errors = handleZerado(
				valor_transporte,
				'valor_transporte',
				errors,
				'custo de transporte',
			);
		}

		// Validação data_de_entrega
		errors = handleObrigatorio(
			data_de_entrega,
			'data_de_entrega',
			errors,
		);
		if (tipo === 4) {
			errors = handleDataInferior(
				data_de_entrega,
				'data_de_entrega',
				errors,
			);
		}

		updateState('errors', errors);

		const info_has_error = Object.keys(errors).length > 0;
		this.enviaCompra(itens_has_error, info_has_error);
	};

	validaAlteracoes() {
		const { compra, itens } = this.props;
		const { changes_confirmation } = this.state;
		const rc_uuid = (compra.rc || {}).uuid || null;
		const tem_rc = rc_uuid !== null;

		if (tem_rc) {
			/**
			 * Itens editados
			 */
			const { all_ids, by_id } = itens;
			let tem_item_editado = false;
			for (let i = 0; i < all_ids.length; i++) {
				const item = by_id[all_ids[i]];
				if (item.editado) {
					tem_item_editado = true;
				}
			}

			/**
			 * Condição de pagamento
			 */
			const rc = compra.rc || {};
			const condicao_id = (compra.condicao_de_pagamento || {}).id || 0;
			const tem_condicao_existente = (rc.condicoes_de_pagamento || [])
				.filter(c => c.id === condicao_id).length > 0;

			/**
			 * Data de entrega
			 */
			const today = moment();
			let dias_prazo_entrega = rc.prazo_de_entrega !== null
				? rc.prazo_de_entrega
				: 0;
			if (today.hour() >= 16 && today.hour() <= 23) {
				dias_prazo_entrega++;
			}
			const dias_prazo_entrega_fds_added = dias_prazo_entrega <= 10
				? dias_prazo_entrega + 10
				: Math.ceil(((dias_prazo_entrega / 5) * 3) + dias_prazo_entrega);
			let data_entrega_min = moment();
			let days = 0;
			for (let i = 1; i <= dias_prazo_entrega_fds_added; i++) {
				const dia = moment().add(i, 'd').day();
				if (dia !== 6 && dia !== 0) {
					days++;
				}
				if (days === dias_prazo_entrega) {
					data_entrega_min = moment().add(i, 'd');
					break;
				}
			}
			const data_entrega_menor_minimo = moment(compra.data_de_entrega).isBefore(data_entrega_min, 'day');
			const entrega_menor_minimo = (
				data_entrega_menor_minimo &&
				rc.transporte !== 2 &&
				compra.tipo !== 5
			);

			/**
			 * Check
			 */
			if (
				compra.tipo === 4
				&& !changes_confirmation
				&& (
					!tem_condicao_existente
					|| entrega_menor_minimo
					|| tem_item_editado
				)
			) {
				this.setState({ changes_confirmation: true });
			}
		}
	}

	clearErrors() {
		this.setState({
			no_items_error: false,
			other_errors: false,
			changes_confirmation: false,
		});
	}

	enviaCompra(itens_has_error, info_has_error) {
		if (itens_has_error || info_has_error) {
			this.setState({ other_errors: true });
			return;
		}

		this.checkIsSaved();
	}

	checkIsSaved() {
		this.setState({ enviando: 1 });
		window.clearTimeout(this.save_timeout);
		const { compra_salva } = this.props;
		if (!compra_salva) {
			this.save_timeout = window.setTimeout(() => {
				this.checkIsSaved();
			}, 500);
			return;
		}
		this.mostraMensagemDeEdicao();
	}

	mostraMensagemDeEdicao() {
		const { modal_edicao_already_shown } = this.state;
		const { compra } = this.props;

		if (
			compra.status_front === 'em_edicao'
			&& !modal_edicao_already_shown
		) {
			this.setState({
				enviando: 0,
				modal_edicao: true,
				modal_edicao_already_shown: true,
			});
			return;
		}

		this.exportToSienge();
	}

	closeModaEdicao = () => {
		this.setState({ modal_edicao: false });
	}

	updateMotivoEdicao = (motivo_edicao = '') => {
		this.setState({ enviando: 1 });
		const { compra } = this.props;
		const url = `/conaz/v2/compras/${compra.id}`;
		const params = { motivo_edicao };
		_put(url, params).then(() => {
			this.closeModaEdicao();
			this.exportToSienge();
		}).catch(() => {
			this.setState({ enviando: 3 });
		});
	}

	exportToSienge = () => {
		const {
			concluir_sem_exportar,
		} = this.state;
		const {
			sienge_liberado,
			handleEnvioSideBar,
			handleExportSiengeModal,
		} = this.props;
		const has_sienge = sienge_liberado || false;

		if (has_sienge && !concluir_sem_exportar) {
			handleExportSiengeModal(true);
			handleEnvioSideBar();
			return;
		}

		this.postCompra();
	}

	postCompra = () => {
		const {
			concluir_sem_exportar,
		} = this.state;
		const {
			compra,
			redirectAfterConcluir,
			updateModals,
			updateComprasRaw,
		} = this.props;
		const url = `/conaz/v2/compras/${compra.id}/concluir`;

		// data decisao
		const data_de_decisao = compra.data_de_decisao || null;
		const _data_de_decisao = data_de_decisao === null
			? moment().format('YYYY-MM-DD')
			: moment(data_de_decisao).format('YYYY-MM-DD');

		const params = {};
		if (compra.tipo !== 4) {
			params.data_de_decisao = _data_de_decisao;
		}
		if (concluir_sem_exportar) {
			params.pular_aprovacao = true;
		}
		_post(url, params).then(() => {
			const message = compra.tipo === 4
				? 'Compra enviada com sucesso!'
				: 'Compra registrada com sucesso!';
			const alert = {
				color: 'green',
				message,
			};
			updateComprasRaw({ alert });
			redirectAfterConcluir();
		}).catch(error => {
			this.clearErrors();
			/**
			 * Notifica decisor
			 */
			if (error.response && error.response.status === 401) {
				this.setState({ enviando: 0 });
				updateModals('notifica_decisor', true);
				return;
			}

			this.setState({
				api_error: true,
				error_message: <p>Desculpe, aconteceu algo errado. Tente novamente após alguns segundos ou atualize a página.</p>,
				enviando: 0,
			});
			handleRequestErrors(error);
		});
	}

	render() {
		const {
			no_items_error,
			other_errors,
			enviando,
			api_error,
			error_message,
			changes_confirmation,
			modal_edicao,
		} = this.state;
		const {
			compra,
			itens,
			handleEnvioSideBar,
			sienge_liberado,
		} = this.props;
		const { by_id, all_ids } = itens;
		const desconto_geral = compra.condicao_de_pagamento.desconto || 0;

		const sub_total = all_ids.reduce((sum, id) => {
			const valores = calcValorItem(by_id[id]);
			const { total } = valores;
			return sum + total;
		}, 0);
		const total = sub_total - (sub_total * desconto_geral) / 100;
		const { valor_transporte, custos_adicionais } = compra;

		let button_text = 'Concluir compra';
		const show_sienge_button = sienge_liberado || false;
		if (show_sienge_button) {
			button_text = 'Concluir e Exportar para Sienge';
		}

		return (
			<>
				{modal_edicao && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.closeModaEdicao}>
							<ModalMotivoEdicao
								type="compra"
								updateModals={this.closeModaEdicao}
								enviaFunc={this.updateMotivoEdicao}
								enviando={enviando} />
						</ModalWrapperPortal>
					</ModalPortal>
				)}

				<div styleName="full-content">
					<button
						type="button"
						className="close"
						styleName="close-x"
						data-dismiss="modal"
						aria-label="Close"
						onClick={handleEnvioSideBar}>
						<span aria-hidden="true">&times;</span>
					</button>
					<button
						type="button"
						styleName="back-button"
						onClick={handleEnvioSideBar}>
						<i className="fa fa-reply" aria-hidden="true" />
						Voltar para compra
					</button>
					<div styleName="title">Resumo da compra</div>
					<div styleName="row">
						<div styleName="label">Subtotal dos itens</div>
						<div styleName="value blue">
							{formatCurrency(sub_total, 2, 'R$')}
						</div>
					</div>
					<div styleName="row">
						<div styleName="label">Desconto geral</div>
						<div styleName="value green">{desconto_geral}%</div>
					</div>
					<div styleName="row">
						<div styleName="label">Total dos itens</div>
						<div styleName="value blue">
							{formatCurrency(total, 2, 'R$')}
						</div>
					</div>

					<div styleName="row">&nbsp;</div>

					<div styleName="row">
						<div styleName="label">Transporte</div>
						<div styleName="value blue">
							{formatCurrency(valor_transporte, 2, 'R$')}
						</div>
					</div>
					<div styleName="row">
						<div styleName="label">Custos adicionais</div>
						<div styleName="value blue">
							{formatCurrency(custos_adicionais, 2, 'R$')}
						</div>
					</div>
					{(compra.usuario_fornecedor.nome !== undefined) && (
						<div styleName="row">
							<hr />
							<div styleName="title vendedor">Vendedor</div>
							<div styleName="label vendedor">
								<div>{compra.usuario_fornecedor.nome}</div>
								{/* <i className="fa fa-star" />
								<span>5.0</span>
								<small>58 avaliações</small> */}
							</div>
							<div styleName="value">
								<AvaliaVendedorButton compra={compra} />
							</div>
						</div>
					)}

					<div styleName="row">
						<div styleName="total-top-border" />
						<div styleName="total">
							<div styleName="value-total">
								{formatCurrency(
									total + valor_transporte + custos_adicionais,
									2,
									'R$',
								)}
								<div styleName="desconto">Desconto já aplicado</div>
							</div>
							<div styleName="label-total">Total</div>
						</div>
					</div>

					{no_items_error && (
						<div styleName="row">
							<div
								className="alert alert-warning"
								styleName="alert-1"
								role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} />{' '}
								Você não pode concluir uma compra sem itens.
							</div>
						</div>
					)}

					{other_errors && (
						<div styleName="row">
							<div
								className="alert alert-danger"
								styleName="alert-1"
								role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />{' '}
								Você possui campos obrigatórios em branco. Eles se encontram destacados em vermelho.
							</div>
						</div>
					)}

					{api_error && (
						<div styleName="row">
							<div
								className="alert alert-danger"
								styleName="alert-1"
								role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />{' '}
								{error_message}
							</div>
						</div>
					)}

					{changes_confirmation && (
						<div styleName="row">
							<div styleName="alteracoes-alert">
								<h4><i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> <b>Aviso importante</b></h4>
								<p>Como ocorreram alterações na sua proposta, existe a possibilidade do fornecedor não aceitá-las. Recomendamos ligar diretamente para o vendedor e alinhar suas alterações.</p>
							</div>
						</div>
					)}

					{enviando === 1 ? (
						<div styleName="row buttons">
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="voltar-button"
								style={{ opacity: '0.3' }}>
								<span>Voltar</span>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading"
								style={{ height: '32px', marginLeft: '16px', padding: '6px 30px 5px' }}>
								<LoadingButton />
								{button_text}
							</button>
						</div>
					) : (
						<div styleName="row buttons">
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="voltar-button"
								onClick={handleEnvioSideBar}>
								<span>Voltar</span>
								<i className="fa fa-times" aria-hidden="true" />
							</button>
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								style={{ height: '32px', marginLeft: '16px', padding: '6px 30px 5px' }}
								onClick={this.validacao}
							>
								{button_text}
							</button>
							{show_sienge_button && (
								<p>
									<button
										type="button"
										className="btn btn-link"
										style={{ marginTop: '18px', textDecoration: 'underline' }}
										onClick={this.concluirSemExportar}>
										Concluir compra sem exportar
									</button>
								</p>
							)}
						</div>
					)}
				</div>
			</>
		);
	}
}

EnvioCompra.propTypes = {
	compra: PropTypes.object.isRequired,
	itens: PropTypes.object.isRequired,
	handleEnvioSideBar: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	updateState: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	compra_salva: PropTypes.bool.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleExportSiengeModal: PropTypes.func.isRequired,
	sienge_liberado: PropTypes.bool.isRequired,
};

export default CSSModules(EnvioCompra, styles, { allowMultiple: true });
