export const DRAWER_OBRA_OPEN = 'DRAWER_OBRA_OPEN';
export const DRAWER_OBRA_OPEN_EDIT = 'DRAWER_OBRA_OPEN_EDIT';
export const DRAWER_OBRA_CLOSE = 'DRAWER_OBRA_CLOSE';

export const DRAWER_OBRA_SUBMIT_OBRA_SUCCESS = 'DRAWER_OBRA_SUBMIT_OBRA_SUCCESS';
// export const DRAWER_OBRA_SUBMIT_OBRA_ERROR = 'DRAWER_OBRA_SUBMIT_OBRA_ERROR';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_REQUEST = 'DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_REQUEST';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_SUCCESS = 'DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_SUCCESS';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_ERROR = 'DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_ERROR';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_REQUEST = 'DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_REQUEST';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_SUCCESS = 'DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_SUCCESS';
export const DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_ERROR = 'DRAWER_OBRA_SUBMIT_ASSOCIA_USUARIO_ERROR';

export const DRAWER_OBRA_SET_FORM_INSTANCE = 'DRAWER_OBRA_SET_FORM_INSTANCE';
// Parte de associar empresa
export const DRAWER_OBRA_HANDLE_CPY_OBRA_EMPRESA = 'DRAWER_OBRA_HANDLE_CPY_OBRA_EMPRESA';
export const DRAWER_OBRA_HANDLE_CHANGE_TO_CPY_EMPRESA = 'DRAWER_OBRA_HANDLE_CHANGE_TO_CPY_EMPRESA';
export const DRAWER_OBRA_ASSOCIA_EMPRESA_HAVE_CHANGED = 'DRAWER_OBRA_ASSOCIA_EMPRESA_HAVE_CHANGED';
export const DRAWER_OBRA_COPIA_EMPRESA_HAVE_CHANGED = 'DRAWER_OBRA_COPIA_EMPRESA_HAVE_CHANGED';
// parte de associar usuario
export const DRAWER_OBRA_CHANGE_TO_CPY_USUARIO = 'DRAWER_OBRA_CHANGE_TO_CPY_USUARIO';
export const DRAWER_OBRA_ASSOCIA_USUARIO_HAVE_CHANGED = 'DRAWER_OBRA_ASSOCIA_USUARIO_HAVE_CHANGED';
export const DRAWER_OBRA_CLOSE_CPY_USUARIO = 'DRAWER_OBRA_CLOSE_CPY_USUARIO';
// Controlador de steps e status do drawer.
export const DRAWER_OBRA_NEXT_STEP_REQUEST = 'DRAWER_OBRA_NEXT_STEP_REQUEST';
export const DRAWER_OBRA_PREV_STEP_REQUEST = 'DRAWER_OBRA_PREV_STEP_REQUEST';
export const DRAWER_OBRA_NEXT_STEP = 'DRAWER_OBRA_NEXT_STEP';
export const DRAWER_OBRA_PREV_STEP = 'DRAWER_OBRA_PREV_STEP';
export const DRAWER_OBRA_CHANGE_STEP_STATUS = 'DRAWER_OBRA_CHANGE_STEP_STATUS';
