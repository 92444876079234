import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CadastrarFornecedor from './CadastrarFornecedor';
import Loading from '../../../../components/_parts/_loadings/Loading/Loading';
import Integracao from './Integracao/InfoFornecedor/InfoFornecedor';
import TabsFornecedor from '../../../../components/_parts/TabsFornecedor/TabsFornecedor';
import TabFornecedor from '../../../../components/_parts/TabsFornecedor/TabFornecedor';
import TabVendedores from './TabVendedores';
import TabAvaliacoes from '../../../../components/_parts/_modals/LeftModalFornecedor/TabAvaliacoes';
import StarsAvaliacao from '../../../../components/_parts/_modals/LeftModalFornecedor/StarsAvaliacao';
import TabInformacoes from './TabInformacoes';
import { _post, _get } from '../../../../components/_functions/_requests';

class RightModalFornecedor extends Component {
	constructor(props) {
		super(props);
		const {
			usuario_fornecedor,
			fornecedor: { usuarios },
		} = props.compra;
		this.state = {
			vendedor_selecionado: {
				...(usuario_fornecedor || usuarios[0]),
			},
			form_novo_vendedor: false,
			salvando_selecao_fornecedor: false,
			error_vendedor: false,
			alert_sem_vendedor: false,
			selected_tab: 1,
		};
	}

	UNSAFE_componentWillMount() {
		this.requestComentarios();
	}

	changeTab = (tab = 1) => {
		this.setState({ selected_tab: tab });
	}

	openVendedorModal = () => {
		this.setState({
			form_novo_vendedor: true,
			vendedor_selecionado: { usuario: { id: -1 } },
		});
	};

	checkVendedor = (usuario) => {
		const vendedor = usuario && usuario.id !== undefined
			? usuario
			: {};
		this.setState({
			form_novo_vendedor: false,
			vendedor_selecionado: vendedor,
		});
	};

	closeModal = () => {
		if (!this.state.salvando_selecao_fornecedor) {
			this.props.updateModals('fornecedor', false);
		}
	};


	requestComentarios() {
		const { id } = this.props.compra.fornecedor;
		if (this.props.compra.fornecedor.nota_detalhada) {
			_get(`/avaliacao/v2/comentarios/fornecedor/${id}/comentarios_usuario_e_fornecedor?page=1&per_page=10`).then(response => {
				const avaliacoesFornecedor = response.data.data;
				if (avaliacoesFornecedor) {
					this.setState({ avaliacoesFornecedor });
				}
			});
		}
	}

	preAdicionaFornecedor = (vendedor) => {
		const {
			handleFornecedorSelecionado,
			handleCadastrarVendedor,
			fornecedor,
		} = this.props;
		handleCadastrarVendedor(fornecedor, vendedor)
			.then(res => {
				if (res !== null) {
					this.setState({ vendedor_selecionado: { ...res } }, () => {
						this.preSelecionarFornecedorVendedor();
					});
				} else {
					handleFornecedorSelecionado(true);
					this.setState({ error_vendedor: true }, () => {
						setTimeout(() => {
							this.setState({ error_vendedor: false });
						}, 2000);
					});
				}
			})
			.catch(err => console.error(err.message)); // eslint-disable-line
	};

	selecionarFornecedorVendedor = ({ fornecedor, vendedor }) => {
		this.setState({ vendedor_selecionado: vendedor }, () => {
			this.preSelecionarFornecedorVendedor(fornecedor);
		});
	};

	handleError = () => {
		this.setState({ error_vendedor: false });
	}

	preSelecionarFornecedorVendedor = (forn = null) => {
		const { vendedor_selecionado } = this.state;

		if (vendedor_selecionado.id === undefined) {
			this.setState({ alert_sem_vendedor: true });
			return;
		}

		const {
			compra,
			updateComprasRaw,
			autoSaveInfo,
			handleFornecedorSelecionado,
			fornecedor,
			fornecedor: { usuarios },
		} = this.props;

		const nova_compra = { ...compra };

		if (compra.avaliacao_vendedor.detalhe || compra.avaliacao_fornecedor.detalhe) {
			_post('/avaliacao/v2/usuario_fornecedor/deletar', { compra_id: compra.id });
			nova_compra.avaliacao_vendedor = {};
		}

		if (!usuarios || (forn !== null && !forn.target)) {
			const _fornecedor = {
				...forn,
				usuarios: [{ usuario: vendedor_selecionado }],
				fornecedor_id: forn.id,
				usuario_id: vendedor_selecionado.id,
			};

			nova_compra.fornecedor = _fornecedor;
			nova_compra.usuario_fornecedor = vendedor_selecionado;

		// troca de vendedor
		} else {
			nova_compra.fornecedor = {
				...fornecedor,
				fornecedor_id: fornecedor.id,
				usuario_id: vendedor_selecionado.id,
			};
		}

		updateComprasRaw({ nova_compra });

		handleFornecedorSelecionado();
		setTimeout(() => {
			autoSaveInfo().then(salvo => {
				handleFornecedorSelecionado(!salvo);
				this.closeModal();
			});
		}, 300);
	};

	render() {
		const {
			form_novo_vendedor,
			vendedor_selecionado,
			error_vendedor,
			alert_sem_vendedor,
			selected_tab,
		} = this.state;
		const {
			fornecedor,
			fornecedor_integrado,
			compra,
			form_novo_fornecedor,
			fornecedor_nome,
			salvando_selecao_fornecedor,
			handleCadastrarFornecedorVendedor,
			fornecedor_ja_existe,
			loading_request,
			logged_sienge,
			saveFornecedorSienge,
		} = this.props;
		const vendedores = fornecedor.usuarios || [];
		const f_integrado = fornecedor_integrado.id !== undefined
			? fornecedor_integrado
			: compra.fornecedor_integrado;

		return (
			<div styleName="modal-content">
				{fornecedor.id && (
					<Integracao
						saveFornecedorSienge={saveFornecedorSienge}
						fornecedor_integrado={f_integrado}
						fornecedor_on_edition={fornecedor}
						compra={compra}
						logged_sienge={logged_sienge}
					/>
				)}
				<div styleName="content">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.closeModal}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{!loading_request ? (
						<>
							{!form_novo_fornecedor ? (
								<>
									<>
										<h3>{fornecedor.nome}</h3>
										{fornecedor?.nota_detalhada?.media ? <div styleName="fornecedor-title-stars">
											<StarsAvaliacao media={fornecedor?.nota_detalhada?.media} starSize={16} />
										</div>	:	<div styleName="fornecedor-title-stars">
											<i className="fa fa-star" aria-hidden="true" />
											<i className="fa fa-star" aria-hidden="true" />
											<i className="fa fa-star" aria-hidden="true" />
											<i className="fa fa-star" aria-hidden="true" />
											<i className="fa fa-star" aria-hidden="true" />
											<span>Em período de coleta de avaliações</span>
										</div>
										}
									</>

									<TabsFornecedor>
										<TabFornecedor
											tab_number={1}
											title="Vendedores"
											description="Selecione o vendedor de sua preferência"
											selected_tab={selected_tab}
											changeTab={this.changeTab}
										/>
										<TabFornecedor
											tab_number={2}
											title="Avaliações"
											description="Veja como você e outras construtoras avaliaram o fornecedor"
											selected_tab={selected_tab}
											changeTab={this.changeTab}
										/>
										<TabFornecedor
											tab_number={3}
											title="Informações gerais"
											description="Verifique razão social, CNPJ e endereço "
											selected_tab={selected_tab}
											changeTab={this.changeTab}
										/>
									</TabsFornecedor>

									<div styleName="tabs-bottom-wrapper">
										{selected_tab === 1 && (
											<TabVendedores
												fornecedor_ja_existe={fornecedor_ja_existe}
												vendedores={vendedores}
												vendedor_selecionado={vendedor_selecionado}
												checkVendedor={this.checkVendedor}
												form_novo_vendedor={form_novo_vendedor}
												openVendedorModal={this.openVendedorModal}
												alert_sem_vendedor={alert_sem_vendedor}
												salvando_selecao_fornecedor={salvando_selecao_fornecedor}
												preSelecionarFornecedorVendedor={this.preSelecionarFornecedorVendedor}
												error_vendedor={error_vendedor}
												handleError={this.handleError}
												preAdicionaFornecedor={this.preAdicionaFornecedor}
											/>
										)}
										{selected_tab === 2 && (
											<TabAvaliacoes
												vendedores={vendedores}
												nota_detalhada={fornecedor.nota_detalhada}
												avaliacoes={this.state.avaliacoesFornecedor}
											/>
										)}
										{selected_tab === 3 && (
											<TabInformacoes
												fornecedor={fornecedor}
											/>
										)}
									</div>
								</>
							) : (
								<CadastrarFornecedor
									handleCadastrarFornecedorVendedor={handleCadastrarFornecedorVendedor}
									pre_nome_fornecedor={fornecedor_nome}
									selecionarFornecedorVendedor={this.selecionarFornecedorVendedor}
									salvando_novo_fornecedor={salvando_selecao_fornecedor}
								/>
							)}
						</>
					) : (
						<div styleName="loading">
							<Loading />
						</div>
					)}
				</div>
			</div>
		);
	}
}

RightModalFornecedor.propTypes = {
	updateModals: PropTypes.func,
	updateComprasRaw: PropTypes.func.isRequired,
	autoSaveInfo: PropTypes.func.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	fornecedor: PropTypes.object,
	compra: PropTypes.object.isRequired,
	fornecedor_integrado: PropTypes.object.isRequired,
	form_novo_fornecedor: PropTypes.bool.isRequired,
	fornecedor_nome: PropTypes.string.isRequired,
	handleFornecedorSelecionado: PropTypes.func.isRequired,
	handleCadastrarVendedor: PropTypes.func.isRequired,
	handleCadastrarFornecedorVendedor: PropTypes.func.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	loading_request: PropTypes.bool.isRequired,
	logged_sienge: PropTypes.bool.isRequired,
};

RightModalFornecedor.defaultProps = {
	updateModals: () => {},
	fornecedor: {},
};

export default CSSModules(RightModalFornecedor, styles, {
	allowMultiple: true,
});
