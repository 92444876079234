import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { setClasses } from '../../_functions/_setClasses';
import SpreadStep from './SpreadStep';

// Components


const StepV2 = ({
	stage,
	current,
	label,
	descricao,
	first,
	steps,
	firsts_steps,
	size,
	done,
}) => {
	const is_active = stage <= current;
	const [isVisible, setAsVisible] = useState(false);
	const step_classes = {
		is_active,
		current: stage === current,
		cancelado: label === 'Cancelada em' || label === 'Compra recusada em',
		registred:
			label === 'Registrada e aprovada em ' ||
			label === 'Confirmada pelo fornecedor em' ||
			label === 'Confirmada por você em' ||
			done,
	};
	const _size = first ? 8 : size;
	const step_wrapper_class = setClasses(
		`step-wrapper size-${_size}`,
		step_classes,
	);
	const zIndex = steps.length - stage + 1;

	const render_steps = firsts_steps.map((step, i) => (
		// primeiros steps
		<SpreadStep
			last={i === firsts_steps.length - 1}
			key={i.toString()}
			label={step.label}
			descricao={step.descricao} />
	));


	if (first) {
		return (
			<>
				<div styleName="button-wrapper">
					<div styleName="step-wrapper button" style={{ zIndex }}>
						<div styleName="triangle button" />
						<div styleName="triangle-border" />
						<div styleName="button-div">
							<button onClick={() => setAsVisible(!isVisible)}>
								<p>
									{`Ver ${firsts_steps.length}`}
									<i className="fa fa-plus" />
								</p>
								<i className={`fa fa-chevron-${isVisible ? 'up' : 'down'}`} />
							</button>
						</div>
					</div>
					{isVisible && (
						<div styleName="spread-wrapper">
							{render_steps}
						</div>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div styleName={step_wrapper_class} style={{ zIndex }}>
				<div styleName="triangle" />
				<div styleName="triangle-border" />
				{stage === current && !done ? (
					<div styleName="dot" />
				) : (
					<i
						className="fa fa-check-circle fa-fw"
						aria-hidden="true"
					/>
				)}
				<div styleName="descricao">
					{label}
					{descricao}
				</div>
			</div>
		</>
	);
};

StepV2.propTypes = {
	stage: PropTypes.number,
	current: PropTypes.number,
	first: PropTypes.bool,
	firsts_steps: PropTypes.array,
	label: PropTypes.string.isRequired,
	descricao: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	steps: PropTypes.array,
	size: PropTypes.number,
	done: PropTypes.bool,

};

StepV2.defaultProps = {
	first: false,
	done: false,
	stage: 1,
	current: 1,
	descricao: '',
	steps: [],
	firsts_steps: [],
	size: 1,
};

export default CSSModules(StepV2, styles, { allowMultiple: true });
