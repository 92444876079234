import update from 'immutability-helper';
import avaliacoes from './store';

import {
	UPDATE_AVALIACOES,
	AVALIACOES_REQUEST,
	AVALIACOES_SUCCESS,
	AVALIACOES_FAILED,
} from './types';

// Functions
import { isGeneralError, handleGeneralErrors } from '../utility';

const reducer = (state = avaliacoes, action) => {
	switch (action.type) {
	case UPDATE_AVALIACOES: {
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});

		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});

		default:
			return state;
		}
	}

	case AVALIACOES_REQUEST: {
		return {
			...state,
			fetching: 10,
			fornecedor: action.fornecedor,
			result: [],
		};
	}

	case AVALIACOES_SUCCESS: {
		const { data } = action.payload;
		return {
			...state,
			fetching: 20,
			result: [...data.result],
			page: { ...data.page },
		};
	}

	case AVALIACOES_FAILED: {
		const error = action.error || {};
		const general_error = isGeneralError(error);
		if (general_error) {
			handleGeneralErrors(error);
			return { ...state };
		}

		return {
			...state,
			fetching: 30,
		};
	}

	default:
		return state;
	}
};

export default reducer;
