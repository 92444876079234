import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

function SpreadStep({ last, label, descricao }) {
	return (
		<div styleName="step-view">
			<div styleName="dot-container">
				<div styleName="dot" />
			</div>
			<div styleName="descricao">
				<span styleName="label">{label}</span>
				<span>{descricao}</span>
			</div>
			{!last && <div styleName="step-bar" /> }
		</div>
	);
}

SpreadStep.propTypes = {
	last: PropTypes.bool,
	label: PropTypes.string.isRequired,
	descricao: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

SpreadStep.defaultProps = {
	last: false,
};

export default CSSModules(SpreadStep, styles, { allowMultiple: true });
