/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Row, Col, Form, Input, Divider, Upload, message } from 'antd';
import InputMask from 'react-input-mask';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { validations } from 'ambar-ui';
import { getLocalstorageData } from 'components/_functions/_getLocalstorageData';

import EnderecoForm from '../../EnderecoForm';
import validateCPF from './validCPF';

const { validateRequired } = validations;

function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
}

const UploadButton = ({ loading }) => (
	<div>
		{loading ? <LoadingOutlined /> : <PlusOutlined />}
		<div stlye={{ marginTop: 10 }}>
			Anexar
		</div>
	</div>
);

function FormPessoaFisica({ mode, form, empresaData, disableAll, ...props }) {
	const [previewImg, setPreviewImg] = useState(form.getFieldValue('logo_url') || null);

	const getValue = (e) => {
		if (Array.isArray(e)) return e;
		return e && e.file;
	};

	function beforeUpload(file) {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
			message.error('Somente arquivos .jpeg, .jpg ou .png');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error('Arquivo deve ser menor que 2mb!');
		}
		// retornando false para skipar o upload interno do <Upload />
		return false;
	}

	const handleFile = async (e) => {
		const base_url = getLocalstorageData('environment', 'base_url');
		const token = getLocalstorageData('user', 'token');

		const { file } = e;
		const f = await getBase64(file);
		setPreviewImg(f);
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (file && file.status !== 'removed') {
			if (!isJpgOrPng) {
				message.error('Somente arquivos .jpeg, .jpg ou .png');
			}

			const url = `${base_url}/conaz/v2/pessoas/${empresaData.id}/logo`;
			const data = new FormData();
			data.append('files', file, file.name);

			axios.defaults.headers.common.token = token;
			axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
			axios.post(url, data).then(() => {
			}).catch({});
		}
		return false;
	};

	useEffect(() => {
		(async () => {
			const ficha_cadastral = form.getFieldValue('logo_url');
			if (!ficha_cadastral) return;
			if (typeof ficha_cadastral === 'string') setPreviewImg(ficha_cadastral);
		})();
	}, [mode, form, previewImg]);

	return (
		<>
			<Row gutter={12}>
				<Col span={5}>
					<div className="logo-uploader">
						<Form.Item name="ficha_cadastral" label="Logo" getValueFromEvent={getValue} valuePropName="fieldList">
							<Upload
								listType="picture-card"
								name="perfil"
								accept="image/png,image/jpg,image/jpeg"
								showUploadList={false}
								beforeUpload={beforeUpload}
								onChange={mode === 'edit' && mode !== 'new' ? handleFile : null}
								disabled={disableAll}
							>
								{previewImg ? <img src={previewImg} alt="avatar" style={{ width: '100%' }} /> : <UploadButton loading={false} />}
							</Upload>
						</Form.Item>
					</div>
				</Col>
				<Col span={12}>
					<Form.Item name="nome" label="Nome da empresa" rules={[validateRequired()]}>
						<Input disabled={disableAll} />
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="documento" label="CPF" rules={[validateRequired(), validateCPF()]}>
						<InputMask mask="999.999.999-99" placeholder="000.000.000-00" disabled={disableAll}>
							{(p) => <Input disabled={disableAll} {...p} />}
						</InputMask>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={12}>
				<Col span={5} />
				<Col span={19} style={{ marginTop: '-12rem' }}>
					<EnderecoForm mode={mode} form={form} disableAll={disableAll} {...props} />
				</Col>
			</Row>

			<Divider className="divider" />

		</>
	);
}

UploadButton.propTypes = {
	loading: PropTypes.bool,
};

UploadButton.defaultProps = {
	loading: false,
};


FormPessoaFisica.propTypes = {
	mode: PropTypes.string,
	props: PropTypes.shape({ }),
	disableAll: PropTypes.bool,
	form: PropTypes.shape({
		getFieldValue: PropTypes.func,
	}),
	empresaData: PropTypes.shape().isRequired,
};

FormPessoaFisica.defaultProps = {
	mode: null,
	props: {},
	form: {},
	disableAll: false,
};

export default FormPessoaFisica;
