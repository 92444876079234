import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import ToolTip from '../../../../components/_parts/_errors/ToolTip/ToolTip';

import { emailValidation } from '../../../../components/_functions/_emailValidation';
import { titleCase } from '../../../../components/_functions/_titleCase';
import { maskPhone } from '../../../../components/_functions/_maskPhone';
import { validaTelefone } from '../../../../components/_functions/_validaTelefone';

class CadastrarFornecedor extends React.Component {
	static titleHasRestrictChars(value) {
		return value.match(/[&\\#,+@$~^%'":/|*?<>{}]/g);
	}

	constructor() {
		super();
		this.state = {
			nome_empresa: '',
			telefones: [{ numero: '', tipo: 1 }],
			email: '',
			nome_vendedor: '',
			site: '',
			errors: {
				nome_empresa: false,
				nome_empresa_invalido: false,
				telefones: [],
				email: false,
				nome_vendedor: false,
				fornecedor_existente: false,
			},
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			nextProps.pre_nome_fornecedor !== '' &&
			this.state.nome_empresa !== nextProps.pre_nome_fornecedor
		) {
			this.setState({ nome_empresa: nextProps.pre_nome_fornecedor });
		}
	}

	clearErrors = () => {
		this.setState({
			errors: {
				nome_empresa: false,
				nome_empresa_invalido: false,
				telefones: [],
				email: false,
				nome_vendedor: false,
				fornecedor_existente: false,
			},
		});
	};

	updateState = e => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
		this.clearErrors();
	};

	handleEditionTelefones = (idx, value) => {
		const { telefones } = this.state;
		telefones[idx].numero = maskPhone(value);
		this.setState({ telefones });
		this.clearErrors();
	};

	removerTelefone = idx => {
		const { telefones } = this.state;
		telefones.splice(idx, 1);
		this.setState({ telefones });
		this.clearErrors();
	};

	adicionarTelefone = () => {
		const { telefones } = this.state;
		telefones.push({ numero: '', tipo: 1 });
		this.setState({ telefones });
		this.clearErrors();
	};

	validarFornecedorVendedor = () => {
		const { nome_empresa, telefones, email } = this.state;
		const email_validation = emailValidation(email);

		if (nome_empresa === '') {
			this.setState({
				errors: { ...this.state.errors, nome_empresa: true },
			});
			document.getElementById('novo_fornecedor_nome_empresa').focus();
			return false;
		}
		if (this.constructor.titleHasRestrictChars(nome_empresa)) {
			this.setState({
				errors: { ...this.state.errors, nome_empresa_invalido: true },
			});
			document.getElementById('novo_fornecedor_nome_empresa').focus();
			return false;
		}
		if (!email_validation) {
			this.setState({ errors: { ...this.state.errors, email: true } });
			document.getElementById('novo_fornecedor_email').focus();
			return false;
		}

		let telefones_validos = true;
		const telefones_errors = this.state.errors.telefones;
		telefones.forEach((telefone, idx) => {
			if (!validaTelefone(telefone.numero)) {
				telefones_errors[idx] = true;
				telefones_validos = false;
				document
					.getElementsByClassName('novo_fornecedor_telefone')
					// eslint-disable-next-line no-unexpected-multiline
					[idx].focus();
			}
		});
		this.setState({
			errors: { ...this.state.errors, telefones: telefones_errors },
		});

		if (!telefones_validos) {
			return false;
		}

		return true;
	};

	preAdicionaNovoFornecedor = async () => {
		const {
			selecionarFornecedorVendedor,
			handleCadastrarFornecedorVendedor,
		} = this.props;
		if (this.validarFornecedorVendedor()) {
			const {
				nome_empresa,
				telefones,
				email,
				nome_vendedor,
				site,
			} = this.state;

			const fornecedor_novo = {
				nome: nome_empresa.toUpperCase(),
				site,
			};

			const _telefones = telefones.filter(tel => tel.numero !== '');
			const usuario_novo = {
				nome: titleCase(nome_vendedor),
				email,
				telefones: _telefones.map(tel => ({
					...tel,
					numero: tel.numero.replace(/\D/g, ''),
				})),
			};
			try {
				const res = await handleCadastrarFornecedorVendedor(
					fornecedor_novo,
					usuario_novo,
				);
				selecionarFornecedorVendedor(res);
			} catch (err) {
				if (err.message.indexOf('fornecedor') !== -1) {
					this.setState({
						errors: { ...this.state.errors, fornecedor_existente: true },
					});
				}
			}
		}
	};

	render() {
		const chars_blocked = '& / # , + @ $ ~ ^ % \' " : | * ? < > { } \\';
		const { salvando_novo_fornecedor } = this.props;
		const {
			nome_empresa,
			telefones,
			email,
			nome_vendedor,
			errors,
		} = this.state;
		return (
			<>
				{errors.nome_empresa && (
					<ToolTip
						message="O nome da empresa é obrigatório"
						display="top"
						margin_top="-32px" />
				)}
				{errors.nome_empresa_invalido && (
					<ToolTip
						message={`O nome não pode conter os caracteres a seguir: ${chars_blocked}`}
						display="top"
						margin_top="-32px" />
				)}
				<label>
					Nome da empresa <span styleName="obrigatorio">*</span>
				</label>
				<input
					type="text"
					name="nome_empresa"
					className="form-control input-md"
					id="novo_fornecedor_nome_empresa"
					value={nome_empresa}
					maxLength="100"
					onChange={this.updateState} />
				{errors.email && (
					<ToolTip
						message="O e-mail para contato é obrigatório"
						display="top"
						margin_top="-35px" />
				)}
				{errors.fornecedor_existente && (
					<ToolTip
						message="O e-mail para contato já está cadastrado no sistema"
						display="top"
						margin_top="-35px" />
				)}
				<label>
					E-mail <span styleName="obrigatorio">*</span>
				</label>
				<input
					type="text"
					name="email"
					className="form-control input-md"
					id="novo_fornecedor_email"
					maxLength="80"
					value={email}
					onChange={this.updateState} />
				{/* eslint-disable react/no-array-index-key */
					telefones.map((tel, idx) => (
						<React.Fragment key={idx}>
							{errors.telefones[idx] && (
								<ToolTip
									message="Telefone inválido"
									display="top"
									margin_top="-34px"
									margin_left="40px" />
							)}
							<label>
								Telefone{' '}
								{idx === 0 && (
									<span styleName="obrigatorio">*</span>
								)}
							</label>
							<input
								type="text"
								name="telefone"
								className="form-control input-md novo_fornecedor_telefone"
								styleName="telefone"
								value={tel.numero}
								onChange={e => this.handleEditionTelefones(idx, e.target.value)
								} />
							{idx > 0 && (
								<button
									type="button"
									className="btn btn-link"
									style={{ fontSize: '16px' }}
									onClick={() => this.removerTelefone(idx)}>
									<i className="fa fa-trash" aria-hidden="true" />
								</button>
							)}
						</React.Fragment>
					))}
				<div>
					<button
						type="button"
						className="btn btn-link"
						styleName="novo-telefone-button"
						onClick={this.adicionarTelefone}>
						<i className="fa fa-plus fa-fw" aria-hidden="true" />{' '}
						adicionar novo telefone
					</button>
				</div>
				<label>
					Nome do vendedor <span styleName="obrigatorio">*</span>
				</label>
				<input
					type="text"
					name="nome_vendedor"
					className="form-control input-md"
					value={nome_vendedor}
					maxLength="60"
					onChange={this.updateState} />
				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					styleName={
						salvando_novo_fornecedor
							? 'btn-adicionar-salvando'
							: 'btn-adicionar'
					}
					onClick={this.preAdicionaNovoFornecedor}
					disabled={salvando_novo_fornecedor}>
					{salvando_novo_fornecedor
						? 'Salvando fornecedor...'
						: 'Salvar e selecionar'}
				</button>
			</>
		);
	}
}

CadastrarFornecedor.propTypes = {
	salvando_novo_fornecedor: PropTypes.bool.isRequired,
	handleCadastrarFornecedorVendedor: PropTypes.func.isRequired,
	pre_nome_fornecedor: PropTypes.string.isRequired,
	selecionarFornecedorVendedor: PropTypes.func.isRequired,
};

export default CSSModules(CadastrarFornecedor, styles, { allowMultiple: true });
