import styled from 'styled-components';
import { device_grouped } from '../../styles/variables';

export const Header = styled.div`
	width: 100%;
	height: 100px;
	position: fixed;
	${device_grouped.mobile} {
		height: 60px;
	}
	display: flex;
	justify-content: center;
	align-content: center;
	background: #FFFFFF;
	border: 1px solid #BBBBBB;
	box-sizing: border-box;
	z-index: 1;
	div {
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;


		> span {
			${device_grouped.mobile} {
				font-size: 20px;
			}
			font-size: 24px;
			line-height: 28px;
			color: #000000;
			margin-left: -25px;
			${device_grouped.laptop} {
				margin-left: -30%;
			}

			${device_grouped.mobile} {
				margin-left: 0px;
			}
		}

		> button {
			display: none;
			border: none;
			background: inherit;
			position: relative;
			left: 70px;
			color: #C4C4C4;
			${device_grouped.mobile} {
				&.showm {
					display: block;
				}
			}
		}

		> .fa {
			${device_grouped.mobile} {
				display: none;
				&.showm {
					display: block;
					left: 30px;
				}
			}
			position: relative;
			left: 25px;
			color: #C4C4C4;
			font-size: 12px;
			line-height: 14px;
		}

		> .search-input {
			${device_grouped.mobile} {
				width: 1px;
				opacity: 0;
				transition: width .5s ease;
				margin-right: 0;
				margin-left: 0px;

				&:focus {
					opacity: 1;
					width: 46vw;
				}
			}
			width: 300px;
			margin-right: 230px;
			${device_grouped.laptop} {
				margin-right: 25%;
			}
			height: 30px;
			border: 0.5px solid #828282;
			box-sizing: border-box;
			padding-left: 40px;

				::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
					color: #C4C4C4;
					${device_grouped.mobile} {
						font-size: 3.5vw;
					}
					font-size: 14px;
				}

				:-ms-input-placeholder { /* Internet Explorer 10-11 */
					color: #C4C4C4;
					${device_grouped.mobile} {
						font-size: 3.5vw;
					}
					font-size: 14px;
				}

				::-ms-input-placeholder { /* Microsoft Edge */
					color: #C4C4C4;
					${device_grouped.mobile} {
						font-size: 3.5vw;
					}
					font-size: 14px;
				}
		}
	}
`;

export const SearchHeader = styled.div`
	width: 100%;
	padding-top: 38px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 50px) and (max-width: 768px) {
		display: none;
	}

	> span {
		${device_grouped.mobile} {
			font-size: 20px;
		}
		font-size: 24px;
		line-height: 28px;
		color: #000000;
		margin-left: -25px;
		${device_grouped.laptop} {
			margin-left: -30%;
		}

		${device_grouped.mobile} {
			margin-left: 0px;
		}
	}

	> button {
		display: none;
		border: none;
		background: inherit;
		position: relative;
		color: #C4C4C4;
		${device_grouped.mobile} {
			&.showm {
				display: block;
			}
		}
	}

	> .fa {
		${device_grouped.mobile} {
			display: none;
			&.showm {
				display: block;
				left: 30px;
			}
		}
		position: relative;
		left: 25px;
		color: #C4C4C4;
		font-size: 12px;
		line-height: 14px;
	}

	> .search-input {
		${device_grouped.mobile} {
			width: 1px;
			opacity: 0;
			transition: width .5s ease;
			margin-right: 0;
			margin-left: 0;

			&:focus {
				opacity: 1;
				margin-right: auto;
				width: 46vw;
			}
		}

		${device_grouped.tablet} {
			width: 200px;
		}

		width: 323px;
		height: 40px;
		border: 1px solid #ccc;
		box-sizing: border-box;
		padding-left: 40px;
	}
`;

export const OrderBlock = styled.div`
	position: relative;
	width: 80%;
	display: flex;
	justify-content: flex-end;
	margin: 60px 0;
	${device_grouped.mobile} {
		margin: 40px 0;
	}
	font-size: 12px;
	line-height: 14px;

	button {
		background: none;
		border: none;
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

`;

export const EvaluationBlock = styled.div`
	margin: 10px auto 0 auto;
	width: 100%;
	background: #FFFFFF;
	padding: 25px;
	margin-bottom: 15px;
`;

export const HeaderCompra = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	> a {
		text-align: left;
		text-decoration: none;
		flex: 3 80%;
		${device_grouped.laptop} {
			flex: 3 50%;
		}

		${device_grouped.mobile} {
			flex: 1 100%;
		}

		&:hover {
			text-decoration: underline;
			text-decoration-color: #000;
		}
	}

	.compra-id, .title, .score {
		font-size: 18px;
		line-height: 21px;
	}

	.compra-title {
		font-size: 18px;
		line-height: 21px;
		color: #000;
	}

	.compra-id {
		color: #C4C4C4;
		margin-right: 5px;
	}

	.score {
		flex: 1 1 18%;
		${device_grouped.mobile} {
			> ul {
				margin-left: 0px;
				font-size: 18px;
			}

			margin-bottom: 10px;
		}
	}

	div {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: center;

		.fornecedor-label {
			flex: 1 100%;
			color: #C4C4C4;
			font-size: 16px;
		}

		.fornecedor {
			flex: 1 50%;
			font-size: 14px;
			color: black;
			/* grid-column: 1 / span 2;
			grid-row: 3; */
		}
	}

	button:last-of-type {
		flex: 1 50%;
		text-align: right;
	}
`;

export const Criterios = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	> span { flex: 1 0 100% }

	.criterio-block {
		color: ${({ disabled }) => (disabled ? '#C4C4C4' : '#333333')};
		&:last-of-type {
			margin-right: 0px;
		}
		flex: 3;
		${device_grouped.mobile} {
			flex: 3 30%;
			height: 110px;
			margin-right: 15px;
		}
		height: 75px;
		${device_grouped.laptop} {
			height: 90px;
			margin-right: 15px;
		}

		margin-right: 50px;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		${device_grouped.laptop} {
			&:first-of-type, &:last-of-type {
				> div {
					margin-top: 16px;
				}
			}
		}

		${device_grouped.mobile} {
			&:nth-of-type(3), &:last-of-type {
				margin-top: 30px;
			}
		}

		> span {
			flex: 1 100%;
			margin-bottom: 15px;
		}

		> div {
			flex: 1 100%;

			> span {
				background: white;
				border-radius: 30px;
				box-sizing: border-box;
				border: 0.8px solid #C4C4C4;
				color: #C4C4C4;
				padding: 5px 25px;

				${device_grouped.mobile} {
					padding: 5px 15px;
				}

				&.success,
				&.failed {
					border-color: #C4C4C4;
					> i {
						color: #C4C4C4;
					}
				}

				&.success.selected {
					border-color: #27AE60;
					> i {
						color: #27AE60;
					}
				}

				&.failed.selected {
					border-color: red;
					> i {
						color: red;
					}
				}

				&:first-of-type {
					margin-right: 10px;
				}
			}
		}
	}
`;

export const AvaliacaoVendedor = styled.div`
	display: flex;
	flex-flow: row wrap;

	> span {
		flex: 3 0 70%;
		${device_grouped.laptop} {
			flex: 3 0 50%;
		}
		${device_grouped.mobile} {
			flex: 1 100%;
		}
	}

	> div {
		flex: 1 15%;
		text-align: end;
		margin-top: 0px;
	}
	.v_rated {
		flex: 1 100%;
		text-align: left;
	}
`;

export const FooterAvaliacao = styled.div`
	display: flex;

	.data-block {
		&:last-of-type {
			flex: 1;
			p:last-of-type { color: #27AE60 }
		}
		flex: 3;
		font-family: Roboto;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;

		p:first-child { color: #C4C4C4; margin-bottom: 5px; }
		p:last-child { color: #000000 }
	}

`;

export const Score = styled.div`
	.score-number {
			margin-top: -5px;
			margin-left: 15px;
			color: #fadb14;
			font-size: 18px;
			&.unrated {
				color: #C4C4C4;
			}

		}

	> small {
		width: 100%;
		color: #C4C4C4;
		font-size: 12px;
		line-height: 14px;
	}
`;

export const Title = styled.span`
	font-size: 16px;
	line-height: 19px;
	font-weight: 500;
	color: ${({ disabled }) => (disabled ? '#C4C4C4' : 'black')};
	margin-bottom: 15px;
`;

export const Description = styled.p`
	color: #333333;
	margin-top: 15px;
	font-size: 14px;
	line-height: 18px;
	font-weight: normal;
	font-family: 'Roboto';
`;

export const LinkButton = styled.button`
	background: white;
	border: none;
	text-decoration: underline;
	color: #2F80ED;
	margin: 0;
	padding: 0;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
`;


export const ErrorWrapper = styled.div`
	position: relative;
	padding-top: 30px;
	text-align: center;
	font-size: 16px;

	> i {
		font-size: 80px;
		color: #cbced3;
	}

	> p {
		margin-top: 12px;
		margin-bottom: 26px;
		font-size: 18px;
		color: #222;
	}
`;

export const SectionPagination = styled.section`
	margin: 20px 0;
	text-align: center;
`;
