import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

import { formatNumbers } from '../../../../components/_functions/_formatNumbers';

const VendedorRow = ({ vendedor, estah_selecionado }) => {
	let _telefones = vendedor.telefones.map(tel => formatNumbers(tel.numero, 'telefone'));
	_telefones = _telefones.length > 0 ? _telefones.join(' / ') : '';

	return (
		<div styleName="vendedor-wrapper">
			{estah_selecionado ? (
				<i
					className="fa fa-check"
					aria-hidden="true"
					style={{ color: '#3cd23c' }} />
			) : (
				<i className="fa fa-long-arrow-right" aria-hidden="true" />
			)}
			<div styleName="nome">
				{vendedor.nome}
				{estah_selecionado && (
					<span style={{ color: '#afafaf' }}>
						{' '}
						(selecionado para esta cotação)
					</span>
				)}
			</div>
			<div styleName="email">{vendedor.email}</div>
			{_telefones !== '' && <div styleName="telefone">{_telefones}</div>}
		</div>
	);
};

VendedorRow.propTypes = {
	vendedor: PropTypes.object.isRequired,
	estah_selecionado: PropTypes.bool.isRequired,
};

export default CSSModules(VendedorRow, styles, { allowMultiple: true });
