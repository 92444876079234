import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';

const SiengeInicio = ({
	// =========== state
	loading,
	// =========== store
	user,
	// =========== local funcs
	// =========== prop funcs
}) => {
	const { sienge } = user;
	const {
		login_configurado,
		obras_configuradas,
		itens_configurados,
		fornecedores_configurados,
	} = sienge;

	const color_on = '#6dc641';

	if (loading === 1) {
		return <LoadingPage />;
	}

	return (
		<>
			{/* Header */}
			<div className="header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h3 className="h3-header">Conectar ao SIENGE</h3>
							<p styleName="sub-title">Agora você pode se conectar ao SIENGE através da plataforma Conaz</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container" style={{ heigth: '210px' }}>
				<div className="row ">
					<div className="col-md-4" styleName="blocks-wrapper">
						<div styleName="number">1</div>
						{login_configurado ?
							(<h4 className="h3-header" style={{ color: color_on }}> <strong>Alterar usuário <i className="fa fa-check" aria-hidden="true" /> </strong> </h4>)
							:
							(<h4 className="h3-header">Faça seu login </h4>)
						}
						<p styleName="sub-title-content sub-title-custom-margin">Precisamos nos conectar à sua<br /> conta no Sienge para sincronizarmos<br /> suas informações.</p>
						<Link
							to="/integracao/sienge/login"
							className="btn btn-clear-conaz round-border"
							styleName="add-files-button">
							<span>{login_configurado ? 'Alterar usuário' : 'Logar no SIENGE'}</span>
							<i className="fa fa-long-arrow-right" aria-hidden="true" />
						</Link>
					</div>
					<div className="col-md-4 h-content" styleName="blocks-wrapper" style={{ opacity: `${login_configurado ? '1' : '0.4'}` }}>
						<div styleName="number">2</div>
						{obras_configuradas ?
							(<h4 className="h3-header" style={{ color: color_on }}> <strong>Conecte suas obras <i className="fa fa-check" aria-hidden="true" /> </strong> </h4>)
							:
							(<h4 className="h3-header">Conecte suas obras </h4>)
						}
						<p styleName="sub-title-content">Sincronize suas obras do Sienge<br /> com as obras da Conaz.</p>
						<div>
							<Link
								to={login_configurado ? '/integracao/sienge/obras' : '/integracao/sienge'}
								disabled={!login_configurado}
								className="btn btn-clear-conaz round-border"
								styleName="add-files-button">
								<span>Configurar obras</span>
								<i className="fa fa-long-arrow-right" aria-hidden="true" />
							</Link>
						</div>
					</div>
					<div className="col-md-4 h-content" styleName="blocks-wrapper" style={{ opacity: `${obras_configuradas ? '1' : '0.4'}` }}>
						<div styleName="number">3</div>
						{itens_configurados ?
							(<h4 className="h3-header" style={{ color: color_on }}> <strong>Configure seus itens <i className="fa fa-check" aria-hidden="true" /> </strong> </h4>)
							:
							(<h4 className="h3-header">Configure seus itens</h4>)
						}
						<p styleName="sub-title-content">Associe seus itens para facilitar<br /> seus pedidos no futuro.</p>
						<div>
							<Link
								to={obras_configuradas ? '/integracao/sienge/itens' : '/integracao/sienge'}
								disabled={!obras_configuradas}
								className="btn btn-clear-conaz round-border"
								styleName="add-files-button">
								<span>Configurar itens</span>
								<i className="fa fa-long-arrow-right" aria-hidden="true" />
							</Link>
						</div>
					</div>
					<div className="col-md-4 h-content" styleName="blocks-wrapper" style={{ opacity: `${obras_configuradas ? '1' : '0.4'}` }}>
						<div styleName="number">4</div>
						{fornecedores_configurados ?
							(<h4 className="h3-header" style={{ color: color_on }}> <strong>Associe seus fornecedores <i className="fa fa-check" aria-hidden="true" /> </strong> </h4>)
							:
							(<h4 className="h3-header">Associe seus fornecedores</h4>)
						}
						<p styleName="sub-title-content">Associe seus fornecedores para facilitar<br /> seus pedidos no futuro.</p>
						<div>
							<Link
								to={obras_configuradas ? '/integracao/sienge/fornecedores' : '/integracao/sienge'}
								disabled={!obras_configuradas}
								className="btn btn-clear-conaz round-border"
								styleName="add-files-button">
								<span>Associar fornecedores</span>
								<i className="fa fa-long-arrow-right" aria-hidden="true" />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

SiengeInicio.propTypes = {
	// =========== state
	loading: PropTypes.number.isRequired,
	// =========== store
	user: PropTypes.object.isRequired,
	// =========== local funcs
	// =========== prop funcs
};
export default CSSModules(SiengeInicio, styles, { allowMultiple: true });
