/* eslint-disable react/react-in-jsx-scope */
import { all, takeLatest, put, delay, call } from 'redux-saga/effects';
import { message } from 'antd';
import { criaEmpresa, deletaEmpresa, editaEmpresa } from 'services/obras';

import { _get } from '../../components/_functions/_requests';
import * as Types from './types';
import * as Actions from './actions';

function* getEmpresas() {
	try {
		yield delay(1000);
		const res = yield call(_get, '/conaz/v2/pessoas/');
		yield put(Actions.getEmpresasSuccess(res.data));
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível carregar as empresas');
		}
		yield put(Actions.getEmpresasError(error));
	}
}

function* newEmpresa({ payload: { data } }) {
	try {
		const formData = new FormData();
		const { endereco, ficha_cadastral, ...rest } = data;
		Object.keys(rest).forEach((k) => {
			formData.append(k, rest[k]);
		});
		formData.append('endereco', JSON.stringify(endereco));

		const result = yield call(criaEmpresa, formData);
		if (result.status !== 201) {
			throw new Error('Não foi possível criar a empresa.');
		}
		yield put(Actions.createEmpresaSuccess(result.data));
		message.success('Empresa criada com sucesso');
	} catch (error) {
		if (error.response.data) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível cadastrar uma nova empresa');
		}
		yield put(Actions.actionErrorEmpresa(error));
	}
}

function* editEmpresa({ payload: { data } }) {
	try {
		const { id, ...empresaData } = data;
		const res = yield call(editaEmpresa, id, empresaData);
		if (res.status !== 200) {
			throw new Error('Não foi possível editar a empresa');
		}
		yield put(Actions.editEmpresaSuccess(res.data));
		message.success('Empresa Atualizada com sucesso');
		yield put(Actions.closeEmpresaDrawer());
	} catch (error) {
		if (error.response.data && error.response.data.message) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível atualizar a empresa');
		}
		yield put(Actions.actionErrorEmpresa(error));
	}
}

function* blockEmpresa({ payload: { data } }) {
	try {
		yield delay(1000);
		yield put(Actions.blockEmpresaSuccess(data));
		message.success('Empresa bloqueada com sucesso');
	} catch (error) {
		if (error.response.data && error.response.data.message) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível bloquear a empresa');
		}
		yield put(Actions.actionErrorEmpresa(error));
	}
}

function* deleteEmpresa({ payload: { data } }) {
	try {
		const { id } = data;
		const res = yield call(deletaEmpresa, id, data);
		if (res.status === 400 || res.status === 404) {
			throw new Error('Não foi possível excluir a empresa');
		}
		yield put(Actions.deleteEmpresaSuccess(data));
		message.success('Empresa deletada com sucesso');
	} catch (error) {
		if (error.response.data && error.response.data.message) {
			message.error(error.response.data.message);
		} else {
			message.error('Não foi possível deletar a empresa');
		}
		yield put(Actions.actionErrorEmpresa(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.EMPRESAS_GET_LIST_REQUEST, getEmpresas),
		takeLatest(Types.EMPRESAS_CREATE_REQUEST, newEmpresa),
		takeLatest(Types.EMPRESAS_EDIT_REQUEST, editEmpresa),
		takeLatest(Types.EMPRESAS_BLOCK_REQUEST, blockEmpresa),
		takeLatest(Types.EMPRESAS_DELETE_REQUEST, deleteEmpresa),
	]);
}
