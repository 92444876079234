import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ExportSteps from './ExportSteps';
import ExportErrorViews from './ExportErrorViews';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';
import { sendSlack } from '../../../../components/_functions/_sendSlack';

const ConcluiExportacao = ({
	sienge_data,
	compra,
	setExportingError,
	redirectAfterConcluir,
	notificaUsuarioAposExportacao,
}) => {
	const [request, setRequest] = useState(0);

	useEffect(() => {
		// TEMP
		function enviaAlertSlack() {
			const is_prod = (window.location.href.indexOf('portal.conazweb.com.br') !== -1);
			if (is_prod) {
				const compra_id = compra.id || 0;
				const compra_email = compra.usuario_construtor.email || '';
				const message = `Exportação da compra *${compra_id} [${compra_email}]* foi finalizada com sucesso! :gustavo_thug_life:`;
				sendSlack('#exportações-sienge', 'Exportação Sienge', message, ':gustavo_thug_life:');
			}
		}

		enviaAlertSlack();

		const status_compra = defineStatusCompra(compra);
		const compra_ja_enviada = (
			status_compra !== 'rascunho'
			&& status_compra !== 'em_edicao'
		);

		if (compra_ja_enviada) {
			setRequest(2);
			return;
		}

		_post(`/conaz/v2/compras/${compra.id}/concluir`)
			.then(() => {
				setRequest(2);
				setExportingError(true);
			})
			.catch(err => {
				/**
				 * Notifica decisor
				 */
				if (err.response && err.response.status === 401) {
					notificaUsuarioAposExportacao();
					return;
				}

				setRequest(3);
				handleRequestErrors(err);
			});
	}, []);

	if (request === 2) {
		const cotacao_id = sienge_data.cotacao_id || 0;
		const negociacao_id = sienge_data.negociacao_id || '';

		return (
			<>
				<div styleName="titles">
					<div styleName="icon">
						<i className="fa fa-check-circle fa-fw" styleName="success-icon" aria-hidden="true" />
					</div>
					<h3 style={{ color: '#56a049' }}>Compra exportada com sucesso!</h3>
				</div>
				<div styleName="content-success">
					<p style={{ fontSize: '16px' }}>Compra Conaz exportada para o Sienge com sucesso!</p>
					<p style={{ fontSize: '16px' }}>ID da cotação criada: <b>{cotacao_id}</b></p>
					<hr />
					<b>Avisos importantes:</b><br /><br />
					<p styleName="warning">
						<i className="fa fa-warning" aria-hidden="true" /> O Sienge não permite que a condição de pagamento seja exportada.
						Você poderá adicionar essa informação diretamente na negociação {negociacao_id}.
					</p>
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						style={{ marginTop: '18px' }}
						onClick={() => redirectAfterConcluir()}
					>
						Fechar
					</button>
				</div>
			</>
		);
	}

	if (request === 3) {
		return <ExportErrorViews error_type={12} />;
	}

	return (
		<ExportSteps current={5} />
	);
};

ConcluiExportacao.propTypes = {
	sienge_data: PropTypes.object.isRequired,
	compra: PropTypes.object.isRequired,
	setExportingError: PropTypes.func.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
};

export default CSSModules(ConcluiExportacao, styles, { allowMultiple: true });
