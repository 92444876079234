import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CV3CondicaoPagamento from './CV3CondicaoPagamento';

// Functions
import { _put } from '../../../../components/_functions/_requests';

class CV3CondicaoAberta extends Component {
	constructor(props) {
		super(props);
		this.state = {
			condicao_pagamento_aberta: {},
			timer: 0,
		};
		this.handleCondicao = this.handleCondicao.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { nova_compra } = this.props;
		const outra_condicao_de_pagamento =
			nova_compra.outra_condicao_de_pagamento || {};
		const {
			id,
			forma,
			prazo,
			outra_forma,
			outro_prazo,
			aceita_parcial,
			compra_minima,
			criado_em,
		} = outra_condicao_de_pagamento;
		this.setState({
			condicao_pagamento_aberta: {
				id,
				aceita_parcial,
				compra_minima,
				criado_em,
				forma,
				prazo,
				outra_forma,
				outro_prazo,
			},
		});
	}

	handleCondicao(condicao_pagamento_aberta) {
		const { timer } = this.state;
		const { nova_compra, updateComprasRaw, autoSaveStart } = this.props;
		const condicao_de_pagamento = {
			...nova_compra.condicao_de_pagamento,
			...condicao_pagamento_aberta,
		};
		updateComprasRaw({
			nova_compra: { ...nova_compra, condicao_de_pagamento },
		});
		if (timer) clearTimeout(this.state.timer);
		this.setState({
			condicao_pagamento_aberta,
			timer: setTimeout(() => {
				_put(`/conaz/v2/condicoes_de_pagamento/${condicao_de_pagamento.id}`, {
					...condicao_de_pagamento,
				}).then(() => autoSaveStart());
			}, 2000),
		});
	}

	render() {
		const { condicao_pagamento_aberta } = this.state;
		const { only_input, errors, handleFixError } = this.props;
		if (only_input) {
			return (
				<>
					<label styleName={errors.forma_de_pagamento ? 'error' : ''}>
						Forma de pagamento<span styleName="obrigatorio">*</span>
					</label>
					<CV3CondicaoPagamento
						type="forma"
						error={errors.forma_de_pagamento}
						handleFixError={handleFixError}
						handleUpdate={this.handleCondicao}
						condicao_pagamento={condicao_pagamento_aberta} />
					<label styleName={errors.forma_de_pagamento ? 'error' : ''}>
						Prazo <span styleName="obrigatorio">*</span>
					</label>
					<CV3CondicaoPagamento
						type="prazo"
						error={errors.prazo_de_pagamento}
						handleFixError={handleFixError}
						handleUpdate={this.handleCondicao}
						condicao_pagamento={condicao_pagamento_aberta} />
				</>
			);
		}

		return (
			<div styleName="form-row">
				<i className="fa fa-credit-card" aria-hidden="true" />
				<label>Condição de pagamento</label>
				<label
					style={{
						fontSize: '14px',
						fontWeight: '700',
						marginTop: '16px',
					}}>
					Forma de pagamento
				</label>
				<CV3CondicaoPagamento
					type="forma"
					handleUpdate={this.handleCondicao}
					condicao_pagamento={condicao_pagamento_aberta}
					error={errors.forma_de_pagamento}
					handleFixError={handleFixError} />
				<label
					style={{
						fontSize: '14px',
						fontWeight: '700',
						marginTop: '6px',
					}}>
					Prazo
				</label>
				<CV3CondicaoPagamento
					type="prazo"
					handleUpdate={this.handleCondicao}
					condicao_pagamento={condicao_pagamento_aberta}
					error={errors.prazo_de_pagamento}
					handleFixError={handleFixError} />
			</div>
		);
	}
}

CV3CondicaoAberta.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
	only_input: PropTypes.bool,
};

CV3CondicaoAberta.defaultProps = {
	only_input: false,
};

export default CSSModules(CV3CondicaoAberta, styles, { allowMultiple: true });
