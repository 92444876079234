import { _post, _put, _get, _delete } from '../components/_functions/_requests';

export const getObraList = () => _get('/conaz/v2/obras/');

export const getObra = (obra_id) => _get(`/conaz/v2/obras/${obra_id}`);

export const criaObra = (data) => _post('/conaz/v2/obras/', data);

export const editaObra = (obraId, data) => _put(`/conaz/v2/obras/${obraId}`, data);

export const statusVisualizacaoObra = (id, data) => _post(`/conaz/v2/obras/${id}/ativar_visualizacao`, data);

export const statusAtivoObra = (id, data) => _post(`/conaz/v2/obras/${id}/desativar_visualizacao`, data);

export const associaUsario = (obraId, usuarioId, data) => _post(`/conaz/v2/usuarios_construtor/${usuarioId}/obras/${obraId}`, data);

export const removeUsuarioAssociado = (obraId, usuarioId) => _delete(`/conaz/v2/usuarios_construtor/${usuarioId}/obras/${obraId}`);

export const associaEmpresa = (obraId, pessoaId, data) => _post(`/conaz/v2/pessoas/${pessoaId}/obras/${obraId}`, data);

export const removeEmpresaAssociada = (obraId, pessoaId) => _delete(`/conaz/v2/pessoas/${pessoaId}/obras/${obraId}`);

// Empresa
export const criaEmpresa = (data) => _post('/conaz/v2/pessoas', data);

export const editaEmpresa = (id, data) => _put(`/conaz/v2/pessoas/${id}`, data);

export const deletaEmpresa = (id) => _delete(`/conaz/v2/pessoas/${id}`);

// Usuários
export const criaUsuario = (data) => _post('/conaz/v2/usuarios_construtor', data);

export const editaUsuario = (id, data) => _put(`/conaz/v2/usuarios_construtor/${id}`, data);

export const desativaUsuario = (id, data) => _post(`/conaz/v2/usuarios_construtor/${id}/desativar_usuario_construtor`, data);

export const ativaUsuario = (id, data) => _post(`/conaz/v2/usuarios_construtor/${id}/reativar_usuario_construtor`, data);
