import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedorRCComb from './FornecedorRCComb';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { _delete } from '../../../../components/_functions/_requests';

class FornecedorComb extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itens_faltando: false,
			edition_menu: false,
			delete_confirm: false,
			deleting_oc: 0,
			oc_is_selected: false,
		};
		this.selectOCLocal = this.selectOCLocal.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.deleteOC = this.deleteOC.bind(this);
	}

	componentDidMount() {
		this.checkItensFaltando();
	}

	checkItensFaltando() {
		const { rcs_analise } = this.props.opcao_de_compra;
		/**
		 * Loop por todos os RC's analise
		 */
		const itens_ativos = {};
		const all_itens = {};
		for (let r = 0; r < rcs_analise.length; r++) {
			const rc_a = rcs_analise[r];

			for (let i = 0; i < rc_a.itens_rc_analise.length; i++) {
				const item = rc_a.itens_rc_analise[i];
				all_itens[item.item_analise_id] = {
					order: i + 1,
					item_analise_id: item.item_analise_id,
				};
				if (item.ativo) {
					itens_ativos[item.item_analise_id] = item.id;
				}
			}
		}
		/**
		 * Identifica se algum item não está com nenhum selecionado
		 */
		const itens_faltando = Object.keys(all_itens)
			.filter(item_analise_id => itens_ativos[item_analise_id] === undefined)
			.map(item_analise_id => all_itens[item_analise_id]);
		if (itens_faltando.length > 0) {
			this.setState({ itens_faltando: true });
		}
	}

	selectOCLocal() {
		// const { opcao_de_compra, selectOC } = this.props;
		// selectOC(opcao_de_compra.id, true);
		const oc_is_selected = !this.state.oc_is_selected;
		this.setState({ edition_menu: false, oc_is_selected });
	}

	deleteOC() {
		const { opcao_de_compra, deleteOCFromProps } = this.props;
		this.setState({ deleting_oc: 1 });
		_delete(`/conaz/v2/opcao_compra/${opcao_de_compra.id}`).then(() => {
			deleteOCFromProps(opcao_de_compra.id);
		}).catch(error => {
			console.log(error); // eslint-disable-line
			this.setState({ deleting_oc: 3 });
		});
	}

	confirmDelete() {
		const delete_confirm = !this.state.delete_confirm;
		this.setState({ delete_confirm, edition_menu: false });
	}

	render() {
		const {
			itens_faltando,
			edition_menu,
			delete_confirm,
			deleting_oc,
			oc_is_selected,
		} = this.state;
		const {
			order,
			opcao_de_compra,
			rcs_abertos,
			openCloseRCs,
			edition_mode,
			infoFornecedor,
			updateModals,
			cotacao_codigo,
			collapseOC,
			combinacao_criada_id,
			goToCriarCompra,
			salvaCombinacao,
			salvando_combinacao,
			loading_compras,
			compras,
			changeCondicaoPagamento,
			pedido,
		} = this.props;
		const { rcs_analise } = opcao_de_compra;

		const h3_class = `c${rcs_analise.length}`;
		/**
		 * Define a class da coluna combinacao
		 */
		// const combinacao_class = !oc_is_selected ? 'combinacao' : 'combinacao oc-selected';
		const combinacao_class = 'combinacao';
		const total_combinacao_class = !edition_menu ? 'total-combinacao' : 'total-combinacao buttons-over';

		/**
		* Verifica se existe compra na combinação
		*/
		const comb_has_compra = rcs_analise
			.filter(rcInfo => {
				const all_compras = compras.filter(c => c.rc_analise_id === rcInfo.id);
				return all_compras.length > 0;
			})
			.length > 0;
		/**
		* Set id elemento do onboarding
		*/
		const total_comb_id = !edition_mode
			? `total_comb_${order}_onboarding`
			: '';

		/**
		 * Opção de compra colapsada
		 */
		if (opcao_de_compra.collapsed) {
			return (
				<div styleName="fornecedor oc-comb-collapsed" id="fornecedor-comb">
					<button
						type="button"
						styleName="collapse-button closed"
						onClick={() => collapseOC(opcao_de_compra.id)} />
					<div styleName="number number-comb-collapsed">C{order}</div>
					<button
						type="button"
						styleName="uncollapse-button"
						onClick={() => collapseOC(opcao_de_compra.id)}>
						COMBINAÇÃO {order}
					</button>
				</div>
			);
		}

		return (
			<div styleName={combinacao_class}>
				{!edition_mode && (
					<button
						type="button"
						styleName="collapse-button"
						onClick={() => collapseOC(opcao_de_compra.id)}>
						<i className="fa fa-caret-square-o-left" aria-hidden="true" />
					</button>
				)}
				{combinacao_criada_id === opcao_de_compra.id && (
					<div styleName="alert-comb-criada">
						<i className="fa fa-check fa-fw" aria-hidden="true" /> Combinação criada com sucesso!
					</div>
				)}
				{(!oc_is_selected && !delete_confirm && !comb_has_compra) && (
					<div id={total_comb_id} styleName={total_combinacao_class}>
						{(itens_faltando && !edition_mode) ? (
							<h3 styleName={h3_class} style={{ paddingLeft: '286px', color: '#ea537c' }}>Itens faltando</h3>
						) : (
							<h3 styleName={h3_class}><span>Total: </span>{formatCurrency(opcao_de_compra.preco_total, 2, 'R$')}</h3>
						)}
					</div>
				)}
				{!!comb_has_compra && (
					<div styleName={total_combinacao_class}>
						<h3 styleName={`${h3_class} smaller`}><span>Total: </span>{formatCurrency(opcao_de_compra.preco_total, 2, 'R$')}</h3>
					</div>
				)}
				{delete_confirm && (
					<div styleName="total-combinacao">
						{!deleting_oc ? (
							<div styleName="confirm-delete">
								Deseja apagar esta combinação?
								<button
									className="btn btn-clear-conaz round-border"
									style={{ margin: '0 7px 0 12px' }}
									onClick={this.confirmDelete}>
									Não
								</button>
								<button
									className="btn btn-danger-conaz round-border"
									onClick={this.deleteOC}>
									Apagar
								</button>
							</div>
						) : (
							<div styleName="confirm-delete">
								Deseja apagar esta combinação?
								<button
									className="btn btn-danger-conaz round-border loading"
									style={{ margin: '0 7px 0 12px' }}
									onClick={this.deleteOC}>
									<LoadingButton />Apagar
								</button>
							</div>
						)}
					</div>
				)}
				{rcs_analise.map((rc_a, r) => (
					<FornecedorRCComb
						key={rc_a.id}
						rc_a={rc_a}
						r={r}
						order={order}
						opcao_de_compra={opcao_de_compra}
						rcs_abertos={rcs_abertos}
						openCloseRCs={openCloseRCs}
						edition_mode={edition_mode}
						infoFornecedor={infoFornecedor}
						updateModals={updateModals}
						cotacao_codigo={cotacao_codigo}
						goToCriarCompra={goToCriarCompra}
						salvaCombinacao={salvaCombinacao}
						salvando_combinacao={salvando_combinacao}
						loading_compras={loading_compras}
						compras={compras}
						changeCondicaoPagamento={changeCondicaoPagamento}
						selectOCLocal={this.selectOCLocal}
						confirmDelete={this.confirmDelete}
						itens_faltando={itens_faltando}
						delete_confirm={delete_confirm}
						oc_is_selected={oc_is_selected}
						comb_has_compra={comb_has_compra}
						pedido={pedido} />
				))}
			</div>
		);
	}
}

FornecedorComb.propTypes = {
	order: PropTypes.number.isRequired,
	opcao_de_compra: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	deleteOCFromProps: PropTypes.func.isRequired,
	edition_mode: PropTypes.bool.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	cotacao_codigo: PropTypes.string.isRequired,
	collapseOC: PropTypes.func.isRequired,
	combinacao_criada_id: PropTypes.number.isRequired,
	goToCriarCompra: PropTypes.func.isRequired,
	salvaCombinacao: PropTypes.func.isRequired,
	salvando_combinacao: PropTypes.number.isRequired,
	loading_compras: PropTypes.number.isRequired,
	compras: PropTypes.array.isRequired,
	changeCondicaoPagamento: PropTypes.func.isRequired,
	pedido: PropTypes.object.isRequired,
};

export default CSSModules(FornecedorComb, styles, { allowMultiple: true });
