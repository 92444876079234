import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// styles
import './styles/bootstrap/bootstrap.scss';
import './styles/app.scss';

import './ReactotronConfig';
import Auxlr from './hocs/Auxlr';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

const app = (
	<Auxlr>
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	</Auxlr>
);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.unregister();
