import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { validations } from 'ambar-ui';
import { Row, Col, Form, Input } from 'antd';
import InputMask from 'react-input-mask';

// Redux HOC
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

const { validateRequired } = validations;

const modes = {
	NEW: 'new',
	EDIT: 'edit',
	VIEW: 'view',
};

function EnderecoForm({ mode, form, disableAll, enableFields, ...props }) {
	const { getAdressByCep, enderecoForm } = props;
	const [disableFields, setDisabledAdressFields] = useState(true);
	const [cepError, setCepError] = useState('');

	const handleCep = (e) => {
		const { value } = e.target;
		const cep = value.replace(/[-_]/g, '');
		if (cep.length === 8) {
			setCepError('');
			getAdressByCep(cep);
		} else {
			setDisabledAdressFields(true);
		}
	};

	const handleCEPInputStatus = () => {
		if (enderecoForm.adressError || cepError) return 'error';
		if (enderecoForm.adressFetching) return 'validating';
		return '';
	};

	const cepInputErrorMessage = () => {
		if (enderecoForm.adressError) return 'CEP não encontrado';
		return cepError;
	};

	useEffect(() => {
		if (enderecoForm.adress) {
			form.setFieldsValue({
				...enderecoForm.adress,
				estado: enderecoForm.adress.uf,
				cidade: enderecoForm.adress.localidade,
			});
			setDisabledAdressFields(false);
		}
	}, [enderecoForm.adress]);

	useEffect(() => {
		// Para poder liberar campos caso já exista cep.
		setDisabledAdressFields(!enableFields);
	}, [enableFields]);

	useEffect(() => {
		if (mode === modes.NEW) {
			form.resetFields();
		}
	}, []);

	return (
		<>
			<Row gutter={24}>
				<Col span={6}>
					<Form.Item noStyle name="ibge"> <Input hidden /> </Form.Item>
					<Form.Item
						name="cep"
						label="CEP"
						rules={[validateRequired(), {
							validator: (_, value) => {
								const stringfied = `${value}`;
								if (!stringfied) return;
								if (stringfied.replace(/[-_]/g, '').length < 8 || enderecoForm.adressError) {
									setCepError('Cep inválido');
								}
								return Promise.resolve();
							},
						}]}
						hasFeedback
						validateStatus={handleCEPInputStatus()}
						help={cepInputErrorMessage()}
					>
						<InputMask mask="99999-999" placeholder="00000-000" onChange={handleCep} disabled={disableAll || enderecoForm.adressFetching}>
							{(p) => <Input disabled={disableAll || enderecoForm.adressFetching} {...p} />}
						</InputMask>
					</Form.Item>
				</Col>
				<Col span={6}>
					<Form.Item name="cidade" label="Cidade" rules={[validateRequired()]}>
						<Input disabled />
					</Form.Item>
				</Col>
				<Col span={2}>
					<Form.Item name="estado" label="UF" rules={[validateRequired()]}>
						<Input disabled />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={24} style={{ marginTop: '-2rem' }}>
				<Col span={12}>
					<Form.Item name="logradouro" label="Logradouro" rules={[validateRequired()]}>
						<Input disabled={disableAll || disableFields} />
					</Form.Item>
				</Col>
				<Col span={2}>
					<Form.Item name="numero" label="Nº" rules={[validateRequired()]}>
						<Input disabled={disableAll || disableFields} type="number" min={0} max={9999} style={{ padding: '5px 3px' }} />
					</Form.Item>
				</Col>
				<Col span={10}>
					<Form.Item name="bairro" label="Bairro" rules={[validateRequired()]}>
						<Input disabled={disableAll || disableFields} />
					</Form.Item>
				</Col>
			</Row>

			<Form.Item name="complemento" label="Complemento">
				<Input disabled={disableAll || disableFields} />
			</Form.Item>
		</>
	);
}

EnderecoForm.propTypes = {
	mode: PropTypes.string,
	getAdressByCep: PropTypes.func,
	form: PropTypes.shape({
		setFieldsValue: PropTypes.func,
		getFieldsValue: PropTypes.func,
		getFieldValue: PropTypes.func,
		resetFields: PropTypes.func,
	}),
	enableFields: PropTypes.bool,
	disableAll: PropTypes.bool,
	enderecoForm: PropTypes.shape({
		adressFetching: PropTypes.bool,
		adressError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
		adress: PropTypes.shape({
			cep: PropTypes.string,
			logradouro: PropTypes.string,
			complemento: PropTypes.string,
			bairro: PropTypes.string,
			localidade: PropTypes.string,
			uf: PropTypes.string,
			ibge: PropTypes.string,
			gia: PropTypes.string,
			ddd: PropTypes.string,
			siafi: PropTypes.string,
		}),
	}),
};

EnderecoForm.defaultProps = {
	mode: null,
	getAdressByCep: () => {},
	enableFields: false,
	disableAll: false,
	enderecoForm: {},
	form: { setFieldsValue: () => {} },
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(EnderecoForm));
