/* eslint-disable no-unused-vars */
import { takeLatest, takeEvery, put, all } from 'redux-saga/effects';
import {
	COMPRAS_REQUESTED,
	COMPRAS_RECEIVED,
	COMPRAS_INITIAL_REQUESTED,
	COMPRAS_INITIAL_RECEIVED,
	COMPRAS_REQUEST_FAILED,
	COMPRA_REQUESTED,
	COMPRA_RECEIVED,
	COMPRA_REQUEST_FAILED,
} from './types';

// Services
import { get } from '../../services/get';

export function* fetchInitialCompras({ obra_id, tab }) {
	const compras_tabs = ['ativas', 'aguardando_entrega_avaliacao', 'finalizadas', 'canceladas', 'em_rascunho'].filter(t => t !== tab);
	try {
		const { data } = yield get(`/conaz/v2/compras/${tab}?obra_id=${obra_id}`);

		const promises = compras_tabs.map(t => get(`/conaz/v2/compras/${t}?obra_id=${obra_id}&per_page=0`));

		let res;

		yield Promise.all(promises).then(results => {
			res = {
				[tab]: data,
				[compras_tabs[0]]: results[0].data,
				[compras_tabs[1]]: results[1].data,
				[compras_tabs[2]]: results[2].data,
				[compras_tabs[3]]: results[3].data,
			};
		});

		const payload = { data: res, tab };

		yield put({ type: COMPRAS_INITIAL_RECEIVED, payload });
	} catch (error) {
		yield put({ type: COMPRAS_REQUEST_FAILED, error });
	}
}

export function* fetchCompras({ obra_id, tab, page }) {
	try {
		const { data } = yield get(`/conaz/v2/compras/${tab}?obra_id=${obra_id}&page=${page}`);
		const payload = { data, tab };
		yield put({ type: COMPRAS_RECEIVED, payload });
	} catch (error) {
		yield put({ type: COMPRAS_REQUEST_FAILED, error });
	}
}

export function* fetchCompra({ url }) {
	try {
		const payload = yield get(url);
		yield put({ type: COMPRA_RECEIVED, payload });
	} catch (error) {
		yield put({ type: COMPRA_REQUEST_FAILED, error });
	}
}

// saga
export default function* comprasSaga() {
	yield takeEvery([COMPRAS_INITIAL_REQUESTED], fetchInitialCompras);
	yield takeEvery([COMPRAS_REQUESTED], fetchCompras);
	yield takeLatest([COMPRA_REQUESTED], fetchCompra);
}
