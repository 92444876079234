import React from 'react';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import UploadFiles from '../../../../components/_parts/UploadFilesV2/UploadFiles';
import { withToggle } from '../../../../components/_hocs/hocs';
import { detailedmapDispatchToProps } from '../../../../store/reduxDispatchs';

// CSS
import styles from './style.module.scss';

// Redux HOC
import withStore from '../../../../store/withStore';

const Anexos = ({
	compra,
	updateCompras,
	updateUi,
	isVisible,
	toggle,
}) => {
	const {
		anexos, id,
	} = compra;
	return (
		<UploadFiles
			{...{
				anexos,
				updateCompras,
				updateUi,
				compra,
			}}
			modal={isVisible}
			handleModal={toggle}
			config={{
				post_url: `/conaz/v2/compras/${id}/anexos`,
				put_url: '/conaz/v2/anexos_compra/$anexo_id',
				delete_url: '/conaz/v2/anexos_compra/$anexo_id',
			}} />
	);
};

Anexos.propTypes = {
	compra: PropTypes.shape({
		anexos: PropTypes.array.isRequired,
		id: PropTypes.number.isRequired,
	}),
	updateCompras: PropTypes.func.isRequired,
	updateUi: PropTypes.func.isRequired,
	isVisible: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

Anexos.defaultProps = {
	compra: {},
};

const mapDispatchToProps = (dispatch) => detailedmapDispatchToProps(dispatch, ['updateCompras', 'updateUi']);

export default compose(
	withStore,
	connect(null, mapDispatchToProps),
	withToggle,
)(CSSModules(Anexos, styles, { allowMultiple: true }));
