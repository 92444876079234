export function getDaysBetweenDates(start, end, dayName) {
	const result = [];
	const days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
	const day = days[dayName.toLowerCase().substr(0, 3)];
	const current = new Date(start);
	// eslint-disable-next-line no-mixed-operators
	current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
	while (current < end) {
		result.push(new Date(+current));
		current.setDate(current.getDate() + 7);
	}
	return result;
}
