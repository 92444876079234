import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputTextArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.default_value,
		};
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { default_value } = nextProps;
		if (default_value !== this.state.value) this.setState({ value: default_value });
	}

	handleEdition(e) {
		const { item_key, field, updateFunction, autoSave } = this.props;
		const { value } = e.target;
		this.setState({ value });
		updateFunction(item_key, field, value);
		autoSave(item_key);
	}

	render() {
		const { value } = this.state;
		const { field_id, maxLength } = this.props;

		return (
			<textarea
				id={field_id}
				className="form-control"
				rows="4"
				value={value}
				maxLength={maxLength}
				onChange={this.handleEdition} />
		);
	}
}

InputTextArea.propTypes = {
	field_id: PropTypes.string.isRequired,
	item_key: PropTypes.number.isRequired,
	field: PropTypes.string.isRequired,
	default_value: PropTypes.string,
	updateFunction: PropTypes.func,
	autoSave: PropTypes.func,
	maxLength: PropTypes.number,
};

InputTextArea.defaultProps = {
	default_value: '',
	updateFunction: () => {},
	autoSave: () => {},
	maxLength: null,
};

export default InputTextArea;
