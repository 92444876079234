import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalItensFaltandoQC = ({ updateModals, itens_faltando }) => {
	const itens = itens_faltando.map(item => item.order);
	const last_item = itens.pop();
	const _view = itens.length > 1
		? <p>Os itens <b>{`${itens.join(', ')} e ${last_item}`}</b> não foram selecionados.</p>
		: <p>O item <b>{last_item}</b> não foi selecionado.</p>;

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => updateModals('itens_comb_qc', false)}>
					<span aria-hidden="true">&times;</span>
				</button>
				<div styleName="icon">
					<i className="fa fa-times-circle" aria-hidden="true" />
				</div>
				<div styleName="content">
					<h3>Itens faltando</h3>
					{_view}
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						onClick={() => updateModals('itens_comb_qc', false)}>
						Entendi!
					</button>
				</div>
			</div>
		</div>
	);
};

ModalItensFaltandoQC.propTypes = {
	updateModals: PropTypes.func,
	itens_faltando: PropTypes.array.isRequired,
};

ModalItensFaltandoQC.defaultProps = {
	updateModals: () => {},
};

export default CSSModules(ModalItensFaltandoQC, styles, { allowMultiple: true });
