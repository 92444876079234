/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import withAddItemFuncs from '../_hocs/withAddItemFuncs';

// Functions
import { setClasses } from '../../../_functions/_setClasses';

class Popular extends Component {
	addItem = () => {
		const { item: _item, getItemToAdd, adding_item } = this.props;
		if (adding_item) return;
		const { item, caracteristicas_preenchidas } = _item;
		getItemToAdd(item, caracteristicas_preenchidas);
	}

	render() {
		const { item, adding_item, error_adding } = this.props;
		const caracteristicas_view = item.caracteristicas_preenchidas
			.filter(c => (
				c.opcoes_preenchidas.length > 0
				&& c.opcoes_preenchidas[0].valor !== ''
			));

		const item_classes = {
			adding: adding_item,
			error: error_adding,
		};
		const item_class = setClasses('item-result', item_classes);

		return (
			<>
				<button
					type="button"
					styleName={item_class}
					onClick={this.addItem}>
					<i className="fa fa-angle-right" aria-hidden="true" />
					<h4 className="list-group-item-heading" styleName="results-list-h4">
						{item.item.nome}
					</h4>
					{caracteristicas_view.map((carac, c) => {
						const { nome } = carac.caracteristica;
						const { valor, unidade } = carac.opcoes_preenchidas[0] || {};

						if (caracteristicas_view.length === c + 1) {
							return (
								<span key={c}>{nome}: <b>{valor} {unidade}</b></span>
							);
						}

						return (
							<span key={c}>{nome}: <b>{valor} {unidade}</b> <span>/</span> </span>
						);
					})}
					{error_adding && (
						<div styleName="error-alert">Erro ao adicionar item. Tente novamente.</div>
					)}
				</button>
			</>
		);
	}
}

Popular.propTypes = {
	item: PropTypes.object.isRequired,
	getItemToAdd: PropTypes.func.isRequired,
	adding_item: PropTypes.bool.isRequired,
	error_adding: PropTypes.bool.isRequired,
	/* Props que vao para o HOC withAddItemFuncs */
	/* eslint-disable react/no-unused-prop-types */
	type: PropTypes.string.isRequired,
	openItemEdition: PropTypes.func.isRequired,
};

const _Popular = CSSModules(Popular, styles, { allowMultiple: true });
export default withAddItemFuncs(_Popular);
