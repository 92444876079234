import React from 'react';
import PropTypes from 'prop-types';

import { Divider } from 'antd';

import EnderecoForm from 'components/EnderecoForm';
import UpperPart from './UpperPart';

function FormPessoaJuridica({ mode, form, disableAll, ...props }) {
	return (
		<>
			<UpperPart disableAll={disableAll} form={form} {...props} />
			<Divider />
			<EnderecoForm mode={mode} form={form} disableAll={disableAll} {...props} />
		</>
	);
}

FormPessoaJuridica.propTypes = {
	mode: PropTypes.string,
	props: PropTypes.shape({ form: PropTypes.shape({ }) }),
	disableAll: PropTypes.bool,
	form: PropTypes.shape({ }).isRequired,
};

FormPessoaJuridica.defaultProps = {
	mode: null,
	props: {},
	disableAll: false,
};

export default FormPessoaJuridica;
