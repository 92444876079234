import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hocs
import asyncComponent from '../../../hoc/asyncComponent/asyncComponent';

// Components
import InputText from '../_inputs/InputText/InputText';
import InputNumber from '../_inputs/InputNumber/InputNumber';
import InputSelect from '../_inputs/InputSelect/InputSelect';
import InputMultiple from '../_inputs/InputMultiple/InputMultiple';
import InputUnidade from '../_inputs/InputUnidade/InputUnidade';

// Functions
import { caracteristicaType } from '../../_functions/_caracteristicaType';
import { buildOpcaoPreenchida } from '../../_functions/_buildOpcaoPreenchida';
import { setDecimalsToString } from '../../_functions/_numbers';

const UpdateCaracteristica =
	process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
		? asyncComponent(() => import('../../_parts_interno/UpdateCaracteristica/UpdateCaracteristica'))
		: () => null;

class Caracteristica extends Component {
	constructor() {
		super();
		this.state = {
			outro: false,
			outra_unidade: false,
		};
		this.setOutroUpdateCarac = this.setOutroUpdateCarac.bind(this);
		this.handleOutro = this.handleOutro.bind(this);
		this.handleOutraUnidade = this.handleOutraUnidade.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkOutro(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.item_key !== nextProps.item_key) {
			this.setState({ outro: false, outra_unidade: false });
			this.checkOutro(nextProps);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (!shallowequal(this.props, nextProps)) return true;
		if (!shallowequal(this.state, nextState)) return true;
		return false;
	}

	setOutroUpdateCarac() {
		this.setState({ outro: false });
	}

	checkOutro(props) {
		const { opcoes_preenchidas } = props.caracteristica_preenchida;
		const {
			opcoes,
			selecao,
			tipo_de_valor,
		} = props.caracteristica_preenchida.caracteristica;
		const caracteristica_type = caracteristicaType(
			opcoes,
			selecao,
			tipo_de_valor,
		);
		const validada =
			opcoes_preenchidas.length > 0
				? opcoes_preenchidas[0].validada
				: null;
		const valor =
			opcoes_preenchidas.length > 0 ? opcoes_preenchidas[0].valor : '';
		// const unidade = opcoes_preenchidas.length > 0 ? opcoes_preenchidas[0].unidade : '';
		// const default_value = `${valor} ${unidade}`;
		if (caracteristica_type === 'selecao' && !validada && valor !== '') this.setState({ outro: true });
		const unidade =
			opcoes_preenchidas.length > 0 ? opcoes_preenchidas[0].unidade : '';
		if (
			unidade !== '' &&
			opcoes.filter(x => x.unidade === unidade).length === 0
		) this.setState({ outra_unidade: true });
		// if (unidade !== '' && opcoes.find(x => x.unidade === unidade) === undefined) this.setState({ outra_unidade: true });
	}

	handleOutro() {
		const outro = !this.state.outro;
		const {
			item_key,
			caracteristica_id,
			updateItemCaracteristica,
		} = this.props;
		const opcao_zerada = buildOpcaoPreenchida(
			caracteristica_id,
			'',
			'',
			null,
		);
		updateItemCaracteristica(item_key, caracteristica_id, [opcao_zerada]);
		if (outro) {
			setTimeout(
				() => document
					.getElementById(
						`resposta-${item_key}-${caracteristica_id}`,
					)
					.focus(),
				60,
			);
		}
		this.setState({ outro });
	}

	handleOutraUnidade() {
		const outra_unidade = !this.state.outra_unidade;
		const {
			item_key,
			caracteristica_id,
			updateItemCaracteristica,
			caracteristica_preenchida,
		} = this.props;
		const opcao_zerada_unidade =
			caracteristica_preenchida.opcoes_preenchidas.length > 0
				? {
					...caracteristica_preenchida.opcoes_preenchidas[0],
					unidade: '',
				}
				: buildOpcaoPreenchida(caracteristica_id, '', '', null);
		updateItemCaracteristica(item_key, caracteristica_id, [
			opcao_zerada_unidade,
		]);
		if (outra_unidade) {
			setTimeout(
				() => document
					.getElementById(
						`caracteristica_un_${item_key}_${caracteristica_id}`,
					)
					.focus(),
				60,
			);
		}
		this.setState({ outra_unidade });
	}

	render() {
		const { outro, outra_unidade } = this.state;
		const {
			item_key,
			itens,
			caracteristica_id,
			caracteristica_preenchida,
			updateItemCaracteristica,
			autoSave,
			updateCotacao,
			isModalSienge,
			is_compra,
			show_outro,
		} = this.props;
		const { opcoes_preenchidas } = caracteristica_preenchida;
		const {
			nome,
			opcoes,
			selecao,
			tipo_de_valor,
			visao_construtor,
			visao_fornecedor,
		} = caracteristica_preenchida.caracteristica;
		const caracteristica_type = caracteristicaType(
			opcoes,
			selecao,
			tipo_de_valor,
		);

		const opcoes_unidades = opcoes.filter(
			o => o.unidade !== '' && o.valor === '',
		);

		const opcoes_completas = opcoes.filter(o => o.valor !== '');

		const opcao_preenchida =
			opcoes_preenchidas.length > 0
				? { ...opcoes_preenchidas[0] }
				: buildOpcaoPreenchida(caracteristica_id, '', '', null);
		const valor =
			opcoes_preenchidas.length > 0 ? opcoes_preenchidas[0].valor : '';
		const unidade =
			opcoes_preenchidas.length > 0 ? opcoes_preenchidas[0].unidade : '';

		// const has_unidade = (opcoes_unidades.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha') ||
		// 	(caracteristica_type === 'selecao' && outro && opcoes_unidades.length > 0);
		const has_unidade =
			(opcoes.length > 0 &&
				caracteristica_type !== 'selecao' &&
				caracteristica_type !== 'multipla_escolha') ||
			(caracteristica_type === 'selecao' &&
				outro &&
				opcoes.length > 0 &&
				opcoes[0].unidade !== '');

		const caracteristica_wrapper_class = !has_unidade
			? 'form-group fg-resposta'
			: 'form-group fg-resposta has-unidade';

		let is_obrigatorio = '';
		if (!is_compra && visao_construtor === 2) {
			is_obrigatorio = <span styleName="obrigatorio-label">*</span>;
		}
		if (is_compra && (visao_construtor === 2 || visao_fornecedor === 2)) {
			is_obrigatorio = <span styleName="obrigatorio-label">*</span>;
		}
		// const is_obrigatorio = visao_construtor === 2
		// 	? <span styleName="obrigatorio-label">*</span>
		// 	: '';

		const nao_validadas = opcoes_preenchidas.filter(o => !o.validada);

		// check outra unidade
		const has_opcoes_unidade = opcoes
			.filter(o => o.valor === '' && o.unidade !== '')
			.length > 0;
		const unidade_preenchida = (opcao_preenchida || {}).unidade || '';
		const outra_unidade_preenchida = opcoes
			.filter(o => o.unidade === unidade_preenchida)
			.length === 0;
		const unidade_nova = (
			has_opcoes_unidade
			&& outra_unidade_preenchida
			&& unidade_preenchida !== ''
		);

		// const unidades_validas = opcoes.filter(o => {
		// 	const unidade_preenchida = opcoes_preenchidas.length > 0
		// 		? opcoes_preenchidas[0].unidade
		// 		: '';
		// 	return o.unidade === unidade_preenchida || unidade_preenchida === '';
		// });
		// const un_valida = opcoes.filter(
		// 	o => o.unidade ===
		// 			(opcoes_preenchidas.length > 0
		// 				? opcoes_preenchidas[0].unidade
		// 				: true) || opcoes_preenchidas[0].unidade === '',
		// );
		// const unidade_nova = unidades_validas.length === 0;

		const need_update = (
			nao_validadas.length > 0
			&& nao_validadas[0].valor !== ''
			&& (
				caracteristica_type === 'selecao'
				|| caracteristica_type === 'multipla_escolha'
			)
		) || unidade_nova;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

		const valor_select = typeof valor === 'number'
			? setDecimalsToString(valor)
			: valor;
		const style_row_edition = (isModalSienge || is_compra)
			? ''
			: 'row-edition';

		return (
			<div styleName={style_row_edition}>
				{(need_update && is_triagem) && (
					<UpdateCaracteristica
						item_key={item_key}
						itens={itens}
						caracteristica_id={caracteristica_id}
						opcoes_preenchidas={opcoes_preenchidas}
						unidade_nova={unidade_nova}
						autoSave={autoSave}
						updateCotacao={updateCotacao}
						setOutroUpdateCarac={this.setOutroUpdateCarac} />
				)}
				<div
					id={`resposta-${item_key}-${caracteristica_id}-wrapper`}
					className={caracteristica_wrapper_class}>
					<div
						id={`tooltip-resposta-${item_key}-${caracteristica_id}`}
						className="tooltip top"
						role="tooltip"
						style={{ marginTop: '-33px' }}>
						<div
							className="tooltip-arrow"
							style={{ borderTopColor: '#ee2622', left: '10%' }} />
						<div
							className="tooltip-inner"
							style={{ backgroundColor: '#ee2622' }}>
							Este campo é obrigatório
						</div>
					</div>
					<label>
						{nome} {is_obrigatorio}
					</label>
					{caracteristica_type === 'texto' && (
						<InputText
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							handleOutro={this.handleOutro}
							autoSave={autoSave} />
					)}
					{caracteristica_type === 'numero' && (
						<InputNumber
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							autoSave={autoSave} />
					)}
					{caracteristica_type === 'selecao' && !outro && (
						<InputSelect
							type="caracteristica"
							item_key={item_key}
							field={caracteristica_id}
							default_value={`${valor_select} ${unidade}`}
							opcoes={opcoes_completas}
							updateFunction={updateItemCaracteristica}
							handleOutro={this.handleOutro}
							autoSave={autoSave}
							show_outro={show_outro} />
					)}
					{/* (caracteristica_type === 'selecao' && outro) && */}
					{caracteristica_type === 'selecao' &&
						outro &&
						tipo_de_valor === 'texto' && (
						<InputText
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSave={autoSave} />
					)}
					{caracteristica_type === 'selecao' &&
						outro &&
						tipo_de_valor === 'numero' && (
						<InputNumber
							type="caracteristica"
							field_id={`resposta-${item_key}-${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={valor}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outro}
							handleOutro={this.handleOutro}
							autoSave={autoSave} />
					)}
					{caracteristica_type === 'multipla_escolha' && (
						<InputMultiple
							type="caracteristica"
							item_key={item_key}
							field={caracteristica_id}
							opcoes={opcoes_completas}
							opcoes_selecionadas={opcoes_preenchidas}
							updateFunction={updateItemCaracteristica}
							autoSave={autoSave}
							tipo_de_valor={tipo_de_valor} />
					)}
				</div>

				{opcoes.length > 0 &&
					caracteristica_type !== 'selecao' &&
					caracteristica_type !== 'multipla_escolha' &&
					!outra_unidade && (
					<div styleName="form-group-unidades">
						<div
							id={`tooltip-unidade-resposta-${item_key}-${caracteristica_id}`}
							className="tooltip top"
							role="tooltip"
							style={{ marginTop: '-15px' }}>
							<div
								className="tooltip-arrow"
								style={{
									borderTopColor: '#ee2622',
									left: '10%',
								}} />
							<div
								className="tooltip-inner"
								style={{ backgroundColor: '#ee2622' }}>
								Este campo é obrigatório
							</div>
						</div>
						<label>
							<br />
						</label>
						<InputUnidade
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							opcoes={opcoes_unidades}
							updateFunction={updateItemCaracteristica}
							handleOutraUnidade={this.handleOutraUnidade}
							autoSave={autoSave}
							show_outra_unidade={show_outro} />
					</div>
				)}

				{caracteristica_type === 'selecao' &&
					outro &&
					opcoes.length > 0 &&
					opcoes[0].unidade !== '' && (
					<div styleName="form-group-unidades">
						<div
							id={`tooltip-unidade-resposta-${item_key}-${caracteristica_id}`}
							className="tooltip top"
							role="tooltip"
							style={{ marginTop: '-15px' }}>
							<div
								className="tooltip-arrow"
								style={{
									borderTopColor: '#ee2622',
									left: '10%',
								}} />
							<div
								className="tooltip-inner"
								style={{ backgroundColor: '#ee2622' }}>
								Este campo é obrigatório
							</div>
						</div>
						<label>
							<br />
						</label>
						<InputText
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSave={autoSave} />
					</div>
				)}

				{(
					outra_unidade
					&& (
						caracteristica_type === 'texto'
						|| caracteristica_type === 'numero'
					)
				) && (
					<div styleName="form-group-unidades">
						<label>
							<br />
						</label>
						<InputText
							type="unidade-caracteristica"
							field_id={`caracteristica_un_${item_key}_${caracteristica_id}`}
							item_key={item_key}
							field={caracteristica_id}
							default_value={unidade}
							opcao_preenchida={opcao_preenchida}
							updateFunction={updateItemCaracteristica}
							outro={outra_unidade}
							handleOutro={this.handleOutraUnidade}
							autoSave={autoSave} />
					</div>
				)}
			</div>
		);
	}
}

Caracteristica.propTypes = {
	item_key: PropTypes.number.isRequired,
	itens: PropTypes.array.isRequired,
	caracteristica_id: PropTypes.string.isRequired,
	caracteristica_preenchida: PropTypes.object.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	isModalSienge: PropTypes.bool,
	is_compra: PropTypes.bool,
	show_outro: PropTypes.bool,
};

Caracteristica.defaultProps = {
	isModalSienge: false,
	is_compra: false,
	show_outro: true,
};

export default CSSModules(Caracteristica, styles, { allowMultiple: true });
