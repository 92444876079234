import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import withAddItemFuncs from '../_hocs/withAddItemFuncs';

class NoResult extends Component {
	addItemNaoCadastrado = () => {
		const {
			search,
			add_or_update,
			item_on_edition_id,
			addItemNaoCadastrado,
			associaItemNaoCadastrado,
			adding_item,
		} = this.props;

		if (adding_item) return;

		switch (add_or_update) {
		case 'add':
			addItemNaoCadastrado(search);
			break;
		case 'update':
			associaItemNaoCadastrado(item_on_edition_id, search);
			break;
		default:
		}
	}

	render() {
		const { search, results } = this.props;
		const has_itens = results.length > 0;

		if (has_itens) {
			return (
				<div styleName="btn-add-mesmo-assim">
					<button
						type="button"
						onClick={this.addItemNaoCadastrado}>
						Não encontrei meu item! <br />Adicionar <b style={{ color: '#0076D6' }}>{search}</b> mesmo assim.
					</button>
				</div>
			);
		}

		return (
			<div styleName="no-result-wrapper">
				<p>Não encontramos nenhum resultado para <b>{`"${search}"`}</b> no nosso banco de dados.</p>
				<button
					type="button"
					className="btn btn-clear-conaz round-border"
					onClick={this.addItemNaoCadastrado}>
					Adicionar à lista mesmo assim
				</button>
			</div>
		);
	}
}

NoResult.propTypes = {
	add_or_update: PropTypes.string.isRequired,
	item_on_edition_id: PropTypes.number.isRequired,
	search: PropTypes.string.isRequired,
	results: PropTypes.array.isRequired,
	addItemNaoCadastrado: PropTypes.func.isRequired,
	associaItemNaoCadastrado: PropTypes.func.isRequired,
	adding_item: PropTypes.bool.isRequired,
	/* Props que vao para o HOC withAddItemFuncs */
	/* eslint-disable react/no-unused-prop-types */
	type: PropTypes.string.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

const _NoResult = CSSModules(NoResult, styles, { allowMultiple: true });
export default withAddItemFuncs(_NoResult);
