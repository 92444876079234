// Functions
// import { _get } from './_requests';

export const prepareItensToGet = (itens_array) => {
	/**
	 * Objeto com id_item_construtor como key
	 * e ids dos itens de pedido dentro como array
	 */
	const ids_item_construtor = itens_array
		.filter(item => item.id_item_construtor !== '')
		.reduce((result, current) => ({
			...result,
			[current.id_item_construtor]: [
				...result[current.id_item_construtor] || [],
				current.id,
			],
		}), {});
	/**
	 * Ids id_item_construtor para buscar via GET
	 */
	// const ids_to_get = Object.keys(ids_item_construtor)
	// 	.map(id => _get(`/conaz/v2/integracoes/itens_integrados/associados?id_no_swi=${id}`));
	const ids_to_get = Object.keys(ids_item_construtor)
		.map(id => id);

	return ids_to_get;
};

export const prepareSearchUrl = (ids_to_get, cliente_id = null) => {
	const cliente_id_string = cliente_id !== null
		? `&cliente_id=${cliente_id}`
		: '';
	const ids_string_search = ids_to_get
		.map(id => `id_no_swi=${id}`)
		.join('&');
	return `/conaz/v2/integracoes/itens_integrados/associados?${ids_string_search}&swi=2&${cliente_id_string}&per_page=1000`;
};

export const handleInfoIntegracoes = (itens_array, response_itens) => {
	const responses_by_id = response_itens
		.reduce((result, current) => {
			const item_integrado = current || {};
			// const item_associado = (item_integrado.itens_associados || [])[0] || {};
			const { id_no_swi } = item_integrado || {};
			return {
				...result,
				[id_no_swi]: item_integrado,
			};

			// const item_associado = current.data[0] || {};
			// const { id_no_swi } = item_associado.item_integrado || {};
			// return {
			// 	...result,
			// 	[id_no_swi]: item_associado,
			// };
		}, {});

	const itens_array_new = itens_array.map(item => {
		const item_integrado = responses_by_id[item.id_item_construtor] !== undefined
			? { ...responses_by_id[item.id_item_construtor] }
			: null;
		return { ...item, item_integrado };
	});

	return itens_array_new;
};
