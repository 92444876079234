export const setAuthLocalstorage = (token, usuario, tem_express) => {
	const { id, email, apelido, obras } = usuario;
	const obra = obras.length > 0 ? obras[0].id : 0;
	const user_localstorage = {
		token,
		id,
		email,
		nome: apelido,
		obra,
		tem_express,
	};
	localStorage.setItem('user', JSON.stringify(user_localstorage));
};
