import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { setClasses } from '../../_functions/_setClasses';

const StepSienge = ({
	stage,
	current,
	label,
	descricao,
	children,
}) => {
	const is_active = stage <= current;
	const next_is_active = stage + 1 <= current;

	// descricao class
	const descricao_classes = {
		is_active,
		has_conn: stage < children.length,
		conn_is_active: is_active && next_is_active,
	};
	const descricao_class = setClasses('descricao', descricao_classes);

	// step class
	const step_classes = {
		is_active,
		current: stage === current,
		cancelado: label === 'Cancelada em' || label === 'Compra recusada em',
	};
	const step_class = setClasses('step', step_classes);

	// done
	const done = stage < current;
	const stage_view = done
		? <i className="fa fa-check" aria-hidden="true" />
		: stage;

	return (
		<div styleName="step-wrapper">
			{stage === current && (
				<div styleName="loader">
					<svg styleName="circular" viewBox="25 25 50 50">
						<circle
							styleName="path"
							cx="50"
							cy="50"
							r="20"
							fill="none"
							strokeWidth="2"
							strokeMiterlimit="10" />
					</svg>
				</div>
			)}
			<div styleName={step_class}>{stage_view}</div>
			<div styleName={descricao_class}>
				{(stage === current && stage < children.length) && (
					<div styleName="half-border" />
				)}
				<div styleName="label">{label}</div>
				{descricao}
			</div>
		</div>
	);
};

StepSienge.propTypes = {
	stage: PropTypes.number,
	current: PropTypes.number,
	label: PropTypes.string.isRequired,
	descricao: PropTypes.string,
	children: PropTypes.array,
};

StepSienge.defaultProps = {
	stage: 1,
	current: 1,
	descricao: '',
	children: [],
};

export default CSSModules(StepSienge, styles, { allowMultiple: true });
