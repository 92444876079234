import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// CSS
import styles from './style.module.scss';

// Components
import LayoutWrapper from '../../components/LayoutWrapper';

function NotFound404(props) {
	useEffect(() => {
		const { match, history } = props;
		const id = match.params.id || 0;
		switch (match.url) {
		case '/login':
			history.replace('/entrar');
			break;
		case `/pedido/${id}`:
		case `/pedido/${id}/i/:item`:
		case `/pedido/${id}/review`:
		case `/pedido/${id}/ver`:
		case `/pedido/${id}/imprimir`:
			history.replace(`/cotacao/${id}`);
			break;
		case '/':
		case '/pedidos':
		case '/pedidos/novo':
		default:
			history.replace('/cotacoes');
		}
	}, []);

	return (
		<LayoutWrapper {...props}>
			<div className="container">
				<div className="row">
					<div className="col-xs-12">.</div>
				</div>
			</div>
		</LayoutWrapper>

	);
}

NotFound404.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.shape({
		replace: PropTypes.func.isRequired,
	}).isRequired,
};

const _NotFound404 = CSSModules(NotFound404, styles, { allowMultiple: true });
const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(_NotFound404));
