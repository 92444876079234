import styled from 'styled-components';

import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
	z-index: 1010 !important;

	.ant-drawer-title {
		color: #262626;
		font-size: 18px;
		line-height: 25px;
		font-weight: normal;
	}

	.ant-drawer-body {
		/* padding: 40px; */
		display: flex;
		flex-direction: column;
		padding: 0px;
		position: relative;
	}

	.ant-form-item-label, .ant-form-item-required {
		color: #595959;
		font-size: 14px;
		> label {
			color: #595959;
			font-size: 14px;
		}
	}

	.ant-form-item-required::before {
		position: absolute;
		right: -16px;
		color: rgb(255, 120, 117) !important;
	}

	.ant-checkbox-wrapper {
		color: #262626;
		font-weight: normal;
	}

	.ant-form-padding {
		padding: 10px 40px 40px 40px;
		.ant-form-item-explain {
			min-width: 140px;
		}
	}

	.footer-form {
		padding: 0px;
	}

	.ant-input, .ant-select-selector {
		border-radius: 4px !important;
	}

	.line-uploader {
		height: 32px;
		display: flex;
		align-content: center;
		align-items: center;
		background: transparent;
		border: 1px dashed #0076D6;
		.ant-upload-drag-container {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 14px;
			color: #0076D6;
		}
	}

	.input-number-width {
		width: 100%;
	}

	.ant-form-item-less-margin {
		margin-bottom: 16px;
	}

	.drawer-obras-empresa-add {
		color: #0076D6;
		border-color: #0076D6;
		& > svg {
			fill: #0076D6;
			width: 23px;
			height: 23px;
		}

		&:disabled {
			color: gray;
			border-color: gray;
			&:hover {
				color: gray;
			}
			&  > svg {
				fill: gray;
			}
		}

		&:hover {
			color: #40a9ff;
			& > svg {
				fill: #40a9ff;
			}
		}
	}
	@media (max-width: 600px) {
		.ant-drawer-content-wrapper {
			max-width: 100% !important;
		}
		.ant-drawer-footer {
			margin-bottom: 6rem;
    		padding: 10px 0px;
		}
	}
`;

export const DrawerFooter = styled.div``;

DrawerFooter.Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	padding: 6px 40px;

	& > .ant-form-item {
		margin-bottom: 0px;
	}

	.cancel-btn {
		border: 1px solid #595959;
		color: #595959;
	}

	.submit-btn {
		background-color: #0076D6;
	}
`;
