import React from 'react';
import PropTypes from 'prop-types';
// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../../components/_parts/_loadings/LoadingButton/LoadingButton';

const InfoLogin = ({
	/* state */
	username,
	dominio,
	user_sienge,
	logging_state,
	infoMsg,
	/* props */
	/* funcs */
	handleEnableEdition,
	handleEdition,
	saveUsuarioSienge,
}) => (
	<>
		<div className="container">
			<div styleName="center-position p-top">
				<div styleName="card-login">
					<div styleName="p-bottom-info" className="form-group">
						<label>Domínio</label>
						<div>
							<div styleName="d-inline-block">
								<span>{dominio}.sienge.com.br</span>
							</div>
						</div>
					</div>
					<div styleName="p-bottom-info" className="form-group">
						<label>Usuário</label>
						<div>
							<span>{username}</span>
						</div>
					</div>
					<div styleName="p-bottom-info" className="form-group">
						<label>Senha</label>
						<div>
							<span>*********</span>
						</div>
					</div>
					<button
						onClick={handleEnableEdition}
						type="button"
						styleName="bg-white"
						className="btn btn-clear-conaz round-border">
						Editar informações da conta
					</button>
					<hr style={{ borderColor: '#dde0e3', margin: '50px 0' }} />
					<div className="form-group">
						<label>Usuário pessoal do Sienge (Código)</label>
						<input
							type="text"
							className="form-control input-md"
							id="user_sienge"
							value={user_sienge}
							onChange={handleEdition}
							maxLength={100} />
					</div>
					{logging_state === 3 && (
						<div className="alert alert-danger" role="alert">
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {infoMsg}
						</div>
					)}
					{logging_state === 1 ? (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border loading">
							<LoadingButton />Salvar usuário
						</button>
					) : (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={saveUsuarioSienge}>
							Salvar usuário
						</button>
					)}
					<p><br /></p><p><br /></p><p><br /></p>
				</div>
			</div>
		</div>
	</>
);


InfoLogin.propTypes = {
	username: PropTypes.string.isRequired,
	user_sienge: PropTypes.string.isRequired,
	dominio: PropTypes.string.isRequired,
	logging_state: PropTypes.number.isRequired,
	infoMsg: PropTypes.string.isRequired,
	handleEnableEdition: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
	saveUsuarioSienge: PropTypes.func.isRequired,
};

export default CSSModules(InfoLogin, styles, { allowMultiple: true });
