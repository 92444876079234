import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import UsarObsAnterior from './UsarObsAnterior';

class Observacoes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle: false,
			observacoes_internas: props.compra.observacoes_internas,
		};
		this.textarea = React.createRef();
		this.button = React.createRef();
	}

	handleChange = ({ target }) => {
		this.setState({ observacoes_internas: target.value });
	}

	handleObservacao = ({ target }) => {
		const { observacoes_internas, toggle } = this.state;
		const { compra, updateComprasRaw, autoSaveStart } = this.props;
		if (target.name === 'obs_interna') {
			updateComprasRaw({
				nova_compra: { ...compra, observacoes_internas },
			});
			this.setState({ toggle: !toggle }, () => autoSaveStart());
		} else {
			updateComprasRaw({
				nova_compra: { ...compra, [target.name]: target.value },
			});
			autoSaveStart();
		}
	};

	toggleObservacaoInterna = () => {
		const { toggle } = this.state;

		this.setState({ toggle: !toggle }, () => {
			if (this.state.toggle) {
				this.button.current.scrollIntoView();
				this.textarea.current.focus();
			}
		});
	};

	render() {
		const { compra } = this.props;
		const { toggle, observacoes_internas } = this.state;
		const { is_editable } = compra;

		if (is_editable) {
			return (
				<>
					{compra.tipo === 4 && (
						<div styleName="info-row">
							<div styleName="label obs">Observações para o Fornecedor</div>
							<UsarObsAnterior compra={compra} />
							<textarea
								name="observacoes"
								className="form-control"
								styleName="textarea"
								cols="60"
								onChange={this.handleObservacao}
								value={compra.observacoes}
								rows="5"
								maxLength="1500"
							/>
						</div>
					)}

					{!toggle ? (
						<>
							<button
								styleName="comentarios-internos closed"
								onClick={this.toggleObservacaoInterna}>
								<div styleName="label">Observações internas: </div>
								{compra.observacoes_internas !== '' ? (
									compra.observacoes_internas
								) : (
									<div styleName="expandir-button">Adicionar</div>
								)}
								{compra.observacoes_internas !== '' && (
									<div styleName="expandir-button">
										<i
											className="fa fa-pencil"
											aria-hidden="true"
											style={{ marginRight: '2px' }}
										/>
									</div>
								)}
							</button>
						</>
					) : (
						<>
							<div styleName="comentarios-internos expanded">
								<div styleName="label">
									Observações internas (essas observações não são
									visíveis pelos fornecedores):
								</div>
								<textarea
									ref={this.textarea}
									styleName="textarea"
									className="form-control"
									rows="4"
									name="observacoes_internas"
									value={observacoes_internas}
									onChange={this.handleChange}
									maxLength={1500}
								/>
								<button
									ref={this.button}
									name="obs_interna"
									className="btn btn-secondary-conaz round-border"
									styleName="salvar-comentarios-btn"
									onClick={this.handleObservacao}>
									Salvar alterações
								</button>
								<button
									name="obs_interna"
									className="btn btn-clear-conaz round-border"
									styleName="cancelar-comentarios-btn"
									onClick={this.handleObservacao}>
									Fechar
								</button>
							</div>
						</>
					)}
				</>
			);
		}

		return (
			<>
				<div styleName="info-row">
					<div styleName="label">Observações gerais</div>
					<p>
						{compra.observacoes !== '' ? (
							<em>&#34;{compra.observacoes}&#34;</em>
						) : (
							<em style={{ color: '#c3c6c9' }}>Nenhuma observação.</em>
						)}
					</p>
				</div>
				<div styleName="comentarios-internos expanded">
					<div styleName="label">Observações internas</div>
					<p>
						{observacoes_internas !== '' ? (
							<em>&#34;{observacoes_internas}&#34;</em>
						) : (
							<em style={{ color: '#c3c6c9' }}>Nenhuma observação.</em>
						)}
					</p>
				</div>
			</>
		);
	}
}

Observacoes.propTypes = {
	compra: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(Observacoes, styles, { allowMultiple: true });
