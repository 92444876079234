export default {
	isFetching: false,
	obra: {},

	actionFetching: false,
	// drawers
	showEditObraDrawer: false,

	showAssociaEmpresaDrawer: false,
	// para poder editar associação de empresa
	empresaToEdit: null,

	showAssociaUsuarioDrawer: false,
	// Para poder editar associação de usuario.
	usuarioToEdit: null,
};
