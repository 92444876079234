import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Icons
import { CopyOutlined } from '@ant-design/icons';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { _post } from '../../../components/_functions/_requests';

class CopiarPedido extends Component {
	constructor() {
		super();
		this.state = {
			confirm_copiar: false,
			duplicando: 0,
		};
		this.confirmaDuplicar = this.confirmaDuplicar.bind(this);
		this.duplicaCotacao = this.duplicaCotacao.bind(this);
	}

	confirmaDuplicar() {
		const confirm_copiar = !this.state.confirm_copiar;
		this.setState({ confirm_copiar });
		this.props.handleInsideConfirm();
	}

	duplicaCotacao() {
		const { match, history, updateUi, updateModals } = this.props;
		this.setState({ duplicando: 1 });
		_post(`/conaz/v2/pedidos/${match.params.id}/duplicar`, {}).then(response => {
			updateUi(['changes', 'cotacoes'], true);
			history.push(`/cotacao/nova/${response.data.codigo}`);
		}).catch(error => {
			this.setState({ duplicando: 3, confirm_copiar: false });
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const { confirm_copiar, duplicando } = this.state;
		const { inside_confirm } = this.props;

		return (
			<>
				{(!confirm_copiar && !inside_confirm) && (
					<button
						type="button"
						data-acoes-pedido-menu
						onClick={this.confirmaDuplicar}>
						<CopyOutlined styleName="icons-menu-acoes" data-acoes-pedido-menu /> Copiar cotação
					</button>
				)}
				{(confirm_copiar && inside_confirm) && (
					<div styleName="confirm-wrapper" data-acoes-pedido-menu id="copiar-pedido">
						{!duplicando && (
							<p data-acoes-pedido-menu>Deseja criar uma nova cotação? Ela será criada como rascunho com os mesmos itens e fornecedores desta cotação.</p>
						)}
						{duplicando ? (
							<p data-acoes-pedido-menu>Copiando cotação...</p>
						) : (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border"
								styleName="descartar-button"
								data-acoes-pedido-menu
								onClick={this.duplicaCotacao}>
								Sim
							</button>
						)}
						{!duplicando && (
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="cancelar-button"
								data-acoes-pedido-menu
								onClick={this.confirmaDuplicar}>
								Não
							</button>
						)}
					</div>
				)}
			</>
		);
	}
}

CopiarPedido.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	inside_confirm: PropTypes.bool.isRequired,
	handleInsideConfirm: PropTypes.func.isRequired,
};

export default CSSModules(CopiarPedido, styles, { allowMultiple: true });
