import { buildOpcaoPreenchida } from '../../../../components/_functions/_buildOpcaoPreenchida';

export const atualizaCaracteristicas = (item_associado) => {
	const i_item_preenchido = item_associado.item_associado_caracteristicas.item_preenchido || {};
	const ia_caracteristicas = i_item_preenchido.caracteristicas || [];

	const caracteristicas_preenchidas_array = (i_item_preenchido.caracteristicas_preenchidas_all_ids || [])
		.map(c_id => {
			const caracteristica = i_item_preenchido.caracteristicas_preenchidas[c_id] || {};
			const caracteristica_associada = ia_caracteristicas.filter(c => c.id === Number(c_id))[0] || {};
			const opcoes_ids = caracteristica_associada.opcoes_ids || [];
			const opcoes_preenchidas = [];

			for (let i = 0; i < opcoes_ids.length; i++) {
				const opcao_id = opcoes_ids[i];
				const opcao = caracteristica.caracteristica.opcoes.filter(o => o.id === opcao_id)[0] || {};
				if (opcao.id !== undefined) {
					opcoes_preenchidas.push(opcao);
				}
			}
			const outra_opcao = caracteristica_associada.outra_opcao || '';
			if (opcoes_ids.length === 0 && outra_opcao !== '') {
				const outra_unidade = caracteristica_associada.unidade || '';
				const opcao_preenchida = buildOpcaoPreenchida(Number(c_id), outra_opcao, outra_unidade, null);
				opcoes_preenchidas.push(opcao_preenchida);
			}
			const caracteristicas_preenchidas = {
				...caracteristica,
				opcoes_preenchidas,
			};
			return caracteristicas_preenchidas;
		});

	const caracteristicas_preenchidas = caracteristicas_preenchidas_array
		.reduce((result, current) => ({
			...result,
			[current.caracteristica.id]: { ...current },
		}), {});
	return caracteristicas_preenchidas;
};
