/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Desconto from './Desconto';
import CV3CondicaoAberta from './CV3CondicaoAberta';
import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';
import { _put } from '../../../../components/_functions/_requests';

// Content
import {
	formas_pagamento,
	prazos_pagamento_full,
} from '../../../../components/_content/_condicoes_pagamento';

class CV3OpcoesPagamento extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opcoes: [],
			opcao_selected: null,
			no_opcao: Object.entries(props.nova_compra.rc).length === 0,
		};
	}

	UNSAFE_componentWillMount() {
		const { nova_compra } = this.props;
		const rc = nova_compra.rc || {};
		const opcao_selected_id = (nova_compra.condicao_de_pagamento || {}).id;
		let opcao_selected = null;

		const opcoes = (rc.condicoes_de_pagamento || [])
			.filter(c => c.forma !== 0 && c.prazo !== 0)
			.map((c, i) => {
				if (c.id === opcao_selected_id) {
					opcao_selected = i;
				}
				if (c.desconto === null || c.desconto === undefined) {
					return { ...c, desconto: 0 };
				}
				return c;
			})
			.sort((a, b) => b.desconto - a.desconto);
		// Check se outra opção foi selecionada
		if (opcao_selected === null) opcao_selected = opcao_selected_id;

		this.setState({
			opcoes,
			opcao_selected,
		});
	}

	handleEdition = key => {
		const {
			nova_compra: compra,
			updateComprasRaw,
			autoSaveStart,
		} = this.props;
		const { outra_condicao_de_pagamento, rc } = compra;
		const { id: outro_id } = outra_condicao_de_pagamento;

		this.props.handleFixError('opcao_pagamento');
		this.setState({ opcao_selected: key });
		const condicao_de_pagamento =
			key === outro_id
				? { ...outra_condicao_de_pagamento }
				: { ...this.state.opcoes[key] };

		const { id: condicao_id = null } = condicao_de_pagamento;
		/**
		 * Tem RC ou RC analise
		 */
		const tem_condicao_existente =
			(rc.condicoes_de_pagamento || []).filter(c => c.id === condicao_id)
				.length > 0;

		const nova_compra = {
			...compra,
			condicao_de_pagamento,
		};
		updateComprasRaw({ nova_compra });
		if (rc.uuid !== undefined && !tem_condicao_existente) {
			_put(
				`/conaz/v2/condicoes_de_pagamento/${outro_id}`,
				condicao_de_pagamento,
			)
				.then(() => autoSaveStart())
				.catch(error => console.error(error)); // eslint-disable-line
			return;
		}
		autoSaveStart();
	};

	render() {
		const { opcoes, opcao_selected, no_opcao } = this.state;
		const {
			nova_compra,
			nova_compra: {
				is_editable,
				condicao_de_pagamento: cp,
				outra_condicao_de_pagamento: { id: outro_id },
			},
			updateComprasRaw,
			errors,
			handleFixError,
			autoSaveStart,
		} = this.props;
		const forma = cp.forma || 0;
		const prazo = cp.prazo || 0;
		const outra_forma = cp.outra_forma || '';
		const outro_prazo = cp.outro_prazo || '';
		const desconto = cp.desconto || 0;

		const opcao_outro_class = opcao_selected === outro_id
			? 'radio-button-block selected'
			: 'radio-button-block';

		return is_editable ? (
			<>
				<div>
					{opcoes.map((opcao, o) => {
						const opcao_class =
							opcao_selected === o
								? 'radio-button-block selected'
								: 'radio-button-block';

						return (
							<button
								key={o}
								id={`condicao_pagamento_o_${o}`}
								type="button"
								styleName={opcao_class}
								onClick={() => this.handleEdition(o)}>
								<div styleName="radio" />
								<div styleName="pagamento">
									<b>
										{opcao.forma === -1
											? opcao.outra_forma
											: formas_pagamento[opcao.forma]
												.view}
										:&nbsp;
										{opcao.prazo === -1
											? opcao.outro_prazo
											: prazos_pagamento_full[opcao.prazo]
												.view}
									</b>
									{opcao.desconto > 0 && (
										<>
											<br />
											<span styleName="desconto">
												({opcao.desconto}% de desconto
												já incluso)
											</span>
										</>
									)}
									<br />
									{opcao.aceita_parcial === 0 && (
										<span
											styleName="compra-parcial"
											style={{ color: '#e91e63' }}>
											Não aceita compras parciais
										</span>
									)}
									{opcao.aceita_parcial === 1 && (
										<span styleName="compra-parcial">
											Aceita compras parciais
										</span>
									)}
									{opcao.aceita_parcial === 2 &&
										opcao.compra_minima > 0 && (
										<span styleName="compra-parcial">
											Aceita compras parciais acima de{' '}
											{formatCurrency(
												opcao.compra_minima,
												2,
												'R$',
											)}
										</span>
									)}
								</div>
							</button>
						);
					})}
					{opcao_selected !== outro_id && !no_opcao && (
						<button
							type="button"
							styleName={opcao_outro_class}
							onClick={() => this.handleEdition(outro_id, 0)}>
							<div styleName="radio" />
							<div styleName="outro-label">Outra condição de pagamento e desconto</div>
						</button>
					)}
					{(opcao_selected === outro_id || no_opcao) && (
						<div
							styleName={
								!no_opcao
									? 'outro-block-wrapper'
									: 'no-option-outro-block-wrapper'
							}>
							{!no_opcao && <div styleName="radio" />}
							{!no_opcao && (
								<div styleName="pagamento">Outra condição de pagamento e desconto</div>
							)}
							{!no_opcao && (<>
								<div styleName="tag editado">Editado</div>
								<div styleName="tooltip-hover">
									<Tooltip
										margin_top="-8.5%"
										margin_left="45%"
										text="Diferente da cotação do fornecedor" />
								</div>
							</>)}
							<CV3CondicaoAberta
								errors={errors}
								handleFixError={handleFixError}
								nova_compra={nova_compra}
								updateComprasRaw={updateComprasRaw}
								autoSaveStart={autoSaveStart}
								only_input />
							<label>Desconto</label>
							<Desconto
								errors={errors}
								handleFixError={handleFixError}
								nova_compra={nova_compra}
								autoSaveStart={autoSaveStart}
								updateComprasRaw={updateComprasRaw}
								only_input />
						</div>
					)}
				</div>
			</>
		) : (
			<>
				<div styleName="pagamento">
					{forma === -1 ? outra_forma : formas_pagamento[forma].view}
					:&nbsp;
					{prazo === -1
						? outro_prazo
						: prazos_pagamento_full[prazo].view}
					{desconto > 0 && (
						<>
							<br />
							<span styleName="desconto">
								({desconto}% de desconto já incluso)
							</span>
						</>
					)}
				</div>
			</>
		);
	}
}

CV3OpcoesPagamento.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default CSSModules(CV3OpcoesPagamento, styles, { allowMultiple: true });
