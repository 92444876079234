import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Row, Col, Select, Button, InputNumber, Checkbox, Divider, Input } from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { validations } from 'ambar-ui';

import TabelaAssociados from './TabelaUsuariosAdicionados';
import CopyUsuarioForm from './copyUsuarioForm';
import papeisOptions from './papeisOptions';

// Redux HOC
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

const { validateRequired } = validations;

function AssociaUsuario({ form, ...props }) {
	const {
		getUsersByClient,
		openUserDrawer,
		openDrawerCopyUsuario,
		obrasDrawer,
		obras,
		usuarios,
		associaUsuario,
	} = props;

	const [searchUserTxt, setSearchUserTxt] = useState('');

	const disableAll = usuarios.isFetching;

	const handleCadastrarUsuario = () => {
		openUserDrawer();
	};

	const handleCopyState = () => {
		openDrawerCopyUsuario();
	};

	const handleUserSearch = (txt) => {
		setSearchUserTxt(txt);
	};

	// func que vai verificar se já pode associar o usuário.
	const canAddUser = (getFieldsValue) => {
		const { nome_usuario, papeis, compra_ilimitada, valor_alcada_compra } = getFieldsValue();
		const alcadaSetado = compra_ilimitada || valor_alcada_compra;
		if (nome_usuario && (alcadaSetado || alcadaSetado === 0) && (papeis && papeis.length)) return false;
		return true;
	};

	const handleChangeUser = (v) => {
		form.setFieldsValue({
			user_id: v,
		});
	};

	const handleAssociaUsuario = () => {
		const { nome_usuario, papeis, compra_ilimitada, valor_alcada_compra, user_id, obra_id } = form.getFieldsValue();
		const usuario = {
			nome_usuario,
			papeis: [...new Set(papeis)],
			compra_ilimitada,
			valor_alcada_compra,
			user_id,
			obra_id,
		};
		associaUsuario(usuario);
	};

	const filterUsers = (txt) => {
		if (!txt) return usuarios.users.map((emp) => ({ label: emp.nome, value: emp.id, data: emp }));

		return usuarios.users.filter((emp) => emp?.nome.toLowerCase().includes(txt.toLowerCase()))
			.map((emp) => ({ label: emp.nome, value: emp.id, data: emp }));
	};

	const filteredUsers = filterUsers(searchUserTxt);

	useEffect(() => {
		if (!usuarios.isFetching && !usuarios.users.length) {
			getUsersByClient();
		}
	}, [usuarios]);

	return (
		!obrasDrawer.copyUsuariosState ?
			<>
				<div style={{ margin: '16px 0px 16px 0px' }}>
					<Button className="copy-obra-btn" disabled={disableAll} onClick={handleCopyState}>
						<CopyOutlined />
						Copiar dados de outra obra
					</Button>
				</div>
				<Form.Item noStyle name="obra_id"> <Input hidden /> </Form.Item>
				<Form.Item name="user_id" noStyle> <Input hidden /> </Form.Item>
				<Row gutter={24} className="usuario-associa-row">
					<Col span={12}>
						<Form.Item
							name="nome_usuario"
							label="Usuário"
							rules={[validateRequired(), { validator: (_, value, callback) => {
								if (!filteredUsers.length && searchUserTxt) return callback('- Usuário não cadastrado');
								callback();
							} }]}
							className="usuario-error-position-top"
						>
							<Select
								showSearch
								allowClear
								showArrow={false}
								onChange={handleChangeUser}
								onSearch={handleUserSearch}
								disabled={usuarios.isFetching}
								options={filteredUsers}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="papeis" label="Papéis" rules={[validateRequired()]}>
							<Select placeholder="Selecione o tipo" options={papeisOptions} mode="multiple" />
						</Form.Item>
					</Col>
				</Row>
				<div style={{ margin: '0px 0px 16px 0px' }}>
					<Button onClick={handleCadastrarUsuario} className="drawer-obras-empresa-add">
						<PlusOutlined />
						Cadastrar usuário
					</Button>
				</div>
				<Row gutter={12}>
					<Col span={4}>
						<Form.Item initialValue={0} name="valor_alcada_compra" label="Alçada" rules={[validateRequired()]} shouldUpdate>
							<InputNumber min={0} className="input-number-width" placeholder="R$" disabled={form.getFieldValue('compra_ilimitada')} />
						</Form.Item>
					</Col>
					<Col span={16}>
						<Form.Item label=" " name="compra_ilimitada" valuePropName="checked">
							<Checkbox>
								Ilimitado para alçada de compra
							</Checkbox>
						</Form.Item>
					</Col>
					<Col span={4}>
						<Form.Item label=" " shouldUpdate>
							{({ getFieldsValue }) => (
								<Button disabled={canAddUser(getFieldsValue) || obras.actionFetching} onClick={handleAssociaUsuario}>
									Associar usuários
								</Button>
							)}
						</Form.Item>
					</Col>
				</Row>
				<Divider className="divider" />
				<Form.Item shouldUpdate name="tabela_usuarios" initialValue={[]}>
					<TabelaAssociados />
				</Form.Item>
			</>
			:
			<CopyUsuarioForm form={form} />
	);
}

AssociaUsuario.propTypes = {
	form: PropTypes.shape(),
	obras: PropTypes.shape(),
	obrasDrawer: PropTypes.shape(),
	associaUsuario: PropTypes.func,
	getUsersByClient: PropTypes.func,
	openUserDrawer: PropTypes.func,
	openDrawerCopyUsuario: PropTypes.func,
	usuarios: PropTypes.shape(),
};

AssociaUsuario.defaultProps = {
	form: {},
	obras: {},
	obrasDrawer: {},
	associaUsuario: () => {},
	openDrawerCopyUsuario: () => {},
	getUsersByClient: () => {},
	openUserDrawer: () => {},
	usuarios: {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(AssociaUsuario));
