export const EMPRESAS_GET_LIST_REQUEST = 'EMPRESAS_GET_LIST_REQUEST';
export const EMPRESAS_GET_LIST_SUCCESS = 'EMPRESAS_GET_LIST_SUCCESS';
export const EMPRESAS_GET_LIST_ERROR = 'EMPRESAS_GET_LIST_ERROR';

export const EMPRESAS_CREATE_REQUEST = 'EMPRESAS_CREATE_REQUEST';
export const EMPRESAS_EDIT_REQUEST = 'EMPRESAS_EDIT_REQUEST';
export const EMPRESAS_DELETE_REQUEST = 'EMPRESAS_DELETE_REQUEST';
export const EMPRESAS_BLOCK_REQUEST = 'EMPRESAS_BLOCK_REQUEST';

export const EMPRESAS_CREATE_SUCCESS = 'EMPRESAS_CREATE_SUCCESS';
export const EMPRESAS_EDIT_SUCCESS = 'EMPRESAS_EDIT_SUCCESS';
export const EMPRESAS_DELETE_SUCCESS = 'EMPRESAS_DELETE_SUCCESS';
export const EMPRESAS_BLOCK_SUCCESS = 'EMPRESAS_BLOCK_SUCCESS';

export const EMPRESAS_ACTION_ERROR = 'EMPRESAS_ACTION_ERROR';
// DRAWER
export const EMPRESAS_OPEN_DRAWER = 'EMPRESAS_OPEN_DRAWER';
export const EMPRESAS_CLOSE_DRAWER = 'EMPRESAS_CLOSE_DRAWER';
