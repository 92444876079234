import React from 'react';

// CSS
import CSSModules from 'react-css-modules';
import { Spin } from 'antd';
import styles from './style.module.scss';

const LoadingOutroFornecedor = () => (
	<div styleName="loader">
		<Spin />
	</div>
);


export default CSSModules(LoadingOutroFornecedor, styles, { allowMultiple: true });
