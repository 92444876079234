import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import withAddItemFuncs from '../_hocs/withAddItemFuncs';

// Functions
import { setClasses } from '../../../_functions/_setClasses';

/* eslint-disable react/no-danger, react/no-array-index-key */
class SearchResult extends Component {
	addItem = () => {
		const {
			add_or_update,
			item_on_edition_id,
			item,
			getItemToAdd,
			associaItemSienge,
			adding_item,
		} = this.props;

		if (adding_item) return;

		switch (add_or_update) {
		case 'add':
			getItemToAdd(item);
			break;
		case 'update':
			associaItemSienge(item_on_edition_id, item);
			break;
		default:
			getItemToAdd(item);
		}
	}

	render() {
		const { item, adding_item, error_adding } = this.props;

		const item_classes = {
			adding: adding_item,
			error: error_adding,
		};
		const item_class = setClasses('item-result', item_classes);

		return (
			<button
				type="button"
				styleName={item_class}
				onClick={this.addItem}>
				<i className="fa fa-angle-right" aria-hidden="true" />
				<h4
					className="list-group-item-heading"
					styleName="results-list-h4"
					dangerouslySetInnerHTML={{ __html: item.nome }} />
				{item.sinonimos_front.length > 0 && (
					<p className="list-group-item-text" styleName="results-list-p">
						<b>Sinônimos:</b> {item.sinonimos_front.map((sinonimo, s) => (
							<span key={s} dangerouslySetInnerHTML={{ __html: sinonimo.nome }} />
						))}
					</p>
				)}
				{item.descricao !== '' && (
					<p className="list-group-item-text" styleName="results-list-p">
						<b>Descrição</b>: {item.descricao}
					</p>
				)}
				{error_adding && (
					<div styleName="error-alert">Erro ao adicionar item. Tente novamente.</div>
				)}
			</button>
		);
	}
}

SearchResult.propTypes = {
	add_or_update: PropTypes.string.isRequired,
	item_on_edition_id: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
	getItemToAdd: PropTypes.func.isRequired,
	associaItemSienge: PropTypes.func.isRequired,
	adding_item: PropTypes.bool.isRequired,
	error_adding: PropTypes.bool.isRequired,
	/* Props que vao para o HOC withAddItemFuncs */
	/* eslint-disable react/no-unused-prop-types */
	type: PropTypes.string.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
};

const _SearchResult = CSSModules(SearchResult, styles, { allowMultiple: true });
export default withAddItemFuncs(_SearchResult);
