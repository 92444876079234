import React from 'react';
import PropTypes from 'prop-types';


import ModalNotificaDecisor from './ModalNotificaDecisor';


import { getLocalstorageData } from '../../../_functions/_getLocalstorageData';


const ModalNotificaSobrePedido = ({ codigo_pedido, obra_id, obra_nome, reportErrors, route_path, updateModals }) => {
	const user_nome = getLocalstorageData('user', 'nome');
	const assunto = `Notificação sobre a cotação ${codigo_pedido}`;
	const pre_mensagem = `${user_nome} está te notificando sobre a cotação ${codigo_pedido} (obra: ${obra_nome}).`;

	return (
		<ModalNotificaDecisor
			sobre="cotacao"
			codigo={codigo_pedido}
			assunto={assunto}
			pre_mensagem={pre_mensagem}
			texto_fim_mensagem="Você pode acessá-la através do link abaixo:"
			obra_selected={obra_id}
			reportErrors={reportErrors}
			route_path={route_path}
			updateModals={updateModals} />);
};

ModalNotificaSobrePedido.propTypes = {
	obra_nome: PropTypes.string.isRequired,
	obra_id: PropTypes.number.isRequired,
	codigo_pedido: PropTypes.string.isRequired,
	reportErrors: PropTypes.func.isRequired,
	route_path: PropTypes.string.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default ModalNotificaSobrePedido;
