import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { formatCurrency } from '../../../../_functions/_formatCurrency';

const ItemExpandido = ({ item, total }) => {
	const {
		preco_unitario,
		desconto,
	} = item;
	const item_preenchido = item.item_preenchido || {};
	const {
		caracteristicas_preenchidas,
		caracteristicas_preenchidas_all_ids,
		// item,
	} = item_preenchido;

	const caracs = (caracteristicas_preenchidas_all_ids || [])
		.sort((a, b) => {
			const ordem_a = caracteristicas_preenchidas[a].caracteristica.ordem;
			const ordem_b = caracteristicas_preenchidas[b].caracteristica.ordem;
			return ordem_a - ordem_b;
		})
		.filter(ca_id => {
			const c = caracteristicas_preenchidas[ca_id] || {};
			const valor = (c.opcoes_preenchidas[0] || {}).valor || '';
			return (
				c.caracteristica.visao_construtor === 2
				|| c.caracteristica.visao_fornecedor === 2
				|| valor !== ''
			);
		});

	const getCompletedInfo = (completed_info) => {
		const valor = (completed_info[0] || {}).valor || '';
		const unidade = (completed_info[0] || {}).unidade || '';
		if (valor === '') return '-';
		return `${completed_info[0].valor} ${unidade}`;
	};

	const observacoes = !item.cliente_associou
		? item.pre_detalhe || ''
		: item.informacoes_adicionais || '';

	return (
		<div styleName="item-info">

			<div styleName="valores">
				<div styleName="valor-unitario">
					<div styleName="label-mobile">Valor unitário</div>
					{formatCurrency(preco_unitario, 2, 'R$')}
				</div>
				<div styleName="desconto">
					<div styleName="label-mobile">Desconto</div>
					{desconto}%
				</div>
				<div styleName="valor-total">
					<div styleName="label-mobile">Total</div>
					{formatCurrency(total, 2, 'R$')}
				</div>
				<hr />
			</div>

			{caracs.map(ca_id => {
				const c = caracteristicas_preenchidas[ca_id] || {};

				return (
					<div key={ca_id} styleName="characteristic">
						<b>{c.caracteristica.nome}: </b>
						{getCompletedInfo(c.opcoes_preenchidas)}
					</div>
				);
			})}
			{observacoes !== '' && (
				<div styleName="observation">
					<b>Observações: </b>
					{observacoes}
				</div>
			)}
		</div>
	);
};

ItemExpandido.propTypes = {
	item: PropTypes.object.isRequired,
	total: PropTypes.number.isRequired,
};

export default CSSModules(ItemExpandido, styles, { allowMultiple: true });
