import { buildNewItem } from '../../../../components/_functions/_buildNewItem';
import { atualizaCaracteristicas } from './_atualizaCaracteristicas';

export const associarItem = (key, item_para_edicao, item_to_add) => {
	const pre_new_item = {
		...item_para_edicao,
		cliente_associou: true,
		item_preenchido: {
			caracteristicas_preenchidas: [],
			item: { ...item_to_add },
			caracteristicas: [],
		},
	};

	const buildItem = buildNewItem(key, pre_new_item, null);
	const new_item = {
		...buildItem,
		item_associado_caracteristicas: {
			item_preenchido: {
				...buildItem.item_preenchido,
				caracteristicas: pre_new_item.item_integrado.item_preenchido.caracteristicas || [],
			},
		},
	};
	new_item.item_preenchido.caracteristicas_preenchidas = atualizaCaracteristicas(new_item);
	return new_item;
};
