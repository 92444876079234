import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _put } from '../../../_functions/_requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';

class IEComentarios extends Component {
	state = {
		comentarios_open: false,
		observacoes_internas: this.props.observacoes_internas,
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { observacoes_internas, item_id } = nextProps;
		if (item_id !== this.props.item_id) {
			this.setState({
				observacoes_internas,
				comentarios_open: false,
			});
		}
	}

	openComentarios = () => {
		this.setState({ comentarios_open: true });
		setTimeout(() => {
			const { item_id } = this.props;
			const textarea = document.getElementById(`observacoes_internas_${item_id}`);
			if (textarea) textarea.focus();
		}, 60);
	}

	closeComentarios = () => {
		const { observacoes_internas } = this.state;
		const { item_id, updateFunction } = this.props;
		this.setState({ comentarios_open: false });
		updateFunction(item_id, 'observacoes_internas', observacoes_internas);

		const url = `/conaz/v2/itens_de_compra/${item_id}`;
		const params = {
			observacoes_internas,
			force: true,
		};
		_put(url, params).then().catch(error => {
			// updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	handleEdition = (e) => {
		const { value } = e.target;
		this.setState({ observacoes_internas: value });
	}

	render() {
		const { comentarios_open, observacoes_internas } = this.state;
		const { item_id, maxLength } = this.props;

		if (!comentarios_open) {
			return (
				<button
					type="button"
					styleName="comentarios-internos closed"
					onClick={this.openComentarios}>
					<div styleName="label">Observações internas: </div>
					{observacoes_internas !== '' ? observacoes_internas : <div styleName="expandir-button">Adicionar</div>}
					{observacoes_internas !== '' && (
						<div styleName="expandir-button">
							<i className="fa fa-pencil" aria-hidden="true" style={{ marginRight: '2px' }} />
						</div>
					)}
				</button>
			);
		}

		return (
			<div styleName="comentarios-internos expanded">
				<div styleName="label">Observações internas (essas observações não são visíveis pelos fornecedores):</div>
				<textarea
					id={`observacoes_internas_${item_id}`}
					className="form-control"
					rows="4"
					value={observacoes_internas}
					onChange={this.handleEdition}
					maxLength={maxLength} />
				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					styleName="salvar-comentarios-btn"
					onClick={this.closeComentarios}>
					Salvar alterações
				</button>
				<button
					type="button"
					className="btn btn-clear-conaz round-border"
					styleName="cancelar-comentarios-btn"
					onClick={this.closeComentarios}>
					Fechar
				</button>
			</div>
		);
	}
}

IEComentarios.propTypes = {
	item_id: PropTypes.number.isRequired,
	observacoes_internas: PropTypes.string.isRequired,
	updateFunction: PropTypes.func.isRequired,
	// autoSave: PropTypes.func.isRequired,
	maxLength: PropTypes.number,
};

IEComentarios.defaultProps = {
	maxLength: null,
};

export default CSSModules(IEComentarios, styles, { allowMultiple: true });
