import update from 'immutability-helper';
import itens from './store';
import {
	UPDATE_ITENS,
	UPDATE_ITENS_RAW,
	UPDATE_ITEM_BY_ID,
	UPDATE_FULLITEM_BY_ID,
	UPDATE_ITEM_CARAC_BY_ID,
	DELETE_ITEM,
} from './types';

const reducer = (state = itens, action) => {
	switch (action.type) {
	case UPDATE_ITENS:
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});
		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});
		default:
			return state;
		}

	case UPDATE_ITENS_RAW: {
		const all_fields = Object.keys(action.fields_n_values)
			.reduce((result, current) => ({
				...result,
				[current]: { $set: action.fields_n_values[current] },
			}), {});
		return update(state, all_fields);
	}

	case UPDATE_ITEM_BY_ID: {
		// TODO enviar tipo do item que sera editado
		const by_id = {
			...state.itens_compra.by_id,
			[action.item_id]: {
				...state.itens_compra.by_id[action.item_id],
				[action.field]: action.value,
			},
		};
		const itens_compra = {
			...state.itens_compra,
			by_id,
		};
		return update(state, { itens_compra: { $set: itens_compra } });
	}

	case UPDATE_FULLITEM_BY_ID: {
		const by_id = {
			...state.itens_compra.by_id,
			[action.item_id]: { ...action.item },
		};
		const itens_compra = {
			...state.itens_compra,
			by_id,
		};
		return update(state, { itens_compra: { $set: itens_compra } });
	}

	case UPDATE_ITEM_CARAC_BY_ID: {
		// TODO enviar tipo do item que sera editado
		const item_to_edit = { ...state.itens_compra.by_id[action.item_id] };
		const caracteristicas_preenchidas = {
			...item_to_edit.item_preenchido.caracteristicas_preenchidas,
			[action.ca_id]: {
				...item_to_edit.item_preenchido.caracteristicas_preenchidas[action.ca_id],
				opcoes_preenchidas: action.value,
			},
		};
		const item = {
			...item_to_edit,
			item_preenchido: {
				...item_to_edit.item_preenchido,
				caracteristicas_preenchidas,
			},
		};
		const by_id = {
			...state.itens_compra.by_id,
			[action.item_id]: item,
		};
		const itens_compra = {
			...state.itens_compra,
			by_id,
		};
		return update(state, { itens_compra: { $set: itens_compra } });
	}

	case DELETE_ITEM: {
		let key = 1;
		const all_ids = state.itens_compra.all_ids
			.filter(id => id !== action.item_id);
		const by_id = all_ids
			.map(id => {
				const item = {
					...state.itens_compra.by_id[id],
				};
				const front = {
					...item.front,
					key,
				};
				key++;
				return {
					...item,
					front,
				};
			})
			.reduce((result, current) => (
				{ ...result, [current.id]: current }
			), {});
		const itens_compra = {
			...state.itens_compra,
			all_ids,
			by_id,
		};
		return update(state, { itens_compra: { $set: itens_compra } });
	}

	default:
		return state;
	}
};

export default reducer;
