/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import createCompraHoc from '../../../hocs/createCompraHoc/createCompraHoc';
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';
import LoadingOutroFornecedor from '../_loadings/LoadingOutroFornecedor/LoadingOutroFornecedor';

class CriarCompraButton extends Component {
	criaCompra = () => {
		const { oc, pedido, updateModals, createCompra } = this.props;
		createCompra(oc, pedido, updateModals);
	}

	render() {
		const { criando, style_class, html, in_comb, row_outro_fornecedor } = this.props;
		const button_class = criando === 1
			? 'btn btn-secondary-conaz round-border loading'
			: 'btn btn-secondary-conaz round-border';
		const on_click_func = criando === 1
			? null
			: this.criaCompra;
		const button_html = criando === 1
			? <><LoadingButton />{html}</>
			: html;


		const buttonCriarCompraSemCombinacao = (window.innerWidth > 768)
			? { top: '1rem' } : {};

		const buttonCriarCompraComCombinacao = (window.innerWidth > 768)
			? { top: '1.5rem' } : {};

		const buttonComprarNovamente = (window.innerWidth > 768 && !in_comb && html === 'Comprar novamente')
			? { top: '1rem' } : !in_comb && html === 'Criar compra' ? buttonCriarCompraSemCombinacao :
				in_comb && html === 'Criar compra' ? buttonCriarCompraComCombinacao : {};

		return (
			<>
				{row_outro_fornecedor ? (
					// Botão do componente RCRowOutroFornecedor
					<>
						<button
							type="button"
							onClick={on_click_func}
							styleName="button-row-fornecedor">aqui
						</button>
						{criando === 1 && <><LoadingOutroFornecedor /></>}
					</>
				) : (
					<button
						type="button"
						className={button_class}
						styleName={(in_comb && html === 'Criar compra') ? style_class : ''}
						style={buttonComprarNovamente}
						onClick={on_click_func}>
						{button_html}
					</button>
				)}
			</>
		);
	}
}

CriarCompraButton.propTypes = {
	oc: PropTypes.object.isRequired,
	pedido: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	createCompra: PropTypes.func.isRequired,
	style_class: PropTypes.string.isRequired,
	criando: PropTypes.number.isRequired,
	html: PropTypes.string,
	in_comb: PropTypes.bool,
	row_outro_fornecedor: PropTypes.bool,
};

CriarCompraButton.defaultProps = {
	html: 'Criar Compra',
	in_comb: false,
	row_outro_fornecedor: false,
};

const _CriarCompraButton = CSSModules(CriarCompraButton, styles, { allowMultiple: true });
export default createCompraHoc(_CriarCompraButton);
