import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import shallowequal from 'shallowequal';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import SearchBar from '../SearchBar/SearchBar';
import AccountMenu from '../AccountMenu/AccountMenu';
import Obras from '../Obras/Obras';
import SearchResults from '../SearchResults/SearchResults';

class TopBar extends Component {
	constructor() {
		super();
		this.clearSearch = this.clearSearch.bind(this);
	}

	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	clearSearch() {
		this.props.updateUi(['search'], '');
	}

	render() {
		const { obras_by_id, obras_all_ids, obra_selected, obras_menu, account_menu,
			updateUi, search, search_results, search_state, changeObra, unsetAuth } = this.props;

		return (
			<div styleName="top-bar">
				{search !== '' && (
					<SearchResults
						search_results={search_results}
						search_state={search_state}
						updateUi={updateUi} />
				)}
				<div>
					<SearchBar
						updateUi={updateUi}
						search={search} />
					<div styleName="mobile-logo">
						<Link to="/cotacoes"><img src="/images/conaz_logo_atualizada.png" width="70" alt="" /></Link>
					</div>
					<div styleName="right-content">
						{search && (
							<button
								type="button"
								className="close"
								styleName="clear-button"
								data-dismiss="modal"
								aria-label="Close"
								onClick={this.clearSearch}>
								<span aria-hidden="true">&times;</span>
							</button>
						)}
						<AccountMenu
							updateUi={updateUi}
							account_menu={account_menu}
							unsetAuth={unsetAuth} />

						<Obras
							updateUi={updateUi}
							obras_menu={obras_menu}
							obras_by_id={obras_by_id}
							obras_all_ids={obras_all_ids}
							obra_selected={obra_selected}
							changeObra={changeObra} />
					</div>
				</div>
			</div>
		);
	}
}

TopBar.propTypes = {
	updateUi: PropTypes.func,
	obras_menu: PropTypes.bool.isRequired,
	account_menu: PropTypes.bool.isRequired,
	obras_by_id: PropTypes.object.isRequired,
	obras_all_ids: PropTypes.array.isRequired,
	obra_selected: PropTypes.number.isRequired,
	changeObra: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
	search_state: PropTypes.number.isRequired,
	search_results: PropTypes.object.isRequired,
	unsetAuth: PropTypes.func.isRequired,
};

TopBar.defaultProps = {
	updateUi: () => {},
};

export default CSSModules(TopBar, styles, { allowMultiple: true });
