import update from 'immutability-helper';
import compras from './store';
import {
	UPDATE_COMPRAS,
	UPDATE_COMPRAS_RAW,
	COMPRAS_REQUESTED,
	COMPRAS_RECEIVED,
	COMPRAS_INITIAL_REQUESTED,
	COMPRAS_INITIAL_RECEIVED,
	COMPRAS_REQUEST_FAILED,
	COMPRA_REQUESTED,
	COMPRA_RECEIVED,
	COMPRA_REQUEST_FAILED,
} from './types';

// Functions
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';
import { isGeneralError, handleGeneralErrors } from '../utility';

const reducer = (state = compras, action) => {
	switch (action.type) {
	case UPDATE_COMPRAS: {
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});

		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});

		default:
			return state;
		}
	}

	case UPDATE_COMPRAS_RAW: {
		const all_fields = Object.keys(action.fields_n_values)
			.reduce((result, current) => ({
				...result,
				[current]: { $set: action.fields_n_values[current] },
			}), {});
		return update(state, all_fields);
	}

	case COMPRAS_REQUESTED: {
		return {
			...state,
			[`RS_${action.tab}`]: 1,
		};
	}

	case COMPRAS_INITIAL_REQUESTED: {
		return {
			...state,
			fetching: 10,
			[`RS_${action.tab}`]: 1,
		};
	}

	case COMPRAS_INITIAL_RECEIVED: {
		const compras_tabs = ['ativas', 'aguardando_entrega_avaliacao', 'finalizadas', 'canceladas', 'em_rascunho']
			.filter(t => t !== action.payload.tab);

		const re = {
			...state,
			fetching: 20,
			[`RS_${action.payload.tab}`]: 2,
			...action.payload.data,
		};

		compras_tabs.forEach(t => {
			re[`RS_${t}`] = 0;
		});

		return re;
	}

	case COMPRAS_RECEIVED: {
		const { data, tab } = action.payload;

		return {
			...state,
			[`RS_${tab}`]: 2,
			[tab]: { ...data },
			current_tab: tab,
		};
	}

	case COMPRAS_REQUEST_FAILED: {
		const error = action.error || {};
		const general_error = isGeneralError(error);
		if (general_error) {
			handleGeneralErrors(error);
			return { ...state };
		}

		return {
			...state,
			fetching: 30,
		};
	}

	case COMPRA_REQUESTED: {
		return {
			...state,
			fetching_compra: 10,
		};
	}

	case COMPRA_RECEIVED: {
		// const data = action.payload.data || [];
		return {
			...state,
			fetching_compra: 20,
		};
	}

	case COMPRA_REQUEST_FAILED: {
		const { status } = (action.error || {}).response || {};
		// 404
		if (status === 404) {
			return { ...state, fetching_compra: 31 };
		}
		// 403
		if (status === 403) {
			return { ...state, fetching_compra: 32 };
		}
		// others
		handleRequestErrors(action.error);
		return { ...state, fetching_compra: 30 };
	}

	default:
		return state;
	}
};

export default reducer;
