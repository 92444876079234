import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import PlaniliaInicio from './PlanilhaInicio';

// Functions
import { _get } from '../../../components/_functions/_requests';

class PlaniliaInicioContainer extends Component {
	constructor() {
		super();
		this.state = {
			loading: 0,
			itens_configurados: 0,
		};
		this.checkLoad = this.checkLoad.bind(this);
	}

	componentDidMount() {
		this.checkLoad(this.props.user);
	}

	UNSAFE_componentWillMount() {
		document.title = 'Integração Planilha - Conaz';
		this.checkLoad(this.props.user);
	}

	checkLoad(user) {
		const { loading } = this.state;
		const { cliente_id } = user;

		if (
			cliente_id !== 0
			&& loading === 0
		) {
			this.setState({ loading: 2 });
			return;
		}

		if (loading !== 1) {
			this.buscaAccountItens(user);
		}
	}

	buscaAccountItens(user) {
		this.setState({ loading: 1 });
		const { cliente_id } = user;
		const url_itens = `/conaz/v2/integracoes/itens_integrados?swi.numero=2&cliente_id=${cliente_id}&per_page=1&fields=id`;
		const all_urls = [
			_get(url_itens, {}, []),
		];
		Promise.all(all_urls).then(responses => {
			this.setState({ loading: 2 });
			const itens = (((responses || [])[0] || {}).data || {}).result || [];

			const itens_configurados = itens
				.filter(o => o.id !== '').length > 0;

			if (itens_configurados) {
				this.setState({ itens_configurados: 1 });
			}
		}).catch(() => {
			this.setState({ loading: 2 });
		});
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>

				<PlaniliaInicio
					{...this.state}
					{...this.props} />

			</LayoutWrapper>
		);
	}
}

PlaniliaInicioContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(PlaniliaInicioContainer));
