import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import LayoutWrapper from '../../components/LayoutWrapper';
import Compras from './Compras';

const ComprasContainer = props => {
	const {
		compras,
		getInitialCompras,
		getCompras,
		updateCompras,
		updateUi,
		user,
	} = props;
	const { fetching, current_tab } = compras;
	const { obra_selected } = user;

	useLayoutEffect(() => {
		if (obra_selected !== 0) {
			updateUi(['changes', 'compras'], false);
			getInitialCompras(obra_selected, current_tab);
		}
	}, [obra_selected]);

	useLayoutEffect(() => {
		// google analytics
		window.ga('set', 'page', window.location.pathname + window.location.search);
		window.ga('send', 'pageview');
		// page title
		document.title = 'Compras - Conaz';
	}, []);

	const openExportaCompras = () => props.updateModals('exporta_compras', true);

	const changeTab = ({ target }) => {
		const tab = target.getAttribute('data-tab');
		if (current_tab !== tab && compras[`RS_${tab}`] === 0) {
			getCompras(obra_selected, tab, 1);
		} else {
			updateCompras(['current_tab'], tab);
		}
		props.updateUi(['tabs', 'compras'], tab);
		window.scroll(0, 0);
	};

	const changePage = ({ selected }) => {
		if (selected + 1 !== compras[compras.current_tab].page.number) {
			getCompras(obra_selected, current_tab, selected + 1);
		}
	};

	return (
		<LayoutWrapper
			fetching={fetching}
			thbackspace_fixed
			{...props}>

			<Compras
				{...props}
				changeTab={changeTab}
				changePage={changePage}
				openExportaCompras={openExportaCompras} />

		</LayoutWrapper>
	);
};

ComprasContainer.propTypes = {
	compras: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	getCompras: PropTypes.func.isRequired,
	getInitialCompras: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
};

const mapStateToProps = props => buildMapStateToProps(props);
const mapDispatchToProps = dispatch => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(ComprasContainer));
