import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
// import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { enviadoEmComprasFormat } from '../../_functions/_editDateFormat';

const CompraRowRealizada = ({ compra }) => {
	// const status = defineStatusCompra(compra);
	const criado_em = compra.criado_em || '';

	const realizada_em_view = criado_em !== ''
		? enviadoEmComprasFormat(criado_em)[0]
		: '';

	const realizada_view = (realizada_em_view)
		? <><span styleName="data">{realizada_em_view}<br styleName="status-line-break" /></span></>
		: 'Sem registro';

	return (
		<div styleName="realizada_em">
			Realizada em
			<p>{realizada_view}</p>
		</div>
	);
};

CompraRowRealizada.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowRealizada, styles, { allowMultiple: true });
