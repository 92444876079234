const itens = {
	itens_compra: {
		by_id: {},
		all_ids: [],
	},
	itens_pedido: {
		by_id: {},
		all_ids: [],
	},
	populares: [],
	adding_item: false,
};

export default itens;
