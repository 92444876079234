import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import StepsV2 from '../../../../components/_parts/StepsV2/StepsV2';
import StepV2 from '../../../../components/_parts/StepsV2/StepV2';
import StepsV2Mobile from '../../../../components/_parts/StepsV2/StepsV2Mobile/StepsV2';
import CurrentStepView from '../../../../components/_parts/StepsV2/StepsV2Mobile/CurrentStepView';

// Functions
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';
import { addZeroToCodigo } from '../../../../components/_functions/_addZeroToCodigo';
import { editEnviadoEmFormat } from '../../../../components/_functions/_editDateFormat';

const CompraStatusBar2 = ({
	compra,
}) => {
	const [steps, setSteps] = useState([]);

	function createBasicStep(_steps, label) {
		const descricao = <></>;
		const ativo = true;
		const step = { label, descricao, ativo };
		setSteps([..._steps, step]);
	}

	function createCancelada(_steps) {
		const label = 'Compra cancelada em';
		const cancelada_em = compra.cancelada_em || null;
		const quem_cancelou = (compra.cancelada_por || {}).nome || '';
		const cancelada_em_view = cancelada_em !== null
			? <span>{editEnviadoEmFormat(cancelada_em)[0]} - {editEnviadoEmFormat(cancelada_em)[1]} <span>por</span> {quem_cancelou}</span>
			: <em styleName="em">Sem registro</em>;
		const descricao = (
			<span>{cancelada_em_view}</span>
		);
		const ativo = true;
		const step = { label, descricao, ativo };
		setSteps([..._steps, step]);
	}

	function createRascunho(_steps) {
		const label = 'Em rascunho';
		createBasicStep(_steps, label);
	}

	function createEmEdicao(_steps) {
		const label = 'Em edição';
		createBasicStep(_steps, label);
	}

	function createEmAprovacao(_steps) {
		const label = 'Aguardando aprovação';
		createBasicStep(_steps, label);
	}

	function createEmAnalise(_steps) {
		const label = 'Aguardando análise Conaz';
		createBasicStep(_steps, label);
	}

	function createAguardandoAprovacaoSienge(_steps) {
		const label = 'Aguardando aprovação no Sienge';
		createBasicStep(_steps, label);
	}

	function createAguardandoConfirmacao(_steps) {
		const label = 'Aguardando confirmação do fornecedor';
		createBasicStep(_steps, label);
	}

	function createEmRefinamento(_steps) {
		const label = 'Em refinamento';
		createBasicStep(_steps, label);
	}

	function createRecusadaFornecedor(_steps) {
		const label = 'Compra recusada pelo fornecedor em';
		const recusada_em = compra.recusada_em || '';
		const recusada_em_view = recusada_em !== ''
			? `${editEnviadoEmFormat(recusada_em)[0]} - ${editEnviadoEmFormat(recusada_em)[1]}`
			: <em styleName="em">Sem registro</em>;
		const descricao = (
			<span>{recusada_em_view}</span>
		);
		const ativo = true;
		const step = { label, descricao, ativo };
		setSteps([..._steps, step]);
	}

	function createConfirmada(_steps) {
		const label = 'Aguardando entrega do material';
		createBasicStep(_steps, label);
	}

	function createEntregaConfirmada(_steps) {
		const label = 'Entrega confirmada';
		createBasicStep(_steps, label);
	}

	function createFinalizada(_steps) {
		const label = 'Compra finalizada';
		createBasicStep(_steps, label);
	}

	function stepStatusAtual(_steps) {
		const status = defineStatusCompra(compra);
		const funcs = {
			cancelada: createCancelada,
			rascunho: createRascunho,
			em_edicao: createEmEdicao,
			em_aprovacao: createEmAprovacao,
			em_analise: createEmAnalise,
			aguardando_aprovacao_sienge: createAguardandoAprovacaoSienge,
			aguardando_confirmacao: createAguardandoConfirmacao,
			em_refinamento: createEmRefinamento,
			recusada_fornecedor: createRecusadaFornecedor,
			confirmada: createConfirmada,
			entrega_confirmada: createEntregaConfirmada,
			finalizada: createFinalizada,
		};
		const fun = funcs[status];
		fun(_steps);
	}

	function stepCompraCriada(_steps) {
		const criado_em = compra.criado_em || '';
		const criado_por_nome = (compra.usuario_construtor || {}).nome || '';
		const label = 'Compra criada em';
		const descricao = (
			<span>{editEnviadoEmFormat(criado_em)[0]} - {editEnviadoEmFormat(criado_em)[1]} <span>por</span> {criado_por_nome}</span>
		);
		const ativo = true;
		const step = { label, descricao, ativo };
		stepStatusAtual([..._steps, step]);
	}

	function stepCotacaoRealizada() {
		const _steps = [];
		const pedido_id = compra.pedido_id || null;
		if (pedido_id !== null) {
			const pedido_codigo = addZeroToCodigo(pedido_id);
			const label = 'Cotação realizada';
			const descricao = (
				<span>
					<Link to={`/cotacao/${pedido_codigo}`}>
						#{pedido_codigo} <span><i className="fa fa-long-arrow-right fa-fw" aria-hidden="true" /> Ver cotação</span>
					</Link>
				</span>
			);
			const ativo = true;
			const step = { label, descricao, ativo };
			_steps.push(step);
		}
		stepCompraCriada(_steps);
	}

	useEffect(() => {
		stepCotacaoRealizada();
	}, [compra.id, compra.status]);

	const current_stage = steps.length;
	const last = steps.length - 1;
	const { status_front } = compra;

	if (steps.length === 0) return null;

	return (
		<>
			<div id="status_bar_compra" styleName="status-bar-wrapper">
				<StepsV2 current={current_stage}>
					{steps.map((step, i) => (
						<StepV2
							key={step.label.toString()}
							label={step.label}
							current={steps.length - 1}
							stage={i}
							steps={steps}
							descricao={step.descricao}
							size={steps.length}
							done={step.done && step.done}
						/>
					))}
				</StepsV2>
			</div>
			<div styleName="status-bar-wrapper mobile">
				<StepsV2Mobile current={current_stage} steps={steps} status_front={status_front}>
					<CurrentStepView
						label={steps[last].label}
						descricao={steps[last].descricao}
						size={steps.length}
						done={steps[last].done && steps[last].done}
						status_front={status_front}
					/>
				</StepsV2Mobile>
			</div>
		</>
	);
};

CompraStatusBar2.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraStatusBar2, styles, { allowMultiple: true });
