/* eslint-disable */
import React from 'react';
import { ActionsArea } from './styled';
import { ReactComponent as EditPen } from '../../icons/edit_pen.svg';
import { ReactComponent as BlockCircle } from '../../icons/block_circle.svg';
import { ReactComponent as VisualizeEye } from '../../icons/visualize_eye.svg';
//
import { formatNumbers } from 'components/_functions/_formatNumbers';
import { documentType } from 'components/_functions/_documentType';
import { Popconfirm, Tooltip } from 'antd';

const emptyCell = '-';

const verificaPersona = (value) => {
	if(value.length <= 11) {
		return 'Física'
	}
	if(value.length > 11) {
		return 'Jurídica'
	}
};

export default (currentUserRole, openDrawer, removeOrBlock, usuarioAdministrador) => [
	{
		title: 'Empresa',
		key: '1',
		dataIndex: 'nome',
		sorter: (a, b) => {
			if (a.nome > b.nome) return 1;
			if (a.nome < b.nome) return -1;
			return 0;
		},
		render: (value) => value || emptyCell,
		align: 'left',
		width: 160,
		ellipsis: true,
	},
	{
		title: 'CNPJ/CPF',
		key: '2',
		dataIndex: 'documento',
		render: (value) => {
			return formatNumbers(value+'', documentType(value+'')) || emptyCell
		},
		sorter: (a, b) => +a.documento - +b.documento,
		width: 120,
		ellipsis: true,
	  },
	{
		title: 'Persona',
		dataIndex: 'documento',
		width: 130,
		key: '3',
		render: (value) => verificaPersona(value) || emptyCell,
		sorter: (a, b) => a.persona - b.persona,
		ellipsis: true,
	},
	{
		title: 'Cidade/UF',
		dataIndex: 'endereco',
		width: 400,
		key: '4',
		render: (value) => `${value.cidade || emptyCell}/${value.estado || emptyCell}` || emptyCell,
		sorter: (a, b) =>  {
			const aTxt = a.endereco.estado + a.endereco.cidade;
			const bTxt = b.endereco.estado + b.endereco.cidade;
			if (aTxt > bTxt) return 1;
			if (aTxt < bTxt) return -1;
			return 0;
		},
		ellipsis: true,
	},
	{
		title: 'Ações',
		dataIndex: 'actions',
		key: '6',
		width: 110,
		render: (_, record) => (
			<ActionsArea>
				{currentUserRole === 'admin' &&
					<>
						<Tooltip placement="top" title={`Editar a empresa ${record.nome || ''}`} color="#0076D6">
						<EditPen onClick={() => usuarioAdministrador === false ? null : openDrawer('edit', record)} />
						</Tooltip>

						<Tooltip placement="top" title="Excluir a empresa" color="#0076D6">
						<Popconfirm
						 title={`Excluir a empresa ${record.nome || ''}`}
						 onCancel={() => {}} 
						 onConfirm={() => removeOrBlock(record)}
						 disabled={usuarioAdministrador === false}
						 cancelText="Cancelar"
						 okText="Sim"
						 >
						<BlockCircle />
						</Popconfirm>
						</Tooltip>
					</>
				}
				<Tooltip placement="top" title="Visualizar a empresa" color="#0076D6">
				<VisualizeEye onClick={() => openDrawer('view', record)} />
				</Tooltip>
			</ActionsArea>
		)
	},
];
