import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Components
import SelectOption from './SelectOption';

class InputSelectGeral extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.default_value,
		};
		this.handleEdition = this.handleEdition.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { default_value } = nextProps;
		if (default_value !== this.state.value) this.setState({ value: default_value });
	}

	handleEdition(opcao) {
		const { field, updateFunction } = this.props;
		this.setState({ value: opcao.valor });
		updateFunction(field, opcao.id);
	}

	render() {
		const { value } = this.state;
		const {
			field,
			field_id,
			opcoes,
			has_outro,
			error,
			tipo,
			handleFixError,
		} = this.props;

		return (
			<div className="dropdown">
				<button
					id={field_id}
					className="btn btn-default input-md dropdown-toggle"
					style={error && tipo === '' ? { border: '1px solid red' } : {}}
					styleName="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="true"
					onClick={() => (field === 'forma'
						? handleFixError('forma_de_pagamento')
						: handleFixError('prazo_de_pagamento'))
					}
					data-dropdown>
					{value}{' '}
					<span
						className="caret"
						styleName="dropdown-caret"
						data-dropdown-2 />
				</button>
				<div
					className="dropdown-menu"
					styleName="dropdown-menu-local"
					data-dropchild>
					{/* eslint-disable react/no-array-index-key */}
					{opcoes.map((option, o) => (
						<SelectOption
							key={o}
							handleEdition={this.handleEdition}
							option={option}
							option_view={value} />
					))}
					{has_outro && (
						<>
							<li
								role="separator"
								className="divider"
								styleName="divider"
								data-dropchild />
							<li data-dropchild>
								<Link
									to="#"
									data-dropchild
									onClick={this.props.handleOutro}>
									Outra opção
								</Link>
							</li>
						</>
					)}
				</div>
			</div>
		);
	}
}

InputSelectGeral.propTypes = {
	field_id: PropTypes.string,
	field: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
	default_value: PropTypes.string,
	// opcoes: PropTypes.array,
	opcoes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			valor: PropTypes.string.isRequired,
		}),
	),
	updateFunction: PropTypes.func,
	handleOutro: PropTypes.func,
	has_outro: PropTypes.bool,
	error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	tipo: PropTypes.string,
	handleFixError: PropTypes.func,
};

InputSelectGeral.defaultProps = {
	handleFixError: () => {},
	field_id: '',
	error: undefined,
	default_value: '',
	opcoes: [],
	updateFunction: () => {},
	handleOutro: () => {},
	has_outro: true,
	tipo: '',
};

export default CSSModules(InputSelectGeral, styles, { allowMultiple: true });
