import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Loading from '../../_loadings/Loading/Loading';
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';
import ToolTip from '../../_errors/ToolTip/ToolTip';

// Functions
import { _get, _post } from '../../../_functions/_requests';
import { handleRequestErrors } from '../../../_functions/_handleRequestErrors';
import { getUserLocalstorageData } from '../../../_functions/_getUserLocalstorageData';

class ModalNotificaDecisor extends Component {
	constructor() {
		super();
		this.state = {
			request_decisores: 1,
			decisores_by_id: {},
			decisores_all_ids: [],
			decisores_selecionados: [],
			mensagem: '',
			alerts: {
				decisores: false,
			},
			enviando: 0,
		};
		this.handleMensagem = this.handleMensagem.bind(this);
		this.validaEnvio = this.validaEnvio.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { pre_mensagem } = this.props;
		this.setState({ mensagem: pre_mensagem });
		this.buscaDecisores();
	}

	setError(error) {
		this.setState({ request_decisores: 3 });
		handleRequestErrors(error);
	}

	closeModal = () => {
		const { enviando } = this.state;
		const { updateModals, tipo, redirectAfterAskAproval } = this.props;
		if (enviando === 2 && tipo === 'aprovacao') {
			updateModals('notifica_decisor', false);
			redirectAfterAskAproval();
			return;
		}
		updateModals('notifica_decisor', false);
	}

	buscaDecisores() {
		const { obra_selected, compra_valor, sobre } = this.props;
		_get(`/conaz/v2/obras/${obra_selected}`).then(response => {
			const user_email = getUserLocalstorageData('email') || '';
			const request_decisores = 2;

			let usuarios = response.data.usuarios
				.filter(u => (
					u.usuario.email !== ''
					&& u.usuario.email !== null
					&& u.usuario.email !== user_email
				));

			if (sobre === 'compra' && compra_valor !== 0) {
				usuarios = usuarios
					.filter(u => (
						u.compra_ilimitada
						|| (!u.compra_ilimitada && u.valor_alcada_compra >= compra_valor)
					));
			}

			if (sobre === 'aprovacao_cotacao') {
				usuarios = usuarios
					.filter(u => u.papeis.some(p => [7].filter(_p => _p === p).length > 0));
			}

			const decisores_all_ids = [];
			const decisores_by_id = {};
			usuarios.forEach(current => {
				decisores_all_ids.push(current.usuario.id);
				decisores_by_id[current.usuario.id] = current.usuario;
			});

			this.setState({ request_decisores, decisores_all_ids, decisores_by_id });
		}).catch(error => this.setError(error));
	}

	selectDecisor(id) {
		const alerts = { ...this.state.alerts, decisores: false };
		const decisores_selecionados = [...this.state.decisores_selecionados, id].filter((el, i, a) => i === a.indexOf(el));
		this.setState({ decisores_selecionados, alerts });
	}

	removeDecisor(id) {
		const alerts = { ...this.state.alerts, decisores: false };
		const decisores_selecionados = this.state.decisores_selecionados.filter(i => i !== id);
		this.setState({ decisores_selecionados, alerts });
	}

	handleMensagem(e) {
		this.setState({ mensagem: e.target.value });
	}

	validaEnvio() {
		const { decisores_selecionados } = this.state;
		if (decisores_selecionados.length === 0) {
			const alerts = { ...this.state.alerts, decisores: true };
			this.setState({ alerts });
			return false;
		}
		this.sendNotificacao();
		return true;
	}

	sendNotificacao() {
		this.setState({ enviando: 1 });
		const { decisores_selecionados, mensagem } = this.state;
		const {
			assunto,
			obra_selected,
			sobre,
			codigo,
			texto_fim_mensagem,
			tipo,
			updateModals,
			redirectAfterAskAproval,
		} = this.props;

		/**
		 * Se for aprovação de pedido
		 */
		if (sobre === 'aprovacao_cotacao') {
			const params = { usuarios_ids: decisores_selecionados };
			_post(`/conaz/v2/pedidos/${codigo}/pedir_aprovacao`, params).then(() => {
				this.setState({ enviando: 2 });
			}).catch(() => {
				this.setState({ enviando: 3 });
			});
			return;
		}

		/**
		 * Se for aprovação de compra
		 */
		if (sobre === 'compra' && tipo === 'aprovacao') {
			const params = { usuarios_ids: decisores_selecionados };
			_post(`/conaz/v2/compras/${codigo}/solicitar_aprovacao`, params).then(() => {
				if (tipo === 'aprovacao') {
					updateModals('notifica_decisor', false);
					redirectAfterAskAproval();
					return;
				}
				this.setState({ enviando: 2 });
			}).catch(() => {
				this.setState({ enviando: 3 });
			});
			return;
		}

		/**
		 * Aqui são todas as outras notificações
		 */
		const params = {
			usuarios_ids: decisores_selecionados,
			assunto,
			mensagem: `${mensagem}\n\n${texto_fim_mensagem}`,
			sobre,
			codigo,
		};
		_post(`/conaz/v2/obras/${obra_selected}/notificar_usuarios`, params).then(() => {
			// if (!response.data.success) throw new Error();
			this.setState({ enviando: 2 });
		}).catch(() => {
			this.setState({ enviando: 3 });
		});
	}

	render() {
		const { request_decisores, decisores_all_ids, decisores_by_id, decisores_selecionados, mensagem, enviando, alerts } = this.state;
		const { titulo_modal, subtitulo_modal, label_botao_enviar } = this.props;

		return (
			<div className="modal-content">
				<div className="modal-header" styleName="modal-header">
					{enviando !== 1 && (
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeModal}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					<h4 className="modal-title">{titulo_modal}</h4>
				</div>

				{request_decisores === 1 && (
					<div className="modal-body" styleName="modal-body">
						<div>
							<div>Carregando pessoas...</div>
							<div styleName="loading-wrapper"><Loading /></div>
						</div>
					</div>
				)}

				{request_decisores === 3 && (
					<div className="modal-body" styleName="modal-body">
						<div styleName="error-wrapper">
							<i className="fa fa-exclamation-circle" aria-hidden="true" />
							Erro ao carregar pessoas!<br />
							Feche esta janela e tente novamente.
						</div>
					</div>
				)}

				{(request_decisores === 2 && enviando !== 2) && (
					<div className="modal-body" styleName="modal-body">
						{alerts.decisores && (
							<ToolTip
								message="Selecione ao menos 1 usuário"
								display="top"
								margin_top="-34px" />
						)}
						<label styleName="title1">{subtitulo_modal}</label>
						{decisores_all_ids.map(id => (
							<div key={id} styleName="decisor">
								{/* decisores_selecionados.find(i => i === id) !== undefined ? ( */}
								{decisores_selecionados.filter(i => i === id).length > 0 ? (
									<button type="button" styleName="checkbox-button checked" onClick={() => this.removeDecisor(id)}>
										<div styleName="checkbox-icon"><i className="fa fa-check" aria-hidden="true" /></div>
									</button>
								) : (
									<button type="button" styleName="checkbox-button" onClick={() => this.selectDecisor(id)}>
										<div styleName="checkbox-icon" />
									</button>
								)}
								{decisores_by_id[id].nome} <span>({decisores_by_id[id].email})</span>
							</div>
						))}
						<label styleName="title2">Mensagem (o link será anexado automaticamente no fim do texto)</label>
						<textarea
							className="form-control"
							rows="5"
							value={mensagem}
							onChange={this.handleMensagem} />
						<p><br /></p>
						{(enviando === 0 || enviando === 3) && (
							<>
								<button
									className="btn btn-clear-conaz round-border"
									onClick={this.closeModal}
									style={{ marginRight: '15px' }}>
									Cancelar
								</button>
								<button
									type="button"
									className="btn btn-secondary-conaz round-border"
									onClick={this.validaEnvio}
									style={{ height: '32px', padding: '6px 30px 5px' }}
								>
									{label_botao_enviar}
								</button>
							</>
						)}
						{enviando === 1 && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading">
								<LoadingButton />Enviar
							</button>
						)}
						{enviando === 3 && (
							<div className="alert alert-danger" role="alert" style={{ marginTop: '25px' }}>
								<i className="fa fa-exclamation-circle fa-fw" aria-hidden="true" /> Erro ao enviar mensagem. Clique em enviar e tente novamente.
							</div>
						)}
					</div>
				)}

				{(request_decisores === 2 && enviando === 2) && (
					<div className="modal-body" styleName="modal-body">
						<div styleName="success-wrapper">
							<i className="fa fa-check" aria-hidden="true" />
							Notificação enviada com sucesso!
						</div>
					</div>
				)}
			</div>
		);
	}
}

ModalNotificaDecisor.propTypes = {
	updateModals: PropTypes.func,
	obra_selected: PropTypes.number.isRequired,
	assunto: PropTypes.string.isRequired,
	pre_mensagem: PropTypes.string.isRequired,
	titulo_modal: PropTypes.string,
	subtitulo_modal: PropTypes.string,
	label_botao_enviar: PropTypes.string,
	// filtrarPorPapeis: PropTypes.array,
	sobre: PropTypes.string.isRequired,
	codigo: PropTypes.string.isRequired,
	texto_fim_mensagem: PropTypes.string.isRequired,
	compra_valor: PropTypes.number,
	tipo: PropTypes.string,
	redirectAfterAskAproval: PropTypes.func,
};

ModalNotificaDecisor.defaultProps = {
	titulo_modal: 'Notificar',
	subtitulo_modal: 'Selecione quem você deseja notificar',
	label_botao_enviar: 'Enviar',
	// filtrarPorPapeis: [],
	updateModals: () => {},
	compra_valor: 0,
	tipo: 'notificacao',
	redirectAfterAskAproval: () => {},
};

export default CSSModules(ModalNotificaDecisor, styles, { allowMultiple: true });
