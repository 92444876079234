const size = {
	mobileS: 320,
	mobileM: 375,
	mobileL: 425,
	tablet: 768,
	laptop: 1024,
	laptopL: 1440,
	desktop: 1980,
	desktopL: 2560,
};

export const device = {
	mobileS: `@media (min-width: ${size.mobileS}px)`,
	mobileM: `@media (min-width: ${size.mobileM}px)`,
	mobileL: `@media (min-width: ${size.mobileL}px)`,
	tablet: `@media (min-width: ${size.tablet}px)`,
	laptop: `@media (min-width: ${size.laptop}px)`,
	laptopL: `@media (min-width: ${size.laptopL}px)`,
	desktop: `@media (min-width: ${size.desktop}px)`,
	desktopL: `@media (min-width: ${size.desktop}px)`,
};

export const device_grouped = {
	mobile: `@media (min-width: ${size.mobileS}px) and (max-width: ${size.mobileL - 1}px)`,
	tablet: `@media (min-width: ${size.mobileL + 1}px) and (max-width: ${size.laptop - 1}px)`,
	laptop: `@media (min-width: ${size.laptop}px) and (max-width: ${size.laptopL - 1}px)`,
	desktop: `@media (min-width: ${size.desktop}px) and (max-width: ${size.desktopL - 1}px)`,
};
