import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginate';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Component
import PlanilhaItensRow from '../../../components/_parts/PlanilhaItensRow/PlanilhaItensRow';
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';
import SelecionaArquivo from './SelecionaArquivo';

const PlanilhaItens = ({
	// =========== state
	request_state,
	itens,
	search,
	itemsPage,
	show_tabelas_update,
	error,
	error_message,
	// =========== funcs
	handleSelectedItem,
	handlePageChange,
	handleFileSelect,
}) => {
	const { activePage, pages } = itemsPage;

	if (request_state === 1) {
		return <LoadingPage />;
	}

	if (request_state === 10) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12" styleName="mt-2">
						<LoadingPage small_pad_top />
						<h4 style={{ textAlign: 'center', margin: '20px 0' }}>Sincronizando seus itens do Planilha</h4>
						<p style={{ textAlign: 'center', fontSize: '16px' }}>Aguarde enquanto salvamos todos os seus itens do Planilha em nosso sistema.<br /> Dependendo do número de itens, <b>este processo pode demorar alguns minutos</b>.</p>
					</div>
				</div>
			</div>
		);
	}

	if (show_tabelas_update) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<SelecionaArquivo
							itens={itens}
							handleFileSelect={handleFileSelect}
							error={error}
							error_message={error_message} />
					</div>
				</div>
			</div>
		);
	}

	if (itens.length === 0) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="empty-wrapper">
							<i className="fa fa-table" aria-hidden="true" />
							<h4>Nenhum insumo encontrado.</h4>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-xs-12" styleName="mt-2" style={{ marginTop: '1em' }}>
					{itens.map(item => (
						<PlanilhaItensRow
							key={item.key}
							item={item}
							handleSelectedItem={handleSelectedItem}
							border />
					))}
				</div>
			</div>
			{search === '' && (
				<div styleName="pagination-area">
					<Pagination
						containerClassName="pagination"
						activeClassName="active"
						forcePage={activePage - 1}
						pageCount={pages}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						nextLabel=">"
						previousLabel="<"
						breaklabel="..."
						onPageChange={handlePageChange} />
				</div>
			)}
			<div className="row">
				<div className="col-xs-12">
					<p><br /></p><p><br /></p><p><br /></p>
				</div>
			</div>
		</div>
	);
};

PlanilhaItens.propTypes = {
	// =========== state
	request_state: PropTypes.number.isRequired,
	itens: PropTypes.array.isRequired,
	search: PropTypes.string.isRequired,
	itemsPage: PropTypes.object.isRequired,
	show_tabelas_update: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	error_message: PropTypes.string.isRequired,
	// =========== store
	// =========== local funcs
	handleSelectedItem: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleFileSelect: PropTypes.func.isRequired,
};

export default CSSModules(PlanilhaItens, styles, { allowMultiple: true });
