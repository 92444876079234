import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Form, Col, Select, Row, InputNumber, Checkbox } from 'antd';

// Redux HOC
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

import papeisOptions from './papeisOptions';

function copyUsuarioForm({ form, ...props }) {
	const {
		obras,
		getObras,
		handleHaveChangedAssociaUsuario,
	} = props;

	const [usuariosOpt, setUsuarioOpt] = useState([]);

	const handleSelectedObra = (_, opt) => {
		setUsuarioOpt(opt.data.usuarios.map((p) => ({ data: p, label: p.usuario.nome, value: p.id })));
	};

	const handleSelectUser = (_, { data }) => {
		const fields = {
			copy_papeis: data.papeis,
			copy_valor_alcada_compra: data.valor_alcada_compra,
			copy_compra_ilimitada: data.compra_ilimitada,
			copy_usuario_id: data.id,
		};
		form.setFieldsValue(fields);
		handleHaveChangedAssociaUsuario(true);
	};

	useEffect(() => {
		if (!obras.obras.length && !obras.isFetching) getObras();
	}, [obras]);

	useEffect(() => () => {
		form.setFieldsValue({
			copy_papeis: undefined,
			copy_obra: undefined,
			copy_valor_alcada_compra: undefined,
			copy_compra_ilimitada: undefined,
			copy_usuario: undefined,
			copy_usuario_id: undefined,
		});
	}, []);

	return (
		<>
			<Form.Item name="copy_usuario_id" noStyle />
			<Col span={12} style={{ marginTop: 15 }}>
				<Form.Item name="copy_obra" label="De qual obra deseja copiar?">
					<Select
						placeholder="Selecione a obra"
						onChange={handleSelectedObra}
						options={obras.obras.map((ob) => ({ label: ob.nome, value: ob.id, data: ob }))}
						disabled={obras.isFetching}
					/>
				</Form.Item>
			</Col>
			<Col span={12}>
				<Form.Item name="copy_usuario" label="Nome do Usuário">
					<Select
						options={usuariosOpt}
						onChange={handleSelectUser}
						disabled={obras.isFetching}
						placeholder="Selecione um usuário"
					/>
				</Form.Item>
			</Col>
			<Col span={12}>
				<Form.Item name="copy_papeis" label="Papéis">
					<Select placeholder="Selecione o tipo" options={papeisOptions} mode="multiple" disabled />
				</Form.Item>
			</Col>
			<Row gutter={12}>
				<Col span={4}>
					<Form.Item name="copy_valor_alcada_compra" label="Alçada">
						<InputNumber min={0} className="input-number-width" placeholder="R$" disabled />
					</Form.Item>
				</Col>
				<Col span={16}>
					<Form.Item label=" " name="copy_compra_ilimitada" valuePropName="checked">
						<Checkbox disabled>
							Ilimitado para alçada de compra
						</Checkbox>
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}

copyUsuarioForm.propTypes = {
	form: PropTypes.shape(),
	obras: PropTypes.shape(),
	getObras: PropTypes.func,
	handleHaveChangedAssociaUsuario: PropTypes.func,
};

copyUsuarioForm.defaultProps = {
	form: {},
	obras: {},
	getObras: () => {},
	handleHaveChangedAssociaUsuario: () => {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(copyUsuarioForm));
