import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Form, Button } from 'antd';
import CreateObraForm from 'components/CreateObraForm';

import { DrawerFooter, StyledDrawer } from '../styled';

// Redux HOC
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

const modes = {
	NEW: 'new',
	EDIT: 'edit',
	VIEW: 'view',
};


const EditObra = ({ ...props }) => {
	const [form] = Form.useForm();
	const {
		user,
		associaObra,
		closeEditObraSelecionada,
		editObraSelecionado,
	} = props;


	const getObraFormInfo = () => {
		const {
			obra_nome,
			obra_id,
			tipo_projeto,
			metragem,
			quantidade_uh,
			padrao_acabamento,
			metodo_construtivo,
			fase_atual,
			cep,
			cidade,
			estado,
			logradouro,
			numero,
			bairro,
			complemento,
			ibge,
			fotos_obra,
		} = form.getFieldsValue();

		return {
			obra_id, // serve para edição.
			endereco: {
				cep: cep.replace('-', ''),
				cidade,
				estado,
				numero,
				bairro,
				complemento,
				logradouro,
			},
			codigo_ibdge: ibge,
			nome: obra_nome,
			quantidade: quantidade_uh || '',
			padrao: padrao_acabamento || '',
			metodo_construtivo,
			fase_atual,
			tipo: tipo_projeto,
			metragem,
			cliente_id: user.cliente_id,
			fotos_obra: fotos_obra || [],
		};
	};

	// função que chama submit do Form. Serve para trigger as validações dos inputs.
	const requestSubmitEdit = () => {
		form.submit();
	};

	// função chamada dps de requestSubmitEdit (caso não tenha problema em algum campo).
	const handleSubmit = () => {
		editObraSelecionado(getObraFormInfo());
	};

	const Footer = () => (
		<DrawerFooter>
			<div className="footer-form">
				<DrawerFooter.Actions>
					<Button onClick={closeEditObraSelecionada} disabled={associaObra.actionFetching}>
						Cancelar
					</Button>
					<Button className="submit-btn" type="primary" onClick={requestSubmitEdit} disabled={associaObra.actionFetching}>
						Salvar
					</Button>
				</DrawerFooter.Actions>
			</div>
		</DrawerFooter>
	);

	useEffect(() => {
		if (associaObra.showEditObraDrawer) {
			const { id: obra_id, codigo_ibdge, nome, anexos, endereco, metragem, unidades_construtivas } = associaObra.obra || {};
			const { tipo, padrao, metodo_construtivo, fase_atual, quantidade } = unidades_construtivas[0] || {};
			const { bairro, cep, cidade, complemento, estado, logradouro, numero } = endereco || {};
			form.setFieldsValue({
				obra_id,
				obra_nome: nome,
				tipo_projeto: tipo,
				quantidade_uh: quantidade,
				metragem,
				metodo_construtivo,
				fase_atual,
				padrao_acabamento: padrao,
				// endereco
				ibge: codigo_ibdge,
				cep,
				cidade,
				estado,
				bairro,
				logradouro,
				numero,
				complemento,
				fotos_obra: (anexos || []).map((a) => ({ ...a, uid: a.id, name: a.nome, status: 'done' })),
			});
		}
	}, [associaObra.showEditObraDrawer]);

	return (
		<StyledDrawer
			title="Edição de obra"
			placement="right"
			width="1060"
			destroyOnClose
			onClose={closeEditObraSelecionada}
			visible={associaObra.showEditObraDrawer}
			footer={<Footer />}
		>
			<Form form={form} className="ant-form-padding" layout="vertical" onFinish={handleSubmit}>
				<CreateObraForm mode={modes.EDIT} form={form} {...props} associaObra={associaObra} disableAll={associaObra.actionFetching} enableFields />
			</Form>
		</StyledDrawer>
	);
};

EditObra.propTypes = {
	user: PropTypes.shape().isRequired,
	associaObra: PropTypes.shape().isRequired,
	editObraSelecionado: PropTypes.func.isRequired,
	closeEditObraSelecionada: PropTypes.func.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(EditObra));
