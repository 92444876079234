import styled from 'styled-components';

const getDesativadoColor = (status) => {
	if (status === 20) return '#52C41A';
	if (status === 10) return '#F5222D';
	if (status === 30) return '#2F54EB';
	return 'black';
};

export const Container = styled.div`
	font-family: Roboto;

	@media (max-width: 600px) {
		.cvCAgK {
			display: grid !important;
			grid-template-columns: repeat(2, 1fr) !important;
			margin-bottom: 2rem !important;
			gap: 20px !important; 
		}

		.ant-table {
			max-width: 100%;
			max-height: 100%;
			resize: none;
			overflow-x: auto;
		}
	}
	.altera-status {
		display: none;

		&.ativo,
		&.ativo-visualizacao {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			border-top: 1px solid #D9D9D9;
			border-left: 1px solid #D9D9D9;
			border-right: 1px solid #D9D9D9;
			border-bottom: 1px solid #D9D9D9;
			background: #FFFF;
			position: relative;
			float: right;
			padding: 47.5px;
			padding-top: 15px;
			padding-bottom: 0px;
			z-index: 1;
			> a {
				margin-bottom: 1.5rem;
				color: #595959;
				font-style: normal;
				font-weight: 300;
				font-size: 15px
			}
		}
		&.ativo-visualizacao {
			padding: 91px !important;
			padding-top: 0px !important;
			padding-bottom: 0px !important;
			> a {
				margin-top: 1rem !important;
			}
		}
		&.ativo:hover,
		&.ativo:focus {
			background-color: #D9EEFF;
		}
	}
`;

export const Content = styled.div`
	padding: 32px;
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	/* padding: 27px 56px 27px 32px; */
	height: 72px;
	background: #FFFFFF;

	.header-back-btn {
		cursor: pointer;
	}

	.header-obra-name {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-left: 3rem;
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 28px;
		color: #595959;
	}

	.header-obra-status {
		height: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		text-align: center;
		gap: 28px;

		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 21px;
		color: #595959;
		
		.header-status {
			height: 100%;
			.button-status {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;
				border: none;
				background-color: #FFFFFF;
				border-left: 1px solid #D9D9D9;
				> button {
					background: none;
    				border: none;
				}
				> .status-icon {
					margin-left: 15px;
    				margin-right: 10px;
					padding: 0px !important;
				}
			}
		}
		}
	}
`;

export const StatusIcon = styled.div`
	background-color: ${({ $status }) => getDesativadoColor($status)};
	border-radius: 33px;
	width: 6px;
	height: 6px;
`;

export const ObraInfos = styled.div`
	/* padding: 32px 32px 44px 32px; */
	margin-bottom: 40px;
`;

ObraInfos.BlockTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 12px;

	& > span {
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: #8C8C8C;
	}

	.info-edit-btn {
		display: flex;
		align-items: center;
		padding: 4px 16px 2px !important;

		background: transparent;
		border-color: #0076D6;
		color: #0076D6;
		&:hover {
			color: #3299ed;
			border-color: #3299ed;
		}
	}
`;

ObraInfos.Line = styled.div`
	display: flex;
	align-items: center;
	gap: 64px;
`;


