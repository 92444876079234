import moment from 'moment';
import 'moment/locale/pt-br';

export const handleObrigatorio = (field, name, errors, alias = null) => {
	if (field === '' || field === null || field === undefined) {
		const nome = alias || name.replace(new RegExp('_', 'g'), ' ');
		const aux = nome.match(/a|as$/g) ? 'a' : 'o';
		return { ...errors, [name]: `${nome} obrigatóri${aux}` };
	}
	return { ...errors };
};

export const handleZerado = (field, name, errors, alias = null, msg = null) => {
	const nome = alias || name.replace(new RegExp('_', 'g'), ' ');
	if (field === 0) return { ...errors, [name]: msg || `${nome} não pode ter valor 0` };
	return { ...errors };
};

export const handleDataInferior = (field, name, errors, alias = null) => {
	const now = moment();
	const data = moment(field);

	const nome = alias || name.replace(new RegExp('_', 'g'), ' ');

	if (data.diff(now, 'days') < 0) {
		return {
			...errors,
			[name]: `${nome} não pode ser anterior a data atual`,
		};
	}
	return { ...errors };
};

export const handleLimite = (value, limit, name, errors, alias = null) => {
	const nome = alias || name.replace(new RegExp('_', 'g'), ' ');
	if (Array.isArray(limit)) {
		const [acima, abaixo] = limit;
		if (value > acima) {
			return {
				...errors,
				[name]: `${nome} não pode ser acima de ${acima}`,
			};
		}

		if (value < abaixo) {
			return {
				...errors,
				[name]: `${nome} não pode ser abaixo de ${abaixo}`,
			};
		}
	}

	if (value > limit) {
		return {
			...errors,
			[name]: `${nome} não pode ser acima de ${limit}`,
		};
	}
	return { ...errors };
};
