import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import { validations } from 'ambar-ui';

// Components
import { Form, Select, Row, Col, InputNumber, Checkbox, Button } from 'antd';
import { StyledDrawer, DrawerFooter } from '../styled';

// Redux HOC
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

import papeisOptions from './papeisOptions';

// Icon
import { ReactComponent as Exclamacao } from '../../../../icons/exclamacao_outlined.svg';

import styles from './style.module.scss';

const { validateRequired } = validations;

function AssociaUsuario({ ...props }) {
	const [form] = Form.useForm();
	const {
		usuarios,
		associaObra,
		getUsersByClient,
		associaUsuarioCloseDrawer,
		associaUsuarioObraSelecionada,
	} = props;
	const [searchUserTxt, setSearchUserTxt] = useState('');
	const editMode = associaObra.usuarioToEdit;
	const userRoleLink = 'https://produtosautodoc.zendesk.com/hc/pt-br/articles/4416789938323-Como-associar-usu%C3%A1rios-a-uma-obra-';

	const disableAll = () => {
		if (!editMode) return associaObra.actionFetching || usuarios.isFetching;
		if (editMode && associaObra.actionFetching) return true;
		return false;
	};

	const handleUserSearch = (txt) => {
		setSearchUserTxt(txt);
	};

	const usersOptions = usuarios.users.map((u) => ({ value: u.id, label: u.nome }));

	const requestSubmitEdit = () => {
		form.submit();
	};

	const handleSubmit = () => {
		const {
			usuario,
			papeis,
			valor_alcada_compra,
			compra_ilimitada,
		} = form.getFieldsValue();
		const { id: obra_id } = associaObra.obra;
		associaUsuarioObraSelecionada({ user_id: usuario.value, papeis, valor_alcada_compra, compra_ilimitada, obra_id }, editMode);
	};

	const Footer = () => (
		<DrawerFooter>
			<div className="footer-form">
				<DrawerFooter.Actions>
					<Button onClick={associaUsuarioCloseDrawer} disabled={associaObra.actionFetching || usuarios.isFetching}>
						Cancelar
					</Button>
					<Button className="submit-btn" type="primary" onClick={requestSubmitEdit} disabled={associaObra.actionFetching || usuarios.isFetching}>
						Salvar
					</Button>
				</DrawerFooter.Actions>
			</div>
		</DrawerFooter>
	);

	useEffect(() => {
		if (!usuarios.isFetching && !usuarios.users.length) {
			getUsersByClient();
		}
	}, []);

	useEffect(() => {
		if (associaObra.showAssociaUsuarioDrawer && associaObra.usuarioToEdit) {
			const {
				usuario,
				papeis,
				compra_ilimitada,
				valor_alcada_compra,
			} = associaObra.usuarioToEdit;
			form.setFieldsValue({
				usuario: { value: usuario.id, label: usuario.nome },
				papeis,
				compra_ilimitada,
				valor_alcada_compra,
			});
		}
	}, [associaObra.showAssociaUsuarioDrawer]);

	return (
		<StyledDrawer
			title="Associação de Usuário"
			placement="right"
			destroyOnClose
			onClose={associaUsuarioCloseDrawer}
			width={620}
			visible={associaObra.showAssociaUsuarioDrawer}
			footer={<Footer />}
		>
			<div styleName="user-user-role-warn" style={{ margin: 0 }}>
				<Exclamacao />
				<div style={{ display: 'block', alignItems: 'center', marginTop: '5px' }}>
					<a styleName="link-roles" href={userRoleLink}>
						Entenda como associar seus usuários em nossa central de ajuda.
					</a>
				</div>
			</div>

			<Form form={form} onFinish={handleSubmit} className="ant-form-padding" layout="vertical">
				<Row className="usuario-associa-row">
					<Col span={24}>
						<Form.Item
							name="usuario"
							label="Usuário"
							rules={[validateRequired(), { validator: (_, value, callback) => {
								if (!usersOptions.length && searchUserTxt) return callback('- Usuário não cadastrado');
								callback();
							} }]}
							className="usuario-error-position-top"
						>
							<Select
								showSearch
								labelInValue
								showArrow={false}
								notFoundContent="Nenhum usuário encontrado"
								onSearch={handleUserSearch}
								filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								disabled={associaObra.actionFetching || usuarios.isFetching || editMode}
								options={usersOptions}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Form.Item name="papeis" label="Papéis" rules={[validateRequired()]}>
							<Select placeholder="Selecione o tipo" options={papeisOptions} mode="multiple" disabled={disableAll()} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={12}>
					<Col span={8}>
						<Form.Item shouldUpdate={(prev, cur) => prev.compra_ilimitada !== cur.compra_ilimitada}>
							{({ getFieldValue }) => (
								<Form.Item
									name="valor_alcada_compra"
									label="Alçada"
									initialValue={0}
									rules={[validateRequired()]}
								>
									<InputNumber
										min={0}
										className="input-number-width"
										placeholder="R$"
										disabled={getFieldValue('compra_ilimitada') || usuarios.isFetching}
									/>
								</Form.Item>
							)}
						</Form.Item>
					</Col>
					<Col span={16}>
						<Form.Item label=" " name="compra_ilimitada" valuePropName="checked" initialValue={false}>
							<Checkbox disabled={disableAll()}>
								Ilimitado para alçada de compra
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</StyledDrawer>
	);
}

AssociaUsuario.propTypes = {
	associaObra: PropTypes.shape().isRequired,
	usuarios: PropTypes.shape().isRequired,
	getUsersByClient: PropTypes.func.isRequired,
	associaUsuarioCloseDrawer: PropTypes.func.isRequired,
	associaUsuarioObraSelecionada: PropTypes.func.isRequired,
};

const WithStyles = CSSModules(AssociaUsuario, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));

