import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const TabsFornecedor = (props) => {
	const children = React.Children
		.map(props.children, (child, i) => (
			React.cloneElement(child, { stage: i + 1, ...props })
		));

	return (
		<div styleName="tabs-wrapper">
			{children}
		</div>
	);
};

TabsFornecedor.propTypes = {
	children: PropTypes.array.isRequired,
};

export default CSSModules(TabsFornecedor, styles, { allowMultiple: true });
