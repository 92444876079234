import {
	UPDATE_COTACAO,
	UPDATE_COTACAO_MULTI,
	UPDATE_ITEM,
	UPDATE_ITEM_CARACTERISTICA,
	REMOVE_ITEM,
	UNDO_REMOVE_ITEM,
	UPDATE_ITEM_PROPRIEDADES,
	UPDATE_ITEM_VALOR_PREENCHIDO,
	CLEAR_SEARCH,
} from './types';

export const updateCotacao = (field, value) => ({ type: UPDATE_COTACAO, field, value });
export const updateCotacaoRaw = fields_n_values => ({ type: UPDATE_COTACAO_MULTI, fields_n_values });
export const updateItem = (item_key, field, value) => ({ type: UPDATE_ITEM, item_key, field, value });
export const updateItemCaracteristica = (item_key, ca_id, value) => ({ type: UPDATE_ITEM_CARACTERISTICA, item_key, ca_id, value });
export const removeItem = item => ({ type: REMOVE_ITEM, item });
export const undoRemoveItem = item => ({ type: UNDO_REMOVE_ITEM, item });
export const updateItemPropriedades = (item_key, resposta_key, tipo_campo, value) => ({
	type: UPDATE_ITEM_PROPRIEDADES, item_key, resposta_key, tipo_campo, value,
});
export const updateItemValorPreenchido = (item_key, resposta_key, tipo_campo, value) => ({
	type: UPDATE_ITEM_VALOR_PREENCHIDO, item_key, resposta_key, tipo_campo, value,
});
export const clearSearch = () => ({ type: CLEAR_SEARCH });
