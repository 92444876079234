import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';
import LoadingButton from '../../../components/_parts/_loadings/LoadingButton/LoadingButton';

const SiengeLogin = ({
	// =========== state
	loading,
	senha,
	dominio,
	username,
	user_sienge,
	logging_state,
	infoMsg,
	// =========== store
	// =========== local funcs
	handleSubmit,
	handleEdition,
	// =========== prop funcs
}) => {
	if (loading === 1) {
		return <LoadingPage />;
	}

	return (
		<div className="container">
			<div styleName="center-position p-top">
				<div styleName="card-login">
					<form onSubmit={handleSubmit}>
						<div className="form-group">
							<label>Domínio <span style={{ color: 'red' }}>*</span></label>
							<div>
								<input
									styleName="d-inline-block w-input-dominio"
									type="text"
									className="form-control input-md"
									placeholder="Domínio"
									value={dominio}
									id="dominio"
									onChange={handleEdition}
									maxLength={100} />
								<div styleName="d-inline-block">
									<span>&nbsp;.sienge.com.br</span>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label>Usuário da API <span style={{ color: 'red' }}>*</span></label>
							<input
								type="text"
								className="form-control input-md"
								placeholder="Usuário"
								id="username"
								value={username}
								onChange={handleEdition}
								maxLength={100} />
						</div>
						<div className="form-group">
							<label>Senha da API <span style={{ color: 'red' }}>*</span></label>
							<input
								type="password"
								className="form-control input-md"
								id="senha"
								value={senha}
								onChange={handleEdition}
								placeholder="Senha"
								maxLength={100} />
						</div>

						<div className="form-group">
							<label>Usuário pessoal do Sienge (Código)</label>
							<input
								type="text"
								className="form-control input-md"
								id="user_sienge"
								value={user_sienge}
								onChange={handleEdition}
								maxLength={100} />
						</div>

						{logging_state === 3 && (
							<div className="alert alert-danger" role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> {infoMsg}
							</div>
						)}

						{logging_state === 1 ? (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading">
								<LoadingButton />Entrar
							</button>
						) : (
							<button
								type="submit"
								className="btn btn-secondary-conaz round-border">
								Entrar
							</button>
						)}
					</form>
				</div>
			</div>
		</div>
	);
};

SiengeLogin.propTypes = {
	loading: PropTypes.number.isRequired,
	username: PropTypes.string.isRequired,
	user_sienge: PropTypes.string.isRequired,
	senha: PropTypes.string.isRequired,
	infoMsg: PropTypes.string.isRequired,
	logging_state: PropTypes.number.isRequired,
	dominio: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleEdition: PropTypes.func.isRequired,
};

export default CSSModules(SiengeLogin, styles, { allowMultiple: true });
