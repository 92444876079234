import { takeLatest, put } from 'redux-saga/effects';
import {
	USER_REQUESTED,
	USER_RECEIVED,
	USER_REQUEST_FAILED,
} from './types';

// Services
import { get } from '../../services/get';

export function* fetchUser() {
	try {
		const url = '/conaz/v2/auth/me';
		const payload = yield get(url);
		yield put({ type: USER_RECEIVED, payload });
	} catch (error) {
		yield put({ type: USER_REQUEST_FAILED, error });
	}
}

// saga
export default function* userSaga() {
	yield takeLatest([USER_REQUESTED], fetchUser);
}
