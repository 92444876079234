import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputNumber from '../../_inputs/InputNumber/InputNumber';

// Functions
import { formatCurrency } from '../../../_functions/_formatCurrency';
import { calcValorItem } from '../_subfunctions/calcValorItem';

class IEValores extends Component {
	static getDerivedStateFromProps(props) {
		const { item } = props;
		const { errors } = item.front || {};
		const error = (errors || [])
			.filter(e => e.type === 'preco_unitario')
			.length > 0;
		return { error };
	}

	state = {
		error: false,
	};

	updatePrecoUnitario = (item_id, field, value) => {
		const { error } = this.state;
		const { item, updateFullItemById } = this.props;
		const front = !error
			? { ...item.front }
			: {
				...item.front,
				errors: (item.front.errors || [])
					.filter(e => e.type !== 'preco_unitario'),
			};
		const new_item = !error
			? { ...item, [field]: value }
			: { ...item, [field]: value, front };
		updateFullItemById(item_id, new_item);
		this.setState({ error: false });
	}

	render() {
		const { error } = this.state;
		const { item, autoSave } = this.props;
		const { id } = item;
		const preco_unitario = item.preco_unitario || 0;
		const desconto = item.desconto || 0;
		const valores = calcValorItem(item);
		const { total } = valores;

		const form_group_class = error
			? 'form-group has-error'
			: 'form-group';
		const label_class = error
			? 'label has-error'
			: 'label';

		return (
			<>
				<div styleName="form-group-resposta valor-unitario">
					<div className={form_group_class}>
						{/* error && (
							<div className="tooltip top in" role="tooltip" style={{ marginTop: '-34px' }}>
								<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
								<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Este campo é obrigatório</div>
							</div>
						) */}
						<label htmlFor={`preco_unitario-${id}`} styleName={label_class}>
							Valor unitário <span styleName="obrigatorio-label">*</span>
						</label>
						<InputNumber
							type="item"
							field_id={`preco_unitario-${id}`}
							item_key={id}
							field="preco_unitario"
							default_value={preco_unitario}
							updateFunction={this.updatePrecoUnitario}
							autoSave={autoSave}
							icon="R$"
							icon_width={40}
							zerar_erros={false} />
					</div>
				</div>
				<div styleName="form-group-resposta desconto">
					<div className="form-group">
						<label htmlFor={`desconto-${id}`}>Desconto unitário</label>
						<InputNumber
							type="item"
							field_id={`desconto-${id}`}
							item_key={id}
							field="desconto"
							default_value={desconto}
							updateFunction={this.updatePrecoUnitario}
							autoSave={autoSave}
							icon="%"
							icon_width={36}
							zerar_erros={false} />
					</div>
				</div>
				<div styleName="form-group-resposta valor-total">
					<div className="form-group">
						<label>Total</label>
						<p styleName="total-view">{formatCurrency(total, 2, 'R$')}</p>
					</div>
				</div>
			</>
		);
	}
}

IEValores.propTypes = {
	item: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IEValores, styles, { allowMultiple: true });
