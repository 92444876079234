import INITIAL_STATE from './store';
import * as Types from './types';

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.ADRESS_REQUEST:
		return {
			...state,
			adressFetching: true,
			adressError: false,
		};
	case Types.ADRESS_SUCCESS:
		return {
			...state,
			adressFetching: false,
			adress: action.payload.adress,
			adressError: false,
		};
	case Types.ADRESS_ERROR:
		return {
			...state,
			adressFetching: false,
			adressError: action.payload.erro_motivo,
		};
	default:
		return state;
	}
}
