import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const ModalItemAnaliseMobile = ({ updateModals, item_order, item_selected_mobile_modal }) => {
	const item_de_pedido = item_selected_mobile_modal.item_de_pedido || {};
	const nome = ((item_de_pedido.item_preenchido || {}).item || {}).nome || '';
	const quantidade = item_selected_mobile_modal.quantidade || 0;
	const unidade = item_selected_mobile_modal.unidade || 'un';
	const c_ordenadas = ((item_de_pedido.item_preenchido || {}).caracteristicas_preenchidas || [])
		.filter(c => c.caracteristica.visao_construtor !== 0)
		.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

	return (
		<div className="modal-content" styleName="modal-content">
			<div className="modal-body" styleName="modal-body">
				<button
					type="button"
					className="close"
					styleName="close-button"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => updateModals('item_analise_mobile', false)}>
					<span aria-hidden="true">&times;</span>
				</button>
				<div styleName="nome"><span styleName="ordem">{item_order}</span> - {nome}</div>
				<div styleName="caracteristica">
					<span>Quantidade: </span> {quantidade} {unidade}
				</div>
				{c_ordenadas.map((c, i2) => {
					const c_nome = (c.caracteristica || {}).nome || '';
					const valor = (c.opcoes_preenchidas || []).map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
					return valor !== '' ? ( // eslint-disable-next-line react/no-array-index-key
						<div key={i2} styleName="caracteristica"><span>{c_nome}:</span> {valor}</div>
					) : false;
				})}
				{item_de_pedido.observacoes !== '' && (
					<div styleName="caracteristica">
						<span>Observações: </span> {item_de_pedido.observacoes}
					</div>
				)}
				{item_de_pedido.observacoes_internas !== '' && (
					<div styleName="caracteristica">
						<span>Observações internas: </span> {item_de_pedido.observacoes_internas}
					</div>
				)}
			</div>
		</div>
	);
};

ModalItemAnaliseMobile.propTypes = {
	updateModals: PropTypes.func,
	item_order: PropTypes.number,
	item_selected_mobile_modal: PropTypes.object,
};

ModalItemAnaliseMobile.defaultProps = {
	updateModals: () => {},
	item_order: -1,
	item_selected_mobile_modal: {},
};

export default CSSModules(ModalItemAnaliseMobile, styles, { allowMultiple: true });
