export default [
	'Alvenaria convencional',
	'Alvenaria estrutural',
	'Parede de Concreto',
	'Wood frame',
	'Concreto pré-moldado',
	'Estruturas de aço',
	'Módulos Steel frame e Drywall',
	'Módulos Wood frame',
];
