const eh_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

export const VERIFICA_RESTRICOES = eh_triagem;
export const ADICIONAR_RC_AO_CHECKITEM = eh_triagem;
export const ALERTAR_PEDIDO_SEM_PROSPECCAO_EXTRA_FORNECEDORES = eh_triagem;
export const LIMITAR_NUMERO_FORNECEDORES = !eh_triagem;
export const SELECAO_FORNECEDOR_CONAZ = eh_triagem;
export const ORIGEM_CADASTRO_FORNECEDOR_CONAZ = eh_triagem;
export const HABILITAR_MODAL_PERFIL_CLIENTE = eh_triagem;
export const OCULTAR_VENDEDORES_SEM_CADASTRO_PLATAFORMA_NAO_CADASTRADOS_PELO_CONSTRUTOR = !eh_triagem;
export const FILTRA_RCS_NAO_RESPONDIDOS_CRIADOS_PELO_FORNECEDOR = !eh_triagem;
export const DESTACAR_RCS_CRIADOS_PELO_FORNECEDOR = eh_triagem;
export const SUBSTITUIR_TIPO_SELECAO_RC_CRIADO_FORNECEDOR = !eh_triagem;
export const DESTACAR_FORNECEDOR_DO_SISTEMA_PARA_CONSTRUTOR = !eh_triagem;
export const DESTACAR_FORNECEDOR_DO_SISTEMA_PARA_INTERNO = eh_triagem;

export const ID_CLIENTE_CONAZ = 10;
