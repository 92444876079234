import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import createCompraHoc from '../../../../hocs/createCompraHoc/createCompraHoc';
import LoadingButton from '../../../../components/_parts/_loadings/LoadingButton/LoadingButton';
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';

class CriarCompraQCCButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal_nao_aceita_parcial: false,
		};
		this.checkAceitaCompraParcial = this.checkAceitaCompraParcial.bind(this);
		this.closeModalAceitaParcial = this.closeModalAceitaParcial.bind(this);
		this.criaCompra = this.criaCompra.bind(this);
	}

	checkAceitaCompraParcial() {
		const { rc_a } = this.props;
		const condicoes_de_pagamento = (rc_a.rc || {}).condicoes_de_pagamento || [];
		const condicao_selecionada_id = rc_a.condicao_selecionada_id || null;
		const condicao_selecionada = condicao_selecionada_id !== null
			? condicoes_de_pagamento.filter(x => x.id === condicao_selecionada_id)[0]
			: condicoes_de_pagamento[0];

		const { aceita_parcial } = (condicao_selecionada || {});
		const compra_minima = (condicao_selecionada || {}).compra_minima || 0;

		/**
		 * Não aceita compra parcial
		 */
		if (aceita_parcial === 0) {
			this.setState({ modal_nao_aceita_parcial: true });
			return;
		}

		/**
		 * Aceita mas depende do valor
		 */
		if (
			aceita_parcial === 2 &&
			compra_minima > rc_a.preco_selecionado_rc
		) {
			this.setState({ modal_nao_aceita_parcial: true });
			return;
		}

		/**
		 * Aceita
		 */
		this.criaCompra();
	}

	closeModalAceitaParcial() {
		this.setState({ modal_nao_aceita_parcial: false });
	}

	criaCompra() {
		const { oc, rc_a, pedido, updateModals, createCompra } = this.props;
		const _oc = {
			...oc,
			rcs_analise: [
				{ ...rc_a },
			],
		};
		createCompra(_oc, pedido, updateModals);
	}

	render() {
		const { modal_nao_aceita_parcial } = this.state;
		const { criando, has_active_compras, status } = this.props;

		const button_class = criando === 1
			? 'btn btn-secondary-conaz round-border loading'
			: 'btn btn-secondary-conaz round-border';
		const on_click_func = criando === 1
			? null
			: this.checkAceitaCompraParcial;
		const text = has_active_compras
			? 'Comprar novamente'
			: 'Comprar';
		const button_html = criando === 1
			? <><LoadingButton />{text}</>
			: text;

		return (
			<>
				{modal_nao_aceita_parcial && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.closeModalAceitaParcial}>
							<ModalConfirm
								title="Este fornecedor desaprovou uma compra parcial com este valor"
								message={<p>O sistema libera a criação de combinações e compras, mas aconselha realizar um alinhamento com estes fornecedores.</p>}
								confirmFunc={this.criaCompra}
								confirmando={criando}
								button_type="secondary-conaz"
								button_text="Entendi!" />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<button
					type="button"
					className={button_class}
					styleName="button-criar-compra"
					onClick={on_click_func}
					id="criar-compra-qcc"
					disabled={status === -30}
				>
					{button_html}
				</button>
			</>
		);
	}
}

CriarCompraQCCButton.propTypes = {
	oc: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
	rc_a: PropTypes.object.isRequired,
	createCompra: PropTypes.func.isRequired,
	criando: PropTypes.number.isRequired,
	pedido: PropTypes.object.isRequired,
	has_active_compras: PropTypes.bool,
	status: PropTypes.number,
};

CriarCompraQCCButton.defaultProps = {
	has_active_compras: false,
	status: null,
};

const _CriarCompraQCCButton = CSSModules(CriarCompraQCCButton, styles, { allowMultiple: true });
export default createCompraHoc(_CriarCompraQCCButton);
