import React from 'react';
import PropTypes from 'prop-types';

import {
	Div,
	CloseButton,
	Title,
	Message,
	ResponsavelBlock,
	OkButton,
} from './ModalAlcada.style';

const ModalAlcada = ({ closeModal, usuarios }) => (
	<Div>
		<CloseButton onClick={closeModal}>
			<i className="fa fa-times" />
		</CloseButton>
		<Title>Você não possui permissão para avaliar</Title>
		<Message>Entre em contato com os usuários que possuem permissão para avaliar:</Message>
		<ResponsavelBlock>
			{usuarios.map(responsavel => (
				<div key={responsavel.id}>
					<span>{responsavel.usuario.nome}</span>
					<span> ({responsavel.usuario.email}) - permissão para avaliar</span>
				</div>
			))}
		</ResponsavelBlock>
		<OkButton onClick={closeModal}>Ok</OkButton>
	</Div>
);

ModalAlcada.propTypes = {
	closeModal: PropTypes.func.isRequired,
	usuarios: PropTypes.array.isRequired,
};

export default ModalAlcada;
