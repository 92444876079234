export const exceptions = {
	a: {},
	e: {},
	o: {},
	da: {},
	de: {},
	do: {},
	na: {},
	no: {},
	em: {},
	dos: {},
	das: {},
	com: {},
};
