import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ExportSteps from './ExportSteps';
import ExportErrorViews from './ExportErrorViews';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

const CriaCotacaoSienge = ({
	sienge_data,
	setSiengeData,
	setStep,
	compra,
	cliente_id,
	setExportingError,
	itens,
	closeFunc,
	setForce,
	setForceCotacao,
	force,
	force_cotacao,
}) => {
	const [error, setError] = useState(null);

	function setAllErrors(err) {
		setError(err);
		setExportingError(true);
	}

	function checkErrors(err) {
		const message = ((
			err.response || {})
			.data || {})
			.message || '';

		// DONE Usuário não foi encontrado
		const error_3 = (
			message.indexOf('O usuário (') !== -1
			&& message.indexOf(') não foi encontrado') !== -1
		);
		if (error_3) {
			setAllErrors(3);
			return;
		}

		// DONE SWI não foi encontrado
		const error_4 = (
			message.indexOf('SWI não foi encontrado') !== -1
			|| message.indexOf('Swi cotação não encontrado') !== -1
		);
		if (error_4) {
			setAllErrors(4);
			handleRequestErrors(err);
			return;
		}

		// DONE Unidade de medida está diferente ou incorreta
		const error_8 = (
			message.indexOf('Unidade de medida está diferente ou incorreta') !== -1
		);
		if (error_8) {
			setAllErrors(8);
			return;
		}

		// DONE Fornecedor swi não existe para esse item
		const error_10 = message
			.indexOf('Fornecedor swi não existe para esse item ou o item já está associado a uma solicitação') !== -1;
		if (error_10) {
			setAllErrors(10);
			return;
		}

		// DONE Negociação ou Cotação já existente para essa solicitação
		const error_11 = message
			.indexOf('Negociação ou Cotação já existente para essa solicitação') !== -1;
		const error_13 = message
			.indexOf('Cotação já criada no swi') !== -1;
		if (error_11 || error_13) {
			setAllErrors(11);
			return;
		}

		// TODO Cotação já criada no swi
		// const error_13 = message
		// 	.indexOf('Cotação já criada no swi') !== -1;
		// if (error_13) {
		// 	setAllErrors(13);
		// 	return;
		// }

		// DONE Erro não identificado
		setAllErrors(0);
		handleRequestErrors(err);
	}

	useEffect(() => {
		function criaCotacaoNoSienge() {
			const cotacao_id = sienge_data.cotacao_id || null;
			if (cotacao_id !== null) {
				setStep(5);
				return;
			}

			const compra_id = compra.id || 0;
			const url = `/conaz/v2/integracoes_de_compras/${compra_id}/cotacao`;
			const params = { cliente_id, swi: 1, force, force_cotacao };
			_post(url, params).then(response => {
				const data = response.data || {};
				setSiengeData(data);
				setStep(5);
			}).catch(err => {
				checkErrors(err);
			});
		}

		criaCotacaoNoSienge();
	}, []);

	// DONE Usuário não foi encontrado
	if (error === 3) {
		return <ExportErrorViews error_type={3} />;
	}

	// DONE SWI não foi encontrado
	if (error === 4) {
		return <ExportErrorViews error_type={4} />;
	}

	// DONE Unidade de medida está diferente ou incorreta
	if (error === 8) {
		const cotacao_id = sienge_data.cotacao_id || '';
		return (
			<ExportErrorViews
				error_type={8}
				cotacao_id={cotacao_id}
			/>
		);
	}

	// DONE Fornecedor Sienge não existe para esse item
	if (error === 10) {
		return <ExportErrorViews error_type={10} />;
	}

	// DONE Negociação ou Cotação já existente para essa solicitação
	if (error === 11) {
		return (
			<ExportErrorViews
				error_type={11}
				itens={itens}
				closeFunc={closeFunc}
				setForce={setForce}
				setStep={setStep}
			/>
		);
	}

	// DONE Cotação já criada no Sienge
	if (error === 13) {
		const cotacao_id = sienge_data.cotacao_id || 0;
		return (
			<ExportErrorViews
				error_type={13}
				closeFunc={closeFunc}
				setForceCotacao={setForceCotacao}
				setStep={setStep}
				cotacao_id={cotacao_id}
			/>
		);
	}

	// DONE Erro não identificado
	if (error === 0) {
		return <ExportErrorViews />;
	}

	return (
		<ExportSteps current={2} />
	);
};

CriaCotacaoSienge.propTypes = {
	sienge_data: PropTypes.object.isRequired,
	setSiengeData: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
	cliente_id: PropTypes.number.isRequired,
	setExportingError: PropTypes.func.isRequired,
	itens: PropTypes.object.isRequired,
	closeFunc: PropTypes.func.isRequired,
	setForce: PropTypes.func.isRequired,
	setForceCotacao: PropTypes.func.isRequired,
	force: PropTypes.bool.isRequired,
	force_cotacao: PropTypes.bool.isRequired,
};

export default CSSModules(CriaCotacaoSienge, styles, { allowMultiple: true });
