import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { _post } from '../../_functions/_requests';
import { getUserLocalstorageData } from '../../_functions/_getUserLocalstorageData';

class CreateCompraButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			criando: 0,
		};
		this.createCompra = this.createCompra.bind(this);
	}

	createCompra() {
		this.setState({ criando: 1 });
		const {
			oc,
			pedido,
			history,
			updateModals,
		} = this.props;
		const obra_selected = getUserLocalstorageData('obra');
		const params = {
			obra_id: obra_selected,
		};

		// RC analise
		const rc_analise_id = (((oc || {}).rcs_analise || [])[0] || {}).id || null;
		if (rc_analise_id !== null) {
			params.rc_analise_id = rc_analise_id;
			// params.tipo = 4;
		}
		// RC
		const rc_uuid = ((((oc || {}).rcs_analise || [])[0] || {}).rc || {}).uuid || null;
		if (rc_uuid !== null && rc_analise_id === null) {
			params.rc_uuid = rc_uuid;
			// params.tipo = 4;
		}
		// Pedido
		const pedido_id = (pedido || {}).id || null;
		if (rc_uuid === null && rc_analise_id === null && pedido_id !== null) {
			params.pedido_id = pedido_id;
			// params.tipo = 5;
		}

		_post('/conaz/v2/compras/', params).then(response => {
			history.push(`/compra/nova/${response.data.id}`);
		}).catch(error => {
			this.setState({ criando: 3 });
			updateModals('error', true);
			handleRequestErrors(error);
		});
	}

	render() {
		const {
			criando,
		} = this.state;
		const {
			button_type,
			button_text,
		} = this.props;

		const button_class = criando === 1
			? `btn btn-${button_type}-conaz round-border loading`
			: `btn btn-${button_type}-conaz round-border`;
		const on_click_func = criando === 1
			? null
			: this.createCompra;
		const button_html = criando === 1
			? <><LoadingButton />{button_text}</>
			: button_text;

		return (
			<button
				type="button"
				className={button_class}
				styleName="create-button"
				onClick={on_click_func}>
				{button_html}
			</button>
		);
	}
}

CreateCompraButton.propTypes = {
	button_type: PropTypes.string,
	button_text: PropTypes.string,
	oc: PropTypes.object,
	pedido: PropTypes.object,
	updateModals: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

CreateCompraButton.defaultProps = {
	button_type: 'primary',
	button_text: 'Nova compra',
	oc: {},
	pedido: {},
};

const _CreateCompraButton = CSSModules(CreateCompraButton, styles, { allowMultiple: true });
export default withRouter(_CreateCompraButton);
