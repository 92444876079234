import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Anexo from './Anexo';

const FileList = ({
	config,
	updateCompras,
	anexos,
	compra,
	updateUi,
	handleModal,
}) => {
	const [addNewFile, setAddNewFile] = useState(window.innerWidth < 700);

	const _resize = () => {
		setAddNewFile(window.innerWidth < 700);
	};

	useEffect(() => {
		window.addEventListener('resize', _resize);
	}, [addNewFile]);

	return (
		<div styleName="files-wrapper">
			<div styleName="content-row">
				<header>
					<p styleName="files">Anexos</p>
					<div>
						<button
							styleName="open-modal"
							onClick={handleModal}
							className="btn btn-secondary-conaz"
							style={addNewFile ? { padding: '6px 10px 5px', bottom: '3px' } : { padding: '6px 22px 5px', bottom: '3px' }}
						>
							+ Adicionar novo arquivo
						</button>
					</div>
				</header>
				<div styleName="anexos">
					<ul>
						{(anexos || []).length !== 0 ? (
							anexos.map((anexo, a) => (
								<Anexo
									{...{
										config,
										updateCompras,
										updateUi,
										anexos,
										compra,
									}}
									first={a === 0}
									last={a === anexos.length - 1}
									name={anexo.nome}
									key={anexo.id}
									criado_em={anexo.criado_em}
									order={a}
									arquivo={anexo} />
							))
						) : (
							<p>Nenhum anexo adicionado </p>
						)}
					</ul>
				</div>

			</div>
		</div>
	);
};

FileList.propTypes = {
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	updateCompras: PropTypes.func.isRequired,
	compra: PropTypes.object.isRequired,
	anexos: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			nome: PropTypes.string,
			tipo: PropTypes.string,
			url: PropTypes.string,
			criado_em: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			tamanho: PropTypes.number,
			visto: PropTypes.bool,
		}),
	).isRequired,
	updateUi: PropTypes.func.isRequired,
	handleModal: PropTypes.func.isRequired,
};

export default CSSModules(FileList, styles, { allowMultiple: true });
