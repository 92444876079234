import React, { useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hoc
import HoverButtonsHoc from '../../../../hocs/HoverButtonsHoc/HoverButtonsHoc';

// Components
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';

function JustificativaButton({
	refinamento_recusado,
	motivo_refinamento,
	justificativa,
	// =========== hoc
	tooltip,
	showTooltip,
	hideTooltip,
}) {
	const [modal, setModal] = useState(false);

	const message = (
		<div style={{ textAlign: 'left', wordBreak: 'break-word' }}>
			Foi solicitada uma correção para está proposta, mas o fornecedor não aceitou devido aos motivos abaixo:<br /><br />
			<b>Correção solicitada:</b><br />
			<span style={{ whiteSpace: 'pre-wrap' }}>{motivo_refinamento}</span><br /><br />
			<b>Justificativa do fornecedor:</b><br />
			<span style={{ whiteSpace: 'pre-wrap' }}>{justificativa}</span><br /><br />
		</div>
	);

	return (
		<>
			{modal && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={() => setModal(false)}>
						<ModalConfirm
							title="Correção solicitada"
							message={message}
							button_type="secondary-conaz"
							button_text="Entendi"
							cancelar_button={false} />
					</ModalWrapperPortal>
				</ModalPortal>
			)}
			{tooltip && (
				<div
					role="tooltip"
					className="tooltip bottom in"
					styleName="tooltip-justificativa">
					<div className="tooltip-arrow" style={{ left: '23px' }} />
					<div className="tooltip-inner">Correção solicitada, clique para saber mais</div>
				</div>
			)}
			{refinamento_recusado && (
				<button
					type="button"
					styleName="icon-refinamento-recusado"
					onMouseEnter={showTooltip}
					onMouseLeave={hideTooltip}
					onClick={() => setModal(true)}>
					<i className="fa fa-exclamation-circle" aria-hidden="true" />
				</button>
			)}
		</>
	);
}

JustificativaButton.propTypes = {
	refinamento_recusado: PropTypes.bool.isRequired,
	motivo_refinamento: PropTypes.string.isRequired,
	justificativa: PropTypes.string.isRequired,
	// =========== hoc
	tooltip: PropTypes.bool.isRequired,
	showTooltip: PropTypes.func.isRequired,
	hideTooltip: PropTypes.func.isRequired,
};

export default HoverButtonsHoc(
	CSSModules(JustificativaButton, styles, { allowMultiple: true }),
);
