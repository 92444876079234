import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class RightModalWrapper extends Component {
	constructor() {
		super();
		this.state = {
			show: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.visible) {
			setTimeout(() => this.setState({ show: true }), 60);
			setTimeout(() => {
				window.addEventListener('keyup', this.escClose);
				window.addEventListener('mousedown', this.handleMouseDown);
			}, 400);
		} else {
			this.setState({ show: false });
			window.removeEventListener('keyup', this.escClose);
			window.removeEventListener('mousedown', this.handleMouseDown);
		}
	}

	escClose = e => {
		const {
			salvando_selecao_fornecedor,
			modal_to_close,
			updateModals,
		} = this.props;
		if (e.keyCode === 27 && !salvando_selecao_fornecedor) updateModals(modal_to_close, false);
	};

	handleMouseDown = e => {
		const {
			salvando_selecao_fornecedor,
			modal_to_close,
			updateModals,
		} = this.props;
		if (
			e.target.getAttribute('data-wrapper-close') &&
			!salvando_selecao_fornecedor
		) {
			updateModals(modal_to_close, false);
		}
	};

	render() {
		const { visible } = this.props;
		const { show } = this.state;
		const modal_class = show ? 'modal fade in' : 'modal fade';

		if (visible) {
			return (
				<div
					className={modal_class}
					styleName={modal_class}
					tabIndex="-1"
					role="dialog"
					data-wrapper-close>
					<div styleName="left-modal-dialog">
						{React.cloneElement(this.props.children, {
							updateModals: this.props.updateModals,
						})}
					</div>
				</div>
			);
		}
		return null;
	}
}

RightModalWrapper.propTypes = {
	visible: PropTypes.bool,
	updateModals: PropTypes.func.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool,
	modal_to_close: PropTypes.string,
	children: PropTypes.object.isRequired,
};

RightModalWrapper.defaultProps = {
	visible: false,
	salvando_selecao_fornecedor: false,
	modal_to_close: 'fornecedor',
};

export default CSSModules(RightModalWrapper, styles, { allowMultiple: true });
