import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedorComb from './FornecedorComb';
import FornecedorRC from './FornecedorRC';

const FornecedoresTopRow = ({
	opcoes_de_compra,
	rcs_abertos,
	openCloseRCs,
	deleteOCFromProps,
	infoFornecedor,
	updateModals,
	edition_mode,
	cotacao_codigo,
	pedido,
	collapseOC,
	combinacao_criada_id,
	goToCriarCompra,
	salvaCombinacao,
	salvando_combinacao,
	loading_compras,
	compras,
	changeCondicaoPagamento,
}) => {
	const fornecedores_class = !edition_mode ? 'fornecedores' : 'fornecedores comb-edition';
	const { all_ids, by_id } = opcoes_de_compra;
	let order = 0;
	let start_rcs = false;
	const has_combinacao = ((by_id[all_ids[0]] || {}).rcs_analise || []).length > 1;
	let start_completos = false;
	let start_incompletos = false;

	const border_class = !edition_mode ? 'f-border' : 'f-border edition';

	const loading_column = all_ids.length === 0 ? (
		<div styleName="fornecedor" style={{ background: '#fff' }}>
			<div styleName="timeline-item">
				<div styleName="animated-background">
					<div styleName="background-masker m-fornecedor" />
					<div styleName="background-masker m-space-1" />
					<div styleName="background-masker m-preco" />
					<div styleName="background-masker m-space-2" />
					<div styleName="background-masker m-condicao" />
					<div styleName="background-masker m-space-3" />
					<div styleName="background-masker m-entrega" />
					<div styleName="background-masker m-space-4" />
				</div>
			</div>
		</div>
	) : null;

	return (
		<div id="fornecedores_wrapper" styleName={fornecedores_class}>
			<div styleName={border_class} />
			<div id="scroll_fornecedores" styleName="f-list">
				{all_ids.map(oc_id => {
					const { rcs_analise } = by_id[oc_id];
					const is_combinacao = rcs_analise.length > 1;
					const opcao_de_compra = by_id[oc_id];

					/**
					* Verifica e separa a ordem das combinações e dos rcs
					* Caso comece a lista os rcs a ordem é reiniciada
					*/
					if (rcs_analise.length === 1 && !start_rcs) {
						order = 0;
						start_rcs = true;
					}
					order++;

					if (is_combinacao) {
						return (
							<FornecedorComb
								key={oc_id}
								order={order}
								opcao_de_compra={opcao_de_compra}
								rcs_abertos={rcs_abertos}
								openCloseRCs={openCloseRCs}
								deleteOCFromProps={deleteOCFromProps}
								edition_mode={edition_mode}
								infoFornecedor={infoFornecedor}
								updateModals={updateModals}
								cotacao_codigo={cotacao_codigo}
								collapseOC={collapseOC}
								combinacao_criada_id={combinacao_criada_id}
								goToCriarCompra={goToCriarCompra}
								salvaCombinacao={salvaCombinacao}
								salvando_combinacao={salvando_combinacao}
								loading_compras={loading_compras}
								compras={compras}
								changeCondicaoPagamento={changeCondicaoPagamento}
								pedido={pedido} />
						);
					}

					const _FornecedorRC = (
						<FornecedorRC
							key={oc_id}
							order={order}
							opcao_de_compra={opcao_de_compra}
							rcs_abertos={rcs_abertos}
							openCloseRCs={openCloseRCs}
							infoFornecedor={infoFornecedor}
							updateModals={updateModals}
							collapseOC={collapseOC}
							goToCriarCompra={goToCriarCompra}
							loading_compras={loading_compras}
							compras={compras}
							pedido={pedido} />
					);

					/**
					* Espaçamento entre combinações e rcs
					*/
					if (has_combinacao && !start_completos) {
						start_completos = true;

						return (
							<React.Fragment key={oc_id}>
								<div styleName="separacao-tipos-rcs" />
								{_FornecedorRC}
							</React.Fragment>
						);
					}

					/**
					* Espaçamento entre completos e incompletos
					*/
					if (!opcao_de_compra.rcs_analise[0].rc.completo && !start_incompletos) {
						start_incompletos = true;

						return (
							<React.Fragment key={oc_id}>
								<div styleName="separacao-tipos-rcs" />
								{_FornecedorRC}
							</React.Fragment>
						);
					}

					return _FornecedorRC;
				})}

				{all_ids.length === 0 && (
					<>
						{loading_column}
						{loading_column}
						{loading_column}
						{loading_column}
					</>
				)}
			</div>
		</div>
	);
};

FornecedoresTopRow.propTypes = {
	opcoes_de_compra: PropTypes.object.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	deleteOCFromProps: PropTypes.func,
	infoFornecedor: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	edition_mode: PropTypes.bool,
	cotacao_codigo: PropTypes.string,
	pedido: PropTypes.object,
	collapseOC: PropTypes.func,
	combinacao_criada_id: PropTypes.number,
	goToCriarCompra: PropTypes.func,
	salvaCombinacao: PropTypes.func,
	salvando_combinacao: PropTypes.number,
	loading_compras: PropTypes.number,
	compras: PropTypes.array,
	changeCondicaoPagamento: PropTypes.func.isRequired,
};

FornecedoresTopRow.defaultProps = {
	deleteOCFromProps: () => {},
	edition_mode: false,
	cotacao_codigo: '',
	pedido: {},
	collapseOC: () => {},
	combinacao_criada_id: 0,
	goToCriarCompra: () => {},
	salvaCombinacao: () => {},
	salvando_combinacao: 0,
	loading_compras: 0,
	compras: [],
};

export default CSSModules(FornecedoresTopRow, styles, { allowMultiple: true });
