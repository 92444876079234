import React from 'react';
import PropTypes from 'prop-types';

const ToolTip = ({ message, display, color, arrow_left, margin_top, margin_left }) => (
	<div className={`tooltip ${display} in`} role="tooltip" style={{ marginTop: margin_top, marginLeft: margin_left, opacity: '1' }}>
		<div className="tooltip-arrow" style={{ borderTopColor: color, left: arrow_left }} />
		<div className="tooltip-inner" style={{ background: color }}>{message}</div>
	</div>
);

ToolTip.propTypes = {
	message: PropTypes.string,
	display: PropTypes.string,
	color: PropTypes.string,
	arrow_left: PropTypes.string,
	margin_top: PropTypes.string,
	margin_left: PropTypes.string,
};

ToolTip.defaultProps = {
	message: 'Preencha o campo corretamente',
	display: 'top',
	color: '#ee2622',
	arrow_left: '23%',
	margin_top: '0px',
	margin_left: '0px',
};

export default ToolTip;
