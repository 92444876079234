import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../_loadings/LoadingButton/LoadingButton';

// Functions
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';
import { _post } from '../../_functions/_requests';

class CreateCotacaoButton extends Component {
	constructor() {
		super();
		this.createPedido = this.createPedido.bind(this);
	}

	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	createPedido() {
		this.props.updateCotacao(['criando_cotacao'], true);
		const { obra_selected, route_path, reportErrors } = this.props;
		const params = {
			obra_id: obra_selected,
		};
		_post('/conaz/v2/pedidos/', params).then(response => {
			this.props.history.push(`/cotacao/nova/${response.data.codigo}`);
			this.props.updateCotacao(['criando_cotacao'], false);
		}).catch(error => {
			this.props.updateCotacao(['criando_cotacao'], false);
			this.props.updateModals('error', true);
			handleRequestErrors(error, route_path, reportErrors);
		});
	}

	render() {
		if (this.props.criando_cotacao) {
			return <button type="button" className="btn btn-primary-conaz round-border loading" styleName="create-button"><LoadingButton />Nova cotação</button>;
		}
		return <button type="button" className="btn btn-primary-conaz round-border" styleName="create-button" onClick={this.createPedido}>Nova cotação</button>;
	}
}

CreateCotacaoButton.propTypes = {
	obra_selected: PropTypes.number.isRequired,
	reportErrors: PropTypes.func.isRequired,
	criando_cotacao: PropTypes.bool.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	route_path: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
};

const _CreateCotacaoButton = CSSModules(CreateCotacaoButton, styles, { allowMultiple: true });
export default withRouter(_CreateCotacaoButton);
