import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import FornecedorReview from './FornecedorReview';
import CadastrarFornecedor from './CadastrarFornecedor';
import TabsFornecedor from '../../TabsFornecedor/TabsFornecedor';
import TabFornecedor from '../../TabsFornecedor/TabFornecedor';
import TabVendedores from './TabVendedores';
import TabAvaliacoes from './TabAvaliacoes';
import TabInformacoes from './TabInformacoes';

// Functions
import { _post, _get } from '../../../_functions/_requests';
import * as constants from './constants';

class LeftModalFornecedor extends Component {
	state = {
		vendedor_selecionado: {},
		form_novo_vendedor: false,
		preenchimento_fornecedor: true,
		resending: 0,
		observacoes_fornecedor: '',
		sem_preferencia_vendedor: true,
		tipo_selecao_fornecedor: null,
		selected_tab: 1,
		avaliacoesFornecedor: {},
	};

	UNSAFE_componentWillMount() {
		this.requestComentarios();
		const { fornecedor } = this.props;
		if (fornecedor.id) {
			const {
				tipo_selecao_fornecedor,
				preenchimento_fornecedor,
				sem_preferencia_vendedor,
				vendedor_selecionado,
			} = fornecedor;
			this.setState({
				tipo_selecao_fornecedor,
				preenchimento_fornecedor,
				sem_preferencia_vendedor,
				vendedor_selecionado,
			});
		} else {
			this.setState({ sem_preferencia_vendedor: false });
		}
	}

	componentDidMount() {
		const { fornecedor } = this.props;
		if (fornecedor.id > 0 && constants.EXIBIR_OBSERVACOES) {
			_get(`/conaz/v2/fornecedores/${fornecedor.id}?fields=observacoes`)
				.then(response => {
					const observacoes_fornecedor = response.data.observacoes;
					this.setState({ observacoes_fornecedor });
				});
		}
	}

	changeTab = (tab = 1) => {
		this.setState({ selected_tab: tab });
	}

	requestComentarios() {
		const { id } = this.props.fornecedor;
		if (this.props.fornecedor.nota_detalhada) {
			_get(`/avaliacao/v2/comentarios/fornecedor/${id}/comentarios_usuario_e_fornecedor?page=1&per_page=10`).then(response => {
				const avaliacoesFornecedor = response.data.data;
				if (avaliacoesFornecedor) {
					this.setState({ avaliacoesFornecedor });
				}
			});
		}
	}

	openVendedorModal = () => {
		this.setState({
			form_novo_vendedor: true,
			vendedor_selecionado: {},
			sem_preferencia_vendedor: false,
		});
		this.props.cancelaFornecedorExistente();
	}

	checkVendedor = (usuario) => {
		const vendedor = usuario && usuario.id !== undefined ? usuario : {};
		this.setState({
			form_novo_vendedor: false,
			vendedor_selecionado: vendedor,
			sem_preferencia_vendedor: !vendedor.id,
		});
	}

	closeModal = () => {
		if (!this.props.salvando_selecao_fornecedor) {
			this.props.updateModals('fornecedor', false);
			this.props.resetaErros();
		}
	}

	preAdicionaFornecedorExistente = () => {
		const {
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		} = this.state;
		this.props.adicionaFornecedorExistente(
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		);
	}

	preAdicionaFornecedor = (_fornecedor, vendedor) => {
		const {
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		} = this.state;
		this.props.cancelaFornecedorExistente();
		this.props.cadastrarVendedor(
			_fornecedor,
			vendedor,
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		);
	}

	preAdicionaNovoFornecedor(_fornecedor, vendedor) {
		const {
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		} = this.state;
		this.props.cadastrarFornecedorVendedor(
			_fornecedor,
			vendedor,
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		);
	}

	preSelecionarFornecedorVendedor = () => {
		const {
			preenchimento_fornecedor,
			vendedor_selecionado,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		} = this.state;
		const { fornecedor } = this.props;
		this.props.criarEditarRC(
			fornecedor,
			vendedor_selecionado,
			preenchimento_fornecedor,
			sem_preferencia_vendedor,
			tipo_selecao_fornecedor,
		);
	}

	handlePreenchimentoFornecedor = () => {
		const preenchimento_fornecedor = !this.state.preenchimento_fornecedor;
		this.setState({ preenchimento_fornecedor });
	}

	handleTipoSelecaoFornecedor = (e) => {
		this.setState({ tipo_selecao_fornecedor: e.target.value });
	}

	resendEmail = () => {
		this.setState({ resending: 1 });
		const { rc_id } = this.props.fornecedor;
		if (rc_id !== undefined && rc_id !== null && rc_id !== 0) {
			_post(`/conaz/v2/rcs/${rc_id}/enviar`, {}).then(() => {
				this.setState({ resending: 2 });
			}).catch(error => {
				console.log(error); // eslint-disable-line
				this.setState({ resending: 3 });
			});
		} else {
			this.setState({ resending: 0 });
		}
	}

	render() {
		const mapSelecoes = {
			1: 'Construtor: Somente empresa',
			2: 'Construtor: Empresa e vendedor',
			3: 'Suporte',
			4: 'Fornecedor: Outras oportunidades',
		};
		const {
			selected_tab,
			form_novo_vendedor,
			vendedor_selecionado,
			preenchimento_fornecedor,
			resending,
			sem_preferencia_vendedor,
		} = this.state;

		const {
			fornecedor,
			form_novo_fornecedor,
			errors_props,
			fornecedor_ja_existe,
			fornecedor_existente,
			cliente_id,
			salvando_selecao_fornecedor,
			ocultar_vendedores_nao_cadastrados_pelo_construtor,
		} = this.props;

		let { vendedores } = fornecedor;
		if (ocultar_vendedores_nao_cadastrados_pelo_construtor && !form_novo_fornecedor) {
			vendedores = vendedores
				.filter(v => (
					v.status >= 0
					|| (
						v.cadastrado_por !== null
						&& v.cadastrado_por.toString() === cliente_id.toString()
					)
				));
		}

		return (
			<div styleName="modal-content">
				<div styleName="content">
					<button
						type="button"
						className="close"
						styleName="close-button"
						data-dismiss="modal"
						aria-label="Close"
						onClick={this.closeModal}
					>
						<span aria-hidden="true">&times;</span>
					</button>

					{!form_novo_fornecedor ? (
						<>
							<FornecedorReview
								fornecedor={fornecedor}
								inclui_link_edicao={constants.INCLUI_LINK_EDICAO}
							/>

							<TabsFornecedor>
								<TabFornecedor
									tab_number={1}
									title="Vendedores"
									description="Selecione o vendedor de sua preferência"
									selected_tab={selected_tab}
									changeTab={this.changeTab}
								/>
								<TabFornecedor
									tab_number={2}
									title="Avaliações"
									description="Veja como você e outras construtoras avaliaram o fornecedor"
									selected_tab={selected_tab}
									changeTab={this.changeTab}
								/>
								<TabFornecedor
									tab_number={3}
									title="Informações gerais"
									description="Verifique razão social, CNPJ e endereço "
									selected_tab={selected_tab}
									changeTab={this.changeTab}
								/>
							</TabsFornecedor>

							<div styleName="tabs-bottom-wrapper">
								{selected_tab === 1 && (
									<TabVendedores
										fornecedor={fornecedor}
										sem_preferencia_vendedor={sem_preferencia_vendedor}
										checkVendedor={this.checkVendedor}
										vendedores={vendedores}
										openVendedorModal={this.openVendedorModal}
										vendedor_selecionado={vendedor_selecionado}
										codigo_ibge_obra={this.props.codigo_ibge_obra}
										grupos_itens_pedido={this.props.grupos_itens_pedido}
										cancelaFornecedorExistente={this.props.cancelaFornecedorExistente}
										form_novo_vendedor={form_novo_vendedor}
										salvando_selecao_fornecedor={salvando_selecao_fornecedor}
										preSelecionarFornecedorVendedor={this.preSelecionarFornecedorVendedor}
										fornecedor_ja_existe={fornecedor_ja_existe}
										fornecedor_existente={fornecedor_existente}
										preAdicionaFornecedor={this.preAdicionaFornecedor}
									/>
								)}
								{selected_tab === 2 && (
									<TabAvaliacoes
										vendedores={vendedores}
										nota_detalhada={fornecedor.nota_detalhada}
										avaliacoes={this.state.avaliacoesFornecedor}
									/>
								)}
								{selected_tab === 3 && (
									<TabInformacoes
										fornecedor={fornecedor}
										cliente_id={cliente_id}
										observacoes_fornecedor={this.state.observacoes_fornecedor}
										exibir_observacoes={constants.EXIBIR_OBSERVACOES}
										exibir_status={constants.EXIBIR_STATUS_FORNECEDOR}
										exibir_cadastrado_por={constants.EXIBIR_CADASTRADO_POR}
									/>
								)}
							</div>
						</>
					) : (
						<CadastrarFornecedor
							inclui_site={constants.INCLUIR_SITE}
							pre_nome_fornecedor={this.props.pre_nome_fornecedor}
							salvando_novo_fornecedor={salvando_selecao_fornecedor}
							cadastrarFornecedorFunc={(_fornecedor, vendedor) => this.preAdicionaNovoFornecedor(_fornecedor, vendedor)}
							fornecedor_ja_existe={fornecedor_ja_existe}
							cancelaFornecedorExistente={this.props.cancelaFornecedorExistente}
						/>
					)}
					{errors_props.salvando_fornecedor && (
						<div className="alert alert-danger" role="alert" style={{ float: 'left', marginBottom: '0' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Desculpe, aconteceu algo errado. Tente novamente.
						</div>
					)}
					{errors_props.fornecedor_com_rc && (
						<div className="alert alert-danger" role="alert" style={{ float: 'left', marginBottom: '0' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> O fornecedor que você escolheu já está selecionado para a cotação.
						</div>
					)}
					{errors_props.vendedor_com_rc && (
						<div className="alert alert-danger" role="alert" style={{ float: 'left', marginBottom: '0' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> O vendedor que você escolheu já está selecionado para a cotação com outra empresa.
						</div>
					)}
					{errors_props.rc_ja_enviado && (
						<div className="alert alert-danger" role="alert" style={{ float: 'left', marginBottom: '0' }}>
							<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> RC já foi enviado.
						</div>
					)}
					{constants.EDITA_TIPO_SELECAO_FORNECEDOR && (
						<div className="form-group">
							<br />
							<label>Seleção</label>
							<select
								className="form-control"
								onChange={this.handleTipoSelecaoFornecedor}
								value={this.state.tipo_selecao_fornecedor || 3}
							>
								{Object.keys(mapSelecoes).map(tipo_sel => (
									<option
										key={tipo_sel}
										value={tipo_sel}
									>
										{mapSelecoes[tipo_sel]}
									</option>
								))}
							</select>
						</div>
					)}
					{constants.EXIBIR_CHECK_ENVIO_EMAIL && (
						<div styleName="checkbox-wrapper">
							<button
								type="button"
								styleName={`checkbox-button ${preenchimento_fornecedor ? 'checked' : ''}`}
								onClick={this.handlePreenchimentoFornecedor}
							>
								<div styleName="checkbox-icon"> {
									preenchimento_fornecedor && <i className="fa fa-check" aria-hidden="true" />
								}
								</div>
								Enviar e-mail para o fornecedor
							</button>
						</div>
					)}
					{(constants.EXIBIR_BOTAO_REENVIO && !form_novo_fornecedor && fornecedor.rc_id) && (
						<>
							{resending === 2 ? (
								<div styleName="reenviar-wrapper">
									<i className="fa fa-check fa-fw" aria-hidden="true" /> E-mail reenviado com sucesso!
								</div>
							) : (
								<div styleName="reenviar-wrapper">
									<button
										className={resending === 1 ? 'btn btn-clear-conaz round-border' : 'btn btn-link'}
										style={{ padding: '8px 19px 9px', marginLeft: '6px', opacity: resending === 1 ? '0.4' : '1' }}
										onClick={this.resendEmail}
									>
										{resending === 1 ? 'Enviando...' : <><span styleName="somente-triagem">*</span> Reenviar e-mail para o fornecedor</>}
									</button>
									{resending === 3 && <p style={{ color: 'red' }}>Erro reenviando e-mail. Tente novamente.</p>}
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

LeftModalFornecedor.propTypes = {
	updateModals: PropTypes.func,
	fornecedor: PropTypes.object,
	form_novo_fornecedor: PropTypes.bool.isRequired,
	errors_props: PropTypes.object.isRequired,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	cancelaFornecedorExistente: PropTypes.func.isRequired,
	adicionaFornecedorExistente: PropTypes.func.isRequired,
	fornecedor_existente: PropTypes.object.isRequired,
	cliente_id: PropTypes.number.isRequired,
	criarEditarRC: PropTypes.func.isRequired,
	pre_nome_fornecedor: PropTypes.string.isRequired,
	cadastrarVendedor: PropTypes.func.isRequired,
	cadastrarFornecedorVendedor: PropTypes.func.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	ocultar_vendedores_nao_cadastrados_pelo_construtor: PropTypes.bool.isRequired,
	resetaErros: PropTypes.func.isRequired,
	codigo_ibge_obra: PropTypes.string.isRequired,
	grupos_itens_pedido: PropTypes.array.isRequired,
};

LeftModalFornecedor.defaultProps = {
	updateModals: () => {},
	fornecedor: {},
};

export default CSSModules(LeftModalFornecedor, styles, { allowMultiple: true });
