export default [
	'Res. Multifamiliar Horizontal',
	'Res. Multifamiliar Vertical',
	'Residencial Unifamiliar',
	'Res. Transitório (Hotéis e similares)',
	'Industrial; Comercial',
	'Misto Residencial/Comercial',
	'Hospitalar',
	'Escolar',
	'Infraestrutura',
	'Saneamento',
	'Loteamento',
	'Reforma',
	'Centro de Distribuição de Materiais',
];
