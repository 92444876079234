import moment from 'moment';
import 'moment/locale/pt-br';

export const attachWootric = (user_data = {}) => {
	const email = user_data.email || '';
	const confirmado_em = user_data.confirmado_em || '';
	const created_at = moment(confirmado_em).unix();
	const origin = window.location.href;

	if (
		origin.indexOf('portal.conazweb.com.br') !== -1
		&& email !== ''
	) {
		window.wootricSettings = {
			email,
			created_at,
			account_token: 'NPS-abadfd29',
		};
		const e = document.createElement('script');
		const t = document.getElementsByTagName('script')[0];
		e.async = 1;
		e.src = 'https://cdn.wootric.com/wootric-sdk.js';
		t.parentNode.insertBefore(e, t);

		setTimeout(() => {
			if (window.wootric) {
				// window.wootric_survey_immediately = true;
				// window.wootric_no_surveyed_cookie = true;
				window.wootric('run');
			}
		}, 2000);
	}
};
