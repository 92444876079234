import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Functions
import { clearItensAlerts } from '../../../_functions/_clearItensAlerts';

class InputText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.default_value,
		};
		this.handleEdition = this.handleEdition.bind(this);
		this.checkOutro = this.checkOutro.bind(this);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { default_value } = nextProps;
		if (default_value !== this.state.value) this.setState({ value: default_value });
	}

	handleEdition(e) {
		const {
			type,
			item_key,
			field,
			opcao_preenchida,
			updateFunction,
			autoSave,
			zerar_erros,
		} = this.props;
		const value = e.target.value.toString();
		this.setState({ value });

		switch (type) {
		case 'item':
		case 'item-unidade': {
			updateFunction(item_key, field, value);
			break;
		}
		case 'caracteristica': {
			const _opcao_preenchida = { ...opcao_preenchida, valor: value };
			updateFunction(item_key, field, [_opcao_preenchida]);
			break;
		}
		case 'unidade-caracteristica': {
			const _opcao_preenchida = { ...opcao_preenchida, unidade: value };
			updateFunction(item_key, field, [_opcao_preenchida]);
			break;
		}
		default:
		}

		autoSave(item_key);
		if (zerar_erros) clearItensAlerts();
	}

	checkOutro() {
		if (this.props.outro && this.state.value === '') this.props.handleOutro();
	}

	render() {
		const { value } = this.state;
		const { type, field_id, disabled, maxLength } = this.props;
		const placeholder = (type === 'item-unidade' || type === 'unidade-caracteristica') ? 'unidade' : '';

		return (
			<input
				id={field_id}
				type="text"
				className="form-control input-md"
				disabled={disabled}
				value={value}
				onChange={this.handleEdition}
				onBlur={this.checkOutro}
				placeholder={placeholder}
				maxLength={maxLength} />
		);
	}
}

InputText.propTypes = {
	type: PropTypes.string.isRequired,
	field_id: PropTypes.string.isRequired,
	item_key: PropTypes.number.isRequired,
	field: PropTypes.string.isRequired,
	default_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	opcao_preenchida: PropTypes.object,
	updateFunction: PropTypes.func,
	outro: PropTypes.bool,
	handleOutro: PropTypes.func,
	disabled: PropTypes.bool,
	autoSave: PropTypes.func,
	maxLength: PropTypes.number,
	zerar_erros: PropTypes.bool,
};

InputText.defaultProps = {
	default_value: '',
	opcao_preenchida: {},
	updateFunction: () => {},
	outro: false,
	handleOutro: () => {},
	disabled: false,
	autoSave: () => {},
	maxLength: null,
	zerar_erros: true,
};

export default InputText;
