import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/pt-br';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { editMomentDateFormat } from '../../../../components/_functions/_editDateFormat';

const ExpiredActions = ({ rc }) => {
	const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
	const validade = (rc || {}).validade || '';
	const esta_expirado = (
		validade !== ''
		&& moment(validade).isBefore(moment(), 'day')
	);

	if (is_triagem || !esta_expirado) return null;

	const validade_view = editMomentDateFormat(validade);

	return (
		<div styleName="alerts-messages-wrapper" style={{ background: 'rgba(233, 236, 242, 0.61)' }}>
			<div styleName="am-text expired">
				<b>Alerta: a validade da proposta do fornecedor era até o dia {validade_view}</b>
				<br />
				<p>Recomenda-se um contato prévio com o vendedor para verificar se as condições e valores da proposta permanecem os mesmos.</p>
			</div>
		</div>
	);
};

ExpiredActions.propTypes = {
	rc: PropTypes.object,
};

ExpiredActions.defaultProps = {
	rc: {},
};

export default CSSModules(ExpiredActions, styles, { allowMultiple: true });
