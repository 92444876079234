export const OBRAS_GET_LIST_REQUEST = 'OBRAS_GET_LIST_REQUEST';
export const OBRAS_GET_LIST_SUCCESS = 'OBRAS_GET_LIST_SUCCESS';
export const OBRAS_GET_LIST_ERROR = 'OBRAS_GET_LIST_ERROR';

export const OBRAS_CREATE_REQUEST = 'OBRAS_CREATE_REQUEST';
export const OBRAS_CREATE_SUCCESS = 'OBRAS_CREATE_SUCCESS';
export const OBRAS_EDIT_REQUEST = 'OBRAS_EDIT_REQUEST';
export const OBRAS_EDIT_SUCCESS = 'OBRAS_EDIT_SUCCESS';
export const OBRAS_BLOCK_REQUEST = 'OBRAS_BLOCK_REQUEST';
export const OBRAS_BLOCK_SUCCESS = 'OBRAS_BLOCK_SUCCESS';
export const OBRAS_DELETE_REQUEST = 'OBRAS_DELETE_REQUEST';
export const OBRAS_DELETE_SUCCESS = 'OBRAS_DELETE_SUCCESS';

export const OBRAS_ACTION_ERROR = 'OBRAS_ACTION_ERROR';

export const OBRAS_OPEN_DRAWER = 'OBRAS_OPEN_DRAWER';
export const OBRAS_CLOSE_DRAWER = 'OBRAS_CLOSE_DRAWER';

export const OBRAS_SUBMIT_ASSOCIA_EMPRESA_REQUEST = 'OBRAS_SUBMIT_ASSOCIA_EMPRESA_REQUEST';
export const OBRAS_SUBMIT_ASSOCIA_EMPRESA_SUCCESS = 'OBRAS_SUBMIT_ASSOCIA_EMPRESA_SUCCESS';
export const OBRAS_SUBMIT_ASSOCIA_EMPRESA_ERROR = 'OBRAS_SUBMIT_ASSOCIA_EMPRESA_ERROR';

export const OBRAS_SUBMIT_ASSOCIA_USUARIO_REQUEST = 'OBRAS_SUBMIT_ASSOCIA_USUARIO_REQUEST';
export const OBRAS_SUBMIT_ASSOCIA_USUARIO_SUCCESS = 'OBRAS_SUBMIT_ASSOCIA_USUARIO_SUCCESS';
export const OBRAS_SUBMIT_ASSOCIA_USUARIO_ERROR = 'OBRAS_SUBMIT_ASSOCIA_USUARIO_ERROR';

export const ASSOCIA_OBRAS_STATUS_VISUALIZACAO = 'ASSOCIA_OBRAS_STATUS_VISUALIZACAO';
export const ASSOCIA_OBRAS_STATUS_ATIVO = 'ASSOCIA_OBRAS_STATUS_ATIVO';
