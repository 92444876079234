import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
	th {
		background: #f1f3f6 !important;
		color: #595959 !important;
		font-size: 12px;
		line-height: 16px;
	}

	td {
		color: #595959;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 1px 0px #D9D9D9;
	}

	.ant-table-tbody {
		background-color: #FFFF !important;
	}
	
	@media(max-width: 600px) {
		.ant-table {
			max-width: 100%;
			min-height: 350px;
			max-height: 100%;
			resize: none;
			overflow-x: auto;
			}
		}
`;

export const ActionsArea = styled.div`
	display: flex;
	gap: 11px;
	align-items: center;
	& > svg {
		width: 22px;
		height: 20px;
		cursor: pointer;
	}
`;
