import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class DropArea extends Component {
	constructor() {
		super();
		this.state = {
			dragover: false,
		};
		this.splitFilesInput = this.splitFilesInput.bind(this);
		this.splitFilesDrop = this.splitFilesDrop.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
	}

	splitFilesInput(e) {
		const { updateEnviarMais, nao_quer_adicionar_mais } = this.props;
		if (!nao_quer_adicionar_mais) {
			updateEnviarMais();
		}

		e.preventDefault();
		const files_to_add = e.target.files;
		this.handleFileSelect(files_to_add);
	}

	splitFilesDrop(e) {
		const { updateEnviarMais, nao_quer_adicionar_mais } = this.props;
		e.stopPropagation();
		e.preventDefault();
		if (!nao_quer_adicionar_mais) {
			updateEnviarMais();
		}
		this.setState({ dragover: false });
		const files_to_add = e.dataTransfer.files;
		this.handleFileSelect(files_to_add);
	}

	handleFileSelect(files_to_add) {
		const max_size = 32000000; // 32mb
		const files = [];
		let file_temp_id = this.props.files_temp_id + 1;

		for (let i = 0; i < files_to_add.length; i++) {
			const size_error = files_to_add[i].size > max_size;
			const single_file = {
				id: 0,
				id_temp: file_temp_id,
				nome: files_to_add[i].name,
				tipo: '',
				visivel_pro_fornecedor: true,
				tamanho: files_to_add[i].size,
				file: files_to_add[i],
				success: 0,
				size_error,
				error_message: '',
			};
			files.push(single_file);
			file_temp_id++;
		}

		const files_by_id = {};
		files.forEach(current => {
			files_by_id[current.id_temp] = current;
		});
		this.props.addFilesToState(files_by_id, file_temp_id);
	}

	handleDragEnter(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ dragover: true });
	}

	handleDragLeave(e) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({ dragover: false });
	}

	/* eslint class-methods-use-this: 0 */
	handleDragOver(e) {
		e.stopPropagation();
		e.preventDefault();
	}

	render() {
		const { dragover } = this.state;
		const droparea_class = !dragover
			? 'droparea'
			: 'droparea dragover';

		return (
			<div id="droparea_box" styleName="droparea-wrapper">
				<div
					styleName={droparea_class}
					onDragEnter={this.handleDragEnter}
					onDragLeave={this.handleDragLeave}
					onDragOver={this.handleDragOver}
					onDrop={this.splitFilesDrop}>

					{!dragover ?
						(
							<>
								<i className="fa fa-upload" style={{ color: '#828282' }} />
								<p styleName="droparea-title">Arraste aqui seus arquivos</p>
								<p styleName="droparea-or">ou</p>
								<div styleName="select-button">
									<input
										type="file"
										id="file-1"
										name="file-1[]"
										styleName="inputfile"
										multiple
										onChange={this.splitFilesInput} />
									<label htmlFor="file-1">
										<p styleName="computer">selecione os arquivos do seu computador</p>
										<p styleName="phone">selecione os arquivos do seu celular</p>
										<p styleName="tablet">selecione os arquivos do seu tablet</p>
									</label>
								</div>
							</>
						)
						:
						(
							<>Solte para enviá-los</>
						)}
				</div>
			</div>
		);
	}
}

DropArea.propTypes = {
	files_temp_id: PropTypes.number.isRequired,
	updateEnviarMais: PropTypes.func.isRequired,
	nao_quer_adicionar_mais: PropTypes.bool.isRequired,
	addFilesToState: PropTypes.func.isRequired,
};

export default CSSModules(DropArea, styles, { allowMultiple: true });
