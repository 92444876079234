/* eslint-disable */
import React from 'react';
import { EditOutlined, StopOutlined } from '@ant-design/icons';
import { formatNumbers } from 'components/_functions/_formatNumbers';
import { documentType } from 'components/_functions/_documentType';
import { ActionsArea } from './styled'
import { Popconfirm } from 'antd';

const emptyCell = '-';

const papeisMap = {
	2: 'Responsável fatura',
	1: 'Responsável obra',
};

const numerosToPaPel = (nums) => nums.map((n) => papeisMap[n]).join(', ');

export default (currentUserRole, openDrawer) => [
	{
		title: 'Nome',
		key: '1',
		dataIndex: 'pessoa',
		onFilter: (value, record) => record.pessoa.nome.includes(value),
		sorter: (a, b) => a.pessoa.nome && a.pessoa.nome.length - b.pessoa.nome.length,
		render: (value) => value.nome || emptyCell,
		align: 'left',
		width: 180,
		ellipsis: true,
	},
	{
		title: 'CNPJ/CPF',
		key: '2',
		dataIndex: 'pessoa',
		onFilter: (value, record) => record.pessoa.documento.includes(value),
		sorter: (a, b) => a.pessoa.documento && a.pessoa.documento.length - b.pessoa.documento.length,
		render: (value) => { return typeof value.documento !== 'undefined' && value.documento !== null && formatNumbers(value.documento+'', documentType(value.documento+'')) || emptyCell },
		width: 185,
		ellipsis: true,
	},
	{
		title: 'Papéis',
		key: '3',
		dataIndex: 'papeis',
		render: (value) => numerosToPaPel(value) || emptyCell,
		onFilter: (value, record) => record.papeis.toLowerCase().includes(value.toLowerCase()),
		sorter: (a, b) => a.papeis.length - b.papeis.length,
		width: 605,
		ellipsis: true,
	},
	{
		title: 'Ações',
		dataIndex: 'actions',
		key: '6',
		width: 110,
		render: (_, record) => (
			<ActionsArea>
				{currentUserRole === 'admin' &&
					<>
						<EditOutlined onClick={() => openDrawer('edit', record)} />
						<Popconfirm
						 title={`Tem certeza que deseja remover ${record.pessoa.nome || ''} ${record.pessoa.nome !== undefined ? 'dessa' : 'essa'} associação?`} 
						 onCancel={() => {}} 
						 onConfirm={() => openDrawer('remove', record)}
						 cancelText="Cancelar"
						 okText="Sim"
						 >
						<StopOutlined />
						</Popconfirm>
					</>
				}
			</ActionsArea>
			),
	},
];
