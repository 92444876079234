import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import VendedorButton from './VendedorButton';
import VendedorRow from './VendedorRow';
import CadastrarVendedor from './CadastrarVendedor';

const TabVendedores = ({
	fornecedor_ja_existe,
	vendedores,
	vendedor_selecionado,
	checkVendedor,
	form_novo_vendedor,
	openVendedorModal,
	alert_sem_vendedor,
	salvando_selecao_fornecedor,
	preSelecionarFornecedorVendedor,
	error_vendedor,
	handleError,
	preAdicionaFornecedor,
}) => {
	const open_vendedor_btn_class = vendedor_selecionado.id === -1
		? 'radio-button-block selected'
		: 'radio-button-block';
	const pre_sel_btn_class = salvando_selecao_fornecedor
		? 'btn-adicionar-salvando'
		: 'btn-adicionar';
	const btn_text = salvando_selecao_fornecedor
		? 'Salvando...'
		: 'Selecionar';

	return (
		<>
			{!fornecedor_ja_existe && vendedores.length > 0 ? (
				<p>Selecione o vendedor de sua preferência</p>
			) : (
				<h4>Vendedores</h4>
			)}

			{vendedores
				.filter(v => v.usuario.nome !== null && v.usuario.nome !== '')
				.map(vendedor => {
					const estah_selecionado = (
						vendedor_selecionado.id === vendedor.usuario.id
					);

					if (!fornecedor_ja_existe) {
						return (
							<VendedorButton
								key={vendedor.usuario.id}
								vendedor={vendedor.usuario}
								onClickFunction={() => checkVendedor(vendedor.usuario)}
								estah_selecionado={estah_selecionado}
							/>
						);
					}

					return (
						<VendedorRow
							key={vendedor.id}
							vendedor={vendedor.usuario}
							estah_selecionado={estah_selecionado}
						/>
					);
				})
			}

			{!form_novo_vendedor ? (
				!fornecedor_ja_existe && (
					<>
						<button
							type="button"
							styleName={open_vendedor_btn_class}
							onClick={openVendedorModal}
						>
							<div styleName="radio" />
							<div styleName="nome">Cadastrar novo vendedor</div>
						</button>
						{alert_sem_vendedor && (
							<div className="alert alert-warning" style={{ margin: '30px 0 0' }} role="alert">
								<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" /> Selecione o vendedor de sua preferência
							</div>
						)}
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							styleName={pre_sel_btn_class}
							disabled={salvando_selecao_fornecedor}
							onClick={() => preSelecionarFornecedorVendedor()}
						>
							{btn_text}
						</button>
					</>
				)
			) : (
				<>
					<div className="alert alert-danger" styleName={`error ${error_vendedor ? 'fade-in' : 'fade-out'}`} role="alert">
						Vendedor Já Existente <button type="button" onClick={handleError}>&times;</button>
					</div>
					<div styleName="form-novo-vendedor-wrapper">
						<div styleName="radio" />
						<div styleName="nome">Cadastrar novo vendedor</div>
						<CadastrarVendedor
							salvando_novo_fornecedor={salvando_selecao_fornecedor}
							cadastrarVendedorFunc={preAdicionaFornecedor}
						/>
					</div>
				</>
			)}
		</>
	);
};

TabVendedores.propTypes = {
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	vendedores: PropTypes.array.isRequired,
	vendedor_selecionado: PropTypes.object.isRequired,
	checkVendedor: PropTypes.func.isRequired,
	form_novo_vendedor: PropTypes.bool.isRequired,
	openVendedorModal: PropTypes.func.isRequired,
	alert_sem_vendedor: PropTypes.bool.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	preSelecionarFornecedorVendedor: PropTypes.func.isRequired,
	error_vendedor: PropTypes.bool.isRequired,
	handleError: PropTypes.func.isRequired,
	preAdicionaFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(TabVendedores, styles, { allowMultiple: true });
