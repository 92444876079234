/* eslint-disable no-nested-ternary */
import React from 'react';

export const CriandoCotacao = () => (
	<span>Status: Criando cotação</span>
);

export const ObtendoDadosCompra = () => (
	<span>Status: Obtendo dados da compra</span>
);

export const VerificandoInsumos = () => (
	<span>Status: Verificando insumos</span>
);

export const obtendoDadosStatus = (passo_atual_tipo) => (
	<>{
		passo_atual_tipo === 'GET_SIENGE_CREDENTIALS' ? (
			<div styleName="status-exportacao">

				<ObtendoDadosCompra />
			</div>
		) : passo_atual_tipo === 'GET_CONAZ_PURCHASE_DETAILS' ? (
			<div styleName="status-exportacao">

				<ObtendoDadosCompra />
			</div>

		) : passo_atual_tipo === 'GET_CONAZ_ALL_PURCHASE_ITEMS_DETAILS' ? (
			<div styleName="status-exportacao">

				<ObtendoDadosCompra />
			</div>

		) : passo_atual_tipo === 'GET_CONAZ_PURCHASE_ITEM_DETAILS' ? (
			<div styleName="status-exportacao">

				<ObtendoDadosCompra />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_ALL_RESOURCE_ITEMS_DETAILS' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_RESOURCE_ITEM_DETAILS' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_PURCHASE_REQUEST_INFO' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_PURCHASE_REQUEST_ITEMS' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_ALL_ITEMS_DELIVERY_REQUIREMENTS' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'GET_SIENGE_DELIVERY_REQUIREMENTS' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'VALIDATE_ALL_PURCHASE_ITEMS_WITH_REQUEST' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'VALIDATE_PURCHASE_ITEM_WITH_REQUEST' ? (
			<div styleName="status-exportacao">

				<VerificandoInsumos />
			</div>

		) : passo_atual_tipo === 'CREATE_SIENGE_QUOTATION' ? (
			<div styleName="status-exportacao">
				<CriandoCotacao />
			</div>
		) : passo_atual_tipo === 'INSERT_ALL_ITEMS_IN_SIENGE_QUOTATION' ? (
			<div styleName="status-exportacao">
				<CriandoCotacao />
			</div>
		) : passo_atual_tipo === 'INSERT_ITEM_IN_SIENGE_QUOTATION' ? (
			<div styleName="status-exportacao">
				<CriandoCotacao />
			</div>
		) : passo_atual_tipo === 'INSERT_SUPPLIER_IN_ALL_SIENGE_QUOTATION_ITEMS' ? (
			<div styleName="status-exportacao">
				<CriandoCotacao />
			</div>
		) : passo_atual_tipo === 'INSERT_SUPPLIER_IN_SIENGE_QUOTATION_ITEM' ? (
			<div styleName="status-exportacao">
				<CriandoCotacao />
			</div>
		) : passo_atual_tipo === 'CREATE_SIENGE_NEGOTIATION' ? (
			<span>Status: Inserindo dados na negociação</span>
		) : passo_atual_tipo === 'ADD_DATA_TO_SIENGE_NEGOTIATION' ? (
			<span>Status: Inserindo dados na negociação</span>
		) : passo_atual_tipo === 'ADD_VALUES_TO_ALL_SIENGE_ITEM_NEGOTIATIONS' ? (
			<span>Status: Inserindo dados na negociação</span>
		) : passo_atual_tipo === 'ADD_VALUES_TO_SIENGE_ITEM_NEGOTIATION' ? (
			<span>Status: Inserindo dados na negociação</span>
		) : passo_atual_tipo === 'POST_SIENGE_QUOTATION_ID_TO_CONAZ' ? (
			<span>Status: Finalizando</span>
		) : ''
	}
	</>
);
