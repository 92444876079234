import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { _delete } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

class ItensLeftColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deleting_oc: 0,
		};
		this.showTooltip = this.showTooltip.bind(this);
		this.hideTooltip = this.hideTooltip.bind(this);
		this.openExportaExcel = this.openExportaExcel.bind(this);
		this.deleteOC = this.deleteOC.bind(this);
		this.expandItensMobile = this.expandItensMobile.bind(this);
	}

	// eslint-disable-next-line
	showTooltip(tooltip) {
		document.getElementById(tooltip).className = 'tooltip top in';
	}

	// eslint-disable-next-line
	hideTooltip(tooltip) {
		document.getElementById(tooltip).className = 'tooltip top';
	}

	openExportaExcel() {
		this.props.updateModals('exporta_excel', true);
	}

	deleteOC(e) {
		e.preventDefault();
		const { opcoes_de_compra, match, history } = this.props;
		/**
		 * Verifica se combinação já foi salva - não é rascunho
		 */
		const comb_to_edit = Number(match.params.comb_id);
		const oc = opcoes_de_compra.by_id[comb_to_edit];
		if (!oc.rascunho) {
			history.push(`/cotacao/${match.params.id}/qc`);
			return;
		}
		/**
		 * É rascunho, apagar combinação
		 */
		this.setState({ deleting_oc: 1 });
		_delete(`/conaz/v2/opcao_compra/${match.params.comb_id}`).then(() => {
			history.push(`/cotacao/${match.params.id}/qc`);
		}).catch(error => {
			handleRequestErrors(error);
			history.push(`/cotacao/${match.params.id}/qc`);
		});
	}

	expandItensMobile(e) {
		const item_order = Number(e.currentTarget.getAttribute('data-order'));
		const item_id = Number(e.currentTarget.getAttribute('data-id'));
		const { updateModals, openItemAnaliseOnMobile } = this.props;
		openItemAnaliseOnMobile(item_order, item_id, updateModals);
	}

	render() {
		const {
			deleting_oc,
		} = this.state;
		const {
			cotacao,
			opcoes_de_compra,
			itens_analise,
			window_height,
			scroll_left,
			openCombinacaoSideBar,
			edition_mode,
			mobile,
		} = this.props;

		const { pedido_id, titulo } = cotacao;
		const { all_ids, by_id } = itens_analise;

		const shadow_class = scroll_left === 0 ? '' : ' shadow';
		const itens_class = `itens${shadow_class}`;

		return (
			<div styleName={itens_class}>
				{!edition_mode ? (
					<div styleName="qc-basic-info">
						{!mobile ? (
							<>
								<div styleName="cotacao-info">
									<p styleName="titulo" title={titulo}>{titulo}</p>
									<p styleName="codigo">#{pedido_id}</p>
									<Link to={`/cotacao/${pedido_id}`} styleName="voltar"><i className="fa fa-angle-left" aria-hidden="true" /> Voltar</Link>
								</div>
								{opcoes_de_compra.all_ids.length > 0 && (
									<div styleName="menu-bar">
										<div styleName="title">Ações:</div>
										<div id="tooltip_comb" className="tooltip top" role="tooltip" style={{ top: '-22px', left: '12px' }}>
											<div className="tooltip-arrow" style={{ borderTopColor: '#0487bd' }} />
											<div className="tooltip-inner" style={{ backgroundColor: '#0076D6' }}>Combinar fornecedores</div>
										</div>
										<button
											type="button"
											styleName="icons"
											onClick={openCombinacaoSideBar}
											onMouseEnter={() => this.showTooltip('tooltip_comb')}
											onMouseLeave={() => this.hideTooltip('tooltip_comb')}>
											<i className="fa fa-window-restore" aria-hidden="true" />
										</button>
										<div styleName="division" />
										<div id="tooltip_excel" className="tooltip top" role="tooltip" style={{ top: '-22px', left: '49px' }}>
											<div className="tooltip-arrow" style={{ borderTopColor: '#0487bd' }} />
											<div className="tooltip-inner" style={{ backgroundColor: '#0076D6' }}>Exportar no formato Excel</div>
										</div>
										<button
											type="button"
											styleName="icons"
											onClick={this.openExportaExcel}
											onMouseEnter={() => this.showTooltip('tooltip_excel')}
											onMouseLeave={() => this.hideTooltip('tooltip_excel')}>
											<i className="fa fa-download" aria-hidden="true" />
										</button>
									</div>
								)}
							</>
						) : (
							<>
								<div styleName="cotacao-info">
									<Link to={`/cotacao/${pedido_id}`} styleName="voltar"><i className="fa fa-angle-left" aria-hidden="true" style={{ fontSize: '21px' }} /></Link>
								</div>
								<div styleName="menu-bar">
									<button
										type="button"
										styleName="icons"
										onClick={openCombinacaoSideBar}>
										<i className="fa fa-window-restore" aria-hidden="true" />
									</button>
								</div>
							</>
						)}
					</div>
				) : (
					<div styleName="qc-basic-info">
						<div styleName="cotacao-info" style={{ paddingTop: '70px' }}>
							{deleting_oc === 1 ? (
								<span styleName="voltar lighter">
									<i className="fa fa-angle-left" aria-hidden="true" /> Descartando...
								</span>
							) : (
								<Link to={`/cotacao/${pedido_id}/qc`} styleName="voltar lighter" onClick={this.deleteOC}>
									{!mobile ? (
										<>
											<i className="fa fa-angle-left" aria-hidden="true" /> Descartar mudanças e voltar
										</>
									) : (
										<i className="fa fa-angle-left" aria-hidden="true" style={{ fontSize: '21px' }} />
									)}
								</Link>
							)}
						</div>
					</div>
				)}
				{/* <div styleName="shadow-top-column" /> */}
				<div id="scroll_itens" styleName="i-list" style={{ height: window_height }}>
					{all_ids.map((item_id, i) => {
						const item = by_id[item_id];
						const order = i + 1;
						const { item_de_pedido } = item;
						const item_nome = ((item_de_pedido.item_preenchido || {}).item || {}).nome || '';
						const item_quantidade = item_de_pedido.quantidade || 0;
						const item_unidade = item_de_pedido.unidade || 'un';

						const row_m_class = !mobile ? '' : ' collapsed';
						const row_class = `item${row_m_class}`;

						const c_ordenadas = ((item_de_pedido.item_preenchido || {}).caracteristicas_preenchidas || [])
							.filter(c => c.caracteristica.visao_construtor !== 0)
							.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem);

						const ordem_m_class = !mobile ? '' : ' collapsed';
						const ordem_class = order.toString().length === 1
							? `ordem${ordem_m_class}`
							: `ordem${ordem_m_class} n2`;

						return (
							<div key={item.id} id={`item_left_${item.id}_${order}`} styleName={row_class}>
								{mobile && (
									<button
										id={`item_left_${item.id}_${order}_expand`}
										styleName="expand-itens-pedido-mobile"
										data-order={order}
										data-id={item.id}
										onClick={this.expandItensMobile} />
								)}
								<div styleName={ordem_class}>{order}</div>
								<div styleName="nome">{item_nome}</div>
								<div styleName="caracteristica">
									<span>Quantidade: </span> {item_quantidade} {item_unidade}
								</div>
								{c_ordenadas.map((c, i2) => {
									const { nome } = c.caracteristica;
									const valor = c.opcoes_preenchidas.map(op => ((op.unidade !== '' && op.unidade !== null && op.unidade !== undefined) ? `${op.valor} ${op.unidade}` : op.valor)).join(', ');
									return valor !== '' ? ( // eslint-disable-next-line react/no-array-index-key
										<div key={i2} styleName="caracteristica"><span>{nome}:</span> {valor}</div>
									) : false;
								})}
								{item_de_pedido.observacoes !== '' && (
									<div styleName="caracteristica">
										<span>Observações: </span> {item_de_pedido.observacoes}
									</div>
								)}
								{item_de_pedido.observacoes_internas !== '' && (
									<div styleName="caracteristica">
										<span>Observações internas: </span> {item_de_pedido.observacoes_internas}
									</div>
								)}
							</div>
						);
					})}
					<div styleName="bottom-blank-space" />
				</div>
			</div>
		);
	}
}

ItensLeftColumn.propTypes = {
	cotacao: PropTypes.object.isRequired,
	opcoes_de_compra: PropTypes.object.isRequired,
	itens_analise: PropTypes.object.isRequired,
	window_height: PropTypes.number.isRequired,
	scroll_left: PropTypes.number.isRequired,
	openCombinacaoSideBar: PropTypes.func,
	edition_mode: PropTypes.bool,
	updateModals: PropTypes.func,
	mobile: PropTypes.bool.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	openItemAnaliseOnMobile: PropTypes.func.isRequired,
};

ItensLeftColumn.defaultProps = {
	openCombinacaoSideBar: () => {},
	edition_mode: false,
	updateModals: () => {},
};

const _ItensLeftColumn = CSSModules(ItensLeftColumn, styles, { allowMultiple: true });
export default withRouter(_ItensLeftColumn);
