import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getLocalstorageData } from '../components/_functions/_getLocalstorageData';

// axiosRetry(axios, { retries: 3 });

/* ------------------------------------------------------ *\
	get
\* ------------------------------------------------------ */
export function get(url, data = {}) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = getLocalstorageData('user', 'token');
	const params = { ...data };
	const instance = axios.create({
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'content-type': 'application/json',
		},
		maxRedirects: 0,
		// validateStatus: status => (status >= 200 && status < 300) || status === 404,
	});

	let retries = 0;
	axiosRetry(instance, {
		retries: 3,
		retryCondition: (error) => {
			const { config } = error;
			if (!config) return false;
			retries++;
			if (retries >= 3) {
				retries = 0;
				return false;
			}
			return true;
		},
	});

	return instance.get(url, { params });
}
