import { montarItem } from './_montarItem';

export const refatoraListaPlanilhaItens = (listaItens, unico = false) => {
	const check_empty = ((listaItens || [])[0] || {}).id === undefined;
	if (check_empty) return [];
	/**
	 * Função para pegar itens com detalhes e criar novos itens a partir dos detalhes
	 */
	const novaListaItens = [];
	let i = 0;

	if (!unico) {
		listaItens.forEach(item => {
			if (item.detalhes.length > 0) {
				item.detalhes.forEach(detalhe => {
					if (detalhe > 0) {
						const detalhes = item.detalhes.filter(d => d.id === detalhe.id);
						const _item = { ...item, detalhes };
						novaListaItens.push(montarItem(i, _item, detalhe));
						i++;
					}
					const _item = { ...item, detalhe };
					novaListaItens.push(montarItem(i, _item, detalhe));
				});
				i++;

				return;
			}

			/**
			 * Verifica se item associado está vazio ou com erro
			 */
			const item_conaz_id = ((item.itens_associados[0] || {}).item || {}).id || null;
			const _item = (item.itens_associados.length > 0 && item_conaz_id === null)
				? { ...item, itens_associados: [] }
				: { ...item };

			novaListaItens.push(montarItem(i, _item, null));
			i++;
		});
	} else {
		listaItens.detalhes.forEach(detalhe => {
			const detalhes = listaItens.detalhes.filter(d => d.id === detalhe.id);
			const _item = { ...listaItens, detalhes };
			novaListaItens.push(montarItem(i, _item, detalhe));
			i++;
		});
		const _item = { ...listaItens, detalhes: [] };
		novaListaItens.push(montarItem(i, _item, null));
		i++;
	}

	return novaListaItens;
};
