import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { addZeroToCodigo } from '../../_functions/_addZeroToCodigo';

const CompraRowCotacao = ({ compra }) => {
	const { pedido_id } = compra;
	const cotacao_codigo = (pedido_id !== '' && pedido_id !== null)
		? `#${addZeroToCodigo(pedido_id)}`
		: '-';

	return (
		<div styleName="cotacao">
			Cotação
			<p style={pedido_id === '' || pedido_id === null ? { textAlign: 'center', paddingRight: '20px' } : {}}>{cotacao_codigo}</p>
		</div>
	);
};

CompraRowCotacao.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowCotacao, styles, { allowMultiple: true });
