export const replaceSearchTextInte = (typed, itens_results) => {
	const typed_words = typed
		.replace(/[~/\\#,+()$~.'"*?<>{}]/g, '')
		.trim()
		.split(' ');
	const _itens_results = itens_results.map(item => ({
		...item,
		nome: item.nome.replace(/~/g, ''),
	}));

	for (let i = 0; i < _itens_results.length; i++) {
		for (let i2 = 0; i2 < typed_words.length; i2++) {
			const regexp = new RegExp(typed_words[i2], 'i');
			if (_itens_results[i].nome.match(regexp)) {
				const typed_match = _itens_results[i].nome.match(regexp)[0];
				const nome = _itens_results[i].nome.replace(regexp, `~${typed_match}~~`);
				_itens_results[i].nome = nome;
			}
		}
		const add_span = _itens_results[i].nome
			.replace(/~~/g, '</span>')
			.replace(/~/g, '<span>');
		_itens_results[i].nome = add_span;
	}

	return _itens_results;
};
