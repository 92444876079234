import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

class OpenItensMobile extends Component {
	state = {};

	render() {
		const {
			number_of_items,
			openItensMobile,
		} = this.props;

		return (
			<div styleName="info-row">
				<button
					type="button"
					styleName="ver-itens-button"
					onClick={openItensMobile}>
					Ver lista de itens na compra ({number_of_items})
				</button>
			</div>
		);
	}
}

OpenItensMobile.propTypes = {
	number_of_items: PropTypes.number.isRequired,
	openItensMobile: PropTypes.func.isRequired,
};

export default CSSModules(OpenItensMobile, styles, { allowMultiple: true });
