import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import SiengeObras from './SiengeObras';

// Functions
import { _post, _put } from '../../../components/_functions/_requests';

class SiengeObrasContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: 1,
			infoMsg: '',
			edicao: false,
			obras_associadas: [],
			logging_state: 0,
			request_state: 0,
			salvando: 0,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.handleEdition = this.handleEdition.bind(this);
	}

	componentDidMount() {
		document.title = 'Integração - Conaz';
		this.checkLoad(this.props.user);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.checkLoad(nextProps.user);
	}

	checkLoad(user) {
		const { cliente_id, sienge_liberado } = user;
		const { history } = this.props;

		if (cliente_id !== 0 && !sienge_liberado) {
			history.replace('/cotacoes');
			return;
		}

		if (cliente_id !== 0) {
			this.buscaObras(user);
		}
	}

	buscaObras(user) {
		const { cliente_id } = user;
		const url = '/conaz/v2/integracoes/obras_associadas/atualizar';
		const params = { swi: 1, cliente_id };
		_post(url, params).then(response => {
			this.setState({ obras_associadas: response.data, loading: 2 });
		}).catch(() => {
			this.setState({ loading: 2 });
		});
	}

	handleEdition(e) {
		const { obras_associadas } = this.state;
		const newArr = obras_associadas.map(obra => ((obra.id.toString() === e.target.id.toString())
			? { id: e.target.id, nome: e.target.name, id_no_swi: e.target.value, edicao: true }
			: obra));
		this.setState({ obras_associadas: newArr, edicao: true });
	}

	handleEnter(e) {
		if (e.keyCode === 13) {
			this.handleSubmit();
		}
	}

	handleSubmit() {
		this.setState({ salvando: 1 });
		const { user, updateUser, history } = this.props;
		const { obras_associadas } = this.state;
		const all_urls = obras_associadas.map(obra => {
			const { id, id_no_swi } = obra;
			const params = {
				swi: 1,
				cliente_id: user.cliente_id,
				id_no_swi,
			};
			const url = `/conaz/v2/integracoes/obras_associadas/${id}`;
			return _put(url, params);
		});

		Promise.all(all_urls).then(() => {
			const sienge = {
				...user.sienge,
				obras_configuradas: true,
			};
			updateUser(['sienge'], sienge);
			history.push('/integracao/sienge');
		}).catch(error => {
			const conaz_message = ((error.response || {}).data || {}).message || '';
			this.setState({
				salvando: 0,
				logging_state: 1,
				infoMsg: conaz_message !== '' ? conaz_message : 'Erro ao conectar com SIENGE',
			});
		});
	}

	atualizarObra(obra) {
		this.setState({ request_state: 1 });
		const { user, history } = this.props;
		const { id, id_no_swi } = obra;
		const params = {
			swi: 1,
			cliente_id: user.cliente_id,
			id_no_swi,
		};
		const url = `/conaz/v2/integracoes/obras_associadas/${id}`;
		_put(url, params).then(() => {
			history.push('/integracao/sienge');
		}).catch((err) => {
			this.setState({
				logging_state: 1,
				infoMsg: (err.response ? err.response.data.message : 'Erro ao conectar com SIENGE'),
			});
		});
	}

	render() {
		return (
			<LayoutWrapper {...this.props}>

				<SiengeObras
					{...this.state}
					{...this.props}
					handleSubmit={this.handleSubmit}
					handleEdition={this.handleEdition}
					handleEnter={this.handleEnter} />

			</LayoutWrapper>
		);
	}
}

SiengeObrasContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(SiengeObrasContainer));
