
export const setPrazoEntregaLabel = (rc) => {
	const transporte = rc.transporte || 0;
	return transporte === 2
		? 'Prazo para retirada'
		: 'Prazo de entrega';
};

export const setPrazoEntregaView = (rc) => {
	const prazo_de_entrega = rc.prazo_de_entrega || null;
	const transporte = rc.transporte || 0;
	const transporte_proprio = rc.transporte_proprio || 0;

	if (transporte === 1 && transporte_proprio === 2) {
		return 'A Combinar';
	}

	if (prazo_de_entrega === null) {
		return 'A Combinar'; // Não entrega
	}

	return prazo_de_entrega === 1
		? `${prazo_de_entrega} dia útil`
		: `${prazo_de_entrega} dias úteis`;
};
