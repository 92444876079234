import * as Types from './types';

export const limpaEstadoAssociaObra = () => ({
	type: Types.ASSOCIA_OBRA_GET_LIMPA_ESTADO,
});

export const getObra = (id) => ({
	type: Types.ASSOCIA_OBRA_GET_OBRA_REQUEST,
	payload: { id },
});

export const getObraSuccess = (data) => ({
	type: Types.ASSOCIA_OBRA_GET_OBRA_SUCCESS,
	payload: { data },
});

export const getObraError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_GET_OBRA_ERROR,
	payload: { erro_motivo },
});

// Edit Obra
export const editObraSelecionado = (data) => ({
	type: Types.ASSOCIA_OBRA_EDIT_OBRA_REQUEST,
	payload: { data },
});

// Edit status obra
export const alteraStatusObraVisualizacao = (data) => ({
	type: Types.ASSOCIA_OBRA_STATUS_VISUALIZACAO,
	payload: { data },
});

export const alteraStatusObraAtivo = (data) => ({
	type: Types.ASSOCIA_OBRA_STATUS_ATIVO,
	payload: { data },
});


export const editObraSelecionadoSuccess = (data) => ({
	type: Types.ASSOCIA_OBRA_EDIT_OBRA_SUCCESS,
	payload: { data },
});

export const editObraSelecionadoError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_EDIT_OBRA_ERROR,
	payload: { erro_motivo },
});

export const openEditObraSelecionada = () => ({
	type: Types.ASSOCIA_OBRA_OPEN_EDIT_OBRA_DRAWER,
});

export const closeEditObraSelecionada = () => ({
	type: Types.ASSOCIA_OBRA_CLOSE_EDIT_OBRA_DRAWER,
});

// Associa empresa
export const associaEmpresaObraSelecionada = (obra_id, empresa_id, papeis, editMode) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_REQUEST,
	payload: { obra_id, empresa_id, papeis, editMode },
});

export const associaEmpresaObraSelecionadaSuccess = (data) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_SUCCESS,
	payload: { data },
});

export const associaEmpresaObraSelecionadaError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_ERROR,
	payload: { erro_motivo },
});

export const removeEmpresaAssociada = (obra_id, empresa_id) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_EMPRESA_REQUEST,
	payload: { obra_id, empresa_id },
});

export const removeEmpresaAssociadaSuccess = (empresa_id) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_EMPRESA_SUCCESS,
	payload: { empresa_id },
});

export const removeEmpresaAssociadaError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_EMPRESA_ERROR,
	payload: { erro_motivo },
});

export const associaEmpresaOpenDrawer = () => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_DRAWER,
});

export const associaEmpresaOpendEditDrawer = (data) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_OPEN_EDIT_DRAWER,
	payload: { data },
});

export const associaEmpresaCloseDrawer = () => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_EMPRESA_CLOSE_DRAWER,
});

// Associa usuario a obra
export const associaUsuarioObraSelecionada = (data, editMode) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_REQUEST,
	payload: { data, editMode },
});

export const associaUsuarioObraSuccess = (data) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_SUCCESS,
	payload: { data },
});

export const associaUsuarioObraError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_ERROR,
	payload: { erro_motivo },
});

export const removeUsuarioAssociado = (obra_id, user_id) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_USUARIO_REQUEST,
	payload: { obra_id, user_id },
});

export const removeUsuarioAssociadoSuccess = (user_id) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_USUARIO_SUCCESS,
	payload: { user_id },
});

export const removeUsuarioAssociadoError = (erro_motivo) => ({
	type: Types.ASSOCIA_OBRA_REMOVE_USUARIO_ERROR,
	payload: { erro_motivo },
});

export const associaUsuarioOpenDrawer = () => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_DRAWER,
});

export const associaUsuarioOpenEditDrawer = (data) => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_OPEN_EDIT_DRAWER,
	payload: { data },
});

export const associaUsuarioCloseDrawer = () => ({
	type: Types.ASSOCIA_OBRA_ASSOCIA_USUARIO_CLOSE_DRAWER,
});
