import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import VendedorButton from './VendedorButton';
import CadastrarVendedor from './CadastrarVendedor';

// Constants
import * as constants from './constants';

const TabVendedores = ({
	fornecedor,
	sem_preferencia_vendedor,
	checkVendedor,
	vendedores,
	openVendedorModal,
	vendedor_selecionado,
	codigo_ibge_obra,
	grupos_itens_pedido,
	cancelaFornecedorExistente,
	form_novo_vendedor,
	salvando_selecao_fornecedor,
	preSelecionarFornecedorVendedor,
	fornecedor_ja_existe,
	fornecedor_existente,
	preAdicionaFornecedor,
}) => (
	<>
		<p>Selecione o vendedor de sua preferência</p>
		<button
			type="button"
			styleName={sem_preferencia_vendedor ? 'radio-button-block selected' : 'radio-button-block'}
			onClick={() => checkVendedor({})}
		>
			<div styleName="radio" />
			<div styleName="nome">Não tenho preferência</div>
			{(vendedores.length === 1 && vendedores[0].nome === '') && (
				<div styleName="email">Cotações serão enviadas para: {vendedores[0].email}</div>
			)}
		</button>
		{vendedores.filter(v => v.nome !== null && v.nome !== '').map(vendedor => (
			<VendedorButton
				key={vendedor.id}
				vendedor={vendedor}
				exibir_status={constants.EXIBIR_STATUS_VENDEDOR}
				onClickFunction={() => checkVendedor(vendedor)}
				estah_selecionado={vendedor_selecionado.id === vendedor.id}
				sem_preferencia={sem_preferencia_vendedor}
				exibe_vendedor_selecionado={constants.FORCA_EXIBICAO_VENDEDOR_SELECIONADO}
				codigo_ibge_obra={codigo_ibge_obra}
				grupos_itens_pedido={grupos_itens_pedido}
			/>
		))}
		{!form_novo_vendedor ? (
			<>
				<button
					type="button"
					styleName="radio-button-block"
					onClick={() => openVendedorModal()}
				>
					<div styleName="radio" />
					<div styleName="nome">Cadastrar novo vendedor</div>
				</button>
				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					styleName={salvando_selecao_fornecedor ? 'btn-adicionar-salvando' : 'btn-adicionar'}
					disabled={salvando_selecao_fornecedor}
					onClick={preSelecionarFornecedorVendedor}
				>
					{salvando_selecao_fornecedor ? 'Salvando...' : 'Selecionar'}
				</button>
			</>
		) : (
			<div styleName="form-novo-vendedor-wrapper">
				<div styleName="radio" />
				<div styleName="nome">Cadastrar novo vendedor</div>
				<CadastrarVendedor
					salvando_novo_fornecedor={salvando_selecao_fornecedor}
					cadastrarVendedorFunc={(vendedor) => preAdicionaFornecedor(fornecedor, vendedor)}
				/>
				{fornecedor_ja_existe && (
					<div className="alert alert-warning" role="alert" style={{ marginTop: '25px', marginBottom: '0', paddingBottom: '5px' }}>
						<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" style={{ color: '#ffd700' }} /> Já existe um vendedor com este e-mail na empresa <b>{fornecedor_existente.nome}</b><br />
						<button
							type="button"
							className="btn btn-link"
							data-field="alert_apenas_compra_externa"
							data-value="false"
							onClick={cancelaFornecedorExistente}
						>
							Entendi!
						</button>
					</div>
				)}
			</div>
		)}
	</>
);

TabVendedores.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	sem_preferencia_vendedor: PropTypes.bool.isRequired,
	checkVendedor: PropTypes.func.isRequired,
	vendedores: PropTypes.array.isRequired,
	openVendedorModal: PropTypes.func.isRequired,
	vendedor_selecionado: PropTypes.object.isRequired,
	codigo_ibge_obra: PropTypes.string.isRequired,
	grupos_itens_pedido: PropTypes.array.isRequired,
	cancelaFornecedorExistente: PropTypes.func.isRequired,
	form_novo_vendedor: PropTypes.bool.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	preSelecionarFornecedorVendedor: PropTypes.func.isRequired,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	fornecedor_existente: PropTypes.object.isRequired,
	preAdicionaFornecedor: PropTypes.func.isRequired,
};

export default CSSModules(TabVendedores, styles, { allowMultiple: true });
