import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import RCRowDetails from './RCRowDetails';

// Functions
import { formatCurrency } from '../../_functions/_formatCurrency';

class CombRowDetails extends Component {
	constructor(props) {
		super(props);
		this.openInfo = this.openInfo.bind(this);
	}

	openInfo() {
		const { oc, openCloseRCs } = this.props;
		const uuid = oc.id !== null
			? oc.rcs_analise[0].id
			: oc.rcs_analise[0].rc.uuid;
		openCloseRCs(uuid);
	}

	render() {
		const {
			oc,
			order,
			loading_compras,
			rcs_abertos,
			openCloseRCs,
			compras,
		} = this.props;

		const preco_combinacao = oc.rcs_analise
			.reduce((result, current) => result + current.preco_selecionado_rc, 0);

		/**
		* Verifica se existe compra na combinação
		*/

		const info_open = oc.id !== null
			? rcs_abertos.filter(x => x === oc.rcs_analise[0].id).length > 0
			: rcs_abertos.filter(x => x === oc.rcs_analise[0].rc).length > 0;

		const { condicoes_de_pagamento } = oc.rcs_analise[0].rc;

		const comb_wrapper_class = !info_open ? 'comb-wrapper' : 'comb-wrapper expanded';

		return (
			<div className="col-xs-12">
				<div styleName={comb_wrapper_class}>
					<div styleName="order multi">C{order}</div>
					<div styleName="fornecedor">
						<div styleName="nome comb">Combinação {order}</div>
					</div>
					{window.innerWidth > 768 && (
						<div styleName="valor-condicao-comb" style={{ left: '0px' }}>
							<h3 styleName="preco"><b>Total: {formatCurrency(preco_combinacao, 2, 'R$')}</b></h3>
						</div>
					)}
					{(!info_open && condicoes_de_pagamento.length > 1) && (
						<button
							className="btn btn-clear-conaz round-border"
							styleName="button-criar-compras"
							style={{ top: '-4.5rem', boxShadow: 'none' }}
							onClick={condicoes_de_pagamento.length > 1 ? this.openInfo : null}>
							<i className="fa fa-caret-down" aria-hidden="true" style={{ color: '#BFBFBF', fontSize: '20px' }} />
						</button>
					)}
					{(info_open) && (
						<button
							className="btn btn-clear-conaz round-border"
							styleName="button-criar-compras"
							style={{ top: '-4.5rem', boxShadow: 'none' }}
							onClick={this.openInfo}>
							<i className="fa fa-caret-up" aria-hidden="true" style={{ color: '#BFBFBF', fontSize: '20px' }} />
						</button>
					)}
				</div>

				{oc.rcs_analise.map(rc_a => {
					const oc_novo = {
						...oc,
						rcs_analise: [{ ...rc_a }],
					};

					return (
						<RCRowDetails
							key={rc_a.id}
							order={0}
							oc={oc_novo}
							cotacao={this.props.cotacao}
							compras={compras}
							updateCompras={this.props.updateCompras}
							updateModals={this.props.updateModals}
							qc_foi_visto={this.props.qc_foi_visto}
							alertVerPdf={this.props.alertVerPdf}
							infoFornecedor={this.props.infoFornecedor}
							history={this.props.history}
							openAnexosFornecedor={this.props.openAnexosFornecedor}
							loading_compras={loading_compras}
							rcs_abertos={rcs_abertos}
							openCloseRCs={openCloseRCs}
							in_comb />
					);
				})}

			</div>
		);
	}
}

CombRowDetails.propTypes = {
	order: PropTypes.number.isRequired,
	oc: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	compras: PropTypes.array,
	updateCompras: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	qc_foi_visto: PropTypes.bool.isRequired,
	alertVerPdf: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	openAnexosFornecedor: PropTypes.func.isRequired,
	loading_compras: PropTypes.number.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
};

CombRowDetails.defaultProps = {
	compras: [],
};

const _CombRowDetails = CSSModules(CombRowDetails, styles, { allowMultiple: true });
export default withRouter(_CombRowDetails);
