import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class Header extends Component {
	handleCollapseAll = () => {
		const { itens, collapsed_items, updateState } = this.props;
		const { all_ids } = itens;
		const _collapsed_items = collapsed_items.length === all_ids.length
			? []
			: [...all_ids];
		updateState('collapsed_items', _collapsed_items);
	}

	render() {
		const {
			height,
			itens,
			collapsed_items,
			openItensSearch,
			is_editable,
			openItensMobile,
		} = this.props;
		const { all_ids } = itens;
		const collapseIcon = all_ids.length === collapsed_items.length
			? 'fa fa-compress'
			: 'fa fa-expand';

		return (
			<div styleName="header" style={{ height }}>
				<div styleName="nome">
					<span>Item</span>
					<button
						type="button"
						styleName="back-button"
						onClick={openItensMobile}>
						<i className="fa fa-reply" aria-hidden="true" />
						Voltar
					</button>
				</div>
				<div styleName="quantidade">Quantidade</div>
				<div styleName="valor-unitario">Valor unitário</div>
				<div styleName="desconto">Desconto</div>
				<div styleName="valor-total">Total</div>
				{is_editable ? (
					<div styleName="buttons">
						<button
							type="button"
							styleName="button-inline"
							onClick={this.handleCollapseAll}>
							<i className={collapseIcon} />
						</button>
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							styleName="add-item-button"
							onClick={openItensSearch}>
							+ Adicionar novo item
						</button>
					</div>
				) : (
					<div styleName="buttons">
						<button
							type="button"
							styleName="button-inline right"
							onClick={this.handleCollapseAll}>
							<i className={collapseIcon} />
						</button>
					</div>
				)}
			</div>
		);
	}
}

Header.propTypes = {
	height: PropTypes.number.isRequired,
	collapsed_items: PropTypes.array.isRequired,
	itens: PropTypes.object.isRequired,
	updateState: PropTypes.func.isRequired,
	openItensSearch: PropTypes.func.isRequired,
	is_editable: PropTypes.bool.isRequired,
	openItensMobile: PropTypes.func.isRequired,
};

export default CSSModules(Header, styles, { allowMultiple: true });
