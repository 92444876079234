import { exceptions } from '../_content/_exceptions';

const validaItem = _item => {
	const item = { ..._item };
	let is_validado = true;
	if (item.quantidade === 0 || item.quantidade === '') is_validado = false;
	if (item.unidade === '') is_validado = false;
	if (item.preferencia_por_active && item.preferencia_por === '') is_validado = false;
	for (let p = 0; p < item.propriedades.length; p++) {
		const propriedade = item.propriedades[p];
		if (propriedade.is_obrigatorio) {
			switch (propriedade.tipo_do_valor) {
			case 'texto':
				if (propriedade.valor_preenchido && propriedade.valor_preenchido.texto === '') is_validado = false;
				if (propriedade.unidades.length > 0 && propriedade.unidade === '') is_validado = false;
				break;
			case 'selecao':
				if (propriedade.valor_preenchido && propriedade.valor_preenchido.selecao === '') is_validado = false;
				if (propriedade.outro_valor && propriedade.unidades.length > 0 && propriedade.unidade === '') is_validado = false;
				break;
			case 'multipla_escolha':
				if (propriedade.valor_preenchido && propriedade.valor_preenchido.multipla_escolha.length === 0) is_validado = false;
				if (propriedade.outro_valor && propriedade.unidades.length > 0 && propriedade.unidade === '') is_validado = false;
				break;
			case 'numero':
				if (propriedade.valor_preenchido && propriedade.valor_preenchido.numero === '') is_validado = false;
				if (propriedade.unidades.length > 0 && propriedade.unidade === '') is_validado = false;
				break;
			default:
				break;
			}
		}
	}
	item.validado = is_validado;
	return item;
};

export const buildItensPedidoV3 = itens_de_pedido => {
	let _i = -1;
	const itens = itens_de_pedido.map((item, i) => {
		if (item.item_preenchido.item === undefined) {
			return {
				...item,
				quantidade: item.quantidade === null ? 0 : item.quantidade,
				item_preenchido: {},
				front: {
					key: i,
					has_error: false,
					validado: false,
					origem: '',
					item_de_planilha: false,
					planilha_pre_values: [],
				},
			};
		}
		const carac_preenchidas_by_id_1 = item.item_preenchido.item.caracteristicas
			.sort((a, b) => a.ordem - b.ordem)
			.reduce((r, c) => ({
				...r,
				[c.id]: {
					caracteristica: { ...c },
					opcoes_preenchidas: c.opcoes_preenchidas !== undefined ? c.opcoes_preenchidas : [],
				},
			}), {});
		const carac_preenchidas_by_id_2 = item.item_preenchido.caracteristicas_preenchidas
			.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem)
			.reduce((r, c) => ({
				...r,
				[c.caracteristica.id]: { ...c },
			}), {});
		const carac_preenchidas_by_id = { ...carac_preenchidas_by_id_1, ...carac_preenchidas_by_id_2 };
		const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(id => id);
		return {
			...item,
			quantidade: item.quantidade === null ? 0 : item.quantidade,
			item_preenchido: {
				...item.item_preenchido,
				caracteristicas_preenchidas: carac_preenchidas_by_id,
				caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
			},
			front: {
				key: i,
				has_error: false,
				validado: false,
				origem: '',
				item_de_planilha: false,
				planilha_pre_values: [],
			},
		};

		// const carac_preenchidas_by_id = item.item_preenchido.item.caracteristicas.reduce((r, c) => ({
		// 	...r, [c.id]: c,
		// }), {});
		// const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(id => id);
		// const _current = {
		// 	...current,
		// 	item_preenchido: {
		// 		...current.item_preenchido,
		// 		caracteristicas_preenchidas: carac_preenchidas_by_id,
		// 		caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
		// 	},
		// };
		// return { ...result, [current.id]: _current };
	}).sort((a, b) => a.ordem - b.ordem).map(item => {
		_i++;
		return {
			...item,
			front: {
				...item.front,
				key: _i,
			},
		};
	});
	// const all_ids = Object.keys(by_id).map(id => id);
	// return [by_id, all_ids];
	return itens;
};

export const buildItensPedido = cotacao => {
	let ordem = 0;
	const itens_de_pedido_new = cotacao.itens_de_pedido.map(item => {
		let nome = (item.item_id === 0 || item.item_id === null) ? item.pre_nome : item.nome;
		if (nome === '' && (item.item_id === 0 || item.item_id === null)) nome = item.nome; // eslint-disable-line prefer-destructuring
		const item_relacionado = item.is_relacionado.length > 0;
		let has_marca = false;
		const propriedades = item.propriedades.map(_propriedade => {
			const propriedade = {
				..._propriedade,
				outro_valor: _propriedade.outro_valor,
				outra_unidade: _propriedade.outra_unidade,
				valor_preenchido: {
					texto: '',
					numero: '',
					selecao: '',
					multipla_escolha: [],
					unidade: '',
				},
			};
			switch (propriedade.tipo_do_valor) {
			case 'texto':
			case 'selecao':
			case 'multipla_escolha':
			default:
				propriedade.valor_preenchido[propriedade.tipo_do_valor] = propriedade.valor;
				break;
			case 'numero':
				propriedade.valor_preenchido.numero = propriedade.valor;
				break;
			}
			propriedade.valor_preenchido.unidade = propriedade.unidade;
			if (propriedade.nome === 'Recomendação de Marca' || propriedade.nome === 'Marca') has_marca = true;
			return propriedade;
		});
		const new_item = {
			item_cadastrado: (item.item_id !== 0 || item.item_id !== null),
			item_relacionado,
			is_relacionado: item.is_relacionado,
			key: ordem,
			item_de_pedido_id: item.item_de_pedido_id,
			id: item.item_id,
			nome,
			quantidade: item.quantidade,
			unidade: item.unidade,
			outra_unidade: item.outra_unidade,
			unidades: item.unidades,
			observacao: item.observacao,
			propriedades,
			checked: false,
			has_error: false,
			validado: false,
			cliente_associou: item.cliente_associou,
			preferencia_por: item.preferencia_por,
			preferencia_por_active: false,
			origem: item.rascunho ? item.rascunho.origem : '',
			item_de_planilha: item.rascunho.item_de_planilha ? item.rascunho.item_de_planilha : false,
			planilha_pre_values: item.rascunho.planilha_pre_values ? item.rascunho.planilha_pre_values : [],
		};
		if (has_marca) new_item.preferencia_por_active = true;
		ordem++;
		return validaItem(new_item);
	});
	return itens_de_pedido_new;
};

export const buildLocalAnexos = anexos => {
	const files_new = [];
	for (let i = 0; i < anexos.length; i++) {
		let url_view = anexos[i].url;
		if (url_view.substring(0, 8) !== 'https://') {
			url_view = `https://${anexos[i].url}`;
		}
		const { criado_em, id, nome, tamanho, url, visto } = anexos[i];
		const single_file = {
			key: i,
			criado_em,
			id,
			nome,
			tamanho,
			url,
			visto,
			file: null,
			progress: 0,
			success: 1,
			error: false,
		};
		files_new.push(single_file);
	}
	return files_new;
};

const titleCase = str => (
	str.toLowerCase().split(' ').map(word => (exceptions[word] === undefined ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join(' ')
);

export const buildFornecedoresSelecionados = fornecedores => fornecedores.map(fornecedor => {
	const cidade = (fornecedor.endereco !== undefined && fornecedor.endereco !== null) ? fornecedor.endereco.cidade : '';
	return {
		id: fornecedor.id !== null ? fornecedor.id : 0,
		documento: fornecedor.documento !== '' ? fornecedor.documento : 0,
		nome: titleCase(fornecedor.nome),
		telefone: (fornecedor.telefone !== null && fornecedor.telefone !== undefined) ? fornecedor.telefone : '',
		email: fornecedor.email,
		cidade: titleCase(cidade),
	};
});
