import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


const StarsAvaliacao = ({ media, starSize }) => {
	const lastStarFraction = media % 1;
	const grayStars = [{ style: { fontSize: `${starSize}px`, color: '#f0f0f0', position: 'absolute', top: '0', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#f0f0f0', position: 'absolute', top: '0', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#f0f0f0', position: 'absolute', top: '0', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#f0f0f0', position: 'absolute', top: '0', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#f0f0f0', position: 'absolute', top: '0', width: `${starSize}px` } }];
	const stars = [{ style: { fontSize: `${starSize}px`, color: '#FFB800', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#FFB800', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#FFB800', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#FFB800', width: `${starSize}px` } },
		{ style: { fontSize: `${starSize}px`, color: '#FFB800', width: `${starSize}px` } }];
	const fullStars = Math.floor(media);
	for (let i = 0; i < fullStars; i++) {
		grayStars[i].style.clip = `rect(0,0,${starSize}px,${starSize / 2}px)`;
	}
	if (fullStars < grayStars.length) {
		if (lastStarFraction >= 0.5) {
			grayStars[fullStars].style.clip = `rect(0,${starSize}px,${starSize}px,${starSize * 0.5}px)`;
		}
	}
	const starContainer
	= { position: 'relative', width: `${starSize}px`, margin: '0 8px 0 0' };
	return <div styleName="stars-message">
		<div>
			<div style={starContainer}>
				<i className="fa fa-star" aria-hidden="true" style={grayStars[0].style} />
				<i className="fa fa-star" aria-hidden="true" style={stars[0].style} />
			</div>
			<div style={starContainer}>
				<i className="fa fa-star" aria-hidden="true" style={grayStars[1].style} />
				<i className="fa fa-star" aria-hidden="true" style={stars[1].style} />
			</div>
			<div style={starContainer}>
				<i className="fa fa-star" aria-hidden="true" style={grayStars[2].style} />
				<i className="fa fa-star" aria-hidden="true" style={stars[2].style} />
			</div>
			<div style={starContainer}>
				<i className="fa fa-star" aria-hidden="true" style={grayStars[3].style} />
				<i className="fa fa-star" aria-hidden="true" style={stars[3].style} />
			</div>
			<div style={starContainer}>
				<i className="fa fa-star" aria-hidden="true" style={grayStars[4].style} />
				<i className="fa fa-star" aria-hidden="true" style={stars[4].style} />
			</div>
		</div>
	</div>;
};

StarsAvaliacao.propTypes = {
	media: PropTypes.number.isRequired,
	starSize: PropTypes.number,
};

StarsAvaliacao.defaultProps = {
	starSize: 24,
};


export default CSSModules(StarsAvaliacao, styles, { allowMultiple: true });
