import * as Types from './types';

export const getImportStatus = (data, status) => ({
	type: Types.IMPORT_STATUS_REQUEST,
	payload: { data, status },
});

export const getImportStatusSuccess = data => ({
	type: Types.IMPORT_STATUS_SUCCESS,
	payload: { data },
});

export const getImportStatusError = error => ({
	type: Types.IMPORT_STATUS_ERROR,
	payload: { error },
});
