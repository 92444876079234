export const createItemBeforePost = (item, ordem = 0) => ({
	item_preenchido: {
		item: { ...item },
	},
	ordem,
	cliente_associou: true,
	incluso_em: 1,
	pre_quantidade: 0,
	quantidade: 0,
	outra_unidade: false,
});
