import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { calcValorItem } from '../../../../components/_parts/Itens/_subfunctions/calcValorItem';
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

class ResumoBottom extends Component {
	state = {};

	render() {
		const { compra, itens, handleEnvioSideBar } = this.props;
		const { all_ids, by_id } = itens;
		const desconto_geral = compra.condicao_de_pagamento.desconto || 0;
		const sub_total = all_ids.reduce((sum, id) => {
			const valores = calcValorItem(by_id[id]);
			const { total } = valores;
			return sum + total;
		}, 0);
		const total = sub_total - (sub_total * desconto_geral) / 100;

		const { is_editable } = compra;
		const { valor_transporte, custos_adicionais } = compra;
		const total_compra = (all_ids.length > 0 || is_editable)
			? total + valor_transporte + custos_adicionais
			: compra.valor_total || 0;

		return (
			<div id="resumo_bottom_compra" styleName="resumo-bottom-wrapper">
				<div styleName="resumo-bottom">
					<div styleName="block-1">
						<div styleName="sub-block itens">
							<div styleName="label">Subtotal dos itens</div>
							<div styleName="value blue">
								{formatCurrency(sub_total, 2, 'R$')}
							</div>
						</div>
						<div styleName="sub-block desconto">
							<div styleName="label">Desconto geral</div>
							<div styleName="value green">{desconto_geral}%</div>
						</div>
						<div styleName="division" />
					</div>

					<div styleName="block-2">
						<div styleName="sub-block transporte">
							<div styleName="label">Transporte</div>
							<div styleName="value blue">
								{formatCurrency(valor_transporte, 2, 'R$')}
							</div>
						</div>
						<div styleName="sub-block custos">
							<div styleName="label">Custos adicionais</div>
							<div styleName="value">
								{formatCurrency(custos_adicionais, 2, 'R$')}
							</div>
						</div>
						<div styleName="division" />
					</div>

					<div styleName="block-3">
						<div styleName="sub-block total">
							<div styleName="label total">Total</div>
							<div styleName="value total">
								{formatCurrency(total_compra, 2, 'R$')}
							</div>
						</div>
						{is_editable && (
							<div styleName="sub-block button">
								<button
									type="button"
									className="btn btn-secondary-conaz round-border"
									onClick={handleEnvioSideBar}
									style={{ height: 32, padding: '6px 30px 5px', right: '25px', top: '7px' }}
								>
									Revisar e concluir
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

ResumoBottom.propTypes = {
	compra: PropTypes.object.isRequired,
	itens: PropTypes.object.isRequired,
	handleEnvioSideBar: PropTypes.func.isRequired,
};

export default CSSModules(ResumoBottom, styles, { allowMultiple: true });
