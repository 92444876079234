/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import Popular from './Popular';
import Loading from '../../_loadings/Loading/Loading';

// Functions
import { _get } from '../../../_functions/_requests';

class Populares extends Component {
	componentDidMount() {
		this.getItensPopulares();
	}

	getItensPopulares() {
		const { populares, updateItensRaw } = this.props;
		const need_load = (populares || []).length === 0;
		if (need_load) {
			_get('/conaz/v2/modelos/1').then(response => {
				updateItensRaw({ populares: response.data.itens_preenchidos });
			}).catch(() => {
				updateItensRaw({ populares: null });
			});
		}
	}

	render() {
		const { populares, type, openItemEdition } = this.props;

		if (populares === null) {
			return (
				<div styleName="search-assist">
					<i className="fa fa-long-arrow-up" aria-hidden="true" />Digite acima o nome do item desejado.
				</div>
			);
		}

		if (populares.length === 0) {
			return (
				<>
					<h4 styleName="populares-title">Mais populares</h4>
					<div style={{ position: 'relative', float: 'left' }}><Loading /></div>
				</>
			);
		}

		return (
			<>
				<h4 styleName="populares-title">Mais populares</h4>
				<div styleName="search-results">
					{populares.map((item, i) => (
						<Popular
							key={i}
							item={item}
							type={type}
							openItemEdition={openItemEdition} />
					))}
				</div>
			</>
		);
	}
}

Populares.propTypes = {
	type: PropTypes.string.isRequired,
	populares: PropTypes.any.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	openItemEdition: PropTypes.func.isRequired,
};

export default CSSModules(Populares, styles, { allowMultiple: true });
