import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import InitialLoading from './InitialLoading';
import ExportErrorViews from './ExportErrorViews';

const CheckItensIntegrados = ({
	itens,
	setStep,
	setExportingError,
}) => {
	const [error, setError] = useState(false);

	useEffect(() => {
		function checkSemIntegrado() {
			const { all_ids, by_id } = itens;

			const sem_integrado = all_ids.filter(item_id => {
				const item = by_id[item_id] || {};
				if (item.excluido) return false;
				const integrado_id = (item.item_integrado || {}).id || null;
				return integrado_id === null;
			});

			if (sem_integrado.length > 0) {
				setError(true);
				setExportingError(true);
				return;
			}

			setStep(2);
		}

		checkSemIntegrado();
	}, []);

	if (error) {
		return (
			<ExportErrorViews error_type={2} />
		);
	}

	return <InitialLoading />;
};

CheckItensIntegrados.propTypes = {
	itens: PropTypes.object.isRequired,
	setStep: PropTypes.func.isRequired,
	setExportingError: PropTypes.func.isRequired,
};

export default CSSModules(CheckItensIntegrados, styles, { allowMultiple: true });
