import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import SearchItens from '../SearchItens/SearchItens';

class IENaoAssociado extends Component {
	static getDerivedStateFromProps(props) {
		const { item } = props;
		const { errors } = item.front || {};
		const error = (errors || [])
			.filter(e => e.type === 'importado_planilha')
			.length > 0;
		return { error };
	}

	state = {
		error: false,
	};

	render() {
		const { error } = this.state;
		const { item, autoSave } = this.props;
		const { id } = item;

		return (
			<>
				<div styleName="row-edition">
					{error && (
						<div className="tooltip top in" role="tooltip" style={{ marginTop: '-32px' }}>
							<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622', left: '10%' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Associe este item antes de enviar o pedido</div>
						</div>
					)}
					<h3>Que item é este?</h3>
					<div id={`tooltip-planilha-${id}`} className="tooltip bottom" role="tooltip" style={{ marginTop: '-5px', width: '170px' }}>
						<div className="tooltip-arrow" style={{ borderBottomColor: '#ee2622' }} />
						<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Associe este item antes de enviar o pedido</div>
					</div>
				</div>
				<div styleName="row-edition">
					<SearchItens
						type="itens_compra"
						add_or_update="update"
						item_on_edition={item}
						autoSave={autoSave} />
				</div>
			</>
		);
	}
}

IENaoAssociado.propTypes = {
	item: PropTypes.object.isRequired,
	// updateItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IENaoAssociado, styles, { allowMultiple: true });
