export const avaliacao_fornecedor = (nome_fornecedor) => ({
	comentarios: true,
	avaliacao_url: '/avaliacao/v2/fornecedor',
	comentario_url: '/avaliacao/v2/fornecedor/comentario',
	criterios: {
		nota_geral: {
			question: `Como foi sua experiência com o fornecedor ${nome_fornecedor}?`,
			type: 'rating',
			tipo_no_backend: null,
			value: null,
		},
		entrega: {
			question: 'A entrega foi dentro do {br} prazo?',
			type: 'bool',
			tipo_no_backend: 1,
			value: null,
		},
		boletos: {
			question: 'Os documentos (NFs, boletos, etc) foram entregues corretamente?',
			type: 'bool',
			tipo_no_backend: 2,
			value: null,
		},
		material: {
			question: 'Os materiais entregues estavam de acordo com a solicitação da compra?',
			type: 'bool',
			tipo_no_backend: 3,
			value: null,
		},
		danos: {
			question: 'Os materiais estavam em boas {br} condições de uso?',
			type: 'bool',
			tipo_no_backend: 4,
			value: null,
		},
	},
});

export const avaliacao_vendedor = (nome_vendedor) => ({
	comentarios: true,
	avaliacao_url: '/avaliacao/v2/usuario_fornecedor',
	comentario_url: '/avaliacao/v2/usuario_fornecedor/comentario',
	criterios: {
		nota_geral: {
			question: `Como você avalia sua compra com ${nome_vendedor}?`,
			type: 'rating',
			value: null,
		},
	},
});
