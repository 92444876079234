import moment from 'moment';
import 'moment/locale/pt-br';

import { buildItensPedidoV3, buildLocalAnexos } from '../../../components/_functions/_buildNovaCotacao';

export const formatCotacao = cotacao => {
	const prazo_de_resposta = cotacao.prazo_de_resposta !== null
		? cotacao.prazo_de_resposta
		: '2016-01-01';
	const entrega_desejada = cotacao.data_de_entrega_desejada !== null
		? cotacao.data_de_entrega_desejada
		: '2016-01-01';

	const existe_condicao_de_preferencia = (
		cotacao.condicao_de_preferencia.id !== undefined &&
		cotacao.condicao_de_preferencia.id !== null
	);
	const preferencia_de_pagamento = !existe_condicao_de_preferencia
		? {
			id: null,
		}
		: {
			...cotacao.condicao_de_preferencia,
			forma: cotacao.condicao_de_preferencia.forma || 0,
			prazo: cotacao.condicao_de_preferencia.prazo || 0,
			desconto: 0,
		};
	return {
		id: cotacao.id,
		codigo: cotacao.codigo,
		anexos: buildLocalAnexos(cotacao.anexos),
		itens: buildItensPedidoV3(cotacao.itens_de_pedido),
		titulo: cotacao.titulo,
		titulo_sugerido: cotacao.titulo_sugerido,
		prazo_entrega_qc: moment(prazo_de_resposta),
		data_entrega_obra: moment(entrega_desejada),
		obra: cotacao.obra.id,
		obra_completa: cotacao.obra,
		observacoes: cotacao.observacoes || '',
		observacoes_internas: cotacao.observacoes_internas || '',
		exibir_contato: cotacao.exibir_contato,
		prospeccao_extra_fornecedores: cotacao.prospeccao_extra_fornecedores,
		request_state_fornecedores: 1,
		status: cotacao.status,
		cancelado: cotacao.cancelado,
		em_aprovacao: cotacao.em_aprovacao,
		em_refinamento: cotacao.em_refinamento,
		preferencia_de_pagamento,
		visto_pelo_fornecedor: cotacao.visto_pelo_fornecedor,
		motivo_refinamento: cotacao.motivo_refinamento,
		codigo_ibge_obra: cotacao.obra.codigo_ibge,
		observacoes_ou_itens_alterados: cotacao.observacoes_ou_itens_alterados,
	};
};
