import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withToggle, withTooltip } from '../../../_hocs/hocs';
import ButtonToolTip from '../ButtonToolTip/ButtonToolTip';

const Tooltip = ({
	tooltipIsVisible,
	closeTooltip,
	openTooltip,
	tooltipMessage,
	positionTooltip,
	stylePosition,
	styleTooltip,
	otherCondition,
	styleArrow,
	children,
	...otherProps
}) => (
	<>
		{(tooltipIsVisible || otherCondition) &&
			<ButtonToolTip
				text={tooltipMessage}
				display={positionTooltip}
				styleArrow={{ ...styleArrow }}
				styleTooltip={{ ...styleTooltip }}
				{...{ ...stylePosition }}
				{...otherProps} />}
		<span
			onMouseEnter={openTooltip}
			onMouseLeave={closeTooltip}>
			{children}
		</span>
	</>
);


Tooltip.propTypes = {
	styleArrow: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	stylePosition: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	styleTooltip: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
	toggleIconName: PropTypes.string,
	tooltipIsVisible: PropTypes.bool.isRequired,
	closeTooltip: PropTypes.func.isRequired,
	openTooltip: PropTypes.func.isRequired,
	tooltipMessage: PropTypes.string,
	positionTooltip: PropTypes.string,
	otherCondition: PropTypes.bool,
	children: PropTypes.object.isRequired,
};

Tooltip.defaultProps = {
	toggleIconName: undefined,
	positionTooltip: undefined,
	tooltipMessage: '',
	otherCondition: false,
	styleTooltip: {},
	styleArrow: {},
	stylePosition: {
		arrow_left: '',
		margin_top: '',
		margin_left: '',
		width: 'auto',
	},
};

export default compose(
	withToggle,
	withTooltip,
)(Tooltip);
