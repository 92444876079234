const qc = {
	analise_id: 0,
	cotacao: {},
	itens_analise: {
		by_id: {},
		all_ids: [],
	},
	opcoes_de_compra: {
		by_id: {},
		all_ids: [],
	},
	compras: [],
};

export default qc;
