import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import { toInteger } from 'lodash';
import styles from './style.module.scss';

// Functions
import { setDecimalsToNumber } from '../../../components/_functions/_numbers';

class SelecionaTabelas extends Component {
	state = {
		tabela: '',
		alert_obrigatorio: false,
	};

	handleEdition = (e) => {
		const number_values = setDecimalsToNumber(e.target.value); // numeros
		const tabela = toInteger(number_values[1]); // string
		this.setState({ tabela, alert_obrigatorio: false });
	}

	startAtualizar = () => {
		const { tabela } = this.state;
		const { handleAtualizarItens } = this.props;

		if (tabela === '') {
			this.setState({ alert_obrigatorio: true });
			return;
		}

		handleAtualizarItens(tabela);
	}

	render() {
		const {
			tabela,
			alert_obrigatorio,
		} = this.state;
		const {
			itens,
			showTabelasToUpdate,
			error,
			error_message,
		} = this.props;

		const timeout_error = error_message === 'timeout';
		const other_error = error && !timeout_error;

		return (
			<>
				<h3 styleName="carregar-title">Carregar insumos do Sienge</h3>
				<p>Escolha de qual tabela de insumos do Sienge você deseja carregar seus insumos:</p>

				<div styleName="input-wrapper">
					{alert_obrigatorio && (
						<div className="tooltip top in" role="tooltip" style={{ marginTop: '-33px', marginLeft: '77px' }}>
							<div className="tooltip-arrow" style={{ borderTopColor: '#ee2622' }} />
							<div className="tooltip-inner" style={{ backgroundColor: '#ee2622' }}>Preencha o campo tabela</div>
						</div>
					)}
					<div styleName="left-icon" style={{ width: '130px' }}>
						Código da tabela
					</div>
					<input
						type="text"
						className="form-control input-md"
						styleName="input"
						value={tabela}
						onChange={this.handleEdition} />
				</div>

				{timeout_error && (
					<div className="alert alert-info" role="alert">
						<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />
						<span>Sua listagem de insumos está em atualização. Em alguns instantes os insumos estarão disponíveis</span>
					</div>
				)}

				{other_error && (
					<div className="alert alert-danger" role="alert">
						<i className="fa fa-exclamation-triangle fa-fw" aria-hidden="true" />
						{error_message !== '' ? (
							<span> {error_message}</span>
						) : (
							<span> Houve algum erro no carregamento de insumos do Sienge. Para evitar um uso de requisições além do seu plano, entre em contato com suporte reportando o erro.</span>
						)}
					</div>
				)}

				{itens.length > 0 && (
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						style={{ marginRight: '15px' }}
						onClick={showTabelasToUpdate}>
						Cancelar
					</button>
				)}
				<button
					type="button"
					className="btn btn-secondary-conaz round-border"
					onClick={this.startAtualizar}>
					Carregar insumos
				</button>
			</>
		);
	}
}

SelecionaTabelas.propTypes = {
	handleAtualizarItens: PropTypes.func.isRequired,
	itens: PropTypes.array.isRequired,
	showTabelasToUpdate: PropTypes.func.isRequired,
	error: PropTypes.bool.isRequired,
	error_message: PropTypes.string.isRequired,
};

export default CSSModules(SelecionaTabelas, styles, { allowMultiple: true });
