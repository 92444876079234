import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import StartExportSienge from './StartExportSienge';
import CheckItensIntegrados from './CheckItensIntegrados';
import CheckQuantidades from './CheckQuantidades';
import CriaObjetoSienge from './CriaObjetoSienge';
import CriaCotacaoSienge from './CriaCotacaoSienge';
import AdicionaItensCotacao from './AdicionaItensCotacao';
import AdicionaItensNegociacao from './AdicionaItensNegociacao';
import ConcluiExportacao from './ConcluiExportacao';

// Functions
import { defineStatusCompra } from '../../../../components/_functions/_defineStatusCompra';

const ModalExportSienge = ({
	logged_sienge,
	compra,
	itens,
	closeModal,
	swi_cotacao,
	cliente_id,
	sienge_liberado,
	redirectAfterConcluir,
	notificaUsuarioAposExportacao,
	updateItensRaw,
	updateItemById,
}) => {
	const [sienge_data, setSiengeData] = useState(swi_cotacao);
	const [step, setStep] = useState(0);
	const [exporting_error, setExportingError] = useState(false);
	const [force, setForce] = useState(false);
	const [force_cotacao, setForceCotacao] = useState(false);

	const compra_status = defineStatusCompra(compra);
	const compra_ja_exportada = (swi_cotacao || {}).status === 10;

	useEffect(() => {
		const pular_exportacao = (
			compra_ja_exportada
			&& compra_status === 'rascunho'
		);
		if (pular_exportacao) {
			setStep(7);
		}
	}, []);

	// bloqueia botão fechar durante exportação
	const closeFuncConcluir = step === 7
		? () => redirectAfterConcluir()
		: closeModal;
	const closeFunc = (step === 0 || exporting_error)
		? closeFuncConcluir
		: () => {};
	const show_close_btn = step === 0 || exporting_error;

	const auto_export = (
		sienge_liberado
		&& !compra_ja_exportada
		&& (
			compra_status === 'rascunho'
			|| compra_status === 'em_edicao'
		)
	);
	const props_to_pass = {
		logged_sienge,
		itens,
		auto_export,
		compra,
		cliente_id,
		setStep,
		sienge_data,
		setSiengeData,
		setExportingError,
		closeFunc,
		force,
		setForce,
		force_cotacao,
		setForceCotacao,
		redirectAfterConcluir,
		notificaUsuarioAposExportacao,
		updateItensRaw,
		updateItemById,
		closeModal,
	};

	const export_steps_views = {
		0: <StartExportSienge {...props_to_pass} />,
		1: <CheckItensIntegrados {...props_to_pass} />,
		2: <CheckQuantidades {...props_to_pass} />,
		3: <CriaObjetoSienge {...props_to_pass} />,
		4: <CriaCotacaoSienge {...props_to_pass} />,
		5: <AdicionaItensCotacao {...props_to_pass} />,
		6: <AdicionaItensNegociacao {...props_to_pass} />,
		7: <ConcluiExportacao {...props_to_pass} />,
	};

	return (
		<ModalPortal>
			<ModalWrapperPortal closeModal={closeFunc}>
				<>
					<div className="modal-content" styleName="modal-content">
						<div className="modal-body" styleName="modal-body">
							{show_close_btn && (
								<button
									type="button"
									className="close"
									styleName="close-button"
									data-dismiss="modal"
									aria-label="Close"
									onClick={closeFuncConcluir}>
									<span aria-hidden="true">&times;</span>
								</button>
							)}
							{export_steps_views[step]}
						</div>
					</div>
				</>
			</ModalWrapperPortal>
		</ModalPortal>
	);
};

ModalExportSienge.propTypes = {
	logged_sienge: PropTypes.bool.isRequired,
	cliente_id: PropTypes.number.isRequired,
	compra: PropTypes.object.isRequired,
	itens: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	swi_cotacao: PropTypes.object.isRequired,
	sienge_liberado: PropTypes.bool.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
	notificaUsuarioAposExportacao: PropTypes.func.isRequired,
	updateItensRaw: PropTypes.func.isRequired,
	updateItemById: PropTypes.func.isRequired,
};

export default CSSModules(ModalExportSienge, styles, { allowMultiple: true });
