import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const ExportErrorWrapper = ({ children }) => (
	<>
		<div styleName="titles">
			<div styleName="icon">
				<i className="fa fa-exclamation-circle fa-fw" styleName="alert-icon" aria-hidden="true" />
			</div>
			<h3>Não foi possível exportar sua compra</h3>
		</div>
		<>{children}</>
	</>
);

ExportErrorWrapper.propTypes = {
	children: PropTypes.object.isRequired,
};

const VEWrapper = CSSModules(ExportErrorWrapper, styles, { allowMultiple: true });

const ExportErrorViews = ({
	error_type,
	item_nome,
	cotacao_id,
	itens,
	closeFunc,
	// setForce,
	setForceCotacao,
	setStep,
}) => {
	// function exportWithoutSolicitacao() {
	// 	setForce(true);
	// 	setStep(4);
	// }

	function exportNovaCotacao() {
		setForceCotacao(true);
		setStep(1);
	}

	// function concluiSemExportar() {
	// 	setStep(7);
	// }

	switch (error_type) {
	case 0:
	default:
		return (
			<VEWrapper>
				<div styleName="content-error">
					Um problema inesperado ocorreu no início da exportação para o Sienge.<br />
					Contate nossa equipe de Suporte para obter ajuda.
				</div>
			</VEWrapper>
		);

	// DONE Conta não conectada
	case 1:
		return (
			<VEWrapper>
				<div styleName="content-error">
					Você não conectou sua conta Sienge. É necessário configurá-la na Conaz para continuar a exportação.<br /><br />
					<a href={`${window.location.origin}/integracao/sienge`} target="_blank" rel="noopener noreferrer">Clique aqui</a> para realizar essa configuração.
				</div>
			</VEWrapper>
		);


	// DONE Sem itens integrados, verificação do frontend
	case 2:
		return (
			<VEWrapper>
				<div styleName="content-error">
					É necessário associar os seus insumos Conaz aos Insumos Sienge para exportar a compra.<br /><br />
					<p styleName="error-title">O que fazer:</p>
					<p styleName="error-steps">1. Aqui na página de compra, clique diretamente no item na listagem de insumos.</p>
					<p styleName="error-steps">2. No canto superior direito da aba lateral, em <b>Insumo Sienge</b>, clique na seta para visualizar a barra de busca e realizar a associação do insumo correspondente.</p>
				</div>
			</VEWrapper>
		);

	// DONE Usuário no sienge não existe
	case 3:
		return (
			<VEWrapper>
				<div styleName="content-error">
					O <b>código</b> do seu <b>usuário pessoal</b> no Sienge está incorreto.<br /><br />
					<a href={`${window.location.origin}/integracao/sienge/login`} target="_blank" rel="noopener noreferrer">Clique aqui</a> para corrigir essa informação e depois tente exportar a compra novamente.
				</div>
			</VEWrapper>
		);

	// DONE SWI não foi encontrado
	case 4:
		return (
			<VEWrapper>
				<div styleName="content-error">
					Um problema inesperado ocorreu ao tentar se conectar o Sienge.<br />
					Contate nossa equipe de Suporte para obter ajuda.
				</div>
			</VEWrapper>
		);

	// DONE Obra associada não existe
	case 5:
		return (
			<VEWrapper>
				<div styleName="content-error">
					A obra selecionada para essa compra não existe no Sienge.<br /><br />
					<a href={`${window.location.origin}/integracao/sienge/obras`} target="_blank" rel="noopener noreferrer">Clique aqui</a> para corrigir ou cadastrar o código e depois tente exportar a compra novamente.
				</div>
			</VEWrapper>
		);

	// DONE Fornecedor não existe
	case 6:
		return (
			<VEWrapper>
				<div styleName="content-error">
					O fornecedor selecionado para esse compra não está <b>associado a um fornecedor do Sienge</b>.<br /><br />
					Aqui na página de compra, clique no fornecedor escolhido e associe-o a um fornecedor do Sienge para prosseguir com a exportação.
				</div>
			</VEWrapper>
		);

	// DONE Não tem item integrado
	case 7:
		return (
			<VEWrapper>
				<div styleName="content-error">
					O item <b>{item_nome}</b> não está associado a um insumo do Sienge.<br /><br />
					Aqui na página de compra, clique no item destacado e associe-o a um insumo do Sienge para prosseguir com a exportação.
				</div>
			</VEWrapper>
		);

	// DONE Unidade de medida está diferente ou incorreta
	case 8:
		return (
			<VEWrapper>
				<div styleName="content-error">
					A cotação <b>{cotacao_id}</b> foi criada, mas o Sienge não permitiu adicionar o item <b>{item_nome}</b> a ela, pois a unidade de medida utilizada não está cadastrada nesse insumo no Sienge.
				</div>
			</VEWrapper>
		);

	// DONE Não é possível cadastrar mais de um insumo de mesmo código
	case 9:
		return (
			<VEWrapper>
				<div styleName="content-error">
					O item <b>{item_nome}</b> já foi inserido na cotação do Sienge.
				</div>
			</VEWrapper>
		);

	// DONE Fornecedor Sienge não existe para esse item
	case 10:
		return (
			<VEWrapper>
				<div styleName="content-error">
					Fornecedor Sienge não existe para esse item ou o item já está associado a uma solicitação.<br />
					Contate nossa equipe de Suporte para obter ajuda.
				</div>
			</VEWrapper>
		);

	// DONE Negociação ou Cotação já existente para essa solicitação
	case 11: {
		const all_ids = itens.all_ids || [];
		const by_id = itens.by_id || {};
		const item_id_with_solicitacao_sienge = _.find(all_ids, (item_id) => {
			const pedido_no_swi = (by_id[item_id] || {}).pedido_no_swi || '';
			return pedido_no_swi !== '';
		});
		const solicitacao_sienge_id = item_id_with_solicitacao_sienge !== undefined
			? by_id[item_id_with_solicitacao_sienge].pedido_no_swi
			: '';

		return (
			<VEWrapper>
				<div styleName="content-error">
					<p>Já existe uma cotação ou pedido de compra vinculado à solicitação {solicitacao_sienge_id} no Sienge.</p>
					<p>Contate nossa equipe de suporte para obter ajuda.</p>
					<div styleName="buttons">
						{/* <button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={exportWithoutSolicitacao}>
							Exportar sem vínculo de solicitação
						</button> */}
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="cancelar-button"
							onClick={closeFunc}>
							Cancelar exportação
						</button>
					</div>
				</div>
			</VEWrapper>
		);
	}

	// DONE Erro ao concluir a compra
	case 12: {
		return (
			<VEWrapper>
				<div styleName="content-error">
					Um problema inesperado ocorreu ao concluir sua compra na Conaz.<br />
					Contate nossa equipe de Suporte para obter ajuda.
				</div>
			</VEWrapper>
		);
	}

	// DONE Cotação já criada no Sienge
	case 13: {
		return (
			<VEWrapper>
				<div styleName="content-error">
					<p>Já existe uma cotação ({cotacao_id}) vinculada a essa compra no Sienge.</p>
					<p>Para prosseguir, você possui estas opções:</p>
					<p>1) Exportar novamente após excluir manualmente a cotação no Sienge</p>
					{/* <p>2) Exportar novamente sem criar vínculo com a solicitação</p> */}
					<p>2) Voltar</p>
					<div styleName="buttons">
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={exportNovaCotacao}>
							Excluí a cotação e quero exportar novamente
						</button>
						{/* <button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={exportWithoutSolicitacao}>
							Não excluí a cotação e quero exportar sem vínculo
						</button> */}
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="cancelar-button"
							onClick={closeFunc}>
							Voltar
						</button>
					</div>
				</div>
			</VEWrapper>
		);
	}

	// DONE Quantidade cotada maior que solicitada
	case 14: {
		return (
			<VEWrapper>
				<div styleName="content-error">
					<p><b>Quantidade maior que a solicitada</b></p>
					<p>O insumo {item_nome} possui uma quantidade maior do que a que constava na importação.</p>
					<p>O Sienge não permite que essa exportação seja feita.</p>
					<p>Você pode alterar a quantidade do item para equivaler à solicitação ou alterar a solicitação para equivaler ao que está sendo exportado.</p>
					<div styleName="buttons">
						{/* <button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={exportWithoutSolicitacao}>
							Exportar sem vínculo de solicitação
						</button> */}
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="cancelar-button"
							onClick={closeFunc}>
							Voltar
						</button>
					</div>
				</div>
			</VEWrapper>
		);
	}
	}
};

ExportErrorViews.propTypes = {
	error_type: PropTypes.number,
	item_nome: PropTypes.string,
	cotacao_id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	itens: PropTypes.object,
	closeFunc: PropTypes.func,
	setForce: PropTypes.func,
	setForceCotacao: PropTypes.func,
	setStep: PropTypes.func,
};

ExportErrorViews.defaultProps = {
	error_type: 0,
	item_nome: '',
	cotacao_id: 0,
	itens: {},
	closeFunc: () => {},
	setForce: () => {},
	setForceCotacao: () => {},
	setStep: () => {},
};

export default CSSModules(ExportErrorViews, styles, { allowMultiple: true });
