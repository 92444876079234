import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class SideBar extends Component {
	state = {
		show: false,
	};

	componentDidMount() {
		setTimeout(() => this.setState({ show: true }), 10);
		setTimeout(() => {
			window.addEventListener('keyup', this.escClose);
		}, 400);
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.escClose);
	}

	escClose = (e) => {
		const { openSideBar } = this.props;
		if (e.keyCode === 27) openSideBar();
	}

	render() {
		const { visible, children } = this.props;
		const { show } = this.state;
		const side_bar_class = show
			? 'side-bar-wrapper in'
			: 'side-bar-wrapper';

		if (visible) {
			return (
				<div styleName={side_bar_class}>
					{React.cloneElement(children)}
				</div>
			);
		}

		return null;
	}
}

SideBar.propTypes = {
	visible: PropTypes.bool,
	openSideBar: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

SideBar.defaultProps = {
	visible: false,
};

export default CSSModules(SideBar, styles, { allowMultiple: true });
