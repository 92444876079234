export default {
	error: null,
	users: [],
	isFetching: false,
	// drawer controllers
	showDrawer: false,
	userToDrawer: null,
	drawerMode: 'new',
	// edit, new, delete
	action: '',
	actionFetching: false,
};
