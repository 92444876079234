import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class StepsV2 extends Component {
	state = {};

	render() {
		const children = this.props.children
			.map((child, i) => React.cloneElement(child, { stage: i + 1, ...this.props }));
		return (
			<div styleName="status-wrapper">
				{children}
			</div>
		);
	}
}

StepsV2.propTypes = {
	children: PropTypes.array.isRequired,
};

export default CSSModules(StepsV2, styles, { allowMultiple: true });
