import { all, takeLatest, select, put } from 'redux-saga/effects';
import { message } from 'antd';

import * as Types from './types';
import * as Actions from './actions';

function* handleChangeCpyEmpresa({ payload: { copy } }) {
	const form = yield select((state) => state.obrasDrawer.formInstance);
	if (copy) {
		const f = form.getFieldsValue();
		const { copy_empresa_responsavel_obra, copy_empresa_responsavel_fatura } = f;

		form.setFieldsValue({
			empresa_responsavel_fatura: copy_empresa_responsavel_fatura,
			empresa_responsavel_obra: copy_empresa_responsavel_obra,
		});
		yield put(Actions.handleHaveChangedAssociaEmpresa(true));
	}
}

function* handleCpyUsuario({ payload: { copy } }) {
	const form = yield select((state) => state.obrasDrawer.formInstance);
	const obras = yield select((state) => state.obras.obras);
	try {
		if (copy) {
			const f = form.getFieldsValue();
			const { copy_usuario, copy_obra } = f;
			const selectedObra = obras.reduce((cur, acc) => {
				if (cur.id === copy_obra) return cur;
				return acc;
			}, { });
			const userToCopy = selectedObra.usuarios.reduce((acc, cur) => {
				if (cur.id === copy_usuario) return cur;
				return acc;
			}, {});
			form.setFieldsValue({
				nome_usuario: userToCopy.usuario.nome,
				user_id: userToCopy.id,
				papeis: [...new Set(userToCopy.papeis)],
				compra_ilimitada: userToCopy.compra_ilimitada,
				valor_alcada_compra: userToCopy.valor_alcada_compra,
				copy_usuario: undefined,
				copy_obra: undefined,
				copy_papeis: undefined,
				copy_valor_alcada_compra: undefined,
				copy_compra_ilimitada: undefined,
			});
		}
	} catch (error) {
		message.error('Não foi possível copiar o usuário');
	}
}

function* handleNextStep() {
	const stepStatus = yield select((state) => state.obrasDrawer.stepStatus);
	const currentStep = yield select((state) => state.obrasDrawer.currentStep);
	const maxStep = yield select((state) => state.obrasDrawer.maxStep);
	if (currentStep < maxStep) {
		stepStatus[currentStep] = 'finish';
		stepStatus[currentStep + 1] = 'process';
		yield put(Actions.obraDrawerNextStepSuccess(stepStatus));
	}
}

function* handlePrevStep() {
	const { stepStatus, currentStep, minStep } = yield select((state) => state.obrasDrawer);
	if (currentStep > minStep) {
		stepStatus[currentStep] = 'process';
		stepStatus[currentStep - 1] = 'process';
		yield put(Actions.obraDrawerPrevStepSuccess(stepStatus));
	}
}

function* handleCreatedObra({ payload: { data } }) {
	const form = yield select((state) => state.obrasDrawer.formInstance);
	form.setFieldsValue({
		...data,
		obra_id: data.id,
	});
	yield put(Actions.obraDrawerNextStep());
}

function* handleAssociatedEmpresa() {
	yield put(Actions.obraDrawerNextStep());
}

function* handleClearDrawer() {
	const form = yield select((state) => state.obrasDrawer.formInstance);
	form.resetFields();
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.DRAWER_OBRA_HANDLE_CHANGE_TO_CPY_EMPRESA, handleChangeCpyEmpresa),
		takeLatest(Types.DRAWER_OBRA_CLOSE_CPY_USUARIO, handleCpyUsuario),
		takeLatest(Types.DRAWER_OBRA_PREV_STEP_REQUEST, handlePrevStep),
		takeLatest(Types.DRAWER_OBRA_NEXT_STEP_REQUEST, handleNextStep),
		takeLatest(Types.DRAWER_OBRA_SUBMIT_OBRA_SUCCESS, handleCreatedObra),
		takeLatest(Types.DRAWER_OBRA_SUBMIT_ASSOCIA_EMPRESA_SUCCESS, handleAssociatedEmpresa),
		takeLatest(Types.DRAWER_OBRA_CLOSE, handleClearDrawer),
	]);
}
