/* ************************************ *\


    # Top-bar and Header backspace when those elements are fixed


\* ************************************ */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const THBackspace = ({ fixed }) => {
	const backspace_class = fixed ? 'backspace-header' : 'backspace-top-bar';
	return <div styleName={backspace_class} />;
};

THBackspace.propTypes = {
	fixed: PropTypes.bool,
};

THBackspace.defaultProps = {
	fixed: false,
};

export default CSSModules(THBackspace, styles, { allowMultiple: true });
