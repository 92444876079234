import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import InputNumber from '../../_inputs/InputNumber/InputNumber';

class IEQuantidade extends Component {
	static getDerivedStateFromProps(props) {
		const { item } = props;
		const { errors } = item.front || {};
		const error = (errors || [])
			.filter(e => e.type === 'quantidade')
			.length > 0;
		return { error };
	}

	state = {
		error: false,
	};

	updateQuantidade = (item_id, field, value) => {
		const { error } = this.state;
		const { item, updateFullItemById } = this.props;
		const front = !error
			? { ...item.front }
			: {
				...item.front,
				errors: (item.front.errors || [])
					.filter(e => e.type !== 'quantidade'),
			};
		const new_item = !error
			? { ...item, [field]: value }
			: { ...item, [field]: value, front };
		if (field === 'pre_quantidade') {
			new_item.quantidade = value;
		}
		updateFullItemById(item_id, new_item);
		this.setState({ error: false });
	}

	render() {
		const { error } = this.state;
		const { item, autoSave } = this.props;
		const { id, cliente_associou, quantidade, pre_quantidade } = item;

		const field = !cliente_associou
			? 'pre_quantidade'
			: 'quantidade';
		const default_value = !cliente_associou
			? pre_quantidade
			: quantidade;

		const form_group_class = error
			? 'form-group has-error'
			: 'form-group';
		const label_class = error
			? 'label has-error'
			: 'label';

		return (
			<div styleName="form-group-resposta has-unidade">
				<div className={form_group_class}>
					<label htmlFor={`quantidade-${id}`} styleName={label_class}>
						Quantidade <span styleName="obrigatorio-label">*</span>
					</label>
					<InputNumber
						type="item"
						field_id={`quantidade-${id}`}
						item_key={id}
						field={field}
						default_value={default_value}
						updateFunction={this.updateQuantidade}
						autoSave={autoSave}
						zerar_erros={false} />
				</div>
			</div>
		);
	}
}

IEQuantidade.propTypes = {
	item: PropTypes.object.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
};

export default CSSModules(IEQuantidade, styles, { allowMultiple: true });
