/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

const SelectObra = ({
	obras_all_ids,
	// obra_nome,
	obras_by_id,
	obra_id,
	compra,
	updateComprasRaw,
	autoSaveStart,
}) => {
	const handleObra = e => {
		// eslint-disable-next-line no-debugger
		// debugger;
		const _obra_id = Number(e.target.id);
		const obra = obras_by_id[_obra_id] || {};
		const _nova_compra = {
			...compra,
			obra,
		};

		updateComprasRaw({ nova_compra: _nova_compra });
		autoSaveStart();
	};

	const obra_nome = (obras_by_id[obra_id] || {}).nome || '';
	return (
		<div styleName="dropdown-wrapper">
			<div className="dropdown">
				<button
					className="btn btn-default input-md dropdown-toggle"
					styleName="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="true"
					data-dropdown>
					{obra_nome}{' '}
					<span
						className="caret"
						styleName="dropdown-caret"
						data-dropdown-2 />
				</button>
				<div
					className="dropdown-menu"
					styleName="dropdown-menu-local"
					data-dropchild>
					{obras_all_ids.map((id, i) => (
						<li key={i} data-dropchild>
							<Link
								to="#"
								id={id}
								onClick={handleObra}
								styleName={
									id === obra_id && 'selected'
								}>
								<i
									className="fa fa-map-marker"
									aria-hidden="true"
									id={id}
									data-dropchild />
								{obras_by_id[id].nome}
							</Link>
						</li>
					))}
				</div>
			</div>
		</div>
	);
};


SelectObra.propTypes = {
	compra: PropTypes.object.isRequired,
	obra_id: PropTypes.number.isRequired,
	obras_by_id: PropTypes.object.isRequired,
	obras_all_ids: PropTypes.array.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
};

export default CSSModules(SelectObra, styles, { allowMultiple: true });
