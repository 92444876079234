export default [
	'Serviços Preliminares',
	'Fundação, Estrutura',
	'Alvenaria e Cobertura',
	'Instalações',
	'Acabamento Bruto',
	'Acabamento Fino',
	'Finalizada',
	'Outro',
];
