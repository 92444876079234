/* eslint-disable react/react-in-jsx-scope */
import { all, takeLatest, put, delay, call } from 'redux-saga/effects';
import { message } from 'antd';

import * as Types from './types';
import * as Actions from './actions';

import { getLogradouro } from '../../services/territorios';


function* getAdress({ payload: { cep } }) {
	try {
		yield delay(1000);
		const { data } = yield call(getLogradouro, cep);
		if (data.erro) {
			yield put(Actions.getAdressError('cep não encontrado'));
			return;
		}

		yield put(Actions.getAdressSuccess(data));
	} catch (error) {
		message.error('Não foi possível recuperar o endereço. Tente novamente mais tarde');
		yield put(Actions.getAdressError(error));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.ADRESS_REQUEST, getAdress),
	]);
}
