/* eslint-disable no-console */
import { getLocalstorageData } from './_getLocalstorageData';
import { _post } from './_requests';

function reportErrors(error_url, error_method, full_error, test, message_conaz) {
	const full_url = window.location.href;
	const email = getLocalstorageData('user', 'email');

	const print_erro = full_url === 'https://portal.conazweb.com.br/cotacao/undefined'
		? `history.length: ${window.history.length}`
		: message_conaz;

	const error_text = `*Request:* ${error_url} (${error_method})
*Url:* ${full_url}
*E-mail do cliente:* ${email}
*Print erro request:* ${full_error}
*Print erro Conaz:* ${print_erro}
----`;
	let channel = test ? '#erros-dev' : '#erros';
	if (
		error_method === 'get' &&
		full_error === 'Network Error' &&
		!test
	) {
		channel = '#erros-get';
	}
	_post('/conaz/legacy/send_slack', {
		channel,
		username: 'Clientes____',
		text: error_text,
		icon_emoji: ':triangular_flag_on_post:',
	});
}

export const handleRequestErrors = error => {
	const _data = ((error || {}).response || {}).data;
	const _message_conaz = (_data || {}).message || '';
	const _message = (error || {}).message || '';
	const _status = ((error || {}).response || {}).status;

	if (error.response) {
		console.log(_data);
		console.log(_status);
		console.log('Error', _message);

		if (_status === 403) {
			window.location.replace(`${window.location.origin}/entrar`);
			return;
		}
	} else if (error.request) {
		console.log(error.request);
	} else {
		console.log('Error', _message);
	}

	const is_test = (window.location.href.indexOf('portal.conazweb.com.br') === -1);
	const error_url = ((error || {}).config || {}).url || 'no-url';
	const error_method = ((error || {}).config || {}).method || 'no-method';

	if (_message_conaz === 'Token não fornecido') {
		window.location.replace(`${window.location.origin}/entrar`);
		return;
	}

	reportErrors(error_url, error_method, error.message, is_test, _message_conaz);
};
