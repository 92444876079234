import * as Types from './types';

export const getExportRequest = id => ({
	type: Types.EXPORT_REQUEST,
	payload: { id },
});

export const getExportSuccess = data => ({
	type: Types.EXPORT_SUCCESS,
	payload: { data },
});

export const getExportError = error => ({
	type: Types.EXPORT_ERROR,
	payload: { error },
});

export const getExportStatus = (data, status) => ({
	type: Types.EXPORT_STATUS_REQUEST,
	payload: { data, status },
});

export const getExportStatusSuccess = data => ({
	type: Types.EXPORT_STATUS_SUCCESS,
	payload: { data },
});

export const getExportStatusError = error => ({
	type: Types.EXPORT_STATUS_ERROR,
	payload: { error },
});
