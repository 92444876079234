import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import { titleCase } from '../../_functions/_titleCase';
import * as constants from './constants';

const FornecedoresSearchResult = ({ cliente_id, fornecedor, already_selected, adicionaFornecedor }) => {
	// localizacao
	const _endereco = (fornecedor !== undefined && fornecedor.endereco !== null && fornecedor.endereco !== undefined)
		? fornecedor.endereco
		: { cidade: '', estado: '' };
	const _estado = (_endereco.estado !== null && _endereco.estado !== undefined)
		? _endereco.estado
		: '';
	const _cidade = (_endereco.cidade !== null && _endereco.cidade !== undefined)
		? _endereco.cidade
		: '';
	const localizacao = (_cidade !== '' && _estado !== '')
		? `${titleCase(_cidade)}/${_estado}`
		: '';

	const { qtd_rcs } = fornecedor;
	const novo = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno' && qtd_rcs <= 10;

	return (
		<button
			type="button"
			styleName={`item-result${already_selected ? '-disabled' : ''}`}
			style={already_selected ? { cursor: 'default' } : {}}
			onClick={() => adicionaFornecedor(fornecedor)}
			disabled={already_selected}
		>
			<i className={`fa fa-${already_selected ? 'check' : 'angle-right'}`} aria-hidden="true" />
			<h4
				className="list-group-item-heading"
				styleName="results-list-h4"
				style={already_selected ? { color: '#86c12b' } : {}}
			>
				{fornecedor.nome}
				{novo && <span styleName="new-provider">NOVO</span>}
			</h4>
			{(constants.EXIBIR_LABEL_CADASTRADO_POR_INTERNO && fornecedor.cadastrado_por) && (
				<span
					className="label label-primary">
					{`Cadastrado pelo ${fornecedor.cadastrado_por === cliente_id ? 'suporte' : 'construtor'}`}
				</span>
			)}
			{(constants.EXIBIR_LABEL_CADASTRADO_POR_CLIENTE && cliente_id === fornecedor.cadastrado_por) && (
				<span
					className="label label-primary">
					Cadastrado por você
				</span>
			)}
			{localizacao !== '' && <p style={{ margin: '0 0 3px', color: '#a2a7ab' }}>{localizacao}</p>}
		</button>
	);
};

FornecedoresSearchResult.propTypes = {
	fornecedor: PropTypes.object,
	adicionaFornecedor: PropTypes.func.isRequired,
	already_selected: PropTypes.bool.isRequired,
	cliente_id: PropTypes.number.isRequired,
};

FornecedoresSearchResult.defaultProps = {
	fornecedor: {},
};

export default CSSModules(FornecedoresSearchResult, styles, { allowMultiple: true });
