/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { _get } from 'components/_functions/_requests';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';
import CompraRowNova from '../../components/_parts/CompraRowNova';
import ModalWrapper from '../../components/_parts/_modals/ModalWrapper/ModalWrapper';
import ModalExportaCompras from '../../components/_parts/_modals/ModalExportaCompras/ModalExportaCompras';
import Tabs from './subcomponents/Tabs';
import Pagination from './subcomponents/Pagination';


// Functions
// import { defineStatusCompra } from '../../components/_functions/_defineStatusCompra';

const Compras = ({
	// =========== props
	compras,
	ui,
	// =========== props funcs
	updateModals,
	// =========== local funcs
	changeTab,
	changePage,
	openExportaCompras,
}) => {
	const { modals, tabs } = ui;
	const { result, page } = compras[tabs.compras];

	const compras_on_page = [...result];

	const [statusList, setStatusList] = useState([]);

	const getExportStatus = useCallback(async () => {
		const job_tipo = 'EXPORT_PURCHASE';
		const exportStatusList = [];

		for (const compra_item in compras_on_page) {
			const compra = compras_on_page[compra_item];
			const { data } = await _get(`/conaz/v2/integracoes/requisicoes/job_status?job_tipo=${job_tipo}&entidade_id=${compra.id}`);
			exportStatusList.push(data);
		}

		setStatusList(exportStatusList);
	}, [compras_on_page]);

	useEffect(() => {
		if (compras[`RS_${tabs.compras}`] === 2 || compras[`RS_${tabs.compras}`] === 3) {
			getExportStatus();
		}
	}, [compras]);

	return (
		<>
			<ModalWrapper visible={modals.exporta_compras} updateModals={updateModals}>
				<ModalExportaCompras />
			</ModalWrapper>
			{/* Header */}
			<div className="header fixed">
				<div className="container">
					<div className="row">
						<div className="col-xs-6 col-sm-6">
							<h3 className="h3-header">Compras</h3>
						</div>
						<div className="col-xs-6 col-sm-6 header-buttons-wrapper" styleName="header-buttons">
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="export-compras-button"
								onClick={openExportaCompras}>
								Exportar compras
							</button>
							<Link
								to="/compras/tipo"
								className="btn btn-primary-conaz round-border"
								styleName="create-compra-button">
								Nova compra
							</Link>
						</div>
					</div>
					<Tabs compras={compras} tabs={tabs} changeTab={changeTab} />
				</div>
			</div>
			<div className="container" styleName="container-list">
				<div className="row">
					<div className="col-xs-12">
						{(compras[`RS_${tabs.compras}`] === 2 || compras[`RS_${tabs.compras}`] === 3) && (statusList.length === compras_on_page.length)
							? compras_on_page.map((compra, index) => {
								const _CompraRow = (
									<CompraRowNova
										key={compra.id}
										compra={compra}
										exportStatus={statusList[index]}
									/>
								);
								return _CompraRow;

								/**
								 * Divisao entre compras aguardando aprovação
								 */
								// if (i > 0 && i < 20) {
								// 	const status_anterior = defineStatusCompra(compras_on_page[i - 1]);
								// 	const status_atual = defineStatusCompra(compra);

								// 	if (
								// 	// 	(
								// 	// 		status_anterior === 'em_edicao'
								// 	// 		|| status_anterior === 'em_aprovacao'
								// 	// 		|| status_anterior === 'aguardando_aprovacao_sienge'
								// 	// 		|| status_anterior === 'recusada_fornecedor'
								// 	// 	)
								// 	// && status_atual !== 'em_edicao'
								// 	// && status_atual !== 'em_aprovacao'
								// 	// && status_atual !== 'aguardando_aprovacao_sienge'
								// 	// && status_atual !== 'recusada_fornecedor'
								// 		status_anterior !== status_atual
								// 	) {
								// 		return (
								// 			<React.Fragment key={`${status_atual}-${compra.id}`}>
								// 				<div styleName="divisao-em-andamento">
								// 					<div styleName="divisao-line" />
								// 				</div>
								// 				{_CompraRow}
								// 			</React.Fragment>
								// 		);
								// 	}
								// }
								/**
								 * Lista normalmente
								 */
							})
							: <LoadingPage />}
					</div>
				</div>
				{compras_on_page.length === 0 && (compras[`RS_${tabs.compras}`] === 2 || compras[`RS_${tabs.compras}`] === 3) && (
					<div className="row">
						<div className="col-xs-12">
							<div styleName="empty-wrapper">
								<i className="fa fa-credit-card" aria-hidden="true" />
								<h4>Nenhuma compra nesta seção.</h4>
							</div>
						</div>
					</div>
				)}
				{compras_on_page.length > 0
				&& page.total > 20
				&& <Pagination
					requestState={compras[`RS_${tabs.compras}`]}
					per_page={page.per_page}
					changePage={changePage}
					totalPage={page.total}
					currentPage={page.number}
				/>}
				<div className="row">
					<div className="col-xs-12">
						<p><br /></p><p><br /></p>
					</div>
				</div>
			</div>
		</>
	);
};

Compras.propTypes = {
	// =========== store
	compras: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateModals: PropTypes.func.isRequired,
	// =========== local funcs
	changeTab: PropTypes.func.isRequired,
	changePage: PropTypes.func.isRequired,
	openExportaCompras: PropTypes.func.isRequired,
};

export default CSSModules(Compras, styles, { allowMultiple: true });
