import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { CalendarOutlined } from '@ant-design/icons';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { defineStatusPedido } from '../../../components/_functions/_defineStatusPedido';

function AlterarInformacoes({ cotacao, history, match }) {
	const status = defineStatusPedido(cotacao);

	if (status === 'revisao_conaz' || status === 'aguardando_cotacoes' || status === 'qc_disponivel') {
		return (
			<button
				type="button"
				onClick={() => {
					history.push(`/cotacao/${match.params.id}/info`);
				}}
				data-acoes-pedido-menu>
				<CalendarOutlined styleName="icons-menu-acoes" data-acoes-pedido-menu /> Alterar informações
			</button>
		);
	}

	return null;
}

AlterarInformacoes.propTypes = {
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
};

export default CSSModules(AlterarInformacoes, styles, { allowMultiple: true });
