/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
// Functions

import { formatNumbers } from '../../../_functions/_formatNumbers';
// Components
import ButtonToolTip from '../../_tooltips/ButtonToolTip/ButtonToolTip';
import { withTooltip } from '../../../_hocs/hocs';


class VendedorButton extends Component {
	constructor() {
		super();
		this.state = {
			currentLabel: null,
		};
		this.messageTooltip = 'Este vendedor realizou o cadastro completo selecionando insumos e áreas que atende. De acordo com o seu cadastro, ele não atende este pedido. Você pode selecionar ele mesmo assim.';
	}

	statusVendedorLabel = value => {
		switch (value) {
		case -10:
			return 'Cadastro interno';
		case 0:
			return 'Realizou cadastro';
		default:
			return 'Sem registro';
		}
	};

	atendeRegiao = () => {
		const { vendedor, codigo_ibge_obra } = this.props;
		const split_code = (codigo_ibge_obra || '').split('-');
		const meso = parseInt(split_code[0], 10);
		const municipio = parseInt(split_code[1], 10);
		const uf = parseInt(split_code[0].substring(0, 2), 10);

		if (vendedor.status_fornecedor_vendedor === 0) {
			return true;
		}
		if (vendedor.locais_de_entrega.estados.indexOf(1) !== -1) {
			return true;
		}
		if (vendedor.locais_de_entrega.estados.indexOf(uf) !== -1) {
			return true;
		}
		if (vendedor.locais_de_entrega.mesorregioes.indexOf(meso) !== -1) {
			return true;
		}
		if (vendedor.locais_de_entrega.municipios.indexOf(municipio) !== -1) {
			return true;
		}
		return false;
	};

	atendeGrupos = () => {
		const { vendedor, grupos_itens_pedido } = this.props;
		const grupos_ids = vendedor.grupos.map(g => g.grupo.id);
		const grupos_pedido_vendedor = grupos_itens_pedido.filter(g => grupos_ids.indexOf(g) !== -1);

		if (vendedor.status_fornecedor_vendedor < 20) {
			return true;
		}
		if (grupos_itens_pedido.length === 0) {
			return true;
		}
		if (grupos_pedido_vendedor.length > 0) {
			return true;
		}
		return false;
	};

	handleTooltip = (type, label) => {
		const { openTooltip, closeTooltip } = this.props;
		if (type === 'open') {
			this.setState({ currentLabel: label });
			openTooltip();
			return;
		}
		this.setState({ currentLabel: null });
		closeTooltip();
	};

	labelAtendimentoVendedorr = () => {
		let label = [];
		if (!this.atendeRegiao()) {
			label.push('Não cadastrou a área de atendimento da sua obra');
		}
		if (!this.atendeGrupos()) {
			if (label === '') {
				label = [];
				label.push('Não cadastrou nenhum insumo deste pedido');
			} else {
				label.push('Não cadastrou nenhum insumo deste pedido');
			}
		}
		return label;
	};

	labelComponent = label => {
		const { tooltipIsVisible } = this.props;
		const { currentLabel } = this.state;
		return (
			<React.Fragment key={label}>
				{(tooltipIsVisible && currentLabel === label) && (
					<ButtonToolTip
						text={this.messageTooltip}
						display="top"
						arrow_left="252px"
						margin_top="-10px"
						margin_left="-130px"
						width="600px"
						styleArrow={{
							position: 'absolute',
							// transform: 'rotate(deg)',
							top: '8px',
							marginBottom: '16px',
						}}
						styleTooltip={{
							position: 'absolute',
							top: '-100px',
							left: '150px',
							borderRadius: '5px',
						}} />
				)}
				<div
					styleName="nao_atende_tag"
					onMouseEnter={() => this.handleTooltip('open', label)}
					onMouseLeave={() => this.handleTooltip('close', label)}>
					<p>{label}</p>
				</div>
			</React.Fragment>
		);
	}

	render() {
		const {
			vendedor,
			estah_selecionado,
			sem_preferencia,
			exibir_status,
			exibe_vendedor_selecionado,
			onClickFunction,
		} = this.props;

		const button_class = `radio-button-block${estah_selecionado && !sem_preferencia ? ' selected' : ''}`;
		let _telefones = vendedor.telefones.map(tel => formatNumbers(tel.numero, 'telefone'));
		_telefones = _telefones.length > 0 ? _telefones.join(' / ') : '';

		return (
			<button type="button" styleName={button_class} onClick={onClickFunction}>
				<div styleName="radio" />
				<div styleName="nome">
					{vendedor.nome !== '' ? vendedor.nome : '-'} {
						exibir_status
						&& (
							<small>
								<span styleName="somente-triagem">*</span>
								<i style={{ color: '#a5a6ab' }}>{this.statusVendedorLabel(vendedor.status)}</i>
							</small>
						)
					}
				</div>
				{
					this.labelAtendimentoVendedorr().length !== 0
					&& this.labelAtendimentoVendedorr()
						.map(label => this.labelComponent(label))
				}
				{
					(exibe_vendedor_selecionado && estah_selecionado && sem_preferencia) && (
						<div>
							<i className="fa fa-check" aria-hidden="true" style={{ color: '#3cd23c' }} />
							Selecionado para esta cotação
						</div>
					)
				}
				<div styleName="email">{vendedor.email}</div>
				{_telefones !== '' && <div styleName="email">{_telefones}</div>}
			</button>
		);
	}
}

VendedorButton.propTypes = {
	closeTooltip: PropTypes.func.isRequired,
	openTooltip: PropTypes.func.isRequired,
	tooltipIsVisible: PropTypes.bool.isRequired,
	vendedor: PropTypes.object.isRequired,
	onClickFunction: PropTypes.func.isRequired,
	exibir_status: PropTypes.bool.isRequired,
	estah_selecionado: PropTypes.bool.isRequired,
	sem_preferencia: PropTypes.bool.isRequired,
	exibe_vendedor_selecionado: PropTypes.bool.isRequired,
	codigo_ibge_obra: PropTypes.string.isRequired,
	grupos_itens_pedido: PropTypes.array.isRequired,
};

export default withTooltip(CSSModules(VendedorButton, styles, { allowMultiple: true }));
