import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Redux
import { buildMapStateToProps } from '../../../../../../store/reduxDispatchs';

// Components
import ButtonExpand from './ButtonExpand';
import InfoCompacted from './InfoCompacted';
import IntegracaoSearch from '../IntegracaoSearch/IntegracaoSearch';
import InfoExpanded from './InfoExpanded';
import Loading from '../../../../../../components/_parts/_loadings/Loading/Loading';

// Functions
import { _get } from '../../../../../../components/_functions/_requests';

class InfoFornecedor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			compacted: true,
			loading: false,
			fornecedor_integrado: { ...props.fornecedor_integrado },
		};
	}

	UNSAFE_componentWillMount() {
		const {
			fornecedor_integrado,
			fornecedor_on_edition,
			saveFornecedorSienge,
		} = this.props;

		if (!fornecedor_integrado.id) {
			this.setState({ loading: true }, () => {
				_get(`/conaz/v2/integracoes/fornecedores_associados?fornecedor_id=${fornecedor_on_edition.id}`)
					.then(({ data }) => {
						if (data.length > 0) {
							const { fornecedor_integrado: f_integrado } = data[0];
							if (f_integrado.id) {
								saveFornecedorSienge(f_integrado);
								this.setState({ fornecedor_integrado: f_integrado, loading: false });
							} else this.setState({ loading: false });
						} else this.setState({ loading: false });
					});
			});
		}
	}

	handleFornecedorAssociado = fornecedor_integrado => {
		const { saveFornecedorSienge } = this.props;
		this.setState({ fornecedor_integrado }, () => saveFornecedorSienge(fornecedor_integrado, true));
	};

	handleDesassociacao = () => {
		const { saveFornecedorSienge } = this.props;
		this.setState({ fornecedor_integrado: {} }, () => saveFornecedorSienge({}, true));
	}

	handleExpand = () => {
		this.setState({ compacted: !this.state.compacted });
	}

	render() {
		const { compacted, loading, fornecedor_integrado } = this.state;
		const {
			logged_sienge,
			fornecedor_on_edition,
			saveFornecedorSienge,
			user,
		} = this.props;

		const show_sienge = user.sienge_liberado || false;
		if (!show_sienge) return null;
		if (!logged_sienge) return null;

		const empty_fornecedor = (fornecedor_integrado.id === undefined) || (fornecedor_integrado.id === null);

		const ButtonExpandView = (
			<ButtonExpand
				compacted={compacted}
				handleExpand={this.handleExpand} />
		);

		if (loading) return <Loading />;

		// Compacted
		if (compacted) {
			return (
				<div styleName="content-info-sienge">
					{ButtonExpandView}
					<InfoCompacted
						empty_fornecedor={empty_fornecedor}
						fornecedor_integrado={fornecedor_integrado} />
				</div>
			);
		}

		// Empty
		if (empty_fornecedor) {
			return (
				<div styleName="content-info-sienge">
					{ButtonExpandView}
					<IntegracaoSearch
						handleFornecedorAssociado={this.handleFornecedorAssociado}
						saveFornecedorSienge={saveFornecedorSienge}
						fornecedor_conaz={fornecedor_on_edition} />
				</div>
			);
		}

		// Expanded
		return (
			<div styleName="content-info-sienge">
				{ButtonExpandView}
				<InfoExpanded
					fornecedor_integrado={fornecedor_integrado}
					handleDesassociacao={this.handleDesassociacao}
					empty_fornecedor={empty_fornecedor} />
			</div>
		);
	}
}

InfoFornecedor.propTypes = {
	fornecedor_on_edition: PropTypes.object.isRequired,
	fornecedor_integrado: PropTypes.object.isRequired,
	logged_sienge: PropTypes.bool.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	user: PropTypes.shape({
		sienge_liberado: PropTypes.bool,
	}).isRequired,
};

const _InfoFornecedor = CSSModules(InfoFornecedor, styles, { allowMultiple: true });
const mapStateToProps = props => buildMapStateToProps(props, ['user']);
export default connect(mapStateToProps, null)(_InfoFornecedor);
