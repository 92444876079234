/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';

// Functions
// import { buildItensPedidoV3, buildLocalAnexos } from './_functions/_buildNovaCotacao';

function HoverButtonsHoc(Comp) {
	return class PP extends Component {
		constructor(props) {
			super(props);
			this.state = {
				tooltip: false,
			};
			this.showTooltip = this.showTooltip.bind(this);
			this.hideTooltip = this.hideTooltip.bind(this);
		}

		showTooltip() {
			this.setState({ tooltip: true });
		}

		hideTooltip() {
			this.setState({ tooltip: false });
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					showTooltip={this.showTooltip}
					hideTooltip={this.hideTooltip} />
			);
		}
	};
}

export default HoverButtonsHoc;
