import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';
import StarsAvaliacao from './StarsAvaliacao';
import FracaoAvaliacao from './FracaoAvaliacao';

const DescricaoAvaliacao = ({ nota_detalhada }) => {
	const calcularPorcentagem = (decimal) => {
		const percentage = decimal * 100;
		return `${percentage.toFixed(0)}%`;
	};
	const mostrar_avaliacao = !!nota_detalhada?.media && nota_detalhada?.total_avaliacoes > 2;
	return <div styleName="descricao-avaliacao">
		<p styleName="avalie-compras">Avalie suas compras e possibilite que os fornecedores tenham avaliações a serem demonstradas.<a href="" target="_blank" rel="noopener noreferrer" style={{ color: '#0076D6' }}> Entenda como as avaliações são feitas.</a></p>
		{mostrar_avaliacao ? <div styleName="com-avaliacao"><StarsAvaliacao media={nota_detalhada?.media} /><div styleName="fracao-avaliacao-container"><FracaoAvaliacao media={nota_detalhada?.media} /></div></div> :	<div styleName="sem-avaliacao">
			<i className="fa fa-frown-o" aria-hidden="true" styleName="frown-icon" />
			<p styleName="periodo-avaliacao">Esse fornecedor ainda não recebeu avaliações suficientes para compor uma nota.</p>
		</div> }
		{mostrar_avaliacao ? <div styleName="avaliacoes-realizadas">{nota_detalhada.total_avaliacoes} avaliações realizadas</div> : <></>}
		<div styleName="linha-horizontal" />
		<div styleName="criterios-avaliacao">
			<div styleName="avaliacao-title">Critérios avaliados</div>
			<div styleName="criterio"><span styleName="criterio-nome">Entregas</span>{mostrar_avaliacao ? <>: <span styleName="porcentagem-criterio">{calcularPorcentagem(nota_detalhada?.respostas['1'].porcentagem)} das entregas bem sucedidas.</span></> : <></>}</div>
			<div styleName="criterio"><span styleName="criterio-nome">Documentação</span>{mostrar_avaliacao ? <>: <span styleName="porcentagem-criterio">{calcularPorcentagem(nota_detalhada?.respostas['2'].porcentagem)} dos documentos corretos.</span></> : <></>}</div>
			<div styleName="criterio"><span styleName="criterio-nome">Vendas</span>{mostrar_avaliacao ? <>: <span styleName="porcentagem-criterio">{calcularPorcentagem(nota_detalhada?.respostas['3'].porcentagem)} das vendas de acordo.</span></> : <></>}</div>
			<div styleName="criterio"><span styleName="criterio-nome">Materiais</span>{mostrar_avaliacao ? <>: <span styleName="porcentagem-criterio">{calcularPorcentagem(nota_detalhada?.respostas['4'].porcentagem)} dos materiais em boas condições.</span></> : <></>}</div>
		</div>
	</div>;
};


DescricaoAvaliacao.propTypes = {
	nota_detalhada: PropTypes.object,
};

DescricaoAvaliacao.defaultProps = {
	nota_detalhada: null,
};

export default CSSModules(DescricaoAvaliacao, styles, { allowMultiple: true });
