import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginate';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Component
import SiengeItensRow from '../../../components/_parts/SiengeItensRow/SiengeItensRow';
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';
import SelecionaTabelas from './SelecionaTabelas';
import ModalPortal from '../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalLimitSienge from '../../../components/_parts/_modals/ModalLimitSienge/ModalLimitSienge';

const SiengeItens = ({
	// =========== state
	request_state,
	itens,
	search,
	itemsPage,
	show_tabelas_update,
	error,
	error_message,
	tabela,
	// =========== store
	// =========== local funcs
	handleSelectedItem,
	handlePageChange,
	handleAtualizarItens,
	showTabelasToUpdate,
	removeLimitError,
	// =========== prop funcs
}) => {
	const { activePage, pages } = itemsPage;

	if (request_state === 1) {
		return <LoadingPage />;
	}

	if (request_state === 10) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12" styleName="mt-2">
						<LoadingPage small_pad_top />
						<h4 style={{ textAlign: 'center', margin: '20px 0' }}>Sincronizando seus itens do Sienge</h4>
						<p style={{ textAlign: 'center', fontSize: '16px' }}>Aguarde enquanto salvamos todos os seus itens do Sienge em nosso sistema.<br /> Dependendo do número de itens, <b>este processo pode demorar alguns minutos</b>.</p>
					</div>
				</div>
			</div>
		);
	}

	if (request_state === 20) {
		return (
			<ModalPortal>
				<ModalWrapperPortal closeModal={() => {}}>
					<ModalLimitSienge
						error_message={error_message}
						requestToContinue={handleAtualizarItens}
						var_for_request={tabela}
						removeLimitError={removeLimitError} />
				</ModalWrapperPortal>
			</ModalPortal>
		);
	}

	if (show_tabelas_update) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<SelecionaTabelas
							handleAtualizarItens={handleAtualizarItens}
							itens={itens}
							showTabelasToUpdate={showTabelasToUpdate}
							error={error}
							error_message={error_message} />
					</div>
				</div>
			</div>
		);
	}

	// if (itens.length === 0) {
	// 	return (
	// 		<div className="container">
	// 			<div className="row">
	// 				<div className="col-xs-12">
	// 					<div styleName="empty-wrapper">
	// 						<i className="fa fa-archive" aria-hidden="true" />
	// 						<h4>Nenhum item cadastrado no Sienge.</h4>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	);
	// }

	return (
		<div className="container">
			<div className="row">
				<div className="col-xs-12" styleName="mt-2" style={{ marginTop: '1em' }}>
					{itens.map(item => (
						<SiengeItensRow
							key={item.key}
							item={item}
							handleSelectedItem={handleSelectedItem}
							border />
					))}
				</div>
			</div>
			{search === '' && (
				<div styleName="pagination-area">
					<Pagination
						containerClassName="pagination"
						activeClassName="active"
						forcePage={activePage - 1}
						pageCount={pages}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						nextLabel=">"
						previousLabel="<"
						breaklabel="..."
						onPageChange={handlePageChange} />
				</div>
			)}
			<div className="row">
				<div className="col-xs-12">
					<p><br /></p><p><br /></p><p><br /></p>
				</div>
			</div>
		</div>
	);
};

SiengeItens.propTypes = {
	// =========== state
	request_state: PropTypes.number.isRequired,
	itens: PropTypes.array.isRequired,
	search: PropTypes.string.isRequired,
	itemsPage: PropTypes.object.isRequired,
	show_tabelas_update: PropTypes.bool.isRequired,
	error: PropTypes.bool.isRequired,
	error_message: PropTypes.string.isRequired,
	tabela: PropTypes.string.isRequired,
	// =========== store
	// =========== local funcs
	handleSelectedItem: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleAtualizarItens: PropTypes.func.isRequired,
	showTabelasToUpdate: PropTypes.func.isRequired,
	removeLimitError: PropTypes.func.isRequired,
	// =========== prop funcs
};

export default CSSModules(SiengeItens, styles, { allowMultiple: true });
