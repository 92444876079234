import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import HoverButtonsHoc from '../../../../hocs/HoverButtonsHoc/HoverButtonsHoc';
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

// Functions
import { setClasses } from '../../../_functions/_setClasses';

const ButtonRound = ({
	size,
	first_of_type,
	left,
	icon,
	onClick,
	tooltip_text,
	loading,
	// =========== hoc
	tooltip,
	showTooltip,
	hideTooltip,
}) => {
	const button_wrapper_classes = { first_of_type };
	const button_wrapper_class = setClasses('button-wrapper', button_wrapper_classes);
	const button_class = `button-round ${size}`;

	return (
		<div styleName={button_wrapper_class}>
			{(tooltip && tooltip_text !== '') && (
				<div
					className="tooltip bottom in"
					role="tooltip"
					styleName="tooltip"
					style={{ left }}>
					<div className="tooltip-arrow" />
					<div className="tooltip-inner">{tooltip_text}</div>
				</div>
			)}
			{!loading ? (
				<button
					type="button"
					styleName={button_class}
					onClick={onClick}
					onMouseEnter={showTooltip}
					onMouseLeave={hideTooltip}>
					<i className={icon} aria-hidden="true" />
				</button>
			) : (
				<button
					type="button"
					styleName={`${button_class} loading`}
					onMouseEnter={showTooltip}
					onMouseLeave={hideTooltip}>
					<i className={icon} aria-hidden="true" />
					<LoadingButton top="2px" />
				</button>
			)}
		</div>
	);
};

ButtonRound.propTypes = {
	size: PropTypes.string,
	first_of_type: PropTypes.bool,
	left: PropTypes.string,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	tooltip_text: PropTypes.string,
	loading: PropTypes.bool,
	// =========== hoc
	tooltip: PropTypes.bool.isRequired,
	showTooltip: PropTypes.func.isRequired,
	hideTooltip: PropTypes.func.isRequired,
};

ButtonRound.defaultProps = {
	size: 'medium',
	first_of_type: false,
	left: '-50%',
	onClick: () => {},
	tooltip_text: '',
	loading: false,
};

export default HoverButtonsHoc(
	CSSModules(ButtonRound, styles, { allowMultiple: true }),
);
