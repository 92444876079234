export default {
	// drawer controllers
	showDrawer: false,
	dataToDrawer: null,
	drawerMode: 'new',
	formInstance: null,
	// variavel que controlar qnd a obra foi cadastrada
	obraCadastrada: false,
	// variáveis para mostrar ou não o form de copia
	copyEmpresaState: false,
	copyUsuariosState: false,
	usersAssociados: [],
	// variável para liberar/bloquear botão no footer quando estiver copiando usuário de outra obra.
	associaUsuariosHaveChanged: false,
	// se teve mudança na hora que está copiando empresa de outra obra.
	associaCopiaEmpresaHaveChanged: false,
	// se teve mudança vai mostrar para "salvar e continuar" ao invés de "pular".
	associaEmpresaHaveChanged: false,
	//
	minStep: 0,
	maxStep: 3,
	currentStep: 0,
	stepStatus: { 0: 'process', 1: 'wait', 2: 'wait' },
};
