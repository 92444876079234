import moment from 'moment';
import 'moment/locale/pt-br';

const cotacao = {
	// new
	data: {},
	fornecedores: {
		by_id: {},
		all_ids: [],
		selecionados: [],
	},
	// old
	criando_cotacao: false,
	cotacao_view: {},
	compras: [],
	rcs_by_id: {},
	rcs_all_ids: [],
	ocs: [],
	rcs: [],
	searching_itens: 0,
	adding_item: false,
	id: 0,
	codigo: '',
	titulo: '',
	titulo_sugerido: '',
	anexos: [],
	obra: 0,
	observacoes: '',
	observacoes_conaz: '',
	observacoes_internas: '',
	itens: [],
	itens_by_id: {},
	itens_all_ids: [],
	item_selecionado: 0,
	itens_deletados: [],
	itens_populares: [],
	modelos: [],
	deletado_relacionado_parent_child: null,
	enviado_em: '',
	prazo_entrega_qc: moment(),
	data_entrega_obra: moment(),
	data_entrega_obra_estimada: false,
	status: 0,
	cancelado: false,
	em_aprovacao: false,
	em_refinamento: false,
	tipo: 0,
	salvando: 0,
	salvar_novamente: false,
	salva: true,
	enviando: 0,
	enviado: [false, 0],
	item_added: false,
	selected_result: -1,
	preferencia_por: '',
	fornecedores_loaded: false,
	salvando_selecao_fornecedor: false,
	request_state_fornecedores: 0,
	request_state_populares: 0,
	fornecedores_by_id: {},
	fornecedores_all_ids: [],
	fornecedores_selecionados: [],
	populares_fornecedores_by_id: {},
	populares_fornecedores_all_ids: [],
	cliente_rc_revisao: {},
	usuario_id: 0,
	usuario_nome: '',
	obra_modelo: 0,
	exibir_contato: false,
	preferencia_de_pagamento: {},
	observacoes_ou_itens_alterados: false,
};

export default cotacao;
