import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import LayoutWrapper from '../../components/LayoutWrapper';
import Avaliacoes from './Avaliacoes';

const AvaliacoesContainer = props => {
	const [modalFornecedor, setModalFornecedor] = useState(false);
	const [modalVendedor, setModalVendedor] = useState(false);
	const [search, setSearch] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		props.getAvaliacoes();
	}, []);

	useEffect(() => {
		if (isSearching) inputRef.current.focus();
	}, [isSearching]);

	const handleModalFornecedor = () => setModalFornecedor(!modalFornecedor);

	const handleModalVendedor = () => setModalVendedor(!modalVendedor);

	const handleMobileSearchClick = () => setIsSearching(!isSearching);

	const handlePage = ({ selected }) => props.getAvaliacoes(selected + 1, search);

	const startSearch = (value) => {
		props.getAvaliacoes(1, value);
	};

	const handleDebounce = useCallback(debounce(value => {
		startSearch(value);
	}, 800), []);

	const handleSearch = (e) => {
		setSearch(e.target.value);
		handleDebounce(e.target.value);
	};

	const commentAnalysis = (status, Component, comentario) => {
		if (status === 2) return <Component>{comentario || 'Sem comentarios'}</Component>;
		if (status === 3) return <Component>Comentário reprovado pela análise</Component>;
		return <Component>Comentário enviado para análise</Component>;
	};

	return (
		<LayoutWrapper
			thbackspace_fixed
			{...props}
		>
			<Avaliacoes
				inputRef={inputRef}
				isSearching={isSearching}
				handleMobileSearchClick={handleMobileSearchClick}
				modalFornecedor={modalFornecedor}
				modalVendedor={modalVendedor}
				handleModalFornecedor={handleModalFornecedor}
				handleModalVendedor={handleModalVendedor}
				handleSearch={handleSearch}
				handlePage={handlePage}
				search={search}
				commentAnalysis={commentAnalysis}
				{...props}
			/>
		</LayoutWrapper>
	);
};

AvaliacoesContainer.propTypes = {
	getAvaliacoes: PropTypes.func.isRequired,
	avaliacoes: PropTypes.object.isRequired,
};

const mapStateToProps = props => buildMapStateToProps(props);
const mapDispatchToProps = dispatch => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(AvaliacoesContainer));
