import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LoadingButton from '../../_loadings/LoadingButton/LoadingButton';

class ModalMotivoEdicao extends Component {
	state = {
		motivo: '',
	};

	handleMotivo = (e) => {
		this.setState({ motivo: e.target.value });
	}

	sendMotivo = () => {
		const { motivo } = this.state;
		const { enviaFunc } = this.props;
		enviaFunc(motivo);
	}

	render() {
		const { motivo } = this.state;
		const { type, updateModals, enviando } = this.props;

		const text = type === 'compra'
			? 'Todos os envolvidos serão informados sobre as alterações na compra. Explique o motivo desta edição:'
			: 'Os fornecedores serão informados sobre as alterações no pedido. Explique o motivo desta edição:';

		return (
			<div className="modal-content">
				<div className="modal-header" styleName="modal-header">
					{enviando !== 1 && (
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => updateModals('motivo_edicao', false)}>
							<span aria-hidden="true">&times;</span>
						</button>
					)}
					<h4 className="modal-title">Motivo da edição</h4>
				</div>
				{enviando !== 2 && (
					<div className="modal-body" styleName="modal-body">
						<label styleName="title1">{text}</label>
						<textarea
							className="form-control"
							rows="5"
							value={motivo}
							onChange={this.handleMotivo}
							placeholder="Ex. Adicionei itens, adicionei anexos, alterei algumas quantidades…" />
						<p><br /></p>
						{(enviando === 0 || enviando === 3) && (
							<>
								<button
									className="btn btn-clear-conaz round-border"
									onClick={() => updateModals('motivo_edicao', false)}
									style={{ marginRight: '15px' }}>
									Cancelar
								</button>
								{motivo !== '' ? (
									<button
										type="button"
										className="btn btn-secondary-conaz round-border"
										onClick={this.sendMotivo}>
										Enviar
									</button>
								) : (
									<button
										type="button"
										className="btn btn-secondary-conaz round-border"
										style={{ opacity: '0.3' }}>
										Enviar
									</button>
								)}
							</>
						)}
						{enviando === 1 && (
							<button
								type="button"
								className="btn btn-secondary-conaz round-border loading">
								<LoadingButton />Enviar
							</button>
						)}
						{enviando === 3 && (
							<div className="alert alert-danger" role="alert" style={{ marginTop: '25px' }}>
								<i className="fa fa-exclamation-circle fa-fw" aria-hidden="true" /> Ops, aconteceu algo errado. Clique em enviar e tente novamente.
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

ModalMotivoEdicao.propTypes = {
	type: PropTypes.string,
	updateModals: PropTypes.func,
	enviaFunc: PropTypes.func.isRequired,
	enviando: PropTypes.number,
};

ModalMotivoEdicao.defaultProps = {
	type: 'pedido',
	updateModals: () => {},
	enviando: 0,
};

export default CSSModules(ModalMotivoEdicao, styles, { allowMultiple: true });
