import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Form, Select, Col, Input } from 'antd';

// Redux HOC
import { connect } from 'react-redux';
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

function CopyResponsaveisForm({ ...props }) {
	const {
		obras,
		obrasDrawer,
		getObras,
		handleChangedCpyEmpresa,
	} = props;

	const [empresasOpt, setEmpresasOpt] = useState([]);

	const handleSelectedObra = (_, opt) => {
		const empresas = opt.data.pessoas;
		setEmpresasOpt(empresas?.map((p) => ({ data: p.pessoa, value: p.pessoa.id, label: p.pessoa.nome, key: p.pessoa.id }) || []));
		const form = obrasDrawer.formInstance;
		form.setFieldsValue({
			copy_empresa_responsavel_obra: undefined,
			copy_empresa_responsavel_fatura: undefined,
		});
		handleChangedCpyEmpresa(false);
	};

	const haveChanged = () => {
		const form = obrasDrawer.formInstance;
		const { copy_empresa_responsavel_obra: obra, copy_empresa_responsavel_fatura: fatura } = form.getFieldsValue();
		if (obra && fatura) return handleChangedCpyEmpresa(true);
		handleChangedCpyEmpresa(false);
	};

	useEffect(() => {
		if (!obras.obras.length && !obras.isFetching) {
			getObras();
		}
	}, [obras]);

	useEffect(() => () => {
		const form = obrasDrawer.formInstance;
		form.setFieldsValue({
			copy_empresa_responsavel_obra: undefined,
			copy_empresa_responsavel_fatura: undefined,
			copy_obra: undefined,
		});
		handleChangedCpyEmpresa(false);
	}, []);

	return (
		<>
			<Col span={12} style={{ marginTop: 24 }}>
				<Form.Item name="copy_obra" label="De qual obra deseja copiar?">
					<Select
						placeholder="Selecione a obra"
						onChange={handleSelectedObra}
						options={obras.obras.map((ob) => ({ value: ob.nome, data: ob, key: ob.id }))}
						disabled={obras.isFetching}
					/>
				</Form.Item>
			</Col>
			<div className="block-title">
				Responsável pela obra
			</div>
			<Col span={12}>
				<Form.Item name="copy_empresa_responsavel_obra" label="Nome da empresa">
					<Select
						onChange={haveChanged}
						options={empresasOpt}
						disabled={obras.isFetching}
						placeholder="Selecione a obra"
					/>
				</Form.Item>
				<Form.Item noStyle name="copy_empresa_obra_nome">
					<Input hidden />
				</Form.Item>
			</Col>
			<div className="block-title">
				Responsável pelo Faturamento
			</div>
			<Col span={12}>
				<Form.Item name="copy_empresa_responsavel_fatura" label="Nome da empresa">
					<Select
						onChange={haveChanged}
						options={empresasOpt}
						disabled={obras.isFetching}
						placeholder="Selecione a obra"
					/>
				</Form.Item>
				<Form.Item noStyle name="copy_empresa_fatura_nome">
					<Input hidden />
				</Form.Item>
			</Col>
		</>
	);
}

CopyResponsaveisForm.propTypes = {
	obrasDrawer: PropTypes.shape(),
	obras: PropTypes.shape({
		isFetching: PropTypes.bool,
		obras: PropTypes.arrayOf(PropTypes.shape({ })),
	}).isRequired,
	getObras: PropTypes.func,
	handleChangedCpyEmpresa: PropTypes.func,
};

CopyResponsaveisForm.defaultProps = {
	obrasDrawer: {},
	getObras: () => {},
	handleChangedCpyEmpresa: () => {},
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(CopyResponsaveisForm));

