/* eslint-disable react/react-in-jsx-scope */
import { all, takeLatest, call, put, delay } from 'redux-saga/effects';
import { message, notification } from 'antd';

import { _post, _get } from '../../components/_functions/_requests';

import * as Types from './types';
import * as Actions from './actions';

function* getExport({ payload: { id } }) {
	const payload = {
		purchase_id: id,
	};

	notification.info({
		message: 'Iniciando Exportação',
		description: 'Aguarde alguns segundos, a exportação será iniciada. Caso não mostre o status da exportação, tente novamente ou entre em contato com nosso suporte.',
		duration: 6,
	});

	try {
		const { data } = yield call(_post, '/conaz/v2/integracoes/exportar_compra_async', payload);
		yield put(Actions.getExportSuccess(data));
	} catch (err) {
		message.error(`Não foi possível exportar a compra ${id}`);
		yield put(Actions.getExportError(err));
	}
}

function* getExportStatus({ payload }) {
	const { entidade_id, job_tipo } = payload.data;

	try {
		const { data, data: { job_status } } = yield call(_get, `/conaz/v2/integracoes/requisicoes/job_status?job_tipo=${job_tipo}&entidade_id=${entidade_id}`);

		yield put(Actions.getExportStatusSuccess(data));

		if (job_status === null) {
			while (true) {
				yield delay(3000);
				yield put(Actions.getExportStatus(payload.data, job_status));
			}
		}

		if (data && data.message === 'Nenhuma requisição encontrada' && !job_status) {
			return false;
		}

		if (job_status !== 'FINISHED' && job_status !== 'ERROR') {
			while (job_status === 'RUNNING') {
				yield delay(50000);
				yield put(Actions.getExportStatus(payload.data, job_status));
			}
		}
	} catch (err) {
		message.error('Não foi possível atualizar o status');
		yield put(Actions.getExportStatusError(err));
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(Types.EXPORT_REQUEST, getExport),
		takeLatest(Types.EXPORT_STATUS_REQUEST, getExportStatus),
	]);
}
