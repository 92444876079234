import * as Types from './types';

export const getEmpresas = () => ({
	type: Types.EMPRESAS_GET_LIST_REQUEST,
});

export const getEmpresasSuccess = (data) => ({
	type: Types.EMPRESAS_GET_LIST_SUCCESS,
	payload: { data },
});

export const getEmpresasError = (erro_motivo) => ({
	type: Types.EMPRESAS_GET_LIST_ERROR,
	payload: { erro_motivo },
});

export const createEmpresa = (data) => ({
	type: Types.EMPRESAS_CREATE_REQUEST,
	payload: { data },
});

export const createEmpresaSuccess = (data) => ({
	type: Types.EMPRESAS_CREATE_SUCCESS,
	payload: { data },
});

export const deleteEmpresa = (data) => ({
	type: Types.EMPRESAS_DELETE_REQUEST,
	payload: { data },
});

export const deleteEmpresaSuccess = (data) => ({
	type: Types.EMPRESAS_DELETE_SUCCESS,
	payload: { data },
});

export const editEmpresa = (data) => ({
	type: Types.EMPRESAS_EDIT_REQUEST,
	payload: { data },
});

export const editEmpresaSuccess = (data) => ({
	type: Types.EMPRESAS_EDIT_SUCCESS,
	payload: { data },
});

export const blockEmpresa = (data) => ({
	type: Types.EMPRESAS_BLOCK_REQUEST,
	payload: { data },
});

export const blockEmpresaSuccess = (data) => ({
	type: Types.EMPRESAS_BLOCK_SUCCESS,
	payload: { data },
});

export const actionErrorEmpresa = (erro_motivo) => ({
	type: Types.EMPRESAS_ACTION_ERROR,
	payload: { erro_motivo },
});

export const openEmpresaDrawer = (mode, data) => ({
	type: Types.EMPRESAS_OPEN_DRAWER,
	payload: { data, mode },
});

export const closeEmpresaDrawer = () => ({
	type: Types.EMPRESAS_CLOSE_DRAWER,
});
