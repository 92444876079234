import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import StepsSienge from '../../../../components/_parts/StepsSienge/StepsSienge';
import StepSienge from '../../../../components/_parts/StepsSienge/StepSienge';

const ExportSteps = ({
	current,
	itens_cotacao,
	itens_negociacao,
}) => {
	const descricao_cotacao = itens_cotacao !== ''
		? `${itens_cotacao} itens adicionados`
		: '';
	const descricao_negociacao = itens_negociacao !== ''
		? `${itens_negociacao} itens adicionados`
		: '';

	return (
		<>
			<div styleName="titles">
				<div styleName="icon">
					<img src="/images/logo-sienge.png" width="74" alt="" />
				</div>
				<h3>Exportar compra para o Sienge</h3>
			</div>
			<div styleName="content" style={{ paddingLeft: '16px' }}>
				<StepsSienge current={current}>
					<StepSienge
						label="Verificando informações do Sienge..." />
					<StepSienge
						label="Criando cotação no Sienge" />
					<StepSienge
						label="Adicionando itens na cotação"
						descricao={descricao_cotacao} />
					<StepSienge
						label="Adicionando itens na negociação"
						descricao={descricao_negociacao} />
					<StepSienge
						label="Concluindo exportação" />
				</StepsSienge>
			</div>
		</>
	);
};

ExportSteps.propTypes = {
	current: PropTypes.number.isRequired,
	itens_cotacao: PropTypes.string,
	itens_negociacao: PropTypes.string,
};

ExportSteps.defaultProps = {
	itens_cotacao: '',
	itens_negociacao: '',
};

export default CSSModules(ExportSteps, styles, { allowMultiple: true });
