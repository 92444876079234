import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Components
import Welcome from './Welcome';

// Functions
import { _post } from '../../components/_functions/_requests';

class WelcomeContainer extends Component {
	constructor() {
		super();
		this.state = {
			page: 1,
			total_pages: 5,
		};
		this.navigate = this.navigate.bind(this);
		this.updateInstrucao = this.updateInstrucao.bind(this);
	}

	componentDidMount() {
		// Google Analytics
		window.ga('set', 'page', window.location.pathname + window.location.search);
		window.ga('send', 'pageview');
		// Set page title
		document.title = 'Bem-vindo - Conaz';
	}

	navigate(page) {
		this.setState({ page });
	}

	updateInstrucao() {
		const { history } = this.props;
		_post('/conaz/v2/auth/construtor/editar_conta', { primeiro_acesso: false });
		history.push('/cotacoes');
	}

	render() {
		return (
			<Welcome
				{...this.state}
				{...this.props}
				navigate={this.navigate}
				updateInstrucao={this.updateInstrucao}
			/>
		);
	}
}

WelcomeContainer.propTypes = {
	// =========== store
	user: PropTypes.object.isRequired, // eslint-disable-line
	// =========== funcs
	updateUser: PropTypes.func.isRequired, // eslint-disable-line
	// =========== router
	history: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WelcomeContainer));
