import styled from 'styled-components';

import { Table } from 'antd';

export const StyledTable = styled(Table)`
	th {
		background: #f1f3f6 !important;
		color: #595959 !important;
		font-size: 12px;
		line-height: 16px;
	}

	td {
		color: #595959;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 1px 0px #D9D9D9;
	}

	.ant-table-tbody {
		background-color: #FFFF !important;
	}

	.ant-table-tbody > tr > td {
		background-color: #FFFF !important;
	}

	@media(max-width: 768px) {
		.ant-table {
			max-width: 100%;
			min-height: 350px;
			max-height: 100%;
			resize: none;
			overflow-x: auto;
		}
	}
`;

const getDesativadoColor = (status) => {
	if (status) return '#F5222D';
	return '#52C41A';
	// return '#2F54EB';
};

export const StatusIcon = styled.div`
	background-color: ${({ $status }) => getDesativadoColor($status)};
	border-radius: 33px;
	width: 6px;
	height: 6px;
`;

export const StatusCell = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const ActionsArea = styled.div`
	display: flex;
	gap: 11px;
	align-items: center;
	& > svg {
		width: 22px;
		height: 20px;
		cursor: pointer;
	}
`;

export const FlexCell = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	& > span {
		color: #0076D6;
	}
`;

export const NumberBadge = styled.div`
	color: #0076D6;
	border-radius: 10px;
	width: fit-content;
	padding: 1px 6px;
	border: 1px solid #0076D6;
	box-sizing: border-box;
`;
