import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-paginate';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Component
import SiengeFornecedoresRow from '../../../components/_parts/SiengeFornecedoresRow/SiengeFornecedoresRow';
import LoadingPage from '../../../components/_parts/_loadings/LoadingPage/LoadingPage';
import ModalPortal from '../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalLimitSienge from '../../../components/_parts/_modals/ModalLimitSienge/ModalLimitSienge';

const SiengeFornecedores = ({
	// =========== state
	request_state,
	fornecedores,
	search,
	fornecedoresPage,
	error_message,
	// =========== store
	// =========== local funcs
	handleSelectedFornecedor,
	handlePageChange,
	handleAtualizarFornecedores,
	removeLimitError,
	// =========== prop funcs
}) => {
	const { activePage, per_page, totalFornecedores } = fornecedoresPage;

	if (request_state === 1) {
		return <LoadingPage />;
	}

	if (request_state === 10) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-xs-12" styleName="mt-2">
						<LoadingPage small_pad_top />
						<h4 style={{ textAlign: 'center', margin: '20px 0' }}>Sincronizando seus fornecedores do Sienge</h4>
						<p style={{ textAlign: 'center', fontSize: '16px' }}>Aguarde enquanto salvamos todos os seus fornecedores do Sienge em nosso sistema.<br /> Dependendo do número de fornecedores, <b>este processo pode demorar alguns minutos</b>.</p>
					</div>
				</div>
			</div>
		);
	}

	if (request_state === 20) {
		return (
			<ModalPortal>
				<ModalWrapperPortal closeModal={() => {}}>
					<ModalLimitSienge
						error_message={error_message}
						requestToContinue={handleAtualizarFornecedores}
						removeLimitError={removeLimitError} />
				</ModalWrapperPortal>
			</ModalPortal>
		);
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-xs-12" styleName="mt-2" style={{ marginTop: '1em' }}>
					{fornecedores.map(fornecedor => (
						<SiengeFornecedoresRow
							key={fornecedor.id}
							fornecedor={fornecedor}
							handleSelectedFornecedor={handleSelectedFornecedor}
							border />
					))}
				</div>
			</div>
			{search === '' && (
				<div styleName="pagination-area">
					<Pagination
						forcePage={activePage - 1}
						containerClassName="pagination"
						activeClassName="active"
						breakLabel="..."
						pageCount={Math.ceil(totalFornecedores / per_page)}
						pageRangeDisplayed={3}
						marginPagesDisplayed={1}
						previousLabel="<"
						nextLabel=">"
						onPageChange={handlePageChange} />
				</div>
			)}
			<div className="row">
				<div className="col-xs-12">
					<p><br /></p><p><br /></p><p><br /></p>
				</div>
			</div>
		</div>
	);
};

SiengeFornecedores.propTypes = {
	// =========== state
	request_state: PropTypes.number.isRequired,
	fornecedores: PropTypes.array.isRequired,
	search: PropTypes.string.isRequired,
	fornecedoresPage: PropTypes.object.isRequired,
	error_message: PropTypes.string.isRequired,
	// =========== store
	// =========== local funcs
	handleSelectedFornecedor: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleAtualizarFornecedores: PropTypes.func.isRequired,
	removeLimitError: PropTypes.func.isRequired,
	// =========== prop funcs
};

export default CSSModules(SiengeFornecedores, styles, { allowMultiple: true });
