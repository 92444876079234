import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// Functions
import { _get, _post } from 'components/_functions/_requests';
import { handleRequestErrors } from 'components/_functions/_handleRequestErrors';

// Components
import Fornecedor from './Fornecedor';

const FornecedorContainer = props => {
	const {
		nova_compra,
		obras_by_id,
		obra_selected,
		updateComprasRaw,
		handleNovoFornecedorModal,
		handleModalFornecedor,
		infoFornecedor,
		handleFixError,
		autoSaveStart,
	} = props;

	const [fornecedor, setFornecedor] = useState({});
	const [fornecedores, setFornecedores] = useState([]);
	const [searchInput, setSearchInput] = useState('');
	const [loading, setLoading] = useState(false);
	const [toggleList, setToggleList] = useState(false);
	const [timerIsOn, setTimerIsOn] = useState(false);
	const [timer, setTimer] = useState(null);
	const fornecedorInput = useRef();

	useEffect(() => {
		setFornecedor(nova_compra.fornecedor);
	}, [nova_compra]);

	const handleTimer = () => {
		setTimerIsOn(false);
		clearTimeout(timer);
	};

	const handleFornecedor = (f = {}) => {
		setFornecedor(f);
		const _nova_compra = {
			...nova_compra,
			fornecedor: { ...f },
		};
		updateComprasRaw({ nova_compra: _nova_compra });
	};

	const handleDisplayFornecedor = async () => {
		handleModalFornecedor();
		try {
			const { data: f } = await _get(`/conaz/v2/fornecedores/${nova_compra.fornecedor.id}`);
			infoFornecedor({ ...f, ja_existe: true });
		} catch (err) {
			handleRequestErrors(err);
		}
	};

	const handleEditarFornecedor = () => {
		setSearchInput(fornecedor.nome);
		handleTimer();
	};

	const handleChangeFornecedor = async f => {
		setToggleList(false);
		handleModalFornecedor();
		if (f.usuarios) infoFornecedor(f);
		else {
			try {
				const { data: _fornecedor } = await _get(`/conaz/v2/fornecedores/${f.id}`);
				infoFornecedor({ ..._fornecedor });
			} catch (err) {
				handleRequestErrors(err);
			}
		}
	};

	const handleNovoFornecedor = () => {
		setToggleList(false);
		handleNovoFornecedorModal(searchInput);
	};

	const handleUndoFornecedor = () => {
		const _nova_compra = {
			...nova_compra,
			fornecedor: {
				fornecedor_id: null,
				usuario_id: null,
			},
		};

		if (nova_compra.avaliacao_fornecedor.detalhe || nova_compra.avaliacao_vendedor.detalhe) {
			_post('/avaliacao/v2/usuario_fornecedor/deletar', { compra_id: nova_compra.id }).then(() => {
				_nova_compra.avaliacao_vendedor = {};
				updateComprasRaw({ nova_compra: _nova_compra });
			});
		} else {
			updateComprasRaw({ nova_compra: _nova_compra });
		}

		autoSaveStart();
		setSearchInput('');
		setFornecedor('');
		setFornecedores([]);
		handleTimer();
		if ((fornecedorInput || {}).current) fornecedorInput.current.focus();
	};

	useEffect(() => {
		if (searchInput !== '') {
			setLoading(true);
			setTimer(setTimeout(async () => {
				try {
					const typed_encode = encodeURIComponent(searchInput).replace(/%0A/g, '');
					const cadastrado_por = obras_by_id[obra_selected].cliente.id;
					const searchUrl = `/conaz/v2/fornecedores/buscar_por_nome?nome=${typed_encode}&cadastrado_por=${cadastrado_por}`;
					const data = await _get(searchUrl)
						.then(response => response.data);
					setLoading(false);
					setToggleList(true);
					setFornecedores([...data]);
				} catch (err) {
					handleRequestErrors(err);
				}
			}, 900));
			setTimerIsOn(true);
		} else {
			setLoading(false);
			setToggleList(true);
			setFornecedores([]);
		}
	}, [searchInput]);

	const handleNomeFornecedor = ({ target }) => {
		handleFixError('fornecedor');
		handleTimer();
		setSearchInput(target.value);
		setFornecedores([]);
		setLoading(false);
	};

	const handleClickOutside = ({ target }) => {
		if (
			fornecedorInput.current
			&& !fornecedorInput.current.contains(target)
			&& !target.closest('button[reference=fornecedor-item]')
			&& !target.closest('div[reference=fornecedores-lista]')
		) {
			setToggleList(false);
		} else setToggleList(true);
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
			setFornecedor(nova_compra.fornecedor || {});
		};
	}, []);

	return (
		<Fornecedor
			{...props}
			fornecedores={fornecedores}
			fornecedor={fornecedor}
			timer={timerIsOn}
			loading={loading}
			searchInput={searchInput}
			fornecedorInput={fornecedorInput}
			toggleList={toggleList}
			handleClickOutside={handleClickOutside}
			handleFornecedor={handleFornecedor}
			handleNomeFornecedor={handleNomeFornecedor}
			handleNovoFornecedor={handleNovoFornecedor}
			handleUndoFornecedor={handleUndoFornecedor}
			handleDisplayFornecedor={handleDisplayFornecedor}
			handleChangeFornecedor={handleChangeFornecedor}
			handleEditarFornecedor={handleEditarFornecedor}
		/>
	);
};

FornecedorContainer.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	obras_by_id: PropTypes.object.isRequired,
	obra_selected: PropTypes.number.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	handleNovoFornecedorModal: PropTypes.func.isRequired,
	handleModalFornecedor: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
};

export default FornecedorContainer;
