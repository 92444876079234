import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../../components/_parts/_modals/ModalConfirm/ModalConfirm';

// Functions
import { _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

class CancelarActions extends Component {
	state = {
		confirmando_descancelamento: 0,
		descancelar_modal: false,
		error_message: undefined,
	};

	descancelarCompraModal = () => {
		const { descancelar_modal, confirmando_descancelamento } = this.state;
		const confirmando = !descancelar_modal
			? confirmando_descancelamento
			: 0;
		this.setState({
			descancelar_modal: !descancelar_modal,
			confirmando_descancelamento: confirmando,
		});
	};

	descancelaCompra = () => {
		const { compra, redirectAfterConcluir } = this.props;
		this.setState({ confirmando_descancelamento: 1 });
		_post(`/conaz/v2/compras/${compra.id}/descancelar`, {})
			.then(() => redirectAfterConcluir())
			.catch(error => {
				const conaz_error = ((
					error.response || {})
					.data || {})
					.message || '';

				if (conaz_error === 'Apenas os responsáveis da compra podem editá-la.') {
					this.setState({
						confirmando_descancelamento: 3,
						error_message: conaz_error,
					});
					return;
				}

				this.setState({
					confirmando_descancelamento: 3,
					error_message: undefined,
				});
				handleRequestErrors(error);
			});
	};

	render() {
		const {
			descancelar_modal,
			confirmando_descancelamento,
			error_message,
		} = this.state;
		const {
			compra,
		} = this.props;
		const {
			motivo_cancelamento,
		} = compra;

		const is_triagem =
			process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

		if (is_triagem) return null;

		return (
			<>
				{descancelar_modal && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.descancelarCompraModal}>
							<ModalConfirm
								title="Deseja reverter o cancelamento desta compra?"
								message={<p>Você poderá editar esta compra em seguida.</p>}
								confirmando={confirmando_descancelamento}
								confirmFunc={this.descancelaCompra}
								error_message={error_message}
								button_type="secondary-conaz"
								button_text="Sim, reverter"
								cancel_button_text="Voltar" />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<div styleName="alerts-messages-wrapper red">
					<div styleName="am-text">
						<i className="fa fa-times" />
						<b>Motivo do cancelamento</b>
						<br />
						&#34;{motivo_cancelamento}&#34;
					</div>
					<div styleName="am-buttons">
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button"
							onClick={this.descancelarCompraModal}>
							<b>Reverter o cancelamento</b>
						</button>
					</div>
				</div>
			</>
		);
	}
}

CancelarActions.propTypes = {
	compra: PropTypes.object.isRequired,
	redirectAfterConcluir: PropTypes.func.isRequired,
};

export default CSSModules(CancelarActions, styles, { allowMultiple: true });
