import update from 'immutability-helper';
import qc from './store';
import { UPDATE_QC } from './types';

const reducer = (state = qc, action) => {
	switch (action.type) {
	case UPDATE_QC:
		switch (action.field.length) {
		case 1:
			return update(state, {
				[action.field[0]]: { $set: action.value },
			});

		case 2:
			return update(state, {
				[action.field[0]]: {
					[action.field[1]]: { $set: action.value },
				},
			});

		default:
			return state;
		}

	default:
		return state;
	}
};

export default reducer;
