import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Form, Button, Checkbox, Row, Col, Divider, Select, Input } from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';

// Redux HOC
import { connect } from 'react-redux';
import withStore from '../../../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../../store/reduxDispatchs';

import CopyResponsaveisForm from './copyResponsaveisForm';

function AssociaEmpresa({ ...props }) {
	const {
		empresas,
		getEmpresas,
		openEmpresaDrawer,
		obrasDrawer,
		handleDrawerCopyEmpresa,
		handleHaveChangedAssociaEmpresa,
	} = props;

	const [responsavelTbm, setResponsavelTbm] = useState(false);
	const [filterResponsavelEmpresas, setFilterResponsavelEmpresas] = useState('');
	const [filterFaturamentoEmpresas, setFilterFaturamentoEmpresas] = useState('');
	const disableAll = empresas.isFetching;

	const searchFaturamentoEmpresas = (searchTxt) => {
		setFilterFaturamentoEmpresas(searchTxt);
	};

	const searchResponsavelEmpresas = (searchTxt) => {
		setFilterResponsavelEmpresas(searchTxt);
	};

	const handleCadastrarEmpresa = () => {
		openEmpresaDrawer();
	};

	const handleChangedField = () => {
		const form = obrasDrawer.formInstance;
		const {
			empresa_responsavel_obra: obra,
			empresa_responsavel_fatura: fatura,
			empresa_resp_obra_faturamento: check,
		} = form.getFieldsValue();
		if (obra && (fatura || check)) {
			handleHaveChangedAssociaEmpresa(true);
		} else {
			handleHaveChangedAssociaEmpresa(false);
		}
	};

	const handleResponsavelTambem = () => {
		setResponsavelTbm(p => !p);
		handleChangedField();
	};

	const handleCopyState = () => {
		handleDrawerCopyEmpresa();
	};

	const filterEmpresas = (txt) => {
		if (!txt) return empresas.empresas.map((emp) => ({ label: emp.nome, value: emp.id, data: emp }));

		return empresas.empresas.filter((emp) => emp?.nome.toLowerCase().includes(txt.toLowerCase()))
			.map((emp) => ({ label: emp.nome, value: emp.id, data: emp }));
	};

	const respEmpresas = filterEmpresas(filterResponsavelEmpresas);
	const fatEmpresas = filterEmpresas(filterFaturamentoEmpresas);

	useEffect(() => {
		if (!empresas.empresas.length && !empresas.isFetching) {
			getEmpresas();
		}
	}, [empresas, getEmpresas]);

	useEffect(() => {
		const form = obrasDrawer.formInstance;
		const { empresa_resp_obra_faturamento } = form.getFieldsValue();
		if (empresa_resp_obra_faturamento !== undefined) setResponsavelTbm(empresa_resp_obra_faturamento);
	}, []);

	return (
		!obrasDrawer.copyEmpresaState ?
			<>
				<Form.Item noStyle name="obra_id"> <Input hidden /> </Form.Item>
				<div style={{ margin: '16px 0px 16px 0px' }}>
					<Button className="copy-obra-btn" disabled={disableAll} onClick={handleCopyState}>
						<CopyOutlined />
						Copiar dados de outra obra
					</Button>
				</div>
				<div className="block-title">
					Responsável pela obra
				</div>
				<Row gutter={12}>
					<Col span={12}>
						<Form.Item name="empresa_responsavel_obra" label="Nome da empresa">
							<Select
								allowClear
								onChange={handleChangedField}
								options={respEmpresas}
								disabled={disableAll}
								onSearch={searchResponsavelEmpresas}
							/>
						</Form.Item>
					</Col>
					<Col>
						<Form.Item name="empresa_resp_obra_faturamento" label=" " valuePropName="checked" initialValue={false}>
							<Checkbox disabled={disableAll} onChange={handleResponsavelTambem}>
								Também é responsável pelo faturamento
							</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Divider className="divider" />
				<div className="block-title">
					Responsável pelo Faturamento
				</div>
				<Row gutter={12}>
					<Col span={12}>
						<Form.Item label="Nome da empresa" name="empresa_responsavel_fatura">
							<Select
								onChange={handleChangedField}
								options={fatEmpresas}
								disabled={disableAll || responsavelTbm}
								onSearch={searchFaturamentoEmpresas}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						{!responsavelTbm && <Form.Item label=" ">
							<Button onClick={handleCadastrarEmpresa} className="drawer-obras-empresa-add">
								<PlusOutlined />
								Cadastrar Empresa
							</Button>
						</Form.Item>}
					</Col>
				</Row>
			</>
			:
			<CopyResponsaveisForm />
	);
}

AssociaEmpresa.propTypes = {
	empresas: PropTypes.shape({
		isFetching: PropTypes.bool,
		empresas: PropTypes.arrayOf(PropTypes.shape({ })),
	}),
	handleHaveChangedAssociaEmpresa: PropTypes.func,
	obrasDrawer: PropTypes.shape({
		formInstance: PropTypes.shape(),
		copyEmpresaState: PropTypes.bool,
	}),
	getEmpresas: PropTypes.func,
	handleDrawerCopyEmpresa: PropTypes.func,
	openEmpresaDrawer: PropTypes.func,
};

AssociaEmpresa.defaultProps = {
	empresas: {},
	obrasDrawer: {},
	handleHaveChangedAssociaEmpresa: () => {},
	openEmpresaDrawer: () => {},
	getEmpresas: () => {},
	handleDrawerCopyEmpresa: () => {},
};


const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(AssociaEmpresa));

