/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Componentes
import CV3OpcoesPagamento from './CV3OpcoesPagamento';
import InputNumber from '../../../../components/_parts/_inputs/InputNumber/InputNumber';
import Tooltip from '../../../../components/_parts/_tooltips/ButtonToolTip/ButtonToolTip';

class Pagamento extends Component {
	handleInputChange = (item_key, field, value) => {
		const {
			handleFixError,
			autoSaveStart,
			nova_compra,
			updateComprasRaw,
		} = this.props;
		handleFixError('custos_adicionais');
		const compra = {
			...nova_compra,
			custos_adicionais: value,
		};
		updateComprasRaw({ nova_compra: compra });
		autoSaveStart();
	};

	render() {
		const {
			nova_compra,
			updateComprasRaw,
			autoSaveStart,
			handleFixError,
			errors,
		} = this.props;
		const {
			is_editable,
			rc,
		} = nova_compra;
		const custos_adicionais = nova_compra.custos_adicionais || 0;

		const label_class = (errors.opcao_pagamento && is_editable)
			? 'label error-label'
			: 'label';

		return (
			<div styleName="info-row">
				<div styleName={label_class}>
					Condição de pagamento e desconto
					{is_editable && <span styleName="obrigatorio"> *</span>}
				</div>
				<CV3OpcoesPagamento
					nova_compra={nova_compra}
					updateComprasRaw={updateComprasRaw}
					autoSaveStart={autoSaveStart}
					errors={errors}
					handleFixError={handleFixError} />
				{is_editable && (
					<>
						<span styleName="label-inline" style={{ paddingTop: '25px' }}>Custos adicionais:</span>
						{rc.custos_adicionais !== undefined &&
							rc.custos_adicionais !== custos_adicionais && (
								<>
									<div styleName="tag editado" style={{ marginTop: '15px' }}>Editado</div>
									<div styleName="tooltip-hover">
										<Tooltip
											margin_top="-23%"
											margin_left="11%"
											text="Diferente da cotação do fornecedor" />
									</div>
								</>
							)}
						<div styleName="custos-input-wrapper">
							<InputNumber
								type="item"
								field_id={`custos_adicionais-${nova_compra.id}`}
								item_key={0}
								field="custos_adicionais"
								default_value={custos_adicionais}
								updateFunction={this.handleInputChange}
								icon="R$"
								icon_width={40}
								zerar_erros={false} />
						</div>
					</>
				)}
			</div>
		);
	}
}

Pagamento.propTypes = {
	nova_compra: PropTypes.object.isRequired,
	updateComprasRaw: PropTypes.func.isRequired,
	autoSaveStart: PropTypes.func.isRequired,
	handleFixError: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
};

export default CSSModules(Pagamento, styles, { allowMultiple: true });
