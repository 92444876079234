import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IntegracaoSearchInput from './IntegracaoSearchInput';
import IntegracaoSearchResult from './IntegracaoSearchResult';
import IntegracaoSugestoes from './IntegracaoSugestoes';
import Loading from '../../../../../../components/_parts/_loadings/Loading/Loading';

class IntegracaoSearch extends Component {
	state = {
		search_state: 0,
		results: [],
		// search: '',
	};

	updateState = (fields_n_values) => {
		this.setState(fields_n_values);
	}

	render() {
		const {
			search_state,
			results,
			// search,
		} = this.state;
		const { fornecedor_conaz, saveFornecedorSienge, handleFornecedorAssociado } = this.props;

		return (
			<>
				<div styleName="title">
					Fornecedor Sienge
				</div>
				<div styleName="search">
					<IntegracaoSearchInput
						updateState={this.updateState} />

					{search_state === 1 && (
						<div styleName="no-result-wrapper">
							<Loading />
						</div>
					)}

					{search_state === 2 && (
						results.map(fornecedor => (
							<IntegracaoSearchResult
								key={fornecedor.id}
								saveFornecedorSienge={saveFornecedorSienge}
								handleFornecedorAssociado={handleFornecedorAssociado}
								fornecedor={fornecedor} />
						))
					)}

					{(search_state === 2 && results.length === 0) && (
						<p>Nenhum fornecedor encontrado</p>
					)}

					{search_state === 3 && (
						<div styleName="no-result-wrapper">
							Error!
						</div>
					)}

					{(search_state === 0 && results.length === 0) && (
						<IntegracaoSugestoes
							saveFornecedorSienge={saveFornecedorSienge}
							fornecedor_conaz={fornecedor_conaz}
							handleFornecedorAssociado={handleFornecedorAssociado} />
					)}
				</div>
			</>
		);
	}
}

IntegracaoSearch.propTypes = {
	fornecedor_conaz: PropTypes.object.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	handleFornecedorAssociado: PropTypes.func.isRequired,
};

export default CSSModules(IntegracaoSearch, styles, { allowMultiple: true });
