import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const CombinacaoWrapper = ({ children, order, combinacao_criada_key, handleOption }) => {
	if (combinacao_criada_key !== -1 && order === combinacao_criada_key) {
		return (
			<div styleName="combination-row-a">
				<div styleName="combination-row selected">
					{children}
				</div>
			</div>
		);
	}

	if (combinacao_criada_key !== -1 && order !== combinacao_criada_key) {
		return (
			<div styleName="combination-row-a" style={{ opacity: '0.3' }}>
				<div styleName="combination-row">
					{children}
				</div>
			</div>
		);
	}

	return (
		<Link to="#" styleName="combination-row-a" onClick={handleOption}>
			<div styleName="combination-row">
				{children}
			</div>
		</Link>
	);
};

CombinacaoWrapper.propTypes = {
	children: PropTypes.array.isRequired,
	order: PropTypes.number.isRequired,
	combinacao_criada_key: PropTypes.number.isRequired,
	handleOption: PropTypes.func.isRequired,
};

export default CSSModules(CombinacaoWrapper, styles, { allowMultiple: true });
