import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { formatNumbers } from '../../../_functions/_formatNumbers';
import { titleCase } from '../../../_functions/_titleCase';

const TabInformacoes = ({
	fornecedor,
	cliente_id,
	observacoes_fornecedor,
	exibir_observacoes,
	exibir_status,
	exibir_cadastrado_por,
}) => {
	const statusList = [
		{ value: -10, label: 'Não cadastrado pelo fornecedor' },
		{ value: -5, label: 'Sem cadastro e já respondeu cotação' },
		{ value: 0, label: 'Cadastro básico' },
		{ value: 10, label: 'Cadastro com locais de entrega' },
		{ value: 20, label: 'Cadastro com famílias de mercado' },
	];

	const statusLabel = _value => {
		const _statusLabel = statusList.find(st => st.value === _value);
		return _statusLabel ? _statusLabel.label : 'Sem registro';
	};

	const { endereco, documento, site } = fornecedor;
	const _rua = (endereco !== null && endereco.logradouro !== null) ? endereco.logradouro : '';
	const _numero = (endereco !== null && endereco.numero !== null && endereco.numero !== undefined) ? endereco.numero : '';
	const _bairro = (endereco !== null && endereco.bairro !== null && endereco.bairro !== undefined) ? endereco.bairro : '';
	const _cidade = (endereco !== null && endereco.cidade !== null && endereco.cidade !== undefined) ? titleCase(endereco.cidade) : '';
	const _estado = (endereco !== null && endereco.estado !== null && endereco.estado !== undefined) ? endereco.estado : '';

	const _documento = (documento !== '' && documento !== null) ? formatNumbers(documento, 'cnpj') : <em>Sem registro</em>;
	const _site = (site !== '' && site !== null) ? site : '';
	const _site_link = (_site.indexOf('https://') === -1 && _site.indexOf('http://') === -1) ? `http://${_site}` : _site;

	return (
		<>
			{exibir_cadastrado_por ? (
				<>
					{fornecedor.cadastrado_por && (
						<span className="label label-primary">
							{`Cadastrado pelo ${fornecedor.cadastrado_por === cliente_id ? 'suporte' : 'construtor'}`}
						</span>
					)}
				</>
			) : (
				<>
					{cliente_id === fornecedor.cadastrado_por && (
						<span className="label label-primary">Cadastrado por você</span>
					)}
				</>
			)}
			{fornecedor.razao_social && (
				<div styleName="cnpj">
					<span styleName="label">Razão social:</span> {fornecedor.razao_social}
				</div>
			)}
			<div styleName="cnpj">
				<span styleName="label">CNPJ:</span> {_documento}
			</div>
			{_cidade !== '' ? (
				<div styleName="endereco">
					<span styleName="label">Endereço:</span> {_rua !== '' && <span>{_rua}, </span>}
					{_numero !== '' && <span>nº {_numero}, </span>}
					{_bairro !== '' && <span>{_bairro} - </span>}
					{_cidade !== '' && <span>{_cidade}</span>}
					{_estado !== '' && <span>/{_estado}</span>}
				</div>
			) : (
				<div styleName="endereco">
					<span styleName="label">Endereço:</span> <em>Sem registro</em>
				</div>
			)}
			{_site !== '' && (
				<div styleName="endereco">
					<span styleName="label">Site:</span> <a href={_site_link} target="_blank" rel="noopener noreferrer">{_site}</a>
				</div>
			)}
			{exibir_status && (
				<div styleName="cnpj">
					<span styleName="somente-triagem">*</span><span styleName="label"> Status:</span> {statusLabel(fornecedor.status)}
				</div>
			)}
			{(observacoes_fornecedor && exibir_observacoes) && (
				<div styleName="endereco">
					<span styleName="somente-triagem">*</span> <strong>Observações sobre o fornecedor: </strong>
					<h4 style={{ whiteSpace: 'pre-line' }}>{observacoes_fornecedor}</h4>
				</div>
			)}
		</>
	);
};

TabInformacoes.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	cliente_id: PropTypes.number,
	observacoes_fornecedor: PropTypes.string,
	exibir_status: PropTypes.bool.isRequired,
	exibir_observacoes: PropTypes.bool.isRequired,
	exibir_cadastrado_por: PropTypes.bool.isRequired,
};

TabInformacoes.defaultProps = {
	cliente_id: -1,
	observacoes_fornecedor: '',
};

export default CSSModules(TabInformacoes, styles, { allowMultiple: true });
