/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// LIB
import _ from 'lodash';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { getLocalstorageData } from '../../../_functions/_getLocalstorageData';
import { splitName } from './utils';

class FileSending extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: 0,
		};
	}

	componentDidMount() {
		this.sendFile();
	}

	sendFile = () => {
		const { config, updateFiles } = this.props;
		const file = this.props.arquivo;

		const setProgress = progress => this.setState({ progress });

		const upload_instance = axios.create();
		const baseURL = getLocalstorageData('environment', 'base_url');
		const token = getLocalstorageData('user', 'token');
		upload_instance.defaults.headers.common.token = token;
		const axios_config = {
			baseURL,
			timeout: 500000,
			onUploadProgress(progress) {
				const percent_completed = Math.round((progress.loaded * 100) / progress.total);
				setProgress(percent_completed);
			},
		};
		const data = new FormData();
		data.append('files', file.file, file.nome);
		data.append('tipo', file.tipo);
		data.append('visivel_pro_fornecedor', file.visivel_pro_fornecedor);
		upload_instance.post(config.post_url, data, axios_config).then(response => {
			setTimeout(() => {
				const arquivo = {
					...this.props.arquivo,
					...response.data[0] || {},
					success: 1,
				};
				const arquivos_para_enviar = {
					...this.props.arquivos_para_enviar,
					[arquivo.id_temp]: arquivo,
				};
				updateFiles('arquivos_para_enviar', arquivos_para_enviar);
			}, 400);
		}).catch(error => {
			const error_msg_conaz = (((
				error || {})
				.response || {})
				.data || {})
				.message || 'Aconteceu algo errado';
			const arquivo = {
				...this.props.arquivo,
				success: 2,
				error_message: error_msg_conaz,
			};
			const arquivos_para_enviar = {
				...this.props.arquivos_para_enviar,
				[arquivo.id_temp]: arquivo,
			};
			updateFiles('arquivos_para_enviar', arquivos_para_enviar);
		});
	}

	handleFileTypeLen = fileName => {
		if (fileName.length <= 11) return 'file-all';
		return 'bigger-name';
	}

	toogleBiggerType = typeName => {
		if (typeName.length > 11) return <p styleName="bigger-type">{typeName}</p>;
		return <p>{typeName}</p>;
	}

	resendFile = () => {
		const { tryAgainFunction, updateFiles, envios_finalizados } = this.props;
		if (!envios_finalizados) return;
		const file_error = _.filter(this.props.arquivos_para_enviar, x => x.success === 2);
		const arquivos_para_enviar = {};
		_.map(file_error, x => { arquivos_para_enviar[x.id_temp] = x; });
		updateFiles('arquivos_para_enviar', arquivos_para_enviar);
		tryAgainFunction();
	}

	render() {
		const { progress } = this.state;
		const { arquivo } = this.props;
		const icon_success = {
			0: <i className="fa fa-file-o" aria-hidden="true" />,
			1: <i className="fa fa-file" aria-hidden="true" style={{ color: '#337ab7' }} />,
			2: <i className="fa fa-file" aria-hidden="true" style={{ color: '#e43062' }} />,
		};

		return (
			<div styleName={arquivo.success === 2 ? 'file-wrapper sending error' : 'file-wrapper sending'}>
				<div styleName="file-all">
					<div styleName="nome">
						<div styleName="icon-nome">
							<span>{icon_success[arquivo.success]}</span>
							{this.toogleBiggerType(arquivo.tipo)}
						</div>
						<>
							{arquivo.success === 1 && (
								<i className="fa fa-check-circle fa-fw" aria-hidden="true" style={{ color: '#337ab7', marginLeft: '6px' }} />
							)}
							{(arquivo.success === 2 || arquivo.success === 0) && (
								<div style={{ marginLeft: '6px', width: '22px' }} />
							)}
						</>
					</div>
					<span styleName="file-name-desktop">{splitName(arquivo.nome, 25)}</span>
					<span styleName="file-name-mobile">{splitName(arquivo.nome, 8)}</span>
				</div>
				{arquivo.success === 2 && (
					<div styleName="file-progress">
						<div styleName="error-message">
							<p>Erro. {arquivo.error_message}!</p>
							<button
								type="button"
								className="btn btn-link"
								onClick={this.resendFile}>
								Tentar novamente
							</button>
						</div>
					</div>
				)}
				{arquivo.success === 0 && (
					<div styleName="file-progress">
						<div className="progress" styleName="progress">
							<div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }} />
						</div>
					</div>
				)}
			</div>
		);
	}
}

FileSending.propTypes = {
	envios_finalizados: PropTypes.bool.isRequired,
	config: PropTypes.shape({
		post_url: PropTypes.string.isRequired,
		put_url: PropTypes.string.isRequired,
		delete_url: PropTypes.string.isRequired,
	}).isRequired,
	tryAgainFunction: PropTypes.func.isRequired,
	arquivo: PropTypes.shape({
		id: PropTypes.number.isRequired,
		id_temp: PropTypes.number,
		nome: PropTypes.string.isRequired,
		file: PropTypes.object.isRequired,
		tipo: PropTypes.string.isRequired,
		tamanho: PropTypes.number,
		success: PropTypes.number.isRequired,
		size_error: PropTypes.bool.isRequired,
		error_message: PropTypes.string.isRequired,
		visivel_pro_fornecedor: PropTypes.bool.isRequired,
	}).isRequired,
	arquivos_para_enviar: PropTypes.objectOf(PropTypes.shape({
		id: PropTypes.number.isRequired,
		id_temp: PropTypes.number,
		nome: PropTypes.string.isRequired,
		file: PropTypes.object.isRequired,
		tipo: PropTypes.string.isRequired,
		tamanho: PropTypes.number,
		success: PropTypes.number.isRequired,
		size_error: PropTypes.bool.isRequired,
		error_message: PropTypes.string.isRequired,
	})).isRequired,
	updateFiles: PropTypes.func.isRequired,
	// list_to_map: PropTypes.arrayOf(PropTypes.number).isRequired,
	// updateCompras: PropTypes.func.isRequired,
	// anexos: PropTypes.arrayOf(PropTypes.shape({
	// 	id: PropTypes.number,
	// 	nome: PropTypes.string,
	// 	tipo: PropTypes.string,
	// 	url: PropTypes.string,
	// 	criado_em: PropTypes.string,
	// 	tamanho: PropTypes.number,
	// 	visto: PropTypes.bool,
	// })).isRequired,
};


export default CSSModules(FileSending, styles, { allowMultiple: true });
