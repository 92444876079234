import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Redux
import { buildMapStateToProps } from '../../../../../../store/reduxDispatchs';

// Components
import ButtonExpand from './ButtonExpand';
import InfoCompacted from './InfoCompacted';
import IntegracaoSearch from '../IntegracaoPlanilhaSearch/IntegracaoPlanilhaSearch';
import InfoExpanded from './InfoExpanded';

class InfoItemPlanilha extends Component {
	state = {
		compacted: true,
	};

	handleExpand = () => {
		this.setState({ compacted: !this.state.compacted });
	}

	render() {
		const {
			compacted,
		} = this.state;
		const {
			item_on_edition,
			updateFullItemById,
			obra_id,
			autoSave,
			client_id,
		} = this.props;

		const item_integrado = item_on_edition.item_integrado || {};
		const empty_item = item_integrado.id === undefined;

		const ButtonExpandView = (
			<ButtonExpand
				compacted={compacted}
				handleExpand={this.handleExpand}
			/>
		);

		// Compacted
		if (compacted) {
			return (
				<div styleName="content-info-planilha">
					{ButtonExpandView}
					<InfoCompacted
						empty_item={empty_item}
						item_on_edition={item_on_edition}
					/>
				</div>
			);
		}

		// Empty
		if (empty_item) {
			return (
				<div styleName="content-info-planilha">
					{ButtonExpandView}
					<IntegracaoSearch
						obra_id={obra_id}
						client_id={client_id}
						item_on_edition={item_on_edition}
						updateFullItemById={updateFullItemById}
						handleExpand={this.handleExpand}
						autoSave={autoSave}
					/>
				</div>
			);
		}

		// Expanded
		return (
			<div styleName="content-info-planilha">
				{ButtonExpandView}
				<InfoExpanded
					item_on_edition={item_on_edition}
					updateFullItemById={updateFullItemById}
				/>
			</div>
		);
	}
}

InfoItemPlanilha.propTypes = {
	item_on_edition: PropTypes.object.isRequired,
	obra_id: PropTypes.number.isRequired,
	client_id: PropTypes.number.isRequired,
	updateFullItemById: PropTypes.func.isRequired,
	autoSave: PropTypes.func.isRequired,
	user: PropTypes.shape({}).isRequired,
	// importado_planilha: PropTypes.number.isRequired,
};

const _InfoItem = CSSModules(InfoItemPlanilha, styles, { allowMultiple: true });
const mapStateToProps = props => buildMapStateToProps(props, ['user']);
export default connect(mapStateToProps, null)(_InfoItem);
