/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
// icons
import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons';

// Components
import { Button, Popconfirm } from 'antd';
import { crudObraCriadoEm } from 'components/_functions/_editDateFormat';
import LoadingPage from '../../components/_parts/_loadings/LoadingPage/LoadingPage';
import InfoPhotos from './subcomponents/InfoPhotos';
import InfoWithLabel from './subcomponents/InfoWithLabel';
import CollapseLine from './subcomponents/CollpaseLine';
import { Header, ObraInfos, StatusIcon, Container, Content } from './styled';

// Layout
import LayoutWrapper from '../../components/LayoutWrapper';

// Redux HOC
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

import styles from './style.module.scss';

// tabelas
import { StyledTable } from './tabelas/styled';
import tableLocale from './tabelas/locale';
import empresaColumns from './tabelas/empresaColumns';
import usuarioColumns from './tabelas/usuarioColumns';

// drawer
import EditObraDrawer from './drawers/EditObraDrawer';
import AssociaEmpresaDrawer from './drawers/AssociaEmpresa';
import AssociaUsuarioDrawer from './drawers/AssociaUsuario';
import { alteraStatusObra } from './AlteraStatusObra';

function AssociacoesObra({ ...props }) {
	const maxPerPageEmpresa = 10;
	const maxPerPageUsuario = 10;
	const campoVazio = '-';
	const history = useHistory();

	const {
		match,
		associaObra: associaObrasEstado,
		getObra,
		limpaEstadoAssociaObra,
		// drawers
		openEditObraSelecionada,
		associaEmpresaOpenDrawer,
		associaEmpresaOpendEditDrawer,
		associaUsuarioOpenDrawer,
		associaUsuarioOpenEditDrawer,
		// funcs
		removeEmpresaAssociada,
		removeUsuarioAssociado,
		alteraStatusObraVisualizacao,
		alteraStatusObraAtivo,
	} = props;

	const { obra, isFetching, actionFetching } = associaObrasEstado;
	const { criado_em, endereco, unidades_construtivas, nome: obraNome, status, usuarios, pessoas } = obra || {};
	const infosConstrutivas = (unidades_construtivas || [{}])[0];
	const enderecoInfos = endereco || {};

	const dropdownRef = useRef(null);
	const [alteraStatus, setAlteraStatus] = alteraStatusObra(dropdownRef, false);
	const statusObra = () => setAlteraStatus(!alteraStatus);

	const [confirmaAlteracao, setConfirmaAlteracao] = useState(false);
	const confirmaStatus = () => setConfirmaAlteracao(true);
	const closeConfirmStatus = () => setConfirmaAlteracao(false);

	const formatarData = (aaaammdd) => {
		if (!aaaammdd) return campoVazio;

		const data = aaaammdd.split('T')[0].split('-');
		const aaaa = data[0];
		const mm = data[1];
		const dd = data[2];
		return `${dd}/${mm}/${aaaa}`;
	};

	const formatNumero = (num) => {
		if (!num) return campoVazio;
		return num.toLocaleString();
	};

	const formatCep = (num) => {
		if (!num) return campoVazio;
		return `${num.substr(0, 5)}-${num.substr(-3)}`;
	};

	const voltar = () => {
		history.goBack();
	};

	// editar obra sendo visualizda
	const handleEditObra = () => {
		openEditObraSelecionada();
	};
	const alteraStatusObraCrud = () => {
		if (status === 30) {
			alteraStatusObraAtivo(obra);
			closeConfirmStatus(true);
			return;
		}
		if (status === 20) {
			alteraStatusObraVisualizacao(obra);
			return closeConfirmStatus(true);
		}
	};


	const openEmpresaDrawer = (mode, columnData) => {
		if (mode === 'edit') {
			associaEmpresaOpendEditDrawer(columnData);
		}

		if (mode === 'remove') {
			removeEmpresaAssociada(obra.id, columnData.pessoa.id);
		}
	};

	const openUsuarioDrawer = (mode, columnData) => {
		if (mode === 'edit') {
			associaUsuarioOpenEditDrawer(columnData);
		}

		if (mode === 'remove') {
			removeUsuarioAssociado(obra.id, columnData.usuario.id);
		}
	};


	useEffect(() => {
		const { obra_id } = match.params;
		getObra(obra_id);

		return () => {
			limpaEstadoAssociaObra();
		};
	}, []);

	const labelStatus = status === 10 ? 'Inativo' : status === 20 ? 'Ativo' : (status === 30 || obra.message === 'Obra alterada para visualização com sucesso!') ? 'Visualização' : '';

	useEffect(() => {
	}, [obra, status]);


	return (
		<LayoutWrapper {...props}>
			<>
				{isFetching ? <LoadingPage /> :
					(
						<>
							<EditObraDrawer />
							<AssociaEmpresaDrawer />
							<AssociaUsuarioDrawer />
							<Container>
								<Header>
									<div className="header-obra-name">
										<ArrowLeftOutlined style={{ fontSize: 20, color: '#8C8C8C' }} className="header-back-btn" onClick={voltar} />
										{obraNome || 'Nome da Obra'}
									</div>
									<div className="header-obra-status">
										Criado em {crudObraCriadoEm(criado_em)}
										<Popconfirm
											visible={confirmaAlteracao}
											title={
												<>
													<p styleName="label-confirm-status">Tem certeza que deseja mudar a obra para apenas visualização?</p>
													<strong styleName="label-muted-description">Isso poderá afetar as solicitações e ações dessa obra
													</strong>
												</>}
											placement="bottom"
											onCancel={() => closeConfirmStatus()}
											onConfirm={alteraStatusObraCrud}
											cancelText="Cancelar"
											okText="Sim"
										>
											<div className="header-status">
												<button className="button-status" onClick={statusObra}>
													<StatusIcon $status={status} className="status-icon" style={{ color: '#01B071' }} /> {labelStatus}
													<DownOutlined style={{ fontSize: 14, color: '#BFBFBF', marginLeft: '60px' }} />
												</button>
											</div>
										</Popconfirm>
									</div>
								</Header>

								{(status === 30 || status === 20) && (
									<button onClick={confirmaStatus} className={`altera-status ${alteraStatus && status !== 30 ? 'ativo' : alteraStatus && status === 30 ? 'ativo-visualizacao' : {}}`} ref={dropdownRef}>
										<Link to="#" onClick={confirmaStatus}>
											{status === 20 ? 'Visualização' : 'Ativo'}
										</Link>
									</button>
								)}
								<Content>
									<ObraInfos>
										<ObraInfos.BlockTitle>
											<span>
												Dados da obra
											</span>
											<Button shape="round" className="info-edit-btn" size="small" onClick={handleEditObra}>
												editar
											</Button>
										</ObraInfos.BlockTitle>
										<ObraInfos.Line style={{ marginBottom: 30 }}>
											<InfoWithLabel label="Fase atual da obra" text={(infosConstrutivas && infosConstrutivas.fase_atual) || campoVazio} />
											<InfoWithLabel label="Data de início" text={formatarData(infosConstrutivas && infosConstrutivas.criado_em)} />
											<InfoWithLabel label="Área total (m²)" text={formatNumero(infosConstrutivas && infosConstrutivas.metragem)} />
											<InfoWithLabel label="Método construtivo" text={(infosConstrutivas && infosConstrutivas.metodo_construtivo) || campoVazio} />
											<InfoWithLabel label="Tipo de projeto" text={(infosConstrutivas && infosConstrutivas.tipo) || campoVazio} />
											<InfoPhotos {...props} anexos={obra.anexos} />
										</ObraInfos.Line>
										<ObraInfos.Line className="endereço-line">
											<InfoWithLabel label="CEP" text={formatCep(enderecoInfos.cep)} />
											<InfoWithLabel label="Endereço" text={enderecoInfos.logradouro || campoVazio} />
											<InfoWithLabel label="N°" text={enderecoInfos.numero || campoVazio} />
											<InfoWithLabel label="Bairro" text={enderecoInfos.bairro || campoVazio} />
											<InfoWithLabel label="Cidade" text={enderecoInfos.cidade || campoVazio} />
											<InfoWithLabel label="UF" text={enderecoInfos.estado || campoVazio} />
										</ObraInfos.Line>
									</ObraInfos>

									<CollapseLine title="Empresas associadas" btnText="Associar nova empresa" btnOnClick={associaEmpresaOpenDrawer}>
										<StyledTable
											columns={empresaColumns('admin', openEmpresaDrawer)}
											rowKey="id"
											loading={isFetching || actionFetching}
											dataSource={pessoas || []}
											pagination={{ pageSize: maxPerPageEmpresa, hideOnSinglePage: true }}
											locale={tableLocale}
											size="small"
										/>
									</CollapseLine>

									<CollapseLine title="Usuários associadas" btnText="Associar novo usuário" btnOnClick={associaUsuarioOpenDrawer}>
										<StyledTable
											columns={usuarioColumns('admin', openUsuarioDrawer)}
											rowKey="id"
											loading={isFetching || actionFetching}
											dataSource={usuarios || []}
											pagination={{ pageSize: maxPerPageUsuario, hideOnSinglePage: true }}
											locale={tableLocale}
											size="small"
										/>
									</CollapseLine>
								</Content>
							</Container>
						</>
					)}
			</>
		</LayoutWrapper>
	);
}

AssociacoesObra.propTypes = {
	match: PropTypes.shape(),
	associaObra: PropTypes.shape({
		isFetching: PropTypes.bool,
		obra: PropTypes.shape(),
	}),
	removeEmpresaAssociada: PropTypes.func,
	removeUsuarioAssociado: PropTypes.func,
	openEditObraSelecionada: PropTypes.func,
	limpaEstadoAssociaObra: PropTypes.func,
	openObraEditDrawer: PropTypes.func,
	associaEmpresaOpenDrawer: PropTypes.func,
	associaEmpresaOpendEditDrawer: PropTypes.func,
	associaUsuarioOpenDrawer: PropTypes.func,
	associaUsuarioOpenEditDrawer: PropTypes.func,
	getObra: PropTypes.func,
	alteraStatusObraVisualizacao: PropTypes.func,
	alteraStatusObraAtivo: PropTypes.func,
};

AssociacoesObra.defaultProps = {
	associaObra: {},
	match: {},
	removeEmpresaAssociada: () => {},
	removeUsuarioAssociado: () => {},
	openEditObraSelecionada: () => {},
	limpaEstadoAssociaObra: () => {},
	openObraEditDrawer: () => {},
	associaEmpresaOpenDrawer: () => {},
	associaEmpresaOpendEditDrawer: () => {},
	associaUsuarioOpenDrawer: () => {},
	associaUsuarioOpenEditDrawer: () => {},
	getObra: () => {},
	alteraStatusObraVisualizacao: () => {},
	alteraStatusObraAtivo: () => {},
};

const WithStyles = CSSModules(AssociacoesObra, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));
