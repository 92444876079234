import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, detailedmapDispatchToProps } from '../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../store/withStore';

// Functions
import { getUserLocalstorageData } from '../../components/_functions/_getUserLocalstorageData';
import { setLocalstorageData } from '../../components/_functions/_getLocalstorageData';
import { attachHotJar } from '../../components/_functions/_attachHotJar';
import { clearDropdowns } from '../../components/_functions/_clearDropdowns';

class Auxlr extends Component {
	static defineBaseUrl() {
		// const origin = window.location.href;
		// // producao
		// const eh_portal = origin.indexOf('portal.conazweb.com.br') !== -1;
		// if (eh_portal) {
		// 	return 'https://api.conazweb.com.br';
		// }
		// // triagem
		// const eh_triagem = origin.indexOf('triagem.conazweb.com.br') !== -1;
		// if (eh_triagem) {
		// 	return 'https://api-triagem.conazweb.com.br';
		// }
		// // dev
		// return 'https://dev.conazweb.com.br';
		return {
			conaz: process.env.REACT_APP_BASEURL,
			exports: process.env.EXPORTS_BASEURL,
		};
	}

	constructor() {
		super();
		this.handleMouseUp = this.handleMouseUp.bind(this);
	}

	UNSAFE_componentWillMount() {
		const base_url = this.constructor.defineBaseUrl();
		setLocalstorageData('environment', {
			base_url: base_url.conaz,
			exports_baseurl: base_url.exports,
			environment: '',
		});
		this.checkAuth();
	}

	componentDidMount() {
		window.addEventListener('mouseup', this.handleMouseUp);
		// this.listenToUrlChanges();

		// TawkTo & HotJar
		const origin = window.location.href;
		const email = getUserLocalstorageData('email');
		if (
			!this.props.ui.tawkto &&
			(origin.indexOf('portal.conazweb.com.br') !== -1 ||
				origin.indexOf('demonstracao.conazweb.com.br') !== -1)
		) {
			// attachTawkTo();
			if (email !== '' && email.indexOf('conazsolucoes.com.br') === -1) {
				attachHotJar(
					window,
					document,
					'https://static.hotjar.com/c/hotjar-',
					'.js?sv=',
				);
			}
			this.props.updateUi('tawkto', true);
		}
	}

	handleMouseUp(e) {
		if (e.target.getAttribute('data-dropdown')) {
			const dropdown_class =
				e.target.parentNode.className === 'dropdown open'
					? 'dropdown'
					: 'dropdown open';
			clearDropdowns();
			e.target.parentNode.className = dropdown_class;
		} else if (e.target.getAttribute('data-dropdown-2')) {
			const dropdown_class =
				e.target.parentNode.parentNode.className === 'dropdown open'
					? 'dropdown'
					: 'dropdown open';
			clearDropdowns();
			e.target.parentNode.parentNode.className = dropdown_class;
		} else if (!e.target.getAttribute('data-dropchild')) {
			clearDropdowns();
		}

		if (
			this.props.ui.account_menu &&
			!e.target.getAttribute('data-account-menu')
		) {
			this.props.updateUi(['account_menu'], false);
		} else if (
			this.props.ui.obras_menu &&
			!e.target.getAttribute('data-obras-menu')
		) {
			this.props.updateUi(['obras_menu'], false);
		} else if (
			this.props.ui.actions_menu &&
			!e.target.getAttribute('data-actions-menu')
		) {
			this.props.updateUi(['actions_menu'], false);
		} else if (
			this.props.ui.descartar_pedido_menu &&
			!e.target.getAttribute('data-descartar-pedido-menu')
		) {
			this.props.updateUi(['descartar_pedido_menu'], false);
		} else if (
			this.props.ui.compra_actions_menu &&
			!e.target.getAttribute('data-compra-actions-menu')
		) {
			this.props.updateUi(['compra_actions_menu'], false);
		}
	}

	checkAuth() {
		const token = getUserLocalstorageData('token');
		const email = getUserLocalstorageData('email');
		const id = getUserLocalstorageData('id');
		const nome = getUserLocalstorageData('nome');
		const tem_express = getUserLocalstorageData('tem_express');

		// Não chama getUser se estiver logando como usuario
		const origin = window.location.href;
		const logando_como_usuario = origin.indexOf('/logar_como_usuario/') !== -1;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';

		if (token === '' || token === null || email === '' || email === null) {
			this.props.unsetAuth();
		} else {
			this.props.setAuth(token, { email, id, nome }, tem_express);
			if (!is_triagem && !logando_como_usuario) {
				this.props.getUser();
			}
		}
	}

	render() {
		return <>{this.props.children}</>;
	}
}

Auxlr.propTypes = {
	ui: PropTypes.object.isRequired,
	updateUi: PropTypes.func.isRequired,
	setAuth: PropTypes.func.isRequired,
	unsetAuth: PropTypes.func.isRequired,
	getUser: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => detailedmapDispatchToProps(dispatch, [
	'updateUi', 'setAuth', 'unsetAuth', 'getUser',
]);

export default withStore(connect(mapStateToProps, mapDispatchToProps)(Auxlr));
