import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { formatCurrency } from '../../../../components/_functions/_formatCurrency';

function ValorDoFornecedor({ compra }) {
	const rc = compra.rc || {};
	const rc_analise_id = compra.rc_analise_id || null;
	const rc_uuid = rc.uuid || null;
	const tem_rc = rc_analise_id !== null || rc_uuid !== null;

	// Não tem RC
	if (!tem_rc) return null;

	const {
		transporte,
		transporte_proprio,
		valor_transporte,
	} = rc;

	const valor_a_pagar = formatCurrency(valor_transporte, 2, 'R$');
	const opcoes_tp = {
		0: 'Próprio',
		1: 'Terceirizado',
		2: 'A Combinar',
	};
	const transporte_proprio_view = opcoes_tp[transporte_proprio] || 'A Combinar';
	let transporte_view;

	switch (transporte) {
	case 0:
		transporte_view = 'Frete incluso no preço (CIF)';
		break;
	case 1:
		if (transporte_proprio === 2) {
			transporte_view = transporte_proprio_view;
			break;
		}
		transporte_view = `Frete a pagar: ${valor_a_pagar} - ${transporte_proprio_view}`;
		break;
	case 2:
		transporte_view = 'Frete por conta do cliente';
		break;
	default:
		break;
	}

	return (
		<p styleName="labelTransporte">
			Valor preenchido pelo fornecedor: <span>{transporte_view}</span>
		</p>
	);
}

ValorDoFornecedor.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(ValorDoFornecedor, styles, { allowMultiple: true });
