import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import IntegracaoSearchResult from './IntegracaoSearchResult';
import Loading from '../../../../../../components/_parts/_loadings/Loading/Loading';

// Functions
import { _get } from '../../../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../../../components/_functions/_handleRequestErrors';

class IntegracaoSugestoes extends Component {
	state = {
		loading: 0,
		sugestoes: [],
	};

	componentDidMount() {
		this.getSugestoes();
	}

	getSugestoes() {
		this.setState({ loading: 1 }, () => {
			const { nome } = this.props.fornecedor_conaz;
			const url = `/conaz/v2/integracoes/fornecedores_integrados/associados?nome=*${nome}*`;
			// const url = `/conaz/v2/integracoes/fornecedores_associados/?nome=*${typed_encode}*`;

			_get(url).then(({ data }) => {
				const result = data.result || [];
				this.setState({ sugestoes: [...result], loading: 2 });
			}).catch(error => {
				this.setState({ loading: 3 });
				handleRequestErrors(error);
			});
		});
	}

	render() {
		const { loading, sugestoes } = this.state;
		const { saveFornecedorSienge, handleFornecedorAssociado } = this.props;

		if (loading === 0 || loading === 1) {
			return (
				<>
					<h4 styleName="sugestoes-title">Sugestões</h4>
					<div styleName="no-result-wrapper"><Loading /></div>
				</>
			);
		}

		return (
			<>
				<h4 styleName="sugestoes-title">Sugestões</h4>
				{sugestoes.length > 0 ? sugestoes.map(fornecedor => (
					<IntegracaoSearchResult
						key={fornecedor.id}
						fornecedor={fornecedor}
						saveFornecedorSienge={saveFornecedorSienge}
						handleFornecedorAssociado={handleFornecedorAssociado} />
				)) : 'Nenhum fornecedor encontrado'}
			</>
		);
	}
}

IntegracaoSugestoes.propTypes = {
	fornecedor_conaz: PropTypes.object.isRequired,
	saveFornecedorSienge: PropTypes.func.isRequired,
	handleFornecedorAssociado: PropTypes.func.isRequired,
};

export default CSSModules(IntegracaoSugestoes, styles, { allowMultiple: true });
