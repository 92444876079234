import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { _get, _post, _delete } from '../../../_functions/_requests';
import { formatNumbers } from '../../../_functions/_formatNumbers';

// Component
import FornecedorReview from '../../../../pages/CompraV3/subcomponents/RightModalFornecedor/FornecedorReview';
import VendedorButton from './VendedorButton';
import ButtonRound from '../../_buttons/ButtonRound/ButtonRound';
// import Loading from '../../_loadings/Loading/Loading';

class LeftModalAssociacao extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search_input: '',
			fornecedores: [],
			saving: 0,
			fornecedor: props.fornecedor_conaz || {},
			timer: 0,
			loading: false,
			sugestao: true,
			fornecedor_associado: !!props.fornecedorSiengeSelected.fornecedores_associados[0],
			displayfornecedor: false,
			vendedor_selecionado: { },
		};
	}

	UNSAFE_componentWillMount() {
		const { fornecedorSiengeSelected } = this.props;
		const url = `/conaz/v2/fornecedores/?fields=usuarios_simplificados,endereco,documento,site&cadastrado_por=${
			this.props.user.cliente_id
		}&nome=*${fornecedorSiengeSelected.nome}*`;
		_get(url).then(res => {
			this.setState({
				loading: false,
				fornecedores: [...res.data],
			});
		}).catch(err => console.log(err.message)); // eslint-disable-line no-console
	}

	closeModal = () => {
		this.props.updateModals('associacao', false);
	};

	handleDisplayFornecedor = fornecedor => this.setState({
		fornecedor,
		displayfornecedor: true,
		vendedor_selecionado: { id: fornecedor.usuarios[0].usuario.id },
	});

	handleCheck = vendedor => this.setState({ vendedor_selecionado: vendedor });

	handleAssociacao = () => {
		const { fornecedor, vendedor_selecionado } = this.state;
		const { fornecedorSiengeSelected, user, handleAssociacao } = this.props;
		const params = {
			swi: 1,
			cliente_id: user.cliente_id,
			fornecedor_id: fornecedor.id,
			id_no_swi_fornecedor_integrado: fornecedorSiengeSelected.id_no_swi,
			usuario_fornecedor_id: vendedor_selecionado.id,
		};

		try {
			this.setState({ saving: 1 }, () => {
				_post(`/conaz/v2/fornecedores/${fornecedor.id}/usuarios/${vendedor_selecionado.id}`, {
					tipo_associacao: 4,
				}).then(async () => {
					const { data } = await _post('/conaz/v2/integracoes/fornecedores_associados', params);
					this.setState({ saving: 2 }, () => {
						handleAssociacao(data);
					});
				});
			});
		} catch (err) {
			this.setState({ saving: 3 });
		}
	}

	handleDesassociacao = async () => {
		const { handleAssociacao, fornecedorSiengeSelected: { fornecedores_associados } } = this.props;
		try {
			await _delete(`/conaz/v2/integracoes/fornecedor_associado/${fornecedores_associados[0].id}`);
			this.setState({ displayfornecedor: false, fornecedor_associado: false }, () => handleAssociacao(false));
		} catch (err) {
			console.error(err.message); // eslint-disable-line no-console
		}
	}

	handleStatusMsg = status => {
		if (status === 'nao_configurado') {
			return 'Não Configurado';
		}
		if (status === 'pre_configurado') {
			return 'Pré Configurado';
		}
		return 'Configurado';
	};

	handleStatusStyle = status => {
		if (status === 'nao_configurado') {
			return 'no_configurado';
		}
		if (status === 'pre_configurado') {
			return 'pre_configurado';
		}
		return 'configurado';
	};

	handleChange = ({ target: { name, value } }) => {
		if (this.state.timer) clearTimeout(this.state.timer);
		this.setState({
			[name]: value,
			fornecedores: [],
			loading: false,
		}, () => {
			if (value) {
				this.setState({
					loading: true,
					timer: setTimeout(async () => {
						const { search_input } = this.state;
						if (search_input) {
							try {
								const url = `/conaz/v2/fornecedores/?fields=usuarios_simplificados,endereco,documento,site&cadastrado_por=${
									this.props.user.cliente_id
								}&nome=*${search_input}*`;
								const res = await _get(url);
								this.setState({
									loading: false,
									sugestao: false,
									fornecedores: [...res.data],
								});
							} catch (err) {
								console.error(err.message); // eslint-disable-line no-console
							}
						}
					}, 1200),
				});
			}
		});
	}

	showTooltip = id => {
		window.clearTimeout(this.tooltip_timeout);
		this.tooltip_timeout = window.setTimeout(() => {
			if (document.getElementById(id)) document.getElementById(id).className = 'tooltip left in';
		}, 600);
	};

	hideTooltip = id => {
		window.clearTimeout(this.tooltip_timeout);
		document.getElementById(id).className = 'tooltip left';
	};

	setStatus = fornecedorAssociated => {
		const fornecedor_associado =
			((fornecedorAssociated || {}).fornecedores_associados || [])[0] || {};

		const fornecedor_associado_id = fornecedor_associado.id || null;
		const aprovado = fornecedorAssociated.aprovado || false;
		const status_list = {
			nao_configurado: fornecedor_associado_id === null,
			pre_configurado: fornecedor_associado_id !== null && !aprovado,
			configurado: fornecedor_associado_id !== null && aprovado,
		};

		const status = Object.keys(status_list)
			.map(c => c)
			.filter(c => status_list[c])
			.join(' ');

		return status || 'nao_configurado';
	};

	render() {
		const {
			fornecedorAssociated,
			fornecedorSiengeSelected,
		} = this.props;

		const {
			search_input,
			fornecedores,
			loading,
			displayfornecedor,
			fornecedor,
			vendedor_selecionado,
			fornecedor: { usuarios: vendedores },
			fornecedor_associado,
			saving,
		} = this.state;
		const status = this.setStatus(fornecedorAssociated);

		const lista_fornecedores = fornecedores.length > 0
			? fornecedores.map(f => (
				<button styleName="fornecedor-lista" key={f.id} onClick={() => this.handleDisplayFornecedor(f)}>
					{f.nome}
					<p>Localizado em: {f.endereco.cidade}</p>
				</button>
			))
			: (<span>Nenhum fornecedor encontrado</span>);

		if (!displayfornecedor && !fornecedor_associado) {
			return (
				<div styleName="modal-content">
					{status === 'nao_configurado' && (
						<div styleName="content">
							<div>
								<h3 className="h3-header">Buscar fornecedores da conaz</h3>
								<div styleName="inputs">
									{!loading ? (
										<i
											styleName="search-icon"
											className="fa fa-search"
											aria-hidden="true" />
									) : (
										<i
											styleName="search-icon"
											className="fa fa-spin fa-spinner"
											aria-hidden="true" />
									)}
									<input
										className="form-control input-lg"
										styleName="input"
										name="search_input"
										type="text"
										value={search_input}
										onChange={this.handleChange}
										placeholder="Buscar fornecedor" />
								</div>
							</div>
						</div>
					)}
					<div styleName="content">
						{this.state.sugestao && (<h3>Fornecedores Sugeridos</h3>)}
						{lista_fornecedores}
					</div>
				</div>
			);
		}

		if (fornecedor_associado) {
			const { nome, email, telefones } = fornecedor.vendedor;

			const lista_telefones = telefones.map(telefone => (
				<p key={telefone.numero}>{formatNumbers(telefone.numero, 'telefone')}</p>
			));

			return (
				<div styleName="modal-content">
					<div styleName="content" style={{ background: '#f7f9fa' }}>
						<div styleName="title">Fornecedor integrado</div>
						<div styleName="code">
							<span>Código Sienge:</span> <span>{fornecedorSiengeSelected.id_no_swi}</span>
						</div>
						<div styleName="name">{fornecedorSiengeSelected.nome}</div>
					</div>
					<div styleName="content">
						{/* <FornecedorReview fornecedor={fornecedorSiengeSelected} /> */}
						<div styleName="title">
							Fornecedor associado
							<ButtonRound
								size="small"
								icon="fa fa-exchange"
								tooltip_text="Remover fornecedor associado"
								left="-70%"
								onClick={this.handleDesassociacao}
								first_of_type />
						</div>
						<FornecedorReview fornecedor={fornecedor} />
						<br />
						<div styleName="vendedor-block">
							<div styleName="title">Vendedor Selecionado</div>
							<p>Nome: {nome}</p>
							<p>Email: {email}</p>
							<br />
							Telefones: <br /><br />
							{lista_telefones}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div styleName="modal-content">
				<div styleName="content">
					<FornecedorReview fornecedor={fornecedor} />
					<hr />
					<p>Selecione o vendedor de sua preferência</p>
					{vendedores.filter(({ usuario: { nome } }) => nome !== null && nome !== '')
						.map(v => (
							<VendedorButton
								key={v.usuario.id}
								vendedor={v.usuario}
								onClickFunction={this.handleCheck}
								estah_selecionado={vendedor_selecionado.id === v.usuario.id} />
						))}
					<button
						type="button"
						onClick={this.handleAssociacao}
						className="btn btn-secondary-conaz round-border">
						{saving === 0 || saving === 2 ? 'Salvar Associação' : 'Associando...'}
					</button>
				</div>
			</div>
		);
	}
}

LeftModalAssociacao.propTypes = {
	updateModals: PropTypes.func.isRequired,
	handleAssociacao: PropTypes.func.isRequired,
	fornecedorSiengeSelected: PropTypes.object.isRequired,
	fornecedorAssociated: PropTypes.object.isRequired,
	fornecedor_conaz: PropTypes.object,
	user: PropTypes.shape({
		cliente_id: PropTypes.number.isRequired,
	}).isRequired,
};

LeftModalAssociacao.defaultProps = {
	fornecedor_conaz: {},
};

export default CSSModules(LeftModalAssociacao, styles, { allowMultiple: true });
