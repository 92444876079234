export const calcValorItem = (item = {}) => {
	const { cliente_associou } = item;
	const excluido = item.excluido || false;
	// quantidade
	const quantidade = !cliente_associou
		? item.pre_quantidade || 0
		: item.quantidade || 0;
	// preco_unitario
	const preco_unitario = item.preco_unitario || 0;
	// sub_total
	const sub_total = quantidade * preco_unitario;
	// desconto
	const desconto = item.desconto || 0;
	const valor_desconto = (sub_total / 100) * desconto;
	// total
	const total = (sub_total - valor_desconto) || 0;

	if (excluido) {
		return {
			total: 0,
			sub_total: 0,
		};
	}

	return {
		total,
		sub_total,
	};
};
