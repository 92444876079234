import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import AlertWrapper from './AlertWrapper';
// import RightModalFornecedor from '../RightModalFornecedor';

class Alert2 extends Component {
	state = {
		visible: true,
	};

	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		const { delay } = this.props;
		this.alert_timeout = window.setTimeout(() => {
			this.setState({ visible: false });
		}, delay + 100);
	}

	componentWillUnmount() {
		window.clearTimeout(this.alert_timeout);
		this._isMounted = false;
	}

	closeAlert = externalFunction => {
		const { updateComprasRaw } = this.props;
		setTimeout(() => {
			if (!this._isMounted) return;
			if (externalFunction) {
				externalFunction();
			}
			const alert = {
				color: '',
				message: '',
			};
			this.setState({ visible: false });
			updateComprasRaw({ alert });
		}, 100);
	}

	render() {
		const { visible } = this.state;
		const { delay, color, mensagem, externalFunction } = this.props;
		const modal_content_class = `modal-content ${color}`;

		if (visible) {
			return (
				<ModalPortal>
					<AlertWrapper delay={delay} closeAlert={() => this.closeAlert(externalFunction)} externalFunction={externalFunction}>
						<div className="modal-content" styleName={modal_content_class}>
							<div className="modal-body" styleName="modal-body">
								{mensagem}
							</div>
						</div>
					</AlertWrapper>
				</ModalPortal>
			);
		}

		return null;
	}
}

Alert2.propTypes = {
	delay: PropTypes.number,
	color: PropTypes.string,
	mensagem: PropTypes.string,
	externalFunction: PropTypes.func,
	updateComprasRaw: PropTypes.func,
};

Alert2.defaultProps = {
	delay: 8000,
	color: 'green',
	mensagem: '',
	externalFunction: () => null,
	updateComprasRaw: () => null,
};

export default CSSModules(Alert2, styles, { allowMultiple: true });
