import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

const CompraRowStatus = ({ compra }) => {
	const { registro } = compra;
	const status = defineStatusCompra(compra);

	const views_status = {
		cancelada: 'Cancelada',
		em_aprovacao: 'Em aprovação',
		aguardando_aprovacao_sienge: 'Aguardando aprovação no Sienge',
		em_edicao: 'Em edição',
		rascunho: 'Rascunho',
		em_analise: 'Aguardando análise Conaz',
		em_refinamento: 'Em refinamento',
		aguardando_confirmacao: 'Aguardando confirmação do fornecedor',
		confirmada: 'Aguardando entrega',
		recusada_fornecedor: 'Recusada pelo fornecedor',
		entrega_confirmada: 'Entrega confirmada',
		finalizada: 'Finalizada',
	};
	const view_status = (registro && status === 'finalizada')
		? 'Registrada'
		: views_status[status] || 'Finalizada';
	const status_class = `status ${status}`;

	return (
		<span styleName={status_class}>
			<b>{view_status}</b>
		</span>
	);
};

CompraRowStatus.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowStatus, styles, { allowMultiple: true });
