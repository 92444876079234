import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Actions
import { updateComprasRaw, getInitialCompras, getCompras } from '../../store/compras/actions';
import { updateUi } from '../../store/ui/actions';

// Components
import Loading from '../_parts/_loadings/Loading/Loading';
import LoadingButton from '../_parts/_loadings/LoadingButton/LoadingButton';

// Functions
import { setClasses } from '../_functions/_setClasses';
import { _post } from '../_functions/_requests';
import { handleRequestErrors } from '../_functions/_handleRequestErrors';
import { sendSlack } from '../_functions/_sendSlack';
import { getLocalstorageData } from '../_functions/_getLocalstorageData';

const RatingLastStep = ({
	step,
	step_number,
	compra,
	criterios,
	avaliacao_url,
	comentario_url,
	closeModal,
	location,
}) => {
	const dispatch = useDispatch();
	const [comment, setComment] = useState('');
	const [saving_avaliacao, setSavingAvaliacao] = useState(1);
	const [saving_comment, setSavingComment] = useState(0);
	const [success, setSuccess] = useState(false);

	const obra_selected =
		useSelector(store => (
			store.user.obra_selected), shallowEqual) || 0;
	const obras_by_id =
		useSelector(store => (
			store.user.obras_by_id), shallowEqual) || {};

	function handleComment({ target }) {
		setComment(target.value);
	}

	function buildRespostas(tipo) {
		return Object
			.keys(criterios)
			.filter(c => c !== 'nota_geral')
			.map(c => ({
				[tipo]: criterios[c].tipo_no_backend,
				resposta: criterios[c].value,
			}));
	}

	// TEMP
	function enviaAlertSlack() {
		const is_prod = (window.location.href.indexOf('portal.conazweb.com.br') !== -1);
		if (is_prod) {
			const obra = obras_by_id[obra_selected] || {};
			const construtora = (obra.cliente || {}).apelido_interno || '';
			const user_nome = getLocalstorageData('user', 'nome') || '';
			const user_email = getLocalstorageData('user', 'email') || '';
			const usuario = `${user_nome} (${user_email})`;
			const fornecedor = compra.fornecedor.nome || '';
			const vendedor = (compra.usuario_fornecedor || {}).nome || '-';
			const stars_array = [...Array(criterios.nota_geral.value).keys()];
			const stars = stars_array.map(() => ':star:').join('');
			const respostas = Object
				.keys(criterios)
				.filter(c => c !== 'nota_geral')
				.map(c => {
					const icon = criterios[c].value
						? ':heavy_check_mark:'
						: ':heavy_multiplication_x:';
					return `\n> ${icon} ${criterios[c].question.replace('{br} ', '')}`;
				})
				.join('');
			const comentario = comment !== ''
				? `\n> Comentário: ${comment}`
				: '';
			const message = `> Construtora: *${construtora}*\n> Usuário: *${usuario}*\n> Compra: *<https://triagem.conazweb.com.br/compra/${compra.id}|#${compra.id}>*\n> Fornecedor: *${fornecedor}*\n> Vendedor: *${vendedor}*\n> *\n> ${stars}${respostas}${comentario}\n***\n***\n***`;
			sendSlack('#avaliacoes', 'Nova avaliação de Fornecedor', message, ':star2:');
		}
	}

	function atualizaCompraNaListagem() {
		dispatch(getInitialCompras(obra_selected, 'aguardando_entrega_avaliacao'));
		dispatch(getCompras(obra_selected, 'finalizadas', 1));
		dispatch(updateUi(['tabs', 'compras'], 'finalizadas'));
		window.scroll(0, 0);
		closeModal();
	}

	function atualizaCompra() {
		const respostas = buildRespostas('tipo_questao');
		const avaliacao_fornecedor = {
			id: 1,
			detalhe: {
				comentario: '',
				estrelas: criterios.nota_geral.value,
				respostas,
			},
		};
		const nova_compra = {
			...compra,
			avaliacao_fornecedor,
			status: 60,
			status_atual: 'FINALIZADA',
			status_front: 'finalizada',
		};
		dispatch(updateComprasRaw({ nova_compra }));
		closeModal();
	}

	function atualizaCompraVendedor() {
		const avaliacao_vendedor = {
			detalhe: {
				estrelas: criterios.nota_geral.value,
			},
		};
		const nova_compra = {
			...compra,
			avaliacao_vendedor,
		};
		dispatch(updateComprasRaw({ nova_compra }));
		closeModal();
	}

	function finalizaModal() {
		enviaAlertSlack();
		if (!success) {
			closeModal();
			return;
		}
		if (location === 'compra') {
			atualizaCompra();
			return;
		}
		if (location === 'vendedor') {
			atualizaCompraVendedor();
			return;
		}
		atualizaCompraNaListagem();
	}

	function salvaAvaliacao() {
		const respostas = buildRespostas('tipo');
		const params = {
			compra_id: compra.id,
			estrelas: criterios.nota_geral.value,
			respostas,
		};
		_post(avaliacao_url, params).then(() => {
			setSavingAvaliacao(2);
			setSuccess(true);
		}).catch(e => {
			const errors = ((
				e.response || {})
				.data || {})
				.errors || [];
			const error_description = (errors[0] || {}).description || '';

			const sem_alcada = error_description
				.indexOf('Usuário não tem permissão para avaliar fornecedor na compra') !== -1;
			if (sem_alcada) {
				setSavingAvaliacao(4);
				return;
			}

			const avaliacao_outro_usuario = error_description
				.indexOf('Compra já foi avaliada por outro usuário') !== -1;
			if (avaliacao_outro_usuario) {
				setSavingAvaliacao(5);
				return;
			}

			handleRequestErrors(e);
			setSavingAvaliacao(3);
		});
	}

	useEffect(() => {
		if (step === step_number) {
			salvaAvaliacao();
		}
	}, [step, step_number]);

	function sendComment() {
		setSavingComment(1);
		const params = {
			compra_id: compra.id,
			comentario: comment,
		};
		_post(comentario_url, params).then(() => {
			setSavingComment(2);
			setSuccess(true);
			setTimeout(() => finalizaModal(), 3000);
		}).catch(error => {
			handleRequestErrors(error);
			setSavingComment(3);
		});
	}

	const step_classes = {
		actual: step === step_number,
		previous: step > step_number,
		next: step < step_number,
	};
	const step_class = setClasses('criterio higher', step_classes);
	const send_buttom_class = comment !== ''
		? 'send-comment-button active'
		: 'send-comment-button';
	const send_buttom_func = comment !== ''
		? sendComment
		: null;

	const closeButton = (
		<button
			type="button"
			className="close"
			styleName="close-button"
			data-dismiss="modal"
			aria-label="Close"
			onClick={finalizaModal}
		>
			<span aria-hidden="true">&times;</span>
		</button>
	);

	if (saving_avaliacao === 1) {
		return (
			<div styleName={step_class}>
				<div styleName="loading">
					<Loading />
				</div>
			</div>
		);
	}

	if (saving_avaliacao === 3) {
		return (
			<>
				{closeButton}
				<div styleName={step_class}>
					<div styleName="title">Desculpe, aconteceu algo errado!</div>
					<div>
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							onClick={salvaAvaliacao}
						>
							Enviar novamente
						</button>
					</div>
				</div>
			</>
		);
	}

	if (saving_avaliacao === 4) {
		return (
			<>
				{closeButton}
				<div styleName={step_class}>
					<div styleName="title">Você não possui permissão para avaliar</div>
					<p>Entre em contato com os usuários que possuem permissão para avaliar.</p>
				</div>
			</>
		);
	}

	if (saving_avaliacao === 5) {
		return (
			<>
				{closeButton}
				<div styleName={step_class}>
					<div styleName="title">Esta compra já foi avaliada por outro usuário e apenas ele pode editá-la</div>
				</div>
			</>
		);
	}

	if (saving_comment === 2) {
		return (
			<>
				{closeButton}
				<div styleName={step_class}>
					<div styleName="title success" style={{ marginTop: '114px' }}>
						Seu comentário foi enviado para análise <i className="fa fa-check-circle" />
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			{closeButton}
			<div styleName={step_class}>
				<div styleName="title success">
					Sua avaliação foi enviada <i className="fa fa-check-circle" />
				</div>
				<div styleName="label-comment">Deixe seu comentário (opcional)</div>
				<textarea
					className="form-control"
					styleName="text-area"
					rows="4"
					value={comment}
					onChange={handleComment}
				/>
				{/* <div styleName="comment-bottom">
					Sua avaliação não ficará visível para outros construtores, apenas para este fornecedor.
				</div> */}
				<div styleName="last-buttons-wrapper">
					<button
						type="button"
						className="btn btn-clear-conaz round-border"
						styleName="no-comment-button"
						onClick={finalizaModal}
					>
						Não tenho comentário
					</button>
					{saving_comment === 1 ? (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border loading"
							styleName={send_buttom_class}
						>
							<LoadingButton />Enviar comentário
						</button>
					) : (
						<button
							type="button"
							className="btn btn-secondary-conaz round-border"
							styleName={send_buttom_class}
							onClick={send_buttom_func}
						>
							Enviar comentário
						</button>
					)}
					{saving_comment === 3 && (
						<div styleName="error-sending-comment">Desculpe, aconteceu algo errado!</div>
					)}
				</div>
				{/* <div styleName="avaliacao-footer">
					Você poderá editar essa avaliação a qualquer momento
				</div> */}
			</div>
		</>
	);
};

RatingLastStep.propTypes = {
	step: PropTypes.number.isRequired,
	step_number: PropTypes.number.isRequired,
	compra: PropTypes.object.isRequired,
	criterios: PropTypes.object.isRequired,
	avaliacao_url: PropTypes.string.isRequired,
	comentario_url: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(RatingLastStep, styles, { allowMultiple: true });
