/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CriarCompraButton from './CriarCompraButton';

const RCRowOutroFornecedor = ({ cotacao, updateModals }) => (
	<div className="col-xs-12">
		<div styleName="rc-status-wrapper outro-fornecedor">
			<i className="fa fa-credit-card" />
			<p> Caso tenha comprado com um fornecedor que não fez a cotação pela plataforma, crie sua compra</p>
			<CriarCompraButton
				oc={{}}
				pedido={cotacao}
				style_class="button-comprar registrar"
				updateModals={updateModals}
				row_outro_fornecedor
			/>
		</div>
	</div>
);

RCRowOutroFornecedor.propTypes = {
	cotacao: PropTypes.object.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default CSSModules(RCRowOutroFornecedor, styles, { allowMultiple: true });
