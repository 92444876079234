export const UPDATE_COTACAO = 'UPDATE_COTACAO';
export const UPDATE_COTACAO_MULTI = 'UPDATE_COTACAO_MULTI';
export const UPDATE_ITEM = 'UPDATE_ITEM';
export const UPDATE_ITEM_CARACTERISTICA = 'UPDATE_ITEM_CARACTERISTICA';
export const UPDATE_ITEM_PROPRIEDADES = 'UPDATE_ITEM_PROPRIEDADES';
export const UPDATE_ITEM_VALOR_PREENCHIDO = 'UPDATE_ITEM_VALOR_PREENCHIDO';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const UNDO_REMOVE_ITEM = 'UNDO_REMOVE_ITEM';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SAVE_ITEMS = 'SAVE_ITEMS';
export const SAVE_RETURNED_IDS = 'SAVE_RETURNED_IDS';
