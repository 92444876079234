import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

class UnidadeOption extends Component {
	constructor() {
		super();
		this.handleOption = this.handleOption.bind(this);
	}

	handleOption(e) {
		e.preventDefault();
		const { option, handleEdition } = this.props;
		handleEdition(option.unidade);
	}

	render() {
		const { option, option_view } = this.props;
		return (
			<li data-dropchild>
				<Link to="#" onClick={this.handleOption} styleName={option.unidade === option_view && 'selected'}>{option.unidade}</Link>
			</li>
		);
	}
}

UnidadeOption.propTypes = {
	option: PropTypes.object.isRequired,
	handleEdition: PropTypes.func.isRequired,
	option_view: PropTypes.string,
};

UnidadeOption.defaultProps = {
	option_view: '',
};

export default CSSModules(UnidadeOption, styles, { allowMultiple: true });
