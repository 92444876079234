const user = {
	fetching: 0,
	request_state: 0,
	error: 0,
	logged: false,
	email: '',
	token: '',
	nome: '',
	id: 0,
	documento: '',
	request_state_obras: 0,
	obras_by_id: {},
	obras_all_ids: [],
	obra_selected: 0,
	tem_express: false,
	instrucoes_lidas: [],
	console_error: '',
	cliente_id: 0,
	sienge: {
		login_configurado: false,
		obras_configuradas: false,
		itens_configurados: false,
		credenciais: {},
		loaded: false,
	},
	usuario_no_swi: '',
	sienge_liberado: false,
	aprovacao_sienge: false,
	confirmado_em: '',
	hichat_loaded: false,
};

export default user;

/*

	INSTRUÇÕES
		[
			1: ...
			2: ...
			3: ...
			4: ...
			5: bem-vindo strutura v2
		]

*/
