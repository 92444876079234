import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';


const StarsAvaliacaoResumido = ({ media, tamanho_estrela }) => {
	if (typeof media === 'undefined' || typeof tamanho_estrela === 'undefined') {
		return <></>;
	}

	const roundedMedia = Math.round(media * 10) / 10;

	const star = { style: { fontSize: `${tamanho_estrela}px`, color: '#FADB14', width: `${tamanho_estrela}px`, marginRight: '3px' } };

	return <>
		<i className="fa fa-star" aria-hidden="true" style={star.style} />
		{roundedMedia.toFixed(1)}
	</>;
};

StarsAvaliacaoResumido.propTypes = {
	media: PropTypes.number.isRequired,
	tamanho_estrela: PropTypes.number.isRequired,
};


export default CSSModules(StarsAvaliacaoResumido, { allowMultiple: true });
