import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CSSModules from 'react-css-modules';

// components
import { Button } from 'antd';
import DrawerUser from 'components/DrawerUser';

// Redux HOC
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

import LayoutWrapper from '../../components/LayoutWrapper';

// styles
import styles from './style.module.scss';
import { StyledTable } from './styled';
import tableLocale from './locale';

import columns from './columns';

function TabelaUsuarios({ ...props }) {
	const [usuarioAdministrador, setUsuarioAdministrador] = useState(localStorage.getItem('usuario_admin') || null);
	const [loadingSearch] = useState(false); // loading do input caso precise...
	const [filterTxt, setFilterTxt] = useState('');
	const maxUserPerPage = 10;
	//
	const {
		openUserDrawer,
		closeUserDrawer,
		getUsersByClient,
		getUser,
		editUser,
		createUser,
		disableUser,
		ativaUser,
		usuarios,
	} = props;

	const openDrawer = (mode = 'new', user = null) => {
		openUserDrawer(mode, user);
	};

	const closeDrawer = () => {
		closeUserDrawer();
	};

	const handleDeleteUser = (user) => {
		if (user.desativado) {
			ativaUser(user);
		}
		if (!user.desativado) {
			disableUser(user);
		}
	};

	const onSearch = (e) => {
		const { value } = e.target;
		setTimeout(() => {
			setFilterTxt(value);
		}, 400);
	};

	useEffect(() => {
		document.title = 'Cadastro - Usuários';
		getUsersByClient();
		getUser();
	}, [getUsersByClient, getUser]);

	const statusUser = usuarios.users.desativado;

	const filteredUsers = filterTxt ? usuarios.users.filter((u) => u.nome.toLowerCase().includes(filterTxt.toLowerCase()))
		: usuarios.users;

	useEffect(() => {
		if ((!localStorage.getItem('usuario_admin') || localStorage.getItem('usuario_admin') === false) && localStorage.getItem('usuario_admin') !== true) {
			setUsuarioAdministrador(false);
		}
	}, [usuarioAdministrador]);

	return (
		<LayoutWrapper {...props}>
			<>
				<DrawerUser
					userData={usuarios.userToDrawer}
					mode={usuarios.drawerMode}
					visible={usuarios.showDrawer}
					loading={usuarios.actionFetching}
					onClose={closeDrawer}
					onSubmit={usuarios.drawerMode === 'edit' ? editUser : createUser}
				/>
				<div styleName="header">
					<div styleName="header-title">
						Usuários
					</div>
					<Button
						type="primary"
						shape="round"
						size="large"
						styleName="add-user-btn"
						style={{ display: (usuarioAdministrador === false ? 'none' : '') }}
						onClick={() => openUserDrawer()}>
						Novo usuário
					</Button>
				</div>

				<div styleName="search-user-area">
					<div styleName="search-input-wrapper">
						<input
							onChange={onSearch}
							styleName="search-input"
							placeholder="Buscar por nome ou palavra chave"
						/>
						<i
							styleName="search-input-icon"
							className={!loadingSearch ? 'fa fa-search' : 'fa fa-spin fa-spinner'}
							aria-hidden="true"
						/>
					</div>
				</div>

				<div styleName="table-space">
					<StyledTable
						columns={columns('admin', openDrawer, handleDeleteUser, statusUser, usuarioAdministrador)}
						dataSource={filteredUsers}
						pagination={{ pageSize: maxUserPerPage, hideOnSinglePage: true }}
						locale={tableLocale}
						size="small"
						loading={usuarios.isFetching || usuarios.actionFetching}
					/>
				</div>
			</>
		</LayoutWrapper>
	);
}

TabelaUsuarios.propTypes = {
	props: PropTypes.any,
	//
	getUsersByClient: PropTypes.func,
	getUser: PropTypes.func,
	openUserDrawer: PropTypes.func,
	closeUserDrawer: PropTypes.func,
	editUser: PropTypes.func,
	createUser: PropTypes.func,
	disableUser: PropTypes.func,
	ativaUser: PropTypes.func,
	//
	usuarios: PropTypes.shape({
		isFetching: PropTypes.bool,
		users: PropTypes.arrayOf(PropTypes.shape()),
		userToDrawer: PropTypes.shape({ }),
		showDrawer: PropTypes.bool,
		drawerMode: PropTypes.string,
		actionFetching: PropTypes.bool,
	}),
};

TabelaUsuarios.defaultProps = {
	props: {},
	openUserDrawer: () => {},
	closeUserDrawer: () => {},
	getUsersByClient: () => {},
	getUser: () => {},
	editUser: () => {},
	createUser: () => {},
	disableUser: () => {},
	ativaUser: () => {},
	usuarios: {},
};

const WithStyles = CSSModules(TabelaUsuarios, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));
