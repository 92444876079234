/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_modals/ModalPortal/ModalWrapperPortal';
import ModalEvaluationResume from '../../ModalEvaluationResume';
import ModalRating from '../../ModalRating';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';

// Avaliacoes
import { avaliacao_fornecedor } from '../../ModalRating/avaliacoes';

const CompraRowAvaliacao = ({ compra, location }) => {
	const [modal_avaliacao, setModalAvaliacao] = useState(false);
	const [modal_edition, setModalEdition] = useState(false);
	const [buttonAvaliacaoPc, setButtonAvaliacaoPc] = useState(window.innerWidth > 600);
	const [buttonAvaliacaoMobile, setButtonAvaliacaoMobile] = useState(window.innerWidth < 600);

	function handleEvaluationResume() {
		setModalAvaliacao(!modal_avaliacao);
	}

	function handleEvaluation() {
		setModalEdition(!modal_edition);
	}

	function preencheAvaliacao() {
		const nome_fornecedor = (compra.fornecedor || {}).nome || '';
		const avaliacao = avaliacao_fornecedor(nome_fornecedor);

		return avaliacao;
	}

	const _resizePc = () => {
		setButtonAvaliacaoPc(window.innerWidth > 600);
	};

	const _resizeMobile = () => {
		setButtonAvaliacaoMobile(window.innerWidth < 600);
	};

	useEffect(() => {
		window.addEventListener('resize', _resizePc);
		window.addEventListener('resize', _resizeMobile);
	}, [buttonAvaliacaoPc, buttonAvaliacaoMobile]);

	const status = defineStatusCompra(compra);
	const nota_fornecedor = location === 'compra'
		? ((compra.avaliacao_fornecedor || {}).detalhe || {}).estrelas || 0
		: compra.avaliacao_fornecedor_estrelas || 0;

	const show_avaliacao = (
		status === 'finalizada'
		&& nota_fornecedor !== 0
	);

	const wrapper_class = location === 'compra'
		? 'div-avaliacao relative'
		: 'div-avaliacao';
	const nota_class = location === 'compra'
		? 'avaliacao-nota relative'
		: 'avaliacao-nota';

	if (show_avaliacao) {
		const avaliacao = preencheAvaliacao();

		return (
			<>
				{modal_edition && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={handleEvaluation}>
							<ModalRating
								closeModal={handleEvaluation}
								avaliacao={avaliacao}
								compra={compra}
								location={location}
							/>
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				{modal_avaliacao && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={handleEvaluationResume}>
							<ModalEvaluationResume
								compra={compra}
								handleEvaluation={handleEvaluation}
								closeModal={handleEvaluationResume} />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<div
					styleName={wrapper_class}
					style={buttonAvaliacaoPc && document.title !== 'Compras - Conaz' ? { width: '100%', display: 'block', marginTop: '1rem' } : {}}>
					<div
						styleName={nota_class}
						style={buttonAvaliacaoMobile ? { display: 'none' } : {}}
					>
						<a href="#" onClick={handleEvaluationResume}>
							<i className="fa fa-star" style={{ color: '#FADB14' }} /> {nota_fornecedor}.0 <span>  sua avaliação</span>
						</a>
					</div>
					<button
						type="button"
						className="btn btn-primary-conaz round-border"
						onClick={handleEvaluationResume}
						style={buttonAvaliacaoMobile ? { marginLeft: '0px', bottom: '4.2rem' } : document.title !== 'Compras - Conaz' ? { marginLeft: '1rem' } : {}}
					>
						Ver avaliação
					</button>
				</div>
			</>
		);
	}

	return null;
};

CompraRowAvaliacao.propTypes = {
	compra: PropTypes.object.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowAvaliacao, styles, { allowMultiple: true });
