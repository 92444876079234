import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../../store/reduxDispatchs';

// Redux HOC
import withStore from '../../../store/withStore';

// Components
import LayoutWrapper from '../../../components/LayoutWrapper';
import SiengeFornecedores from './SiengeFornecedores';
import HeaderSearchFornecedores from './HeaderSearchFornecedores';
import LeftModalWrapper from '../../../components/_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalFornecedorAssociacao from '../../../components/_parts/_modals/LeftModalFornecedorAssociacao/LeftModalFornecedorAssociacao';

// Functions
import { _get, _post } from '../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../components/_functions/_handleRequestErrors';
import { debounce } from '../../../components/_functions/_debounce';

class SiengeFornecedoresContainer extends Component {
	handleDebounce = debounce(() => {
		const { search } = this.state;
		if (search.length > 0) {
			this.search(search).then(response => {
				const fornecedores = response.data.result || [];
				this.setState({ request_state: 2, fornecedores });
			}).catch(error => {
				this.setState({ request_state: 2, itens: [] });
				if (error.message !== 'canceled_request') {
					handleRequestErrors(error);
				}
			});
		}
	}, 800);

	constructor(props) {
		super(props);
		this.state = {
			fornecedoresPage: {
				activePage: 1,
				pages: 0,
				per_page: 10,
				totalFornecedores: 0,
			},
			search: '',
			search_detalhe: '', // detalhe vem da url
			flagAssociated: true,
			flagNotAssociated: true,
			fornecedorSiengeSelected: {},
			fornecedorAssociated: {},
			request_state: 0,
			saving: 0,
			page: props.match.params.id,
			key_item_selecionado: -1,
			fornecedores: [],
			error: false,
			error_message: '',
			fornecedor_conaz: {},
		};
		this.handleDebounce = this.handleDebounce.bind(this);
		this.handleflagNotAssociated = this.handleflagNotAssociated.bind(this);
		this.handleflagAssociated = this.handleflagAssociated.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleSelectedFornecedor = this.handleSelectedFornecedor.bind(this);
		this.handleAssociacao = this.handleAssociacao.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleAtualizarFornecedores = this.handleAtualizarFornecedores.bind(this);
		this.updateFornecedor = this.updateFornecedor.bind(this);
		this.autoSave = this.autoSave.bind(this);
		this.updateAprovacao = this.updateAprovacao.bind(this);
		this.removeLimitError = this.removeLimitError.bind(this);
	}

	componentDidMount() {
		document.title = 'Integração - Conaz';
		this.checkLoad(this.props.user);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.checkLoad(nextProps.user);
	}

	checkLoad(user) {
		const { cliente_id, sienge_liberado } = user;
		const { request_state, fornecedoresPage } = this.state;
		const { history } = this.props;

		if (cliente_id !== 0 && !sienge_liberado) {
			history.replace('/cotacoes');
			return;
		}

		if (cliente_id !== 0 && request_state === 0) {
			this.carregarFornecedores(fornecedoresPage.activePage);
		}
	}

	openFornecedorModalOnSearch(fornecedores, search) {
		const { search_detalhe } = this.state;
		const fornecedor = fornecedores.find(f => {
			if (search_detalhe !== '') {
				return f.id_no_swi === search && (f.detalhe || {}).id_no_swi === search_detalhe;
			}
			return f.id_no_swi === search && (f.detalhe || {}).id === undefined;
		});
		if (fornecedor !== undefined) this.handleSelectedFornecedor(fornecedor);
	}

	updateAprovacao(aprovado) {
		const { fornecedorAssociated } = this.state;
		const new_fornecedor = {
			...fornecedorAssociated,
			associado_aprovado: aprovado,
		};
		this.atualizarFornecedorNaLista(new_fornecedor.key, new_fornecedor);
		this.autoSave(new_fornecedor);
	}

	checkSearch(opt = 0) {
		const { search } = this.props.location;
		const id_search = search.indexOf('?id=') !== -1;
		if (id_search) {
			const full_search = search
				.replace('?id=', '')
				.replace(/%20/g, ' ');
			const id_to_search = full_search
				.split('&detalhe=')[0]
				.replace(/\D/g, '');
			const detalhe_to_search = full_search
				.split('&detalhe=')[1] || '';
			this.setState({ search: id_to_search, search_detalhe: detalhe_to_search });
			const e = {
				target: {
					value: id_to_search,
				},
			};
			if (opt === 0) return this.handleSearch(e);
			return id_to_search;
		}
		return false;
	}

	carregarFornecedores(pageNumber, request_state = 1, check_url_params = true) {
		this.setState({ request_state });
		const url = `/conaz/v2/integracoes/fornecedores_integrados/associados?page=${pageNumber}`;
		_get(url, {}, []).then(result => {
			/**
			 * Verifica se os fornecedores estão vazios
			 * seria o primeiro carregamento
			 */
			const fornecedores = result.data.result || [];
			if (fornecedores.length === 0 && request_state === 1) {
				this.handleAtualizarFornecedores();
				return;
			}

			/**
			 * Verifica se existe busca a ser feita
			 */
			const { search } = this.props.location;
			const id_search = search.indexOf('?id=') !== -1;
			if (id_search && check_url_params) {
				this.checkSearch();
				return;
			}

			/**
			 * Fornecedores ok
			 */

			this.setState({
				request_state: 2,
				fornecedores,
				fornecedoresPage: {
					activePage: pageNumber,
					pages: result.data.pages,
					per_page: result.data.per_page,
					totalFornecedores: result.data.total,
				},
			});
		}).catch(() => this.setState({ request_state: 2, fornecedores: [] }));
	}

	handleAssociacao(fornecedores_associados) {
		const { fornecedorSiengeSelected, fornecedores: fornecedores_integrados, fornecedorAssociated } = this.state;
		const fornecedores = fornecedores_associados ? fornecedores_integrados.map(fornecedor => {
			if (fornecedor.id === fornecedorSiengeSelected.id) {
				return {
					...fornecedor,
					fornecedores_associados: [fornecedores_associados],
				};
			}
			return fornecedor;
		}) : fornecedores_integrados.map(fornecedor => {
			if (fornecedor.id === fornecedorSiengeSelected.id) {
				return {
					...fornecedor,
					fornecedores_associados: [],
				};
			}
			return fornecedor;
		});

		fornecedorAssociated.fornecedores_associados = [];

		this.setState({ fornecedores, fornecedorAssociated }, () => {
			if (fornecedores_associados) this.props.updateModals('associacao', false);
		});
	}

	handleAtualizarFornecedores(force = false) {
		this.setState({ error: false, request_state: 10 });
		const { user } = this.props;
		const { cliente_id } = user;
		const url = '/conaz/v2/integracoes/fornecedores_integrados/atualizar_async';
		const params = {
			swi: 1,
			cliente_id,
			force,
		};
		_post(url, params, 0).then(((response) => {
			const ultima_atualizacao_fornecedores = response.data.requisitado_em;
			this.carregarFornecedores(1, 10);
			localStorage.setItem('ultima_atualizacao_fornecedores', ultima_atualizacao_fornecedores);
			const data = localStorage.getItem('ultima_atualizacao_fornecedores');
			return data;
		})).catch(error => {
			const message_conaz = (((error || {}).response || {}).data || {}).message || '';
			const error_message = message_conaz !== ''
				? message_conaz
				: '';

			// Erro de limite de requests
			const requests_error = error_message
				.indexOf('LimitReachedException: Limite de requests') !== -1;
			if (requests_error) {
				this.setState({
					error_message,
					request_state: 20,
					fornecedores: [],
				});
				return;
			}

			this.setState({
				error: true,
				request_state: 0,
				fornecedores: [],
			});
		});
	}

	removeLimitError() {
		this.setState({
			error_message: '',
			request_state: 0,
		});
	}

	handlePageChange({ selected }) {
		this.carregarFornecedores(selected + 1, 1, false);
	}

	// eslint-disable-next-line class-methods-use-this
	search(typed) {
		const typed_encode = encodeURIComponent(typed).replace(/%0A/g, '');
		if (parseInt(typed_encode, 10)) {
			const url = `/conaz/v2/integracoes/fornecedores_integrados/associados?id_no_swi=${typed_encode}`;
			// const url = `/conaz/v2/integracoes/fornecedores_associados/?id_no_swi=${typed_encode}`;
			return _get(url);
		}

		let url = `/conaz/v2/integracoes/fornecedores_integrados/associados?nome=*${typed_encode}*`;
		const get_nome = _get(url);
		url = `/conaz/v2/integracoes/fornecedores_integrados/associados?nome_fantasia=*${typed_encode}*`;
		const get_fantasia = _get(url);

		return Promise.all([get_nome, get_fantasia]).then(promises => {
			const fornecedores_sienge = [];
			const nomes = {};
			fornecedores_sienge.push(...promises[0].data.result.map(nome => {
				nomes[nome.id] = nome;
				return nome;
			}));

			promises[1].data.result.forEach(nome_fantasia => {
				if (!nomes[nome_fantasia.id]) fornecedores_sienge.push(nome_fantasia);
			});

			return { data: { result: fornecedores_sienge } };
		});
	}

	handleSearch(e) {
		if (this.cancel !== undefined) {
			this.cancel('canceled_request');
		}

		const { fornecedoresPage } = this.state;
		const search = e.target.value.toString();
		if (search.trim().length > 0) {
			this.setState({ search, request_state: 1 }, this.handleDebounce);
		} else {
			this.setState({ search: '', fornecedores: [], request_state: 1 });
			this.carregarFornecedores(fornecedoresPage.activePage, 1, false);
		}
	}

	async handleSelectedFornecedor(fornecedor) {
		let fornecedor_conaz = {};
		const { fornecedores_associados } = fornecedor;
		if ((fornecedores_associados[0] || {}).fornecedor) {
			fornecedor_conaz = {
				...fornecedores_associados[0].fornecedor,
				vendedor: fornecedores_associados[0].usuario_fornecedor,
			};
		} else if ((fornecedores_associados[0] || {}).usuario_fornecedor) {
			fornecedor_conaz = {
				...fornecedores_associados[0].usuario_fornecedor.fornecedores[0].fornecedor,
				vendedor: fornecedores_associados[0].usuario_fornecedor,
			};
		}
		this.setState({ fornecedorSiengeSelected: fornecedor, fornecedor_conaz, fornecedorAssociated: fornecedor });
		this.props.updateModals('associacao', true);
	}

	handleflagNotAssociated() {
		const { flagNotAssociated } = this.state;
		this.setState({ flagNotAssociated: !flagNotAssociated });
	}

	handleflagAssociated() {
		const { flagAssociated } = this.state;
		this.setState({ flagAssociated: !flagAssociated });
	}

	updateFornecedor(fornecedor_id, field, value) {
		const { fornecedorAssociated } = this.state;
		const _fornecedorAssociated = {
			...fornecedorAssociated,
			[field]: value,
		};

		this.setState({ fornecedorAssociated: _fornecedorAssociated });
		this.atualizarFornecedorNaLista(fornecedor_id, _fornecedorAssociated);
		this.autoSave(_fornecedorAssociated);
	}

	saveFornecedor(key, new_fornecedor) {
		this.setState({ saving: 1 });
		const { cliente_id } = this.props.user;
		this.atualizarFornecedorNaLista(key, new_fornecedor);
		const param = {
			cliente_id,
			swi: 1,
			fornecedor_id: new_fornecedor.id,
			id_no_swi_fornecedor_integrado: new_fornecedor,
		};
		_post('/conaz/v2/integracoes/fornecedores_associados', param).then(res => {
			this.setState({ saving: 2 });
			setTimeout(() => this.setState({ saving: 0 }), 5000);
			const { fornecedorSiengeSelected } = this.state;
			if (fornecedorSiengeSelected.id === res.data.fornecedor_integrado.id) {
				const data = {
					...new_fornecedor,
					idAssociacao: res.data.id,
					fornecedores_associados: [
						{ id: res.data.id },
					],
				};
				this.setState({ key_fornecedor_selecionado: key, fornecedorAssociated: data });
				this.atualizarFornecedorNaLista(key, data);
			}
		}).catch(() => {
			this.setState({ saving: 0 });
		});
	}

	atualizarFornecedorNaLista(fornecedor_id, fornecedor) {
		const fornecedor_associado_caracteristicas = {
			fornecedor_preenchido: {
				...fornecedor,
				caracteristicas: fornecedor || [],
			},
		};

		const new_fornecedor_lista = {
			...fornecedor,
			fornecedor_associado_caracteristicas,
		};

		const _fornecedores = this.state.fornecedores;
		_fornecedores[fornecedor_id] = new_fornecedor_lista;
		this.setState({ fornecedores: _fornecedores });

		const { FornecedorSiengeSelected } = this.state;
		if (FornecedorSiengeSelected.id === fornecedor.id) {
			this.setState({ FornecedorAssociated: fornecedor });
		}
	}

	autoSave(fornecedor) {
		window.clearTimeout(this.save_timeout);
		this.save_timeout = window.setTimeout(() => this.saveFornecedor(this.state.key_fornecedor_selecionado, fornecedor), 3000);
	}

	render() {
		const { ui, updateModals } = this.props;

		return (
			<LayoutWrapper {...this.props}>
				<>
					<LeftModalWrapper
						visible={ui.modals.associacao}
						updateModals={updateModals}
						modal_to_close="associacao">
						<LeftModalFornecedorAssociacao
							updateFornecedorCaracteristica={this.updateFornecedorCaracteristica}
							handleAssociacao={this.handleAssociacao}
							updateFornecedor={this.updateFornecedor}
							saveFornecedor={this.saveFornecedor}
							desassociarFornecedor={this.desassociarFornecedor}
							fornecedorSiengeSelected={this.state.fornecedorSiengeSelected}
							fornecedorAssociated={this.state.fornecedorAssociated}
							updateAprovacao={this.updateAprovacao}
							fornecedores={this.state.fornecedores}
							autoSave={this.autoSave}
							{...this.state}
							{...this.props} />
					</LeftModalWrapper>
					<HeaderSearchFornecedores
						handleflagAssociated={this.handleflagAssociated}
						handleflagNotAssociated={this.handleflagNotAssociated}
						handleSearch={this.handleSearch}
						handleAtualizarFornecedores={this.handleAtualizarFornecedores}
						{...this.state}
						{...this.props} />
					<SiengeFornecedores
						handleSelectedFornecedor={this.handleSelectedFornecedor}
						handlePageChange={this.handlePageChange}
						handleAtualizarFornecedores={this.handleAtualizarFornecedores}
						removeLimitError={this.removeLimitError}
						{...this.state}
						{...this.props} />
				</>
			</LayoutWrapper>
		);
	}
}

SiengeFornecedoresContainer.propTypes = {
	// =========== store
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	cotacao: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(SiengeFornecedoresContainer));
