import INITIAL_STATE from './store';
import * as Types from './types';

export default function (state = INITIAL_STATE, action) {
	switch (action.type) {
	case Types.EMPRESAS_GET_LIST_REQUEST:
		return {
			...state,
			isFetching: true,
		};
	case Types.EMPRESAS_GET_LIST_SUCCESS:
		return {
			...state,
			isFetching: false,
			error: false,
			empresas: action.payload.data,
		};
	case Types.EMPRESAS_GET_LIST_ERROR:
		return {
			...state,
			isFetching: false,
			error: action.payload.erro_motivo,
		};
	case Types.EMPRESAS_CREATE_REQUEST:
	case Types.EMPRESAS_BLOCK_REQUEST:
	case Types.EMPRESAS_DELETE_REQUEST:
	case Types.EMPRESAS_EDIT_REQUEST:
		return {
			...state,
			actionFetching: true,
			action: action.payload.type,
		};
	case Types.EMPRESAS_CREATE_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			empresas: [...state.empresas, action.payload.data],
			showDrawer: false,
			dataToDrawer: null,
			drawerMode: 'new',
			adress: null,
		};
	case Types.EMPRESAS_EDIT_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			empresas: state.empresas.map((emp) => {
				if (emp.id === action.payload.data.id) {
					return ({
						...emp,
						...action.payload.data,
						endereco: { ...emp.endereco, ...action.payload.data.endereco },
					});
				}
				return 	emp;
			}),
			showDrawer: false,
			dataToDrawer: null,
			drawerMode: 'new',
			adress: null,
		};
	case Types.EMPRESAS_DELETE_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			empresas: state.empresas.filter((emp) => emp.id !== action.payload.data.id),
		};
	case Types.EMPRESAS_BLOCK_SUCCESS:
		return {
			...state,
			actionFetching: false,
			error: false,
			empresas: state.empresas.map((emp) => {
				if (emp.id === action.payload.data.id) return ({ ...emp, ...action.payload.data });
				return 	emp;
			}),
		};
	case Types.EMPRESAS_ACTION_ERROR:
		return {
			...state,
			actionFetching: false,
			error: action.payload.error_motivo,
		};

	case Types.EMPRESAS_OPEN_DRAWER:
		return {
			...state,
			showDrawer: true,
			dataToDrawer: action.payload.data,
			drawerMode: action.payload.mode,
			adress: null,
		};
	case Types.EMPRESAS_CLOSE_DRAWER:
		return {
			...state,
			showDrawer: false,
			dataToDrawer: null,
			drawerMode: 'new',
			adress: null,
		};
	default:
		return state;
	}
}
