import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Hocs
import asyncComponent from '../../../../hoc/asyncComponent/asyncComponent';

// Components
import Alert2 from '../../../../components/_parts/Alerts/Alert2';
import ModalPortal from '../../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';

// Functions
import { editEnviadoEmFormat } from '../../../../components/_functions/_editDateFormat';
import { _post } from '../../../../components/_functions/_requests';
import { handleRequestErrors } from '../../../../components/_functions/_handleRequestErrors';

// Async components
const ModalRefinamento = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno'
	? asyncComponent(() => import('../../../../components/_parts/_modals/ModalRefinamento/ModalRefinamento'))
	: () => null;

class AnaliseActions extends Component {
	state = {
		refinamento_modal: false,
		concluindo: false,
	};

	openRefinamento = () => {
		const { refinamento_modal } = this.state;
		this.setState({ refinamento_modal: !refinamento_modal });
	}

	concluirCompra = () => {
		const { compra } = this.props;
		this.setState({ concluindo: 1 });
		const url = `/conaz/v2/compras/${compra.id}/enviar`;
		_post(url, {}).then(() => {
			this.setState({ concluindo: 2 });
		}).catch(error => {
			this.setState({ concluindo: 3 });
			alert('Erro ao enviar compra!'); // eslint-disable-line
			handleRequestErrors(error);
		});
	}

	render() {
		const { refinamento_modal, concluindo } = this.state;
		const { compra } = this.props;
		const {
			// is_editable,
			id,
			criado_em,
			usuario_construtor,
			titulo,
		} = compra;
		const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
		const quem_criou = (usuario_construtor || {}).nome || '';
		const descricao = (criado_em || '') !== '' ? (
			<span>
				Enviada em {editEnviadoEmFormat(criado_em)[0]} -{' '}
				{editEnviadoEmFormat(criado_em)[1]}
			</span>
		) : (
			<em styleName="em">Sem registro</em>
		);

		if (!is_triagem) {
			return null;
		}

		return (
			<>
				{concluindo === 2 && (
					<Alert2
						color="green"
						mensagem="Compra enviada com sucesso!" />
				)}
				{refinamento_modal && (
					<ModalPortal>
						<ModalWrapperPortal closeModal={this.openRefinamento}>
							<ModalRefinamento
								type="compra"
								codigo={id}
								titulo={titulo}
								updateModals={this.openRefinamento} />
						</ModalWrapperPortal>
					</ModalPortal>
				)}
				<div styleName="alerts-messages-wrapper">
					<div styleName="am-text">
						<b>Analisar a compra de {quem_criou}</b>
						<br />
						{descricao}
					</div>
					<div styleName="am-buttons">
						<button
							type="button"
							className="btn btn-clear-conaz round-border"
							styleName="alert-button"
							onClick={this.openRefinamento}>
							Solicitar refinamento
						</button>
						{concluindo === 1 ? (
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="alert-button loading">
								Concluindo...
							</button>
						) : (
							<button
								type="button"
								className="btn btn-clear-conaz round-border"
								styleName="alert-button"
								onClick={this.concluirCompra}>
								Concluir
							</button>
						)}
					</div>
				</div>
			</>
		);
	}
}

AnaliseActions.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(AnaliseActions, styles, { allowMultiple: true });
