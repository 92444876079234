/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

// components
import { Image } from 'antd';
import { ObraPhotos } from './styled';

const InfoPhotos = ({ anexos }) => (
	<ObraPhotos>
		<ObraPhotos.Label>
			Fotos
		</ObraPhotos.Label>
		{anexos.length > 0 ? (
			<>
				<ObraPhotos.Imgs style={{ marginTop: '5px' }}>
					{anexos.map(item => item && (
						<Image
							src={item.url}
							className="infos-photo"
							width={60}
							height={60}
							style={{ marginRight: 2 }}
							alt="fotos"
						/>
					))}
				</ObraPhotos.Imgs>
			</>
		) : (
			'-'
		)}
	</ObraPhotos>
);

InfoPhotos.propTypes = {
	anexos: PropTypes.arrayOf(),
};

InfoPhotos.defaultProps = {
	anexos: [],
};

export default InfoPhotos;
