import update from 'immutability-helper';
import cotacoes from './store';
import {
	UPDATE_COTACOES,
	UPDATE_COTACOES_RAW,
	UPDATE_COTACOES_LIST,
	COTACOES_INITIAL_REQUESTED,
	COTACOES_INITIAL_RECEIVED,
	COTACOES_RECEIVED,
	COTACOES_REQUESTED,
	COTACOES_REQUEST_FAILED,
} from './types';

// Functions
import { isGeneralError, handleGeneralErrors } from '../utility';

const reducer = (state = cotacoes, action) => {
	switch (action.type) {
	case UPDATE_COTACOES: {
		return update(state, { [action.field]: { $set: action.value } });
	}

	case UPDATE_COTACOES_RAW: {
		const all_fields = Object.keys(action.fields_n_values)
			.reduce((result, current) => ({
				...result,
				[current]: { $set: action.fields_n_values[current] },
			}), {});
		return update(state, all_fields);
	}

	case UPDATE_COTACOES_LIST: {
		return update(state, {
			by_id: { $set: action.by_id },
			all_ids: { $set: action.all_ids },
			ativas: { $set: action.ativas },
			finalizadas: { $set: action.finalizadas },
			canceladas: { $set: action.canceladas },
			rascunhos: { $set: action.rascunhos },
		});
	}

	case COTACOES_INITIAL_REQUESTED: {
		return {
			...state,
			[`RS_${action.tab}`]: 1,
			fetching: 10,
		};
	}

	case COTACOES_INITIAL_RECEIVED: {
		const { data, tab } = action.payload;
		const cotacoes_lista = ['em_andamento', 'finalizados', 'cancelados', 'em_rascunho']
			.filter(t => t !== tab);

		const re = {
			...state,
			[`RS_${tab}`]: 2,
			fetching: 20,
			...data,
		};

		cotacoes_lista.forEach(t => {
			re[`RS_${t}`] = 0;
		});

		return re;
	}

	case COTACOES_REQUESTED: {
		const { tab } = action;

		return {
			...state,
			current_tab: tab,
			[`RS_${tab}`]: 1,
		};
	}

	case COTACOES_RECEIVED: {
		const { tab, data } = action.payload;

		return {
			...state,
			current_tab: tab,
			[tab]: { ...data },
			[`RS_${tab}`]: 2,
		};
	}

	case COTACOES_REQUEST_FAILED: {
		const error = action.error || {};
		const general_error = isGeneralError(error);
		if (general_error) {
			handleGeneralErrors(error);
			return { ...state };
		}

		return {
			...state,
			fetching: 30,
		};
	}

	default:
		return state;
	}
};

export default reducer;
