/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import TabDetalhes from './TabDetalhes';
import TabItens from './TabItens';
import TabQc from './TabQc';
import StatusBar from './StatusBar';
import AcoesMenu from './AcoesMenu/AcoesMenu';
import ModalWrapper from '../../components/_parts/_modals/ModalWrapper/ModalWrapper';
import ModalPdf from '../../components/_parts/_modals/ModalPdf/ModalPdf';
import ModalAnexos from '../../components/_parts/_modals/ModalAnexos/ModalAnexos';
import ModalNotificaSobrePedido from '../../components/_parts/_modals/ModalNotificaDecisor/ModalNotificaSobrePedido';
import LeftModalWrapper from '../../components/_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalFornecedorPerfil from '../../components/_parts/_modals/LeftModalFornecedor/LeftModalFornecedorPerfil';
import Alerts from '../../components/_parts/Alerts/Alerts';

const ViewCotacao = ({
	// =========== state
	rc_card_min_height,
	qc_versao,
	qc_anexo,
	pdf_foi_aberto_alert,
	loading_fornecedores,
	perfil_fornecedor,
	anexos_fornecedor,
	loading_compras,
	rcs_abertos,
	qc_visto,
	// =========== props
	ui,
	user,
	cotacao,
	match,
	history,
	// =========== prop funcs
	updateModals,
	updateUi,
	updateCompras,
	updateCotacao,
	reportErrors,
	// =========== funcs
	reloadPageChangeStatus,
	changeTab,
	openPdf,
	alertVerPdf,
	openAnexosFornecedor,
	infoFornecedor,
	openCloseRCs,
}) => {
	const { obra_selected, obras_by_id } = user;
	const { tabs, modals, alerts } = ui;
	const { ocs } = cotacao;

	const {
		titulo,
		id,
		rcs,
		compras,
		codigo,
		enviado_em,
		prazo_de_resposta,
		data_de_entrega_desejada,
		obra,
		observacoes,
		observacoes_conaz,
		observacoes_internas,
		fornecedores,
		itens_de_pedido,
		status,
		cancelado,
		ultima_atualizacao,
		condicao_de_preferencia,
	} = cotacao.cotacao_view;

	const [codigoSwi, setCodigoSwi] = useState([]);

	const usuario = cotacao.cotacao_view.usuario || {};
	const aprovado_por = cotacao.cotacao_view.aprovado_por || {};
	const aprovacao_pedida_por = cotacao.cotacao_view.aprovacao_pedida_por || {};
	const anexos = (cotacao.cotacao_view.anexos || []).filter(a => (
		a.nome.indexOf(`QC ${id} - ${titulo}`) === -1 && a.nome.indexOf(`QC ${codigo} - ${titulo}`) === -1
	));
	const obra_nome = (obra || {}).nome || '';

	const pdf_foi_aberto = qc_anexo.visto !== null ? qc_anexo.visto : false;
	const qc_foi_visto = qc_versao === 2 ? qc_visto : pdf_foi_aberto;

	const usuario_pode_visualizar_qc = Array.isArray(obras_by_id[obra_selected].papeis) || localStorage.getItem('obra') ?
		obras_by_id[obra_selected].papeis.includes(12) : false;

	// cliente_id
	const eh_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
	const cliente_id = !eh_triagem
		? obras_by_id[obra_selected].cliente.id
		: 10;

	useEffect(() => {
		if (cotacao.cotacao_view.itens_de_pedido && cotacao.cotacao_view.itens_de_pedido[0]) {
			const listSwiCotacao = [];
			cotacao.cotacao_view.itens_de_pedido.map(itemSwi => {
				if (listSwiCotacao.length === 0) {
					listSwiCotacao.push(itemSwi.pedido_no_swi);
				} else if (!listSwiCotacao.includes(itemSwi.pedido_no_swi)) {
					listSwiCotacao.push(itemSwi.pedido_no_swi);
				}
			});
			setCodigoSwi([...listSwiCotacao]);
		}
	}, [cotacao.cotacao_view]);

	return (
		<>
			<ModalWrapper visible={modals.pdf_qc} updateModals={updateModals} pdf>
				<ModalPdf
					qc_anexo={qc_anexo}
					openCloseModals={updateModals} />
			</ModalWrapper>
			<ModalWrapper visible={modals.anexos} updateModals={updateModals}>
				<ModalAnexos
					title="Arquivos dos fornecedores"
					anexos={anexos_fornecedor} />
			</ModalWrapper>
			<ModalWrapper visible={modals.notifica_decisor} updateModals={updateModals}>
				<ModalNotificaSobrePedido
					codigo_pedido={codigo}
					obra_id={obra_selected}
					obra_nome={obras_by_id[obra_selected].nome}
					reportErrors={reportErrors}
					route_path={match.path}
					updateModals={updateModals} />
			</ModalWrapper>

			<LeftModalWrapper
				visible={modals.fornecedor}
				updateModals={updateModals}>
				<LeftModalFornecedorPerfil
					perfil_fornecedor={perfil_fornecedor}
					route_path={match.path}
					reportErrors={reportErrors}
					cliente_id={cliente_id}
				/>
			</LeftModalWrapper>

			<Alerts
				alerts={alerts}
				updateUi={updateUi} />

			{/* Header */}
			<div className="header clear" styleName="header" id="viewcotacao-acoes-menu">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-5">
							<div className="col-xs-12 col-sm-7" style={{ width: 'auto' }}>
								<div style={{ display: 'flex' }}>
									<AcoesMenu
										pedido_status={status}
										cancelado={cancelado}
										match={match}
										history={history}
										updateUi={updateUi}
										updateModals={updateModals}
										updateCotacao={updateCotacao}
										reloadPageChangeStatus={reloadPageChangeStatus}
										cotacao={cotacao.cotacao_view} />
									<h3 className="h3-header small-margin" styleName="h3-header-cotacao">{titulo}</h3>
								</div>
								<p styleName="p-header-cotacao" style={{ color: '#a8aeb9', fontSize: '18px' }}>#{codigo}</p>
							</div>
							<div
								styleName="codigos-solicitacao-label">
								{codigoSwi && codigoSwi.map((compraInfo) => (
									<div
										styleName="codigos-solicitacao"
										style={window.innerWidth === 768 ? { width: '150%', gridTemplateColumns: 'repeat' } :
											window.innerWidth === 1024 ? { width: '250%', gridTemplateColumns: 'repeat(3, 1fr)' } :
												window.innerWidth <= 768 && codigoSwi.length <= 1 ? { width: '100%', gridTemplateColumns: 'repeat(1, 1fr)' } : {}
										}
									>
										{compraInfo !== '' && compraInfo !== null ? (
											<p styleName="codigo-solicitacao-sienge">
												<strong styleName="codigo-sienge">
													<span>#{compraInfo}</span>
												</strong>
											</p>
										) : ''}
									</div>
								))}
							</div>
						</div>
						<div className="col-xs-12 col-sm-7">
							<StatusBar
								status={status}
								cancelado={cancelado} />
						</div>
					</div>
				</div>
			</div>

			{!cancelado && (
				<TabQc
					ocs={ocs}
					rcs={rcs}
					compras={compras}
					pedido_status={status}
					titulo_pedido={titulo}
					codigo={id}
					cotacao={cotacao.cotacao_view}
					rc_card_min_height={rc_card_min_height}
					updateCompras={updateCompras}
					qc_versao={qc_versao}
					ultimo_envio_qc={ultima_atualizacao}
					updateModals={updateModals}
					qc_foi_visto={qc_foi_visto}
					pdf_foi_aberto_alert={pdf_foi_aberto_alert}
					openPdf={openPdf}
					alertVerPdf={alertVerPdf}
					infoFornecedor={infoFornecedor}
					openAnexosFornecedor={openAnexosFornecedor}
					loading_compras={loading_compras}
					history={history}
					rcs_abertos={rcs_abertos}
					openCloseRCs={openCloseRCs}
					visualiza_qc={usuario_pode_visualizar_qc} />
			)}

			<div className="container" styleName="opcoes-tabs" id="opcoes-tabs">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="content-wrapper">
							<div styleName="tabs-wrapper">
								<button
									type="button"
									id="detalhes"
									styleName={(tabs.cotacao === 'detalhes' || tabs.cotacao === '') ? 'tabs-item active' : 'tabs-item'}
									onClick={changeTab}>
									Informações básicas
								</button>
								<button
									type="button"
									id="itens"
									styleName={tabs.cotacao === 'itens' ? 'tabs-item active' : 'tabs-item'}
									onClick={changeTab}>
									Itens
								</button>
							</div>

							{(tabs.cotacao === 'detalhes' || tabs.cotacao === '') && (
								<TabDetalhes
									enviado_em={enviado_em}
									prazo_de_resposta={prazo_de_resposta}
									data_entrega_desejada={data_de_entrega_desejada}
									obra_nome={obra_nome}
									observacoes={observacoes}
									observacoes_conaz={observacoes_conaz}
									observacoes_internas={observacoes_internas}
									anexos={anexos}
									fornecedores={fornecedores}
									loading_fornecedores={loading_fornecedores}
									infoFornecedor={infoFornecedor}
									rcs={rcs}
									usuario={usuario}
									aprovado_por={aprovado_por}
									aprovacao_pedida_por={aprovacao_pedida_por}
									condicao_de_preferencia={condicao_de_preferencia} />
							)}
							{tabs.cotacao === 'itens' && (
								<TabItens
									itens_de_pedido={itens_de_pedido}
									id={id}
									updateCotacao={updateCotacao} />
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

ViewCotacao.propTypes = {
	// =========== local
	rc_card_min_height: PropTypes.number.isRequired,
	qc_versao: PropTypes.number.isRequired,
	qc_anexo: PropTypes.object.isRequired,
	pdf_foi_aberto_alert: PropTypes.bool.isRequired,
	loading_fornecedores: PropTypes.bool.isRequired,
	perfil_fornecedor: PropTypes.object.isRequired,
	anexos_fornecedor: PropTypes.array.isRequired,
	loading_compras: PropTypes.number.isRequired,
	rcs_abertos: PropTypes.array.isRequired,
	qc_visto: PropTypes.bool.isRequired,
	// =========== local funcs
	reloadPageChangeStatus: PropTypes.func.isRequired,
	changeTab: PropTypes.func.isRequired,
	openPdf: PropTypes.func.isRequired,
	alertVerPdf: PropTypes.func.isRequired,
	openAnexosFornecedor: PropTypes.func.isRequired,
	infoFornecedor: PropTypes.func.isRequired,
	openCloseRCs: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateCompras: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
	reportErrors: PropTypes.func.isRequired,
	// =========== router
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export default CSSModules(ViewCotacao, styles, { allowMultiple: true });
