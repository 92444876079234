// Functions
import { getUserLocalstorageData } from '../../components/_functions/_getUserLocalstorageData';

export const buildObras = (data) => {
	const obras = Array.isArray(data)
		? data || []
		: [];
	const obra_localstorage = getUserLocalstorageData('obra');
	let obra_selecionada = 0;
	const all_ids = [];
	const by_id = obras.reduce((result, current) => {
		all_ids.push(current.obra.id);
		if (obra_localstorage === current.obra.id) {
			obra_selecionada = current.obra.id;
		}
		return {
			...result,
			[current.obra.id]: {
				...current.obra,
				papeis: current.papeis,
				compra_ilimitada: current.compra_ilimitada,
				valor_alcada_compra: current.valor_alcada_compra,
			},
		};
	}, {});
	if (obra_selecionada === 0 && all_ids.length > 0) {
		[obra_selecionada] = all_ids;
	}
	return [by_id, all_ids, obra_selecionada];
};
