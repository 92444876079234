/* eslint-disable no-param-reassign */
const buildItensForEdition = _itens => {
	let _i = 0;
	const itens = _itens
		.map(item => {
			if (item.item_preenchido.item === undefined) {
				item.quantidade = item.quantidade === null ? 0 : item.quantidade;
				item.item_preenchido = {};
				return item;
			}
			const item_conaz = item.item_preenchido.item || {};
			const caracteristicas = (item_conaz.caracteristicas || [])
				.filter(c => c.nome !== 'Recomendação de Marca');
			const carac_preenchidas_by_id_1 = caracteristicas
				.sort((a, b) => a.ordem - b.ordem)
				.reduce((r, c) => {
					r[c.id] = {
						caracteristica: { ...c },
						opcoes_preenchidas: c.opcoes_preenchidas !== undefined ? c.opcoes_preenchidas : [],
					};
					return r;
				}, {});
			const caracs_preenchidas = (item.item_preenchido.caracteristicas_preenchidas || [])
				.filter(c => c.caracteristica.nome !== 'Recomendação de Marca');
			const carac_preenchidas_by_id_2 = caracs_preenchidas
				.sort((a, b) => a.caracteristica.ordem - b.caracteristica.ordem)
				.reduce((r, c) => {
					r[c.caracteristica.id] = c;
					return r;
				}, {});
			const carac_preenchidas_by_id = { ...carac_preenchidas_by_id_1, ...carac_preenchidas_by_id_2 };
			const carac_preenchidas_all_ids = Object.keys(carac_preenchidas_by_id).map(id => id);

			return {
				...item,
				quantidade: item.quantidade === null ? 0 : item.quantidade,
				item_preenchido: {
					...item.item_preenchido,
					caracteristicas_preenchidas: carac_preenchidas_by_id,
					caracteristicas_preenchidas_all_ids: carac_preenchidas_all_ids,
					item: {
						...item_conaz,
						caracteristicas,
					},
				},
			};
		})
		.sort((a, b) => a.ordem - b.ordem)
		.map(item => {
			_i++;
			item.front = {
				key: _i,
				errors: [],
				origem: '',
				item_de_planilha: false,
				planilha_pre_values: [],
			};
			return item;
		});

	return itens;
};

export const transformItens = itens => {
	const itens_edition_format = buildItensForEdition(itens);
	const all_ids = [];
	const by_id = {};

	itens_edition_format.forEach(current => {
		all_ids.push(current.id);
		by_id[current.id] = current;
	});

	return { by_id, all_ids };
};
