/* eslint class-methods-use-this: 0 */
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

// Functions
import { buildItensPedidoV3, buildLocalAnexos } from '../../components/_functions/_buildNovaCotacao';
import { caracteristicaType } from '../../components/_functions/_caracteristicaType';
import { _get } from '../../components/_functions/_requests';
import { handleRequestErrors } from '../../components/_functions/_handleRequestErrors';
import { defineStatusPedido } from '../../components/_functions/_defineStatusPedido';
import { prepareItensToGet, prepareSearchUrl, handleInfoIntegracoes } from '../../components/_functions/_handleInfoIntegracoes';

function NovaCotacaoHoc(Comp) {
	return class PP extends Component {
		constructor(props) {
			super(props);
			this.state = {
				window_height: 600,
			};
			this.handleResponsesHoc = this.handleResponsesHoc.bind(this);
			this.validacaoItens = this.validacaoItens.bind(this);
			this.validaInfo = this.validaInfo.bind(this);
		}

		componentDidMount() {
			window.addEventListener('resize', this.handleDimensions);
			window.addEventListener('mouseup', this.handleMouseUp);
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.handleDimensions);
			window.removeEventListener('mouseup', this.handleMouseUp);
		}

		handleResponsesHoc(
			responses,
			updateWrapperState,
			history,
			updateCotacaoRaw,
		) {
			/**
			 * Pedido não existe
			 */
			const error_message = (responses[0].data || {}).message || '';
			const error_type = (responses[0].data || {}).type || '';
			if (error_type === 'ResourceError' && error_message !== '') {
				updateWrapperState('request_state', 5);
				return;
			}

			const nova_cotacao = { ...responses[0].data };
			/**
			 * Verifica status da cotação e redireciona caso tenha sido enviada
			 * Mostra mensagem que o pedido não existe caso status esteja errado
			 */
			const status = defineStatusPedido(nova_cotacao);
			if (
				(status === 'revisao_conaz' ||
				status === 'aguardando_cotacoes' ||
				status === 'qc_disponivel' ||
				status === 'compras_aguardando_confirmacao' ||
				status === 'cancelado' ||
				status === 'finalizado') && process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno'
			) {
				history.replace(`/cotacao/${nova_cotacao.codigo}`);
				return;
			}
			if (
				(status !== 'rascunho' &&
				status !== 'refinamento' &&
				status !== 'em_edicao' &&
				status !== 'envio_solicitado') && process.env.REACT_APP_CONAZ_PROJECT !== 'construtor-interno'
			) {
				updateWrapperState('request_state', 5);
				return;
			}
			/**
			 * Atualiza redux
			 */
			const itens_array = buildItensPedidoV3(nova_cotacao.itens_de_pedido);
			const prazo_de_resposta = nova_cotacao.prazo_de_resposta !== null
				? nova_cotacao.prazo_de_resposta
				: '2016-01-01';
			const entrega_desejada = nova_cotacao.data_de_entrega_desejada !== null
				? nova_cotacao.data_de_entrega_desejada
				: '2016-01-01';
			/**
			 * Condição de pagamento de preferência do construtor
			 */
			const existe_condicao_de_preferencia = (
				nova_cotacao.condicao_de_preferencia.id !== undefined &&
				nova_cotacao.condicao_de_preferencia.id !== null
			);
			const preferencia_de_pagamento = !existe_condicao_de_preferencia
				? {
					id: null,
				}
				: {
					...nova_cotacao.condicao_de_preferencia,
					forma: nova_cotacao.condicao_de_preferencia.forma || 0,
					prazo: nova_cotacao.condicao_de_preferencia.prazo || 0,
					desconto: 0,
				};
			updateCotacaoRaw({
				id: nova_cotacao.id,
				codigo: nova_cotacao.codigo,
				anexos: buildLocalAnexos(nova_cotacao.anexos),
				itens: itens_array,
				titulo: nova_cotacao.titulo,
				titulo_sugerido: nova_cotacao.titulo_sugerido,
				prazo_entrega_qc: moment(prazo_de_resposta),
				data_entrega_obra: moment(entrega_desejada),
				obra: nova_cotacao.obra.id,
				obra_completa: nova_cotacao.obra,
				observacoes: nova_cotacao.observacoes || '',
				observacoes_internas: nova_cotacao.observacoes_internas || '',
				exibir_contato: nova_cotacao.exibir_contato,
				prospeccao_extra_fornecedores: nova_cotacao.prospeccao_extra_fornecedores,
				request_state_fornecedores: 1,
				status: nova_cotacao.status,
				cancelado: nova_cotacao.cancelado,
				em_aprovacao: nova_cotacao.em_aprovacao,
				em_refinamento: nova_cotacao.em_refinamento,
				preferencia_de_pagamento,
				visto_pelo_fornecedor: nova_cotacao.visto_pelo_fornecedor,
				motivo_refinamento: nova_cotacao.motivo_refinamento,
				codigo_ibge_obra: nova_cotacao.obra.codigo_ibge,
				observacoes_ou_itens_alterados: nova_cotacao.observacoes_ou_itens_alterados,
			});
			if (process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno') {
				const comprador = nova_cotacao.obra.pessoas.filter(x => x.papeis.filter(p => p === 2).length > 0);
				// const comprador = nova_cotacao.obra.pessoas.filter(x => x.papeis.find(p => p === 2) !== undefined);
				const cliente = {
					...comprador[0].pessoa,
					endereco: { ...nova_cotacao.obra.endereco },
					id_cliente: nova_cotacao.obra.cliente.id,
					observacoes_criticas: nova_cotacao.obra.cliente.observacoes_criticas,
					nome_cliente: nova_cotacao.obra.cliente.apelido_interno,
					nome_obra: nova_cotacao.obra.nome,
				};
				updateCotacaoRaw({
					cliente_rc_revisao: cliente,
					observacoes_conaz: nova_cotacao.observacoes_conaz,
					usuario_id: nova_cotacao.usuario.id,
					usuario_nome: nova_cotacao.usuario.nome,
					aprovado_por_nome: nova_cotacao.aprovado_por.nome,
					status: nova_cotacao.status,
					obra_modelo: nova_cotacao.obra.modelo,
					obra_endereco: nova_cotacao.obra.endereco,
					obra_pessoas: nova_cotacao.obra.pessoas,
				});
			}
			// updateWrapperState('request_state', 2);
			this.carregaInfoIntegracoes(nova_cotacao, itens_array, updateCotacaoRaw, updateWrapperState);
		}

		carregaInfoIntegracoes(nova_cotacao, itens_array, updateCotacaoRaw, updateWrapperState) {
			const ids_to_get = prepareItensToGet(itens_array);
			/**
			 * Mostra itens caso não tenha nenhum GET
			 */
			if (ids_to_get.length === 0) {
				updateWrapperState('request_state', 2);
				this.carregaFornecedoresSelecionados(nova_cotacao.id, updateCotacaoRaw);
				return;
			}
			/**
			 * GET dos itens integração
			 */
			const is_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';
			const cliente_id = ((nova_cotacao.obra || {}).cliente || {}).id;
			const search_url = !is_triagem
				? prepareSearchUrl(ids_to_get, null)
				: prepareSearchUrl(ids_to_get, cliente_id);
			// const search_url = prepareSearchUrl(ids_to_get);
			_get(search_url).then(response => {
				const response_itens = (response.data || {}).result || [];
				const itens_array_new = handleInfoIntegracoes(itens_array, response_itens);
				updateCotacaoRaw({ itens: itens_array_new });
				updateWrapperState('request_state', 2);
				this.carregaFornecedoresSelecionados(nova_cotacao.id, updateCotacaoRaw);
			}).catch(error => {
				updateWrapperState('request_state', 2);
				handleRequestErrors(error);
			});
		}

		carregaFornecedoresSelecionados(cotacao_id, updateCotacaoRaw) {
			const url = `/conaz/v2/rcs/
				?pedido_id=${cotacao_id}
				&fields=uuid,status,fornecedor,usuario,estah_resetado,tipo_selecao_fornecedor,preenchimento_fornecedor,criado_em,visto
			`;
			_get(url).then(response => {
				const filtrados = response.data.filter(f => f.status !== -30); // remove RC's cancelados
				const fornecedores_by_id = filtrados.reduce((result, current) => {
					const vendedores = current.fornecedor.usuarios.map(u => ({
						id: u.usuario.id,
						nome: u.usuario.nome,
						email: u.usuario.email,
						telefones: u.usuario.telefones,
						status: u.usuario.status,
						cadastrado_por: u.usuario.cadastrado_por,
						locais_de_entrega: u.locais_de_entrega,
						grupos: u.grupos,
						status_fornecedor_vendedor: u.status,
					}));
					/**
					 * tipo_selecao_fornecedor
					 *   1 => contrutor escolheu só fornecedor
					 *   2 => contrutor escolheu só fornecedor e vendedor
					 *   3 => conaz
					 */
					return {
						...result,
						[current.fornecedor.id]: {
							...current.fornecedor,
							rc_id: current.uuid,
							rc: {
								estah_resetado: current.estah_resetado,
								status: current.status,
							},
							vendedores,
							vendedor_selecionado: current.usuario,
							sem_preferencia_vendedor: current.tipo_selecao_fornecedor === 1,
							preenchimento_fornecedor: current.preenchimento_fornecedor,
							tipo_selecao_fornecedor: current.tipo_selecao_fornecedor,
						},
					};
				}, {});
				const fornecedores_all_ids = Object.keys(fornecedores_by_id).map(id => Number(id));
				updateCotacaoRaw({
					fornecedores_by_id,
					fornecedores_all_ids,
					request_state_fornecedores: 2,
				});
			}).catch(error => {
				updateCotacaoRaw({ request_state_fornecedores: 3 });
				handleRequestErrors(error);
			});
		}

		validacaoItens(itens, setRespostaAlert, setRespostaUnidadeAlert) {
			for (let i = 0; i < itens.length; i++) {
				const item = itens[i];
				const { key } = itens[i].front;
				// Item de planilha não associado
				if (item.importado_planilha === 1) {
					return { success: false, key, type: 'importado_planilha' };
				}

				// Item Sienge pre-configurado
				const detalhe_id = item.id_detalhe_item_construtor || null;
				const itens_associados = (item.item_integrado || {}).itens_associados || [];
				const item_associado = itens_associados
					.filter(ia => (
						ia.detalhe_item_integrado.id_no_swi === detalhe_id
						|| (ia.detalhe_item_integrado.id_no_swi === undefined && detalhe_id === null)
					))[0] || {};
				const item_associado_id = (item_associado || {}).id || null;
				const aprovado = (item_associado || {}).aprovado || null;
				const item_associado_caracs_preenchidas = ((item_associado || {}).item_preenchido || {}).caracteristicas || [];
				const has_caracteristicas = item_associado_caracs_preenchidas.length > 0;
				const item_integracao_nao_aprovado = !item.entendido && !aprovado && item_associado_id !== null && has_caracteristicas;
				if (item_integracao_nao_aprovado) {
					return { success: false, key, type: 'item_associado_pre_aprovado' };
				}

				// Quantidade
				if (
					(item.cliente_associou && (item.quantidade === 0 || item.quantidade === '')) ||
					(!item.cliente_associou && (item.pre_quantidade === 0 || item.pre_quantidade === ''))
				) {
					return { success: false, key, type: 'quantidade' };
				}
				// Unidade
				if ((item.cliente_associou && item.unidade === '') || (!item.cliente_associou && item.pre_unidade === '')) {
					return { success: false, key, type: 'unidade' };
				}

				// Respostas
				if (item.item_preenchido.caracteristicas_preenchidas_all_ids) {
					for (let p = 0; p < item.item_preenchido.caracteristicas_preenchidas_all_ids.length; p++) {
						const carac = item.item_preenchido.caracteristicas_preenchidas[item.item_preenchido.caracteristicas_preenchidas_all_ids[p]];
						const resposta_id = item.item_preenchido.caracteristicas_preenchidas_all_ids[p];
						if (carac.caracteristica.visao_construtor === 2) {
							const { selecao, tipo_de_valor, opcoes } = carac.caracteristica;
							const valor = carac.opcoes_preenchidas.length > 0 ? carac.opcoes_preenchidas[0].valor : '';
							const unidade = carac.opcoes_preenchidas.length > 0 ? carac.opcoes_preenchidas[0].unidade : '';
							if (carac.opcoes_preenchidas.length === 0 || valor === '' || (tipo_de_valor === 'numero' && valor === 0)) {
								if (setRespostaAlert !== undefined) {
									setRespostaAlert(key, resposta_id);
								}
								return { success: false, key, type: '' };
							}

							const caracteristica_type = caracteristicaType(opcoes, selecao, tipo_de_valor);
							const has_unidade = (opcoes.length > 0 && caracteristica_type !== 'selecao' && caracteristica_type !== 'multipla_escolha') ||
								(caracteristica_type === 'selecao' && opcoes.length > 0 && opcoes[0].unidade !== '');
							if (has_unidade && unidade === '') {
								if (setRespostaUnidadeAlert !== undefined) {
									setRespostaUnidadeAlert(key, resposta_id);
								}
								return { success: false, key, type: '' };
							}
						}
					}
				}
			}
			return { success: true, key: -1, type: '' };
		}

		validaInfo(cotacao, _errors) {
			const { titulo, prazo_entrega_qc, data_entrega_obra } = cotacao;

			// titulo
			const _titulo = (titulo !== null && titulo !== undefined) ? titulo.trim() : '';
			if (_titulo === '') {
				const errors = { ..._errors, titulo: true };
				return { success: false, errors, type: 'titulo' };
			}

			// datas
			const prazo_resposta_minimo = moment();
			if (prazo_resposta_minimo.hour() >= 11) prazo_resposta_minimo.add(1, 'd'); // passou das 11h
			if (prazo_resposta_minimo.day() === 6) prazo_resposta_minimo.add(2, 'd'); // sábado
			if (prazo_resposta_minimo.day() === 0) prazo_resposta_minimo.add(1, 'd'); // domingo

			const prazo_entrega_qc_diff = moment(prazo_entrega_qc).format('YYYY-MM-DD');
			const prazo_resposta_minimo_diff = moment(prazo_resposta_minimo).format('YYYY-MM-DD');

			if (!moment(prazo_entrega_qc_diff).isSameOrAfter(prazo_resposta_minimo_diff, 'day')) {
				const errors = { ..._errors, prazo_entrega_qc: true };
				return { success: false, errors, type: 'prazo_entrega_qc' };
			}

			const data_entrega_obra_diff = moment(data_entrega_obra).format('YYYY-MM-DD');

			if (!moment(data_entrega_obra_diff).isAfter(prazo_entrega_qc_diff, 'day')) {
				const errors = { ..._errors, data_entrega_obra: true };
				return { success: false, errors, type: 'data_entrega_obra' };
			}

			return { success: true, errors: {}, type: '' };
		}

		render() {
			return (
				<Comp
					{...this.state}
					{...this.props}
					handleResponsesHoc={this.handleResponsesHoc}
					validacaoItens={this.validacaoItens}
					validaInfo={this.validaInfo}
				/>
			);
		}
	};
}

export default NovaCotacaoHoc;
