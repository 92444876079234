import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Functions
import { setClasses } from '../_functions/_setClasses';

const ModalRatingThumbnails = ({
	criterios,
	step,
	navigateTo,
	checkStepCompleto,
}) => {
	const criterios_arr = Object.keys(criterios) || [];
	const max_step_incompleto = checkStepCompleto();

	return (
		<div styleName="thumbnails-wrapper">
			<div styleName="thumbnails">
				{criterios_arr.map((criterio_id, n) => {
					const step_number = n + 1;
					const thumb_classes = {
						actual: step === step_number,
						previous: step > step_number,
						next: step < step_number,
					};
					const thumb_class = setClasses('thumb', thumb_classes);

					return (
						<div
							key={criterio_id}
							styleName={thumb_class}
						/>
					);
				})}
			</div>
			{step > 1 && (
				<button
					type="button"
					styleName="nav-prev-button"
					onClick={() => navigateTo(step - 1)}
				>
					<i className="fa fa-angle-left" />
				</button>
			)}
			{step < max_step_incompleto && (
				<button
					type="button"
					styleName="nav-next-button"
					onClick={() => navigateTo(step + 1)}
				>
					<i className="fa fa-angle-right" />
				</button>
			)}
		</div>
	);
};

ModalRatingThumbnails.propTypes = {
	criterios: PropTypes.object.isRequired,
	step: PropTypes.number.isRequired,
	navigateTo: PropTypes.func.isRequired,
	checkStepCompleto: PropTypes.func.isRequired,
};

export default CSSModules(ModalRatingThumbnails, styles, { allowMultiple: true });
