import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from '../style.module.scss';

// Functions
import {
	setDecimalsToNumber,
	setDecimalsToString,
} from '../../../_functions/_numbers';
import { clearItensAlerts } from '../../../_functions/_clearItensAlerts';

class InputNumber extends Component {
	constructor() {
		super();
		this.state = {
			value: '',
		};
		this.handleEdition = this.handleEdition.bind(this);
		this.checkOutro = this.checkOutro.bind(this);
	}

	UNSAFE_componentWillMount() {
		const { default_value } = this.props;
		if (default_value !== 0) this.setState({ value: setDecimalsToString(default_value) });
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.item_key !== nextProps.item_key) {
			const value =
				nextProps.default_value !== 0
					? setDecimalsToString(nextProps.default_value)
					: '';
			this.setState({ value });
		}
	}

	handleEdition(e) {
		const {
			type,
			item_key,
			field,
			opcao_preenchida,
			updateFunction,
			autoSave,
			icon,
			zerar_erros,
		} = this.props;
		const number_values = setDecimalsToNumber(e.target.value); // numeros
		let props_value = number_values[0]; // numero
		let state_value = setDecimalsToString(number_values[1]); // string

		if (icon === '%' && props_value >= 100) {
			props_value = 100;
			state_value = '100';
		}

		this.setState({ value: state_value });

		switch (type) {
		case 'item': {
			updateFunction(item_key, field, props_value);
			break;
		}
		case 'caracteristica': {
			// const opcao_preenchida = buildOpcaoPreenchida(props_value, '', null);
			const _opcao_preenchida = {
				...opcao_preenchida,
				valor: props_value,
			};
			updateFunction(item_key, field, [_opcao_preenchida]);
			break;
		}
		default:
		}

		autoSave(item_key);
		if (zerar_erros) clearItensAlerts();
	}

	checkOutro() {
		if (this.props.outro && this.state.value === '') this.props.handleOutro();
	}

	render() {
		const { value } = this.state;
		const { field_id, disabled, icon, icon_width } = this.props;
		const input_padding =
			icon !== '' ? { paddingLeft: `${icon_width + 11}px` } : {};

		return (
			<>
				{icon !== '' && (
					<div
						styleName="left-icon"
						style={{ width: `${icon_width}px` }}>
						{icon}
					</div>
				)}
				<input
					id={field_id}
					type="text"
					className="form-control input-md"
					style={input_padding}
					disabled={disabled}
					value={value}
					onChange={this.handleEdition}
					onBlur={this.checkOutro} />
			</>
		);
	}
}

InputNumber.propTypes = {
	type: PropTypes.string.isRequired,
	field_id: PropTypes.string.isRequired,
	item_key: PropTypes.number.isRequired,
	field: PropTypes.string.isRequired,
	default_value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	opcao_preenchida: PropTypes.object,
	updateFunction: PropTypes.func,
	outro: PropTypes.bool,
	handleOutro: PropTypes.func,
	disabled: PropTypes.bool,
	autoSave: PropTypes.func,
	icon: PropTypes.string,
	icon_width: PropTypes.number,
	zerar_erros: PropTypes.bool,
};

InputNumber.defaultProps = {
	default_value: 0,
	opcao_preenchida: {},
	updateFunction: () => {},
	outro: false,
	handleOutro: () => {},
	disabled: false,
	autoSave: () => {},
	icon: '',
	icon_width: 0,
	zerar_erros: true,
};

export default CSSModules(InputNumber, styles, { allowMultiple: true });
