import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { CaretDownOutlined } from '@ant-design/icons';
// Components
import { Button, Collapse } from 'antd';
import { CollapseContainer } from './styled';

const CollapseLine = ({ title, btnText, btnOnClick, children }) => (
	<CollapseContainer>
		<Collapse
			ghost
			expandIcon={({ isActive }) => <CaretDownOutlined rotate={isActive ? 180 : 0} style={{ fontSize: 20, color: '#8C8C8C' }} />}
			collapsible="header"
			defaultActiveKey={['1']}
		>
			<Collapse.Panel
				key="1"
				header={(
					<CollapseContainer.Actions onClick={(e) => e.stopPropagation()}>
						<span className="colapse-title">
							{title}
						</span>
						<Button
							style={btnText === 'Associar novo usuário' ? { left: '1rem' } : {}}
							onClick={btnOnClick}
							size="small"
							className="colapse-add-btn"
						>
							{btnText}
						</Button>
					</CollapseContainer.Actions>
				)}
			>
				{children}
			</Collapse.Panel>
		</Collapse>
	</CollapseContainer>
);

CollapseLine.propTypes = {
	title: PropTypes.string.isRequired,
	btnText: PropTypes.string.isRequired,
	btnOnClick: PropTypes.func.isRequired,
	children: PropTypes.any,
};

CollapseLine.defaultProps = {
	children: null,
};

export default CollapseLine;
