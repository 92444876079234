import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class AlterarItemSienge extends Component {
	alteraItem = () => {
		const { desassociarItem } = this.props;
		desassociarItem();
	}

	render() {
		const { showTooltip, hideTooltip, saving } = this.props;

		return (
			<>
				{saving !== 1 ? (
					<button
						type="button"
						className="close"
						styleName="desassocia-button"
						onClick={this.alteraItem}
						onMouseEnter={() => showTooltip('tooltip-alterar')}
						onMouseLeave={() => hideTooltip('tooltip-alterar')}>
						<i className="fa fa-exchange" aria-hidden="true" />
					</button>
				) : (
					<button
						type="button"
						className="close"
						styleName="desassocia-button"
						style={{ opacity: '0.4' }}
						onMouseEnter={() => showTooltip('tooltip-alterar')}
						onMouseLeave={() => hideTooltip('tooltip-alterar')}>
						<i className="fa fa-exchange" aria-hidden="true" />
					</button>
				)}
				<div id="tooltip-alterar" className="tooltip left" style={{ top: '7px', right: '48px' }} role="tooltip">
					<div className="tooltip-arrow" />
					<div className="tooltip-inner">Trocar item</div>
				</div>
			</>
		);
	}
}

AlterarItemSienge.propTypes = {
	desassociarItem: PropTypes.func.isRequired,
	showTooltip: PropTypes.func.isRequired,
	hideTooltip: PropTypes.func.isRequired,
	saving: PropTypes.number.isRequired,
};

export default CSSModules(AlterarItemSienge, styles, { allowMultiple: true });
