import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

// Components
import { Button } from 'antd';
import DrawerEmpresas from 'components/DrawerEmpresas';

// Redux HOC
import { connect } from 'react-redux';
import withStore from '../../store/withStore';
import { buildMapStateToProps, buildmapDispatchToProps } from '../../store/reduxDispatchs';

//
import LayoutWrapper from '../../components/LayoutWrapper';

//
import styles from './style.module.scss';
import { StyledTable } from './styled';

import columns from './columns';
import tableLocale from './locale';

function TabelaEmpresas({ ...props }) {
	const [usuarioAdministrador, setUsuarioAdministrador] = useState(localStorage.getItem('usuario_admin') || null);
	const [loadingSearch] = useState(false);
	const [filterTxt, setFilterTxt] = useState('');
	const maxPerPage = 10;

	const {
		empresas,
		getEmpresas,
		getUser,
		editEmpresa,
		createEmpresa,
		deleteEmpresa,
		openEmpresaDrawer,
		closeEmpresaDrawer,
	} = props;

	const handleOpenDrawer = (mode = 'new', data = null) => {
		openEmpresaDrawer(mode, data);
	};

	const closeDrawer = () => {
		closeEmpresaDrawer();
	};

	const handleDelete = (empresa) => {
		deleteEmpresa(empresa);
	};

	const onSearch = (e) => {
		const { value } = e.target;
		setTimeout(() => {
			setFilterTxt(value);
		}, 400);
	};

	const handleSubmit = (data) => {
		if (empresas.drawerMode === 'edit') {
			editEmpresa(data);
			return;
		}
		createEmpresa(data);
	};

	useEffect(() => {
		document.title = 'Cadastro - Empresas';
		getEmpresas();
		getUser();
	}, [getEmpresas, getUser]);

	const filteredEmpresas = filterTxt ? empresas.empresas.filter((u) => u.nome.toLowerCase().includes(filterTxt.toLowerCase()))
		: empresas.empresas;

	useEffect(() => {
		if ((!localStorage.getItem('usuario_admin') || localStorage.getItem('usuario_admin') === false) && localStorage.getItem('usuario_admin') !== true) {
			setUsuarioAdministrador(false);
		}
	}, [usuarioAdministrador]);

	return (
		<LayoutWrapper {...props}>
			<>
				<DrawerEmpresas
					onClose={closeDrawer}
					mode={empresas.drawerMode}
					visible={empresas.showDrawer}
					empresaData={empresas.dataToDrawer}
					onSubmit={handleSubmit}
					loading={empresas.actionFetching}
				/>
				<div styleName="header">
					<div styleName="header-title">
						Empresas
					</div>
					<Button
						type="primary"
						shape="round"
						size="large"
						styleName="add-empresa-btn"
						style={{ display: (usuarioAdministrador === false ? 'none' : '') }}
						onClick={() => handleOpenDrawer()}>
						Nova empresa
					</Button>
				</div>

				<div styleName="search-area">
					<div styleName="search-input-wrapper">
						<input
							onChange={onSearch}
							styleName="search-input"
							placeholder="Buscar por nome ou palavra chave"
						/>
						<i
							styleName="search-input-icon"
							className={!loadingSearch ? 'fa fa-search' : 'fa fa-spin fa-spinner'}
							aria-hidden="true"
						/>
					</div>
				</div>

				<div styleName="table-space">
					<StyledTable
						columns={columns('admin', handleOpenDrawer, handleDelete, usuarioAdministrador)}
						dataSource={filteredEmpresas}
						pagination={{ pageSize: maxPerPage, hideOnSinglePage: true }}
						locale={tableLocale}
						size="small"
						loading={empresas.isFetching || empresas.actionFetching}
					/>
				</div>
			</>
		</LayoutWrapper>
	);
}

TabelaEmpresas.propTypes = {
	props: PropTypes.any,
	// Funções
	getEmpresas: PropTypes.func,
	getUser: PropTypes.func,
	openEmpresaDrawer: PropTypes.func,
	closeEmpresaDrawer: PropTypes.func,
	editEmpresa: PropTypes.func,
	createEmpresa: PropTypes.func,
	deleteEmpresa: PropTypes.func,
	//
	empresas: PropTypes.shape({
		dataToDrawer: PropTypes.shape({}),
		drawerMode: PropTypes.string,
		showDrawer: PropTypes.bool,
		empresas: PropTypes.arrayOf(PropTypes.shape({})),
		isFetching: PropTypes.bool,
		actionFetching: PropTypes.bool,
	}),
};

TabelaEmpresas.defaultProps = {
	props: {},
	getEmpresas: () => {},
	getUser: () => {},
	openEmpresaDrawer: () => {},
	closeEmpresaDrawer: () => {},
	editEmpresa: () => {},
	createEmpresa: () => {},
	deleteEmpresa: () => {},
	empresas: {},
};

const WithStyles = CSSModules(TabelaEmpresas, styles);

const mapStateToProps = (props) => buildMapStateToProps(props);
const mapDispatchToProps = (dispatch) => buildmapDispatchToProps(dispatch);
export default withStore(connect(mapStateToProps, mapDispatchToProps)(WithStyles));
