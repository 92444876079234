import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CompraRowAvaliacaoButton from './CompraRowAvaliacaoButton';
import CompraRowEntregaButton from './CompraRowEntregaButton';
import ModalPortal from '../_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../_modals/ModalPortal/ModalWrapperPortal';
import ModalRating from '../../ModalRating';
import ModalAlcada from '../../ModalAlcada';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { _get } from '../../_functions/_requests';
import { handleRequestErrors } from '../../_functions/_handleRequestErrors';

const CompraRowButtons = ({
	compra,
	avaliacao,
	location,
}) => {
	const obra_selected =
		useSelector(store => (
			store.user.obra_selected), shallowEqual) || 0;
	const obras_by_id =
		useSelector(store => (
			store.user.obras_by_id), shallowEqual) || {};

	const [modal_evaluation, setModalEvaluation] = useState(false);
	const [modal_alcada, setModalAlcada] = useState(false);
	const [usuarios_alcada, setUsuariosAlcada] = useState([]);
	const [loading, setLoading] = useState(0);

	function handleModalAlcada() {
		setModalAlcada(!modal_alcada);
	}

	function getUserWithAlcada() {
		const obra_id = (compra.obra || {}).id || 0;
		const url = `/conaz/v2/obras/${obra_id}`;
		_get(url).then(({ data }) => {
			const usuarios = (data || {}).usuarios || [];
			const responsaveis = usuarios
				.filter(usuario => usuario.papeis
					.find(papel => (papel === 10 || papel === 11)));
			if (responsaveis.length >= 1) {
				setUsuariosAlcada(responsaveis);
			}
			handleModalAlcada();
			setLoading(2);
		}).catch(error => {
			handleRequestErrors(error);
			setLoading(3);
		});
	}

	function userHasAlcada() {
		const papeis_obra = (obras_by_id[obra_selected] || {}).papeis || [];
		const has_alcada = papeis_obra
			.filter(x => x === 10 || x === 11)
			.length > 0;
		return has_alcada;
	}

	function handleEvaluation() {
		const has_alcada = userHasAlcada();
		if (has_alcada) {
			setModalEvaluation(!modal_evaluation);
			return;
		}
		getUserWithAlcada();
	}

	const modalRating = modal_evaluation ? (
		<ModalPortal>
			<ModalWrapperPortal closeModal={handleEvaluation}>
				<ModalRating
					closeModal={handleEvaluation}
					avaliacao={avaliacao}
					compra={compra}
					location={location}
				/>
			</ModalWrapperPortal>
		</ModalPortal>
	) : null;
	const modalAlcada = modal_alcada ? (
		<ModalPortal>
			<ModalWrapperPortal closeModal={handleModalAlcada}>
				<ModalAlcada
					closeModal={handleModalAlcada}
					compra={compra}
					usuarios={usuarios_alcada}
					handleModal={handleModalAlcada}
				/>
			</ModalWrapperPortal>
		</ModalPortal>
	) : null;

	const status = defineStatusCompra(compra);

	const avaliacao_fornecedor_id = location === 'compra'
		? (compra.avaliacao_fornecedor || {}).id || 0
		: compra.avaliacao_fornecedor_id || 0;
	const has_avaliacao = avaliacao_fornecedor_id !== 0;

	const show_avaliacao_button = (
		status === 'entrega_confirmada'
		&& !has_avaliacao
	);
	if (show_avaliacao_button) {
		return (
			<>
				{modalRating}
				{modalAlcada}
				<CompraRowAvaliacaoButton
					compra={compra}
					handleEvaluation={handleEvaluation}
					location={location}
				/>
			</>
		);
	}

	const show_entrega_button = (
		status === 'aguardando_confirmacao'
		|| status === 'confirmada'
	);
	if (show_entrega_button) {
		return (
			<>
				{modalRating}
				{modalAlcada}
				<CompraRowEntregaButton
					compra={compra}
					loading={loading}
					setLoading={setLoading}
					handleEvaluation={handleEvaluation}
					getUserWithAlcada={getUserWithAlcada}
					location={location}
				/>
			</>
		);
	}

	return null;
};

CompraRowButtons.propTypes = {
	compra: PropTypes.object.isRequired,
	avaliacao: PropTypes.object.isRequired,
	location: PropTypes.string.isRequired,
};

export default CSSModules(CompraRowButtons, styles, { allowMultiple: true });
