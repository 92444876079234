import React, { Component } from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

class ItemRowWrapper extends Component {
	state = {};

	render() {
		const {
			is_editable,
			excluido,
			openItemEditionLocal,
			children,
		} = this.props;
		const child = React.cloneElement(children);

		if (is_editable && !excluido) {
			return (
				<a href="#" onClick={openItemEditionLocal}>{child}</a>
			);
		}

		return (
			<a href="#" onClick={openItemEditionLocal}>{child}</a>
		);
		// return (
		// 	<div>{child}</div>
		// );
	}
}

ItemRowWrapper.propTypes = {
	is_editable: PropTypes.bool.isRequired,
	excluido: PropTypes.bool.isRequired,
	openItemEditionLocal: PropTypes.func.isRequired,
	children: PropTypes.object.isRequired,
};

export default CSSModules(ItemRowWrapper, styles, { allowMultiple: true });
