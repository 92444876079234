import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getLocalstorageData } from './_getLocalstorageData';

// axiosRetry(axios, { retries: 3 });

import { instance_get } from '../../services/api';

/* ------------------------------------------------------ *\
	get
\* ------------------------------------------------------ */
export function _get(url, data = {}, status_to_validate = [404]) {
	const token = getLocalstorageData('user', 'token');
	const params = { ...data };
	return instance_get.get(url, {
		params,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'content-type': 'application/json',
		},
		maxRedirects: 0,
		validateStatus: status => (
			(status >= 200 && status < 300)
			|| status_to_validate.filter(s => s === status).length > 0
		),
	});
}

// export function _get(url, data = {}, status_to_validate = [404]) {
// 	const baseURL = getLocalstorageData('environment', 'base_url');
// 	const token = getLocalstorageData('user', 'token');
// 	const params = { ...data };
// 	const instance = axios.create({
// 		baseURL,
// 		headers: {
// 			common: {
// 				...axios.defaults.headers.common,
// 				token,
// 			},
// 			'content-type': 'application/json',
// 		},
// 		maxRedirects: 0,
// 		validateStatus: status => (status >= 200 && status < 300) || status_to_validate.filter(s => s === status).length > 0,
// 	});

// 	let retries = 0;
// 	axiosRetry(instance, {
// 		retries: 3,
// 		retryCondition: (error) => {
// 			const { config } = error;
// 			if (!config) return false;
// 			retries++;
// 			if (retries >= 3) {
// 				retries = 0;
// 				return false;
// 			}
// 			return true;
// 		},
// 	});

// 	return instance.get(url, { params });
// }

/* ------------------------------------------------------ *\
	put
\* ------------------------------------------------------ */
export function _put(url, data) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = getLocalstorageData('user', 'token');
	const params = { ...data };
	const instance = axios.create({
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
	});

	let retries = 0;
	axiosRetry(instance, {
		retries: 3,
		retryCondition: (error) => {
			const { config } = error;
			if (!config) return false;
			retries++;
			if (retries >= 3) {
				retries = 0;
				return false;
			}
			return true;
		},
	});

	return instance.put(url, params);
}

/* ------------------------------------------------------ *\
	post
\* ------------------------------------------------------ */
export function _post(url, data) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = getLocalstorageData('user', 'token');
	const instance = axios.create({
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
	});
	return instance.post(url, data);
}

/* ------------------------------------------------------ *\
	delete
\* ------------------------------------------------------ */
export function _delete(url) {
	const baseURL = getLocalstorageData('environment', 'base_url');
	const token = getLocalstorageData('user', 'token');
	const instance = axios.create({
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
	});
	return instance.delete(url);
}

/* ------------------------------------------------------ *\
	post exports service
\* ------------------------------------------------------ */
export function _post_exports(url, data) {
	const baseURL = getLocalstorageData('environment', 'exports_baseurl');
	const token = getLocalstorageData('user', 'token');
	const instance = axios.create({
		baseURL,
		headers: {
			common: {
				...axios.defaults.headers.common,
				token,
			},
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=-1,private',
		},
	});
	return instance.post(url, data);
}
