import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowequal from 'shallowequal';
import { Scrollbars } from 'react-custom-scrollbars';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.sass';

// Components
import Item from '../Item/Item';

class Lista extends Component {
	shouldComponentUpdate(nextProps) {
		if (!shallowequal(this.props, nextProps)) return true;
		return false;
	}

	handleCollapseItem = (toggle, item_id) => {
		const { collapsed_items, updateState } = this.props;
		const _collapsed_items = toggle
			? [...collapsed_items, item_id]
			: [...collapsed_items.filter(i_id => i_id !== item_id)];
		updateState('collapsed_items', _collapsed_items);
	}

	render() {
		const {
			itens,
			height,
			collapsed_items,
			openItemEdition,
			closeItemEdition,
			item_on_edition_id,
			compra_has_rc,
			deleteItem,
			is_editable,
			updateModals,
			handleModalErroEdicao,
			user,
		} = this.props;
		const { by_id, all_ids } = itens;

		return (
			<Scrollbars style={{ height: `${height}px` }}>
				<div styleName="itens-list">
					{all_ids.map(id => {
						//	const { caracteristicas_preenchidas: opcoes } = by_id[id].item_preenchido;
						const item = by_id[id] || {};
						const toggle = !!collapsed_items.find(it => id === it);
						const selected = item_on_edition_id === id;

						return (
							<Item
								key={id}
								item={item}
								user={user}
								toggle={toggle}
								handleCollapseItem={this.handleCollapseItem}
								openItemEdition={openItemEdition}
								closeItemEdition={closeItemEdition}
								selected={selected}
								compra_has_rc={compra_has_rc}
								deleteItem={deleteItem}
								is_editable={is_editable}
								updateModals={updateModals}
								handleModalErroEdicao={handleModalErroEdicao} />
						);
					})}
				</div>
				{all_ids.length === 0 && (
					<div styleName="empty-wrapper">
						<i className="fa fa-inbox" aria-hidden="true" />
						<h4>Nenhum item adicionado.</h4>
					</div>
				)}
			</Scrollbars>
		);
	}
}

Lista.propTypes = {
	height: PropTypes.number.isRequired,
	itens: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	collapsed_items: PropTypes.array.isRequired,
	updateState: PropTypes.func.isRequired,
	openItemEdition: PropTypes.func.isRequired,
	closeItemEdition: PropTypes.func.isRequired,
	item_on_edition_id: PropTypes.number.isRequired,
	compra_has_rc: PropTypes.bool.isRequired,
	deleteItem: PropTypes.func.isRequired,
	is_editable: PropTypes.bool.isRequired,
	updateModals: PropTypes.func.isRequired,
	handleModalErroEdicao: PropTypes.func.isRequired,
};

export default CSSModules(Lista, styles, { allowMultiple: true });
