const buildItemPreenchido = (item) => {
	if (item.cliente_associou) {
		const cp_all_ids = (item.item_preenchido || {}).caracteristicas_preenchidas_all_ids || [];
		const caracteristicas_preenchidas = cp_all_ids
			// .filter(c => {
			// 	const { caracteristica } = item.item_preenchido.caracteristicas_preenchidas[c];
			// 	return caracteristica.visao_construtor !== 0 || caracteristica.visao_fornecedor !== 0;
			// })
			.map(c => {
				const opcoes_preenchidas = item.item_preenchido.caracteristicas_preenchidas[c].opcoes_preenchidas.map(opcao => {
					if (opcao.id !== null) return opcao;
					if (opcao.caracteristica_id !== null) {
						return { caracteristica_id: opcao.caracteristica_id, valor: opcao.valor, unidade: opcao.unidade, validada: false };
					}
					return { valor: opcao.valor, unidade: opcao.unidade, validada: false };
				});
				return {
					...item.item_preenchido.caracteristicas_preenchidas[c],
					opcoes_preenchidas,
				};
			});

		return {
			caracteristicas_preenchidas,
			item: { ...item.item_preenchido.item },
		};
	}

	return {};
};

export const buildItem = (item) => {
	const item_preenchido = buildItemPreenchido(item);
	const _item = {
		...item,
		item_preenchido,
	};
	delete _item.front;
	if (item_preenchido === null) delete _item.item_preenchido;
	return _item;
};
