import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Component
import ItemPlanilhaEditionColumn from 'components/_parts/ItemPlanilhaEditionColumn/ItemPlanilhaEditionColumn';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';


class LeftModalAssociacaoPlanilha extends Component {
	closeModal = () => {
		this.props.updateModals('associacao', false);
	};

	handleStatusMsg = status => {
		if (status === 'nao_configurado') {
			return 'Não Configurado';
		}
		if (status === 'pre_configurado') {
			return 'Pré Configurado';
		}
		return 'Configurado';
	};

	handleStatusStyle = status => {
		if (status === 'nao_configurado') {
			return 'no_configurado';
		}
		if (status === 'pre_configurado') {
			return 'pre_configurado';
		}
		return 'configurado';
	};

	setStatus = itemAssociated => {
		// const item_integrado =
		// 	(itemAssociated || {}).item_integrado || [] || {};
		const item_associado =
			((itemAssociated || {}).itens_associados || [])[0] || {};

		const item_associado_id = item_associado.id || null;
		const aprovado = itemAssociated.associado_aprovado || false;
		const status_list = {
			nao_configurado: item_associado_id === null,
			pre_configurado: item_associado_id !== null && !aprovado,
			configurado: item_associado_id !== null && aprovado,
		};

		const status = Object.keys(status_list)
			.map(c => c)
			.filter(c => status_list[c])
			.join(' ');

		return status || 'nao_configurado';
	};

	render() {
		const {
			itemAssociated,
			itens,
			itemPlanilhaSelected,
			saving,
		} = this.props;

		const key = itens.findIndex(x => x.key === itemPlanilhaSelected.key);
		const status = this.setStatus(itemAssociated);
		const style_configurado = this.handleStatusStyle(status);

		if (itemPlanilhaSelected) {
			return (
				<div styleName="modal-content">
					<div styleName="contentInfoSienge">
						<div className="row">
							<div className="col-sm-12">
								<p style={{ fontWeight: '500' }}>
									Insumo via Planilha
								</p>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-4">
								<div styleName="id-sienge">
									<span>Código ERP:</span>
									<p>
										<b
											style={{
												color: '#333333',
												fontSize: '15px',
											}}>
											{itemPlanilhaSelected.id_no_swi}
										</b>
									</p>
								</div>
							</div>
							<div className="col-sm-8">
								<b
									style={{
										color: '#333333',
										fontSize: '15px',
									}}>
									{itemPlanilhaSelected.nome}
								</b>
								<p styleName="id-sienge">
									<span>Unidade: </span>
									<b
										style={{
											color: '#333333',
											fontSize: '15px',
										}}>
										{itemPlanilhaSelected.unidade}
									</b>
								</p>
							</div>
						</div>
						<div className="row">
							{itemPlanilhaSelected.detalhe && (
								<div className="col-sm-4">
									<p styleName="id-sienge">
										<span>Detalhe: </span>
										<label>
											{itemPlanilhaSelected.detalhe.valor}
										</label>
									</p>
								</div>
							)}
							<div className="col-sm-8">
								<div>
									<p styleName="id-sienge">
										<span>Status: </span>
										<label styleName={style_configurado}>
											{this.handleStatusMsg(status)}
										</label>
									</p>

								</div>
							</div>
						</div>
						<button
							type="button"
							className="close"
							styleName="close-button"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.closeModal}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<hr styleName="hrCustom" />
					{status === 'nao_configurado' && (
						<div styleName="content">
							<div>
								<h3 className="h3-header">
									Configurar associação automática
								</h3>
							</div>
						</div>
					)}
					<div styleName="content">
						<ItemPlanilhaEditionColumn
							itens={this.props.itens}
							client_id={this.props.user.cliente_id}
							key_item_selecionado={key}
							item_para_edicao={this.props.itemAssociated}
							updateItem={this.props.updateItem}
							desassociarItem={this.props.desassociarItem}
							saveItem={this.props.saveItem}
							updateCotacao={this.props.updateCotacao}
							autoSave={() => {}}
							status={status}
							updateAprovacao={this.props.updateAprovacao}
							updateItemCaracteristica={this.props.updateItemCaracteristica}
							saving={saving} />
					</div>
				</div>
			);
		}

		return null;
	}
}

LeftModalAssociacaoPlanilha.propTypes = {
	itens: PropTypes.array.isRequired,
	updateModals: PropTypes.func.isRequired,
	updateItem: PropTypes.func.isRequired,
	desassociarItem: PropTypes.func.isRequired,
	updateItemCaracteristica: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	saveItem: PropTypes.func.isRequired,
	itemPlanilhaSelected: PropTypes.object.isRequired,
	itemAssociated: PropTypes.object.isRequired,
	saving: PropTypes.number.isRequired,
	user: PropTypes.shape({
		cliente_id: PropTypes.number.isRequired,
	}).isRequired,
	updateAprovacao: PropTypes.func.isRequired,
};

export default CSSModules(LeftModalAssociacaoPlanilha, styles, { allowMultiple: true });
