import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import LeftModalWrapper from '../../../components/_parts/_modals/LeftModalWrapper/LeftModalWrapper';
import LeftModalFornecedor from '../../../components/_parts/_modals/LeftModalFornecedor/LeftModalFornecedor';
import LeftModalClientePerfil from '../../../components/_parts/_modals/LeftModalClientePerfil/LeftModalClientePerfil';
import FornecedoresSearchBar from '../../../components/_parts/FornecedoresSearchBar/FornecedoresSearchBar';
import ModalPortal from '../../../components/_parts/_modals/ModalPortal/ModalPortal';
import ModalWrapperPortal from '../../../components/_parts/_modals/ModalPortal/ModalWrapperPortal';
import ModalConfirm from '../../../components/_parts/_modals/ModalConfirm/ModalConfirm';
import FornecedorBox from './FornecedorBox';
import * as constants from './constants';

const NovaCotacaoFornecedores = ({
	// =========== state
	errors,
	fornecedor_pre_selecionado,
	form_novo_fornecedor,
	fornecedor_ja_existe,
	fornecedor_existente,
	max_5_alert,
	alert_delete_fornecedor_respondido,
	removendo_fornecedor,
	pre_nome_fornecedor,
	salvando_selecao_fornecedor,
	no_fornecedor_tooltip,
	// =========== props
	ui,
	cotacao,
	// =========== prop funcs
	updateModals,
	updateUi,
	updateCotacao,
	// =========== local funcs
	openSearch,
	validaEnvio,
	validaEnvioTooltip,
	removeFornecedor,
	revelaFornecedor,
	updateState,
	cancelaFornecedorExistente,
	adicionaFornecedorExistente,
	removeMax5Alert,
	handleAlertFornecedor,
	exibePerfilCliente,
	handleProspeccaoExtraFornecedores,
	criarEditarRC,
	cadastrarFornecedorVendedor,
	cadastrarVendedor,
	obterClienteIdCadastro,
	obterClienteIdReal,
	resetaErros,
}) => {
	const { modals, search_fornecedores_open } = ui;
	const { codigo, fornecedores_all_ids, fornecedores_by_id, request_state_fornecedores,
		populares_fornecedores_by_id, populares_fornecedores_all_ids, request_state_populares,
		obra_endereco, obra_pessoas, prospeccao_extra_fornecedores, codigo_ibge_obra, itens } = cotacao;

	const grupos_itens_pedido = itens
		.map(idp => {
			const id = ((((idp || {}).item_preenchido || {}).item || {}).grupo || {}).id || 0;
			return id;
		})
		.filter(g => g);

	let _filter_fornecedores = (f) => f;
	if (constants.FILTRA_RCS_NAO_RESPONDIDOS_CRIADOS_PELO_FORNECEDOR) {
		_filter_fornecedores = (f) => f.rc.status >= 40 || f.tipo_selecao_fornecedor < 4;
	}

	const _fornecedores_all_ids = fornecedores_all_ids.filter(f => _filter_fornecedores(fornecedores_by_id[f]));

	return (
		<>
			{alert_delete_fornecedor_respondido && (
				<ModalPortal>
					<ModalWrapperPortal closeModal={handleAlertFornecedor}>
						<ModalConfirm
							title="Fornecedor já respondeu esta cotação"
							message={<p>Por questões de transparência, não é possível retirar fornecedores que já responderam ao pedido de cotação.</p>}
							button_type="secondary-conaz"
							button_text="Entendi!"
							cancelar_button={false} />
					</ModalWrapperPortal>
				</ModalPortal>
			)}

			<FornecedoresSearchBar
				updateState={updateState}
				populares_fornecedores_by_id={populares_fornecedores_by_id}
				populares_fornecedores_all_ids={populares_fornecedores_all_ids}
				updateCotacao={updateCotacao}
				updateUi={updateUi}
				search_fornecedores_open={search_fornecedores_open}
				updateModals={updateModals}
				request_state_populares={request_state_populares}
				fornecedores_all_ids={_fornecedores_all_ids}
				cliente_id_cadastro={obterClienteIdCadastro()}
				cliente_id_real={obterClienteIdReal()}
				ocultar_vendedores_nao_cadastrados_pelo_construtor={constants.OCULTAR_VENDEDORES_SEM_CADASTRO_PLATAFORMA_NAO_CADASTRADOS_PELO_CONSTRUTOR} />

			<LeftModalWrapper
				visible={modals.fornecedor}
				updateModals={updateModals}
				salvando_selecao_fornecedor={salvando_selecao_fornecedor}>
				<LeftModalFornecedor
					fornecedor={fornecedor_pre_selecionado}
					form_novo_fornecedor={form_novo_fornecedor}
					errors_props={errors}
					fornecedor_ja_existe={fornecedor_ja_existe}
					cancelaFornecedorExistente={cancelaFornecedorExistente}
					adicionaFornecedorExistente={adicionaFornecedorExistente}
					fornecedor_existente={fornecedor_existente}
					cliente_id={obterClienteIdCadastro()}
					criarEditarRC={criarEditarRC}
					pre_nome_fornecedor={pre_nome_fornecedor}
					salvando_selecao_fornecedor={salvando_selecao_fornecedor}
					cadastrarVendedor={cadastrarVendedor}
					cadastrarFornecedorVendedor={cadastrarFornecedorVendedor}
					ocultar_vendedores_nao_cadastrados_pelo_construtor={constants.OCULTAR_VENDEDORES_SEM_CADASTRO_PLATAFORMA_NAO_CADASTRADOS_PELO_CONSTRUTOR}
					resetaErros={resetaErros}
					codigo_ibge_obra={codigo_ibge_obra}
					grupos_itens_pedido={grupos_itens_pedido} />
			</LeftModalWrapper>

			<LeftModalWrapper
				visible={modals.perfil_cliente}
				updateModals={updateModals}
				modal_to_close="perfil_cliente">
				<LeftModalClientePerfil
					cliente_id={constants.HABILITAR_MODAL_PERFIL_CLIENTE ? cotacao.cliente_rc_revisao.id_cliente : -1}
					obra_endereco={obra_endereco}
					obra_pessoas={obra_pessoas} />
			</LeftModalWrapper>

			<div className="container-fluid">
				<div className="row">
					<div className="col-xs-12">
						<div styleName="form-row-back-button">
							<Link to={`/cotacao/nova/${codigo}`}><button className="btn btn-clear-conaz round-border"><i className="fa fa-angle-left fa-fw" aria-hidden="true" /> Voltar para itens</button></Link>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-xs-12" styleName="header-wrapper">
						{
							constants.HABILITAR_MODAL_PERFIL_CLIENTE ? (
								<h3>Fornecedores para: <a href="#" onClick={exibePerfilCliente}>{`${cotacao.cliente_rc_revisao.nome_obra} - ${cotacao.cliente_rc_revisao.nome_cliente}`} </a></h3>
							) : (
								<h3>Selecione seus fornecedores</h3>
							)
						}
					</div>
				</div>
			</div>

			<div styleName="selecionados-wrapper">
				<div className="container">
					<div className="row">
						{request_state_fornecedores === 1 && (
							<div styleName="loading-wrapper">
								<div styleName="loader">
									<svg styleName="circular" viewBox="25 25 50 50">
										<circle styleName="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
									</svg>
								</div>
							</div>
						)}
						{request_state_fornecedores === 2 && _fornecedores_all_ids.map(fornecedor => (
							<FornecedorBox
								key={fornecedor}
								fornecedor={fornecedores_by_id[fornecedor]}
								onClickRemove={() => removeFornecedor(fornecedores_by_id[fornecedor].id)}
								onClickOpen={() => revelaFornecedor(fornecedores_by_id[fornecedor])}
								removendo_fornecedor={removendo_fornecedor}
								exibir_vendedor={fornecedores_by_id[fornecedor].tipo_selecao_fornecedor !== 1}
								destacar_rc_criado_pelo_fornecedor={constants.DESTACAR_RCS_CRIADOS_PELO_FORNECEDOR && fornecedores_by_id[fornecedor].tipo_selecao_fornecedor === 4}
								destacar_rc_criado_pelo_sistema={constants.DESTACAR_FORNECEDOR_DO_SISTEMA_PARA_CONSTRUTOR && (fornecedores_by_id[fornecedor].tipo_selecao_fornecedor === 3 || fornecedores_by_id[fornecedor].tipo_selecao_fornecedor === 4)}
								destacar_rc_criado_por_suporte={constants.DESTACAR_FORNECEDOR_DO_SISTEMA_PARA_INTERNO && fornecedores_by_id[fornecedor].tipo_selecao_fornecedor === 3} />))
						}

						{(request_state_fornecedores === 2 && _fornecedores_all_ids.length === 0) && (
							<div className="col-xs-12 col-md-4" />
						)}

						{request_state_fornecedores === 2 && (
							<div className="col-xs-12 col-md-4">
								{max_5_alert === 0 && (
									<div styleName="max-5-alert">
										<i className="fa fa-exclamation-triangle" aria-hidden="true" />
										Você já atingiu o limite máximo de fornecedores
										<p><button type="button" className="btn btn-link" onClick={removeMax5Alert} style={{ color: '#e4b349' }}>Entendi!</button></p>
									</div>
								)}
								<button type="button" styleName="btn-add-novo-fornecedor" onClick={openSearch}>+ Adicionar fornecedor</button>
							</div>
						)}

					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-xs-12" styleName="header-wrapper">
						<button
							type="button"
							onClick={handleProspeccaoExtraFornecedores}
							style={{ margin: '20px 0 0 0' }}
							styleName={`checkbox-button${prospeccao_extra_fornecedores ? ' checked' : ''}`}>
							<div styleName="checkbox-icon">
								{prospeccao_extra_fornecedores && <i className="fa fa-check" aria-hidden="true" />}
							</div>
							Quero que o sistema prospecte outros fornecedores além dos selecionados
						</button>
					</div>
				</div>
			</div>
			<div styleName="form-row">
				<Link to={`/cotacao/nova/${codigo}`}><button type="button" className="btn btn-clear-conaz round-border" styleName="back-button-1">Voltar</button></Link>
				{no_fornecedor_tooltip === 1 && (
					<div styleName="tooltip">
						<div styleName="tooltipbox">
							<p>Você não adicionou fornecedores para essa cotação. Conhece algum fornecedor que possa atender esse pedido?</p>
							<button
								name="no_fornecedor"
								onClick={validaEnvioTooltip}
								className="btn btn-clear-conaz round-border">
								Não conheço
							</button>
							<button
								name="add_fornecedor"
								onClick={validaEnvioTooltip}
								className="btn btn-secondary-conaz round-border">
								Conheço, quero adicionar
							</button>
						</div>
					</div>
				)}
				<button type="button" className="btn btn-secondary-conaz round-border" onClick={validaEnvio} style={{ height: 32 }}>Ir para informações gerais <i className="fa fa-angle-right fa-fw" aria-hidden="true" /></button>
				<Link to={`/cotacao/nova/${codigo}`}><button type="button" className="btn btn-clear-conaz round-border" styleName="back-button-2">Voltar</button></Link>
			</div>
		</>
	);
};

NovaCotacaoFornecedores.propTypes = {
	// =========== local
	errors: PropTypes.object.isRequired,
	fornecedor_pre_selecionado: PropTypes.object.isRequired,
	form_novo_fornecedor: PropTypes.bool.isRequired,
	fornecedor_ja_existe: PropTypes.bool.isRequired,
	no_fornecedor_tooltip: PropTypes.number.isRequired,
	max_5_alert: PropTypes.number.isRequired,
	alert_delete_fornecedor_respondido: PropTypes.bool.isRequired,
	fornecedor_existente: PropTypes.object.isRequired,
	removendo_fornecedor: PropTypes.bool.isRequired,
	pre_nome_fornecedor: PropTypes.string.isRequired,
	salvando_selecao_fornecedor: PropTypes.bool.isRequired,
	// =========== local funcs
	openSearch: PropTypes.func.isRequired,
	validaEnvio: PropTypes.func.isRequired,
	validaEnvioTooltip: PropTypes.func.isRequired,
	removeFornecedor: PropTypes.func.isRequired,
	revelaFornecedor: PropTypes.func.isRequired,
	updateState: PropTypes.func.isRequired,
	cancelaFornecedorExistente: PropTypes.func.isRequired,
	adicionaFornecedorExistente: PropTypes.func.isRequired,
	removeMax5Alert: PropTypes.func.isRequired,
	handleAlertFornecedor: PropTypes.func.isRequired,
	exibePerfilCliente: PropTypes.func.isRequired,
	handleProspeccaoExtraFornecedores: PropTypes.func.isRequired,
	criarEditarRC: PropTypes.func.isRequired,
	cadastrarFornecedorVendedor: PropTypes.func.isRequired,
	cadastrarVendedor: PropTypes.func.isRequired,
	obterClienteIdCadastro: PropTypes.func.isRequired,
	obterClienteIdReal: PropTypes.func.isRequired,
	resetaErros: PropTypes.func.isRequired,
	// =========== store
	cotacao: PropTypes.object.isRequired,
	ui: PropTypes.object.isRequired,
	// =========== funcs
	updateUi: PropTypes.func.isRequired,
	updateCotacao: PropTypes.func.isRequired,
	updateModals: PropTypes.func.isRequired,
};

export default CSSModules(NovaCotacaoFornecedores, styles, { allowMultiple: true });
