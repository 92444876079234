import styled, { css } from 'styled-components';
import { Drawer } from 'antd';

export const StyledDrawer = styled(Drawer)`
	z-index: 1012 !important;

	.ant-drawer-title {
		color: #262626;
		font-size: 18px;
		line-height: 25px;
		font-weight: normal;
	}

	.ant-drawer-body {
		/* padding: 40px; */
		display: flex;
		flex-direction: column;
		padding: 0px;
		position: relative;
	}

	.ant-form-item-label, .ant-form-item-required {
		color: #595959;
		font-size: 14px;
		> label {
			color: #595959;
			font-size: 14px;
		}
	}

	.ant-form-item-required::before {
		position: absolute;
		right: -16px;
		color: rgb(255, 120, 117) !important;
	}

	.ant-checkbox-wrapper {
		color: #262626;
		font-weight: normal;
	}

	.ant-form {
		padding: 40px;
	}

	.footer-form {
		padding: 0px;
	}

	.ant-input, .ant-select-selector {
		border-radius: 4px !important;
	}
	
	// específicos
	.phone-input-with-plus {
		display: flex;
		align-items: center;
		gap: 15px;

		svg {
			width: 23px;
			height: 23px;
			cursor: pointer;
		}
	}

	.usuario-user-role-warn {
		background: #D9EEFF;
		display: flex;
		align-items: center;
		margin: 24px 40px 0px !important;
		padding: 5px 13px;
		font-size: 12px;

		.link-roles {
			font-style: italic;
		}

		& > svg {
			margin-right: 10px;
			width: 23px;
			height: 23px;
		}
	}

	.select-tags-obras {
		.ant-select-selection-item {
			background: #E8E8E8;
			padding: 0px 12px;
			color: #0076D6;
			border: none;
			cursor: default !important;
		}
		> div {
			cursor: default !important;
			background: transparent !important;
		}
 	}

	@media(max-width: 600px) {
		.ant-drawer-content-wrapper {
			max-width: 100%;
		}
		.ant-drawer-footer {
			margin-bottom: 6rem;
    		padding: 10px 0px;
		}
	}
`;

export const IconButton = styled.div`
	cursor: pointer;
	${({ disabled }) => disabled && css`
	 	pointer-events: none;
		cursor: not-allowed;
	`};
`;

export const DrawerFooter = styled.div`
	/* position: absolute; */
	/* bottom: 0; */
	position: sticky;
	bottom: 0;
	margin-top: auto;
	width: 100%;
	z-index: 1;
	background: #fff;
	box-shadow: 0px -1px 0px #BFBFBF;
`;

DrawerFooter.Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	padding: 16px 95px 0px;

	.cancel-btn {
		border: 1px solid #595959;
		color: #595959;
	}

	.submit-btn {
		background-color: #0076D6;
	}
`;
