import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

// Components
import CompraRowStatus from './CompraRowStatus';

// Functions
import { defineStatusCompra } from '../../_functions/_defineStatusCompra';
import { formatCurrency } from '../../_functions/_formatCurrency';

const CompraRowTopInfo = ({ compra }) => {
	const { valor_total } = compra;
	const status = defineStatusCompra(compra);
	const compra_id = compra.id || 0;
	const titulo = compra.titulo || '';
	const preco_class = `preco-fechado ${status}`;

	return (
		<>
			<div styleName="titulo">
				<span style={{ color: '#8C8C8C' }}>#{compra_id}</span> - {titulo}
				<p>
					<CompraRowStatus compra={compra} />
				</p>
			</div>
			<div styleName={preco_class}>
				{formatCurrency(valor_total, 2, 'R$')}
			</div>
		</>
	);
};

CompraRowTopInfo.propTypes = {
	compra: PropTypes.object.isRequired,
};

export default CSSModules(CompraRowTopInfo, styles, { allowMultiple: true });
