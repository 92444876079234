const ambiente_eh_triagem = process.env.REACT_APP_CONAZ_PROJECT === 'construtor-interno';


// perfil fornecedor
export const EXIBIR_STATUS_FORNECEDOR = ambiente_eh_triagem;
export const INCLUI_LINK_EDICAO = ambiente_eh_triagem;
export const EXIBIR_OBSERVACOES = ambiente_eh_triagem;
export const EXIBIR_CADASTRADO_POR = ambiente_eh_triagem;


// perfil vendedor
export const EXIBIR_STATUS_VENDEDOR = ambiente_eh_triagem;
export const FORCA_EXIBICAO_VENDEDOR_SELECIONADO = ambiente_eh_triagem;


// selecao fornecedor
export const EXIBIR_BOTAO_REENVIO = ambiente_eh_triagem;
export const EXIBIR_CHECK_ENVIO_EMAIL = ambiente_eh_triagem;
export const EDITA_TIPO_SELECAO_FORNECEDOR = ambiente_eh_triagem;


// cadastro fornecedor
export const INCLUIR_SITE = ambiente_eh_triagem;
