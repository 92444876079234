import React from 'react';
import PropTypes from 'prop-types';

// CSS
import CSSModules from 'react-css-modules';
import styles from './style.module.scss';

const FornecedorBox = ({
	fornecedor,
	onClickRemove,
	onClickOpen,
	removendo_fornecedor,
	exibir_vendedor,
	destacar_rc_criado_pelo_fornecedor,
	destacar_rc_criado_pelo_sistema,
	destacar_rc_criado_por_suporte,
}) => {
	let button_class = 'content-wrapper selected';

	if (destacar_rc_criado_por_suporte) {
		button_class = 'content-wrapper selected interno';
	}

	if (destacar_rc_criado_pelo_fornecedor) {
		button_class = 'content-wrapper selected fornecedor';
	}

	const { cidade, estado } = fornecedor.endereco;
	const localizacao = `${cidade || ''}/${estado || ''}`;

	return (
		<div className="col-xs-12 col-md-4">
			<div styleName="fornecedor-wrapper">
				{!removendo_fornecedor && (
					<button
						type="button"
						styleName="remove-fornecedor"
						onClick={onClickRemove}>
						<i className="fa fa-trash" aria-hidden="true" />
					</button>
				)}
				<button
					type="button"
					styleName={button_class}
					onClick={onClickOpen}>
					{destacar_rc_criado_pelo_sistema && (
						<div styleName="selecionado-pelo-sistema">SELECIONADO PELO SISTEMA</div>
					)}
					<div styleName="nome">{fornecedor.nome}</div>
					{localizacao !== '/' && (
						<p style={{ marginBottom: '6px' }}>{localizacao}</p>
					)}
					{exibir_vendedor && fornecedor.vendedor_selecionado.nome && (
						<p styleName="vendedor-line"><span>Vendedor: </span> {fornecedor.vendedor_selecionado.nome !== '' ? fornecedor.vendedor_selecionado.nome : fornecedor.vendedor_selecionado.email}</p>
					)}
				</button>
			</div>
		</div>
	);
};

FornecedorBox.propTypes = {
	fornecedor: PropTypes.object.isRequired,
	onClickOpen: PropTypes.func.isRequired,
	onClickRemove: PropTypes.func.isRequired,
	removendo_fornecedor: PropTypes.bool.isRequired,
	exibir_vendedor: PropTypes.bool.isRequired,
	destacar_rc_criado_pelo_fornecedor: PropTypes.bool.isRequired,
	destacar_rc_criado_pelo_sistema: PropTypes.bool.isRequired,
	destacar_rc_criado_por_suporte: PropTypes.bool.isRequired,
};

export default CSSModules(FornecedorBox, styles, { allowMultiple: true });
