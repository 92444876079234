import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import {
	Form,
	Radio,
	Button,
} from 'antd';

import { StyledDrawer, DrawerFooter } from './styled';

// Icons
import { ReactComponent as Exclamacao } from '../../icons/exclamacao_outlined.svg';

import FormPessoaFisica from './FormPessoaFisica';
import FormPessoaJuridica from './FormPessoaJuridica';

const modes = {
	NEW: 'new',
	EDIT: 'edit',
	VIEW: 'view',
};

const personas = {
	FISICA: 'fisica',
	JURIDICA: 'juridica',
};

function DrawerEmpresas({ mode, onClose, visible, onSubmit, empresaData, loading }) {
	const [form] = Form.useForm();
	const [personaType, setPersonaType] = useState(personas.FISICA);
	const [enableFields, setEnableFields] = useState(false);
	const loadData = mode === modes.VIEW || mode === modes.EDIT;
	const disableAll = mode === modes.VIEW || loading;
	const userRoleLink = 'https://produtosautodoc.zendesk.com/hc/pt-br/articles/4416798746515-Como-cadastrar-uma-empresa-';

	const getDrawerTitle = (m) => {
		if (m === modes.NEW) return 'Cadastro de nova empresa';
		if (m === modes.EDIT) return 'Edição de empresa';
		return 'Visualização de empresa';
	};

	const handleClose = () => {
		onClose();
		setPersonaType(personas.FISICA);
		setEnableFields(false);
		form.resetFields();
	};

	const submitForm = () => {
		form.submit();
	};

	const handleSubmit = (values) => {
		const {
			nome,
			documento,
			razao_social,
			// endereco
			cep,
			cidade,
			estado,
			logradouro,
			numero,
			bairro,
			complemento,
			inscricao_estadual,
			...rest
		} = values;

		const previousData = empresaData && mode === modes.EDIT ? empresaData : {};
		onSubmit({
			id: previousData.id,
			nome,
			documento: documento.replace(/[^\d]+/g, ''),
			razao_social,
			endereco: {
				...previousData.endereco,
				cep: cep.replace(/[^\d]+/g, ''),
				cidade,
				estado,
				logradouro,
				numero,
				bairro,
				complemento,
			},
			...rest,
			persona: personaType,
			inscricao_estadual: inscricao_estadual || '',
		});
	};

	const handlePersonaRadio = (e) => {
		setPersonaType(e.target.value);
	};

	useEffect(() => {
		if ((mode === modes.EDIT && visible) || (mode === modes.VIEW && visible)) {
			const { id, documento, nome, endereco, logo_url, inscricao_estadual, razao_social } = empresaData || {};
			const { bairro, cep, cidade, estado, logradouro, numero, complemento } = endereco || {};
			form.setFieldsValue({
				id,
				documento,
				nome,
				endereco,
				razao_social,
				// endereco
				bairro,
				cep,
				cidade,
				estado,
				logradouro,
				numero,
				complemento,
				logo_url,
				ficha_cadastral: logo_url,
				inscricao_estadual,
			});
		}
		if (mode === modes.NEW && visible) {
			form.resetFields();
		}
	}, [mode, visible]);

	const Footer = () => (
		<DrawerFooter>
			<div className="footer-form">
				<DrawerFooter.Actions>
					{mode === modes.EDIT || mode === modes.NEW ? (
						<>
							<Button className="cancel-btn" shape="round" onClick={handleClose} disabled={loading}>
								Cancelar
							</Button>
							<Button
								className="submit-btn"
								type="primary"
								shape="round"
								htmlType="submit"
								key="submit"
								form="empresa_form"
								onClick={submitForm}
								disabled={loading}
							>
								{mode === modes.EDIT ? 'Editar' : 'Cadastar'}
							</Button>
						</>
					) : (
						<Button className="submit-btn" type="primary" shape="round" onClick={handleClose}>
							Ok
						</Button>
					)
					}
				</DrawerFooter.Actions>
			</div>
		</DrawerFooter>
	);

	useEffect(() => {
		if (loadData && empresaData) {
			const { endereco } = empresaData;
			if (endereco.cep) setEnableFields(true);

			const isPersonaFisica = `${empresaData.documento || ''}`.length <= 11;
			if (isPersonaFisica) setPersonaType(personas.FISICA);
			else setPersonaType(personas.JURIDICA);

			form.setFieldsValue({
				...empresaData,
				...endereco,
			});
		}
	}, [empresaData, loadData, mode]);

	return (
		<StyledDrawer
			title={getDrawerTitle(mode)}
			placement="right"
			width="1060"
			destroyOnClose
			onClose={handleClose}
			visible={visible}
			footer={<Footer />}
		>
			{window.innerWidth > 768 && (
				<div className="empresa-user-role-warn">
					<Exclamacao />
					<a className="link-roles" href={userRoleLink}>
						Entenda como cadastrar suas empresas em nossa central de ajuda.
					</a>
				</div>
			)}

			{window.innerWidth < 768 && (
				<div className="empresa-user-role-warn" style={{ margin: 0 }}>
					<Exclamacao />
					<div style={{ display: 'block', alignItems: 'center' }}>
						<a className="link-roles" href={userRoleLink}>
							Entenda como cadastrar suas empresas em nossa central de ajuda.
						</a>
					</div>
				</div>
			)}
			<Form form={form} layout="vertical" onFinish={handleSubmit} name="empresa" id="empresa_form">
				<Form.Item name="persona">
					<div className="radio-persona-type">
						<Radio.Group buttonStyle="solid" defaultValue="fisica" onChange={handlePersonaRadio} value={personaType} disabled={disableAll}>
							<Radio.Button value={personas.FISICA} disabled={mode === 'edit' && mode !== 'new' && personaType === personas.JURIDICA}> Pessoa Física </Radio.Button>
							<Radio.Button value={personas.JURIDICA} disabled={mode === 'edit' && mode !== 'new' && personaType === personas.FISICA}> Pessoa Jurídica </Radio.Button>
						</Radio.Group>
					</div>
				</Form.Item>
				{
					personaType === personas.FISICA ? (
						<FormPessoaFisica mode={mode} form={form} empresaData={empresaData} disableAll={disableAll} enableFields={enableFields} />
					) : (
						<FormPessoaJuridica mode={mode} form={form} empresaData={empresaData} disableAll={disableAll} enableFields={enableFields} />
					)
				}

			</Form>
		</StyledDrawer>
	);
}

DrawerEmpresas.propTypes = {
	mode: PropTypes.string,
	loading: PropTypes.bool,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	empresaData: PropTypes.shape({
		documento: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.number,
		]),
		endereco: PropTypes.shape({ cep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) }),
	}),
};

DrawerEmpresas.defaultProps = {
	mode: 'new',
	visible: false,
	loading: false,
	onClose: () => {},
	onSubmit: () => {},
	empresaData: null,
};

export default DrawerEmpresas;
