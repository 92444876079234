import React from 'react';
import PropTypes from 'prop-types';

// Functions
import { formatNumbers } from '../../_functions/_formatNumbers';

const CNPJFornecedorSienge = ({ documento }) => {
	const cnpj = (documento || '').toString();
	const doc_size = cnpj.length;
	const is_cnpj = doc_size === 14;

	return is_cnpj
		? <>{formatNumbers(cnpj, 'cnpj')}</>
		: <>fornecedor não possui CNPJ cadastrado no Sienge</>;
};

CNPJFornecedorSienge.propTypes = {
	documento: PropTypes.string.isRequired,
};

export default CNPJFornecedorSienge;
